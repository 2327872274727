import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsSpikeArmor, MiscDataSsTempSafeZone, MiscDataSsWhammyWave } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';

const fishInfo = fishInfos[FishType.MantraRay];

export class SsTempSafeZoneSymbol implements MiscSymbol {

	public static get() {
		const symbol = SsTempSafeZoneSymbol._pool.get();
		symbol.area.scale.set(0.01);
		Tween.get(symbol.area.scale).to({ x: 1, y: 1 }, 500, Ease.quintOut);
		return symbol;
	}

	private static _pool: Pool<SsTempSafeZoneSymbol> = new Pool(SsTempSafeZoneSymbol);
	public area: Graphics = new Graphics();

	public data: MiscData | null = null;
	// public pt = 0;
	// public casting = true;


	constructor() {
		this.area.lineStyle(3, 0x00ff00, 0.2);
		this.area.beginFill(0x00ff00, 0.1);
		this.area.drawCircle(0, 0, 200);
	}
	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		SsTempSafeZoneSymbol._pool.pool(this);

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsTempSafeZone;
		this.area.x = data.x;
		this.area.y = data.y;

		if (!this.data) {
			this.area.visible = true;
			this.area.alpha = 1;
			// this.visible = false;
			new SoundEfx('tempSafeZone').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
		}
		// else {
		// 	const delta = rt - this.pt;
		// }
		// this.pt = rt;
		const deactivateTime = data.da;
		const remainingTime = deactivateTime - rt;
		if (remainingTime < 500) {
			this.area.alpha = remainingTime / 500;
		}
		// const startTime = data.st;
		// this.casting = rt <= startTime + castTime;
		// if (!this.casting && this.area.parent) {
		// 	this.area.parent.removeChild(this.area);
		// }
		this.data = data;
	}
	public show(room: RoomControl) {
		// if (this.casting) {
		room.wallBottomContainer.addChild(this.area);
		// }
		// room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		// if (this.parent) {
		// 	this.parent.removeChild(this);
		// }
	}
}
