import { SoundInstance } from './SoundInstance';
import { Sound, PlayOptions, IMediaInstance } from '@pixi/sound';
import { Global } from '@/store/globalz';
import { SfxId, sfxs } from './soundInfos';

export class LongSoundEfx extends SoundInstance {

	public media: IMediaInstance | null = null;

	constructor(sfxId: SfxId) {
		super(sfxs[sfxId]);
		this.addGroup(Global.masterSoundGroup);
		this.addGroup(Global.sfxSoundGroup);
	}

	public play(options: PlayOptions = {}) {
		if (options.loop === undefined) {
			options.loop = true;
		}
		if (options.volume !== undefined) {
			this.volume = options.volume;
		}
		if (this.media) {
			this.media.destroy();
			this.media = null;
		}
		options.volume = this.getVolume();
		if (this.sound.isPlayable) {
			this.media = this.sound.play(options) as IMediaInstance;
		}
	}
	public start(options?: PlayOptions) {
		if (!this.media) {
			this.play(options);
		}
	}
	public stop() {
		if (this.media) {
			this.media.destroy();
			this.media = null;
		}
	}
	public updateVolume() {
		if (this.media) {
			this.media.volume = this.getVolume();
		}
	}
}
