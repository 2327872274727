

















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import userx from '@/store/modules/userx';
import { SignLinkError } from '@/store/firestore';
import fb from '@/store/sf-firestore';
import PinInput from '@/components/inputs/PinInput.vue';
import { EditState } from '@/store/models.def';
import StateButton from '@/components/inputs/StateButton.vue';
import Radix36Input from '@/components/inputs/Radix36Input.vue';
import TeamMateComponent from './TeamMateComponent.vue';

@Component({
  components: {
    PinInput,
    Radix36Input,
    StateButton,
    TeamMateComponent,
  },
})
export default class TeamModal extends Vue {
  public passcodeInput = '';
  public validatePasscodeNow = false;
  public teamIdInput = '';
  public validateTeamIdNow = false;

  public createTeamState: EditState = 'active';
  public joinTeamState: EditState = 'active';
  public leaveTeamState: EditState = 'active';
  public changePasscodeState: EditState = 'active';

  public get createTeamLabel() {
    const labels = {
      active: 'Create Team',
      waiting: 'Create Team',
      sending: 'Creating...',
      done: 'Created',
    };
    return labels[this.createTeamState];
  }
  public get joinTeamLabel() {
    const labels = {
      active: 'Join Team',
      waiting: 'Join Team',
      sending: 'Joining...',
      done: 'Joined',
    };
    return labels[this.createTeamState];
  }
  public get leaveTeamLabel() {
    const labels = {
      active: 'Leave Team',
      waiting: 'Leave Team',
      sending: 'Leaving...',
      done: 'Leaved',
    };
    return labels[this.leaveTeamState];
  }
  public get changePasscodeLabel() {
    const labels = {
      active: 'Change Passcode',
      waiting: 'Change Passcode',
      sending: 'Changing...',
    };
    return labels[this.changePasscodeState];
  }

  public get noTeamDisabled() {
    return this.teamData ||
      this.createTeamState !== 'active' ||
      this.joinTeamState !== 'active'
      ? true
      : false;
  }

  public get titleHtml() {
    return `<i class="fas fa-users pr-2"></i> Team`;
  }

  public get teamData() {
    return userx.teamData;
  }
  public get members() {
    if (!this.teamData) { return []; }
    const members = Object.values(this.teamData.members).sort(
      (a, b) => a.index - b.index,
    );
    members.find((m) => m.uid === this.uid)!.isOnline = true;
    return members;
  }

  public get numMembers() {
    return this.members.length;
  }

  public get uid() {
    return userx.user?.uid || '';
  }

  public get leader() {
    for (const member of this.members) {
      if (member.isOnline) {
        return member.uid;
      }
    }
  }
  public get imLeader() {
    return this.uid === this.leader;
  }

  public get hasTeam() {
    return this.teamData ? true : false;
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }

  public async onLeaveTeam() {
    this.leaveTeamState = 'waiting';
    const value = await this.$bvModal.msgBoxConfirm('Are you sure?', {
      title: `Leave Team`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      cancelVariant: 'link btn-link-warning',
      modalClass: 'funny-modal',
      okTitle: 'Confirm',
      cancelTitle: 'Cancel',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: false,
    });
    if (value) {
      try {
        await userx.leaveTeam();
        this.leaveTeamState = 'done';
      } catch (error) {
        this.showError(error);
      }
    } else {
      this.leaveTeamState = 'active';
    }
  }
  public onCreateTeam() {
    this.createTeamState = 'waiting';
    this.$bvModal.show('team-passcode');
  }
  public onChangePasscode() {
    this.changePasscodeState = 'waiting';
    this.$bvModal.show('team-passcode');
  }
  public onJoinTeam() {
    this.joinTeamState = 'waiting';
    this.$bvModal.show('team-id-input');
  }
  public async onPasscodeOk(e) {
    this.validatePasscodeNow = true;
    if (this.passcodeInput === null) {
      e.preventDefault();
    } else {
      if (this.createTeamState === 'waiting') {
        this.createTeamState = 'sending';
        try {
          await userx.createTeam(this.passcodeInput || '');
          this.createTeamState = 'done';
        } catch (error) {
          this.createTeamState = 'active';
          this.showError(error);
        }
      } else if (this.joinTeamState === 'waiting') {
        this.joinTeamState = 'sending';

        try {
          await userx.joinTeam({
            tid: this.teamIdInput.toLowerCase(),
            passcode: this.passcodeInput || '',
          });
          this.joinTeamState = 'done';
        } catch (error) {
          this.joinTeamState = 'active';
          this.showError(error);
        }
      } else if (this.changePasscodeState === 'waiting') {
        this.changePasscodeState = 'sending';

        try {
          await userx.changeTeamPasscode(this.passcodeInput || '');
          this.changePasscodeState = 'active';
        } catch (error) {
          this.changePasscodeState = 'active';
          this.showError(error);
        }
      }
    }
  }
  public onPasscodeCancel() {
    this.passcodeInput = '';
    this.createTeamState = 'active';
    this.joinTeamState = 'active';
    this.changePasscodeState = 'active';
  }
  public onTeamIdOk(e) {
    this.validateTeamIdNow = true;
    if (this.teamIdInput === null) {
      e.preventDefault();
    } else {
      this.$bvModal.show('team-passcode');
    }
  }
  public onTeamIdCancel() {
    this.teamIdInput = '';
    this.createTeamState = 'active';
    this.joinTeamState = 'active';
  }

  public async showModal(error: SignLinkError) {
    const modal = this.$refs.modal as BModal;
    if (modal) {
      modal.show();
    }
  }

  public created() {
    this.$root.$on('partyModal', this.showModal);
  }

  @Watch('hasTeam', { immediate: true })
  public hasTeamChanged(newVal: boolean) {
    if (newVal) {
      this.leaveTeamState = 'active';
      this.changePasscodeState = 'active';
      this.passcodeInput = this.teamData!.passcode;
    } else {
      this.createTeamState = 'active';
      this.joinTeamState = 'active';
      this.passcodeInput = '';
    }
  }
}
