import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import { Global } from '@/store/globalz';
import { Dictionary } from '@/util/dictionary';
import { Pool } from '@/util/pool';
import { Container } from 'pixi.js';
import { DecoName, decoBasicInfos, DecoData, DecoType, DecoSet } from '@/game/infos/decorativeInfos';
import { generateOutlineFromContainer } from './generateOutline';
import { FishType } from '@/game/infos/fishInfos';
import userx from '@/store/modules/userx';

declare const sub: any;


export interface DecoInfo {
	defaultX: number,
	defaultY: number,
	defaultScale: number,
	defaultAngle: number,
	splitted: boolean,
	defaultType: DecoType,
	decoName: DecoName,
	name: string,
}

export enum DecoSplitType {
	NONE,
	PART_A,
	PART_B,
}
export interface DecoItem {
	id: number;
	decoId: string;
	decoName: DecoName | 'Body';
	splitType: DecoSplitType;
	entangle?: DecoItem;
	name: string;
}
export interface DecoSymbol extends Container {
	decoName: DecoName;
}

const decoInfos: Dictionary<DecoInfo> = {};
const pools: Dictionary<Pool<DecoSymbol>> = {};

const decoSymbolCache: Dictionary<DecoSymbol> = {};



export const MOUTH_W = 20;
export const MOUTH_H = 10;
export const MOUTH_Y = -28;
export const EYE_W = 60;
export const EYE_Y = -24;


export class Decoratives {

	public static async requestTexture(decoSets: DecoSet[]) {

	}


	/** used for modelAvatar in decoEditor only */
	public static setAvatarDeco(decos: Dictionary<DecoData>, list: DecoItem[], ignoreType = false) {
		// console.log('set avatar deco');
		const remainingIds: Set<number> = new Set();
		const top = Global.modelAvatar.topContainer;
		const bottom = Global.modelAvatar.bottomContainer;
		top.removeChildren();
		bottom.removeChildren();
		let isBottom = false;
		for (const item of list) {
			if (item.decoName === 'Body') {
				isBottom = true;
				continue;
			}
			remainingIds.add(item.id);
			if (decoSymbolCache[item.id] && decoSymbolCache[item.id].decoName !== item.decoName) {
				const oldSymbol = decoSymbolCache[item.id];
				Decoratives.pool(oldSymbol.decoName, oldSymbol);
				delete decoSymbolCache[item.id];
			}
			if (!decoSymbolCache[item.id]) {
				decoSymbolCache[item.id] = this.getDeco(item.decoName, false);
				if (item.splitType === DecoSplitType.PART_B) {
					decoSymbolCache[item.id].children[0].visible = true;
					decoSymbolCache[item.id].children[1].visible = false;
				} else if (item.splitType === DecoSplitType.PART_A) {
					decoSymbolCache[item.id].children[0].visible = false;
					decoSymbolCache[item.id].children[1].visible = true;
				}
			}
			const symbol = decoSymbolCache[item.id];
			const prop = decos[item.decoId];
			symbol.x = prop.x;
			symbol.y = prop.y;
			symbol.angle = prop.angle;
			symbol.scale.set(prop.scale);
			symbol.scale.x *= prop.flipX ? -1 : 1;
			symbol.scale.y *= prop.flipY ? -1 : 1;
			// if (!ignoreType) {
			// 	if (prop.type === 'eyewear') {
			// 		const eyeGuide = eyeGui;
			// 		const esx = eyeGuide.scale.x;
			// 		const ey = eyeGuide.y;
			// 		symbol.y = ey + prop.y;
			// 		symbol.x = prop.x * esx;
			// 		const d = Math.abs(prop.x) / (EYE_W / 2);
			// 		if (d < 1) {
			// 			const scale = (1 - d) * (esx - 1) + 1;
			// 			symbol.scale.set(scale);
			// 		}
			// 	} else if (prop.type === 'mouthpiece') {
			// 		const mouthGuide = Global.modelAvatar.mouthGuide;
			// 		const msx = mouthGuide.scale.x;
			// 		const msy = mouthGuide.scale.y;
			// 		const mh = msy * MOUTH_H;
			// 		const my = mouthGuide.y;
			// 		if (prop.y <= 0) {
			// 			symbol.y = my + prop.y;
			// 			symbol.x = prop.x;
			// 		} else if (prop.y >= MOUTH_H) {
			// 			symbol.y = my + mh + prop.y;
			// 			symbol.x = prop.x;
			// 		} else {
			// 			symbol.y = my + msy * prop.y;
			// 			symbol.x = msx * prop.x;
			// 		}
			// 	}
			// }
			const container = isBottom ? bottom : top;
			container.addChildAt(symbol, 0);
		}
		for (const key in decoSymbolCache) {
			if (Object.prototype.hasOwnProperty.call(decoSymbolCache, key)) {
				if (remainingIds.has(Number(key))) { continue; }
				const symbol = decoSymbolCache[key];
				Decoratives.pool(symbol.decoName, symbol);
				delete decoSymbolCache[key];
			}
		}
	}
	public static clearAvatarCache() {
		for (const key in decoSymbolCache) {
			if (Object.prototype.hasOwnProperty.call(decoSymbolCache, key)) {
				const symbol = decoSymbolCache[key];
				Decoratives.pool(symbol.decoName, symbol);
				delete decoSymbolCache[key];
			}
		}
	}

	/** used for player own canvas component */
	public static generateDeco(avatar: PlayerSymbol, decoSet: DecoSet | null) {
		// console.log('generateDeco');
		if (!decoSet) {
			decoSet = { decos: {}, body: null, top: [], bottom: [] };
		}
		const top = avatar.topContainer;
		const bottom = avatar.bottomContainer;
		avatar.disposeDecos();
		const addedIds = new Set<string>();
		const addSymbol = (container: Container, decoId: string) => {
			const prop = decoSet!.decos[decoId];
			const decoName = prop.decoName;
			const symbol = this.getDeco(decoName, false);
			if (addedIds.has(decoId)) {
				symbol.children[1].visible = false;
				symbol.children[0].visible = true;
			} else {
				if (symbol.children[1]) {
					symbol.children[1].visible = true;
					symbol.children[0].visible = false;
				} else {
					symbol.children[0].visible = true;
				}
			}
			symbol.x = prop.x;
			symbol.y = prop.y;
			symbol.angle = prop.angle;
			symbol.scale.set(prop.scale);
			symbol.scale.x *= prop.flipX ? -1 : 1;
			symbol.scale.y *= prop.flipY ? -1 : 1;
			// if (!ignoreType) {
			// 	if (prop.type === 'eyewear') {
			// 		const eyeGuide = avatar.eyeGuide;
			// 		const esx = eyeGuide.scale.x;
			// 		const ey = eyeGuide.y;
			// 		symbol.y = ey + prop.y;
			// 		symbol.x = prop.x * esx;
			// 		const d = Math.abs(prop.x) / (EYE_W / 2);
			// 		if (d < 1) {
			// 			const scale = (1 - d) * (esx - 1) + 1;
			// 			symbol.scale.set(prop.scale * scale);
			// 		}
			// 	} else if (prop.type === 'mouthpiece') {
			// 		const mouthGuide = avatar.mouthGuide;
			// 		const msx = mouthGuide.scale.x;
			// 		const msy = mouthGuide.scale.y;
			// 		const mh = msy * MOUTH_H;
			// 		const my = mouthGuide.y;
			// 		if (prop.y <= 0) {
			// 			symbol.y = my + prop.y;
			// 			symbol.x = prop.x;
			// 		} else if (prop.y >= MOUTH_H) {
			// 			symbol.y = my + mh + prop.y - MOUTH_H;
			// 			symbol.x = prop.x;
			// 		} else {
			// 			symbol.y = my + msy * prop.y;
			// 			symbol.x = msx * prop.x;
			// 		}
			// 	}
			// }
			container.addChildAt(symbol, 0);
			addedIds.add(decoId);
		};
		for (const decoId of decoSet.top) {
			addSymbol(top, decoId);
		}
		for (const decoId of decoSet.bottom) {
			addSymbol(bottom, decoId);
		}
	}
	public static async isDecoOutOfBound(decoSet: DecoSet | null, fishType: FishType) {
		if (!decoSet) {
			return false;
		}
		const container = new Container();
		const addedIds = new Set<string>();
		const addSymbol = (decoId: string) => {
			const prop = decoSet!.decos[decoId];
			const decoName = prop.decoName;
			const symbol = this.getDeco(decoName, false);
			if (addedIds.has(decoId)) {
				symbol.children[1].visible = false;
				symbol.children[0].visible = true;
			} else {
				if (symbol.children[1]) {
					symbol.children[1].visible = true;
					symbol.children[0].visible = false;
				} else {
					symbol.children[0].visible = true;
				}
			}
			symbol.x = prop.x;
			symbol.y = prop.y;
			symbol.angle = prop.angle;
			symbol.scale.set(prop.scale);
			symbol.scale.x *= prop.flipX ? -1 : 1;
			symbol.scale.y *= prop.flipY ? -1 : 1;
			container.addChildAt(symbol, 0);
			addedIds.add(decoId);
		};
		for (const decoId of decoSet.top) {
			addSymbol(decoId);
		}
		for (const decoId of decoSet.bottom) {
			addSymbol(decoId);
		}
		if (container.children.length === 0) {
			return false;
		}
		const outline = await generateOutlineFromContainer(container, fishType);
		for (const point of outline) {
			if (point.x <= -105 || point.x >= 105 || point.y <= -100 || point.y >= 80) {

				if (fishType) {
					for (const decoId in decoSet.decos) {
						if (Object.prototype.hasOwnProperty.call(decoSet.decos, decoId)) {
							const deco = decoSet.decos[decoId];
							const decoInfo = Decoratives.getDecoInfo(deco.decoName);
							deco.angle = decoInfo.defaultAngle;
							deco.scale = decoInfo.defaultScale;
							deco.x = decoInfo.defaultX;
							deco.y = decoInfo.defaultY;
						}
					}
					decoSet.version = 1;
					userx.updateDecoSet({
						fishType,
						decoSet,
					});
				}
				for (const child of container.children) {
					const symbol = child as DecoSymbol;
					if (symbol.decoName) {
						Decoratives.pool(symbol.decoName, symbol);
					}
				}
				// const polygon = new SimplePolygon();

				// polygon.vertices = outline;
				// const decoShape = new Graphics();
				// decoShape.lineStyle(1, 0xff0000, 0.5);
				// decoShape.drawPolygon(
				// 	polygon.transformedVertices.map(p => new Point(p.x, p.y)),
				// );
				return true;

			}
		}
	}

	/** used for live game player control */
	public static generateDecoContainers(decoSet: DecoSet | null) {
		if (!decoSet) {
			decoSet = { decos: {}, body: null, top: [], bottom: [] };
		}
		const top = new Container();
		const bottom = new Container();

		// for splitted decos
		const addedIds = new Set<string>();
		const addSymbol = (container: Container, decoId: string) => {
			const prop = decoSet!.decos[decoId];
			const decoName = prop.decoName;
			if (!decoName || !sub['DecoDefault_' + decoName]) { return; }
			const symbol = this.getDeco(decoName, false);
			if (addedIds.has(decoId)) {
				symbol.children[1].visible = false;
				symbol.children[0].visible = true;
			} else {
				if (symbol.children[1]) {
					symbol.children[1].visible = true;
					symbol.children[0].visible = false;
				} else {
					symbol.children[0].visible = true;
				}
			}
			symbol.x = prop.x;
			symbol.y = prop.y;
			symbol.angle = prop.angle;
			symbol.scale.set(prop.scale);
			symbol.scale.x *= prop.flipX ? -1 : 1;
			symbol.scale.y *= prop.flipY ? -1 : 1;

			container.addChildAt(symbol, 0);
			addedIds.add(decoId);
		};
		for (const decoId of decoSet.top) {
			addSymbol(top, decoId);
		}
		for (const decoId of decoSet.bottom) {
			addSymbol(bottom, decoId);
		}
		return { top, bottom };
	}

	public static getDeco(name: DecoName, defaultPosition: boolean) {
		const decoInfo = Decoratives.getDecoInfo(name);
		// if(!pools[name]) {
		// 	pools[name] = new Pool(sub[`Deco_${name}`]);
		// }
		const symbol = pools[name].get();
		symbol.decoName = name;
		if (defaultPosition) {
			symbol.x = decoInfo.defaultX;
			symbol.y = decoInfo.defaultY;
			symbol.angle = decoInfo.defaultAngle;
			symbol.scale.set(decoInfo.defaultScale);
		}
		return symbol;
	}

	public static getDecoInfo(decoName: DecoName) {
		if (decoInfos[decoName]) {
			return decoInfos[decoName];
		}
		const container = new sub['DecoDefault_' + decoName]() as Container;
		const child = container.children[0] as DecoSymbol;
		const basicInfo = decoBasicInfos[decoName];
		const defaultType = basicInfo.type || 'gear';
		child.decoName = decoName;
		const defaultX = child.x;
		const defaultY = defaultType === 'eyewear' ? child.y - EYE_Y :
			defaultType === 'mouthpiece' ? child.y - MOUTH_Y :
				child.y;
		const defaultScale = Math.min(.5, child.scale.x);
		const defaultAngle = child.angle || child.skew.y / Math.PI * 180;
		child.skew.set(0);
		const splitted = child.children.length > 1;
		const name = basicInfo.name || decoName.replace(/_/g, ' ');
		decoInfos[decoName] = {
			defaultX, defaultY, defaultScale, defaultAngle, splitted, defaultType, decoName, name,
		};
		Decoratives.pool(decoName, child);
		return decoInfos[decoName];
	}

	public static pool(name: DecoName, symbol: DecoSymbol) {
		if (!pools[name]) {
			pools[name] = new Pool(sub[`Deco_${name}`]);
		}
		for (const child of symbol.children) {
			child.visible = true;
		}
		pools[name].pool(symbol);
	}

}
