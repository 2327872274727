import { BLEND_MODES, Container, Graphics, Sprite, Ticker } from 'pixi.js';
import { MiscSymbol } from '../../skills/miscSymbol';
import { RoomControl } from '@/client/controls/roomControl';
import { Interpolatable, MiscData, MiscDataBiMechguin, MiscDataBiMechguinLaserGun, MiscDataBiMechguinMissile, MiscDataBiMechguinTurret, MiscDataBiMechguinWing, MiscType } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { MovieClip } from 'pixi-animate';
import Factory from '@/client/factory';
import { Pool } from '@/util/pool';
import { MechguinLaserGunState, MechguinMissileState, MechguinState, MechguinTurretState, MechguinWingState } from '@/game/infos/boss/xmasPenguinInfos';
import { Particle, disposeParticle, getParticle } from '@/client/factory/particles';
import { Ease, Tween } from '@/util/tweents';
import { Vector } from 'matter-js';
import { Point, Rotate } from '@/util/rotate';
import Collision from '@/util/collide';
import { Preset } from '@/game/infos/preset';
import settingx from '@/store/modules/settingx';
import { BossLabelSymbol } from '../../playerLabelMixin';
import { BgmManager } from '@/client/sound/BgmManager';
import { wait } from '@/util/wait';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';

export class Iceberg extends Container { }
export class MechguinExplosion extends MovieClip { }
export class Mechguin extends Container implements MiscSymbol {
	public get turretPointRight() {
		return this._turretPointRight;
	}
	public set turretPointRight(value) {
		if (this._turretPointRight === value) {
			return;
		}
		const turretIndex = 5;
		this._turretPointRight = value;
		if (value) {
			this.addChild(
				this.turret2,
				this.turret1,
				this.turret0,
				this.missileContainer,
			);
		} else {
			this.addChild(
				this.turret0,
				this.turret1,
				this.turret2,
				this.missileContainer,
			);
		}
	}

	public static get() {
		const symbol = Mechguin._pool.get();
		return symbol;
	}

	private static _pool: Pool<Mechguin> = new Pool(Mechguin);
	public head = Factory.get(MechguinHead);
	public headSupport = Factory.get(MechguinHeadSupport);
	public body = Factory.get(MechguinBody);
	public laserGun = Factory.get(MechguinLaserGun);
	public laserGunHand = Factory.get(MechguinLaserGunHand);
	public wing = Factory.get(MechguinWing);
	public wheels = Factory.get(MechguinWheels);
	public backDoor = Factory.get(MechguinBackDoor);
	public turret0 = Factory.get(MechguinTurret);
	public turret1 = Factory.get(MechguinTurret);
	public turret2 = Factory.get(MechguinTurret);
	public ambulance = Factory.get(MechguinFaintedCaptain);
	public flag = Factory.get(MechguinFlag);
	public missileContainer = new Container();
	public data: MiscDataBiMechguin | null = null;
	public label = Factory.get(BossLabelSymbol);

	private _turretPointRight = true;
	private snowTime = 0;
	constructor() {
		super();
		this.addChild(
			this.laserGun.line,
			this.backDoor,
			this.laserGunHand,
			this.laserGun,
			this.headSupport,
			this.body,
			this.head,
			this.wheels,
			this.wing,
			this.turret2,
			this.turret1,
			this.turret0,
			this.missileContainer,
		);
		this.label.setName('Mechguin V-55');
	}

	public dispose() {
		this.body.reset();
		this.wing.reset();
		this.laserGun.reset();
		this.flag.gotoAndPlay(0);
		this.ambulance.parent?.removeChild(this.ambulance);
		Tween.get(this.flag, { override: true }).to({ angle: 0 });
		this.data = null;
		Mechguin._pool.pool(this);
		this.parent?.removeChild(this);
		this.flag.parent?.removeChild(this.flag);
		this.label?.parent.removeChild(this.label);
		this.snowTime = 0;
	}
	public explode(room: RoomControl, duration: number) {
		const endTime = Date.now() + duration;
		const makeExplosion = () => {
			const boom = Factory.get(MechguinExplosion);
			boom.x = Math.random() * 420 + this.body.x - 210;
			boom.y = Math.random() * 560 + this.body.y - 280;
			boom.gotoAndPlay(0);
			room.playerTopContainer.addChild(boom);
			const volume = (1300 - Rotate.dist(boom.x, boom.y, -room.symbol.x, -room.symbol.y)) / 1300;
			new SoundEfx('mechguinExplode').play({ volume });

			if (Date.now() < endTime) {
				setTimeout(makeExplosion, 200);
			}

		};
		makeExplosion();
	}
	public update(room: RoomControl, dt: MiscData, time: number, state: ViewState) {
		const data = dt as MiscDataBiMechguin;
		if (this.data) {
			if (data.sk !== this.data.sk) {
				this.body.shiftGear();
			}
			if (data.ff !== this.data.ff) {
				this.body.burn(data.ff);
			}
			if (data.stt !== this.data.stt) {
				if (data.stt === MechguinState.Weak) {
					this.body.weaken();
					Tween.get(this.flag, { override: true }).to({ angle: 10 }, 3000, Ease.elasticOut);
					this.explode(room, 400);

					new SoundEfx('mechguinGlassbreak').play({ volume: (1300 - Rotate.dist(state.x, state.y, -room.symbol.x, -room.symbol.y)) / 1300 });
				} else if (data.stt === MechguinState.Dead) {
					this.explode(room, 3000);
					new SoundEfx('mechguinGlassbreak').play({ volume: (1300 - Rotate.dist(state.x, state.y, -room.symbol.x, -room.symbol.y)) / 1300 });
					this.body.dead();
					Tween.get(this.flag, { override: true }).to({ angle: 10 }, 1000, Ease.elasticOut).wait(400).call(() => {
						this.flag.gotoAndPlay('dead');
					});
					Tween.get(BgmManager.currentBgm, { override: true }).to(
						{ volume: 0.2 },
						500,
					);
				} else if (data.stt === MechguinState.Empty) {
					room.playersContainer.addChild(this.ambulance);
					this.ambulance.x = this.data.x + 23;
					this.ambulance.y = this.data.y - 20;
				} else if (data.stt === MechguinState.Active) {
					Tween.get(this.flag, { override: true }).to({ angle: 0 }, 500, Ease.sineIn);
					this.flag.gotoAndPlay(0);
					// this.body.gotoAndStop('gear2');
					if (!data.ff) {
						this.body.fireContainer.removeChildren();
					}
				}
			}
			if (data.stt === MechguinState.Stunnable) {
				if (data.rp !== this.data.rp) {
					this.body.gotoAndPlay('repair' + data.rp);
					this.body.play();
				}
				if (data.stun !== this.data.stun) {
					if (data.stun === 0) {
						this.body.gotoAndPlay('repair' + data.rp);
						this.body.play();
					} else {
						this.body.gotoAndPlay('confuse' + (data.stun - 1));
						this.body.play();
					}
				}
			} else if (data.stt === MechguinState.Empty) {
				this.ambulance.x += Ticker.shared.elapsedMS * 0.6;
				this.ambulance.alpha = Math.min(1, (2560 - this.ambulance.x) / 100);
				if (this.ambulance.x > 2560) {
					this.ambulance.parent?.removeChild(this.ambulance);
				}
			}
		} else {
			this.label.maxHps = data.mhps;
		}

		let i = 0;
		const headData = data.cs[i++];
		this.head.update(room, headData, time, state);
		this.headSupport.update(room, headData, time, state);
		const bodyData = data.cs[i++];
		this.body.update(room, bodyData, time, state);
		const laserGunData = data.cs[i++];
		this.laserGun.update(room, laserGunData as MiscDataBiMechguinLaserGun, time, state);
		this.laserGunHand.update(laserGunData, bodyData);
		this.wing.update(room, data.cs[i++] as MiscDataBiMechguinWing, time, state);
		this.wheels.update(room, data.cs[i++], time, state);
		this.backDoor.update(room, data.cs[i++], time, state);
		this.turret0.update(room, data.cs[i++] as MiscDataBiMechguinTurret, time, state);
		this.turret1.update(room, data.cs[i++] as MiscDataBiMechguinTurret, time, state);
		this.turret2.update(room, data.cs[i++] as MiscDataBiMechguinTurret, time, state);
		this.turretPointRight = ((this.turret1.angle - this.wing.angle + 90) % 360 + 360) % 360 < 180;

		this.flag.x = bodyData.x + 128;
		this.flag.y = bodyData.y - 6;

		let labelHp = data.hp;
		for (let hh = data.stg + 1; hh < data.mhps.length; hh++) {
			labelHp += data.mhps[hh];
		}
		this.label.setHp(data.hp, data.stg);
		this.label.x = bodyData.x;
		this.label.y = bodyData.y - 390;

		this.data = data;

		const makeSnow = (position = 0) => {

			const snow = getParticle(Particle.LegendaryItemBling);
			snow.scale.set(Math.random() * .7 + .3);
			snow.alpha = snow.scale.x;
			snow.tint = 0xFFFFFF;
			snow.x = Math.random() * 380 + 116;
			snow.y = 0;
			const life = 3000 * Math.random() + 3000;
			Tween.get(snow).wait(life - 500).to({ alpha: 0 }, 500).setPosition(life * position);
			Tween.get(snow).to({ y: snow.y + 500 + Math.random() * 200, angle: life * 0.05 }, life).call(
				() => {
					room.snowParticlesContainer.removeChild(snow);
					snow.scale.set(1);
					snow.x = snow.y = snow.angle = 0;
					snow.alpha = 1;
					snow.tint = 0xffffff;
					disposeParticle(Particle.LegendaryItemBling, snow);
				},
			).setPosition(life * position);
			room.snowParticlesContainer.addChild(snow);
		};
		if (this.snowTime === 0) {
			const snowCount = settingx.now.hideParticles ? 20 : 40;
			for (let ii = 0; ii < snowCount; ii++) {
				makeSnow(ii / (snowCount + 1));
			}

			this.snowTime = time + (settingx.now.hideParticles ? 200 : 100);
		} else if (time >= this.snowTime) {

			this.snowTime = time + (settingx.now.hideParticles ? 200 : 100);
			makeSnow();
		}

	}

	public show(room: RoomControl) {
		room.bossContainer.addChild(this);
		room.playerBottomContainer.addChild(this.flag);
		room.playerLabelsContainer.addChild(this.label);
	}
	public hide(room: RoomControl) {
		this.parent?.removeChild(this);
		this.flag.parent?.removeChild(this.flag);
		room.playerLabelsContainer.addChild(this.label);
	}
}

export class MechguinBackDoor extends Container {
	public update(room: RoomControl, data: Interpolatable, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;
	}
}
export class MechguinHead extends Container {
	public update(room: RoomControl, data: Interpolatable, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;
	}
}
export class MechguinHeadSupport extends Container {
	public update(room: RoomControl, data: Interpolatable, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;
	}
}
export class MechguinLaserGunHand extends Container {
	public update(data: Interpolatable, bodyPosition: Point) {
		this.x = data.x;
		this.y = data.y;
		this.angle = Rotate.degree(this.x, this.y, bodyPosition.x - 85, bodyPosition.y - 44) - 90;
	}
}
export class MechguinFaintedCaptain extends MovieClip {
}
export class MechguinFlag extends MovieClip {
}
export class MechguinBody extends MovieClip {
	public fire1!: MovieClip;
	public fire2!: MovieClip;
	public fireContainer!: Container;
	public isGear2 = false;
	public init() {
		this.gotoAndStop('gear2');
		this.fire1 = this.fireContainer.children[0] as MovieClip;
		this.fire2 = this.fireContainer.children[1] as MovieClip;
		this.fireContainer.removeChildren();
	}
	public update(room: RoomControl, data: Interpolatable, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;
	}
	public shiftGear() {
		const newGear = this.isGear2 ? '1' : '2';
		this.isGear2 = !this.isGear2;
		this.gotoAndPlay(`gear` + newGear);
		this.play();
	}
	public weaken() {
		const currentGear = this.isGear2 ? 'gear2' : 'gear1';
		this.gotoAndPlay(currentGear + 'Bang');
		this.play();
		this.isGear2 = false;
	}
	public dead() {
		const currentGear = this.isGear2 ? 'gear2' : 'gear1';
		this.gotoAndPlay(currentGear + 'Over');
		this.play();
		this.isGear2 = false;
		this.burn(1);
	}
	public burn(value: number) {
		if (value) {
			this.fireContainer.addChild(this.fire1, this.fire2);
		} else {
			this.fireContainer.removeChildren();
		}
	}
	public reset() {
		this.gotoAndStop('gear2');
		this.fireContainer.removeChildren();
		this.isGear2 = false;
	}
}
export class MechguinTurret extends Container {
	public highlight!: Sprite;
	public data: MiscDataBiMechguinTurret | null = null;
	public reset() {
		this.data = null;
		Tween.get(this.highlight, { override: true }).to({ alpha: 0 });
	}
	public init() {
		this.highlight = this.children[1] as Sprite;
		this.highlight.alpha = 0;
	}
	public update(room: RoomControl, data: MiscDataBiMechguinTurret, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;
		if (this.data) {

			if (data.stt !== this.data.stt) {
				if (data.stt === MechguinTurretState.Firing) {
					Tween.get(this.highlight, { override: true }).to({ alpha: 1 }, 400);
				} else {
					if (this.highlight.alpha !== 0) {
						Tween.get(this.highlight, { override: true }).to({ alpha: 0 }, 100);
					}
				}
			}
		}
		this.data = data;
	}
}
export class MechguinWing extends MovieClip {
	public data: MiscDataBiMechguinWing | null = null;
	public snowContainer = new Container();
	public init() {
		this.gotoAndStop('ready');
		this.addChild(this.snowContainer);
	}
	public reset() {
		this.data = null;
	}
	public update(room: RoomControl, data: MiscDataBiMechguinWing, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;

		if (this.data) {
			if (this.data.ms === 0 && data.ms === 1) {
				this.gotoAndPlay('spawnMissile');
				new SoundEfx('mechguinMissileLoad').play({ volume: (1300 - Rotate.dist(data.x, data.y, -room.symbol.x, -room.symbol.y)) / 1300 });
			} else if (this.data.ms === 1 && data.ms === 0) {
				this.gotoAndStop('noMissile');
			}
		} else {
			this.gotoAndStop(data.ms === 0 ? 'noMissile' : 'ready');
		}
		if (data.stt === MechguinWingState.Snowing) {
			const x = -165;
			const y = Math.random() * 122 - 54;
			const snow = getParticle(Particle.Circle);
			snow.scale.set(Math.random() * .1 + .1);
			snow.tint = 0xFFFFFF;
			snow.alpha = 1;
			snow.x = x;
			snow.y = y;
			Tween.get(snow).to({ x: x - 250 }, 200).call(
				() => {
					this.snowContainer.removeChild(snow);
					snow.scale.set(1);
					snow.x = snow.y = 0;
					snow.alpha = 1;
					snow.tint = 0xffffff;
					disposeParticle(Particle.Circle, snow);
				},
			);
			this.snowContainer.addChild(snow);
		}
		this.data = data;
	}
}
export class MechguinWheels extends MovieClip {
	public update(room: RoomControl, data: Interpolatable, time: number, state: ViewState) {
		if (data.x !== this.x) {
			const frame = (Math.floor(-this.x / 0.25) % 119 + 119) % 119;
			this.gotoAndStop(frame);
			this.x = data.x;
		}
		this.y = data.y;
		this.angle = data.d || 0;
	}
}
export class MechguinMissile extends MovieClip implements MiscSymbol {
	public data: MiscDataBiMechguinMissile | null = null;
	public dispose() {
		this.parent?.removeChild(this);
		this.data = null;
	}
	public update(room: RoomControl, data: MiscData, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;
		const dt = data as MiscDataBiMechguinMissile;
		if (!this.data) {
			this.gotoAndStop('idle');
		} else {
			if (this.data.stt === MechguinMissileState.PointUp && dt.stt === MechguinMissileState.LauchWater) {
				new SoundEfx('mechguinMissileLaunch').play({ volume: (1300 - Rotate.dist(data.x, data.y, -room.symbol.x, -room.symbol.y)) / 1300 });
			} else if (this.data.stt === MechguinMissileState.LauchWater && dt.stt === MechguinMissileState.ExitWater) {
				this.gotoAndPlay('exitWater');
				new SoundEfx('mechguinMissileSeparate').play({ volume: (1300 - Rotate.dist(data.x, data.y, -room.symbol.x, -room.symbol.y)) / 1300 });

			} else if (this.data.stt === MechguinMissileState.ExitWater && dt.stt !== MechguinMissileState.ExitWater) {
				this.gotoAndPlay('other');
				new SoundEfx('mechguinMissilePush').play({ volume: (2000 - Rotate.dist(data.x, data.y, -room.symbol.x, -room.symbol.y)) / 2000 });
			}
		}
		this.data = dt;
	}
	public show(room: RoomControl) {
		const parent = (room.allMiscs[this.data!.bid] as Mechguin)?.missileContainer || room.bossContainer;
		parent.addChild(this);
	}
	public hide(room: RoomControl) {
		this.parent?.removeChild(this);
	}
}

export class MechguinLaserGun extends MovieClip {
	public data: MiscDataBiMechguinLaserGun | null = null;
	public line = new Graphics();
	public sound = new LongSoundEfx('laserBeam');
	public highlight!: Sprite;
	public init() {
		this.gotoAndStop('ready');
		this.highlight = this.children[1] as Sprite;
		this.highlight.blendMode = BLEND_MODES.ADD;
	}
	public reset() {
		this.gotoAndStop('ready');
		this.data = null;
		this.line.clear();
		Tween.get(this.highlight, { override: true }).to({ alpha: 0 });
		this.sound.stop();
	}
	public update(room: RoomControl, data: MiscDataBiMechguinLaserGun, time: number, state: ViewState) {
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d || 0;
		this.line.visible = data.stt === MechguinLaserGunState.Preparing;
		if (this.line.visible) {
			const targetRadian = data.d / 180 * Math.PI;
			const startPoint = Vector.add(Rotate.move(targetRadian, -202), data);
			let endPoint = Vector.add(Rotate.move(targetRadian, -100000), data);
			const floor = 0.5 * Preset.SECTOR_SIZE;
			endPoint = Collision.lineLine(startPoint.x, startPoint.y, endPoint.x, endPoint.y, 0, -100000000, 0, 100000000, true) as Point;
			if (endPoint.y > floor) {
				endPoint = Collision.lineLine(startPoint.x, startPoint.y, endPoint.x, endPoint.y, -100000000, floor, 100000000, floor, true) as Point;
			}
			this.line.clear();
			this.line.lineStyle({ color: 0xff0000, alpha: 0.5, width: 3 });
			this.line.moveTo(startPoint.x, startPoint.y);
			this.line.lineTo(endPoint.x, endPoint.y);
		}
		if (data.stt >= MechguinLaserGunState.Preparing) {

			const volume = (2000 - Rotate.dist(data.x, data.y, state.x, state.y)) / 2000;
			this.sound.volume = volume;
		}
		if (this.data) {
			if (data.stt !== this.data.stt) {
				if (data.stt === MechguinLaserGunState.Preparing) {
					this.line.visible = true;
					Tween.get(this.highlight, { override: true }).to({ alpha: 1 }, 1500);
					this.sound.start();
				} else if (data.stt !== MechguinLaserGunState.Preparing) {
					this.line.visible = false;
					if (data.stt === MechguinLaserGunState.Returning) {
						this.sound.stop();
						Tween.get(this.highlight, { override: true }).to({ alpha: 0.4 }, 1000);
					} else if (data.stt <= MechguinLaserGunState.Weak) {
						Tween.get(this.highlight, { override: true }).to({ alpha: 0 }, 1000);
					} else if (data.stt === MechguinLaserGunState.Active) {
						Tween.get(this.highlight, { override: true }).to({ alpha: 0.4 }, 1000);
					}
				}
			}
		}
		this.data = data;
	}
}
