


















import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseInput from '@/components/inputs/BaseInput.vue';

@Component
export default class PinInput extends BaseInput {
  @Prop({ default: true }) public readonly showInvalid!: boolean;
  @Prop({ default: '' }) public readonly placeholder!: string;

  public input: string = '';

  get invalidError() {
    return `Invalid Passcode.`;
  }

  public validate(value: string) {
    if (value === null) {
      return false;
    }
    if (value.length === 0) { return true; }
    if (value.length !== 4) { return false; }
    if (/\D/.test(value)) { return false; }
    return true;
  }
}
