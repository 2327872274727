


























































import gamex, { ChatMessage } from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Dictionary } from '@/util/dictionary';

@Component({
  components: {},
})
export default class ChatLogs extends Vue {
  @Prop({ default: () => [] }) public chatLogs!: ChatMessage[];
  @Prop({ default: () => {} }) public users!: Dictionary<{
    name: string;
    muted?: boolean;
    mutedYou?: boolean;
    mutedAll?: boolean;
  }>;
}
