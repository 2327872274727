import { MovieClip } from 'pixi-animate';
import { Container } from 'pixi.js';

export class DpadSymbol extends MovieClip {

	public handle!: Container;
	public bg!: Container;


	public init() {

	}
}
