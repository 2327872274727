













import { ItemCode } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { addQuarterField, subQuarterField } from './poqFunctions';

@Component({
  components: {},
})
export default class BlackPearlField extends Vue {
  public baseUrl = process.env.BASE_URL;

  public onShop() {
    Global.shopModal.show();
  }
  public get gem() {
    return addComma(userx.inventory.availables[ItemCode.Gem] || 0);
  }
}
