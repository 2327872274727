import { RoomControl } from '@/client/controls/roomControl';
import { MiscData, MiscDataMcHazardCloud } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { Rotate } from '@/util/rotate';

export class ChargedCloudSymbol extends MovieClip {

}
export class FrostCloudSymbol extends MovieClip {

}
export class EmberCloudSymbol extends MovieClip {

}
// const info = specialSkillInfos[SpecialSkill.SwimmingFang];

export class McHazardCloudSymbolController implements MiscSymbol {

	public static get() {
		return McHazardCloudSymbolController._pool.get();
	}

	private static _pool: Pool<McHazardCloudSymbolController> = new Pool(McHazardCloudSymbolController);


	public data: MiscDataMcHazardCloud | null = null;
	public isSameTeam = false;

	public symbol: Container = new Container();

	public hazard: EmberCloudSymbol | FrostCloudSymbol | null = null;
	public chargedCloud: ChargedCloudSymbol | null = null;
	public constructor() {
		this.symbol.scale.set(6);
	}

	public dispose(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}

		if (this.chargedCloud) {
			this.symbol.removeChild(this.chargedCloud);
			this.chargedCloud.x = 0;
			this.chargedCloud.y = 0;
			this.chargedCloud.angle = 0;
			this.chargedCloud.scale.set(1);
			Factory.pool(ChargedCloudSymbol, this.chargedCloud);
			this.chargedCloud = null;
		}
		if (this.hazard) {
			this.symbol.removeChild(this.hazard);
			if (this.data) {
				const hazardClass = this.data.ff === -1 ? FrostCloudSymbol : EmberCloudSymbol;
				this.hazard.x = 0;
				this.hazard.y = 0;
				this.hazard.angle = 0;
				this.hazard.scale.set(1);
				Factory.pool(hazardClass, this.hazard);
			}
			this.hazard = null;
		}
		this.data = null;

		McHazardCloudSymbolController._pool.pool(this);
	}

	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataMcHazardCloud;
		if (!this.data) {
			room.playerTopContainer.addChild(this.symbol);

			if (data.pr) {
				this.chargedCloud = Factory.get(ChargedCloudSymbol);
				this.symbol.addChild(this.chargedCloud);
				this.chargedCloud.gotoAndPlay(0);
			}
			if (data.ff !== 0) {

				const hazardClass = data.ff === -1 ? FrostCloudSymbol : EmberCloudSymbol;
				this.hazard = Factory.get(hazardClass);
				this.symbol.addChild(this.hazard);
				this.hazard.gotoAndPlay(0);
			}
			new SoundEfx('poof').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .6 });
		}
		this.symbol.x = data.x;
		this.symbol.y = data.y;

		this.data = data;
	}
	public show(room: RoomControl) {
		if (this.data) {
			room.playerTopContainer.addChild(this.symbol);
		}
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
	}
}
