import uuid from 'uuid';

export interface ValueRarityPair<T> {
	value: T,
	rarity: number,
}

class Random {

	public v4 = () => uuid.v4();
	public v1 = () => uuid.v1();
	public firestore(): string {
		// Alphanumeric characters
		const chars =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let autoId = '';
		for (let i = 0; i < 20; i++) {
			autoId += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return autoId;
	}
	public six(): string {
		const chars =
			'abcdefghijklmnopqrstuvwxyz0123456789';
		let autoId = '';
		for (let i = 0; i < 6; i++) {
			autoId += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return autoId;
	}
	public five(): string {
		const chars =
			'abcdefghijklmnopqrstuvwxyz0123456789';
		let autoId = '';
		for (let i = 0; i < 5; i++) {
			autoId += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		return autoId;
	}
	public choose<T>(array: T[]): T {
		return array[Math.floor(Math.random() * array.length)];
	}

	public chooseAndSplice<T>(array: T[]): T {
		const index = Math.floor(Math.random() * array.length);
		return array.splice(index, 1)[0];
	}

	public rarity<T>(list: Array<ValueRarityPair<T>>, raritySum = 1) {
		const r = Math.random() * raritySum;
		let count = 0;
		for (const object of list) {
			count += object.rarity;
			if (r < count) {
				return object.value;
			}
		}
		return list[0].value;
	}

	public int(length: number) {
		return Math.floor(Math.random() * length);
	}
}

export default new Random();
