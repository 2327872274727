































































import FishCanvasComponent from '@/components/canvas/FishCanvasComponent.vue';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { fishUnlockRequirements } from '@/game/infos/fishUnlockRequirements';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { floorToFixed, roundToFixed } from '@/util/number';
import { BModal } from 'bootstrap-vue';
import uuid from 'uuid';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FishIntelData } from '../mainMenu/tools/fishSelector/FishIntelTooltip.vue';
import FishUnlockStepsComponent from '../mainMenu/tools/fishSelector/FishUnlockStepsComponent.vue';
import StateButton from '@/components/inputs/StateButton.vue';

import { EditState } from '@/store/models.def';
@Component({
  components: {
    FishCanvasComponent,
    FishUnlockStepsComponent,
    StateButton,
  },
})
export default class FishUnlockModal extends Vue {
  public unlockState: EditState = 'active';
  public get unlockLabel() {
    const labels = {
      active: 'Unlock Now',
      sending: 'Unlocking',
      done: 'Unlocked',
    };
    return labels[this.unlockState];
  }
  public get unlockProgress() {
    return floorToFixed(userx.fishUnlockProgress[this.fishType] * 100, 1);
  }
  public get desc() {
    return fishInfos[this.fishType].desc;
  }
  public get fishName() {
    return fishInfos[this.fishType].name;
  }
  public get tier() {
    return fishInfos[this.fishType].tier;
  }
  public get unlocked() {
    return userx.fishUnlocked[this.fishType];
  }
  public get readyToUnlock() {
    return userx.pendingFishUnlocks[this.fishType];
  }

  public get steps() {
    return fishUnlockRequirements[this.fishType];
  }
  public fishType: FishType = 1;
  @Prop({ default: '' }) public container!: string;

  public canvasId = uuid.v4();

  public created() {
    Global.fishUnlockModal = this;
  }

  public show(fishType: FishType) {
    this.fishType = fishType;
    this.unlockState = 'active';
    (this.$refs.myModal as BModal).show();

    const newlyCompleted = userx.userSettings.completedChallenges.filter(
      (s) => s.indexOf(`F-${fishType}-`) === 0,
    );
    setTimeout(() => {
      userx.readUnlock(newlyCompleted);
    }, 500);
  }
  public showFishIntel() {
    const data: FishIntelData = {
      targetId: this.canvasId,
      placement: 'left',
      container: this.container,
      fishType: this.fishType,
    };
    this.$root.$emit('showFishIntel', data);
  }
  public hideFishIntel() {
    this.$root.$emit('hideFishIntel');
  }
  public async onUnlock() {
    this.unlockState = 'sending';
    try {
      await userx.unlockFish(this.fishType);
      this.unlockState = 'done';
    } catch (error) {
      this.unlockState = 'active';
      this.$root.$emit('error', (error as any).message);
    }
  }
}
