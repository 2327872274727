import {
    getModule,
    Module,
    VuexModule,
    Mutation,
    Action,
} from 'vuex-module-decorators';
import store from '@/store';
import { Dictionary } from 'vue-router/types/router';
import { TipId, tipInfos } from '@/game/infos/tipInfos';
import userx from './userx';


@Module({
    namespaced: true,
    name: 'tipx',
    store,
    dynamic: true,
})
class TipModule extends VuexModule {
    public tipsRead: Dictionary<boolean> = {};
    public tipsToShow: Dictionary<boolean> = {};

    public get currentShowingTipId(): TipId {
        let currentPriority = Number.MAX_SAFE_INTEGER;
        let currentId = 0;

        const ct = Date.now();
        for (const tipId in this.tipsToShow) {
            if (Object.prototype.hasOwnProperty.call(this.tipsToShow, tipId)) {
                const toShow = this.tipsToShow[tipId];
                if (toShow) {
                    const info = tipInfos[tipId];
                    if (info.priority < currentPriority) {
                        currentPriority = info.priority;
                        currentId = Number(tipId);
                    }
                }
            }
        }

        return currentId;
    }
    @Mutation
    public m_updateTipsRead(tipsRead: Dictionary<boolean> = {}) {
        this.tipsRead = { ...tipsRead };
    }
    @Mutation
    public m_updateTipsToShow(tipsToShow: Dictionary<boolean> = {}) {
        this.tipsToShow = { ...tipsToShow };
    }

    @Action({ rawError: true })
    public async updateTipsRead(tipsRead: Dictionary<boolean> = {}) {
        this.m_updateTipsRead(tipsRead);
    }
    @Action({ rawError: true })
    public async readTip(tipId: TipId) {
        if (this.tipsRead[tipId]) {
            return;
        }
        const tipsRead = this.tipsRead;
        tipsRead[tipId] = true;
        this.m_updateTipsRead(this.tipsRead);
        userx.changeUserSettings({
            tipsRead,
        });
    }
    @Action({ rawError: true })
    public async closeTip(tipId: TipId) {
        this.readTip(tipId);
        if (this.tipsToShow[tipId]) {
            delete this.tipsToShow[tipId];
            this.m_updateTipsToShow(this.tipsToShow);
        }
    }
    @Action({ rawError: true })
    public async achieveTip(tipId: TipId) {
        this.readTip(tipId);
        setTimeout(() => {
            if (this.tipsToShow[tipId]) {
                delete this.tipsToShow[tipId];
                this.m_updateTipsToShow(this.tipsToShow);
            }
        }, 1000);
    }
    @Action({ rawError: true })
    public async showTip(tipId: TipId) {
        const tipsToShow = this.tipsToShow;
        tipsToShow[tipId] = true;
        this.m_updateTipsToShow(tipsToShow);
    }
}


export default getModule(TipModule);
