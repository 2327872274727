import { Global } from '@/store/globalz';
import random from '@/util/random';
import { wait } from '@/util/wait';
import { consoleTestResultHandler } from 'tslint/lib/test';
import { Portal } from '../portal';
declare const gtag: any;

declare const aiptag: {
    cmd: { display: any[], player: any[]; };
};
declare const aipDisplayTag: {
    display: (id: string) => void;
};
declare const adplayer: {
    startPreRoll: () => void;
    startRewardedAd: () => void;
};

type StabfishBannerAipId = 'stabfish2-io_300x250' |
    'stabfish2-io_468x60' |
    'stabfish2-io_336x280' |
    'stabfish2-io_728x90' |
    'stabfish2-io_970x250'
    ;

// let count = 0;

export class AipAds {
    static get instance() {
        AipAds._instance = AipAds._instance || new AipAds();
        return AipAds._instance;
    }
    private static _instance: AipAds;
    public adReady = { midgame: true, rewarded: false };
    public timeout: any = null;
    public lastAdId = '';
    public lastAdType?: 'midgame' | 'rewarded';
    private _waitingPromises: Array<{ resolve: (v?: any) => void, reject: (error: any) => void; }> = [];

    constructor() {
        (window as any).aipVideoAdCompleted = this.aipVideoAdCompleted.bind(this);
        (window as any).aipRewardedAdCompleted = this.aipRewardedAdCompleted.bind(this);
        (window as any).aipVideoAdRemoved = this.aipVideoAdRemoved.bind(this);
        // document.getElementById('logo')!.addEventListener('click', () => {
        //     count++;
        //     if (count === 5) {
        //         adplayer.startRewardedAd();
        //         count = 0;
        //     }
        // });
        // (window as any).requestVideoAd = this.requestVideoAd.bind(this);
        // (window as any).refreshBannerAd = this.refreshBannerAd.bind(this);
        // this.countDownVideoAd('midgame');
        this.countDownVideoAd('rewarded');
    }
    public countDownVideoAd(adType: 'midgame' | 'rewarded' = 'midgame') {
        this.adReady[adType] = false;
        if (adType === 'rewarded') {
            this.adReady[adType] = true;
        }
        setTimeout(() => {
            this.adReady[adType] = true;
        }, 1 * 60 * 1000);
    }
    public aipVideoAdCompleted() {
        // console.log('ad Completed')
        while (this._waitingPromises.length > 0) {
            const promise = this._waitingPromises.shift()!;
            promise.resolve();
        }
        this.countDownVideoAd('midgame');
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    public aipRewardedAdCompleted() {
        // console.log('ad Completed')
        while (this._waitingPromises.length > 0) {
            const promise = this._waitingPromises.shift()!;
            promise.resolve();
        }
        this.countDownVideoAd('rewarded');
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    public aipVideoAdRemoved() {
        // console.log('ad Removed')
        while (this._waitingPromises.length > 0) {
            const promise = this._waitingPromises.shift()!;
            promise.resolve();
        }
        this.countDownVideoAd(this.lastAdType);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }
    public async refreshBannerAd(id: StabfishBannerAipId) {
        // if (Portal.referral === 'kong' || window.location.host === 'localhost:8080') { return; }
        if (Global.adsdk !== 'aipads') { return; }
        console.log('refreshBannerAd', id);
        await wait(500);
        aiptag.cmd.display.push(function() { aipDisplayTag.display(id); });
    }

    public async requestVideoAd(adType: 'midgame' | 'rewarded' = 'midgame') {
        if (Global.adsdk !== 'aipads') { return; }
        // if (Portal.referral === 'kong' || window.location.host === 'localhost:8080') { return; }
        this.countDownVideoAd(adType);
        return new Promise((resolve, reject) => {
            this._waitingPromises.push({ resolve, reject });
            if (typeof adplayer !== 'undefined') {
                this.lastAdId = random.firestore();
                this.lastAdType = adType;
                aiptag.cmd.player.push(function() {
                    if (adType === 'midgame') {
                        adplayer.startPreRoll();
                    } else {
                        adplayer.startRewardedAd();
                    }
                });
                this.timeout = setTimeout(() => {
                    this.timeout = null;
                    const i = this._waitingPromises.findIndex((p) => p.resolve === resolve);
                    if (i !== -1) {
                        this._waitingPromises.splice(i, 1);
                        this.countDownVideoAd(adType);
                        resolve(new Error('requestAd Timeout'));
                    }
                }, 45 * 1000);
            } else {
                this.timeout = setTimeout(() => {
                    this.timeout = null;
                    const i = this._waitingPromises.findIndex((p) => p.resolve === resolve);
                    if (i !== -1) {
                        this._waitingPromises.splice(i, 1);
                        this.countDownVideoAd(adType);
                        resolve(new Error('requestAd Timeout'));
                    }
                }, 45 * 1000);
            }
        });
    }
}
(window as any).aipads = AipAds.instance;
