import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import { allTextureLoaded } from '@/client/factory/helper';
import { DecoSet } from '@/game/infos/decorativeInfos';
import { FishType } from '@/game/infos/fishInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import { Scene } from 'pixi-animate';
import { Container } from 'pixi.js';
import { Decoratives } from './decoEditor/decorative';

const fishApp = new Scene(
	500,
	500,
	{
		antialias: true,
		transparent: true,
		forceCanvas: true,
	},
	true,
);

const scale = 2;
const app = fishApp;
const w = app.view.width;
const h = app.view.height;

const stage = app.stage;
const container = new Container();
stage.addChild(container);
container.angle = 0;
container.y = h / 2 + 10;
container.x = w / 2;
container.scale.set(scale);
let symbol: PlayerSymbol;

export async function generateLargeFishImage(
	fishType: FishType,
	skinGroup: SkinGroup,
	decoSet: DecoSet | null,
) {
	if (!symbol) {
		symbol = PlayerSymbol.get();
		symbol.setAction('idle');
		container.addChild(symbol);
		symbol.miniSpike.visible = true;
		symbol.spear.visible = false;
	}
	if (!fishType) {
		fishType = 3;
	}
	// const bodyScale = fishInfos[fishType].stat.size;
	await symbol.setFishType(fishType, skinGroup);
	// symbol.setBodyScale(bodyScale);
	Decoratives.generateDeco(symbol, decoSet);
	await allTextureLoaded(symbol);
	app.start();
	app.render();
	app.stop();
	const result = app.view.toDataURL();
	return result;
}
