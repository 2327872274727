



























import { SoundEfx } from '@/client/sound/SoundEfx';
import { specialSkillInfos } from '@/game/infos/skills';
import {
  spearCost,
  spearLvlRequirements,
  spearSkills,
  SpearType,
  weaponDecs,
  weaponNames,
  weaponLongNames,
} from '@/game/infos/spearInfos';
import {
  CommandBuyEquipSpearAction,
  GameCommandType,
} from '@/game/multithread/command';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import settingx from '@/store/modules/settingx';
import { getBoolean, storeBoolean } from '@/util/number';
import { wait } from '@/util/wait';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { isQuestTime, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';


@Component({
  components: {},
})
export default class WeaponComponent extends Vue {
  @Prop({ default: 0 }) public spearType!: SpearType;

  //   public bought = this.spearType === 0 ? true : false;
  //   public equipped = this.spearType === 0 ? true : false;
  public interval: any = null;
  public index = 1;

  public mounted() {
    this.interval = setInterval(() => {
      this.index++;
      if (this.index >= 5) {
        this.index = 1;
      }
    }, 1000);
  }
  public beforeDestroy() {
    clearTimeout(this.interval);
  }

  public get notAvailable() {
    if (this.spearType === SpearType.HammerToy && !isQuestTime(TimeLimitedQuestCode.AprilFool)) {
      return true;
    }
    return false;
  }
  public get bought() {
    return getBoolean(gamex.currentGameState.spearsBought, this.spearType);
  }
  //   @Watch('serverBought', { immediate: true })
  //   public serverBoughtChanged(newVal: boolean, oldVal: boolean) {
  //     this.bought = newVal;
  //   }
  public get equipped() {
    return gamex.currentGameState.spearType === this.spearType;
  }
  //   @Watch('serverEquipped', { immediate: true })
  //   public serverEquippedChanged(newVal: boolean, oldVal: boolean) {
  //     this.equipped = newVal;
  //   }

  public get sufficient() {
    return gamex.currentGameState.scoreL >= this.cost;
  }

  public onEquip() {
    Global.clientCommunicator.stateManager.lastState.spearType = this.spearType;
    const command: CommandBuyEquipSpearAction = {
      type: GameCommandType.EquipSpear,
      st: this.spearType,
      sessionId: Global.clientCommunicator.sessionId,
    };
    Global.clientCommunicator.sendCommand(command);
    new SoundEfx('changeWeapon').play({ volume: 0.3 });
  }
  public onBuy() {
    Global.clientCommunicator.stateManager.lastState.spearsBought = storeBoolean(
      gamex.currentGameState.spearsBought,
      this.spearType,
      true,
    );
    const command: CommandBuyEquipSpearAction = {
      type: GameCommandType.BuySpear,
      st: this.spearType,
      sessionId: Global.clientCommunicator.sessionId,
    };
    Global.clientCommunicator.sendCommand(command);

    new SoundEfx('buyChaching').play();
    wait(500).then(() => {
      new SoundEfx(('polar' + Math.ceil(Math.random() * 4)) as any).play();
    });
  }

  public get name() {
    return weaponLongNames[this.spearType];
  }
  public get desc() {
    if (spearSkills[this.spearType]) {
      return specialSkillInfos[spearSkills[this.spearType]].desc;
    }
    return weaponDecs[this.spearType];
  }
  public get suffix() {
    return settingx.now.control === 'mouse' && spearSkills[this.spearType]
      ? ` [Press ${settingx.now.skill3}]`
      : '';
  }
  public get imgSrc() {
    return `/images/weapons/${weaponNames[this.spearType]}${this.index}.png`;
  }
  public get full() {
    return spearLvlRequirements[this.spearType][3];
  }
  public get cost() {
    return spearCost[this.spearType];
  }
}
