





























































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import settingx, {
  ActionKey,
  actionKeys,
  SettingsData,
} from '@/store/modules/settingx';
import ColorPickerButton from '../inputs/ColorPicker.vue';
import * as PIXI from 'pixi.js';
import globalx from '@/store/modules/globalx';
import gamex, { GameStage } from '@/store/modules/gamex';
import { Global } from '@/store/globalz';
import { ViewState } from '@/game/multithread/viewState';
import OreColorButton from '../inputs/OreColorButton.vue';
import { GameType } from '@/game/infos/roomInfos';
@Component({
  components: {
    ColorPickerButton,
    OreColorButton,
  },
})
export default class SettingsComponent extends Vue {
  public get actionKeys() {
    return actionKeys;
  }
  public get settings() {
    return settingx.now;
  }

  public get sidebarShown() {
    return settingx.sidebarShown;
  }

  public get gameStage() {
    return gamex.gameStage;
  }
  public get serverName() {
    if (!gamex.currentGameServer) {
      return '';
    }
    const split = gamex.currentGameServer.name.split('|');
    return this.gameType === GameType.Armageddon
      ? split[0] + '-' + split[1] + '-' + split[2] + '-' + split[3]
      : split[0] + '-' + split[1];
  }
  public get gameType() {
    return gamex.gameType;
  }

  public get gameState() {
    return gamex.currentGameState;
  }
  public show = false;
  public dpi = settingx.now.dpi;
  public control = settingx.now.control;
  public frameRate = settingx.now.frameRate;
  public detailsLevel = settingx.now.detailsLevel;
  public canvasSize = settingx.now.canvasSize;
  public antialias = settingx.now.antialias;
  public variant = 'secondary';
  public rightHanded = !settingx.now.leftHanded || false;

  public armageddonFilter = settingx.now.armageddonFilter || false;
  public cameraShake = !settingx.now.cameraShakeOff || false;
  public hideEnemyDecos = settingx.now.hideEnemyDecos || false;
  public hideTeamDecos = settingx.now.hideTeamDecos || false;
  public hideMyDecos = settingx.now.hideMyDecos || false;
  public hideEnemySkin = settingx.now.hideEnemySkin || false;
  public hideTeamSkin = settingx.now.hideTeamSkin || false;
  public hideMySkin = settingx.now.hideMySkin || false;
  public hideParticles = settingx.now.hideParticles || false;
  public hideDmgNums = settingx.now.hideDmgNums || false;
  public hideShadows = settingx.now.hideShadows || false;

  public whiteOre = PIXI.utils.hex2string(settingx.now.whiteOre);
  public yellowOre = PIXI.utils.hex2string(settingx.now.yellowOre);
  public greenOre = PIXI.utils.hex2string(settingx.now.greenOre);
  public blueOre = PIXI.utils.hex2string(settingx.now.blueOre);
  public purpleOre = PIXI.utils.hex2string(settingx.now.purpleOre);
  public redOre = PIXI.utils.hex2string(settingx.now.redOre);

  public color1 = PIXI.utils.hex2string(settingx.now.color1);
  public color2 = PIXI.utils.hex2string(settingx.now.color2);
  public color3 = PIXI.utils.hex2string(settingx.now.color3);
  public color4 = PIXI.utils.hex2string(settingx.now.color4);
  public serverCloseTime = '';
  public vultrMaintenance = false;

  public skill1: ActionKey = settingx.now.skill1;
  public skill2: ActionKey = settingx.now.skill2;
  public skill3: ActionKey = settingx.now.skill3;
  public skill1Old: ActionKey = settingx.now.skill1;
  public skill2Old: ActionKey = settingx.now.skill2;
  public skill3Old: ActionKey = settingx.now.skill3;
  public wpnROld: ActionKey = settingx.now.wpnR;
  public wpnLOld: ActionKey = settingx.now.wpnL;
  public wpnR: ActionKey = settingx.now.wpnR;
  public wpnL: ActionKey = settingx.now.wpnL;

  public bgm = settingx.now.bgm;
  public sfx = settingx.now.sfx;
  public bgmMuted = settingx.now.bgmMuted;
  public sfxMuted = settingx.now.sfxMuted;
  public depthFilter = settingx.now.depthFilter;

  @Watch('settings', { deep: true })
  public settingsChanged(newVal) {
    this.control = newVal.control;
    this.dpi = newVal.dpi;
    this.frameRate = newVal.frameRate;
    this.detailsLevel = newVal.detailsLevel;
    this.canvasSize = newVal.canvasSize;
    this.antialias = newVal.antialias;
    this.rightHanded = !(newVal.leftHanded || false);

    this.armageddonFilter = newVal.armageddonFilter || false;
    this.cameraShake = !newVal.cameraShakeOff || false;
    this.hideEnemyDecos = newVal.hideEnemyDecos || false;
    this.hideTeamDecos = newVal.hideTeamDecos || false;
    this.hideMyDecos = newVal.hideMyDecos || false;
    this.hideEnemySkin = newVal.hideEnemySkin || false;
    this.hideTeamSkin = newVal.hideTeamSkin || false;
    this.hideMySkin = newVal.hideMySkin || false;
    this.hideParticles = newVal.hideParticles || false;
    this.hideDmgNums = newVal.hideDmgNums || false;
    this.hideShadows = newVal.hideShadows || false;

    this.color1 = PIXI.utils.hex2string(newVal.color1);
    this.color2 = PIXI.utils.hex2string(newVal.color2);
    this.color3 = PIXI.utils.hex2string(newVal.color3);
    this.color4 = PIXI.utils.hex2string(newVal.color4);

    this.whiteOre = PIXI.utils.hex2string(newVal.whiteOre);
    this.yellowOre = PIXI.utils.hex2string(newVal.yellowOre);
    this.greenOre = PIXI.utils.hex2string(newVal.greenOre);
    this.redOre = PIXI.utils.hex2string(newVal.redOre);
    this.purpleOre = PIXI.utils.hex2string(newVal.purpleOre);
    this.blueOre = PIXI.utils.hex2string(newVal.blueOre);

    this.skill1 = this.skill1Old = newVal.skill1;
    this.skill2 = this.skill2Old = newVal.skill2;
    this.skill3 = this.skill3Old = newVal.skill3;
    this.wpnR = this.wpnROld = newVal.wpnR;
    this.wpnL = this.wpnLOld = newVal.wpnL;

    this.bgmMuted = newVal.bgmMuted || false;
    this.sfxMuted = newVal.sfxMuted || false;
    this.depthFilter = newVal.depthFilter || false;
    this.bgm = newVal.bgm || 0;
    this.sfx = newVal.sfx || 0;
  }
  @Watch('sidebarShown', { immediate: true })
  public sidebarShownChanged(newVal) {
    this.show = this.sidebarShown;
  }
  @Watch('show', { immediate: true })
  public showChanged(newVal) {
    if (settingx.sidebarShown !== this.show) {
      if (this.show) {
        settingx.show();
      } else {
        settingx.hide();
      }
    }
  }

  public onSkillKeyChanged(index: number) {
    const keys = ['skill1', 'skill2', 'skill3', 'wpnR', 'wpnL'];
    const currentKey = keys[index - 1];
    const oldKey = currentKey + 'Old';
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (i === index - 1) {
        continue;
      }
      if (this[key] === this[currentKey]) {
        this[key] = this[oldKey];
      }
    }
    this[oldKey] = this[currentKey];
    const submission: Partial<SettingsData> = {
      skill1: this.skill1,
      skill2: this.skill2,
      skill3: this.skill3,
      wpnL: this.wpnL,
      wpnR: this.wpnR,
    };
    settingx.updateSettings(submission);
  }

  public onSettingsChanged() {
    const submission: Partial<SettingsData> = {
      control: this.control,
      dpi: this.dpi,
      frameRate: this.frameRate,
      detailsLevel: this.detailsLevel,
      canvasSize: this.canvasSize,
      antialias: this.antialias,
      leftHanded: !this.rightHanded,

      depthFilter: this.depthFilter,

      armageddonFilter: this.armageddonFilter,
      cameraShakeOff: !this.cameraShake,
      hideEnemyDecos: this.hideEnemyDecos,
      hideTeamDecos: this.hideTeamDecos,
      hideMyDecos: this.hideMyDecos,
      hideEnemySkin: this.hideEnemySkin,
      hideTeamSkin: this.hideTeamSkin,
      hideMySkin: this.hideMySkin,
      hideParticles: this.hideParticles,
      hideDmgNums: this.hideDmgNums,
      hideShadows: this.hideShadows,

      color1: PIXI.utils.string2hex(this.color1),
      color2: PIXI.utils.string2hex(this.color2),
      color3: PIXI.utils.string2hex(this.color3),
      color4: PIXI.utils.string2hex(this.color4),

      whiteOre: PIXI.utils.string2hex(this.whiteOre),
      yellowOre: PIXI.utils.string2hex(this.yellowOre),
      greenOre: PIXI.utils.string2hex(this.greenOre),
      redOre: PIXI.utils.string2hex(this.redOre),
      purpleOre: PIXI.utils.string2hex(this.purpleOre),
      blueOre: PIXI.utils.string2hex(this.blueOre),
    };
    settingx.updateSettings(submission);
  }

  public onDefaultColor() {
    settingx.defaultColor();
  }

  public mounted() {
    this.$root.$on('settings', () => {
      this.show = !this.show;
    });
  }
  @Watch('gameStage')
  public gameStageChange(value: GameStage) {
    this.show = false;
  }
  @Watch('gameState', { immediate: true })
  public gameStateChanged(newVal: ViewState, oldVal: ViewState) {
    if (!oldVal || newVal.vultrMaintenance !== oldVal.vultrMaintenance) {
      this.vultrMaintenance = newVal.vultrMaintenance;
    }
    if (!oldVal || newVal.closeTime !== oldVal.closeTime) {
      const closeTime = newVal.closeTime;
      if (closeTime === 0) {
        this.serverCloseTime = 'Indefinite';
      } else {
        this.serverCloseTime = new Date(closeTime).toString();
      }
    }
  }

  public async onQuitGame() {
    if (await Global.stageControl.uiControl.onQuitGame()) {
      this.show = false;
    }
  }

  public get bgmVolumeButtonClass() {
    return this.bgmMuted
      ? 'mute text-brand-youtube'
      : Number(this.bgm) === 0
      ? 'off'
      : Number(this.bgm) > 0.5
      ? 'up'
      : 'down';
  }
  public triggerBgmMute() {
    this.bgmMuted = !this.bgmMuted;
    const submission: Partial<SettingsData> = {
      bgmMuted: this.bgmMuted,
    };
    settingx.updateSettings(submission);
  }
  public onBgmChanged() {
    const submission: Partial<SettingsData> = {
      bgm: this.bgm,
    };
    settingx.updateSettings(submission);
  }
  public get sfxVolumeButtonClass() {
    return this.sfxMuted
      ? 'mute text-brand-youtube'
      : Number(this.sfx) === 0
      ? 'off'
      : Number(this.sfx) > 0.5
      ? 'up'
      : 'down';
  }
  public triggerSfxMute() {
    this.sfxMuted = !this.sfxMuted;
    const submission: Partial<SettingsData> = {
      sfxMuted: this.sfxMuted,
    };
    settingx.updateSettings(submission);
  }
  public onSfxChanged() {
    const submission: Partial<SettingsData> = {
      sfx: this.sfx,
    };
    settingx.updateSettings(submission);
  }
}
