










































import {
  decoDic,
  DecoName,
  decoNames,
  DecoSet,
} from '@/game/infos/decorativeInfos';
import { ItemCode, ItemInfo, itemInfos } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { BModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import ItemInventory from '../../bag/ItemInventory.vue';
import { DecoItem, DecoSplitType } from '../decoEditor/decorative';
import SkinButton from '@/components/gacha/SkinButton.vue';
import { FishType } from '@/game/infos/fishInfos';
import {
  encodeSkinId,
  SkinGroup,
  SkinInfo,
  skinInfos,
} from '@/game/infos/skinInfos';
import { bannerInfos } from '@/game/infos/skinBannerInfos';

interface SkinData extends SkinInfo {
  owned: number;
  equipped: boolean;
}
@Component({
  components: {
    SkinButton,
  },
})
export default class DecoSkinInventoryModal extends Vue {
  public resolve: null | ((value: DecoName | null) => void) = null;
  public okTitle = 'Equip';
  public title = 'Skins';

  public fishType = 0;
  public selected = 0;

  public get titleHtml() {
    return `<i class="fa fa-tshirt"></i> ${this.title}`;
  }

  public get equippedSkin() {
    return userx.inventory.equippedSkins[this.fishType];
  }
  public get skins() {
    const list: SkinData[] = [];

    for (const group in skinInfos) {
      if (Object.prototype.hasOwnProperty.call(skinInfos, group)) {
        if (group === '0') { continue; }
        const infos = skinInfos[group];
        const skinGroup = Number(group);
        const fishType = this.fishType;
        const info = infos[fishType];
        const data: any = { ...info };
        data.equipped = this.equippedSkin === skinGroup;
        data.owned =
          userx.inventory.skins[encodeSkinId(Number(skinGroup), fishType)] || 0;
        list.push(data);
      }
    }
    return list;
  }
  public show(fishType: FishType) {
    const modal = this.$refs.modal as BModal;
    if (modal) {
      modal.show();
    }
    this.fishType = fishType;
    this.selected = this.equippedSkin || 0;
  }

  public select(skinGroup: SkinGroup) {
    this.selected = skinGroup;
  }

  public async onOk() {
    try {
      await userx.equipSkin({ skin: this.selected, fish: this.fishType });
    } catch (error) {
      this.showError(error);
    }
  }

  public onCancel() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }
  public onClose() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }

  public created() {
    Global.decoSkinModal = this;
  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
