import { blueprintInfos } from './blueprintInfos';
import { availableFishes } from './fishInfos';
import { ItemCode } from './itemInfos';
import { levelRequirements } from './levelInfos';
import { Quest, QuestStep, QuestType, UnlockChallengeType, UnlockSteps } from './unlockModels';

export enum MainQuestCode {
	LevelCrystal,
	Diy,
	Lvl5,
	NewCraft5,
	Deco5,
	Rank,
	UnlockSf2,
	EmailVerify,
	LinkPoq,
	UnlockSkin10,
	Tutorial,
	UnlockSf1,
}

export const mainQuests: Quest[] = [];

function setQuest(questTitle: string, questSteps: QuestStep[], isSf1 = false) {
	const missionCode = questSteps[0].challenges[0].unlockId;
	for (let i = 0; i < questSteps.length; i++) {
		const req = questSteps[i].challenges;
		for (let k = 0; k < req.length; k++) {
			const cha = req[k];
			cha.isMaster = false;
			cha.step = i;
			cha.index = k;
			cha.unlockId = missionCode;
		}
	}
	mainQuests[missionCode] = {
		code: missionCode,
		title: questTitle,
		type: QuestType.Main,
		steps: questSteps,
		isSf1,
	};
}

let steps: QuestStep[];
const index = 0;
let unlockId;
let title;
unlockId = MainQuestCode.LevelCrystal;
title = 'New Level New Fish!';
steps = [];
for (let i = 0; i < availableFishes.length - 12; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.LevelCrystal, amount: 1 }],
			challenges: [
				{
					unlockId,
					index,
					step: 0,
					type: UnlockChallengeType.Level,
					goal: 10 + i,
					description: `Reach level ${10 + i}`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = MainQuestCode.Diy;
title = 'Mine... Craft!';
steps = [
	{
		rewards: [{ itemCode: ItemCode.BpGlasses, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Accumulate,
				goal: 1,
				description: 'Find and open a purple treasure chest to get a blueprint.',
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.BpBlueCap, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Craft,
				goal: 1,
				description: 'Craft a decoration from a blueprint in your bag.',
			},
		],
	},
	{
		rewards: [{ itemCode: ItemCode.Shield, amount: 1 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.Decorate,
				goal: 1,
				description: 'Customize one of your fish with a decoration.',
			},
		],
	},
];


setQuest(title, steps);

unlockId = MainQuestCode.Lvl5;
title = 'From Beginner to Pro!';
steps = [];
let len = Math.floor((levelRequirements.length + 1) / 5);
for (let i = 0; i < len; i++) {
	const goal = (i + 1) * 5;
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 50 }],
			challenges: [
				{
					unlockId,
					index,
					step: 0,
					type: UnlockChallengeType.Level,
					goal,
					description: `Reach level ${goal}`,
				},
			],

		},
	);
}
setQuest(title, steps);


unlockId = MainQuestCode.NewCraft5;
title = 'Leonardo Da Vinci';
steps = [];
let numBlueprints = 0;
for (const code in blueprintInfos) {
	if (Object.prototype.hasOwnProperty.call(blueprintInfos, code)) {
		numBlueprints++;
	}
}
len = Math.floor((numBlueprints) / 5);
for (let i = 0; i < len; i++) {
	const goal = (i + 1) * 5;
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 10 }],
			challenges: [
				{
					unlockId,
					index,
					step: 0,
					type: UnlockChallengeType.NumNewCraft,
					goal,
					description: `Craft ${goal} new decorations.`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = MainQuestCode.Deco5;
title = 'My Fish My Style!';
steps = [];
len = Math.floor((availableFishes.length) / 10);
for (let i = 0; i < len; i++) {
	const goal = (i + 1) * 10;
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 10 }],
			challenges: [
				{
					unlockId,
					index,
					step: 0,
					type: UnlockChallengeType.NumDeco,
					goal,
					description: `Decorate ${goal} of your fishes.`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = MainQuestCode.UnlockSf2;
title = 'Aquarium';
steps = [];
for (let i = 0; i < availableFishes.length; i++) {
	const goal = i + 1;
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 5 }],
			challenges: [
				{
					unlockId,
					index,
					step: 0,
					type: UnlockChallengeType.NumUnlock,
					goal,
					description: `Unlock ${goal} fishes.`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = MainQuestCode.LinkPoq;
title = 'Pocketful Of Quarters!';
steps = [{
	rewards: [{ itemCode: ItemCode.Gem, amount: 200 }],
	challenges: [
		{
			unlockId,
			index,
			step: 0,
			type: UnlockChallengeType.LinkPoq,
			goal: 1,
			description: `Link poq.gg account in User Panel.`,
		},
	],
}];
setQuest(title, steps);


unlockId = MainQuestCode.EmailVerify;
title = 'Wrong Email? Bye Data~';
steps = [{
	rewards: [{ itemCode: ItemCode.Gem, amount: 100 }],
	challenges: [
		{
			unlockId,
			index,
			step: 0,
			type: UnlockChallengeType.LinkEmail,
			goal: 1,
			description: `Link your email to prevent account data loss.`,
		},
	],
}, {
	rewards: [{ itemCode: ItemCode.Gem, amount: 100 }],
	challenges: [
		{
			unlockId,
			index,
			step: 0,
			type: UnlockChallengeType.EmailVerify,
			goal: 1,
			description: `Verify your email address just in case.`,
		},
	],
}];
setQuest(title, steps);


unlockId = MainQuestCode.Rank;
title = 'Hoorah!';
steps = [];
const goals = [1000, 5000, 10000, 100000, 500000];
const rewards = [12, 14, 16, 18, 20];
const ranks = ['B', 'A', 'S', 'SS', 'SSS'];
for (let i = 0; i < goals.length; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: rewards[i] }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.OneGame,
					goal: goals[i],
					description: `Achieve Rank ${ranks[i]} (${goals[i]} pts) or higher.`,
				},
			],

		},
	);
}
setQuest(title, steps);


unlockId = MainQuestCode.UnlockSkin10;
title = 'Fishes from another universe!';
steps = [];
const numSkins = availableFishes.length;

len = Math.floor(numSkins / 10);
for (let i = 0; i < len; i++) {
	const goal = (i + 1) * 10;
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 100 }],
			challenges: [
				{
					unlockId,
					index,
					step: 0,
					type: UnlockChallengeType.NumSkins,
					goal,
					description: `Summon ${goal} different skins.`,
				},
			],

		},
	);
}
setQuest(title, steps);


unlockId = MainQuestCode.UnlockSf1;
title = 'Stabfish 1';
steps = [];
for (let i = 0; i < 60 + 2; i++) {
	const goal = i + 1;
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 5 }],
			challenges: [
				{
					unlockId,
					index,
					step: 0,
					type: UnlockChallengeType.UnlockSf1,
					goal,
					description: `Unlock ${goal} fishes from stabfish.io.`,
				},
			],

		},
	);
}
setQuest(title, steps, true);
