


























































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gamex from '@/store/modules/gamex';
import userx from '@/store/modules/userx';
import {
  ClientTourneyPlayerData,
  MatchData,
  TourneyLobbyState,
} from '@/game/infos/roomInfosTny';
import { ordinalSuffix } from '@/util/number';
import ScrollContainer from '../ScrollContainer.vue';
import TnyChatWindow from './TnyChatWindow.vue';
import { Global } from '@/store/globalz';
import { GameType } from '@/game/infos/roomInfos';
@Component({
  components: {
    TnyChatWindow,
    ScrollContainer,
  },
})
export default class AfterTourneyPage extends Vue {
  public get myUid() {
    return userx.uid;
  }
  public get tnyLobbyData() {
    return gamex.tnyLobbyData!;
  }
  public get lobbyClosed() {
    return this.tnyLobbyData.state === TourneyLobbyState.Closed;
  }
  public get totalRound() {
    return this.tnyLobbyData.roomOptions.totalRound || 2;
  }
  public get currentRound() {
    if (this.tnyLobbyData.currentRound === this.totalRound) {
      return 'Final';
    }
    return ordinalSuffix(this.tnyLobbyData.currentRound);
  }
  public get participants() {
    return Object.values(this.tnyLobbyData?.participants).sort((a, b) => {
      if (a.rank === b.rank) {
        if (a.isComputer) {
          return 1;
        }
        if (b.isComputer) {
          return -1;
        }
        return 0;
      }
      return a.rank - b.rank;
    }) as ClientTourneyPlayerData[];
  }
  public get me() {
    const me = this.tnyLobbyData.participants[userx.uid || ''];
    return me as ClientTourneyPlayerData;
  }
  public get myMatch(): MatchData {
    const matches = this.tnyLobbyData.matches;
    if (matches) {
      for (const mid in matches) {
        if (Object.prototype.hasOwnProperty.call(matches, mid)) {
          const mm = matches[mid];
          if (mm.p1 === userx.uid || mm.p2 === userx.uid) {
            return mm as MatchData;
          }
        }
      }
    }
    return {
      mid: '1',
      p1: '',
      p2: '',
      closed: false,
      result: '',
	  difficulty: 0,
    };
  }

  public width = 640;
  public height = 460;
  public mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }
  public beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
  public onResize() {
    this.width = this.$el.clientWidth;
    this.height = (this.width / 640) * 460;
  }

  public onContext(event: any, uid: string) {
    (this.$refs.chatbox as TnyChatWindow).onContext(event, uid);
  }

  public onQuit() {
	Global.stageControl.uiControl.onLeaveTourney();
  }
  public get socketErrorMessage() {
    return gamex.socketErrorMessage;
  }
  @Watch('socketErrorMessage')
  public async socketErrorMessageChanged(value: string) {
    await this.$bvModal.msgBoxOk(value, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Connection Lost`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
    gamex.updateGameType(GameType.None);
    gamex.setGameStage('TourneyList');
  }
  public get rejectReason() {
    return gamex.rejectReason;
  }
  @Watch('rejectReason')
  public async rejectReasonChanged(value: string) {
    await this.$bvModal.msgBoxOk(value, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Join Room Failed`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
    gamex.updateGameType(GameType.None);
    gamex.setGameStage('TourneyList');
  }
}
