




import assetx from '@/store/modules/assetx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { fishInfos } from '@/game/infos/fishInfos';
import { Decoratives } from '../ui/mainMenu/tools/decoEditor/decorative';
import { wait } from '@/util/wait';
import { MainScenePage } from '@/client/controls/mainScene';

@Component({
  components: {},
})
export default class MainMenuCanvasComponent extends Vue {
  @Prop({ default: 'mainMenu' }) public page!: MainScenePage;
  public get assetLoadState() {
    return assetx.assetLoadState;
  }
  public get heroes() {
    return userx.heroes;
  }
  public get checkSum() {
    return this.heroes.join('|') + '~' + this.skins.join('|') + '~' + this.decoStrings.join('|');
  }
  @Watch('checkSum', { immediate: true })
  public heroesChanged() {
    if (this.assetLoadState === 'completed') {
      for (let i = 0; i < this.heroes.length; i++) {
        const fishType = this.heroes[i];
        const avatar = Global.avatars[i];
        avatar.setFishType(fishType, userx.equippedSkins[fishType] || 0);
        avatar.setAction('move');
        const size = fishInfos[fishType].stat.size * 0.8;
        avatar.setBodyScale(size);
        assetx.mainApp.mainMenuControl?.shadows[i]?.scale.set(size);
        const decoSet = userx.decoSets[fishType];
        if ((decoSet?.version || 0) < 1) {
          Decoratives.isDecoOutOfBound(decoSet, fishType);
        }
        Decoratives.generateDeco(avatar, decoSet);
      }
    }
  }
  public get skins() {
    return this.heroes.map((f) => userx.equippedSkins[f] || 0);
  }
  // @Watch('skins', { immediate: true })
  // public skinsChanged() {
  //   if (this.assetLoadState === 'completed') {
  //     for (let i = 0; i < this.skins.length; i++) {
  //       const skin = this.skins[i];
  //       const fishType = this.heroes[i];
  //       const avatar = Global.avatars[i];
  //       avatar.setFishType(fishType, skin);
  //       avatar.setAction('move');
  //     }
  //   }
  // }
  public get decoStrings() {
    return this.heroes.map((f) => userx.inventory.decoSets[f]);
  }
  public get decoSets() {
    return this.heroes.map((f) => userx.decoSets[f]);
  }
  // @Watch('decoSets', { immediate: true })
  // public decoSetsChanged() {
  //   if (this.assetLoadState === 'completed') {
  //     for (let i = 0; i < this.decoSets.length; i++) {
  //       const decoSet = this.decoSets[i];
  //       const avatar = Global.avatars[i];
  //       avatar.setAction('move');
  //       Decoratives.generateDeco(avatar, this.decoSets[i]);
  //     }
  //   }
  // }
  public get app() {
    return assetx.mainApp;
  }

  @Watch('page', { immediate: true })
  public onPageChange(value: MainScenePage) {
    this.app.page = value;
  }
  public mounted() {
    this.$el.appendChild(this.app.view);
    this.app.page = this.page;
  }

  public destroyed() {
    this.$el.removeChild(this.app.view);
    wait(500).then(() => {
      if (this.app.page === 'mainMenu' || this.app.page === 'decoEditor') {
        this.app.page = 'none';
      }
    });
  }
}
