import { RoomControl } from '@/client/controls/roomControl';
import { MiscData, MiscDataSsGoldFishing } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Ease, Tween } from '@/util/tweents';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';

export class SsGoldFishingSymbol extends MovieClip {

}
export class SsGoldFishingController implements MiscSymbol {

	public static get() {
		return SsGoldFishingController._pool.get();
	}

	private static _pool: Pool<SsGoldFishingController> = new Pool(SsGoldFishingController);


	public data: MiscData | null = null;
	public pt = 0;

	public symbol = Factory.get(SsGoldFishingSymbol);

	public dispose(room: RoomControl) {
		this.data = null;
		Tween.get(this.symbol).to({ alpha: 0 }, 300).call(() => {
			if (this.symbol.parent) {
				this.symbol.parent.removeChild(this.symbol);
			}
			SsGoldFishingController._pool.pool(this);
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsGoldFishing;
		this.symbol.x = data.x;
		this.symbol.y = data.y;
		// this.symbol.angle = data.d;


		if (!this.data) {
			this.symbol.alpha = 1;
		}
		if (!room.hookEvent) {
			room.blingThing(data, rt);
			this.symbol.visible = true;
		} else {
			this.symbol.visible = false;
		}
		this.data = data;

	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this.symbol);
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
	}
}
