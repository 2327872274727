




































import { SkillIconData } from '@/client/factory/assets/skillIcon';
import SkillIconCanvasComponent from '@/components/canvas/SkillIconCanvasComponent.vue';
import {
  SpecialSkillInfo,
  specialSkillInfos,
  normalSkillInfos,
  PassiveSkillInfo,
  passiveSkillInfos,
  SkillInfo,
} from '@/game/infos/skills';
import settingx from '@/store/modules/settingx';
import { roundToFixed } from '@/util/number';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const typeNames = [
  'Action',
  'Release',
  'Bullet',
];
const romans = [
  '0',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
];
@Component({
  components: {
    SkillIconCanvasComponent,
  },
})
export default class FishSkillComponent extends Vue {
  @Prop({ required: true }) public iconData!: SkillIconData;
  @Prop({ default: 0 }) public index!: number;
  @Prop({ default: false }) public small!: boolean;
  @Prop({ default: false }) public tooltipMode!: boolean;
  @Prop({ default: false }) public showAdvanceSkill!: boolean;
  @Prop({ default: '' }) public container!: string;

  public onMouseOver() {
    this.$root.$emit('bv::show::tooltip', this.popoverId);
  }
  public onMouseOut() {
    this.$root.$emit('bv::hide::tooltip', this.popoverId);
  }
  public get popoverId() {
    return random.v4();
  }
  public get type() {
    return this.iconData.type;
  }
  public get isRecessive() {
    return this.iconData.type === 'recess';
  }
  public get lvl() {
    return this.iconData.lvl;
  }
  public get info() {
    return this.type === 'normal'
      ? normalSkillInfos[this.index]
      : this.type === 'special'
        ? specialSkillInfos[this.index]
        : passiveSkillInfos[this.index];
  }
  public get desc() {
    return this.type === 'normal' || this.type === 'special'
      ? (this.info as SkillInfo).desc
      : (this.info as PassiveSkillInfo).lvls[this.lvl ? this.lvl - 1 : 0].desc;
  }
  public get advance() {
    return this.type === 'normal' || this.type === 'special'
      ? ((this.info as SkillInfo).advance || '')
      : '';
  }
  public get skillInfo() {
    if (this.type === 'special') {
      const info = this.info as SpecialSkillInfo;
      const castTime = roundToFixed(info.castTime / 1000, 1);
      const coolDown = roundToFixed(info.coolDown / 1000, 1);
      return `< cast time: ${castTime}s, cool down: ${coolDown}s >`;
    }
    return '';
  }

  public get name() {
    const name = this.info.name;
    const roman =
      this.type === 'normal' || this.type === 'special' || this.lvl === 0
        ? ''
        : ' ' + romans[this.lvl];
    let suffix = '';
    // if (settingx.now.control === 'mouse') {
    //   if (this.type === 'normal') {
    //     suffix = ' (Left Click)';
    //   } else if (this.type === 'special') {
    //     suffix = ' (Right Click)';
    //   }
    // }
    if (!this.tooltipMode) {
      if (this.type === 'normal') {
        suffix = ' (Normal Skill)';
      } else if (this.type === 'special') {
        suffix = ` (Special ${typeNames[(this.info as SpecialSkillInfo).type]} Skill)`;
      }
    }
    return name + roman + suffix;
  }
  public get skillType() {
    if (this.tooltipMode) {
      if (this.type === 'normal') {
        return 'Normal Skill';
      } else if (this.type === 'special') {
        return `Special ${typeNames[(this.info as SpecialSkillInfo).type]} Skill`;
      }
    }
    return '';
  }
}
