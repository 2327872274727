var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-column d-flex fish-list"},[_c('div',{staticClass:"d-flex w-100 align-items-center"},[_c('div',{staticClass:"filter-container d-flex align-items-center"},[_c('i',{staticClass:"fa fa-filter p-2"}),_c('b-form-input',{staticClass:"high-rounded",attrs:{"placeholder":"Name / Tier / Gene / Family / Color"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('i',{directives:[{name:"b-modal",rawName:"v-b-modal.tips-fish-filter",modifiers:{"tips-fish-filter":true}}],staticClass:"\n\t\t\t\t\t\t\t\t\t\tfa fa-info-circle\n\t\t\t\t\t\t\t\t\t\tml-1\n\t\t\t\t\t\t\t\t\t\ttext-white\n\t\t\t\t\t\t\t\t\t\tinfo-btn\n\t\t\t\t\t\t\t\t\t\tcursor-pointer\n\t\t\t\t\t\t\t\t\t"})],1),_c('b-button',{staticStyle:{"width":"36px","height":"36px"},attrs:{"size":"small","variant":"primary super-rounded ml-auto mx-2 justify-content-center p-1"},on:{"click":function($event){return _vm.$emit('changeMode')}}},[_c('i',{staticClass:"fa",class:{
          'fa-angle-up': _vm.showInfo,
          'fa-angle-down': !_vm.showInfo,
        }})])],1),_c('div',{staticClass:"flex-grow-1 flex-shrink-1 ps-container"},[_c('div',{ref:"scroll",staticClass:"pscroll sf-scrollbar"},[_c('div',{ref:"fishButtonContainer",staticClass:"d-flex fish-button-container",class:{
          'flex-wrap': !_vm.singleLine,
          'justify-content-center': !_vm.singleLine,
        }},_vm._l((_vm.inventory),function(element,index){return _c('FishButton',{key:element.type,class:{
          'd-none': !_vm.filteredInventory.includes(element),
        },staticStyle:{"flex-grow":"0","flex-shrink":"0"},attrs:{"fishType":element.type,"selectable":true,"active":_vm.life >= 0 && element.type === _vm.confirmed,"confirmable":false && _vm.confirmed !== element.type,"small":_vm.width < 503,"selected":element.type === _vm.selected,"unlockProgress":_vm.fishUnlockProgress[element.type],"locked":!_vm.unlocked[element.type],"disabled":_vm.life >= 0 && element.tier > _vm.life + 1,"pinkIndicator":_vm.hasNewlyCompletes[index],"yellowIndicator":_vm.pendingFishUnlocks[element.type]},on:{"click":function($event){return _vm.select(element.type)},"confirm":function($event){return _vm.$emit('confirm', element.type)}}})}),1)])]),_c('b-modal',{attrs:{"id":"tips-fish-filter","modalClass":"funny-modal","hide-footer":"","size":"sm"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Fish Filter ")]},proxy:true}])},[_c('div',{staticClass:"text-md"},[_vm._v(" You can filter fish by name, tier, gene, family, and color."),_c('br'),_c('br'),_vm._v(" For more specific results, use keywords like \"tier 1\" or \"blue color.\""),_c('br'),_c('br'),_vm._v(" Use commas to separate keywords, and add '!' before a keyword to exclude it. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }