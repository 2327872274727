








import SummonTips from '@/components/gacha/SummonTips.vue';
import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    SummonTips,
  },
})
export default class SummonTutorial extends Vue {
  public baseUrl = process.env.BASE_URL;
}
