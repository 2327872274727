





import { FishType } from '@/game/infos/fishInfos';
import { decodeSkinId, skinInfos, SkinGroup } from '@/game/infos/skinInfos';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ContextMenuItem } from '../ui/ContextMenu.vue';
import { bannerInfos } from '@/game/infos/skinBannerInfos';
import { Rarity } from '@/game/infos/itemInfos';
import { Dictionary } from '@/util/dictionary';

@Component({
  components: {},
})
export default class GachaListener extends Vue {
  public mounted() {
    this.$root.$on('skinContext', this.onContext);
  }
  // skin context
  public onContext(event: any, skinId: number, previewOnly = false) {
    const { skinGroup, fishType } = decodeSkinId(skinId);
    const equipped = userx.inventory.equippedSkins[fishType] === skinGroup;

    const list: ContextMenuItem[] = [
      {
        icon: '',
        text: 'Preview',
        disabled: false,
        click: () => {
          this.$root.$emit('previewSkin', skinId);
        },
      },
    ];
    if (!previewOnly && userx.inventory.skins[skinId]) {
      const skinRarity = skinInfos[skinGroup][fishType].rarity;
      const rarityLabel =
        skinRarity === Rarity.Rare
          ? 'Rares'
          : skinRarity === Rarity.Epic
          ? 'Epics'
          : 'Legendaries';
      list.push(
        {
          icon: '',
          text: equipped ? 'Unequip' : 'Equip',
          disabled: false,
          click: () => {
            if (equipped) {
              this.unequip(fishType);
            } else {
              this.equip(skinId);
            }
          },
        },
        {
          icon: '',
          text: 'Convert Duplicates',
          disabled: (userx.inventory.skins[skinId] || 0) <= 1,
          click: () => {
            this.convertToBlackPearl(skinId);
          },
        },
        {
          icon: '',
          text: `Convert All ${rarityLabel}`,
          disabled: (userx.numDuplicates[skinGroup][skinRarity] || 0) === 0,
          click: () => {
            this.convertAllToBlackPearl(skinGroup, skinRarity);
          },
        },
      );
    }
    Global.contextMenu.open(event, list);
  }
  public async equip(skinId: number) {
    const { skinGroup, fishType } = decodeSkinId(skinId);
    try {
      await userx.equipSkin({ skin: skinGroup, fish: fishType });
    } catch (error) {
      this.showError(error);
    }
    // const inventory = userx.inventory;
    // if (inventory.equippedSkins[fishType] === skinGroup) {
    //   inventory.equippedSkins[fishType] = 0;
    // } else {
    //   inventory.equippedSkins[fishType] = skinGroup;
    // }
    // userx.updateUserInventory({ ...inventory });
  }
  public async unequip(fishType: FishType) {
    try {
      await userx.equipSkin({ skin: 0, fish: fishType });
    } catch (error) {
      this.showError(error);
    }
  }
  public convertToBlackPearl(skinId: number) {
    Global.convertDuplicatesModal.show(skinId);
  }
  public convertAllToBlackPearl(skinGroup: SkinGroup, rarity: Rarity) {
    Global.convertAllDuplicatesModal.show(skinGroup, rarity);
  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
