













import { GameUtility } from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UtilityBarButton extends Vue {
  @Prop({ default: 'info' }) public icon!: string;
  @Prop({ default: 'None' }) public currentUtility!: GameUtility;
  @Prop({ default: 'None' }) public utility!: GameUtility;

  public get active() {
    return this.currentUtility === this.utility;
  }

  public get iconClass() {
    return `fa fa-${this.icon}`;
  }

  public onTouchStart(e) {
    this.$emit('click', this.utility);
    e.preventDefault();
  }
}
