


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import MustLoginModal from '../mainMenu/MustLoginModal.vue';
import { ServerInitInfo } from '@/game/infos/serverInfos';
import gamex from '@/store/modules/gamex';
import { GameType } from '@/game/infos/roomInfos';

@Component({
  components: { MustLoginModal },
})
export default class TourneyList extends Vue {
  public serverList: ServerInitInfo[] = [
    {
      id: 'Home|Local|1|1',
      name: 'Home|Local|1|1',
      url: 'http://192.168.1.100:9002',
      region: 'local',
      type: 'local',
    },
    {
      id: 'Asia|Singapore|8|1',
      name: 'Asia|Singapore|8|1',
      url: 'https://sgp-8-1.gs.stabfish2.io',
      region: 'sgd',
      type: 'production',
    },
    {
      id: 'Asia|Singapore|8|2',
      name: 'Asia|Singapore|8|2',
      url: 'https://sgp-8-2.gs.stabfish2.io',
      region: 'sgd',
      type: 'production',
    },
  ];
  public get showMustLoginModal() {
    return userx.loginStatus !== 'logged in' ? true : false;
  }
  public onCreate(id: number) {
    Global.stageControl.communicator?.createTourneyLobby(
      this.serverList[id].url,
    );
  }
  public onJoin(id: number) {
    Global.stageControl.communicator?.joinTourneyLobby(
      this.serverList[id],
      '1',
    );
  }
  public get socketErrorMessage() {
    return gamex.socketErrorMessage;
  }
  @Watch('socketErrorMessage')
  public async socketErrorMessageChanged(value: string) {
    if (!value) {
      return;
    }
    gamex.updateGameType(GameType.None);
    gamex.setGameStage('TourneyList');
		  gamex.setSocketErrorMessage('');
    this.$bvModal.msgBoxOk(value, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Connection Lost`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
  }
  public get rejectReason() {
    return gamex.rejectReason;
  }
  @Watch('rejectReason')
  public async rejectReasonChanged(value: string) {
    if (!value) {
      return;
    }
    gamex.updateGameType(GameType.None);
    gamex.setGameStage('TourneyList');
		  gamex.setRejectReason('');
    this.$bvModal.msgBoxOk(value, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Join Room Failed`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
  }
}
