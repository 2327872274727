














































import {
  blackPearlPurchaseInfos,
  BlackPearlPurchaseType,
} from '@/game/infos/blackPearlInfos';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const imgNames = ['half', 1, 5, 10, 20, 50, 100];

@Component({
  components: {},
})
export default class BlackPearlButton extends Vue {
  public baseUrl = process.env.BASE_URL;

  @Prop({ default: 0 }) public purchaseType!: BlackPearlPurchaseType;

  public get info() {
    return blackPearlPurchaseInfos[this.purchaseType];
  }

  public get cost() {
    return this.info.cost;
  }
  public get amount() {
    return this.info.amount;
  }
  public get isFirstTime() {
    return (userx.inventory.bpData.firstTime[this.purchaseType] || 0) === 0;
  }
  public get imgSrc() {
    return `/images/blackpearls/blackpearl-${imgNames[this.purchaseType]}.png`;
  }
}
