























import SkinButton from '@/components/gacha/SkinButton.vue';
import { SkinIntelData } from '@/components/gacha/SkinIntelTooltip.vue';
import { decodeSkinId, SkinInfo, skinInfos } from '@/game/infos/skinInfos';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

interface SkinData extends SkinInfo {
  equipped: boolean;
  owned: number;
}
@Component({
  components: {
    SkinButton,
  },
})
export default class SkinsTab extends Vue {
  public get skins() {
    const list: SkinData[] = [];
    for (const skinId of userx.inventory.skinOrders) {
      const { skinGroup, fishType } = decodeSkinId(skinId);
      const info = skinInfos[skinGroup][fishType];
      const data: any = { ...info };
      data.equipped = userx.inventory.equippedSkins[info.fishType] === 1;
      data.owned = userx.inventory.skins[skinId] || 0;
      list.push(data);
    }
    return list;
  }
  public showSkinIntel(skin: SkinData, index: number) {
    const data: SkinIntelData = {
      targetId: 'item-skin-tab-' + index,
      placement: 'top',
      container: 'item-skin-tab',
      ...skin,
    };
    this.$root.$emit('showSkinIntel', data);
  }
  public hideSkinIntel() {
    this.$root.$emit('hideSkinIntel');
  }
}
