import store from '@/store';
import { Global } from '@/store/globalz';
import settingx from '@/store/modules/settingx';
import { Scene } from 'pixi-animate';
import { StageControl } from './stageControl';
import throttle from 'lodash/throttle';
import { Container } from 'pixi.js';
import { MainMenuControl } from './mainMenuControl';
import { DecoEditorControl } from './decoEditorControl';
import { FishSelectorControl } from './fishSelectorControl';
export type MainScenePage = 'game' | 'mainMenu' | 'decoEditor' | 'fishSelector' | 'none';
export class MainScene extends Scene {
	public get page(): MainScenePage {
		return this._page;
	}
	public set page(value: MainScenePage) {
		this._page = value;
		if (value === 'game') {
			this.stage.addChild(this.gameStageContainer);
			this.stage.removeChild(this.mainMenuContainer);
			this.stage.removeChild(this.decoEditorContainer);
			this.stage.removeChild(this.fishSelectorContainer);
			if (this.stageControl) {
				const allPlayers = this.stageControl.cameraControl.roomControl.allPlayers;
				for (const playerId in allPlayers) {
					if (Object.prototype.hasOwnProperty.call(allPlayers, playerId)) {
						const playerControl = allPlayers[playerId];
						if (playerControl) {
							if (!((settingx.now.hideEnemyDecos && playerControl.alliance === 'enemy') ||
								(settingx.now.hideTeamDecos && playerControl.alliance === 'team') ||
								(settingx.now.hideMyDecos && playerControl.alliance === 'me'))) {
								playerControl.showDecos();
							}
						}
					}
				}
			}
		} else if (value === 'mainMenu') {
			this.stage.addChild(this.mainMenuContainer);
			this.stage.removeChild(this.gameStageContainer);
			if (this.decoEditorContainer.parent) {
				this.decoEditorControl?.hide();
			}
			if (this.fishSelectorContainer.parent) {
				this.fishSelectorControl?.hide();
			}
		} else if (value === 'decoEditor') {
			this.stage.addChild(this.decoEditorContainer);
			this.stage.removeChild(this.mainMenuContainer);
			this.stage.removeChild(this.gameStageContainer);
			if (this.fishSelectorContainer.parent) {
				this.fishSelectorControl?.hide();
			}
		}  else if (value === 'fishSelector') {
			this.stage.addChild(this.fishSelectorContainer);
			this.stage.removeChild(this.mainMenuContainer);
			this.stage.removeChild(this.gameStageContainer);
			if (this.decoEditorContainer.parent) {
				this.decoEditorControl?.hide();
			}
		} else {
			this.stage.removeChild(this.gameStageContainer);
			this.stage.removeChild(this.mainMenuContainer);
			if (this.decoEditorContainer.parent) {
				this.decoEditorControl?.hide();
			}
			if (this.fishSelectorContainer.parent) {
				this.fishSelectorControl?.hide();
			}
		}
	}

	public stageControl: StageControl | null = null;
	public mainMenuControl: MainMenuControl | null = null;
	public decoEditorControl: DecoEditorControl | null = null;
	public fishSelectorControl: FishSelectorControl | null = null;
	public width = document.documentElement.clientWidth;
	public height = document.documentElement.clientHeight;
	public dpi = settingx.now.dpi;
	public updateSize = this._updateSize.bind(this);
	public resizeThrottled = throttle(this.updateSize, 500);

	public gameStageContainer = new Container();
	public mainMenuContainer = new Container();
	public decoEditorContainer = new Container();
	public fishSelectorContainer = new Container();

	private _page: MainScenePage = 'none';

	constructor(width: number, height: number) {
		super(width, height, {
			transparent: true,
			antialias: false,
		});

		this.view.style!.width = '100%';
		this.view.style!.height = '100%';
		this.stage.filterArea = this.renderer.screen;
		this.view.addEventListener!('contextmenu', (e) => {
			e.preventDefault();
		});
		window.addEventListener('resize', this.updateSize);
		(window as any).mainScene = this;

		store.watch((states, getters) => getters['settingx/now'],
			(newValue, oldValue) => {

				const str = settingx.now.canvasSize * 100 + '%';
				this.view.style!.width = str;
				this.view.style!.height = str;
				this.ticker.maxFPS = settingx.now.frameRate;

				if (this.dpi !== settingx.now.dpi) {
					this.resizeThrottled();
					this.dpi = settingx.now.dpi;
				}

			});
	}

	public updateAssetLoadState(state: 'completed' | 'loading') {
		if (state === 'completed') {
			this.build();
		}
	}

	public build() {
		if (!this.stageControl) {
			this.stageControl = Global.stageControl;
			this.gameStageContainer.addChild(this.stageControl.symbol);
		}
		if (!this.mainMenuControl) {
			this.mainMenuControl = Global.mainMenuControl;
			this.mainMenuContainer.addChild(this.mainMenuControl);
		}
		if (!this.decoEditorControl) {
			this.decoEditorControl = Global.decoEditorControl;
			this.decoEditorContainer.addChild(this.decoEditorControl);
		}
		if (!this.fishSelectorControl) {
			this.fishSelectorControl = Global.fishSelectorControl;
			this.fishSelectorContainer.addChild(this.fishSelectorControl);
		}
		this._updateSize();
	}
	private _updateSize() {
		const width = document.documentElement.clientWidth;
		const height = document.documentElement.clientHeight;
		this.width = width;
		this.height = height;
		const { dpi } = this;
		this.renderer.resize(width * dpi, height * dpi);

		const appScale = Global.appScale;
		this.stage.scale.set(dpi * appScale);
		const w = Math.ceil(width / appScale);
		const h = Math.ceil(height / appScale);
		if (this.stageControl) {
			this.stageControl.setSize(w, h);
		}
		if (this.mainMenuControl) {
			this.mainMenuControl.setSize(w, h);
		}
		if (this.decoEditorControl) {
			this.decoEditorControl.setSize(w, h);
		}
		if (this.fishSelectorControl) {
			this.fishSelectorControl.setSize(w, h);
		}
	}
}
