



































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DecoItem } from './decorative';

@Component({
  components: {},
})
export default class DecoArrangerItem extends Vue {
  @Prop({ required: true }) public item!: DecoItem;
}
