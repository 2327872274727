import { Dictionary } from '@/util/dictionary';

export enum NormalSkill {
	None,
	BleedExperdite,
	BleedDelay,
	Boost,
	ShellHiding,
	Boost2,
}
export enum SpecialSkill {
	None,
	Ration,
	Ration2,
	Bandage,
	Medkit,
	WhammyWave,
	SwimmingFang,
	MegaBite,
	SpinDodge,
	SpikeArmor,
	HolySword,
	DiamondHarden,
	Burst,
	InkSac,
	BodySlam,
	Sacrifice,
	TempSafeZone,
	ForceField,
	ElectricField,
	MercyRelease,
	Dart,
	BatDart,
	ElectricDart,
	FishBolt,
	TridentWave,
	FireBullet,
	SunPunch,
	Decoy,
	TentaBola,
	Disguise,
	SwordDance,
	StrongArms,
	MosaBite,
	DragonTrident,
	Snowball,
	BoxPunch,
	GoldFishing,
}
export enum PassiveSkill {
	None,
	NewbieDefBonus,
	RookieDefBonus,
	ScoreBonus,
	Detective,
	StabScoreBonus,
	KillScoreBonus,
	Assassin,
	AttackUp,
	DefenseUp,
	ChampHunter,
	MissileDamageUp,
	Teamwork,
	MaxHpUp,
	HpRegenUp,
	StamRegenUp,
	WpnSizeUp,
	ElemResUp,
	EfxTimeUp,
	MoneyUp,
	OreRarityUp,
	OreDoubleUp,
	FoodScoreUp,
	Ecologist,
	Speedster,
	BlindResUp,
	TinyHero,
	Vampire,
	UtilityBelt,
	SkillTimeUp,
	CoolDownTimeDown,
	Revenger,
	CnyBlessing,
	AttackUpNemesis,
	DefenseUpNemesis,
	GemRarityUp,
	Berserker,
	Daredevil,
	Vegetarian,
}

export interface SkillInfo {
	name: string,
	index: number,
	desc: string,
	advance?: string,
}
export interface NormalSkillInfo extends SkillInfo {
	cost: number,
}

export enum SpecialSkillType {
	Action,
	Release,
	Bullet,
}
export interface SpecialSkillInfo extends SkillInfo {
	type: SpecialSkillType,
	coolDown: number,
	castTime: number,
	duration: number,
	max: number,
	cancelable: boolean,
	autoUse?: boolean,
}
export interface PassiveSkillInfo {
	name: string,
	index: number,
	lvls: PassiveSkillLvlInfo[],
}
export interface PassiveSkillLvlInfo {
	lvl: number,
	desc: string,
	buffs: Array<{
		p: string,
		m?: number,
		a?: number,
	}>;
}

export const normalSkillInfos: Dictionary<NormalSkillInfo> = {};

let skill: number;
skill = NormalSkill.None;
normalSkillInfos[skill] = {
	name: 'None',
	index: skill,
	cost: 0,
	desc: '',
};
skill = NormalSkill.BleedExperdite;
normalSkillInfos[skill] = {
	name: 'Bleed Faster',
	index: skill,
	cost: 2,
	desc: 'Experdites bleeding',
};
skill = NormalSkill.BleedDelay;
normalSkillInfos[skill] = {
	name: 'Bleed Slower',
	index: skill,
	cost: 0.5,
	desc: 'Slows down bleeding',
};
skill = NormalSkill.Boost;
normalSkillInfos[skill] = {
	name: 'Boost',
	index: skill,
	cost: 1,
	desc: 'Accelerates swimming speed.',
	advance: 'Boost during long-range aiming will slide instead of sharp turn, stamina cost halved',
};
skill = NormalSkill.Boost2;
normalSkillInfos[skill] = {
	name: 'Boost II',
	index: skill,
	cost: 0.5,
	desc: 'Accelerates swimming speed at half stamina cost.',
	advance: 'Boost during long-range aiming will slide instead of sharp turn, stamina cost halved',
};
skill = NormalSkill.ShellHiding;
normalSkillInfos[skill] = {
	name: 'Shell Hiding',
	index: skill,
	cost: 1,
	desc: 'Hide in the indestructible shell.',
	advance: 'Time your skill activation to parry weapons and skills.',
};


export const specialSkillInfos: Dictionary<SpecialSkillInfo> = {};

skill = SpecialSkill.None;
specialSkillInfos[skill] = {
	index: skill,
	name: 'None',
	desc: '',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 0,
	duration: 0,
	coolDown: 0,
	cancelable: false,
};
skill = SpecialSkill.Ration;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Ration',
	desc: 'Recovers 25% of your stamina.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 1000,
	duration: 0,
	coolDown: 10000,
	cancelable: false,
};
skill = SpecialSkill.Ration2;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Ration II',
	desc: 'Recovers 50% of your stamina.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 1000,
	duration: 0,
	coolDown: 10000,
	cancelable: false,
};
skill = SpecialSkill.Bandage;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Bandage',
	desc: 'Rest and patch up your wound, slowly recover a portion of HP and a smaller portion of stamina.',
	advance: 'Press again to cancel.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 0,
	duration: 4000,
	coolDown: 20000,
	cancelable: true,
};
skill = SpecialSkill.Medkit;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Medkit',
	desc: 'Rest and patches up your wound, slowly recover a portion of HP. Press again to cancel.',
	type: SpecialSkillType.Action,
	max: 2,
	castTime: 0,
	duration: 0,
	coolDown: 0,
	cancelable: true,
};
skill = SpecialSkill.WhammyWave;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Whammy Wave',
	desc: 'Release a nauseating wave from your weapon\'s tip, stunning nearby enemies and canceling their skills.',
	advance: 'Press again to send a small pulse. Stunned fish deal only 30% weapon damage.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 700,
	duration: 2000,
	coolDown: 5000,
	cancelable: false,
};
skill = SpecialSkill.SwimmingFang;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Swimming Fang',
	desc: 'Release 6 toy fangs that track and bite nearby enemies.',
	advance: 'Long-press to speed up, short-press to change the target to your closest enemy.',
	type: SpecialSkillType.Bullet,
	max: 0,
	castTime: 0,
	duration: 100,
	coolDown: 10000,
	cancelable: false,
};
skill = SpecialSkill.MegaBite;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Mega Bite',
	desc: 'Bite nearby enemies in front of you with a huge set of fangs.',
	advance: 'Subsequent hits on the same enemy in a single bite deal an extra 50% damage.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 500,
	duration: 880,
	coolDown: 5000,
	cancelable: false,
};
skill = SpecialSkill.SpikeArmor;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Spike Armor',
	desc: 'Puffs up and protrudes spikes to damage nearby enemies.',
	advance: ' The armor grants 40% weapon damage reduction and a 50% damage increase against Nemeses and Mobs.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 500,
	duration: 5000,
	coolDown: 5000,
	cancelable: false,
};
skill = SpecialSkill.DiamondHarden;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Diamond Shield',
	desc: 'Conjure an impenetrable shield by your side while enjoying a 10% damage reduction.',
	advance: 'Long-press to adjust the shield\'s location. Time your skill activation to parry weapons and skills.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 0,
	duration: 6000,
	coolDown: 4000,
	cancelable: false,
};
skill = SpecialSkill.HolySword;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Holy Sword',
	desc: 'Summon a radiant, lightweight holy sword.',
	advance: 'Weapon Size +50%; Attack +30%;',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 0,
	duration: 5000,
	coolDown: 10000,
	cancelable: false,
};
skill = SpecialSkill.SpinDodge;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Spin Dodge',
	desc: 'Dodge any attack with an elegant spin.',
	advance: 'Boosting mid-spin propels the fish in the target direction.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 50,
	duration: 550,
	coolDown: 2000,
	cancelable: false,
};
skill = SpecialSkill.BodySlam;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Body Slam',
	desc: 'Enlarge and slam into enemies, releasing their impaled victims. This move charms the parties and collects healing hearts.',
	advance: 'Collect 5 hearts for a shield buff or 7 hearts for a weapon buff.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 500,
	duration: 750,
	coolDown: 5000,
	cancelable: false,
};
skill = SpecialSkill.Sacrifice;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Sacrifice',
	desc: 'Sacrifice your impaled victims to increase multiple stats, recover HP, and restore stamina based on the number of sacrifices.',
	advance: 'Increased stats include attack, defense, weapon size, max HP, reduced damage from Nemeses/Mobs, and increased damage dealt to Nemeses/Mobs.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 1000,
	duration: 5000,
	coolDown: 6000,
	cancelable: false,
};
skill = SpecialSkill.Burst;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Burst',
	desc: 'Short burst to a small distance',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 0,
	duration: 100,
	coolDown: 2000,
	cancelable: false,
};
skill = SpecialSkill.InkSac;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Ink Sac',
	desc: 'Releases a mist of ink that will blind the enemies who are caught in it.',
	type: SpecialSkillType.Bullet,
	max: 0,
	castTime: 0,
	duration: 600,
	coolDown: 6000,
	cancelable: false,
};
skill = SpecialSkill.MercyRelease;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Mercy Release',
	desc: 'Release your last impaled opponent. Will stop getting stab score from them.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 0,
	duration: 0,
	coolDown: 250,
	cancelable: false,
};
skill = SpecialSkill.FishBolt;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Fish Bolt',
	desc: 'Shoot your impaled victim, potentially make elemental explosion. Long-press to shoot upon release.',
	type: SpecialSkillType.Bullet,
	max: 0,
	castTime: 300,
	duration: 0,
	coolDown: 500,
	cancelable: false,
};
skill = SpecialSkill.TempSafeZone;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Temporary Safe Zone',
	desc: 'Create a temporary safe zone around you. No harm can be done within the zone.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 0,
	duration: 5000,
	coolDown: 10000,
	cancelable: false,
};
skill = SpecialSkill.ForceField;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Force Field',
	desc: 'Release a force field to keep distance and control enemies\' positions.',
	advance: 'Short press to shrink the field, granting 35% and 75% damage reduction for smaller and smallest sizes.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 500,
	duration: 3000,
	coolDown: 5000,
	cancelable: false,
};
skill = SpecialSkill.ElectricField;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Electric Field',
	desc: 'Release an electric field around you, electrocuting enemies inside.',
	advance: 'Double-press for an instant zap. Enhance range by 10% with the Lightning Rod.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 700,
	duration: 1000,
	coolDown: 5000,
	cancelable: false,
};
skill = SpecialSkill.TridentWave;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Trident Wave',
	desc: 'Send a strong wave to push objects.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 0,
	duration: 450,
	coolDown: 600,
	cancelable: false,
};
skill = SpecialSkill.Decoy;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Substitute',
	desc: 'Escape and replace yourself with your tail when you are impaled.',
	advance: '"Rescue" your tail to recover 30% of your original streaks and 50% of your HP.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 1000,
	duration: 0,
	coolDown: 30000,
	cancelable: false,
	autoUse: true,
};
skill = SpecialSkill.TentaBola;
specialSkillInfos[skill] = {
	index: skill,
	name: 'TentaBola',
	desc: 'Throw a bola that traps enemies and continuously deals damage',
	advance: 'Long-press to keep it spinning in place.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 600,
	duration: 3000,
	coolDown: 3000,
	cancelable: false,
};
skill = SpecialSkill.Disguise;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Camouflage',
	desc: 'Turn into an ironmonite or 3 zellyfish, boost or getting damaged will cancel the disguise.',
	advance: 'Can be detected by Detective Gene.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 0,
	duration: 30000,
	coolDown: 5000,
	cancelable: true,
};

skill = SpecialSkill.Dart;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Dart',
	desc: 'Shoot a long distance dart that carries the weapon\'s hazard element.',
	advance: 'Long-press to shoot upon release.',
	type: SpecialSkillType.Bullet,
	max: 0,
	castTime: 750,
	duration: 2000,
	coolDown: 100,
	cancelable: false,
};
skill = SpecialSkill.BatDart;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Bat Dart',
	desc: 'Shoot 2 bat darts that return like boomerangs.',
	advance: 'Long-press to shoot upon release.',
	type: SpecialSkillType.Bullet,
	max: 0,
	castTime: 750,
	duration: 3000,
	coolDown: 100,
	cancelable: false,
};
skill = SpecialSkill.ElectricDart;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Electric Dart',
	desc: 'Shoot 2 electric-charged darts.',
	advance: 'Long-press to shoot upon release. Equip the Lightning Rod, and darts will deplete 50% of opponents\' current HP.',
	type: SpecialSkillType.Bullet,
	max: 0,
	castTime: 750,
	duration: 2000,
	coolDown: 100,
	cancelable: false,
};
skill = SpecialSkill.FireBullet;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Fire Bullet',
	desc: 'Shoot a mid-range fire bullet.',
	advance: 'Long-press to shoot upon release. Transform it into an ice bullet by freezing the weapon.',
	type: SpecialSkillType.Bullet,
	max: 0,
	castTime: 750,
	duration: 2000,
	coolDown: 100,
	cancelable: false,
};
skill = SpecialSkill.SunPunch;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Sun Punch',
	desc: 'Punch in sonic speed to create a mini sun..',
	advance: 'Long-press or boost during the punch will propel you forward',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 500,
	duration: 100,
	coolDown: 5000,
	cancelable: false,
};

skill = SpecialSkill.SwordDance;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Sword Dance',
	desc: 'Summon all weapons in your inventory and control them with your movement.',
	advance: 'The number of weapons affects cooldown.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 500,
	duration: 3000,
	coolDown: 2000,
	cancelable: false,
};
skill = SpecialSkill.StrongArms;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Strong-arms',
	desc: 'Capture and control an enemy stabfish with your long claws.',
	advance: 'Killing a Nemesis with Nemesis resets the control timer.',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 800,
	duration: 10000,
	coolDown: 15000,
	cancelable: false,
};
skill = SpecialSkill.MosaBite;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Mosa Bite',
	desc: 'Break enemies\' weapons in front of you with a powerful bite.',
	advance: 'Beware: breaking a pufferfish family\'s weapon will double their spike damage.',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 500,
	duration: 880,
	coolDown: 5000,
	cancelable: false,
};
skill = SpecialSkill.DragonTrident;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Dragon Axe',
	desc: 'Summon a special axe infused with three elemental powers.',
	advance: 'Weapon  Size +30%; Attack +30%;',
	type: SpecialSkillType.Action,
	max: 0,
	castTime: 0,
	duration: 5000,
	coolDown: 10000,
	cancelable: false,
};
skill = SpecialSkill.Snowball;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Snowball',
	desc: '',
	advance: '',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 1000,
	duration: 5000,
	coolDown: 2000,
	cancelable: false,
};
skill = SpecialSkill.BoxPunch;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Boxing Punch',
	desc: '',
	advance: '',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 200,
	duration: 200,
	coolDown: 0,
	cancelable: false,
};
skill = SpecialSkill.GoldFishing;
specialSkillInfos[skill] = {
	index: skill,
	name: 'Gold Fishing',
	desc: '',
	advance: '',
	type: SpecialSkillType.Release,
	max: 0,
	castTime: 0,
	duration: Number.POSITIVE_INFINITY,
	coolDown: 0,
	cancelable: false,
};

export const passiveSkillInfos: PassiveSkillInfo[] = [];
skill = PassiveSkill.NewbieDefBonus;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Newbie Damage Bonus',
	lvls: [
		{
			lvl: 0,
			desc: 'Damage on Mobs +50%',
			buffs: [
				{ p: 'dmgOnNms', a: 0.5 },
			],
		},

	],
};
skill = PassiveSkill.RookieDefBonus;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Rookie Damage Bonus',
	lvls: [
		{
			lvl: 0,
			desc: 'Damage on Mobs +25%',
			buffs: [
				{ p: 'dmgOnNms', a: .25 },
			],
		},

	],
};
skill = PassiveSkill.ScoreBonus;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Bonus Score',
	lvls: [
		{
			lvl: 1,
			desc: 'Score +25%',
			buffs: [
				{ p: 'scoreScale', a: 0.25 },
			],
		},
		{
			lvl: 2,
			desc: 'Score +50%',
			buffs: [
				{ p: 'scoreScale', a: 0.5 },
			],
		},
	],
};
skill = PassiveSkill.Detective;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Detective',
	lvls: [
		{
			lvl: 1,
			desc: 'Sight +30%; Blindness -40%; Camouflage Detection +40%; Speed +5%;',
			buffs: [
				{ p: 'sightRadiusScale', a: 0.2 },
				{ p: 'blindRes', a: -0.35 },
				{ p: 'speedScale', a: .05 },
			],
		},
		{
			lvl: 2,
			desc: 'Sight +60%; Blindness -99%; Camouflage Detection +99%; Speed +10%;',
			buffs: [
				{ p: 'sightRadiusScale', a: 0.4 },
				{ p: 'blindRes', a: -0.99 },
				{ p: 'speedScale', a: .1 },
			],
		},
	],
};
skill = PassiveSkill.StabScoreBonus;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Bonus Stab Score',
	lvls: [
		{
			lvl: 1,
			desc: 'Stab Score +50%',
			buffs: [
				{ p: 'stabScoreScale', a: 0.5 },
			],
		},
		{
			lvl: 2,
			desc: 'Stab Score +100%',
			buffs: [
				{ p: 'stabScoreScale', a: 1 },
			],
		},
	],
};
skill = PassiveSkill.KillScoreBonus;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Bonus Kill Score',
	lvls: [
		{
			lvl: 1,
			desc: 'Kill Score +33%',
			buffs: [
				{ p: 'killScoreScale', a: 0.33 },
			],
		},
		{
			lvl: 2,
			desc: 'Kill Score +66%',
			buffs: [
				{ p: 'killScoreScale', a: 0.66 },
			],
		},
	],
};
skill = PassiveSkill.Assassin;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Assassin',
	lvls: [
		{
			lvl: 1,
			desc: 'Temporary 200% Weapon Damage upon pulling the weapon',
			buffs: [
				{ p: 'assassin', a: 2 },
			],
		},
		{
			lvl: 2,
			desc: 'Temporary 300% Weapon Damage upon pulling the weapon',
			buffs: [
				{ p: 'assassin', a: 3 },
			],
		},

	],
};
skill = PassiveSkill.AttackUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Attack Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Base Attack +10%',
			buffs: [
				{ p: 'attack', m: 1.1 },
			],
		},
		{
			lvl: 2,
			desc: 'Base Attack +20%',
			buffs: [
				{ p: 'attack', m: 1.2 },
			],
		},
	],
};
skill = PassiveSkill.DefenseUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Defense Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Base Defense +10',
			buffs: [
				{ p: 'defense', a: 10 },
			],
		},
		{
			lvl: 2,
			desc: 'Base Defense +20',
			buffs: [
				{ p: 'defense', a: 20 },
			],
		},
	],
};
skill = PassiveSkill.ChampHunter;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Champion Hunter',
	lvls: [
		{
			lvl: 1,
			desc: 'Kill to initiate the hunt for the PVP zone champion. Deal an extra 5% damage to the champion if they have a higher score. Whoever survives the hunt receives a 5% bonus kill score for the kill. Nemeses Tracker is inactive at lvl 1.',
			buffs: [
				{ p: 'champHunter', a: 1 },
			],
		},
		{
			lvl: 2,
			desc: 'Kill to initiate the hunt for the PvP zone champion. Deal an extra 10% damage to the champion if they have a higher score. Whoever survives the hunt receives a 10% bonus kill score for the kill. Nemeses Tracker is activated!!',
			buffs: [
				{ p: 'champHunter', a: 2 },
				{ p: 'nmsTracker', a: 1 },
			],
		},
	],
};
skill = PassiveSkill.MissileDamageUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Projectile Damage Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Bullet-type Projectile Damage +25%',
			buffs: [
				{ p: 'missileAtt', a: .25 },
			],
		},
		{
			lvl: 2,
			desc: 'Bullet-type Projectile Damage +50%',
			buffs: [
				{ p: 'missileAtt', a: .5 },
			],
		},
	],
};
skill = PassiveSkill.Teamwork;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Teamwork',
	lvls: [
		{
			lvl: 1,
			desc: 'Speed +10%; Stamina Regeneration +50%; Healing effects +80%; Teammates receive 40% of healing effects & you earn 4% of their pts when they score;',
			buffs: [
				{ p: 'speedScale', a: .1 },
				{ p: 'stamRegen', a: .5 },
				{ p: 'friendshipScale', a: 0.4 },
				{ p: 'healScale', a: 0.8 },
				{ p: 'friendScoreScale', a: 0.04 },
			],
		},
		{
			lvl: 2,
			desc: 'Speed +20%; Stamina Regeneration +100%; Healing effects +160%; Teammates receive 80% of healing effects & you earn 8% of their pts when they score;',
			buffs: [
				{ p: 'speedScale', a: .2 },
				{ p: 'stamRegen', a: 1 },
				{ p: 'friendshipScale', a: 0.8 },
				{ p: 'healScale', a: 1.6 },
				{ p: 'friendScoreScale', a: 0.08 },
			],
		},
	],
};
skill = PassiveSkill.MaxHpUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Max HP Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Max HP +25%',
			buffs: [
				{ p: 'maxHp', m: 1.25 },
			],
		},
		{
			lvl: 2,
			desc: 'Max HP +50%',
			buffs: [
				{ p: 'maxHp', m: 1.5 },
			],
		},
	],
};
skill = PassiveSkill.HpRegenUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'HP Regeneration Up',
	lvls: [
		{
			lvl: 1,
			desc: 'HP Auto Regeneration +2',
			buffs: [
				{ p: 'hpRegen', a: 2 },
			],
		},
		{
			lvl: 2,
			desc: 'HP Auto Regeneration +4',
			buffs: [
				{ p: 'hpRegen', a: 4 },
			],
		},
	],
};
skill = PassiveSkill.StamRegenUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Stamina Regeneration Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Stamina Regeneration +100%',
			buffs: [
				{ p: 'stamRegen', a: 1 },
			],
		},
		{
			lvl: 2,
			desc: 'Stamina Regeneration +200%',
			buffs: [
				{ p: 'stamRegen', a: 2 },
			],
		},

	],
};
skill = PassiveSkill.WpnSizeUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Weapon Size Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Weapon Size 9%',
			buffs: [
				{ p: 'spearScale', a: 0.11 },
			],
		},
		{
			lvl: 2,
			desc: 'Weapon Size +18%',
			buffs: [
				{ p: 'spearScale', a: 0.22 },
			],
		},
	],
};
skill = PassiveSkill.ElemResUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Elemental Resistance Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Elemental Resistance +50%',
			buffs: [
				{ p: 'elemRes', a: -0.5 },
			],
		},
		{
			lvl: 2,
			desc: 'Elemental Resistance +90%',
			buffs: [
				{ p: 'elemRes', a: -0.9 },
			],
		},

	],
};
skill = PassiveSkill.EfxTimeUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Negative Status Duration Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Inflict Negative Status Duration +25%',
			buffs: [
				{ p: 'efxTime', a: 0.25 },
			],
		},
		{
			lvl: 2,
			desc: 'Inflict Negative Status Duration +50%',
			buffs: [
				{ p: 'efxTime', a: 0.5 },
			],
		},

	],
};
skill = PassiveSkill.MoneyUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Gold Gained Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Gold Gained from Killing +40%',
			buffs: [
				{ p: 'goldGained', a: .4 },
			],
		},
		{
			lvl: 2,
			desc: 'Gold Gained from Killing +80%',
			buffs: [
				{ p: 'goldGained', a: .8 },
			],
		},
	],
};
skill = PassiveSkill.OreRarityUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Ore Rarity Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Legendary Ore Chance +1%',
			buffs: [
				{ p: 'oreRarity', a: 0.01 },
			],
		},
		{
			lvl: 2,
			desc: 'Legendary Ore Chance +2%',
			buffs: [
				{ p: 'oreRarity', a: 0.02 },
			],
		},

	],
};
skill = PassiveSkill.GemRarityUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Gem Rarity Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Legendary Fish Gem Chance +1%',
			buffs: [
				{ p: 'gemRarity', a: 0.01 },
			],
		},
		{
			lvl: 2,
			desc: 'Legendary Fish Gem Chance +2%',
			buffs: [
				{ p: 'gemRarity', a: 0.02 },
			],
		},

	],
};
skill = PassiveSkill.FoodScoreUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Bonus Food Score',
	lvls: [
		{
			lvl: 1,
			desc: 'Food Score +33%',
			buffs: [
				{ p: 'foodScoreScale', a: 0.33 },
			],
		},
		{
			lvl: 2,
			desc: 'Food Score +66%',
			buffs: [
				{ p: 'foodScoreScale', a: 0.66 },
			],
		},
	],
};
skill = PassiveSkill.Ecologist;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Ecologist',
	lvls: [
		{
			lvl: 1,
			desc: 'Reveal nearby hazards on the minimap and offer an immediate forecast of Armageddon, whirlpools, and other ecological events, while also adapting slightly to them.',
			buffs: [
				{ p: 'ecologist', a: 1 },
				{ p: 'hazardSensor', m: 3 },
			],
		},
		{
			lvl: 2,
			desc: 'Reveal all hazards on the minimap and offer an advance forecast of Armageddon, whirlpools, and other ecological events, while also adapting moderately to them.',
			buffs: [
				{ p: 'ecologist', a: 2 },
				{ p: 'hazardSensor', m: 100 },
			],
		},
	],
};
skill = PassiveSkill.Speedster;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Speedster',
	lvls: [
		{
			lvl: 1,
			desc: 'Speed +10%; Boost Speed +10%;',
			buffs: [
				{ p: 'speedScale', a: .1 },
				{ p: 'boostSpeedScale', a: .1 },
			],
		},
		{
			lvl: 2,
			desc: 'Speed +20%; Boost Speed +20%;',
			buffs: [
				{ p: 'speedScale', a: .2 },
				{ p: 'boostSpeedScale', a: .2 },
			],
		},

	],
};
skill = PassiveSkill.BlindResUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Blind Resistance Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Blind Resistance +33%',
			buffs: [
				{ p: 'blindRes', a: -0.33 },
			],
		},
		{
			lvl: 2,
			desc: 'Blind Resistance +66%',
			buffs: [
				{ p: 'blindRes', a: -0.66 },
			],
		},
		{
			lvl: 3,
			desc: 'Blind Resistance +99%',
			buffs: [
				{ p: 'blindRes', a: -0.99 },
			],
		},

	],
};
skill = PassiveSkill.TinyHero;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Tiny Hero',
	lvls: [
		{
			lvl: 1,
			desc: 'Size -25%; Agility +25%;',
			buffs: [
				{ p: 'bodyScale', a: -0.25 },
				{ p: 'spearScale', a: -0.25 },
				{ p: 'speedScale', a: 0.25 },
				{ p: 'torqueScale', a: 0.25 },
			],
		},
		{
			lvl: 2,
			desc: 'Size -50%; Agility +50%;',
			buffs: [
				{ p: 'bodyScale', a: -0.5 },
				{ p: 'spearScale', a: -0.5 },
				{ p: 'speedScale', a: 0.5 },
				{ p: 'torqueScale', a: 0.5 },
			],
		},

	],
};
skill = PassiveSkill.Vampire;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Vampire',
	lvls: [
		{
			lvl: 1,
			desc: 'Absorb HP & stamina from your bleeding impaled victims Lvl 1',
			buffs: [
				{ p: 'vampire', a: 1 },
			],
		},
		{
			lvl: 2,
			desc: 'Absorb HP & stamina from your bleeding impaled victims Lvl 2',
			buffs: [
				{ p: 'vampire', a: 2 },
			],
		},

	],
};
skill = PassiveSkill.UtilityBelt;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Engineer',
	lvls: [
		{
			lvl: 1,
			desc: 'Increase the projectiles of bullet-type skills Lvl 1',
			buffs: [
				{ p: 'utility', a: 1 },
			],
		},
		{
			lvl: 2,
			desc: 'Increase the projectiles of bullet-type skills Lvl 2',
			buffs: [
				{ p: 'utility', a: 2 },
			],
		},

	],
};
skill = PassiveSkill.SkillTimeUp;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Skill Duration Up',
	lvls: [
		{
			lvl: 1,
			desc: 'Non-release-type Skill Duration Up Lvl 1',
			buffs: [
				{ p: 'skillTime', a: 0.5 },
			],
		},
		{
			lvl: 2,
			desc: 'Non-release-type Skill Duration Up Lvl 2',
			buffs: [
				{ p: 'skillTime', a: 1 },
			],
		},
	],
};
skill = PassiveSkill.CoolDownTimeDown;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Cool Down Time Reduce',
	lvls: [
		{
			lvl: 1,
			desc: 'Cool Down Time -25%',
			buffs: [
				{ p: 'coolDown', a: -0.25 },
			],
		},
		{
			lvl: 2,
			desc: 'Cool Down Time -50%',
			buffs: [
				{ p: 'coolDown', a: -0.5 },
			],
		},
	],
};
skill = PassiveSkill.Revenger;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Revenger',
	lvls: [
		{
			lvl: 1,
			desc: 'Tracks your last stabber or killer. If the target is a player, they will take an extra 6% damage from you. If the target is not a player, all Mobs, Nemeses, and Bosses will take an extra 25% damage from you. Nemeses Tracker is inactive at lvl 1.',
			buffs: [
				{ p: 'revenger', a: 1 },
			],
		},
		{
			lvl: 2,
			desc: 'Tracks your last stabber or killer. If the target is a player, they will take an extra 12% damage from you. If the target is not a player, all Mobs, Nemeses, and Bosses will take an extra 50% damage from you. Nemeses Tracker is activated!!',
			buffs: [
				{ p: 'revenger', a: 2 },
				{ p: 'nmsTracker', a: 1 },
			],
		},
	],
};
skill = PassiveSkill.CnyBlessing;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Lunar New Year Blessing',
	lvls: [
		{
			lvl: 1,
			desc: 'Additional 100% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1 },
			],
		},
		{
			lvl: 2,
			desc: 'Additional 110% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.1 },
			],
		},
		{
			lvl: 3,
			desc: 'Additional 120% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.2 },
			],
		},
		{
			lvl: 4,
			desc: 'Additional 130% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.3 },
			],
		},
		{
			lvl: 5,
			desc: 'Additional 140% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.4 },
			],
		},
		{
			lvl: 6,
			desc: 'Additional 150% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.5 },
			],
		},
		{
			lvl: 7,
			desc: 'Additional 160% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.6 },
			],
		},
		{
			lvl: 8,
			desc: 'Additional 170% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.7 },
			],
		},
		{
			lvl: 9,
			desc: 'Additional 180% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.8 },
			],
		},
		{
			lvl: 10,
			desc: 'Additional 190% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 1.9 },
			],
		},
		{
			lvl: 11,
			desc: 'Additional 200% Gold from Killing',
			buffs: [
				{ p: 'goldGained', a: 2 },
			],
		},

	],
};
skill = PassiveSkill.AttackUpNemesis;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Nemeses Predator',
	lvls: [
		{
			lvl: 1,
			desc: 'Damage Dealt On Nemeses, Mobs, and Bosses +30%',
			buffs: [
				{ p: 'dmgOnNms', a: 0.3 },
			],
		},
		{
			lvl: 2,
			desc: 'Damage Dealt On Nemeses, Mobs, and Bosses +60%',
			buffs: [
				{ p: 'dmgOnNms', a: 0.6 },
			],
		},
	],
};
skill = PassiveSkill.DefenseUpNemesis;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Nemeses Guard',
	lvls: [
		{
			lvl: 1,
			desc: 'Damage Received From Nemeses, Mobs, and Bosses -30%',
			buffs: [
				{ p: 'nmsDamage', a: -0.2 },
			],
		},
		{
			lvl: 3,
			desc: 'Damage Received From Nemeses, Mobs, and Bosses -40%',
			buffs: [
				{ p: 'nmsDamage', a: -0.4 },
			],
		},

	],
};

skill = PassiveSkill.Berserker;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Berserker',
	lvls: [
		{
			lvl: 1,
			desc: 'Attack +20%; Defense -10;',
			buffs: [
				{ p: 'attack', m: 1.2 },
				{ p: 'defense', a: -10 },
			],
		},
		{
			lvl: 2,
			desc: 'Attack +40%; Defense -20;',
			buffs: [
				{ p: 'attack', m: 1.4 },
				{ p: 'defense', a: -20 },
			],
		},
	],
};
skill = PassiveSkill.Daredevil;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Daredevil',
	lvls: [
		{
			lvl: 1,
			desc: 'Attack +25% & Defense +5 when HP drops below 25%.',
			buffs: [
				{ p: 'daredevil', a: 1 },
			],
		},
		{
			lvl: 2,
			desc: 'Attack +50% & Defense +10 when HP drops below 25%',
			buffs: [
				{ p: 'daredevil', a: 2 },
			],
		},
	],
};
skill = PassiveSkill.Vegetarian;
passiveSkillInfos[skill] = {
	index: skill,
	name: 'Vegetarian',
	lvls: [
		{
			lvl: 1,
			desc: 'A pumpkin grows on your weapon every 6 seconds if sufficient space is provided.',
			buffs: [
				{ p: 'vegetarian', a: 6000 },
			],
		},
		{
			lvl: 2,
			desc: 'A pumpkin grows on your weapon every 3 seconds if sufficient space is provided.',
			buffs: [
				{ p: 'vegetarian', a: 3000 },
			],
		},
	],
};
