import { Point } from '@/util/rotate';
import { DecoSet } from '../infos/decorativeInfos';
import { EmojiType } from '../infos/emojiInfos';
import { PlayerDeathReason, PlayerMode, PlayerType, StababDeathReason } from '../infos/enums';
import { BroadcastEvent, ChatType, MuteType } from '../infos/eventType';
import { FishType } from '../infos/fishInfos';
import { FoodType } from '../infos/foodInfos';
import { HazardType } from '../infos/hazardInfos';
import { ItemCode } from '../infos/itemInfos';
import { OreColor, OreType } from '../infos/oreInfo';
import { MiscData } from './skills/miscContainer';

export enum PlayerGameState {
	None,
	Spectate,
	Waiting,
	Active,
	Stabab,
	Dead,
	GameOver,
	ArmageddonOver,
	FishBolt,
}
export enum DamageTargetType {
	ToPlayer,
	ToMisc,
	ToMechguin,
	ToPearl,
}
export enum DamageType {
	Spear,
	Spike,
	Skill,
	Hazard,
}

export interface RoomState {
	t: number,
	rt: number,
	ranks: ScoreboardData[],
	evts: BroadcastEvent[],
	ps: PlayerData[],
	fds: FoodData[],
	ors: OreData[],
	mys: MyData[],
	misc: MiscData[],
	hz: {
		f: HazardData[],
		i: HazardData[],
		e: HazardData[],
	};
}

export interface BroadcastState {
	t: number,
	rt: number,
	ranks: ScoreboardData[],
	evts: BroadcastEvent[],
	pids: number[],
	ps: PlayerData[],
	fds: FoodData[],
	ors: OreData[],
	mids: number[],
	misc: MiscData[],
	my: MyData,
	hz: {
		f: HazardData[],
		i: HazardData[],
		e: HazardData[],
	};
}

export interface SkillData {
	sk: number,
	ac: boolean,
	db: boolean,
}
export interface SpecialSkillData {
	sk: number,
	ac: boolean,
	cd: number,
	db: boolean,
	b: number,
	max: number,
}

export interface ScoreboardData {
	name: string,
	pid: number,
	score: number,
	st?: number, // highscore: 1-pve, 2-pvp; //pvp: 0-inactive 1-active
}

export interface HazardData {
	id: number,
	x: number,
	y: number,
	isActive?: boolean,
	deg?: number,
}

export interface PlayerData {
	name: string,
	id: number,
	tid: string,
	st: number,
	ft: FishType,
	pt: PlayerType,
	mobLvl: number,
	ds: DecoSet | null,
	maxHp: number,
	skill1: boolean,
	skill2: boolean,
	skill3: boolean,
	spd: number,
	sLvl: number,
	champion: boolean,
	stababs: StababData[],
	hasSpear: boolean,
	frozen: boolean,
	burn: boolean,
	paralyzed: boolean,
	stabbed: number,

	n: {
		blink: number,
		stun: number,
		x: number,
		y: number,
		hp: number,
		bodyRadius: number,
		spearW: number,
		spearH: number,
	},
	d: {
		deg: number,
	};
}

export interface StababData {
	n: {
		dist: number,
		scale: number,
		temp: number,
	},
	deg: number,
	state: StababState,
	ft: FishType,
	pid: number,
	id: number,
	frozen: boolean,
	burn: boolean,
	paralyzed: boolean,
}

export enum StababState {
	Normal,
	Delay,
	Experdite,
	Dead,
}
export interface FoodData {
	n: {
		x: number,
		y: number,
		tx: number,
		ty: number,
	},
	ft: FoodType,
	id: number,
	score: number,
	eatenTime: number,
	deathTime: number,
}
export interface OreData {
	x: number,
	y: number,
	color: OreColor,
}

export interface MyData {
	// pt: PlayerType,
	sid: string,
	tid: string,
	pid: number | undefined,
	pt: PlayerType,
	mobLvl: number,
	userInfo: any,
	rank: number,
	stab: number,
	streak: number,
	kills: number,
	kStreak: number,
	state: PlayerGameState,
	maxHp: number,
	lf: number,
	lf0: number,
	lf1: number,
	lf2: number,
	team: number[],
	bfs: string,
	n: {
		hscore: number,
		scoreL: number,
		money: number,
		sr: number,
		vx: number,
		vy: number,
		hp: number,
		stm: number,
		blood: number,
		reinc: number,
		s2: number,
	},
	ts: TrackerData[],

	sk1: SkillData,
	sk2: SkillData | SpecialSkillData,
	sk3: SkillData,
	evts: BroadcastEvent[],
}

export enum TrackerType {
	None,
	Champion,
}

export interface TrackerData {
	id: string,
	type: TrackerType,
	n: {
		x: number,
		y: number,
	};
}

export interface BroadcastStabEvent extends BroadcastEvent {
	pidS: number,
	pidV: number,
	stabStreak: number,
	score: number,
	pos: Point,
	norm: Point,
}

export interface BroadcastPlayerDeathEvent extends BroadcastEvent {
	pidK: number,
	pidV: number,
	deathBy: PlayerDeathReason,
	stababDeathBy?: StababDeathReason,
	pos: Point,
	lifeth: number,
}

export interface BroadcastDamageEvent extends BroadcastEvent {
	pidD?: number,
	pidV?: number,
	damage: number,
	dt?: DamageType,
	to?: DamageTargetType,
	crit?: number,
	pos: Point,
	norm?: Point,
}
export interface BroadcastSpearHitSpearEvent extends BroadcastEvent {
	pidA: number,
	pidB: number,
	pos: Point,
	// norm: Point,
}
export interface BroadcastBonkEvent extends BroadcastEvent {
	pidB: number,
	pidV: number,
	pos: Point,
}

export interface BroadcastStunEvent extends BroadcastEvent {
	pidS: number,
	pidV: number,
	stun: number,
	pos: Point,
	norm: Point,
}
export interface BroadcastSpearHitOreEvent extends BroadcastEvent {
	oid: number,
	pid: number,
	pos: Point,
	norm: Point,
	// damage: number,
	// isDeathBlow: boolean,
	// col: OreColor,
}
export interface BroadcastSpearHitShieldEvent extends BroadcastEvent {
	pid: number,
	pos: Point,
	norm: Point,
}
export interface BroadcastSpearHitBottleEvent extends BroadcastEvent {
	bid: number,
	bPos: Point,
	pos: Point,
	norm: Point,
	pid: number,
	// damage: number,
	// isDeathBlow: boolean,
	// col: OreColor,
}
export interface BroadcastBodyHitWallEvent extends BroadcastEvent {
	pid: number,
	pos: Point,
	norm: Point,
}
export enum LootSpecial {
	None,
	Pirate,
	Zompkin,
	Xmas,
	Cny,
}
export interface BroadcastLootEvent extends BroadcastEvent {
	item: number,
	amount: number,
	pos: Point,
	pid: number,
	s?: LootSpecial;
}
export interface BroadcastTailEvent extends BroadcastEvent {
	id: number,
	pos: Point,
	pid: number,
}
export interface BroadcastJobuffEvent extends BroadcastEvent {
	ps: Point[],
	pid: number,
}

export interface BroadcastBuffEvent extends BroadcastEvent {
	pid: number,
	bid: string,
	tid: string,
	pos: Point,
}
export interface BroadcastEmojiEvent extends BroadcastEvent {
	pid: number,
	eid: EmojiType,
	tid: string,
	pos: Point,
}

export interface BroadcastPublicChestEvent extends BroadcastEvent {
	pos: Point,
	pid: number,
	loot: Array<[ItemCode, number]>,
}

export interface BroadcastCorpseToFoodEvent extends BroadcastEvent {
	pos: Point,
}
export interface BroadcastJoinTempTeamEvent extends BroadcastEvent {
	isOldTeam: boolean,
}
export interface BroadcastNewMemberEvent extends BroadcastEvent {
	name: string,
}
export interface BroadcastChatEvent extends BroadcastEvent {
	msg: string,
	uid: string,
	ct?: ChatType,
}
export interface BroadcastMuteEvent extends BroadcastEvent {
	uid: string,
	mt: MuteType,
	v: boolean,
}
export interface BroadcastSecretRoomEvent extends BroadcastEvent {
	x: number,
	y: number,
	d: number,
}
export interface BroadcastSecretRoomFoundEvent extends BroadcastEvent {
	uid: string,
	n: string,
	d: number,
	pid?: number,
}
export interface BroadcastWhirlpoolEvent extends BroadcastEvent {
	x: number,
	y: number,
	et: number,
}
export interface BroadcastOuroborosOpenEvent extends BroadcastEvent {
	x1: number,
	y1: number,
	x2: number,
	y2: number,
	n?: number,
}
export interface BroadcastForecastWhirlpoolEvent extends BroadcastEvent {
	x: number,
	y: number,
	et: number,
}
export interface BroadcastForecastArmageddonEvent extends BroadcastForecastWhirlpoolEvent {
	d: number,
}
export interface BroadcastForecastOuroborosOpenEvent extends BroadcastEvent {
	x1: number,
	y1: number,
	x2: number,
	y2: number,
	et: number,
}
export interface BroadcastArmageddonExposeEvent extends BroadcastEvent {
	l: number,
}
export interface BroadcastSwordBreakEvent extends BroadcastEvent {
	pidB: number,
	pidV: number,
	pos: Point,
	s: number,
}

export interface BroadcastDojoEvent extends BroadcastEvent {
	uid: string,
	did: number,
}
export interface BroadcastDojoDisbandEvent extends BroadcastEvent {
	uid: string,
}
export interface BroadcastDojoInvitesEvent extends BroadcastEvent {
	set?: string[],
	add?: string,
	remove?: string,
	setM?: string[],
	addM?: string,
	removeM?: string,
	setS?: string[],
	addS?: string,
	removeS?: string,
}
export interface BroadcastDojoHostChangeEvent extends BroadcastEvent {
	uid1: string,
	uid2: string,
	did: number,
}
export interface BroadcastRecessGeneEvent extends BroadcastEvent {
	g: number,
}
export interface BroadcastHookedEvent extends BroadcastEvent {
	pid: number,
	p: Point,
	d: Point,
}
