











































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import ItemButton from '../tools/inventory/ItemButton.vue';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import { wait } from '@/util/wait';
import { itemInfos, Rarity } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { ItemIntelData } from './ItemIntelTooltip.vue';
@Component({
  components: {
    ItemButton,
  },
})
export default class ItemInventory extends Vue {
  public get infos() {
    return this.items.map((i) => itemInfos[i.itemCode]);
  }
  @Prop({ default: () => [] }) public items!: ItemCodeAmountPair[];
  @Prop({ default: true }) public showTooltip!: boolean;
  @Prop({ default: true }) public showRarity!: boolean;
  @Prop({ default: true }) public selectable!: boolean;
  @Prop({ default: 'i' }) public idPrefix!: string;
  @Prop({ default: 0 }) public activeItemCode!: number;
  @Prop({ default: 0 }) public selectedItemCode!: number;
  @Prop({ default: () => [] }) public disabledItems!: boolean[];

  public width = 0;
  public height = 400;
  public numBoxes = 0;

  public getRarity(index: number) {
    const info = this.infos[index];
    const rarity = Global.rarityLabels[info.rarity];
    const color = Global.rarityColors[info.rarity];
    return `<div class="text-sm font-italic" style="color:${color}">${rarity}</div>`;
  }
  public async mounted() {
    await wait(1);
    if (!(this.$el as any).resizeSensor) {
      const res = new ResizeSensor(this.$el, this.onResize);
    }
    this.onResize();
  }
  public beforeDestroy() {
    if ((this.$el as any).resizeSensor) {
      ResizeSensor.detach(this.$el);
    }
  }
  public onResize() {
    this.width = this.$el.clientWidth;
    this.height = this.$el.clientHeight;
    const col = Math.floor(this.width / 80) || 1;
    let min = col * Math.floor(400 / 80);
    while (min < this.items.length) {
      min += col;
    }
    this.numBoxes = min;
  }

  public showItemIntel(index: number) {
    if (!this.items[index]) { return; }
    const id = this.idPrefix + '-inventory-item-' + (index + 1);
    if (this.showTooltip) {
      this.$root.$emit('bv::show::tooltip', id);
      return;
    }
    const data: ItemIntelData = {
      targetId: this.idPrefix + '-inventory-item-' + (index + 1),
      placement: 'top',
      container: 'item-inventory',
      itemCode: this.items[index].itemCode,
    };
    this.$root.$emit('showItemIntel', data);
  }
  public hideItemIntel() {
    if (this.showTooltip) {
      this.$root.$emit('bv::hide::tooltip');
    } else {
      this.$root.$emit('hideItemIntel');
    }
  }
}
