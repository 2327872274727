








































































































































import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import NewsPost from './news/NewsPost.vue';
import globalx from '@/store/modules/globalx';
import { TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';
import NewDecoComponent from './NewDecoComponent.vue';
import { ItemCode } from '@/game/infos/itemInfos';
import { ItemIntelData } from '../../bag/ItemIntelTooltip.vue';
import DecoContestPoster from '@/components/ui/decoContest/DecoContestPoster.vue';
import CnyPoster from '@/components/ui/decoContest/CnyPoster.vue';
import CnyBanner from '@/components/ui/decoContest/CnyBanner.vue';
import ProductButtonComponent from '@/components/shop/ProductButtonComponent.vue';

@Component({
	components: {
		NewDecoComponent,
		DecoContestPoster,
		CnyPoster,
		CnyBanner,
		ProductButtonComponent,
		NewsPost,
		NewsPost4: () => import('./news/NewsPost4.vue'),
		NewsPost3: () => import('./news/NewsPost3.vue'),
		NewsPost2: () => import('./news/NewsPost2.vue'),
		NewsPost1: () => import('./news/NewsPost1.vue'),
		NewsPost0: () => import('./news/NewsPost0.vue'),
	},
})
export default class Newspaper extends Vue {
	@Prop({ default: 10 }) public readRemains!: number;

	public showNews = 5;

	@Watch('readRemains')
	public onReadRemainsChanged(newVal: number) {
		if (this.readRemains < 1 && this.showNews > 0) {
			this.showNews--;
		}
	}

	public onMounted(value: number) {
		this.showNews = value;
	}
	public showItemIntel(id: string, itemCode: ItemCode) {
		const data: ItemIntelData = {
			targetId: id,
			placement: 'top',
			container: 'item-inventory',
			itemCode,
		};
		this.$root.$emit('showItemIntel', data);
	}
	public hideItemIntel() {
		this.$root.$emit('hideItemIntel');
	}
	public get autoTimeLimitedEvent() {
		return globalx.autoTimeLimitedEvent;
	}
	public get hasTimeLimited() {
		return globalx.autoTimeLimitedEvent && globalx.autoTimeLimitedEvent < TimeLimitedQuestCode.PostHalloween || globalx.autoTimeLimitedEvent === TimeLimitedQuestCode.PirateDay;
	}
	public get decoContest() {
		return globalx.decoContest;
	}
	public get decoStage() {
		return globalx.autoDecoContestStage;
	}
	public get decoLink() {
		return this.decoStage === 'vote' ? '/decoContest/vote'
			: '/decoContest/';
	}
	public onCnyPurchase() {
		Global.cnyShopModal.show();
	}
}
