import { DecoPinchArea } from '@/components/ui/mainMenu/tools/decoEditor/decoPinchArea';
import { Global } from '@/store/globalz';
import { Tween } from '@/util/tweents';
import { AdjustmentFilter } from 'pixi-filters';
import { Container } from 'pixi.js';

export class DecoEditorControl extends Container {

	public modelContainer = new Container();
	public area = new DecoPinchArea();
	public modelTargetX = 0;
	public modelTargetY = 0;
	public modelTargetScale = 1;
	public filter = new AdjustmentFilter({ alpha: 0 });

	public constructor() {
		super();
		this.filters = [this.filter as any];
		this.area.visible = true;
		this.addChild(this.modelContainer);
		this.addChild(this.area);

		(window as any).avatar = Global.modelAvatar;
		this.modelContainer.addChild(Global.modelAvatar);
		this.area.on('pinchset', () => {
			this.emit('pinchset');
		});
		this.area.on('pinchend', () => {
			this.emit('pinchend');
		});
		this.area.on('pinchmove', (delta) => {
			this.emit('pinchmove', delta);
		});
	}

	public setSize(width: number, height: number) {
		if (width <= 640) {
			this.modelTargetX = width / 2;
			this.modelTargetY = (height - 150) / 2 + 110;
			const maxWidth = width - 20;
			const maxHeight = height - 170;
			this.modelTargetScale = Math.min(maxWidth / 180, maxHeight / 150, 2.5);
		} else if (width < 960) {
			this.modelTargetX = (width - 320) / 2;
			this.modelTargetY = (height - 150) / 2 + 160;
			const maxWidth = width - 340;
			const maxHeight = height - 170;
			this.modelTargetScale = Math.min(maxWidth / 180, maxHeight / 150, 2.5);
		} else {
			this.modelTargetX = width / 2;
			this.modelTargetY = height / 2;
			const maxWidth = width - 660;
			const maxHeight = height - 20;
			this.modelTargetScale = Math.min(maxWidth / 180, maxHeight / 150, 2.5);
		}
		this.area.x = this.modelTargetX;
		this.area.y = this.modelTargetY;
		this.area.scale.set(this.modelTargetScale);
		this.modelContainer.x = this.modelTargetX;
		this.modelContainer.y = this.modelTargetY;
		this.modelContainer.scale.set(this.modelTargetScale);

	}

	public show() {
		this.filter.alpha = 0;
		Tween.get(this.filter).to({ alpha: 1 }, 500);
	}
	public hide() {
		Tween.get(this.filter).to({ alpha: 0 }, 300).call(() => {
			this.parent.parent.removeChild(this.parent);
		});
	}
}
