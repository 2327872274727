
































































import gamex from '@/store/modules/gamex';
import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishSkillComponent from '../../mainMenu/tools/fishSelector/FishSkillComponent.vue';
import globalx from '@/store/modules/globalx';
import { PlayerMode } from '@/game/infos/enums';
@Component({
	components: {
		FishSkillComponent,
	},
})
export default class UtilityRankComponent extends Vue {
	public get ranks() {
		return gamex.currentGameState.ranks.slice(1, 6);
	}
	public get myPid() {
		return gamex.currentGameState.pid;
	}
	public get myScore() {
		return gamex.highscore;
	}
	public get todayHighscore() {
		return (globalx.rankings?.todayHighscores[0].score) || 0;
	}
	public get myRank() {
		return gamex.currentGameState.rank === 255 ? '?' : (gamex.currentGameState.rank + 1);
	}
	public get myName() {
		return userx.userDoc.name;
	}
	public get myKills() {
		return gamex.kills;
	}
	public get myCurrentKillStreak() {
		return gamex.currentGameState.kStreak;
	}
	public get myKillStreakRecord() {
		return gamex.killStreakRecord;
	}
	public get myNmsStageRecord() {
		return gamex.nmsStageRecord;
	}
	public get myStabs() {
		return gamex.stabs;
	}
	public get myStabStreakRecord() {
		return gamex.stabStreakRecord;
	}
	public get myCurrentStabStreak() {
		return gamex.currentGameState.streak;
	}
	public get myCurrentLifeScore() {
		return gamex.highscore - gamex.previousLifeScore;
	}
	public tab: 'highscore' | 'personal' = 'highscore';
	public addComma = addComma;
	public mounted() {
		this.$el.addEventListener('contextmenu', (e) => {
			e.preventDefault();
		});
	}
	public beforeDestroy() {
		this.$el.removeEventListener('contextmenu', (e) => {
			e.preventDefault();
		});
	}
}
