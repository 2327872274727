

































import { getChallengeProgress } from '@/game/infos/firestoreFiles';
import { FishType } from '@/game/infos/fishInfos';
import {
  UnlockChallengeProgress,
  UnlockSteps,
} from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishChallengeComponent from './FishChallengeComponent.vue';

@Component({
  components: {
    FishChallengeComponent,
  },
})
export default class FishUnlockStepsComponent extends Vue {
  @Prop({ default: null }) public steps!: UnlockSteps | null;
  @Prop({ default: false }) public reading!: boolean;

  public get completedStep() {
    if (!this.steps) {
      return 0;
    }
    const progresses = userx.userDoc.unlockProgress;
    for (let i = 0; i < this.steps.length; i++) {
      const challenges = this.steps[i];
      for (const c of challenges) {
        const progress = getChallengeProgress(
          progresses,
          c.unlockId,
          c.step,
          c.index,
        );
        if (!progress.completed) {
          return i;
        }
      }
    }
    return this.steps.length;
  }
}
