import { RoomControl } from '@/client/controls/roomControl';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { MiscData, MiscDataSsTridentWave } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container } from 'pixi.js';
import Factory from '../..';
import { tint } from '../../helper';
import { MiscSymbol } from './miscSymbol';
import { Rotate } from '@/util/rotate';


export class SsTridentWaveSymbol extends Container implements MiscSymbol {

	// public missileIndicator = getParticle(Particle.MissileIndicator);

	public data: MiscDataSsTridentWave | null = null;

	public init() {
		// this.gotoAndStop('idle');
		this.scale.set(3);
	}
	public dispose(room: RoomControl) {
		this.data = null;
		if (this.parent) {
			Tween.get(this).to({ alpha: 0 }, 500).call(() => {
				if (this.parent) {
					this.parent.removeChild(this);
				}

				Factory.pool(SsTridentWaveSymbol, this);
			});
		} else {
			Factory.pool(SsTridentWaveSymbol, this);
		}

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataSsTridentWave;
		if (!this.data) {
			this.alpha = 0;
			Tween.get(this).to({ alpha: 1 }, 200);
			new SoundEfx('wave').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .2 });
		}
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d;
		this.data = data;

	}
	public show(room: RoomControl) {
		room.wallBottomContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}

}
