import { DecoPinchArea } from '@/components/ui/mainMenu/tools/decoEditor/decoPinchArea';
import { Global } from '@/store/globalz';
import { Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import { Particle, getParticle } from '../factory/particles';
import { FishType } from '@/game/infos/fishInfos';
import { Decoratives } from '@/components/ui/mainMenu/tools/decoEditor/decorative';
import { DecoSet } from '@/game/infos/decorativeInfos';
import userx from '@/store/modules/userx';
import { AdjustmentFilter } from 'pixi-filters';

export class FishSelectorControl extends Container {
	public get size() {
		return this._size;
	}
	public set size(value) {
		this._size = value;
		Global.selectedAvatar.setBodyScale(this.size);
		this.shadow.scale.set(this.size);
	}
	public get fishType(): FishType {
		return this._fishType;
	}
	public set fishType(value: FishType) {
		this._fishType = value;
		const avatar = Global.selectedAvatar;
		const fishType = this.fishType;
		avatar.setFishType(fishType, this.skinGroup);
		avatar.setAction('move');
		this.decoSet = userx.decoSets[this.fishType];
		this.skinGroup = userx.equippedSkins[this.fishType] || 0;
		Decoratives.generateDeco(avatar, this.decoSet);
		Global.selectedAvatar.setBodyScale(this.size);
		this.shadow.scale.set(this.size);
		Global.selectedAvatar.setTint(this.locked ? 0x999999 : 0xffffff);
	}
	public get locked() {
		return this._locked;
	}
	public set locked(value) {
		this._locked = value;
		Global.selectedAvatar.setTint(this.locked ? 0x999999 : 0xffffff);
	}
	public get skinGroup() {
		return this._skinGroup;
	}
	public set skinGroup(value) {
		this._skinGroup = value;
		const avatar = Global.selectedAvatar;
		avatar.setFishType(this.fishType, this.skinGroup);
		avatar.setAction('move');
	}
	public get decoSet(): DecoSet | null {
		return this._decoSet;
	}
	public set decoSet(value: DecoSet | null) {
		this._decoSet = value;
		const avatar = Global.selectedAvatar;
		avatar.setAction('move');
		Decoratives.generateDeco(avatar, this.decoSet);
	}

	public avatarContainer = new Container();
	public shadow = getParticle(Particle.Shadow);
	public graphics = new Graphics();
	public el: HTMLElement | null = null;
	public filter = new AdjustmentFilter({ alpha: 0 });
	protected loopBound = this.loop.bind(this);

	protected isShown = false;

	private _size = 1;

	private _fishType: FishType = FishType.BlueShark;

	private _locked = true;
	private _skinGroup = 0;
	private _decoSet: DecoSet | null = null;
	public constructor() {
		super();
		this.filters = [this.filter as any];
		this.addChild(this.graphics);
		// const width = this.width;
		this.addChild(this.avatarContainer);
		this.shadow.angle = 45;
		this.avatarContainer.addChild(this.shadow);
		// this.avatarContainer.x = width / 2;
		// this.avatarContainer.y = width / 2;
		this.avatarContainer.scale.set(2);
		this.avatarContainer.addChild(Global.selectedAvatar);
		// Global.selectedAvatar.setBodyScale(this.size);
		// this.shadow.scale.set(this.size);
	}

	public setSize(width: number, height: number) {

	}
	public show() {
		this.isShown = true;
		this.filter.alpha = 0;
		Tween.get(this.filter).to({ alpha: 1 }, 500);
		this.el = document.getElementById('fishSelectorAvatarContainer');
		requestAnimationFrame(this.loopBound);
	}
	public hide() {
		Tween.get(this.filter).to({ alpha: 0 }, 300).call(() => {
			this.parent.parent.removeChild(this.parent);
			this.isShown = false;
			// this.el = null;
		});
	}

	protected loop() {
		const el = this.el;
		if (el) {
			const bound = el.getBoundingClientRect(); this.avatarContainer.scale.set(bound.width / 400 / Global.appScale);
			this.avatarContainer.x = (bound.left + bound.width / 2) / Global.appScale;
			this.avatarContainer.y = (bound.top + bound.height / 2) / Global.appScale;
		}
		if (this.isShown) {
			requestAnimationFrame(this.loopBound);
		}
	}
}
