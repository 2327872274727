import { BroadcastDamageEvent, DamageTargetType, DamageType } from '@/game/multithread/state';
import { Pool } from '@/util/pool';
import { Point } from '@/util/rotate';
import { BitmapText, Container, Ticker } from 'pixi.js';

function quintIn(x: number) {
	return x * x * x * x * x;
}
const duration = 600;

export class DamageNumberControl {

	public static get() {
		const control = DamageNumberControl._pool.get();
		return control;
	}
	private static _pool: Pool<DamageNumberControl> = new Pool(DamageNumberControl);

	// public onMouseDown(e: MouseEvent) {
	// 	const target = { x: e.clientX, y: e.clientY };
	// 	const start = { x: e.clientX + 100, y: e.clientY + 100 };
	// 	this.init(start, target);
	// }
	// public onMouseMove(e: MouseEvent) {
	// 	this.target = { x: e.clientX, y: e.clientY };
	// }

	public speed: Point = { x: 0, y: 0 };
	public startTime = 0;


	public container = new Container();
	protected text = new BitmapText('20', { fontName: 'TitanOneShaded', fontSize: 40, align: 'center' });

	constructor() {

		this.container.addChild(this.text);
		this.text.anchor.set(.5, .5);

		// window.addEventListener('click', this.onMouseDown.bind(this));
		// window.addEventListener('mousemove', this.onMouseMove.bind(this));
	}

	public ticker = () => {
		const ct = Date.now();
		const timePassed = (ct - this.startTime);

		this.speed.y += 0.5;
		this.container.x += this.speed.x;
		this.container.y += this.speed.y;

		if (timePassed > duration) {
			this.dispose();
		}
	}
	public set(event: BroadcastDamageEvent, myId: number) {
		if (event.pidV! === myId || event.to === DamageTargetType.ToPearl) {
			this.text.tint = 0xC1421D;
		} else {
			this.text.tint = event.dt === DamageType.Spike ? 0xD4C11D : 0xC7D4E2;
		}
		this.speed.y = -10;
		this.speed.x = Math.random() * 10 - 5;
		this.container.x = event.pos.x;
		this.container.y = event.pos.y;
		this.startTime = Date.now();
		this.text.text = event.damage.toFixed(0);
		let scale = event.dt === DamageType.Spike ? 0.75 : 1;
		if ((event.crit || 0) > 0) {
			scale *= 1.5;
		}
		this.container.scale.set(scale);
		Ticker.shared.add(this.ticker);
	}


	public dispose() {
		this.speed = { x: 0, y: 0 };
		Ticker.shared.remove(this.ticker);
		if (this.container.parent) {
			this.container.parent.removeChild(this.container);
		}
		DamageNumberControl._pool.pool(this);
	}
}
