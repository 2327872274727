import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import { decodeDecoSet, decoLimitCost, DecoSet, encodeDecosSet } from '@/game/infos/decorativeInfos';
import { availableFishes, fishInfos, FishType } from '@/game/infos/fishInfos';
// import FirestoreFakeGet from '@/views/util/fakeget/firestoreFakeGet';
import { ProcessState, LoginStatus, LoginSubmission, SignInAndResetPasswordSubmission, StateKeyPairs, Signature } from '../models.def';
import fb from '../sf-firestore';
import { OAuthSigninMethod, SignLinkError, User } from '../firestore';
import { BossRecord, getChallengeProgress, getDefaultStabfishAccountData, getDefaultStabfishSettings, getEmptyInventory, getEmptyTeam, getQuestProgress, PoqIndentity, PoqWallet, StabfishAccountData, StabfishAccountInventory, StabfishTeamDoc, StabfishUserSettings } from '@/game/infos/firestoreFiles';
import { ChangeTeamLeaderRequest, ClaimQuestRequest, ConvertAllDuplicateSkinsRequest, ConvertDuplicateSkinsRequest, EquipSkinRequest, GachaRequest, JoinTeamRequest, PeaceModeVote, PoqConnectRequest, ShopLimitedRequest } from '@/game/infos/dataServerPackets';
import { StabfishError } from '@/util/error';
import { fishUnlockRequirements } from '@/game/infos/fishUnlockRequirements';
import { Dictionary } from 'vue-router/types/router';
import { ItemCodeAmountPair, Quest, QuestProgress, QuestType, UnlockChallenge, UnlockChallengeType } from '@/game/infos/unlockModels';
import globalx from './globalx';
import { ItemCode, itemInfos } from '@/game/infos/itemInfos';
import { alertAdmin, Global } from '../globalz';
import { MainQuestCode, mainQuests } from '@/game/infos/questMain';
import { weeklyQuests } from '@/game/infos/questWeekly';
import { timeLimitedQuests } from '@/game/infos/questTimeLimited';
import { decodeSkinId, SkinGroup, skinInfos } from '@/game/infos/skinInfos';
import { BlackPearlPurchaseType } from '@/game/infos/blackPearlInfos';
import tipx from './tipx';
import { wait } from '@/util/wait';
import { LocalData } from '@/util/localData';

@Module({
	namespaced: true,
	name: 'userx',
	store,
	dynamic: true,
})
class UserModule extends VuexModule {

	public get lifesUnlocked() {
		const lvl = this.userDoc.lvl;
		const result = [true, lvl > 1, lvl > 2];
		return result;
	}
	get uid(): string | null | undefined {
		return (this.user && this.user.uid);
	}
	public signinState: ProcessState = ProcessState.Undefined;
	public loginStatus: LoginStatus = 'loading';
	public holdLoginStatus = false;
	public user: User | null = null;
	public termsSignature: Signature | null = null;
	public privacySignature: Signature | null = null;
	public userDocLoaded: boolean = false;
	public emailVerificationSent = false;
	public signLinkError: SignLinkError | null = null;

	// firestore docs
	public userDoc: StabfishAccountData = getDefaultStabfishAccountData('');
	public inventory: StabfishAccountInventory = getEmptyInventory();
	public teamData: StabfishTeamDoc | null = null;
	public userSettings: StabfishUserSettings = getDefaultStabfishSettings();

	public decoSets: Dictionary<DecoSet | null> = {};
	public get equippedSkins(): Dictionary<SkinGroup | undefined> {
		return this.inventory.equippedSkins;
	}
	// public fishUnlocked: boolean[] = fishInfos.map((f, i) => true);
	public masterUnlocked: Dictionary<boolean> = {};
	public oldUnreadCompletedChallenges: string[] | null = null;

	public oldLvl: number = 0;
	public oldExp: number = 0;
	public oldFishUnlockProgress: number[] = [];
	public oldBossRecord: Dictionary<Dictionary<BossRecord>> = {};
	public fishUnlockProgress: number[] = [];
	public fishCurrentUnlockChallenges: Array<UnlockChallenge | null> = [];
	public pendingFishUnlocks: boolean[] = [];

	public materials: ItemCodeAmountPair[] = [];
	public decos: ItemCodeAmountPair[] = [];
	public blueprints: ItemCodeAmountPair[] = [];
	public tokens: ItemCodeAmountPair[] = [];
	public skins: ItemCodeAmountPair[] = [];
	public pets: ItemCodeAmountPair[] = [];

	public peaceModeVote = LocalData.load('peaceVote') || {
		uid: '',
		keep: -1,
		player: -1,
		middle: false,
	};

	// poq
	public poqWallet: PoqWallet = { balance: 0, allocation: 0 };

	public get needTutorial() {
		return this.userDoc.lvl < 2 && (this.userDoc.tutorial || 0) < 6;
	}
	public get heroes(): FishType[] {
		return this.userSettings.lastHeroes;
	}
	public get fishUnlocked() {
		return this.userDoc.unlockedFishes;
	}
	public get unreadCompletedFishChallenges() {
		return this.userSettings.completedChallenges.filter((c) => c.charAt(0) === 'F');
	}
	public get toShowCompletedChallenges() {
		return this.userSettings.completedChallenges.filter((c) => !this.oldUnreadCompletedChallenges || !this.oldUnreadCompletedChallenges.includes(c));
	}
	public get unreadCompletedQuestChallenges() {
		return this.userSettings.completedChallenges.filter((c) => c.charAt(0) !== 'F');
	}
	public get claimableQuests() {
		const list: Quest[] = [];
		const checkQuest = (quest: Quest, progresses: Dictionary<QuestProgress>) => {
			if (!quest) {
				return;
			}
			const progress = getQuestProgress(progresses, quest.code);
			const numSteps = quest.steps.length;
			if (progress.currentStep >= numSteps) {
				return;
			}
			let completed = true;
			const step = quest.steps[progress.currentStep];
			if (step) {
				for (let i = 0; i < step.challenges.length; i++) {
					const challenge = step.challenges[i];
					if (challenge.type === UnlockChallengeType.UnlockFish) {
						if (!this.fishUnlocked[challenge.goal]) {
							completed = false;
							break;
						}
					} else {
						const current =
							challenge.type === UnlockChallengeType.Level
								? this.userDoc.lvl
								: progress.progresses[i] || 0;
						if (current < challenge.goal) {
							completed = false;
							break;
						}
					}
				}
			}
			if (completed) {
				list.push(quest);
			}
		};
		for (const quest of mainQuests) {
			checkQuest(quest, this.userDoc.mainQuestProgress);
		}
		for (const quest of weeklyQuests) {
			checkQuest(quest, this.userDoc.weeklyQuestProgress);
		}
		for (const quest of timeLimitedQuests) {
			checkQuest(quest, this.userDoc.timeLimitedQuestProgress);
		}
		return list;
	}
	public get hasClaimableQuest() {

		return this.claimableQuests.length > 0;
	}
	public get numDuplicates() {
		const list: Dictionary<Dictionary<number>> = {
			1: {
				2: 0,
				3: 0,
				4: 0,
			},
		};
		const skins = this.inventory.skins;
		for (const skinId in skins) {
			if (Object.prototype.hasOwnProperty.call(skins, skinId)) {
				const amount = skins[skinId];
				const { skinGroup, fishType } = decodeSkinId(Number(skinId));
				const skinRarity = skinInfos[skinGroup][fishType].rarity;
				list[skinGroup][skinRarity] += Math.max(amount - 1, 0);
			}
		}
		return list;
	}


	@Mutation
	public m_updateUser(user: User | null) {
		this.user = user;

		const reset = () => {

			this.teamData = null;
			this.userSettings = getDefaultStabfishSettings();
			this.inventory = getEmptyInventory();
			this.decoSets = {};
			this.masterUnlocked = {};
			this.poqWallet = { balance: 0, allocation: 0 };
			this.userDocLoaded = false;
			this.oldLvl = this.oldExp = 0;
			this.fishUnlockProgress.length = 0;
			this.oldFishUnlockProgress.length = 0;
			this.fishCurrentUnlockChallenges.length = 0;
			this.pendingFishUnlocks.length = 0;
			this.oldUnreadCompletedChallenges = null;

			this.materials.length = 0;
			this.decos.length = 0;
			this.blueprints.length = 0;
			this.tokens.length = 0;
			this.skins.length = 0;
			this.pets.length = 0;
			globalx.setUserDocsReady(false);
		};
		if (!user) {
			this.emailVerificationSent = false;
			this.userDoc = getDefaultStabfishAccountData('');
			reset();
		} else {
			if (!this.userDoc.uid) {
				this.userDoc = getDefaultStabfishAccountData(user.uid);
				reset();
			}
			const userDoc = this.userDoc;
			const emailProgress = getQuestProgress(userDoc.mainQuestProgress, MainQuestCode.EmailVerify);
			if (emailProgress.currentStep === 0) {
				if (user.email) {
					emailProgress.progresses[0] = 1;
				}
			} else if (emailProgress.currentStep === 1) {
				if (user.emailVerified) {
					emailProgress.progresses[0] = 1;
				}
			}
		}
	}
	@Mutation
	public m_updateUserDoc(userDoc: StabfishAccountData) {
		userDoc.exp = Math.round(userDoc.exp);

		if (userDoc.poq) {
			const progress = getQuestProgress(userDoc.mainQuestProgress, MainQuestCode.LinkPoq);
			if (progress.currentStep === 0) {
				progress.progresses[0] = 1;
			}
		}
		if (this.user) {
			const emailProgress = getQuestProgress(userDoc.mainQuestProgress, MainQuestCode.EmailVerify);
			if (emailProgress.currentStep === 0) {
				if (this.user.email) {
					emailProgress.progresses[0] = 1;
				}
			} else if (emailProgress.currentStep === 1) {
				if (this.user.emailVerified) {
					emailProgress.progresses[0] = 1;
				}
			}
		}


		this.userDoc = userDoc;

		const pendings: boolean[] = [];
		const fishUnlockProgress: number[] = [];
		const currentChallenges: Array<UnlockChallenge | null> = [];
		currentChallenges.length = 0;
		const progresses = userDoc.unlockProgress;
		// userDoc.mainQuestProgress = {
		// 	0: {
		// 		currentStep: 2,
		// 		progresses: {
		// 			0: 6,
		// 			1: 12,
		// 		},
		// 	},
		// };
		// for (const ft of availableFishes) {
		// 	userDoc.unlockedFishes[ft.type] = true;
		// }
		for (let i = 0; i < fishUnlockRequirements.length; i++) {
			const steps = fishUnlockRequirements[i];
			if (userDoc.unlockedFishes[i] || !steps || !availableFishes.find((f) => f.type === i)) {
				fishUnlockProgress[i] = 1;
				pendings[i] = false;
				currentChallenges[i] = null;
				continue;
			}
			let completedAll = true;

			let totalChallenges = 0;
			let completedChallenges = 0;



			for (let step = 0; step < steps.length; step++) {
				const challenges = steps[step];
				totalChallenges += challenges.length;

				for (let index = 0; index < challenges.length; index++) {
					const progress = getChallengeProgress(progresses, i, step, index);
					if (!progress.completed) {
						const challenge = challenges[index];
						if (!currentChallenges[i]) { currentChallenges[i] = challenge; }
						completedAll = false;
						if (challenge.type === UnlockChallengeType.Accumulate || challenge.type === UnlockChallengeType.OneGame) {
							completedChallenges += progress.current / challenge.goal;
						}
					} else {
						completedChallenges++;
					}
				}
			}
			if (completedAll) {
				fishUnlockProgress[i] = 1;
				currentChallenges[i] = null;
				pendings[i] = true;
			} else {

				fishUnlockProgress[i] = completedChallenges / totalChallenges;
				pendings[i] = false;
			}
		}
		this.fishUnlockProgress = fishUnlockProgress;
		this.fishCurrentUnlockChallenges = currentChallenges;
		this.pendingFishUnlocks = pendings;
	}
	@Mutation
	public m_updateUserInventory(inventory: StabfishAccountInventory) {
		const decoStrings = inventory.decoSets;
		const decoSets: Dictionary<DecoSet | null> = {};
		for (const fishType in decoStrings) {
			if (Object.prototype.hasOwnProperty.call(decoStrings, fishType)) {
				const decoString = decoStrings[fishType];
				if (this.inventory.decoSets[fishType] === decoString) {
					decoSets[fishType] = this.decoSets[fishType];
				} else {
					decoSets[fishType] = decodeDecoSet(decoString);
				}
			}
		}
		this.decoSets = decoSets;
		if (!inventory.skins) { inventory.skins = {}; }
		if (!inventory.skinOrders) { inventory.skinOrders = []; }
		if (!inventory.skinHistory) { inventory.skinHistory = {}; }
		if (!inventory.equippedSkins) { inventory.equippedSkins = {}; }
		if (!inventory.bpData) {
			inventory.bpData = {
				bought: 0,
				earned: 0,
				convert: 0,
				spent: 0,
				oneOff: 0,
				firstTime: {},
			};
		}
		// for (const ft of availableFishes) {
		// 	inventory.equippedSkins[ft.type] = 1;
		// }

		this.inventory = inventory;

		// // hack for all invetory
		// inventory.orders.length = 0;
		// for (const itemInfo of itemInfos) {
		// 	if (itemInfo) {
		// 		this.inventory.availables[itemInfo.code] = 10;
		// 		inventory.orders.push(itemInfo.code);
		// 	}
		// }

		this.materials.length = 0;
		this.decos.length = 0;
		this.blueprints.length = 0;
		this.tokens.length = 0;
		this.skins.length = 0;
		this.pets.length = 0;
		for (const itemCode of inventory.orders) {
			const type = itemInfos[itemCode].type;
			const list =
				type === 'material' ? this.materials
					: type === 'blueprint' ? this.blueprints
						: type === 'deco' ? this.decos
							: type === 'pet' ? this.pets
								: this.tokens;

			const amount = this.inventory.availables[itemCode] || 0;
			list.push({ itemCode, amount });
		}
		for (const skinId of inventory.skinOrders) {
			const amount = this.inventory.skins[skinId] || 0;
			this.skins.push({ itemCode: Number(skinId), amount });
		}
	}
	@Mutation
	public m_updateUserSettings(userSettings: StabfishUserSettings) {
		if (!this.oldUnreadCompletedChallenges) {
			this.oldUnreadCompletedChallenges = [...userSettings.completedChallenges];
		}
		this.userSettings = userSettings;
		const progress = this.userDoc.unlockProgress;
		for (const challengeId of userSettings.completedChallenges) {
			const splits = challengeId.split('-');
			if (splits[0] === 'F') {
				const p = getChallengeProgress(progress,
					Number(splits[1]),
					Number(splits[2]),
					Number(splits[3]));
				p.completed = true;
			}
		}
		this.userDoc = { ...this.userDoc };
		tipx.updateTipsRead(userSettings.tipsRead);
	}
	@Mutation
	public m_updateState(keyPair: StateKeyPairs) {
		(this as any)[keyPair.key] = keyPair.state;
	}
	@Mutation
	public m_updatePoqWallet(wallet: PoqWallet) {
		this.poqWallet = wallet;
	}

	@Mutation
	public m_updateLoginStatus(status: LoginStatus) {
		this.loginStatus = status;
	}
	@Mutation
	public m_setSignLinkError(error: SignLinkError | null) {
		this.signLinkError = error;
	}
	@Mutation
	public m_updatePeaceVote(submission: PeaceModeVote) {
		this.peaceModeVote = submission;
	}
	@Action({ rawError: true })
	public setSignLinkError(error: SignLinkError | null) {
		this.m_setSignLinkError(error);
	}
	@Action({ rawError: true })
	public async updateDecoSet(submission: { fishType: FishType, decoSet: DecoSet; }) {
		const { decoSet, fishType } = submission;
		const decoString = encodeDecosSet(decoSet);
		try {
			const inventory = await fb.updateDecoSet(fishType, decoString);

			if (inventory) {
				this.m_updateUserInventory(inventory);
			}
			this.decoSets[fishType] = decoSet;
			this.m_updateDecoSet({ ...this.decoSets });
		} catch (error) {
			throw error;
		}

	}
	@Action({ rawError: true })
	public async increaseDecoLimit(toLimit: number) {
		try {
			const result = await fb.increaseDecoLimit(toLimit);
			if (result && result.decoLimit > (this.inventory.decoLimit || 0)) {
				const cost = decoLimitCost[toLimit - 1];
				if (toLimit < 4) {
					this.inventory.money -= cost;
				} else {
					this.inventory.availables[ItemCode.Gem] -= cost;
				}
				this.inventory.decoLimit = result.decoLimit;
				this.m_updateUserInventory({ ...this.inventory });
			}
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public updateHeroes(submission: { life: number, fishType: FishType; }) {
		const { life, fishType } = submission;
		const heroes = [...this.heroes];
		heroes[life] = fishType;
		this.changeUserSettings({
			lastHeroes: heroes,
		});
		this.m_updateUserSettings(this.userSettings);
		// this.m_updateHeroes(heroes);
	}
	@Action({ rawError: true })
	public updateBossHeroes(submission: { life: number, fishType: FishType; }) {
		const { life, fishType } = submission;
		const heroes = [...this.heroes];
		heroes[life] = fishType;
		this.changeUserSettings({
			lastBossHeroes: heroes,
		});
		this.m_updateUserSettings(this.userSettings);
		// this.m_updateHeroes(heroes);
	}
	@Action({ rawError: true })
	public updateUser(user: User | null = null) {
		const currentUser: User | null | undefined = (this.context.state as any).user;
		if (user && user.uid === this.uid) { this.m_updateUser(JSON.parse(JSON.stringify(user))); }
	}
	@Action({ rawError: true })
	public updateUserDoc(user: StabfishAccountData) {
		this.m_updateUserDoc(user);
	}
	@Action({ rawError: true })
	public updateUserInventory(inventory: StabfishAccountInventory) {
		this.m_updateUserInventory(inventory);
	}
	@Action({ rawError: true })
	public updateUserSettings(settings: StabfishUserSettings) {
		this.m_updateUserSettings(settings);
	}
	@Action({ rawError: true })
	public changeUserSettings(settings: Partial<StabfishUserSettings>) {
		return fb.changeUserSettings(settings);
	}

	@Action({ rawError: true })
	public loginLocal(user: User | null = null) {
		if (alertAdmin && user?.uid === 'fTaakPLzz0c8WTgQd8hkPZvaYu03') {
			alert(alertAdmin);
		}
		this.m_updateUser(user);
		this.m_updateLoginStatus((user) ? 'logged in' : 'logged out');
		(window as any).userx = this;
	}

	@Action({ rawError: true })
	public async logoutLocal() {
		this.m_updateTeamData(null);
		this.loginLocal(); // passing null as arg
		// this.m_updateHistory({ lastVisitedAccount: null, lastLoginTime: null, visitedAccounts: [] });
		// this.m_updateMyAccounts({});
	}
	@Mutation
	public m_emailVerificationSent() {
		this.emailVerificationSent = true;
	}
	@Action({ rawError: true })
	public async createUserWithEmailAndPassword(userSubmit: LoginSubmission) {
		this.m_updateState({ key: 'signinState', state: ProcessState.Await });
		try {
			await fb.createUserWithEmailAndPassword(userSubmit);
			this.m_updateState({ key: 'signinState', state: ProcessState.Succeed });
		} catch (error) {
			this.m_updateState({ key: 'signinState', state: ProcessState.Failed });
			throw error;
		}
	}
	@Action({ rawError: true })
	public async signInAnonymously() {
		this.m_updateState({ key: 'signinState', state: ProcessState.Await });
		try {
			await fb.loginUserAnonymously();
			this.m_updateState({ key: 'signinState', state: ProcessState.Succeed });
		} catch (error) {
			this.m_updateState({ key: 'signinState', state: ProcessState.Failed });
			throw error;
		}
	}
	@Action({ rawError: true })
	public async signIn(userSubmit: LoginSubmission) {

		this.m_updateState({ key: 'signinState', state: ProcessState.Await });
		try {
			await fb.loginUserWithEmailAndPassword(userSubmit);
			this.m_updateState({ key: 'signinState', state: ProcessState.Succeed });
		} catch (error) {
			this.m_updateState({ key: 'signinState', state: ProcessState.Failed });
			throw error;
		}
	}
	@Action({ rawError: true })
	public async signInWithOAuth(method: OAuthSigninMethod) {
		this.m_updateState({ key: 'signinState', state: ProcessState.Await });
		try {
			await fb.loginUserWithOAuth(method, false);
			this.m_updateState({ key: 'signinState', state: ProcessState.Succeed });
		} catch (error) {
			this.m_updateState({ key: 'signinState', state: ProcessState.Failed });
			throw error;
		}
	}
	@Action({ rawError: true })
	public async signOut() {
		await fb.logoutUser();
		// FirestoreFakeGet.dispose();
	}

	@Action({ rawError: true })
	public async updateUserEmail(email: string) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await fb.updateUserEmail(email);
			// this.user.email = userSubmit.email;
			// this.m_updateUser(this.user);
		} catch (error) {
			throw error;
		}
	}

	@Action({ rawError: true })
	public async linkUserWithEmailAndPassword(userSubmit: LoginSubmission) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await fb.linkUserWithEmailAndPassword(userSubmit);
			this.user.email = userSubmit.email;
			this.user.noPassword = false;
			this.m_updateUser(this.user);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async linkUserWithOAuth(method: OAuthSigninMethod) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await fb.linkUserWithOAuth(method, false);
			// this.m_updateUser(this.user);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async unlinkUserWithOAuth(method: OAuthSigninMethod) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await fb.unlinkUserWithOAuth(method);
			this.user.providerData = this.user.providerData.filter((p) => p.providerId !== `${method}.com`);
			this.m_updateUser(this.user);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async signInWithCrazyGames(token: string) {
		try {
			const cgToken = await fb.signInWithCrazyGamesToken(token);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async linkUserWithCrazyGames(token: string) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			const cgToken = await fb.linkWithCrazyGamesToken(token);
			// this.m_updateUser(this.user);
			this.userDoc.crazyGames = cgToken;
			this.m_updateUserDoc(this.userDoc);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async unlinkUserWithCrazyGames() {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await fb.unlinkWithCrazyGames();
			this.userDoc.crazyGames = null;
			this.m_updateUserDoc(this.userDoc);
		} catch (error) {
			throw error;
		}
	}

	@Action({ rawError: true })
	public async updateDisplayName(displayName: string) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		if (this.userDoc.name === displayName) {
			return;
		}
		try {
			const name = await fb.updateDisplayName(displayName);
			this.user.displayName = name;
			this.userDoc.name = name;
			this.m_updateUser(this.user);
			this.m_updateUserDoc(this.userDoc);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async updatePassword(password: string) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await fb.updatePassword(password);
			this.user.noPassword = false;
			this.m_updateUser(this.user);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async signInAndResetPassword(passwords: SignInAndResetPasswordSubmission) {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		const { oldPassword, newPassword } = passwords;
		try {
			await fb.signInAndResetPassword(oldPassword, newPassword);
			this.user.noPassword = false;
			this.m_updateUser(this.user);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async sendSignInEmail(email: string) {
		return fb.sendSignInEmail(email);
	}
	@Action({ rawError: true })
	public async sendEmailVerification() {
		if (!this.user) {
			throw new StabfishError('auth/requires-login');
		}
		if (this.emailVerificationSent) { return; }
		try {
			await fb.sendEmailVerification();
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async sendPasswordResetEmail(email: string) {
		return fb.sendPasswordResetEmail(email);
	}
	@Action({ rawError: true })
	public async updateTeamData(teamData: StabfishTeamDoc | null) {
		this.m_updateTeamData(teamData);
	}

	@Action({ rawError: true })
	public async createTeam(passcode: string) {
		try {
			const teamData = await fb.createTeam(passcode);
			this.userDoc.teamId = teamData.tid;
			this.m_updateUserDoc(this.userDoc);
			this.m_updateTeamData(teamData);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async changeTeamLeader(request: ChangeTeamLeaderRequest) {
		try {
			const teamData = await fb.changeTeamLeader(request);
			this.m_updateTeamData(teamData);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async changeTeamPasscode(passcode: string) {
		try {
			const teamData = await fb.changeTeamPasscode(passcode);
			this.m_updateTeamData(teamData);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async joinTeam(request: JoinTeamRequest) {
		try {
			const teamData = await fb.joinTeam(request);
			this.userDoc.teamId = teamData.tid;
			this.m_updateUserDoc(this.userDoc);
			this.m_updateTeamData(teamData);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async leaveTeam() {
		try {
			const teamData = await fb.leaveTeam();
			this.userDoc.teamId = '';
			this.m_updateUserDoc(this.userDoc);
			this.m_updateTeamData(null);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async kickMember(uid: string) {
		try {
			const teamData = await fb.kickMember(uid);
			this.m_updateTeamData(teamData);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async readUnlock(unlockIds: string[]) {
		if (unlockIds.length === 0) { return; }
		try {
			await fb.readUnlock(unlockIds);
			this.userSettings.completedChallenges = this.userSettings.completedChallenges.filter((s) => !unlockIds.includes(s));
			this.m_updateUserSettings(this.userSettings);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async unlockFish(fishType: FishType) {
		try {
			globalx.showUnlock(fishType);
			const success = await fb.unlockFish(fishType);
			if (success) {
				this.readUnlock(this.unreadCompletedFishChallenges.filter(
					(s) => s.indexOf(`F-${fishType}-`) === 0,
				));
				this.userDoc.unlockedFishes[fishType] = true;
				this.m_updateUserDoc({ ...this.userDoc });
			}
		} catch (error) {
			throw error;
		}
	}

	@Action({ rawError: true })
	public async readQuest() {
		if (this.unreadCompletedQuestChallenges.length === 0) { return; }
		try {
			await fb.readUnlock(this.unreadCompletedQuestChallenges);
			this.userSettings.completedChallenges = this.userSettings.completedChallenges.filter((s) => !this.unreadCompletedQuestChallenges.includes(s));
			this.m_updateUserSettings(this.userSettings);
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async readContest() {
		if (this.userSettings.contestRead === globalx.latestContest) {
			return;
		}
		await wait(3000);
		try {
			this.changeUserSettings({ contestRead: globalx.latestContest });
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async craft(blueprint: ItemCode) {
		try {
			const inventory = await fb.craft(blueprint);
			if (inventory) {
				this.m_updateUserInventory(inventory);
			}
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async sortBag() {
		try {

			this.inventory.orders.sort((a, b) => a - b);
			this.m_updateUserInventory(this.inventory);
			const inventory = await fb.sortBag();
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async contribute(challengeId: string) {
		try {
			const result = await fb.contribute(challengeId);
			if (result) {

				const splits = challengeId.split('-');
				if (splits[0] === 'F') {
					const p = getChallengeProgress(
						this.userDoc.unlockProgress,
						Number(splits[1]),
						Number(splits[2]),
						Number(splits[3]));
					p.completed = true;
				}
				this.m_updateUserDoc({ ...this.userDoc });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async questPay(challengeId: string) {
		try {
			const result = await fb.questPay(challengeId);
			if (result) {

				const splits = challengeId.split('-');
				const initials = splits[0];
				const unlockId = Number(splits[1]);
				const step = Number(splits[2]);
				const k = Number(splits[3]);
				const progresses = initials === 'QM' ? this.userDoc.mainQuestProgress :
					initials === 'QW' ? this.userDoc.weeklyQuestProgress : this.userDoc.timeLimitedQuestProgress;

				const quest = initials === 'QM' ? mainQuests[unlockId] :
					initials === 'QW' ? weeklyQuests[unlockId] : timeLimitedQuests[unlockId];
				const progress = getQuestProgress(progresses, unlockId);
				progress.progresses[k] = quest.steps[step].challenges[k].goal;

				this.m_updateUserDoc({ ...this.userDoc });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async claimQuest(submission: ClaimQuestRequest) {
		try {
			const result = await fb.claimQuest(submission);
			if (result) {
				const progresses = submission.questType === QuestType.Main ? this.userDoc.mainQuestProgress :
					submission.questType === QuestType.Weekly ? this.userDoc.weeklyQuestProgress : this.userDoc.timeLimitedQuestProgress;
				const progress = getQuestProgress(progresses, submission.questId);
				progress.currentStep++;
				progress.progresses = {};

				this.m_updateUserDoc({ ...this.userDoc });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async readMail(mailId: string) {
		if (!this.inventory || !this.inventory.inbox || !this.inventory.inbox[mailId] || this.inventory.inbox[mailId].readTime) {
			return;
		}
		try {
			const result = await fb.readMail(mailId);
			const items = this.inventory.inbox[mailId].items;
			if (items) {
				for (const code in items) {
					if (Object.prototype.hasOwnProperty.call(items, code)) {
						const amount = items[code];
						const itemCode = Number(code);
						if (itemCode === ItemCode.Money) {
							this.inventory.money += amount;
						} else {
							if (!this.inventory.availables[itemCode]) {
								this.inventory.availables[itemCode] = amount;
								this.inventory.orders.push(itemCode);
							} else {
								this.inventory.availables[itemCode] += amount;
							}
						}
					}
				}
				this.updateUserInventory(this.inventory);
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async claimDecoContestPrize(id: string) {
		try {
			const result = await fb.claimDecoContestPrize(id);
			if (result) {
				if (!this.inventory.claimables) {
					this.inventory.claimables = {};
				}
				this.inventory['decoContest-' + id] = 'done';
				this.m_updateUserInventory({ ...this.inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async claimAccuRewards(id: string) {
		try {
			const result = await fb.claimAccuRewards(id);
			if (result) {
				if (!this.inventory.accuRewards) {
					this.inventory.accuRewards = {};
				}
				delete this.inventory.accuRewards[id];
				this.m_updateUserInventory({ ...this.inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async linkPoq(submission: PoqConnectRequest) {
		try {
			const result = await fb.linkPoq(submission);
			if (result) {
				this.userDoc.poq = result;
				this.m_updateUserDoc({ ...this.userDoc });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async updatePoqWallet() {
		try {
			const result = await fb.getPoqWallet();
			if (result) {
				this.m_updatePoqWallet(result);
			}
			return result;
		} catch (error) {
			throw error;
		}
	}

	@Action({ rawError: true })
	public async cnyPurchase(itemCode: ItemCode) {
		try {
			const result = await fb.cnyPurchase(itemCode);
			if (result) {
				const inventory = this.inventory;
				if (itemCode === ItemCode.HatOfFortune) {
					const wallet = this.poqWallet;
					wallet.balance -= result.cost;
					this.m_updatePoqWallet({ ...wallet });
					inventory.availables[ItemCode.Gem] = inventory.availables[ItemCode.Gem] ?
						inventory.availables[ItemCode.Gem] + result.amount : result.amount;
				} else {
					inventory.availables[ItemCode.Gem] = inventory.availables[ItemCode.Gem] ?
						inventory.availables[ItemCode.Gem] - result.cost : -result.cost;
				}
				inventory.availables[itemCode] = inventory.availables[itemCode] ?
					inventory.availables[itemCode] + result.amount : result.amount;
				this.m_updateUserInventory({ ...inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async buyBlackPearl(type: BlackPearlPurchaseType) {
		try {
			const result = await fb.buyBlackPearl(type);
			if (result) {
				const wallet = this.poqWallet;
				wallet.balance -= result.cost;
				this.m_updatePoqWallet({ ...wallet });
				const inventory = this.inventory;
				inventory.availables[ItemCode.Gem] = inventory.availables[ItemCode.Gem] ?
					inventory.availables[ItemCode.Gem] + result.amount : result.amount;
				this.m_updateUserInventory({ ...inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async buyShopLimited(submission: ShopLimitedRequest) {
		try {
			const result = await fb.buyShopLimited(submission);
			if (result) {
				const inventory = this.inventory;
				inventory.availables = { ...inventory.availables, ...result };
				if (!inventory.shop[submission.itemId]) {
					inventory.shop[submission.itemId] = 0;
				}
				inventory.shop[submission.itemId] += submission.count;
				this.m_updateUserInventory({ ...inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async convertDuplicateSkins(submission: ConvertDuplicateSkinsRequest) {
		try {
			const result = await fb.convertDuplicateSkins(submission);
			if (result) {
				const inventory = this.inventory;
				inventory.skins[submission.skinId] -= result.cost;
				inventory.availables[ItemCode.Gem] = inventory.availables[ItemCode.Gem] ?
					inventory.availables[ItemCode.Gem] + result.amount : result.amount;
				this.m_updateUserInventory({ ...inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async convertAllDuplicateSkins(submission: ConvertAllDuplicateSkinsRequest) {
		try {
			const result = await fb.convertAllDuplicateSkins(submission);
			if (result) {
				const inventory = this.inventory;
				const skins = inventory.skins;
				for (const skinId in skins) {
					if (Object.prototype.hasOwnProperty.call(skins, skinId)) {
						const amount = skins[skinId];
						if (amount > 1) {
							const { skinGroup, fishType } = decodeSkinId(Number(skinId));
							if (skinGroup === submission.skinGroup && skinInfos[skinGroup][fishType].rarity === submission.rarity) {
								skins[skinId] = 1;
							}
						}
					}
				}
				inventory.availables[ItemCode.Gem] = inventory.availables[ItemCode.Gem] ?
					inventory.availables[ItemCode.Gem] + result.amount : result.amount;
				this.m_updateUserInventory({ ...inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async equipSkin(submission: EquipSkinRequest) {
		try {
			const result = await fb.equipSkin(submission);
			if (result) {
				const inventory = this.inventory;
				inventory.equippedSkins[submission.fish] = submission.skin;
				this.m_updateUserInventory({ ...inventory });
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action({ rawError: true })
	public async gachaSkins(submission: GachaRequest) {
		try {
			const result = await fb.gachaSkins(submission);
			return result;
		} catch (error) {
			throw error;
		}
	}

	@Action({ rawError: true })
	public async votePeaceMode(submission: PeaceModeVote) {
		try {
			const result = await fb.votePeaceMode(submission);
			if (result) {
				this.m_updatePeaceVote(submission);
				LocalData.save('peaceVote', submission);
			}
			return result;
		} catch (error) {
			throw error;
		}
	}
	@Action
	public snapShotFishUnlockProgress() {
		this.m_snapShotFishUnlockProgress();
	}
	@Mutation
	public m_snapShotFishUnlockProgress() {
		this.oldExp = this.userDoc.exp;
		this.oldLvl = this.userDoc.lvl;
		this.oldFishUnlockProgress = JSON.parse(JSON.stringify(this.fishUnlockProgress));
	}
	@Action
	public snapShotBossRecord() {
		this.m_snapShotBossRecord();
	}
	@Mutation
	public m_snapShotBossRecord() {
		this.oldBossRecord = this.userDoc.bossRecords || {};
	}

	@Mutation
	private m_updateDecoSet(submission: Dictionary<DecoSet | null>) {
		this.decoSets = submission;
	}
	// @Mutation
	// private m_updateHeroes(heroes: FishType[]) {
	// 	this.heroes = heroes;
	// }
	@Mutation
	private m_updateTeamData(teamData: StabfishTeamDoc | null) {
		this.teamData = teamData;
	}

}


export default getModule(UserModule);
