import { render, staticRenderFns } from "./FishCanvasComponent.vue?vue&type=template&id=f173d55e&"
import script from "./FishCanvasComponent.vue?vue&type=script&lang=ts&"
export * from "./FishCanvasComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports