






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { specialSkillInfos } from '@/game/infos/skills';
import {
	spearCost,
	spearLvlRequirements,
	spearSkills,
	SpearType,
	weaponDecs,
	weaponNames,
	weaponLongNames,
} from '@/game/infos/spearInfos';
import settingx from '@/store/modules/settingx';
import WeaponIntelToolTip, { WeaponIntelData } from './WeaponIntelToolTip.vue';
import TnyWeaponModal from './TnyWeaponModal.vue';
@Component({
	components: {
		WeaponIntelToolTip,
		TnyWeaponModal,
	},
})

export default class TnyWeaponSelector extends Vue {

	public get onlyChoice() {
		return this.weapons.length === 1;
	}
	public get name() {
		return weaponNames[this.spearType];
	}
	public get imgSrc() {
		return `/images/weapons/${weaponNames[this.spearType]}1.png`;
	}
	@Prop({ default: () => [] }) public weapons!: SpearType[];
	@Prop({ default: 0 }) public spearType!: SpearType;



	public touchingStart = 0;
	public touchX = 0;
	public touchY = 0;

	public onSwitch(direction: number) {
		let weaponIndex = this.weapons.indexOf(this.spearType) + direction;
		if (weaponIndex < 0) {
			weaponIndex = this.weapons.length - 1;
		} else if (weaponIndex >= this.weapons.length) {
			weaponIndex = 0;
		}
		this.$emit('select', this.weapons[weaponIndex]);
	}
	public onMouseOver(e: any) {
		if (settingx.now.control === 'touch') {
			e.preventDefault();
			return;
		}
		this.mouseOver();
	}
	public async onTouchStart(e: any) {
		this.mouseOver();
		this.touchX = e.changedTouches[0]?.screenX;
		this.touchY = e.changedTouches[0]?.screenY;
		this.touchingStart = Date.now();

	}
	public async onTouchEnd(e: any) {
		this.mouseLeave();
		if (
			Date.now() - this.touchingStart < 500 &&
			this.touchX === e.changedTouches[0]?.screenX &&
			this.touchY === e.changedTouches[0]?.screenY
		) {
			this.click();
		}
	}

	public mouseOver() {

		const data: WeaponIntelData = {
			targetId: 'tny-wpn-btn',
			placement: 'top',
			container: 'tny-weapon-selector',
			spearType: this.spearType,
		};
		this.$root.$emit('showWeaponIntel', data);
	}
	public mouseLeave() {
		this.$root.$emit('hideWeaponIntel');
	}
	public click() {

	}
}
