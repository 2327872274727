import { FishType } from '@/game/infos/fishInfos';
import { decodeSkinId, legendarySkins, SkinGroup } from '@/game/infos/skinInfos';
import { Global } from '@/store/globalz';
import globalx from '@/store/modules/globalx';
import { Tween } from '@/util/tweents';
import { Container, Sprite } from 'pixi.js';
import Factory from '../factory';
import { GachaAnimation, GachaReveal, GachaRevealSkin, getSkinImgUrl } from '../factory/gachaAssets';
import { preloadTextures } from '../factory/helper';
import { BgmManager } from '../sound/BgmManager';
import { SoundEfx } from '../sound/SoundEfx';

declare const gac: any;
export async function preloadGachaSkins(skins: number[]) {
	const urls = new Set<string>();
	for (const skinId of skins) {
		const { fishType, skinGroup } = decodeSkinId(skinId);
		const url = getSkinImgUrl(skinGroup, fishType);
		urls.add(url);
	}
	return preloadTextures(urls);
}

export class SkinGachaControl extends Container {
	public animation: GachaAnimation = new gac.GachaAnimation();
	public reveal: GachaReveal = new gac.GachaReveal();
	public revealBg = Sprite.from('gachaAssets_GachaRevealBgBlue');
	public white = Factory.getByName('White');
	public animationSound: SoundEfx | null = null;

	public nextRevealSkinId = 0;
	public skinsToBeRevealed: number[] = [];

	public toResolves: Array<() => void> = [];

	public constructor() {
		super();
		this.animation.on('completed', () => {
			if (this.animationSound) {
				this.animationSound.stop();
				this.animationSound = null;
			}
			this.revealNext(true);
		});
		this.reveal.on('completed', () => {
			globalx.updateToShowNextRevealButton(true);
			BgmManager.instance.play('bgmMainMenu');
		});
	}


	public resize(w: number, h: number) {
		this.revealBg.width = w + 200;
		this.revealBg.height = h + 10;
		this.revealBg.x = -100;
		this.revealBg.y = -5;
		this.white.width = w;
		this.white.height = h;

		const oh = 1080;
		let ow = 1920;
		let scale = h / oh;
		if (ow * scale > w) {
			scale = w / ow;
		}
		this.animation.scale.set(scale);
		this.animation.x = w / 2;
		this.animation.y = h / 2;

		ow = 1300;
		scale = h / oh;
		if (ow * scale > w) {
			scale = w / ow;
		}
		this.reveal.scale.set(scale);
		this.reveal.x = w / 2;
		this.reveal.y = h / 2;
	}

	public playAnimation(skins: number[]) {
		return new Promise((resolve: (value?: unknown) => void) => {
			this.toResolves.push(resolve);
			BgmManager.instance.play('');
			Global.overlayCanvasStage.addChild(this);
			this.skinsToBeRevealed = [...skins];
			let hasLegendary = false;
			const isMultiple = skins.length > 1;
			for (const skinId of skins) {
				const skin = decodeSkinId(skinId);
				if (legendarySkins[skin.skinGroup]?.includes(skin.fishType)) {
					hasLegendary = true;
					break;
				}
			}
			this.removeChildren();
			this.addChild(this.animation);
			this.animation.alpha = 0;
			globalx.updateShowingGachaAnimation(true);
			this.nextRevealSkinId = this.skinsToBeRevealed.shift() || 0;
			this.animation.start(isMultiple, hasLegendary);
			this.animationSound = new SoundEfx('gachaAnimation');
			this.animationSound.play();
			Tween.get(this.animation).to({ alpha: 1 }, 500);
		});
	}

	public playReveal(fishType: FishType, skinGroup: SkinGroup, hideWhite = false) {
		new SoundEfx('gachaReveal').play();
		Global.overlayCanvasStage.addChild(this);
		globalx.updateToShowNextRevealButton(false);
		this.nextRevealSkinId = this.skinsToBeRevealed.shift() || 0;
		this.removeChild(this.animation);
		this.addChild(this.revealBg);
		this.addChild(this.reveal);
		if (hideWhite) {
			this.addChild(this.white);
			this.white.alpha = 1;
			Tween.get(this.white).to({ alpha: 0 }, 500);
		} else {
			this.removeChild(this.white);
		}
		this.reveal.start(fishType, skinGroup);
	}

	public revealNext(hideWhite = false) {
		globalx.updateToShowNextRevealButton(false);
		if (this.nextRevealSkinId) {
			const skin = decodeSkinId(this.nextRevealSkinId);
			this.playReveal(skin.fishType, skin.skinGroup, hideWhite);
		} else {
			this.removeChildren();
			Global.overlayCanvasStage.removeChild(this);
			globalx.updateShowingGachaAnimation(false);
			this.emit('completed');
			let resolve = this.toResolves.shift();
			while (resolve) {
				resolve();
				resolve = this.toResolves.shift();
			}
		}
	}
	public skipGacha(hideWhite = false) {

		if (this.animationSound) {
			this.animation.emit('completed');
		} else {
			this.nextRevealSkinId = 0;
			this.revealNext(false);
		}
	}

}

window.addEventListener('keydown', (e) => {
	if (e.key === ' ' && Global.skinGachaControl && globalx.toShowNextRevealButton) {
		Global.skinGachaControl.revealNext();
	}
});
