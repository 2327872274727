import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsHolySword } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { MiscSymbol } from './miscSymbol';

// const info = specialSkillInfos[SpecialSkill.SwimmingFang];

export class SsHolySwordSymbol implements MiscSymbol {
	public static get() {
		return SsHolySwordSymbol._pool.get();
	}

	private static _pool: Pool<SsHolySwordSymbol> = new Pool(SsHolySwordSymbol);

	public data: MiscDataSsHolySword | null = null;
	public sound = new LongSoundEfx('holySword');


	public dispose(room: RoomControl) {
		this.data = null;

		this.sound.stop();

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsHolySword;

		const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		this.sound.volume = volume;
		if (!this.data) {
			this.sound.start();
			if (volume > .5) {
				room.cameraControl.swing(4 * (volume - .4), 500);
			}
		}
		this.data = data;
	}

	public show(room: RoomControl) {

	}
	public hide(room: RoomControl) {

	}

}
