export function generateDateString(d: Date) {
	let dd = d.getDate().toString();
	if (dd.length < 2) { dd = '0' + dd; }
	let mm = (d.getMonth() + 1).toString();
	if (mm.length < 2) { mm = '0' + mm; }
	const yy = d.getFullYear();
	return dd + '-' + mm + '-' + yy;
}
export function generateHour(hour: number) {
	const d = new Date(hour);
	let dd = d.getDate().toString();
	if (dd.length < 2) { dd = '0' + dd; }
	let mm = (d.getMonth() + 1).toString();
	if (mm.length < 2) { mm = '0' + mm; }
	const yy = d.getFullYear();
	let hh = d.getHours().toString();
	if (hh.length < 2) { hh = '0' + hh; }
	return dd + '-' + mm + '-' + yy + ':' + hh;
}
