import { SoundGroup } from './SoundGroup';
import { sound, filters } from '@pixi/sound';
import { bgmUrls, BgmId } from '@/client/sound/soundInfos';
import { Tween } from '@/util/tweents';
import { SoundInstance } from './SoundInstance';
import { Global } from '@/store/globalz';
import settingx from '@/store/modules/settingx';

// sound.stopAll();
export const bgms: { [T in BgmId]: SoundInstance } = {} as any;

const depthFilter = new filters.EqualizerFilter();
const bgmGameSoundGroup = new SoundGroup();
let useDepthFilter = false;
// avoid init loop
setTimeout(() => {
	for (const bgmId in bgmUrls) {
		if (Object.prototype.hasOwnProperty.call(bgmUrls, bgmId)) {
			const url = bgmUrls[bgmId];
			const s = sound.add(bgmId, {
				url,
				preload: true,
				loaded: () => {
					BgmManager.instance.bgmLoaded(bgmId as BgmId);
				},
			});
			const ins = new SoundInstance(s);
			bgms[bgmId] = ins;
			ins.addGroup(Global.masterSoundGroup);
			ins.addGroup(Global.bgmSoundGroup);
			ins.addGroup(Global.bgmControlSoundGroup);
			if (bgmId === 'bgmGame' || bgmId === 'armageddon' || bgmId === 'arena') {
				ins.addGroup(bgmGameSoundGroup);
			}
		}
	}
	useDepthFilter = settingx.now.depthFilter;
	if (useDepthFilter) {
		bgms.bgmGame.sound.filters = [depthFilter];
		bgms.armageddon.sound.filters = [depthFilter];
	}

}, 10);

export function setDepthFilter(value: boolean) {
	if (value) {
		bgms.bgmGame.sound.filters = [depthFilter];
		bgms.armageddon.sound.filters = [depthFilter];
	} else {
		bgms.bgmGame.sound.filters = [];
		bgms.armageddon.sound.filters = [];
	}
}

export class BgmManager extends SoundGroup {
	private static _instance: BgmManager = new BgmManager();

	public currentBgmId: BgmId | '' = '';

	public static get instance() {
		return BgmManager._instance;
	}
	public static get currentBgm() {
		return bgms[BgmManager.instance.currentBgmId];
	}

	public bgmLoaded(bgmId: BgmId) {
		if (this.currentBgmId === bgmId) {
			const bgm = bgms[bgmId];
			bgm.play({ start: 0, loop: true });
		}
	}

	public play(bgmId: BgmId | '') {
		if (bgmId === this.currentBgmId) {
			return;
		}
		this.fadeOut(this.currentBgmId);
		this.currentBgmId = bgmId;
		if (!bgmId) { return; }
		const bgm = bgms[bgmId];
		bgm.play({ start: 0, loop: true });
	}

	public fadeOut(bgmId: BgmId | '') {
		const soundInstance = bgms[bgmId];
		if (soundInstance) {
			for (const intances of soundInstance.sound.instances) {
				Tween.get(intances, { override: true }).to({ volume: 0 }, 500).call(() => {
					if (this.currentBgmId !== bgmId) {
						intances.destroy();
					}
				});
			}
		}
	}

	public setDepth(value: number) {
		if (useDepthFilter) {
			const depth = value * 40;
			depthFilter.f32 = depth / 5 * 4.5;
			depthFilter.f64 = depth / 5 * 3.5;
			depthFilter.f125 = depth / 5 * 2.5;
			depthFilter.f250 = depth / 5 * 1.5;
			depthFilter.f500 = depth / 5 * 0.5;
			depthFilter.f1k = -depth / 5 * 0.5;
			depthFilter.f2k = -depth / 5 * 1.5;
			depthFilter.f4k = -depth / 5 * 2.5;
			depthFilter.f8k = -depth / 5 * 3.5;
			depthFilter.f16k = -depth / 5 * 4.5;
		}
		bgmGameSoundGroup.volume = (1 - value * 1.5);
	}
}

