import { RoomControl } from '@/client/controls/roomControl';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { MiscData, MiscDataSsSpinDodge } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Tween } from '@/util/tweents';
import { rgbToInteger } from '../../helper';
import { disposeParticle, getParticle, Particle } from '../../particles';
import { MiscSymbol } from './miscSymbol';

export class SsSpinDodgeSymbol implements MiscSymbol {
	public static get() {
		return SsSpinDodgeSymbol._pool.get();
	}

	private static _pool: Pool<SsSpinDodgeSymbol> = new Pool(SsSpinDodgeSymbol);

	public data: MiscData | null = null;

	// public info = specialSkillInfos[SpecialSkill.Ration];


	public dispose(room: RoomControl) {
		this.data = null;
		SsSpinDodgeSymbol._pool.pool(this);
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsSpinDodge;
		if (!this.data) {
			const newP = Math.floor(data.p * 10) / 10;
			this.makeShadow(room, newP, dt.x, dt.y, data.bs);
			new SoundEfx('whoosh').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
		} else {
			const oldProgress = (this.data as MiscDataSsSpinDodge).p;
			const oldP = Math.floor(oldProgress * 10) / 10;
			const newP = Math.floor(data.p * 10) / 10;
			if (oldP !== newP) {
				this.makeShadow(room, newP, dt.x, dt.y, data.bs);
			}
		}

		this.data = data;
	}

	public show(room: RoomControl) {
	}
	public hide(room: RoomControl) {
	}
	protected makeShadow(room: RoomControl, progress: number, x: number, y: number, scale: number) {
		const shadow = getParticle(Particle.TeamCircle);
		room.backParticlesContainer.addChild(shadow);
		shadow.alpha = 0.5;
		shadow.scale.set(0.75 * scale);
		shadow.x = x;
		shadow.y = y;

		shadow.tint = rgbToInteger(
			0,
			125 + 125 * progress,
			125 + 50 * progress,
		);

		Tween.get(shadow).to({ alpha: 0 }, 500).call(() => {
			shadow.scale.set(1);
			shadow.alpha = 1;
			if (shadow.parent) {
				shadow.parent.removeChild(shadow);
			}
			disposeParticle(Particle.TeamCircle, shadow);
		});
	}
}
