













































































































import { ArmageddonHighscoreData } from '@/game/infos/highscoreInfos';
import { UserData } from '@/game/multithread/viewState';
import gamex from '@/store/modules/gamex';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UserComponent extends Vue {
  @Prop({ required: true }) public user!: UserData;
  @Prop({ default: () => [] })
  public armageddonWinners!: ArmageddonHighscoreData[];
  public interval: any;
  public get users() {
    return gamex.users;
  }
  public get isMe() {
    return userx.uid === this.user.uid;
  }
  public get isDraco() {
    return (
      this.user.name === 'dragonwhites' &&
      this.user.uid === 'fTaakPLzz0c8WTgQd8hkPZvaYu03'
    );
  }
  public get allTime() {
    if (!globalx.rankings) {
      return 0;
    }
    const index = globalx.rankings.highscores.findIndex(
      (h) => h.uid === this.user.uid,
    );
    return index + 1;
  }
  public get today() {
    if (!globalx.rankings) {
      return 0;
    }
    const index = globalx.rankings.todayHighscores.findIndex(
      (h) => h.uid === this.user.uid,
    );
    return index + 1;
  }
  public get nemeses() {
    if (!globalx.rankings) {
      return 0;
    }
    const index = globalx.rankings.nemesesHighscores.findIndex(
      (h) => h.uid === this.user.uid,
    );
    return index + 1;
  }
  public get craft() {
    if (!globalx.rankings) {
      return 0;
    }
    const index = globalx.rankings.craftingHighscores.findIndex(
      (h) => h.uid === this.user.uid,
    );
    return index + 1;
  }
  public get rich() {
    if (!globalx.rankings) {
      return 0;
    }
    const index = globalx.rankings.wealthHighscores.findIndex(
      (h) => h.uid === this.user.uid,
    );
    return index + 1;
  }
  public get isSurvivor() {
    const index = this.armageddonWinners.findIndex(
      (h) => h.type === 'survivor' && h.uid === this.user.uid,
    );
    return index !== -1;
  }
  public get isExplorer() {
    return gamex.explorerUid === this.user.uid;
  }

  public onMouseOver() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      (this.$refs.scroll as HTMLDivElement).scrollLeft += 1;
    }, 66);
  }
  public onMouseOut() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    (this.$refs.scroll as HTMLDivElement).scrollLeft = 0;
  }
}
