
























import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  WeeklyHighscoreData,
  WeeklyHighscoreDataWithRank,
} from './WeeklyContestComponent.vue';

@Component({
  components: {},
})
export default class WeeklyHighscoreComponent extends Vue {
  @Prop({ default: 0 }) public index!: number;
  @Prop() public data?: WeeklyHighscoreDataWithRank;

  public get name() {
    return this.data ? this.data.name : userx.userDoc.name;
  }
  public get count() {
    return this.data ? addComma(this.data.count) : '???';
  }
  public get isMe() {
    return this.data ? this.data.uid === userx.uid : true;
  }
}
