

























































































import { itemInfos, Rarity } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import settingx from '@/store/modules/settingx';
import { roundToFixed } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { blueprintInfos } from '@/game/infos/blueprintInfos';

@Component({
  components: {},
})
export default class ItemButton extends Vue {
  public get mouseMode() {
    return settingx.now.control === 'mouse';
  }

  public get hasIndicator() {
    return this.pinkIndicator || this.yellowIndicator;
  }
  @Prop({ default: null }) public data!: ItemCodeAmountPair | null;
  @Prop({ default: false }) public selectable!: boolean;
  @Prop({ default: false }) public confirmable!: boolean;
  @Prop({ default: false }) public selected!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public active!: boolean;
  @Prop({ default: false }) public small!: boolean;
  @Prop({ default: false }) public pinkIndicator!: boolean;
  @Prop({ default: false }) public yellowIndicator!: boolean;
  @Prop({ default: true }) public showRarity!: boolean;
  @Prop({ default: true }) public showAmount!: boolean;
  @Prop({ default: false }) public hideBg!: boolean;
  @Prop({ default: false }) public greyOut!: boolean;
  @Prop({ default: false }) public halfGreyOut!: boolean;
  @Prop({ default: false }) public locked!: boolean;
  @Prop({ default: false }) public showCrafted!: boolean;

  public touchingStart = 0;
  public touchX = 0;
  public touchY = 0;

  public get rarity() {
    return this.showRarity && this.data
      ? itemInfos[this.data.itemCode].rarity
      : Rarity.Common;
  }
  public get boxStyle() {
    const color = Global.rarityRgbs[this.rarity];
    return this.hideBg
      ? {}
      : {
          border: `1px solid rgba(${color}, ${this.rarity * 0.15 + 0.2})`,
          background: `rgba(${color}, ${this.rarity * 0.05})`,
        };
  }

  public get imgSrc() {
    if (!this.data) {
      return '';
    }
    let itemCode = this.data.itemCode;
    if (this.showCrafted) {
      const bpInfo = blueprintInfos[itemCode];
      if (bpInfo && bpInfo.result) {
        itemCode = bpInfo.result;
      }
    }
    let id = itemCode.toString();
    while (id.length < 4) {
      id = '0' + id;
    }
    return `/images/items/items${id}.png`;
  }

  public onClick(e) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    if (!this.disabled) {
      this.$emit('click', event);
    }
  }

  public onMouseOver(e: any) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    this.$emit('mouseover', e);
  }
  public async onTouchStart(e: any) {
    this.$emit('mouseover', e);
    this.touchX = e.changedTouches[0]?.screenX;
    this.touchY = e.changedTouches[0]?.screenY;
    this.touchingStart = Date.now();

  }
  public async onTouchEnd(e: any) {
    this.$emit('mouseleave', e);
    if (
      Date.now() - this.touchingStart < 500 &&
      this.touchX === e.changedTouches[0]?.screenX &&
      this.touchY === e.changedTouches[0]?.screenY
    ) {
      this.$emit('click', e);
    }

  }
}
