
















































import { getSkinMiniImgUrl } from '@/client/factory/gachaAssets';
import { decodeSkinId, SkinInfo, skinInfos } from '@/game/infos/skinInfos';
import { Global } from '@/store/globalz';
import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SkinButton extends Vue {
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public small!: boolean;
  @Prop({ default: false }) public showNew!: boolean;
  @Prop({ default: false }) public showEquipped!: boolean;
  @Prop({ default: false }) public showStorageIcon!: boolean;
  @Prop({ default: false }) public showNumber!: boolean;
  @Prop({ default: false }) public showPointer!: boolean;
  @Prop({ default: false }) public selected!: boolean;
  @Prop({ default: 0 }) public number!: number;
  @Prop({ default: 0 }) public skinId!: number;
  public touchingStart = 0;
  public touchX = 0;
  public touchY = 0;

  public get skinSrc() {
    const { fishType, skinGroup } = this.skinInfo;
    const url = getSkinMiniImgUrl(skinGroup, fishType);

    return url;
  }

  public get skinInfo(): SkinInfo {
    const { fishType, skinGroup } = decodeSkinId(this.skinId);
    return skinInfos[skinGroup] && skinInfos[skinGroup][fishType]
      ? skinInfos[skinGroup][fishType]
      : {
          name: '',
          fishType,
          skinGroup,
          rarity: 2,
          rarityForColor: 0,
          skinId: this.skinId,
        };
  }

  public get borderStyle() {
    if (this.selected) {
      return {
        border: `2px solid yellow`,
      };
    }
    const color =
      Global.rarityRgbs[this.disabled ? 0 : this.skinInfo.rarityForColor];
    return {
      border: `2px solid rgba(${color}, ${(this.skinInfo.rarity - 2) * 0.4 +
        0.2})`,
    };
  }
  public get bgStyle() {
    const color =
      Global.rarityRgbs[this.disabled ? 0 : this.skinInfo.rarityForColor];
    return {
      backgroundColor: `rgba(${color}, 0.15)`,
    };
  }

  public onClick(e) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    this.$emit('click', event);
  }

  public onMouseOver(e: any) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    this.$emit('mouseover', e);
  }
  public async onTouchStart(e: any) {
    this.$emit('mouseover', e);
    this.touchX = e.changedTouches[0]?.screenX;
    this.touchY = e.changedTouches[0]?.screenY;
    this.touchingStart = Date.now();

  }
  public async onTouchEnd(e: any) {
    this.$emit('mouseleave', e);
    if (
      Date.now() - this.touchingStart < 500 &&
      this.touchX === e.changedTouches[0]?.screenX &&
      this.touchY === e.changedTouches[0]?.screenY
    ) {
      this.$emit('click', e);
    }

  }
}
