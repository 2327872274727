import { SoundInstance } from './SoundInstance';
import { Sound, PlayOptions, IMediaInstance } from '@pixi/sound';
import { Global } from '@/store/globalz';
import { SfxId, sfxs } from './soundInfos';

export class SoundEfx extends SoundInstance {

	public media: IMediaInstance | null = null;

	constructor(sfxId: SfxId) {
		super(sfxs[sfxId]);
		this.addGroup(Global.masterSoundGroup);
		this.addGroup(Global.sfxSoundGroup);
	}

	public play(options: PlayOptions = {}) {
		options.loop = false;
		if (options.volume !== undefined) {
			this.volume = options.volume;
		}
		if (this.media) {
			this.media.destroy();
			this.media = null;
		}
		options.volume = this.getVolume();
		if (options.complete) {
			const customComplete = options.complete;
			options.complete = () => {
				this.dispose();
				customComplete(this.sound);
			};
		} else {
			options.complete = () => { this.dispose(); };
		}
		if (this.sound.isPlayable) {
			this.media = this.sound.play(options) as IMediaInstance;
		}
	}
	public start() {
		if (!this.media) {
			this.play();
		}
	}
	public stop() {
		if (this.media) {
			this.media.destroy();
			this.media = null;
		}
	}
	public updateVolume() {
		if (this.media) {
			this.media.volume = this.getVolume();
		}
	}
}
