
export enum MechguinMissileState {
	Idle,
	PointUp,
	LauchWater,
	ExitWater,
	Push,
	BeginArc,
	FreeFall,
	Explode,
}
export enum MechguinTurretState {
	Idle,
	Firing,
	Fired,
	Weak,
}
export enum MechguinSnowBallState {
	Growing,
	Fired,
}
export enum MechguinWingState {
	Idle,
	Snowing,
	Weak,
}
export enum MechguinLaserGunState {
	Idle,
	Weak,
	Active,
	Setup,
	Preparing,
	Firing,
	Returning,
}
export enum MechguinPenguinState {
	Spawn,
	Stabab,
	Active,
	Fleeing,
}

export enum MechguinState {
	Active,
	Weak,
	Stunnable,
	Unstunnable,
	Dead,
	Empty,
}
