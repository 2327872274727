













































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { Global } from '@/store/globalz';
import {
  FishInfo,
  fishInfos,
  fishTiers,
  FishType,
} from '@/game/infos/fishInfos';
import userx from '@/store/modules/userx';
import settingx from '@/store/modules/settingx';
import { FishIntelData } from '../mainMenu/tools/fishSelector/FishIntelTooltip.vue';
import FishButton from '../mainMenu/tools/inventory/FishButton.vue';

@Component({
  components: {
    FishButton,
  },
})
export default class TnyFishSelectModal extends Vue {
  public get titleHtml() {
    return `<i class="fa fa-fish"></i> ${this.title}`;
  }

  public get unlocked() {
    return userx.fishUnlocked;
  }
  public get fishUnlockProgress() {
    return userx.fishUnlockProgress;
  }
  public resolve: null | ((value: FishType | null) => void) = null;
  public fishType: FishType = FishType.BlueShark;
  public okTitle = 'Choose';
  public title = 'Choose fish';

  public selectables: FishType[] = [];
  public currentFishType: FishType = FishType.BlueShark;

  public get inventory() {
    const result: FishInfo[][] = [[], [], []];
    for (const fishType of this.selectables) {
      const info = fishInfos[fishType];
      result[info.tier - 1].push(info);
    }
    return result;
  }

  public show(currentFishType: FishType, selectables: FishType[]) {
    if (this.resolve) {
      this.resolve(this.currentFishType);
    }
    this.selectables = selectables;
    this.currentFishType = currentFishType;
    this.fishType = currentFishType;

    const modal = this.$refs.modal as BModal;
    if (modal) {
      modal.show();
    }
    return new Promise((resolve: (value: FishType | null) => void) => {
      this.resolve = resolve;
    });
  }

  public select(fishType: FishType) {
    this.fishType = fishType;
    if (this.resolve) {
      this.resolve(this.fishType);
    }
    this.resolve = null;
    const modal = this.$refs.modal as BModal;
    if (modal) {
      modal.hide();
    }
  }

  public onOk() {
    if (this.resolve) {
      this.resolve(this.fishType);
    }
    this.resolve = null;
  }

  public onCancel() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }
  public onClose() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }

  public showFishIntel(fishType: number) {
    const data: FishIntelData = {
      targetId: 'fish-select-' + fishType,
      placement: 'right',
      container: 'fish-select',
      fishType,
    };
    this.$root.$emit('showFishIntel', data);
  }
  public hideFishIntel() {
    this.$root.$emit('hideFishIntel');
  }
  public created() {
    // Global.fishInvetoryModal = this;
  }
}
