












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gamex from '@/store/modules/gamex';
import { Global } from '@/store/globalz';
@Component({
	components: {},
})
export default class IdolNoteModal extends Vue {
	public get explorer() {
		return gamex.explorerName;
	}

	public onShow() {
		gamex.updateIvModalShowing(true);
	}
	public onHide() {
		gamex.updateIvModalShowing(false);
		Global.stageControl.uiControl.closeIdol();
	}
}
