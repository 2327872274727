







































































import StateButton from '@/components/inputs/StateButton.vue';
import { ServerData } from '@/game/infos/serverInfos';
import { ServerList } from '@/store/api/serverList';
import { ServerTime } from '@/store/api/serverTime';
import { EditState } from '@/store/models.def';
import globalx from '@/store/modules/globalx';
import { Dictionary } from '@/util/dictionary';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ServerComponent from './ServerComponent.vue';

@Component({
  components: { ServerComponent, StateButton },
})
export default class ServerModal extends Vue {
  public get titleHtml() {
    return `Servers`;
  }
  public get myServer() {
    return globalx.selectedServer || globalx.recommendedServer;
  }
  public get myServerName() {
    if (!this.myServer) {
      return '';
    }
    const split = this.myServer.name.split('|');
    return split[0] + '-' + split[1] + '-' + split[2] + '-' + split[3];
  }

  public get fastestContinent() {
    const fastestContinent: string[] = [];
    for (const server of globalx.fastestServers) {
      if (
        (server.armageddonStartTime &&
          this.time > server.armageddonStartTime) ||
        (server.closeTime && this.time > server.closeTime - 60000)
      ) {
        continue;
      }
      const continent = server.name.split('|')[0];
      if (fastestContinent.indexOf(continent) === -1) {
        fastestContinent.push(continent);
        this.servers[continent] = [];
      }
      this.servers[continent].push(server);
    }
    for (const continent in this.servers) {
      if (Object.prototype.hasOwnProperty.call(this.servers, continent)) {
        const servers = this.servers[continent];
        servers.sort((a, b) => {
          if (!a.ping || a.ping === -1) {
            return 1;
          }
          if (!b.ping || b.ping === -1) {
            return -1;
          }
          return a.ping - b.ping;
        });
      }
    }
    return fastestContinent;
  }
  public get refreshLabel() {
    const labels = {
      active: 'Refresh',
      waiting: 'Refresh',
      sending: 'Refresh',
      done: 'Refresh',
    };
    return labels[this.refreshState];
  }
	public get time() {
		return globalx.time;
	}

  public servers: Dictionary<ServerData[]> = {};

  public refreshState: EditState = 'active';
  public mounted() {
    if (globalx.previousPing < Date.now() - 3000) {
      this.onRefresh();
    }
  }

  public async onRefresh() {
    try {
      this.refreshState = 'sending';
      await ServerList.sync();
      await ServerList.pingAll();

      this.refreshState = 'active';
    } catch (error) {
      this.showError(error);
    }
  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
