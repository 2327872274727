


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gamex from '@/store/modules/gamex';
import { Global } from '@/store/globalz';
import { GameCommandType, IGameCommand, CommandDojoIdAction } from '@/game/multithread/command';
import ScrollContainer from '../../ScrollContainer.vue';

@Component({
	components: {
		ScrollContainer,
	},
})
export default class DojoLobbyModal extends Vue {

	public get users() {
		const users = Object.values(gamex.users).filter((u) => u.dojoId);
		return users;
	}

	public async onCreateDojo() {
		const command: IGameCommand = {
			type: GameCommandType.DojoCreate,
			sessionId: Global.clientCommunicator.sessionId,
		};
		Global.clientCommunicator.sendCommand(command);

		Global.$root.$bvModal.hide('dojoLobby-modal');
	}
	public async onToDojo(dojoId: number) {
		const command: CommandDojoIdAction = {
			type: GameCommandType.DojoGoto,
			sessionId: Global.clientCommunicator.sessionId,
			id: dojoId,
		};
		Global.clientCommunicator.sendCommand(command);

		Global.$root.$bvModal.hide('dojoLobby-modal');
	}
	public onShow() {
		gamex.updateIvModalShowing(true);
	}
	public onHide() {
		gamex.updateIvModalShowing(false);
		Global.stageControl.uiControl.closeDojoLobby();
	}
}
