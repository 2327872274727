























import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

export interface ContextMenuItem {
  icon?: string;
  disabled?: boolean;
  text: string;
  click?: () => void;
}

@Component({
  components: {},
})
export default class ContextMenu extends Vue {
  public get myStyle() {
    return {
      position: 'fixed',
      top: this.y + 'px',
      left: this.x + 'px',
    };
  }

  public items: ContextMenuItem[] = [];

  public x = 0;
  public y = 0;
  public mounted() {
    Global.contextMenu = this;
    document.body.appendChild(this.$el);
  }
  public beforeDestroy() {
    document.body.removeChild(this.$el);
  }
  public open(event: any, items: ContextMenuItem[]) {
    const touch = event.changedTouches && event.changedTouches[0];
    if (touch) {
      this.x = touch.clientX;
      this.y = touch.clientY;
    } else {
      this.x = event.x;
      this.y = event.y;
    }
    this.items = items;
    (this.$refs.menu as any).show();
  }

  public onClick(item: ContextMenuItem) {
    if (item.click) {
      item.click();
    }
  }
}
