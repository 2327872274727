













































































































































































































































import { DecoSet } from '@/game/infos/decorativeInfos';
import {
	HighscoreData,
	ArmageddonHighscoreData,
	CraftingHighscoreData,
	WealthHighscoreData,
} from '@/game/infos/highscoreInfos';
import { Global } from '@/store/globalz';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { addComma, roundToFixed } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ScrollContainer from '../../ScrollContainer.vue';
import AmgdHighscoreComponent from './AmgdHighscoreComponent.vue';
import CraftingHighscoreComponent from './CraftingHighscoreComponent.vue';
import WealthHighscoreComponent from './WealthHighscoreComponent.vue';
import HighscoreComponent from './HighscoreComponent.vue';
import NemesesHighscoreComponent from './NemesesHighscoreComponent.vue';
import { wait } from '@/util/wait';
import { fishTiers, FishType } from '@/game/infos/fishInfos';
const fishTypes: Array<{ text: string, value: FishType; }> = fishTiers[2].map((i) => ({ text: i.name, value: i.type }));
fishTypes.unshift({ text: 'All Fish', value: FishType.Random });
type Tab =
	| 'today'
	| 'allTime'
	| 'personal'
	| 'crafting'
	| 'armageddon'
	| 'wealth'
	| 'nemeses'
	| 'best';

@Component({
	components: {
		ScrollContainer,
		HighscoreComponent,
		CraftingHighscoreComponent,
		AmgdHighscoreComponent,
		WealthHighscoreComponent,
		NemesesHighscoreComponent,
	},
})
export default class HighscoreTab extends Vue {
	public get personal() {
		return (
			userx.userDoc.record || {
				kills: 0,
				stabs: 0,
				killstreaks: 0,
				stabstreaks: 0,
				money: 0,
				score: 0,
				lastSurvivors: 0,
				firstExplorer: 0,

				totalStabs: 0,
				totalKills: 0,
				totalMoneyEarned: 0,
			}
		);
	}

	public get amgdTimeUsed() {
		const time = this.personal.amgdTimeUsed;
		if (!time) {
			return '';
		}
		let secs = Math.floor((time % 60000) / 1000).toString();
		if (secs.length < 2) {
			secs = '0' + secs;
		}
		const mins = Math.floor(time / 60000);
		return `${mins}m ${secs}s`;
	}
	public get explorerTimeUsed() {
		const time = this.personal.explorerTimeUsed;
		if (!time) {
			return '';
		}
		let secs = Math.floor((time % 60000) / 1000).toString();
		if (secs.length < 2) {
			secs = '0' + secs;
		}
		const mins = Math.floor(time / 60000);
		return `${mins}m ${secs}s`;
	}
	@Prop({ default: false }) public hasCloseBtn!: boolean;

	public tab: Tab = 'today';
	public addComma = addComma;
	public roundToFixed = roundToFixed;
	public highscores: HighscoreData[][] = [];
	public todayHighscores: HighscoreData[][] = [[]];
	public nemesesHighscores: HighscoreData[][] = [];
	public craftingHighscores: CraftingHighscoreData[][] = [[]];
	public amgdHighscores: ArmageddonHighscoreData[][] = [[]];
	public wealthHighscores: WealthHighscoreData[][] = [[]];
	public fishHighscores: HighscoreData[][] = [[]];
	public fishNemeses: HighscoreData[][] = [[]];

	public fishType: FishType = FishType.Random;
	public fishTypes = fishTypes;
	public sub: 'Highscore' | 'Nemeses' = 'Highscore';
	public subs = ['Highscore', 'Nemeses'];

	public constructor() {
		super();
		for (const tv of fishTypes) {
			this.highscores[tv.value] = [];
			this.nemesesHighscores[tv.value] = [];
		}
	}
	public onFishTypeChanged() {
		this.selectTab(this.tab);
	}
	public onSubChanged() {
		this.selectTab(this.tab);
	}

	public selectTab(tab: Tab) {
		this.tab = tab;
		const json = globalx.rankings;
		if (json) {
			if (tab === 'today') {
				this.insertIfEmpty(this.todayHighscores, json.todayHighscores, 0);
			} else if (tab === 'allTime') {
				this.insertIfEmpty(this.highscores, json.highscores, this.fishType);
			} else if (tab === 'nemeses') {
				this.insertIfEmpty(this.nemesesHighscores, json.nemesesHighscores, this.fishType);
			} else if (tab === 'crafting') {
				this.insertIfEmpty(this.craftingHighscores, json.craftingHighscores, 0);
			} else if (tab === 'wealth') {
				this.insertIfEmpty(this.wealthHighscores, json.wealthHighscores, 0);
			} else if (tab === 'armageddon') {
				this.insertIfEmpty(this.amgdHighscores, json.amgdHighscores, 0);
			} else if (tab === 'best') {
				if (this.sub === 'Highscore') {
					this.insertIfEmpty(this.fishHighscores, json.fishHighscores, 0);
				} else {
					this.insertIfEmpty(this.fishNemeses, json.fishNemeses, 0);
				}
			}
		}
	}
	public async insertIfEmpty(list: any[][], list2: any[], fishType: number) {
		if (fishType === 0) {
			if (list[fishType].length === 0) {
				this.insertHighscoreData(list[fishType], list2, 0);
			}
		} else {
			if (list[fishType].length === 0) {

				const lobbyServerAddress = globalx.lobbyServerAddress;
				try {
					const response = await fetch(`${lobbyServerAddress}/fishRanks/${this.tab}/${fishType}?v=ae`, {
						method: 'GET',
					});
					if (!response.ok) {
						throw await response.json();
					}
					const json = await response.json();
					this.insertHighscoreData(list[fishType], json, 0);
				} catch (error) {
					throw error;
				}
			}
		}
	}
	public async insertHighscoreData(list: any[], list2: any[], index: number) {
		list.push(list2[index]);
		if (list2[index + 1]) {
			await wait(5);
			this.insertHighscoreData(list, list2, index + 1);
		}
	}

	public mounted() {
		this.loadHighscore();
	}
	protected async loadHighscore() {
		const lobbyServerAddress = globalx.lobbyServerAddress;
		try {
			const response = await fetch(`${lobbyServerAddress}/highscores?v=ae`, {
				method: 'GET',
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			// this.highscores[0].length = 0;
			// this.nemesesHighscores[0].length = 0;

			for (const tv of fishTypes) {
				this.highscores[tv.value].length = 0;
				this.nemesesHighscores[tv.value].length = 0;
			}
			this.todayHighscores[0].length = 0;
			this.craftingHighscores[0].length = 0;
			this.amgdHighscores[0].length = 0;
			this.wealthHighscores[0].length = 0;
			this.fishHighscores[0].length = 0;
			this.fishNemeses[0].length = 0;
			globalx.updateRankings(json);
			this.selectTab(this.tab);
		} catch (error) {
			throw error;
		}
	}
}
