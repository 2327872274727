export class Preset {
    public static renderer: IGraphics | null = null;

    public static ROOM_WIDTH = 6;
    public static ROOM_HEIGHT = 4;
    public static SECTOR_SIZE = 2047;
    public static BLOCK_SIZE = 341;
    public static EMPTY_SECTOR_SIZE = 1370;
    public static EMPTY_MAIN_NODE_SIZE = 922;
    public static EMPTY_SIDE_NODE_SIZE = 796;
    public static DOJO_SIZE = 1536;
    public static DOJO_GAP = -Preset.SECTOR_SIZE * 4;
    public static DOJO_Y = Preset.SECTOR_SIZE * 2;
    public static TUTORIAL_SIZE = 1024;
    public static TUTORIAL_Y = Preset.SECTOR_SIZE * 4;


    public static PLAYER_RADIUS = 45;

    public static SPEAR_MIN_LEVEL = 3;
    public static SPEAR_GRID_HEIGHT = 36;
    public static SPEAR_MAX_SCALE_BEFORE_LVL_UP = 0.5;
    public static SPEAR_WIDTH = 36;
    public static SPEAR_WIDTH_INCREMENT_PER_LVL = 6 - 3;

    public static NEMESIS_BLINK_DURATION = 1000;
    public static BORN_BLINK_DURATION = 4000;
    public static RESCUED_BLINK_DURATION = 2000;
    public static RESCUED_MOVABLE_DURATION = Preset.RESCUED_BLINK_DURATION - 500;
    public static TELEPORT_BLINK_DURATION = 1500;
    public static OUROBOROS_SHRINK_DURATION = 150;

    public static FOOD_EATEN_DELAY = 500;
    public static FOOD_EDIBLE_DELAY = 500;

    public static MAX_STAMINA = 200;
    public static STAMINA_RECOVER_DELAY = 800;

    public static STABAB_LENGTH = 50;
    public static STABAB_MIN_LENGTH = 40;
    public static STABAB_WIDTH = 60;

    public static STABAB_BLEEDOUT_TIMEOUT = 15000;
    public static STABAB_REINCARNATE_TIMEOUT = 30000;
    public static OXYGEN_TIMEOUT = 60000;

    public static DISCHARGE_HIT_RADIUS = Preset.PLAYER_RADIUS + 25;
    public static DEFAULT_DISCHARGE_LIFE = 3000;
    public static BLIND_DURATION = 5000;
    public static PARALYZE_DURATION = 3000;

}

interface IGraphics {
    lineStyle: (width: number, color?: number, alpha?: number) => void,
    moveTo: (x: number, y: number) => void,
    lineTo: (x: number, y: number) => void,
    drawCircle: (x: number, y: number, radius: number) => void,
    clear: () => void,
}
