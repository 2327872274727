export function roundToFixed(num: number, decimal = 2) {
  const mul = 10 ** decimal;
  return Math.round(num * mul) / mul;
}
export function floorToFixed(num: number, decimal = 2) {
  const mul = 10 ** decimal;
  return Math.floor(num * mul) / mul;
}

export function addComma(num: number,
                         decimal = 2,
                         forceDecimal = false,
                         fillDecimal = true) {
  const parts = roundToFixed(num, decimal).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1].substring(0, decimal);
    while (parts[1].length < decimal && (fillDecimal || forceDecimal)) { parts[1] += '0'; }
  } else if (forceDecimal) {
    parts[1] = '0'.repeat(decimal);
  }
  return parts.join('.');
}
export function addMK(num: number, mDecimal = 2, kDecimal = 1) {
  if (num >= 1000000) {
    return addComma(num / 1000000, mDecimal, mDecimal ? true : false) + 'm';
  } else if (num >= 1000) {
    return addComma(num / 1000, kDecimal, kDecimal ? true : false) + 'k';
  } else { return addComma(Math.round(num), 0); }
}

export function ordinalSuffix(n: number) {
  const i = n;
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function storeBoolean(target: number, index: number, value = true) {
  if (value) {
    target |= (1 << index);
  } else {
    target &= (~(1 << index));
  }
  return target;
}
export function getBoolean(target: number, index: number) {
  return (target & (1 << index)) !== 0;
}
