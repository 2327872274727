import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsForceField, MiscDataSsWhammyWave } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';


export class SsForceFieldSymbol extends Container implements MiscSymbol {

	public area!: Graphics;
	public field!: Container;

	public data: MiscDataSsForceField | null = null;
	public pt = 0;

	public sound = new LongSoundEfx('forceField');

	public init() {
		this.area = this.children[0] as Graphics;
	}
	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		Tween.get(this).to({ alpha: 0, angle: this.angle + 90 }, 300).call(() => {
			if (this.parent) {
				this.parent.removeChild(this);
			}
			Factory.pool(SsForceFieldSymbol, this);
		});
		Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
			this.sound.stop();
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsForceField;
		this.area.x = data.x;
		this.area.y = data.y;

		this.x = data.x;
		this.y = data.y;

		this.alpha = 1;
		this.angle += 0.05;

		if (data.ts) {
			this.area.visible = true;
			this.visible = false;
			this.area.scale.set(data.ts);
		} else {
			this.area.visible = false;
			this.visible = true;
			this.scale.set(data.sc);
			const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
			this.sound.volume = volume * .2;
			this.sound.start();
		}

		this.data = data;
	}
	public show(room: RoomControl) {
		if (this.data?.ts) {
			room.wallBottomContainer.addChild(this.area);
		}
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
		this.sound.volume = 0;
	}
}
