import { SoundEfx } from '@/client/sound/SoundEfx';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import globalx from '@/store/modules/globalx';
import { ManualTween } from '@/util/animatingVertices';
import { Point, Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, Text, Ticker } from 'pixi.js';
import Factory from '../..';
import { PlayerSymbol } from '../playerMixin';
import { UnlockAnimationBubble, UnlockAnimationStar } from './unlockParticles';

export class StarSymbol extends Container {

}

const textTime = 4400;
const starTime = 5000;
const burstTime = 3700;

export class UnlockAnimation extends MovieClip {

	public fish!: Container;
	public container!: Container;
	public playerTopContainer!: Container;
	public playerBottomContainer!: Container;

	public tier = 1;

	public title = new Text('', {
		fontFamily: 'Titan One',
		fontSize: 90,
		fill: 0xffffff,
		align: 'center',
	});


	public star1 = Factory.get(StarSymbol);
	public star2 = Factory.get(StarSymbol);
	public star3 = Factory.get(StarSymbol);
	public starContainer1 = new Container();
	public starContainer2 = new Container();
	public starContainer3 = new Container();

	public white = new Graphics();
	public playerSymbol = PlayerSymbol.get();
	// public bg!: Container;
	public flash = this._flash.bind(this);

	protected prevPos: Point = { x: 0, y: 0 };

	protected tickAdded = false;


	protected tween1 = new ManualTween(this).wait(0)
		.call(() => {
			this.playerSymbol.setAction('skill1');
		})
		.wait(3530).call(() => {
			this.playerSymbol.setAction('move');
		});

	protected ttTween1 = new ManualTween(this.title).to({ x: 2600 }, 0)
		.wait(textTime)
		.to({ x: 0 }, 1100, Ease.circOut);
	protected scTween1 = new ManualTween(this.starContainer1).to({ x: 1300 }, 0)
		.wait(starTime)
		.to({ x: 0 }, 1100, Ease.circOut);
	protected srTween1 = new ManualTween(this.star1).to({ angle: 2000 }, 0)
		.wait(starTime)
		.to({ angle: 0 }, 1200)
		.call(this.flash);
	protected scTween2 = new ManualTween(this.starContainer2).to({ x: 1300 }, 0)
		.wait(starTime + 500)
		.to({ x: 0 }, 1100, Ease.circOut);
	protected srTween2 = new ManualTween(this.star2).to({ angle: 2000 }, 0)
		.wait(starTime + 500)
		.to({ angle: 0 }, 1200)
		.call(this.flash);
	protected scTween3 = new ManualTween(this.starContainer3).to({ x: 1300 }, 0)
		.wait(starTime + 1000)
		.to({ x: 0 }, 1100, Ease.circOut);
	protected srTween3 = new ManualTween(this.star3).to({ angle: 2000 }, 0)
		.wait(starTime + 1000)
		.to({ angle: 0 }, 1200)
		.call(this.flash);


	public init() {
		this.title.anchor.set(0.5);
		this.title.y = -460;
		this.title.x = 2600;
		this.container.addChild(this.title);
		this.stop();
		this.white.beginFill(0xffffff);
		this.white.drawRect(-1000, -540, 2000, 1080);
		this.container.addChild(this.white);
		this.white.alpha = 0;
		this.fish.addChild(this.playerSymbol);
		this.container.addChild(this.starContainer1);
		this.container.addChild(this.starContainer2);
		this.container.addChild(this.starContainer3);

		this.starContainer1.addChild(this.star1);
		this.starContainer2.addChild(this.star2);
		this.starContainer3.addChild(this.star3);
		this.starContainer1.y = this.starContainer2.y = this.starContainer3.y = 440;
		this.starContainer1.x = this.starContainer2.x = this.starContainer3.x = 1300;

		this.starContainer1.children[0].x = -120;
		this.starContainer3.children[0].x = 120;
	}

	public show(fishType: FishType) {
		this.playerSymbol.setFishType(fishType, 0);
		if (!this.tickAdded) {
			Ticker.shared.add(this.tick);
			this.tickAdded = true;
		}
		this.gotoAndPlay(0);
		this.white.alpha = 0;

		const info = fishInfos[fishType];
		this.title.text = info.name;
		const tier = this.tier = info.tier;
		if (tier === 1) {
			this.star1.x = 0;
			this.starContainer2.visible = false;
			this.starContainer3.visible = false;
		} else if (tier === 2) {

			this.star1.x = -60;
			this.star2.x = 60;
			this.starContainer2.visible = true;
			this.starContainer3.visible = false;
		} else {
			this.star1.x = -120;
			this.star2.x = 0;
			this.starContainer2.visible = true;
			this.starContainer3.visible = true;
		}
		this.tween1.setPosition(0);
		this.ttTween1.setPosition(0);
		this.scTween1.setPosition(0);
		this.srTween1.setPosition(0);
		this.scTween2.setPosition(0);
		this.srTween2.setPosition(0);
		this.scTween3.setPosition(0);
		this.srTween3.setPosition(0);

		this.playerTopContainer.removeChildren();
		this.playerBottomContainer.removeChildren();
		this.prevPos.x = this.fish.x;
		this.prevPos.y = this.fish.y;
	}

	public hide() {
		if (this.tickAdded) {
			Ticker.shared.remove(this.tick);
			this.tickAdded = false;
		}
		this.white.alpha = 0;
		this.gotoAndStop(0);
		this.tween1.setPosition(0);
		this.ttTween1.setPosition(0);
		this.scTween1.setPosition(0);
		this.srTween1.setPosition(0);
		this.scTween2.setPosition(0);
		this.srTween2.setPosition(0);
		this.scTween3.setPosition(0);
		this.srTween3.setPosition(0);

	}

	public resize(w: number, h: number) {

		const oh = 1080;
		const ow = 1500;
		let scale = h / oh;
		if (ow * scale > w) {
			scale = w / ow;
		}
		this.scale.set(scale);
		this.x = w / 2;
		this.y = h / 2;
	}


	protected _flash() {
		Tween.get(this.white).to({ alpha: 0.5 }, 0).to({ alpha: 0 }, 200);
		new SoundEfx('unlockStar').play();
	}


	protected tick = () => {
		// console.log(this.elapsedTime);
		const time = this.elapsedTime * 1000;
		this.tween1.setPosition(time);
		this.ttTween1.setPosition(time);
		this.scTween1.setPosition(time);
		this.srTween1.setPosition(time);
		if (this.tier >= 2) {
			this.scTween2.setPosition(time);
			this.srTween2.setPosition(time);
		}
		if (this.tier >= 3) {
			this.scTween3.setPosition(time);
			this.srTween3.setPosition(time);
		}

		if (time >= 8300) {
			globalx.showUnlock(0);
		} else if (time > burstTime && time < burstTime + 200) {

			for (let i = 0; i < 4; i++) {
				const star = UnlockAnimationStar.get();
				star.init();
				this.playerBottomContainer.addChild(star.symbol);
			}
		}

		const { fish, prevPos } = this;
		if (fish.x > -1000 && fish.x < 1000 && fish.y > -1000 && fish.y < 1000) {
			const speed = Rotate.dist(prevPos.x, prevPos.y, fish.x, fish.y) / this.fish.scale.x;
			if (speed > 2) {

				const angle = Rotate.radian(prevPos.x, prevPos.y, fish.x, fish.y);
				// console.log(speed / this.fish.scale.x);
				for (let i = 0; i < 3; i++) {
					const bubble = UnlockAnimationBubble.get();
					bubble.init(fish.x, fish.y, speed, angle, fish.scale.x);
					this.playerTopContainer.addChild(bubble.symbol);
				}
				for (let i = 0; i < 12; i++) {
					const bubble = UnlockAnimationBubble.get();
					bubble.init(fish.x, fish.y, speed, angle, fish.scale.x);
					this.playerBottomContainer.addChild(bubble.symbol);
				}
				// console.log(bubble.symbol.x, bubble.symbol.y);
			}
		}

		prevPos.x = fish.x;
		prevPos.y = fish.y;
	}
}
