import { RoomControl } from '@/client/controls/roomControl';
import { StababControl } from '@/client/controls/stababControl';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsBandage, MiscDataSsSacrifice } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Dictionary } from '@/util/dictionary';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { Particle } from '../../particles';
import { KrakenRunSymbol } from '../playerModel';
import { MiscSymbol } from './miscSymbol';

export class SsSacrificeSymbol extends Container implements MiscSymbol {
	public static get() {
		return SsSacrificeSymbol._pool.get();
	}

	private static _pool: Pool<SsSacrificeSymbol> = new Pool(SsSacrificeSymbol);

	public data: MiscData | null = null;

	public krakenRunSymbol = Factory.get(KrakenRunSymbol);

	public pid = NaN;
	public releasedParticles = false;

	public stababDic: Dictionary<StababSacrificeControl> = {};

	// public info = specialSkillInfos[SpecialSkill.Ration];

	constructor() {
		super();
	}

	public dispose(room: RoomControl) {
		this.data = null;
		this.disposeSacrificeControl(this.stababDic);
		this.stababDic = {};
		if (this.parent) {
			this.parent.removeChild(this);
		}
		SsSacrificeSymbol._pool.pool(this);
	}
	public disposeSacrificeControl(dic: Dictionary<StababSacrificeControl>) {
		for (const stababId in dic) {
			if (Object.prototype.hasOwnProperty.call(dic, stababId)) {
				const control = dic[stababId];
				control.next(1);
			}
		}
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsSacrifice;
		this.pid = data.pid;
		// if (!this.data || data.p.lvl !== this.data.p.lvl) {
		// 	this.info = data.p.lvl === 1 ? specialSkillInfos[SpecialSkill.Ration]
		// 		: specialSkillInfos[SpecialSkill.Ration2];
		// }
		const player = room.allPlayers[this.pid];
		if (!this.data) {
			new SoundEfx('sacrifice1').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
		}

		const percent = (rt - data.st) / data.ct;
		if (percent < 1) {

			this.releasedParticles = false;

			const stababDic: Dictionary<StababSacrificeControl> = {};
			if (player) {

				for (const stababId in player.stababs) {
					if (Object.prototype.hasOwnProperty.call(player.stababs, stababId)) {
						const stabab = player.stababs[stababId];

						const control = this.stababDic[stababId] || new StababSacrificeControl(stabab);
						stababDic[stababId] = control;
						delete this.stababDic[stababId];
						control.next(percent);
					}
				}
				this.disposeSacrificeControl(this.stababDic);
				this.stababDic = stababDic;

			}
		} else {
			if (player) {
				if (!this.releasedParticles) {
					this.releasedParticles = true;
					const dist = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
					new SoundEfx('sacrifice2').play({ volume: dist * .4 });

					if (dist > .5) {
						room.cameraControl.swing(10 * (dist - .4), 1000);
					}

					for (let i = 0; i < 10; i++) {
						player.healParticle(0, Particle.Circle);
					}
					this.addChild(this.krakenRunSymbol);
					this.krakenRunSymbol.scale.set(2);
					this.krakenRunSymbol.alpha = 1;
					Tween.get(this.krakenRunSymbol).to({ alpha: 0 }, 1000).call(() => {
						this.removeChild(this.krakenRunSymbol);
					});
					Tween.get(this.krakenRunSymbol.scale).to({ x: 5, y: 5 }, 1000);
				}
			}
			this.disposeSacrificeControl(this.stababDic);
			this.stababDic = {};
		}

		this.data = data;
		this.x = dt.x;
		this.y = dt.y;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		room.playerTopContainer.removeChild(this);

	}
}

const info = specialSkillInfos[SpecialSkill.Sacrifice];

export class StababSacrificeControl {
	public stabab: StababControl;
	public tween1: ManualTween;
	public tween2: ManualTween;
	constructor(stabab: StababControl) {
		this.stabab = stabab;
		const ogAngle = stabab.container.angle;
		this.tween1 = ManualTween.get(stabab.container).to({ angle: ogAngle - 100, brightness: 0.5 }, 0.2 * info.castTime, Ease.circOut).to({ angle: ogAngle + 720, brightness: 0 }, 0.8 * info.castTime, Ease.quintIn).to({ angle: ogAngle, brightness: 1 }, 0);
		this.tween2 = Tween.get(stabab.container.scale).to({ x: 1.1, y: 1.1 }, 0.2 * info.castTime, Ease.circOut).to({ x: 0.001, y: 0.001 }, 0.8 * info.castTime, Ease.quintIn).to({ x: .6, y: .6 }, 0);
	}

	public next(castPercent: number) {
		this.tween1.setPosition(castPercent * info.castTime);
		this.tween2.setPosition(castPercent * info.castTime);
	}
}
