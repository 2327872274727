import { Dictionary } from '@/util/dictionary';
import { StabfishAccountData, StabfishAccountInventory } from './firestoreFiles';
import { ItemCode, Rarity, itemInfos } from './itemInfos';
import { TimeLimitedQuestCode, isQuestTime } from './questTimeLimited';



const shopLimitedIds = [
	'boss1dif2',
	'boss1dif3',
	'boss1PirateBandana',
	'boss1PirateBicorne',
	'boss1EyePatch',
	'boss1Saber',
];
export type ShopLimitedIds = typeof shopLimitedIds[number];

export interface LimitedShopItem extends LimitedShopItemClient {
	condition(data: StabfishAccountData, inventory: StabfishAccountInventory): boolean,
}
export interface LimitedShopItemClient {
	itemCode: ItemCode,
	unit: number,
	limit: number,
	cost: number,
	costItem: ItemCode,
}

export const limitedShopItemsInfos: { [T in ShopLimitedIds]: LimitedShopItem } = {};

limitedShopItemsInfos.boss1dif2 = {
	itemCode: ItemCode.None,
	unit: 0,
	limit: 1,
	costItem: ItemCode.PirateToken,
	cost: 1000,
	condition: (data: StabfishAccountData, inventory: StabfishAccountInventory) => {
		return isQuestTime(TimeLimitedQuestCode.XmasPenguin);
	},
};
limitedShopItemsInfos.boss1PirateBandana = {
	itemCode: ItemCode.BpPirateBandana,
	unit: 1,
	limit: 10,
	costItem: ItemCode.PirateToken,
	cost: 5000,
	condition: (data: StabfishAccountData, inventory: StabfishAccountInventory) => {
		return (isQuestTime(TimeLimitedQuestCode.XmasPenguin) && inventory.shop.boss1dif2) ? true : false;
	},
};
limitedShopItemsInfos.boss1dif3 = {
	itemCode: ItemCode.None,
	unit: 0,
	limit: 1,
	costItem: ItemCode.PirateToken,
	cost: 2000,
	condition: (data: StabfishAccountData, inventory: StabfishAccountInventory) => {
		return (isQuestTime(TimeLimitedQuestCode.XmasPenguin) && inventory.shop.boss1dif2) ? true : false;
	},
};
limitedShopItemsInfos.boss1EyePatch = {
	itemCode: ItemCode.BpEyepatch,
	unit: 1,
	limit: 10,
	costItem: ItemCode.PirateToken,
	cost: 2000,
	condition: (data: StabfishAccountData, inventory: StabfishAccountInventory) => {
		return (isQuestTime(TimeLimitedQuestCode.XmasPenguin) && inventory.shop.boss1dif2) ? true : false;
	},
};
limitedShopItemsInfos.boss1PirateBicorne = {
	itemCode: ItemCode.BpPirateCaptainBicorne,
	unit: 1,
	limit: 10,
	costItem: ItemCode.PirateToken,
	cost: 10000,
	condition: (data: StabfishAccountData, inventory: StabfishAccountInventory) => {
		return (isQuestTime(TimeLimitedQuestCode.XmasPenguin) && inventory.shop.boss1dif3) ? true : false;
	},
};
limitedShopItemsInfos.boss1Saber = {
	itemCode: ItemCode.BpSaber,
	unit: 1,
	limit: 10,
	costItem: ItemCode.PirateToken,
	cost: 5000,
	condition: (data: StabfishAccountData, inventory: StabfishAccountInventory) => {
		return (isQuestTime(TimeLimitedQuestCode.XmasPenguin) && inventory.shop.boss1dif3) ? true : false;
	},
};
