





















import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CrazyAd } from '@/client/ads/crazyads';
import { AipAds } from '@/client/ads/aipads';
import gamex from '@/store/modules/gamex';
import { GameCommandType, IGameCommand } from '@/game/multithread/command';
@Component({
  components: {},
})
export default class IvHealModal extends Vue {
  public get titleHtml() {
    return `Healing Station`;
  }
  public get isInDojo() {
    return gamex.currentGameState.dojoId;
  }
  public videoAdReady = false;
  public tick = this._tick.bind(this);
  public interval: any = undefined;
  public timeLeft = '0m 0s';
  public isHealAvailable = false;

  public async onHeal() {
    const command: IGameCommand = {
      type: GameCommandType.AdHeal,
      sessionId: Global.clientCommunicator.sessionId,
    };
    Global.clientCommunicator.sendCommand(command);
    gamex.updateLastHeal(Date.now());
    this.isHealAvailable = false;

    Global.$root.$bvModal.hide('iv-modal');
    if (this.videoAdReady) {
      gamex.updateWatchingAds(true);
      if (Global.adsdk === 'crazyads') {
        await CrazyAd.instance.requestAd('rewarded');
      } else {
        await AipAds.instance.requestVideoAd('midgame');
      }
      gamex.updateWatchingAds(false);
    }
  }
  public async onDojoHeal() {
    const command: IGameCommand = {
      type: GameCommandType.DojoHeal,
      sessionId: Global.clientCommunicator.sessionId,
    };
    Global.clientCommunicator.sendCommand(command);
    Global.$root.$bvModal.hide('iv-modal');
  }
  public onShow() {
    gamex.updateIvModalShowing(true);
    this.tick();
    this.interval = setInterval(this.tick, 1000);
  }
  public onHide() {
    gamex.updateIvModalShowing(false);
    Global.stageControl.uiControl.closeIvHeal();
    clearInterval(this.interval);
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }

  protected _tick() {
    const adReady =
      Global.adsdk === 'crazyads'
        ? CrazyAd.instance.adReady.rewarded
        : AipAds.instance.adReady.midgame;
    this.videoAdReady = adReady;
    const availableTime = gamex.lastHeal + 3 * 60000;

    let tl = availableTime - Date.now();
    if (tl <= 0) {
      tl = 0;
      this.timeLeft = 'Now';
      this.isHealAvailable = true;
    } else {
      this.isHealAvailable = false;
      //   const day = Math.floor(tl / (24 * 60 * 60 * 1000));
      //   tl -= day * 24 * 60 * 60000;
      tl = Math.ceil(tl / 1000);
      const min = Math.floor(tl / 60);
      tl -= min * 60;
      const sec = tl;
      // tl -= min * 60000
      this.timeLeft = `in ${min}m ${sec}s`;
    }
  }
}
