var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chats px-1"},_vm._l((_vm.chatLogs),function(chat,index){return _c('div',{key:index,staticClass:"chat-post",class:{
			muted:
				chat.chatType === 0 &&
				_vm.users[chat.senderUid] &&
				_vm.users[chat.senderUid].muted,
			system: chat.chatType >= 1,
			chatsys: chat.chatType === 2,
			warning: chat.chatType === 3,
		}},[(chat.chatType !== 2)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"trunc-name",class:{ 'is-draco': chat.isDraco },attrs:{"title":chat.senderName + (chat.isDraco ? ' (creator)' : '')},on:{"click":function($event){return _vm.$emit('context', $event, chat.senderUid)}}},[_vm._v(_vm._s(chat.senderName))]):_vm._e(),(
				chat.chatType === 0 &&
					_vm.users[chat.senderUid] &&
					(_vm.users[chat.senderUid].mutedYou ||
						_vm.users[chat.senderUid].mutedAll)
			)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"badge badge-dark mx-1",attrs:{"title":_vm.users[chat.senderUid] && _vm.users[chat.senderUid].mutedAll
					? 'Muted All'
					: 'Muted You'}},[_c('i',{staticClass:"fa fa-sm fa-deaf"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.users[chat.senderUid] && _vm.users[chat.senderUid].mutedAll
				),expression:"\n\t\t\t\t\tusers[chat.senderUid] && users[chat.senderUid].mutedAll\n\t\t\t\t"}],staticClass:"fa fa-sm fa-deaf"})]):_vm._e(),(chat.chatType !== 2)?_c('span',[_vm._v(": ")]):_vm._e(),(chat.chatType >= 1)?_c('span',{staticClass:"msg",domProps:{"innerHTML":_vm._s(chat.msg)}}):_c('span',{staticClass:"msg"},[_vm._v(_vm._s(!_vm.users[chat.senderUid] || !_vm.users[chat.senderUid].muted ? chat.msg : 'muted.'))])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }