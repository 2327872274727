import globalx from '../modules/globalx';
import io from 'socket.io-client';
import { wait } from '@/util/wait';
import { ServerData } from '@/game/infos/serverInfos';
import { Dictionary } from '@/util/dictionary';

export class ServerList {
	public static async sync() {

		const lobbyServerAddress = globalx.lobbyServerAddress;
		let list: Dictionary<ServerData>;
		try {
			const response = await fetch(`${lobbyServerAddress}/serverList/${globalx.clientVersionString}?v=ae`);
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			list = json.list;
		} catch (error) {
			list = {};
		}

		if (process.env.NODE_ENV === 'development' ||
			location.hostname === 'test8845.stabfish2.io') {
			list['Home|Local|1|1'] = { id: 'Home|Local|1|1', name: 'Home|Local|1|1', url: 'http://192.168.1.100:9002', region: 'local', type: 'local', closeTime: 0, startTime: 1, armageddonStartTime: 0, vultrMaintenance: false };
		}
		globalx.updateServerList(list);
	}
	public static async ping(id: string, url: string, region: string) {
		const ct = Date.now();
		try {
			const fullUrl = `${url}/ping/${ct}`;
			const response = await fetch(fullUrl);
			if (!response.ok) {
				throw await response.json();
			}
			const nt = Date.now();
			const result = await response.json();
			const entry = performance.getEntriesByType('resource')
				.find((n) => n.name === fullUrl) as any;
			const ttfb = entry.responseStart - entry.requestStart;
			// console.log(region, fullUrl, ttfb, nt - ct);
			const ping = entry ?
				Math.round(ttfb) :
				nt - ct;
			globalx.updateServerStat({
				id,
				ping,
				isOffline: false,
				numPlayers: result.numPlayers,
				closeTime: result.closeTime,
				spf: result.spf,
			});
		} catch (error) {
			// globalx.updateServerStat({
			// 	id,
			// 	isOffline: true,
			// });
		}
	}
	public static async pingAll() {
		const servers = Object.values(globalx.serverList);
		performance.clearResourceTimings();
		for (const server of servers) {
			const { id, url, region } = server;
			this.ping(id, url, region);

			// const socket = io(url);
			// const connectionFailed = () => {
			// 	socket.disconnect();
			// 	globalx.updateServerStat({
			// 		id,
			// 		isOffline: true,
			// 	});
			// };
			// // socket.on('disconnect', connectionFailed);
			// socket.on('connect_failed', connectionFailed);
			// socket.on('connect_error', connectionFailed);
			// socket.once('connect', () => {
			// 	socket.emit('pt', Date.now());
			// });
			// socket.on('p2', async (sendTime: number, numPlayers = 0, spf = -1, closeTime = 0) => {
			// 	const ping = Date.now() - sendTime;
			// 	socket.off('connect_failed');
			// 	socket.off('connect_error');
			// 	socket.disconnect();
			// 	await wait(500);

			// 	globalx.updateServerStat({
			// 		id,
			// 		ping,
			// 		isOffline: false,
			// 		numPlayers,
			// 		closeTime,
			// 		spf,
			// 	});
			// });
			// socket.connect();
		}
	}
}

