import { Dictionary } from '@/util/dictionary';
import { FishType } from './fishInfos';

export enum BossRoomType {
	Test,
	Mechguin,
}

export interface ClientBossPlayerData {
	uid: string,
	name: string,
	readyTime: number,
	muted: boolean,
	mutedYou: boolean,
	fishType: FishType,
	skin: number,
	deco: string,
}
export interface BossLobbyData {
	id: string,
	host: string,

	roomOptions: BossOptions,
	participants: Dictionary<ClientBossPlayerData>,
	quits?: string[],

	state: BossLobbyState,
	hostClaimTime?: number,

	prevResult?: {
		timeUsed: number,
		rewards?: Dictionary<number>,
	};
}
export enum LobbyPrivacy {
	Public,
	Private,
	Team,
}
export interface BossOptions extends BossJoinOptions {
	difficulty: number,
	maxPlayers: number,
	privacy: LobbyPrivacy,
}
export interface BossJoinOptions {
	boss: BossRoomType,
	difficulty?: number,
}
export enum BossLobbyState {
	Creating,
	Transfering,
	Prestart,
	RoundStarted,
	Closing,
	Closed,
}

export interface BossLobbyEvent {
	type: BossLobbyEventType,
	data?: any,
}
export enum BossLobbyEventType {
	Join,
	Error,
	RejectClaim,
	MaintenanceClosing,
	Transfer,
}
export interface BossMapInfo {
	roomType: BossRoomType,
	name: string,
	img: string,
	desc: string,
}
export const tourneyMapInfos: BossMapInfo[] = [];
