export const levelRequirements: number[] = [
	10000, // 10000
	25000, // 35000
	30000, // 65000
	45000, // 110000
	50000, // 160000
	60000, // 220000
	60000, // 280000
	70000, // 350000
	80000, // 430000
	80000, // 510000
	90000, // 600000
	100000, // 700k
	100000, // 800k
	100000, // 900k
	100000, // 1m
	100000, // 1.1m
	100000, // 1.2
	100000, // 1.3
	100000, // 1.4
	100000, // 1.5

	100000, // 1.6
	100000, // 1.7
	100000, // 1.8
	100000, // 1.9
	100000, // 2.0
	100000, // 2.1

	100000, // 2.2
	100000, // 2.3
	100000, // 2.4
	100000, // 2.5
	100000, // 2.6
	100000, // 2.7

	100000, // 2.8
	100000, // 2.9
	100000, // 3

	150000, // 3.15
	150000, // 3.3
	150000, // 3.45

	150000, // 3.6
	200000, // 3.8
	200000, // 4

	200000, // 4.2
	200000, // 4.4
	250000, // 4.65

	250000, // 4.9
	250000, // 5.15
	250000, // 5.4

	300000, // 5.7
	300000, // 6
	300000, // 6.3

	350000, // 6.65
	350000, // 7
	400000, // 7.4

	450000, // 7.85
	550000, // 8.4
	600000, // 9
];

let exp = 0;
export const expRequirements: number[] = [0];
for (let i = 0; i < levelRequirements.length; i++) {
	exp += levelRequirements[i];
	expRequirements[i + 1] = exp;
}

export const maxExp = exp;
