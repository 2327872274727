import { BroadcastState } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import settingx from '@/store/modules/settingx';
import { arrayRemoveOne } from '@/util/array';
import { Point, Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container } from 'pixi.js';
import { RoomControl } from './roomControl';

export class CameraControl {
	public roomControl = new RoomControl(this);
	public symbol = new Container();

	protected center: Point = { x: 0, y: 0 };
	protected swings: Array<{ r: number; }> = [];
	protected viewportScale = 1;
	protected sightRadiusScale = 1;
	protected previousSightRadius = 1;

	constructor() {
		this.symbol.addChild(this.roomControl.symbol);
	}


	public update(state: ViewState) {
		this.roomControl.update(state);

		// do camera shake;
		this.updatePostion();

		// do camera zoom;
		if (this.previousSightRadius !== state.sightRadius) {
			const sightRadius = this.previousSightRadius + (state.sightRadius - this.previousSightRadius) / 5;
			this.sightRadiusScale = 1300 / sightRadius;
			this.symbol.scale.set(this.viewportScale * this.sightRadiusScale);
			this.previousSightRadius = sightRadius;
		}
		// this.symbol.scale.set(1);

	}


	public updatePostion() {
		this.symbol.x = this.center.x;
		this.symbol.y = this.center.y;
		this.symbol.angle = 0;
		for (const shake of this.swings) {
			this.symbol.angle += shake.r;
		}

		// this.symbol.scale.set(0.5);
	}
	public swing(amplitude: number, duration: number, left = true) {
		if (settingx.now.cameraShakeOff) {
			return;
		}
		const frequency = Math.floor(duration / 66);
		const shaker = { r: 0 };
		const tween = Tween.get(shaker);
		let amp = amplitude;
		let d = left ? 1 : -1;
		let time = 0;
		const freq = frequency - 1;
		for (let i = 0; i < frequency; i++) {
			const dur = Ease.cubicIn(i / frequency) * duration;
			const gap = dur - time;
			tween.to({ r: amp * d }, gap);
			time = dur;
			const n = freq - i;
			amp = amplitude * Ease.cubicIn(n / freq);
			d *= -1;
		}
		tween.call(() => { arrayRemoveOne(this.swings, shaker); });
		this.swings.push(shaker);
	}

	public setSize(width: number, height: number) {
		const viewport = Math.min(width, height);
		const size = Math.max((viewport - 450) / 200, 0) * 200 + 1000;
		this.viewportScale = viewport / Math.min(size, 1200);
		this.symbol.scale.set(this.viewportScale * this.sightRadiusScale);

		this.center.x = width / 2;
		this.center.y = height / 2;
		this.updatePostion();
	}
}
