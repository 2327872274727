export class Ease {
	/**
	 * Identical to linear.
	 * @method none
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static none = Ease.linear;

	/**
	 * @method quadIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quadIn = Ease.getPowIn(2);
	/**
	 * @method quadOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quadOut = Ease.getPowOut(2);
	/**
	 * @method quadInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quadInOut = Ease.getPowInOut(2);

	/**
	 * @method cubicIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static cubicIn = Ease.getPowIn(3);
	/**
	 * @method cubicOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static cubicOut = Ease.getPowOut(3);
	/**
	 * @method cubicInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static cubicInOut = Ease.getPowInOut(3);

	/**
	 * @method quartIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quartIn = Ease.getPowIn(4);
	/**
	 * @method quartOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quartOut = Ease.getPowOut(4);
	/**
	 * @method quartInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quartInOut = Ease.getPowInOut(4);

	/**
	 * @method quintIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quintIn = Ease.getPowIn(5);
	/**
	 * @method quintOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quintOut = Ease.getPowOut(5);
	/**
	 * @method quintInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static quintInOut = Ease.getPowInOut(5);
	/**
	 * @method backIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static backIn = Ease.getBackIn(1.7);
	/**
	 * @method backOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static backOut = Ease.getBackOut(1.7);
	/**
	 * @method backInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static backInOut = Ease.getBackInOut(1.7);
	/**
	 * @method elasticIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static elasticIn = Ease.getElasticIn(1, 0.3);
	/**
	 * @method elasticOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static elasticOut = Ease.getElasticOut(1, 0.3);
	/**
	 * @method elasticInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static elasticInOut = Ease.getElasticInOut(1, 0.3 * 1.5);

	// static methods and properties
	/**
	 * @method linear
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static linear(t: number) { return t; }
	/**
	 * Mimics the simple -100 to 100 easing in Adobe Flash/Animate.
	 * @method get
	 * @param {Number} amount A value from -1 (ease in) to 1 (ease out) indicating the strength and direction of the ease.
	 * @static
	 * @return {Function}
	 */
	public static get(amount: number) {
		if (amount < -1) { amount = -1; } else if (amount > 1) { amount = 1; }
		return function(t: number) {
			if (amount === 0) { return t; }
			if (amount < 0) { return t * (t * -amount + 1 + amount); }
			return t * ((2 - t) * amount + (1 - amount));
		};
	}
	/**
	 * Configurable exponential ease.
	 * @method getPowIn
	 * @param {Number} pow The exponent to use (ex. 3 would return a cubic ease).
	 * @static
	 * @return {Function}
	 */
	public static getPowIn(pow: number) {
		return function(t: number) {
			return Math.pow(t, pow);
		};
	}
	/**
	 * Configurable exponential ease.
	 * @method getPowOut
	 * @param {Number} pow The exponent to use (ex. 3 would return a cubic ease).
	 * @static
	 * @return {Function}
	 */
	public static getPowOut(pow: number) {
		return function(t: number) {
			return 1 - Math.pow(1 - t, pow);
		};
	}
	/**
	 * Configurable exponential ease.
	 * @method getPowInOut
	 * @param {Number} pow The exponent to use (ex. 3 would return a cubic ease).
	 * @static
	 * @return {Function}
	 */
	public static getPowInOut(pow: number) {
		return function(t: number) {
			t *= 2;
			if (t < 1) { return 0.5 * Math.pow(t, pow); }
			return 1 - 0.5 * Math.abs(Math.pow(2 - t, pow));
		};
	}
	/**
	 * @method sineIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static sineIn(t: number) {
		return 1 - Math.cos(t * Math.PI / 2);
	}
	/**
	 * @method sineOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static sineOut(t: number) {
		return Math.sin(t * Math.PI / 2);
	}
	/**
	 * @method sineInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static sineInOut(t: number) {
		return -0.5 * (Math.cos(Math.PI * t) - 1);
	}
	/**
	 * Configurable "back in" ease.
	 * @method getBackIn
	 * @param {Number} amount The strength of the ease.
	 * @static
	 * @return {Function}
	 */
	public static getBackIn(amount: number) {
		return function(t: number) {
			return t * t * ((amount + 1) * t - amount);
		};
	}
	/**
	 * Configurable "back out" ease.
	 * @method getBackOut
	 * @param {Number} amount The strength of the ease.
	 * @static
	 * @return {Function}
	 */
	public static getBackOut(amount: number) {
		return function(t: number) {
			return (--t * t * ((amount + 1) * t + amount) + 1);
		};
	}
	/**
	 * Configurable "back in out" ease.
	 * @method getBackInOut
	 * @param {Number} amount The strength of the ease.
	 * @static
	 * @return {Function}
	 */
	public static getBackInOut(amount: number) {
		amount *= 1.525;
		return function(t: number) {
			t *= 2;
			if (t < 1) { return 0.5 * (t * t * ((amount + 1) * t - amount)); }
			return 0.5 * ((t -= 2) * t * ((amount + 1) * t + amount) + 2);
		};
	}
	/**
	 * @method circIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static circIn(t: number) {
		return -(Math.sqrt(1 - t * t) - 1);
	}
	/**
	 * @method circOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static circOut(t: number) {
		return Math.sqrt(1 - (--t) * t);
	}
	/**
	 * @method circInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static circInOut(t: number) {
		t *= 2;
		if (t < 1) { return -0.5 * (Math.sqrt(1 - t * t) - 1); }
		return 0.5 * (Math.sqrt(1 - (t -= 2) * t) + 1);
	}
	/**
	 * @method bounceIn
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static bounceIn(t: number) {
		return 1 - Ease.bounceOut(1 - t);
	}
	/**
	 * @method bounceOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static bounceOut(t: number) {
		if (t < 1 / 2.75) {
			return (7.5625 * t * t);
		} else if (t < 2 / 2.75) {
			return (7.5625 * (t -= 1.5 / 2.75) * t + 0.75);
		} else if (t < 2.5 / 2.75) {
			return (7.5625 * (t -= 2.25 / 2.75) * t + 0.9375);
		} else {
			return (7.5625 * (t -= 2.625 / 2.75) * t + 0.984375);
		}
	}
	/**
	 * @method bounceInOut
	 * @param {Number} t
	 * @static
	 * @return {Number}
	 */
	public static bounceInOut(t: number) {
		if (t < 0.5) { return Ease.bounceIn(t * 2) * .5; }
		return Ease.bounceOut(t * 2 - 1) * 0.5 + 0.5;
	}
	/**
	 * Configurable elastic ease.
	 * @method getElasticIn
	 * @param {Number} amplitude
	 * @param {Number} period
	 * @static
	 * @return {Function}
	 */
	public static getElasticIn(amplitude: number, period: number) {
		const pi2 = Math.PI * 2;
		return function(t: number) {
			if (t === 0 || t === 1) { return t; }
			const s = period / pi2 * Math.asin(1 / amplitude);
			return -(amplitude * Math.pow(2, 10 * (t -= 1)) * Math.sin((t - s) * pi2 / period));
		};
	}
	/**
	 * Configurable elastic ease.
	 * @method getElasticOut
	 * @param {Number} amplitude
	 * @param {Number} period
	 * @static
	 * @return {Function}
	 */
	public static getElasticOut(amplitude: number, period: number) {
		const pi2 = Math.PI * 2;
		return function(t: number) {
			if (t === 0 || t === 1) { return t; }
			const s = period / pi2 * Math.asin(1 / amplitude);
			return (amplitude * Math.pow(2, -10 * t) * Math.sin((t - s) * pi2 / period) + 1);
		};
	}
	/**
	 * Configurable elastic ease.
	 * @method getElasticInOut
	 * @param {Number} amplitude
	 * @param {Number} period
	 * @static
	 * @return {Function}
	 */
	public static getElasticInOut(amplitude: number, period: number) {
		const pi2 = Math.PI * 2;
		return function(t: number) {
			const s = period / pi2 * Math.asin(1 / amplitude);
			t *= 2;
			if (t < 1) { return -0.5 * (amplitude * Math.pow(2, 10 * (t -= 1)) * Math.sin((t - s) * pi2 / period)); }
			return amplitude * Math.pow(2, -10 * (t -= 1)) * Math.sin((t - s) * pi2 / period) * 0.5 + 1;
		};
	}
	/**
	 * The Ease class provides a collection of easing functions for use with TweenJS. It does not use the standard 4 param
	 * easing signature. Instead it uses a single param which indicates the current linear ratio (0 to 1) of the tween.
	 *
	 * Most methods on Ease can be passed directly as easing functions:
	 *
	 *      createjs.Tween.get(target).to({x:100}, 500, createjs.Ease.linear);
	 *
	 * However, methods beginning with "get" will return an easing function based on parameter values:
	 *
	 *      createjs.Tween.get(target).to({y:200}, 500, createjs.Ease.getPowIn(2.2));
	 *
	 * Please see the <a href="http://www.createjs.com/Demos/TweenJS/Tween_SparkTable">spark table demo</a> for an
	 * overview of the different ease types on <a href="http://tweenjs.com">TweenJS.com</a>.
	 *
	 * <em>Equations derived from work by Robert Penner.</em>
	 * @class Ease
	 * @static
	 */
	constructor() {
		throw new Error('Ease cannot be instantiated.');
	}
}
