






















































































































import FishCanvasComponent from '@/components/canvas/FishCanvasComponent.vue';
import { getChallengeProgress } from '@/game/infos/firestoreFiles';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { fishUnlockRequirements } from '@/game/infos/fishUnlockRequirements';
import { UnlockChallenge } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { floorToFixed, roundToFixed } from '@/util/number';
import uuid from 'uuid';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishChallengeComponent from '../mainMenu/tools/fishSelector/FishChallengeComponent.vue';
import { FishIntelData } from '../mainMenu/tools/fishSelector/FishIntelTooltip.vue';
import StateButton from '@/components/inputs/StateButton.vue';
import { EditState } from '@/store/models.def';

@Component({
  components: {
    FishCanvasComponent,
    FishChallengeComponent,
    StateButton,
  },
})
export default class ChallengeProgressComponent extends Vue {
  @Prop({ default: 1 }) public fishType!: FishType;
  @Prop({ default: '' }) public container!: string;
  @Prop({ default: () => [] }) public progresses!: string[];

  public unlockState: EditState = 'active';
  public get unlockLabel() {
    const labels = {
      active: 'Unlock Now',
      sending: 'Unlocking',
      done: 'Unlocked',
    };
    return labels[this.unlockState];
  }

  public canvasId = uuid.v4();

  public hasToDo = false;

  public get challengesFromProgresses() {
    this.hasToDo = false;
    if (!fishUnlockRequirements[this.fishType]) {
      return [];
    }
    const progresses = userx.userDoc.unlockProgress;
    const results: UnlockChallenge[] = [];
    for (const challengeId of this.progresses) {
      const splits = challengeId.split('-');
      if (splits[0] !== 'F') {
        continue;
      }
      //   if(Number(splits[1]) !== this.fishType) continue;
      const a = Number(splits[2]);
      const b = Number(splits[3]);
      if (
        fishUnlockRequirements[this.fishType] &&
        fishUnlockRequirements[this.fishType][a] &&
        fishUnlockRequirements[this.fishType][a][b]
      ) {
        const p = getChallengeProgress(progresses, this.fishType, a, b);
        if (!p.completed) {
          this.hasToDo = true;
          results.push(fishUnlockRequirements[this.fishType][a][b]);
        } else {
          results.unshift(fishUnlockRequirements[this.fishType][a][b]);
        }
      }
    }

    return results;
  }

  public showFishIntel() {
    const data: FishIntelData = {
      targetId: this.canvasId,
      placement: 'left',
      container: this.container,
      fishType: this.fishType,
    };
    this.$root.$emit('showFishIntel', data);
  }
  public hideFishIntel() {
    this.$root.$emit('hideFishIntel');
  }

  public get oldUnlockProgress() {
    return floorToFixed(userx.oldFishUnlockProgress[this.fishType] * 100, 1);
  }
  public get unlockProgress() {
    return floorToFixed(userx.fishUnlockProgress[this.fishType] * 100, 1);
  }
  public get fishName() {
    return fishInfos[this.fishType].name;
  }
  public get tier() {
    return fishInfos[this.fishType].tier;
  }
  public get readyToUnlock() {
    return userx.pendingFishUnlocks[this.fishType];
  }
  public get nextChallenge() {
    return this.hasToDo || this.readyToUnlock
      ? null
      : userx.fishCurrentUnlockChallenges[this.fishType] || null;
  }

  public get newBarStyle() {
    return {
      width: `${this.unlockProgress}%`,
      borderRadius: this.unlockProgress < 98 ? '10px 0px 0px 10px' : '10px',
    };
  }
  public get oldBarStyle() {
    return {
      width: `${this.oldUnlockProgress}%`,
      borderRadius:
        this.unlockProgress >= 98 && this.oldUnlockProgress > 96
          ? '10px'
          : '10px 0px 0px 10px',
    };
  }

  public async onUnlock() {
    this.unlockState = 'sending';
    try {
      await userx.unlockFish(this.fishType);
      this.unlockState = 'done';
    } catch (error) {
      this.unlockState = 'active';
      this.$root.$emit('error', (error as any).message);
    }
  }

  public onViewAll() {
    this.$emit('view', this.fishType);
  }
}
