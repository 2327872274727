





























































import { DecoData, DecoType } from '@/game/infos/decorativeInfos';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class DecoProperties extends Vue {
	@Prop({ default: true }) public disabled!: boolean;
	@Prop() public selectedDeco!: DecoData;
	public scale = 0.5;
	public angle = 0;
	public flipX = false;
	public flipY = false;

	@Watch('selectedDeco', { immediate: true, deep: true })
	public decoChanged(selectedDeco: DecoData | null) {
		if (selectedDeco) {
			this.scale = selectedDeco.preferedScale!;
			this.angle = selectedDeco.angle;
			this.flipX = selectedDeco.flipX;
			this.flipY = selectedDeco.flipY;
		}
	}

	public onFlipX() {
		this.flipX = !this.flipX;
		this.onPropertiesChanged();
	}
	public onFlipY() {
		this.flipY = !this.flipY;
		this.onPropertiesChanged();
	}

	public onPropertiesChanged() {
		const { scale, angle, flipX, flipY } = this;
		const submission: DecoData = {
			...this.selectedDeco,
			preferedScale: scale,
			angle,
			flipX,
			flipY,
		};
		this.$emit('propUpdate', submission);
	}

	public async onBack() {
		this.$emit('back');
	}
}
