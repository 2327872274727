





















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class IconButton extends Vue {
  public baseUrl = process.env.BASE_URL;
  @Prop({ required: true }) public imgUrl!: string;
  @Prop({ default: '' }) public label!: string;
  @Prop({ default: false }) public pinkIndicator!: boolean;
  @Prop({ default: false }) public yellowIndicator!: boolean;

  public get hasIndicator() {
    return this.pinkIndicator || this.yellowIndicator;
  }
}
