import { BuffData } from '@/store/modules/gamex';
import { Container } from 'pixi.js';
import { generateBuffSkillIconData, SkillIcon } from '../skillIcon';

const iconW = 20;
const numPerLine = 8;
const scale = 0.5;

export class BuffIconsContainer extends Container {

	public update(buffs: BuffData[]) {
		for (const child of this.children) {
			SkillIcon.pool(child as SkillIcon);
		}
		this.removeChildren();
		for (let i = 0; i < buffs.length; i++) {
			const buff = buffs[i];
			const xx = i * iconW;
			const symbol = SkillIcon.get(generateBuffSkillIconData(buff.type, buff.lvl));
			this.addChild(symbol);

			// TO DO: next line if more tha numPerLine
			symbol.x = xx;
			symbol.y = 0;
			symbol.scale.set(scale);
		}
	}
}
