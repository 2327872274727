import { EmojiContainer } from '@/client/controls/utility/emojiContainer';
import { MiniMap } from '@/client/controls/utility/miniMap';
import { Global } from '@/store/globalz';
import { getScreenWidth } from '@/util/getScreenWidth';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, ParticleContainer } from 'pixi.js';
import { getParticle, Particle } from '../../particles';
import { BuffIconsContainer } from './BuffIconsContainer';
import { DpadSymbol } from './DpadMixin';
import { ShadowedProgressBar } from './ShadowedProgressBar';
import { SkillButtonSymbol } from './SkillButtonMixin';

export class UiControlSymbol extends MovieClip {

	public skill1!: SkillButtonSymbol;
	public skill2!: SkillButtonSymbol;
	public skill3!: SkillButtonSymbol;
	public dpad!: DpadSymbol;
	public touchable: Graphics = new Graphics();
	public blackout: Graphics = new Graphics();
	public pointer = getParticle(Particle.Pointer);

	public bar = new ShadowedProgressBar();

	public utilityContainer = new Container();
	public locatorContainer = new Container();
	public trackerContainer = new Container();
	public buffIconsContainer = new BuffIconsContainer();

	public bloodParticleContainer = new ParticleContainer(10, { vertices: false, position: true, rotation: false, uvs: false, tint: true });

	public minimap = new MiniMap();
	public emojiContainer = new EmojiContainer();

	public screenScale = Math.min(Math.max(document.documentElement.clientWidth / getScreenWidth(), 1), 2);

	public init() {
		this.addChildAt(this.pointer, 0);
		this.addChildAt(this.buffIconsContainer, 0);
		this.addChildAt(this.bar, 0);
		this.addChildAt(this.bloodParticleContainer, 0);
		this.addChildAt(this.trackerContainer, 0);
		this.addChildAt(this.blackout, 0);
		this.addChild(this.touchable);
		this.addChild(this.utilityContainer);

		this.bar.setBarWidth(170);

		this.utilityContainer.addChild(this.minimap);
		this.utilityContainer.addChild(this.emojiContainer);

		this.pointer.visible = false;
		this.pointer.alpha = 0.75;
		this.pointer.scale.set(0.8);
		this.touchable.interactive = true;
	}

	public setSize(w: number, h: number, rightHanded: boolean) {
		this.touchable.clear();
		this.touchable.beginFill(0, 0.001);
		this.touchable.drawRect(0, 0, w, h);
		this.blackout.clear();
		this.blackout.beginFill(0, 1);
		this.blackout.drawRect(0, 0, w, h);
		this.blackout.alpha = 0;


		const screenScale = this.screenScale;

		this.dpad.x = rightHanded ? w - 65 * screenScale : 65 * screenScale;
		this.dpad.y = h - 65 * screenScale;
		this.dpad.scale.set(screenScale);

		this.skill1.scale.set(screenScale);
		this.skill2.scale.set(screenScale);
		this.skill3.scale.set(screenScale);

		const h2 = rightHanded ? h : h - 250;
		const y2 = rightHanded ? 0 : 250;

		this.skill1.x = this.skill2.x = this.skill3.x = rightHanded ? 40 * screenScale : w - 40 * screenScale;
		this.skill1.y = y2 + h2 / 2;
		this.skill2.y = y2 + h2 / 2 + 60 * screenScale;
		this.skill3.y = y2 + h2 / 2 - 60 * screenScale;

		// for overlay to update
		this.skill1.emit('update');
		this.skill2.emit('update');
		this.skill3.emit('update');

		// if (w < 500) {
		// 	this.utilityContainer.x = (w - 300 * .75) / 2;
		// 	this.utilityContainer.y = 100;
		// 	this.utilityContainer.scale.set(0.75);
		// } else {
		this.utilityContainer.x = w - 310;
		this.utilityContainer.y = 60;
		this.utilityContainer.scale.set(1);
		// }

		this.bar.x = w / 2 + (rightHanded ? -20 : 20);
		this.bar.y = h - 40;
		this.bloodParticleContainer.y = this.bar.y;
		this.bloodParticleContainer.x = this.bar.x - 170 / 2;
		this.buffIconsContainer.y = h - 70;
		this.buffIconsContainer.x = w / 2 - 20 - 170 / 2 + 7;
	}
}
