import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataBiSnowBall, MiscDataSsSunPunch } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../../..';
import { MiscSymbol } from '../../skills/miscSymbol';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';
import { SsSunPunchSymbol } from '../../skills/ssSunPunchSymbol';

export class BiMechguinSnowBallSymbol extends Container {

}
export class BiSnowBallController implements MiscSymbol {

	public static get() {
		return BiSnowBallController._pool.get();
	}

	private static _pool: Pool<BiSnowBallController> = new Pool(BiSnowBallController);


	public data: MiscData | null = null;
	public pt = 0;

	public sound = new LongSoundEfx('sunPunch');
	public symbol = Factory.get(BiMechguinSnowBallSymbol);

	public dispose(room: RoomControl) {
		this.data = null;
		Tween.get(this.symbol).to({ alpha: 0 }, 300).call(() => {
			if (this.symbol.parent) {
				this.symbol.parent.removeChild(this.symbol);
			}
			BiSnowBallController._pool.pool(this);
		});
		Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
			this.sound.stop();
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataBiSnowBall;

		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.symbol.scale.set(data.sc);


		if (!this.data) {
			// this.scale.set(0.01);
			this.symbol.alpha = 1;
			this.symbol.angle = 10;
		} else {
			this.symbol.angle -= 0.05;
		}
		this.data = data;

		const volume = (2000 - Rotate.dist(data.x, data.y, state.x, state.y)) / 2000;
		this.sound.volume = volume;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this.symbol);
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		this.sound.volume = 0;
	}
}
