



































import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { BTooltip } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  generateSpecialSkillIconData,
  generateGeneSkillIconData,
  generateNormalSkillIconData,
  generatePassiveSkillIconData,
  generateRGeneSkillIconData,
} from '@/client/factory/assets/skillIcon';
import SkillIconCanvasComponent from '@/components/canvas/SkillIconCanvasComponent.vue';
import FishSkillComponent from './FishSkillComponent.vue';
import { wait } from '@/util/wait';

export interface FishIntelData {
  targetId: string;
  placement: 'top' | 'left' | 'right' | 'bottom';
  container: any;
  fishType: FishType;
}
@Component({
  components: {
    SkillIconCanvasComponent,
    FishSkillComponent,
  },
})
export default class FishIntelToolTip extends Vue {

  public get fishInfo() {
    return fishInfos[this.fishType];
  }

  public get hasActiveSkill() {
    return this.fishInfo.skill1 || this.fishInfo.skill2;
  }
  public get hasPassiveSkill() {
    return this.fishInfo.passives.length > 0;
  }
  public get hasGeneSkill() {
    return this.fishInfo.genes.length > 0;
  }
  public get hasRGeneSkill() {
    return this.fishInfo.recessives.length > 0;
  }

  public get normalSkillIconData() {
    return generateNormalSkillIconData(this.fishInfo.skill1);
  }
  public get specialSkillIconData() {
    return generateSpecialSkillIconData(this.fishInfo.skill2);
  }
  public get passiveSkillIconData() {
    return this.fishInfo.passives.map((s) =>
      generatePassiveSkillIconData(s.type, s.lvl),
    );
  }
  public get geneSkillIconData() {
    return this.fishInfo.genes.map((s) =>
      generateGeneSkillIconData(s.type, s.lvl),
    );
  }
  public get recessSkillIconData() {
    return this.fishInfo.recessives.map((s) =>
      generateRGeneSkillIconData(s.type, s.lvl),
    );
  }
  public targetId = '';
  public placement = 'top';
  public container: any = null;
  public fishType: FishType = FishType.Random;
  public show = false;
  public showAdvanceSkill = true;
  protected localShow = false;

  @Watch('localShow', { immediate: true })
  public async showChanged() {
    const fish = this.fishType;
    if (this.localShow) {
      await wait(100);
      if (this.localShow && this.fishType === fish) {
        this.show = true;
      }
    } else {
      this.show = false;
    }
  }

  public mounted() {
    this.$root.$on('showFishIntel', this.showFishIntel);
    this.$root.$on('hideFishIntel', this.hideFishIntel);
  }

  public async showFishIntel(data: FishIntelData) {
    this.targetId = data.targetId;
    this.placement = data.placement;
    this.container = data.container;
    this.fishType = data.fishType;
    // this.$nextTick(() => {
    this.localShow = true;
    // });
  }
  public hideFishIntel() {
    this.localShow = false;
    this.targetId = '';
  }
}
