import { Container, Sprite, Text, Texture, TilingSprite } from 'pixi.js';
import Factory from '..';
import { ModeBanner } from './safeZoneMixin';
import { Preset } from '@/game/infos/preset';
import { Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import settingx from '@/store/modules/settingx';
import { wait } from '@/util/wait';
import { getWallSprite, MapAsset } from '../mapAssets';
import { tint } from '../helper';
import gamex from '@/store/modules/gamex';

export class TutorialPageBoost extends MovieClip {
	public text = new Text('', {
		fontFamily: 'Titan One',
		fontSize: 15,
		fill: 0xB6D6F3,
		align: 'center',
	});
	public mouse!: MovieClip;
	public key!: Container;
	public space!: MovieClip;
	public touch!: MovieClip;
	public init() {
		this.mouse = this.children[0] as MovieClip;
		this.key = this.children[1] as Container;
		this.space = this.children[2] as MovieClip;
		this.touch = this.children[3] as MovieClip;
		this.text.style.align = 'center';
		this.text.anchor.set(.5, .5);
		this.text.x = -65;
		this.text.y = -93;
		this.addChild(this.text);
	}
	public check() {
		this.gotoAndPlay(80);
		let skill: string = settingx.now.skill1;
		if (settingx.now.control === 'mouse') {
			this.touch.visible = false;
			if (skill === 'Left-Click') {
				this.mouse.visible = true;
				this.key.visible = false;
				this.space.visible = false;
				this.mouse.scale.set(1, 1);

			} else if (skill === 'Right-Click') {
				this.mouse.visible = true;
				this.key.visible = false;
				this.space.visible = false;
				this.mouse.scale.set(-1, 1);

			} else if (skill === 'Space') {
				this.mouse.visible = false;
				this.key.visible = false;
				this.space.visible = true;
				skill = 'Press ' + skill.toLocaleUpperCase();
			} else {
				this.mouse.visible = false;
				this.key.visible = true;
				this.space.visible = false;
				((this.key.children[0] as Container).children[0] as Text).text = skill;
				skill = 'Press ' + skill.toLocaleUpperCase();
			}
		} else {
			this.key.visible = false;
			this.mouse.visible = false;
			this.touch.visible = true;
			skill = 'Tap';
		}
		this.text.text = `${skill} & Hold`;
	}
}
export class TutorialPageRation extends MovieClip {
	public text = new Text('', {
		fontFamily: 'Titan One',
		fontSize: 15,
		fill: 0xB6D6F3,
		align: 'center',
	});
	public mouse!: MovieClip;
	public key!: Container;
	public space!: MovieClip;
	public touch!: MovieClip;
	public init() {
		this.mouse = this.children[0] as MovieClip;
		this.key = this.children[1] as Container;
		this.space = this.children[2] as MovieClip;
		this.touch = this.children[3] as MovieClip;
		this.text.style.align = 'center';
		this.text.anchor.set(.5, .5);
		this.text.x = -65;
		this.text.y = -93;
		this.addChild(this.text);
	}
	public check() {
		this.gotoAndPlay(0);
		let skill: string = settingx.now.skill2;
		if (settingx.now.control === 'mouse') {
			this.touch.visible = false;
			if (skill === 'Left-Click') {
				this.mouse.visible = true;
				this.key.visible = false;
				this.space.visible = false;
				this.mouse.scale.set(1, 1);

			} else if (skill === 'Right-Click') {
				this.mouse.visible = true;
				this.key.visible = false;
				this.space.visible = false;
				this.mouse.scale.set(-1, 1);

			} else if (skill === 'Space') {
				this.mouse.visible = false;
				this.key.visible = false;
				this.space.visible = true;
				skill = 'Press ' + skill.toLocaleUpperCase();
			} else {
				this.mouse.visible = false;
				this.key.visible = true;
				this.space.visible = false;
				((this.key.children[0] as Container).children[0] as Text).text = skill;
				skill = 'Press ' + skill.toLocaleUpperCase();
			}
		} else {
			this.key.visible = false;
			this.mouse.visible = false;
			this.touch.visible = true;
			skill = 'Tap';
		}
		this.text.text = `${skill}`;
	}
}
export class TutorialSymbol extends Container {
	public boost!: TutorialPageBoost;
	public ration!: TutorialPageRation;
	public welcome!: Container;
	public stab!: MovieClip;
	public kill!: MovieClip;
	public evolve!: MovieClip;
	public last!: MovieClip;
	public bye!: MovieClip;
	public symbol!: Container;
	public walls = new Container();
	public init() {
		this.removeChildren();
		this.symbol = this.welcome;
		// const wall = new TilingSprite(Texture.from('images/Tunnel_Wall.jpg'), 2048, 2048);
		const bg = Sprite.from('images/SecretTemple.jpg');
		this.addChild(this.walls, bg);
		bg.y = bg.x = -512;
		// wall.y = wall.x = -1024;

		const blockSize = 341;
		this.walls.addChild(getWallSprite(MapAsset.MainNodeB, -1024, -1024 + blockSize));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeR, -1024 + blockSize, -1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNode, -1024, -1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeT, 1024, 1024 + blockSize));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeL, 1024 - blockSize, 1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNode, 1024, 1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeB, 1024, -1024 + blockSize));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeL, 1024 - blockSize, -1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNode, 1024, -1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeT, -1024, 1024 - blockSize));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeR, -1024 + blockSize, 1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNode, -1024, 1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeT, 1024, 1024 - blockSize));
		this.walls.addChild(getWallSprite(MapAsset.MainNodeL, 1024 - blockSize, 1024));
		this.walls.addChild(getWallSprite(MapAsset.MainNode, 1024, 1024));
		this.walls.addChild(getWallSprite(MapAsset.TopNodeL, -blockSize, -1024));
		this.walls.addChild(getWallSprite(MapAsset.TopNodeR, blockSize, -1024));
		this.walls.addChild(getWallSprite(MapAsset.TopNode, 0, -1024));
		this.walls.addChild(getWallSprite(MapAsset.TopNodeL, -blockSize, 1024));
		this.walls.addChild(getWallSprite(MapAsset.TopNodeR, blockSize, 1024));
		this.walls.addChild(getWallSprite(MapAsset.TopNode, 0, 1024));
		this.walls.addChild(getWallSprite(MapAsset.LeftNodeT, -1024, -blockSize));
		this.walls.addChild(getWallSprite(MapAsset.LeftNodeB, -1024, blockSize));
		this.walls.addChild(getWallSprite(MapAsset.LeftNode, -1024, 0));
		this.walls.addChild(getWallSprite(MapAsset.LeftNodeT, 1024, -blockSize));
		this.walls.addChild(getWallSprite(MapAsset.LeftNodeT, 1024, blockSize));
		this.walls.addChild(getWallSprite(MapAsset.LeftNode, 1024, 0));
		tint(this.walls, 0x666666);
		this.walls.scale.set(.9);
	}

	public async setStage(stage: number) {
		if (stage === 1) {
			this.removeChild(this.symbol);
			await wait(1000);
			this.showSymbol(this.welcome, 1000);
		} else {
			Tween.get(this.symbol).to({ alpha: 0 }, 500).call(() => {
				this.removeChild(this.symbol);
				if (stage === 1) {
					this.showSymbol(this.welcome);
				} else if (stage === 2) {
					this.showSymbol(this.boost);
					this.boost.check();
				} else if (stage === 3) {
					this.showSymbol(this.ration);
					this.ration.check();
				} else if (stage === 4) {
					this.showSymbol(this.stab);
					this.stab.gotoAndPlay(0);
				} else if (stage === 5) {
					this.showSymbol(this.kill);
					this.stab.gotoAndPlay(0);
				} else if (stage === 6) {
					this.showSymbol(this.evolve);
					this.evolve.gotoAndPlay(0);
				} else if (stage === 7) {
					this.showSymbol(this.last);
					this.last.gotoAndPlay(0);
				} else if (stage === 8) {
					this.showSymbol(this.bye);
				}
			});
		}
	}
	public showSymbol(symbol: Container, fadeTime = 500) {
		this.symbol = symbol;
		this.addChild(symbol);
		symbol.alpha = 0;
		Tween.get(symbol).to({ alpha: 1 }, fadeTime);
	}
}

export class DojoSymbol extends Container {

	public dojo = new Container();
	public tutorial: TutorialSymbol | null = null;
	public currentStage = 0;
	constructor() {
		super();
		const dojoSize = Preset.DOJO_SIZE;
		const dojoSizeHalf = Preset.DOJO_SIZE / 2;
		const dojoBg = new TilingSprite(Texture.from('images/dojoBg.jpg'), dojoSize, dojoSize);
		dojoBg.y = dojoBg.x = -dojoSizeHalf;

		const armoryIv = Sprite.from('mainAssets_ArmoryIv');
		armoryIv.x = -486;
		armoryIv.y = 298 - 512 + dojoSizeHalf;

		const modeBanner = Factory.get(ModeBanner);

		modeBanner.x = 400;
		modeBanner.y = 517 - 512 + dojoSizeHalf;
		this.dojo.addChild(dojoBg, armoryIv, modeBanner);
		this.addChild(this.dojo);
		this.y = Preset.DOJO_Y;
	}
	public setTutorialStage(stage: number) {
		if (stage === this.currentStage) {
			return;
		}
		if (stage === 0) {
			this.addChild(this.dojo);
			this.y = Preset.DOJO_Y;
			if (this.tutorial) {
				this.removeChild(this.tutorial);
			}
		} else {
			if (gamex.currentUtility === 'Map') {
				gamex.updateCurrenUtility('None');
			}
			if (!this.tutorial) {
				this.tutorial = Factory.get(TutorialSymbol);
			}
			this.addChild(this.tutorial);
			this.removeChild(this.dojo);
			this.y = Preset.TUTORIAL_Y;
			this.tutorial.setStage(stage);
		}
		this.currentStage = stage;
	}
}
