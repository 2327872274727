






























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LogoutModal from '@/components/modals/LogoutModal.vue';
import LoginFailedModal from '@/components/modals/LoginFailedModal.vue';
import userx from './store/modules/userx';
import { SignLinkError } from './store/firestore';
import LoadingPage from './components/ui/LoadingPage.vue';
import { Global } from './store/globalz';
import FishIntelToolTip from './components/ui/mainMenu/tools/fishSelector/FishIntelTooltip.vue';
import globalx from './store/modules/globalx';
import gamex from './store/modules/gamex';
import MaintenancePage from './components/ui/MaintenancePage.vue';
import ItemIntelToolTip from './components/ui/mainMenu/bag/ItemIntelTooltip.vue';
import { Analytics } from '@/client/analytics';
import OverlayComponent from '@/components/ui/OverlayComponent.vue';
import ContextMenu from './components/ui/ContextMenu.vue';
import SkinIntelToolTip from './components/gacha/SkinIntelTooltip.vue';
import ConvertDuplicatesModal from './components/gacha/ConvertDuplicatesModal.vue';
import GachaListener from './components/gacha/GachaListener.vue';
import PoqLinkModal from './components/gacha/poq/PoqLinkModal.vue';
import ShopModal from './components/shop/ShopModal.vue';
import CnyShopModal from './components/shop/CnyShopModal.vue';
import ChallengeCompletedToast from './components/ui/ChallengeCompletedToast.vue';
import TipsToast from './components/ui/game/TipsToast.vue';
import ConvertAllDuplicatesModal from './components/gacha/ConvertAllDuplicatesModal.vue';
import { ServerTime } from './store/api/serverTime';

const sheet = document.createElement('style');
sheet.type = 'text/css';
(document.head || document.getElementsByTagName('head')[0]).appendChild(sheet);
function setStyle(cssText: string, node?: Text) {
  if (!node || node.parentNode !== sheet) {
    return sheet.appendChild(document.createTextNode(cssText));
  }
  node.nodeValue = cssText;
  return node;
}

const theCss = setStyle('.modal{transform:scale(1) !important;}');

@Component({
  components: {
    ShopModal,
    CnyShopModal,
    LogoutModal,
    LoginFailedModal,
    LoadingPage,
    FishIntelToolTip,
    MaintenancePage,
    ItemIntelToolTip,
    OverlayComponent,
    ContextMenu,
    SkinIntelToolTip,
    ConvertDuplicatesModal,
    ConvertAllDuplicatesModal,
    GachaListener,
    PoqLinkModal,
    ChallengeCompletedToast,
    TipsToast,
  },
})
export default class App extends Vue {
  public get hasMaintenance() {
    const result = globalx.hasMaintenance;
    if (result) {
      globalx.updateHadMaintenance(true);
    }
    return result;
  }
  public get showMaintenance() {
    return (this.hasMaintenance || globalx.hadMaintenance) && !gamex.belayMaintenance;
  }

  public async mounted() {
    this.$root.$on('error', this.showError);
    Global.$root = this.$root;
    Global.$bvModal = this.$bvModal;
    Global.partner = (this.$route.query.p as string) || '';
    Analytics.visit();

    // document.addEventListener(
    //   'touchmove',
    //   (event: any) => {
    //     this.debug = event.scale;
    //     if (event.scale !== 1) {
    //       event.preventDefault();
    //     }
    //   },
    //   {passive: false},
    // );
    let lastTouchEnd = 0;
    document.addEventListener(
      'touchend',
      (event) => {
        const now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      { passive: false },
    );

    this.resize();
    window.addEventListener('resize', this.resize.bind(this));

    setInterval(() => {
      globalx.updateTime(ServerTime.time);
    }, 1000);
  }

  public resize() {
    const appScale = Global.appScale;
    const width = (1 / appScale) * 100;
    const div = this.$el as HTMLDivElement;
    div.style.transformOrigin = 'top left';
    div.style.transform = `scale(${appScale})`;
    div.style.width = `${width}%`;
    div.style.height = `${width}%`;
    setStyle(
      `.modal{transform:scale(${appScale}) !important; width:${width}% !important; height:${width}% !important;}
       .b-toaster{transform:scale(${appScale}) !important; width:calc(${width}% - 1rem) !important;}
      `,
      theCss,
    );
  }

  public showError(error: any) {
    this.$bvModal.msgBoxOk(error.message || error, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Error`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
  }

  public get signLinkError() {
    return userx.signLinkError;
  }
  @Watch('signLinkError', { immediate: true })
  public signLinkErrorChanged(newVal: SignLinkError | null) {
    if (newVal) {
      this.$root.$emit('signLinkError', newVal);
    }
  }
}
