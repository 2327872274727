import { RoomControl } from '@/client/controls/roomControl';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { MiscData, MiscDataSsInkSac } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import Factory from '../..';
import { tint } from '../../helper';
import { MiscSymbol } from './miscSymbol';
import { PlayerMode } from '@/game/infos/enums';


export class SsInkSacSymbol extends MovieClip implements MiscSymbol {

	// public missileIndicator = getParticle(Particle.MissileIndicator);

	public data: MiscDataSsInkSac | null = null;
	public isSameTeam = false;

	public init() {
		this.gotoAndStop('idle');
		this.scale.set(0.01);
	}
	public dispose(room: RoomControl) {
		this.data = null;
		this.stop();
		if (this.parent) {
			Tween.get(this).to({ alpha: 0 }, 500).call(() => {
				if (this.parent) {
					this.parent.removeChild(this);
				}

				Factory.pool(SsInkSacSymbol, this);
			});
		} else {
			Factory.pool(SsInkSacSymbol, this);
		}

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		this.alpha = 1;

		const data = dt as MiscDataSsInkSac;

		this.scale.set(data.sc);
		this.alpha = 1;

		if (!this.data) {
			this.x = data.x;
			this.y = data.y;
			this.isSameTeam = data.tid === state.tid || state.playerMode === PlayerMode.PvE && data.tid === '_pve';
			tint(this, this.isSameTeam ? 0x193F88 : 0);
			if (data.p < 0.1) {

				// const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
				// const sfx = new SoundEfx('swimmingFangBoom');
				// sfx.play({ volume });
			}
		}
		this.data = data;

	}
	public show(room: RoomControl) {
		room.wallBottomContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}

}
