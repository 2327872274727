import { rotate, translate, applyToPoint, scale, Point, identity, Matrix, transform } from 'transformation-matrix';
import { Tween, TweenOptions } from './tweents';

export class AnimatingContainers {
	public parentData: Animatable = getEmptyAnimatable();
	public parent: any = null;
	public collectParentDataFunction: ((parent: any) => Animatable) | null = null;

	public containers: Animatable[] = [];

	public getMatrix() {

		const matrix = !this.parent ? identity() :
			toMatrix(
				this.collectParentDataFunction ? this.collectParentDataFunction(this.parent) :
					this.parent as Animatable);
		return this.containers.reduce((m: Matrix, a: Animatable) => transform(m, toMatrix(a)), matrix);
	}
	public getAnimatable() {
		return fromMatrix(this.getMatrix());
	}
}

export function toMatrix(a: Animatable) {
	return transform(
		translate(a.x, a.y),
		rotate(a.radian),
		scale(a.scaleX, a.scaleY),
	);
}
/**
 * Decomposes the matrix (x, y, scaleX, scaleY, and rotation) and sets the properties on to a transform.
 * @param transform - The transform to apply the properties to.
 * @returns The transform with the newly applied properties
 */
export function fromMatrix(matrix: Matrix): Animatable {
	// sort out rotation / skew..
	const a = matrix.a;
	const b = matrix.b;
	const c = matrix.c;
	const d = matrix.d;

	const radian = Math.atan2(b, a);
	// next set scale
	const scaleX = Math.sqrt((a * a) + (b * b));
	const scaleY = Math.sqrt((c * c) + (d * d));

	// next set position
	const x = matrix.e;
	const y = matrix.f;

	return { x, y, scaleX, scaleY, radian };

}

export interface Animatable {
	x: number,
	y: number,
	scaleX: number,
	scaleY: number,
	radian: number,
}

export function getEmptyAnimatable(): Animatable {
	return { x: 0, y: 0, scaleX: 1, scaleY: 1, radian: 0 };
}

export class ManualTween extends Tween {
	constructor(target: any, options: TweenOptions = {}) {
		options.autoStart = false;
		super(target, options);
	}
}
