import { MovieClip } from 'pixi-animate';
import { Container, Sprite, Text } from 'pixi.js';

export class PublicChest extends MovieClip {

	public init() {

	}

}
export class ClickFinger extends MovieClip {
}
export class TempleChest extends MovieClip {

	public init() {

	}

}
