import { BroadcastState, PlayerData, PlayerGameState, StababState } from '@/game/multithread/state';
import assetx from '@/store/modules/assetx';
import { Container, Graphics } from 'pixi.js';
import { CameraControl } from './cameraControl';
import { Preset } from '@/game/infos/preset';
import { PlayerType } from '@/game/infos/enums';
import { IClientCommunicator } from '../io/iclientCommunicator';
import { UiControl } from './uiControl';
import gamex from '@/store/modules/gamex';
import { PartialMapData } from '@/game/multithread/roomMap';
import { nonReact } from '@/util/nonReact';
import { ViewState } from '@/game/multithread/viewState';
import { GameType } from '@/game/infos/roomInfos';

export interface TeamMemberInfo {
	x: number,
	y: number,
	name: string,
	deg: number,
	teamIndex: number,
	isMe: boolean,
	isStabbed: boolean,
}

export class StageControl {
	public get sessionId(): string {
		return this._sessionId;
	}
	public set sessionId(value: string) {
		this._sessionId = value;
		this.uiControl.setSessionId(value);
	}
	public stage = assetx.app.stage;
	public width = 0;
	public height = 0;
	public symbol = nonReact(new Container());
	public cameraControl = nonReact(new CameraControl());
	public uiControl = nonReact(new UiControl(this));
	public communicator: IClientCommunicator | null = null;

	public graphics = new Graphics();

	public state: ViewState | null = null;

	private _sessionId: string = '';

	constructor(clientCommunicator: IClientCommunicator | null = null) {
		(window as any).assetx = assetx;
		(window as any).stageControl = this;
		this.symbol.addChild(this.cameraControl.symbol);
		this.symbol.addChild(this.graphics);
		this.symbol.addChild(this.uiControl.symbol);
		this.communicator = clientCommunicator;

		let lastActionCommand: string = '';
		let lastBleedoutCommand: string = '';
		setInterval(() => {
			if (this.communicator && this.state && this.sessionId) {
				if (this.state.state === PlayerGameState.Active || this.state.armageddonPlace === 1 || this.state.cpsPlace >= 0) {
					const command = JSON.stringify(this.uiControl.actionCommand);
					if (command !== lastActionCommand) {
						lastBleedoutCommand = '';
						lastActionCommand = command;
						this.communicator.sendCommand(this.uiControl.actionCommand);
					}
				} else if (this.state.state === PlayerGameState.Stabab) {
					const command = JSON.stringify(this.uiControl.bleedoutCommand);
					if (command !== lastBleedoutCommand) {
						lastActionCommand = '';
						lastBleedoutCommand = command;
						this.communicator.sendCommand(this.uiControl.bleedoutCommand);
					}
				} else {
					lastActionCommand = '';
					lastBleedoutCommand = '';
				}
			}
		}, 50);
		// this.update(state);
	}
	public buildBossMap() {
		this.cameraControl.roomControl.buildBossMap();
		this.uiControl.symbol.minimap.visible = false;
	}
	public build(mapData: PartialMapData) {
		this.cameraControl.roomControl.build(mapData);
		if (gamex.gameType === GameType.Armageddon) {
			this.uiControl.buildMiniMap(mapData);
		}
	}
	public purge() {
		this.cameraControl.roomControl.purge();
		this.uiControl.purge();
	}

	public update(state: ViewState) {
		// if (state.my.state === PlayerGameState.Active && gamex.gameStage !== 'Start') {
		// 	gamex.setGameStage('Start');
		// }
		Object.freeze(state);

		const teamMembers: TeamMemberInfo[] = [];
		const tid = state.tid;
		const team = state.team;
		const pid = state.pid;
		for (const p of state.ps) {
			const player = state.players[p.id];
			if (player.tid === tid) {
				const index = team.indexOf(player.id);
				if (index >= 0) {
					teamMembers[index] = {
						name: player.name,
						isMe: player.id === pid,
						x: player.x,
						y: player.y,
						deg: player.deg,
						teamIndex: index,
						isStabbed: player.stababId ? true : false,
					};
				}
			}
		}
		this.cameraControl.update(state);
		this.uiControl.update(state, teamMembers);
		this.state = state;

		gamex.updateCurrentGameState(state);
	}

	public setSize(width: number, height: number) {
		this.width = width;
		this.height = height;

		this.cameraControl.setSize(width, height);
		this.uiControl.setSize(width, height);
		// if (this.data) {
		// 	this.update(this.data);
		// }
		// this.graphics.clear();
		// this.graphics.lineStyle(1, 0xff0000);
		// this.graphics.drawRect(10, 10, width - 20, height - 20);
	}
}
