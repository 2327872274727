import { RoomControl } from '@/client/controls/roomControl';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsBandage, MiscDataSsRation, MiscDataSsDecoy } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import { rgbToInteger } from '../../helper';
import { disposeParticle, getParticle, Particle } from '../../particles';
import { getSpecialSprite, SpecialSkillAsset } from '../../skillAssets';
import { MiscSymbol } from './miscSymbol';
import { PlayerSymbol } from '../playerMixin';
import { MovieClip } from 'pixi-animate';

export class SsDecoySymbol implements MiscSymbol {
	public static get() {
		return SsDecoySymbol._pool.get();
	}

	private static _pool: Pool<SsDecoySymbol> = new Pool(SsDecoySymbol);

	public data: MiscDataSsDecoy | null = null;

	public pid = NaN;
	protected symbol: { tail: Container; } | null = null;
	// public info = specialSkillInfos[SpecialSkill.Ration];



	public dispose(room: RoomControl) {
		this.data = null;
		this.symbol = null;
		SsDecoySymbol._pool.pool(this);
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsDecoy;
		this.pid = data.pid;
		const player = room.allPlayers[this.pid];
		if (player) {
			let symbol: any;
			if (player.stababControl) {
				symbol = player.stababControl.fishSymbol.body.children[0];
			} else {
				symbol = player.symbol.body.children[0];
			}
			if (this.symbol !== symbol) {
				const resetTail = () => {
					symbol.off('reset', resetTail);
					symbol.tail.scale.set(1);
				};
				symbol.on('reset', resetTail);
				this.symbol = symbol;
			}
			if (data.cd === 1) {
				if (data.pid === state.pid && this.data && this.data.cd !== 1) {
					new SoundEfx('decoyReady').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
					Tween.get(this.symbol!.tail).wait(50).to({ alpha: 0.5 }, 0).wait(50).to({ alpha: 1 }, 0)
						.wait(50).to({ alpha: 0.5 }, 0).wait(50).to({ alpha: 1 }, 0)
						.wait(50).to({ alpha: 0.5 }, 0).wait(50).to({ alpha: 1 }, 0)
						.wait(50).to({ alpha: 0.5 }, 0).wait(50).to({ alpha: 1 }, 0)
						.wait(50).to({ alpha: 0.5 }, 0).wait(50).to({ alpha: 1 }, 0);
				}
				this.symbol!.tail.scale.set(1);
			} else {
				const p = data.cd === 1 ? 1 : Ease.quadOut(data.cd) * 0.8;
				this.symbol!.tail.scale.set(p);
			}
		}
		if (this.data && data.sid !== this.data.sid) {
			new SoundEfx('decoy').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
			const victim = room.visiblePlayers[data.vid || 0];
			if (victim) {
				victim.stababs[data.sid || 0]?.puff(2);
			} else if (player) {
				player.puff();
			}
		}
		this.data = data;
	}

	public show(room: RoomControl) {
	}
	public hide(room: RoomControl) {
	}
}
