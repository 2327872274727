
















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import { ordinalSuffix } from '@/util/number';
import globalx from '@/store/modules/globalx';
import { eventTimes, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';

@Component({
	components: {},
})
export default class DecoContestPoster extends Vue {
	@Prop({ default: 0 }) public contest!: number;
	@Prop({ default: '' }) public border!: string;
	public get ordinal() {
		return ordinalSuffix(this.contest || globalx.decoContest);
	}
	@Prop({ default: 'result' }) public stage!: 'none' | 'contest' | 'vote' | 'wait' | 'result';
	public width = 800;
	public height = 500;
	public mounted() {
		if (!(this.$el as any).resizeSensor) {
			const res = new ResizeSensor(this.$el, this.onResize);
		}

		this.onResize();
	}
	public beforeDestroy() {
		if ((this.$el as any).resizeSensor) {
			ResizeSensor.detach(this.$el);
		}
	}
	public onResize() {
		this.width = this.$el.clientWidth;
		this.height = (this.width / 800) * 500;
	}
	public get myStyle() {
		return `height: ${this.height}px;`;
	}
	public get contentStyle() {
		return {
			transform: 'scale(' + this.width / 800 + ')',
			backgroundImage: `url('/images/articles/decoPoster/${this.stage}.jpg')`,
			border: this.border,
		};
	}

	public get date() {
		if (this.stage === 'result') {
			return 'Ended';
		}
		if (this.stage === 'contest') {
			return `Closing Date: ${new Intl.DateTimeFormat(
				'en-UK', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
				hour12: true,
				hour: '2-digit',
				minute: '2-digit',
			}).format(new Date(eventTimes[TimeLimitedQuestCode.DecoSubmission][1]))}`;
		}
		if (this.stage === 'vote') {
			const d1 = new Date(eventTimes[TimeLimitedQuestCode.DecoVote][0]);
			const d2 = new Date(eventTimes[TimeLimitedQuestCode.DecoVote][1]);
			const str = `$${d1.getDate()} ~ ${new Intl.DateTimeFormat(
			'en-UK', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour12: true,
			hour: '2-digit',
			minute: '2-digit',
			}).format(d2)}`;

			return 'Voting Period: ' + str;
		}
		if (this.stage === 'wait') {
			return `Estimated Result Release Date: ${new Intl.DateTimeFormat(
				'en-UK', {
				day: 'numeric',
				month: 'short',
				year: 'numeric',
			}).format(new Date(eventTimes[TimeLimitedQuestCode.DecoWait][1]))}`;
		}
		return '';
	}
}
