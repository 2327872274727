










import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gamex from '@/store/modules/gamex';
import { AipAds } from '@/client/ads/aipads';
import { CrazyAd } from '@/client/ads/crazyads';
import { Global } from '@/store/globalz';

let nextBannerRefresh = 0;
@Component({
  components: {},
})
export default class OverlayGameAds extends Vue {
  @Prop({ required: true }) public showReincarnation!: boolean;
  @Prop({ required: true }) public width!: number;
  @Prop({ required: true }) public height!: number;
  public get toShowAdBannerContainer() {
    return gamex.gameStage === 'Start';
  }
  public get adBannerShowReason() {
    return gamex.toShowChat
      ? 'chat'
      : this.showReincarnation
      ? 'reincarnate'
      : gamex.polarModalShowing
      ? 'polar'
      : gamex.ivModalShowing
      ? 'iv'
      : 'none';
  }
  public get adBannerShowSize() {
    let toShow = false;
    switch (this.adBannerShowReason) {
      case 'chat':
        toShow = this.width >= 1130;
        break;

      case 'polar':
        toShow = this.width >= 1470;
        break;
      case 'iv':
        toShow = this.width >= 990 || this.height >= 775;
        break;
      case 'reincarnate':
        return this.width >= 1130 || this.height >= 920;
        break;
      default:
        toShow = false;
        break;
    }
    if (toShow) {
      this.refreshBanner();
    }
    return toShow;
  }
  public refreshBanner() {
    const ct = Date.now();
    if (ct < nextBannerRefresh) {
      return;
    }
    nextBannerRefresh = ct + 60000;
    if (Global.adsdk === 'crazyads') {
      CrazyAd.instance.refreshBannerAd('300x250');
    } else {
      AipAds.instance.refreshBannerAd('stabfish2-io_300x250');
    }
  }

  public mounted() {
    document.body.appendChild(this.$el);
  }
  public beforeDestroy() {
    document.body.removeChild(this.$el);
  }
}
