






import { ItemInfo, itemInfos } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ItemInventory from './ItemInventory.vue';

@Component({
  components: {
    ItemInventory,
  },
})
export default class BlueprintsTab extends Vue {
  public get items(): ItemCodeAmountPair[] {
    return userx.blueprints;
  }

  public onClick(itemInfo: ItemInfo | undefined, elementId: string) {
    if (itemInfo) { Global.craftModal.show(itemInfo.code); }
  }
}
