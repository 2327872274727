import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataBiLaserBeam, MiscDataSsSunPunch } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { BLEND_MODES, Container, Graphics, NineSlicePlane, Sprite, Texture } from 'pixi.js';
import Factory from '../../..';
import { MiscSymbol } from '../../skills/miscSymbol';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';

export class BiLaserBeamController implements MiscSymbol {

	public static get() {
		return BiLaserBeamController._pool.get();
	}

	private static _pool: Pool<BiLaserBeamController> = new Pool(BiLaserBeamController);


	public data: MiscData | null = null;

	public symbol = new NineSlicePlane(Texture.from('baXmasPenguin_MechguinLaserBeam'), 34, 30, 30, 34);
	public glow = new NineSlicePlane(Texture.from('baXmasPenguin_MechguinLaserBeamGlow'), 88, 65, 88, 65);
	public container = new Container();

	public constructor() {
		this.glow.blendMode = BLEND_MODES.ADD;
		this.container.addChild(this.glow, this.symbol);
		this.symbol.x = 202;
		this.glow.x = 202 - 88;
	}
	public dispose(room: RoomControl) {
		this.data = null;
		Tween.get(this.container).to({ alpha: 0 }, 300).call(() => {
			if (this.container.parent) {
				this.container.parent.removeChild(this.container);
			}
			BiLaserBeamController._pool.pool(this);
		});
		// Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
		// 	this.sound.stop();
		// });
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataBiLaserBeam;

		this.container.x = data.x;
		this.container.y = data.y;
		this.container.angle = data.d + 180;
		const h = data.sc * 75;
		this.symbol.width = data.l + 20;
		this.symbol.height = h + 19;
		this.symbol.y = -(h + 19) / 2;

		this.glow.width = data.l + 196;
		this.glow.height = h + 87;
		this.glow.y = -(h + 87) / 2;

		if (!this.data) {
			this.container.alpha = 0.9;
		} else {
		}
		this.data = data;

	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this.container);
	}
	public hide(room: RoomControl) {
		if (this.container.parent) {
			this.container.parent.removeChild(this.container);
		}
		// this.sound.volume = 0;
	}
}
