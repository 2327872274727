




import PixiCanvasComponent from '@/components/canvas/PixiCanvasComponent.vue';
import { Container, Graphics } from 'pixi.js';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { FishType } from '@/game/infos/fishInfos';
import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import Factory from '@/client/factory';
import userx from '@/store/modules/userx';
import { Decoratives } from '../ui/mainMenu/tools/decoEditor/decorative';

declare const sub: any;
@Component({
  components: {},
})
export default class FishButtonCanvasComponent extends PixiCanvasComponent {
  public get decoSet() {
    return userx.decoSets[this.fishType];
  }
  public get skinGroup() {
    return userx.equippedSkins[this.fishType] || 0;
  }
  public get sumcheck() {
    return (
      this.fishType.toString() +
      JSON.stringify(this.decoSet) +
      this.skinGroup.toString() +
      this.width.toString() +
      this.height.toString()
    );
  }
  @Prop({ default: 0 }) public fishType!: FishType;
  @Prop({ default: false }) public small!: boolean;

  public builtSumCheck = '';
  public symbol: PlayerSymbol | null = null;

  @Watch('sumcheck', { immediate: true })
  public sumcheckChanged(newVal: FishType | '', oldVal: FishType | '') {
    if (this.assetLoadState !== 'completed') {
      return;
    }
    if (newVal) {
      this.build();
    }
  }

  public async build() {
    if (this.builtSumCheck === this.sumcheck || this.width === 0) {
      return;
    }
    this.builtSumCheck = this.sumcheck;
    if (!this.symbol) {
      this.symbol = PlayerSymbol.get();
    }
    const symbol = this.symbol;
    const decoSet = this.decoSet;
    this.app.start();
    this.stage.addChild(symbol);
    symbol.setFishType(this.fishType, this.skinGroup);
    symbol.setAction('idle');
    // symbol.angle = -90;
    symbol.scale.set(0.7 * (this.small ? 0.83 : 1));
    symbol.x = this.width / 2;
    symbol.y = this.height / 2;
    // symbol.shadow.visible = false;
    symbol.miniSpike.visible = true;
    Decoratives.generateDeco(symbol, decoSet);
    this.rerenderAndStop();
  }
  public mounted() {
    this.width = this.height = 114;
    this.resize();
    if (this.assetLoadState === 'completed') {
      this.build();
    }
  }
  public _beforeDestroy() {
    this.stage.removeChildren();
    if (this.symbol) {
      this.symbol.disposeDecos();
      PlayerSymbol.pool(this.symbol);
      this.symbol = null;
    }
    // super.beforeDestroy();
  }
  public rerenderAndStop2() {
    this.app.start();
    this.isStopped = true;
    this.app.render();
    this.app.stop();
  }
}
