function _cross(o: [number, number], a: [number, number], b: [number, number]) {
	return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);
}

function _upperTangent(pointset: Array<[number, number]>) {
	const lower: Array<[number, number]> = [];
	for (const point of pointset) {
		while (lower.length >= 2 && (_cross(lower[lower.length - 2], lower[lower.length - 1], point) <= 0)) {
			lower.pop();
		}
		lower.push(point);
	}
	lower.pop();
	return lower;
}

function _lowerTangent(pointset: Array<[number, number]>) {
	const reversed = pointset.reverse(),
		upper: Array<[number, number]> = [];
	for (const point of reversed) {
		while (upper.length >= 2 && (_cross(upper[upper.length - 2], upper[upper.length - 1], point) <= 0)) {
			upper.pop();
		}
		upper.push(point);
	}
	upper.pop();
	return upper;
}

// pointset has to be sorted by X
export function convexHull(pointset: Array<[number, number]>) {
	const upper = _upperTangent(pointset),
		lower = _lowerTangent(pointset);
	const convex = lower.concat(upper);
	convex.push(pointset[0]);
	return convex;
}
