








import { getSkinImgUrl } from '@/client/factory/gachaAssets';
import { decodeSkinId, SkinInfo, skinInfos } from '@/game/infos/skinInfos';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SkinPreviewer extends Vue {
  public get skinSrc() {
    if (this.skinId === 0) { return ''; }
    const { fishType, skinGroup } = decodeSkinId(this.skinId);
    const url = getSkinImgUrl(skinGroup, fishType);

    return url;
  }
  public get skinName() {
    if (this.skinId === 0) { return ''; }
    const { fishType, skinGroup } = decodeSkinId(this.skinId);
    return skinInfos[skinGroup]
      ? skinInfos[skinGroup][fishType]
        ? skinInfos[skinGroup][fishType].name
        : ''
      : '';
  }
  public skinId: number = 0;
  public baseUrl = process.env.BASE_URL;

  public mounted() {
    this.$root.$on('previewSkin', (skinId: number) => {
      this.skinId = skinId;
    });
    document.body.appendChild(this.$el);
  }
  public beforeDestroy() {
    document.body.removeChild(this.$el);
  }
  public onClose() {
    this.$emit('close');
  }
}
