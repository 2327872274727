import { Container, Sprite } from 'pixi.js';
import { MiscSymbol } from './miscSymbol';
import { MiscData, MiscDataMcIronWave, MiscDataMcIronmonite, MiscDataMcZellyfish } from '@/game/multithread/skills/miscContainer';
import { RoomControl } from '@/client/controls/roomControl';
import { Ease, Tween } from '@/util/tweents';
import Factory from '../..';
import { ViewState } from '@/game/multithread/viewState';
import { SpearType } from '@/game/infos/spearInfos';
import { Rotate } from '@/util/rotate';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { BurningEffect, FrozenEffect, ParalyzedEffect } from '../hazardMixin';
import { HazardType } from '@/game/infos/hazardInfos';
import { tint } from '../../helper';

export class ZellyfishSymbol extends MovieClip {
}
export class McZellyfishSymbol extends Container implements MiscSymbol {
	public static get() {
		const symbol = McZellyfishSymbol._pool.get();
		symbol.symbol.gotoAndPlay('move');
		return symbol;
	}

	private static _pool: Pool<McZellyfishSymbol> = new Pool(McZellyfishSymbol);

	public data: MiscDataMcZellyfish | null = null;
	public symbol = Factory.get(ZellyfishSymbol);
	public paralyzedEffect = Factory.get(ParalyzedEffect);
	public burningEffect = Factory.get(BurningEffect);
	public frozenEffect = Factory.get(FrozenEffect);
	public effect: Container | null = null;
	public constructor() {
		super();
		this.scale.set(0.15);
	}

	public dispose(room: RoomControl) {
		this.data = null;
		if (this.parent) {
			this.parent.removeChild(this.symbol);
			this.parent.removeChild(this);
		}
		this.symbol.scale.set(1);
		this.scale.set(0.15);
		McZellyfishSymbol._pool.pool(this);

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataMcZellyfish;
		if (this.data?.h !== data.h) {
			if (this.effect) {
				this.removeChild(this.effect);
			}
			if (data.h === HazardType.Fire) {
				this.effect = this.burningEffect;
				this.addChild(this.effect);
				tint(this.symbol, 0xffe1dd);
			} else if (data.h === HazardType.Ice) {
				this.effect = this.frozenEffect;
				this.addChild(this.effect);
				tint(this.symbol, 0xdaedff);
			} else if (data.h === HazardType.Electric) {
				this.effect = this.paralyzedEffect;
				this.addChild(this.effect);
				tint(this.symbol, 0xeceecc);
			} else {
				this.effect = null;
				tint(this.symbol, 0xffffff);
			}
		}

		if (data.p) {
			if (data.p > 0) {
				const s = Ease.circOut(data.p) * .75 + 1;
				this.symbol.scale.set(s);
				this.scale.set(s * .15);
				if (!this.data?.p) {
					this.symbol.gotoAndPlay('explode');
					new SoundEfx('zellyExplode').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
				}
			} else {
				const s = Ease.circOut(-data.p);
				this.symbol.scale.set(s);
				this.scale.set(s * .15);
			}
			this.symbol.angle = 0;
		} else if (this.data) {
			const s = data.x - this.data.x;
			this.symbol.angle = Rotate.n2d(-s, 10) - 90;
		}
		this.x = data.x;
		this.y = data.y;
		this.symbol.x = data.x;
		this.symbol.y = data.y;
		// this.angle = (data.d || 0) + 90;
		this.data = data;
	}
	public show(room: RoomControl) {
		room.playerBottomContainer.addChild(this);
		room.playerBottomContainer.addChild(this.symbol);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this.symbol);
			this.parent.removeChild(this);
		}
	}
}
