import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsFireBullet } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container, DisplayObject, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { BurningEffect, FrozenEffect, ParalyzedEffect } from '../hazardMixin';
import { MiscSymbol } from './miscSymbol';

// const info = specialSkillInfos[SpecialSkill.SwimmingFang];
export class SsFireBulletSymbol extends MovieClip {
}
export class SsIceBulletSymbol extends Container {
}
export class SsFireBulletSymbolController implements MiscSymbol {

	public static get() {
		return SsFireBulletSymbolController._pool.get();
	}

	private static _pool: Pool<SsFireBulletSymbolController> = new Pool(SsFireBulletSymbolController);


	public area: Graphics = new Graphics();
	public data: MiscDataSsFireBullet | null = null;
	public isSameTeam = false;

	public fire = Factory.get(SsFireBulletSymbol);
	public ice = Factory.get(SsIceBulletSymbol);
	public symbol: DisplayObject = this.fire;

	public dispose(room: RoomControl) {
		this.data = null;
		this.area.clear();
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}

		SsFireBulletSymbolController._pool.pool(this);
	}

	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataSsFireBullet;
		if (!this.data || data.ac !== this.data.ac) {
			this.isSameTeam = data.tid === state.tid;
		}
		if (data.ac) {
			if (!this.data || !this.data.ac) {
				this.symbol = data.ff < 0 ? this.ice : this.fire;
				room.playerTopContainer.addChild(this.symbol);
				if (this.area.parent) {
					this.area.parent.removeChild(this.area);
				}
				new SoundEfx('fireBullet').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
			}
			if (!data.dd) {
				this.symbol.x = data.x;
				this.symbol.y = data.y;
				this.symbol.angle = data.d;
			}
		} else {
			const lineColor = this.isSameTeam ? 0xFFFF00 : 0xFF3399;
			this.area.clear();
			this.area.lineStyle(2, lineColor, 0.5);
			this.area.moveTo(data.x, data.y);
			const end = Rotate.move(Rotate.d2r(data.d), 750);
			this.area.lineTo(data.x + end.x, data.y + end.y);
		}


		this.data = data;
	}
	public show(room: RoomControl) {
		if (this.data) {
			if (this.data.ac) {
				room.playerTopContainer.addChild(this.symbol);
			} else {
				room.playerBottomContainer.addChild(this.area);
			}
		}
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
	}
}
