import random from '@/util/random';
import { Dictionary } from '@/util/dictionary';
import { Global } from '@/store/globalz';
import globalx from '@/store/modules/globalx';
declare const gtag: any;
declare const CrazyGames: any;
const count = 0;
type CrazyAdBannerType = '300x250' | 'gameOver-728x90' | 'gameOver-320x100' | 'gameOver-468x60' | 'afterGame-468x60' | 'afterGame-300x250';

export class CrazyAd {
    public static get instance(): CrazyAd {
        CrazyAd._instance = CrazyAd._instance || new CrazyAd();
        return CrazyAd._instance;
    }

    public get crazysdk() {
        return CrazyGames.SDK;
    }
    public static isVideoPlaying = false;
    private static _instance: CrazyAd;
    public adReady = { midgame: true, rewarded: true };
    public timeout: any = null;
    public lastAdId = '';
    public lastAdType?: 'midgame' | 'rewarded';
    public bannerTimeout = { '300x250': 0, 'gameOver-728x90': 0, 'gameOver-320x100': 0, 'gameOver-468x60': 0 };


    private _waitingPromises: Array<{ resolve: (v?: any) => void, reject: (error: any) => void; }> = [];
    constructor() {
        if (Global.adsdk !== 'crazyads') { return; }
        const { CrazySDK } = (window as any).CrazyGames.SDK;
        // crazysdk.init();
        // this.installListeners();
        this.countDownAd('rewarded');
        this.countDownAd('midgame');

        this.crazysdk.getEnvironment().then((env) => {
            if (env !== 'disabled' && env !== 'local') {
                globalx.updateShowCrazyGames(true);
            }
        });

        // document.getElementById('logo')!.addEventListener('click', () => {
        //     count++;
        //     if (count === 5) {
        //         document.getElementById('gameOverBanner')!.style.display = 'flex';
        //         this.refreshBannerAd('gameOver-728x90');
        //         count = 0;
        //     }
        // });

        // (window as any).sendAdStat = this.sendStat;
    }
    public async getUserName() {
        try {
            const user = await this.crazysdk.user.getUser();
            return user.username;
        } catch (error) {
            return '';
        }
    }
    public gameplayStart() {
        this.crazysdk.game.gameplayStart();
    }
    public gameplayStop() {
        this.crazysdk.game.gameplayStop();
    }

    public getBannerObject(type: CrazyAdBannerType) {
        const ct = Date.now();
        if (ct - this.bannerTimeout[type] <= 60000) {
            return null;
        }
        this.bannerTimeout[type] = ct;

        // if (Portal.referral === 'kong' || window.location.host === 'localhost:8080') return;
        const containerId = type === '300x250' ? 'stabfish2-io_300x250' :
            type === 'gameOver-728x90' ? 'stabfish2-io_728x90' :
                type === 'gameOver-468x60' ? 'stabfish2-io_468x60' :
                    type === 'gameOver-320x100' ? 'stabfish2-io_320x100' :
                        type === 'afterGame-468x60' ? 'stabfish2-io_468x60' :
                            type === 'afterGame-300x250' ? 'stabfish2-io_336x280' :
                                'stabfish2-io_300x250';

        const size = type === '300x250' ? '300x250' :
            type === 'gameOver-728x90' ? '728x90' :
                type === 'gameOver-468x60' ? '468x60' :
                    type === 'gameOver-320x100' ? '320x100' :
                        type === 'afterGame-468x60' ? '468x60' :
                            '300x250';
        const [width, height] = size.split('x');

        console.log('refreshBanner', containerId, size);
        return {
            id: containerId,
            width: Number(width),
            height: Number(height),
        };
    }
    public refreshMultipleBannerAd(...types: CrazyAdBannerType[]) {
        // const list: any[] = [];
        for (const type of types) {
            // const data = this.getBannerObject(type);
            // if (!data) { continue; }
            // list.push(data);
            this.refreshBannerAd(type);
        }
        // const crazysdk = this.crazysdk;
        // crazysdk.requestBanner(list);
    }
    public refreshBannerAd(type: CrazyAdBannerType) {
        const data = this.getBannerObject(type);
        if (!data) { return; }

        const crazysdk = this.crazysdk;
        crazysdk.banner.requestBanner(
            data,
        );
    }

    public countDownAd(adType: 'midgame' | 'rewarded') {
        this.adReady[adType] = false;
        setTimeout(() => {
            this.adReady[adType] = true;
        }, 1 * 60 * 1000);
    }

    // public installListeners() {
    //     this.crazysdk.addEventListener('adStarted', this.adStarted);
    //     this.crazysdk.addEventListener('adError', this.adError);
    //     this.crazysdk.addEventListener('adFinished', this.adFinished);
    //     this.crazysdk.addEventListener('bannerRendered', (event: any) => {
    //         console.log(`Banner for container ${event.containerId} has been
    //        rendered!`);
    //     });
    //     this.crazysdk.addEventListener('bannerError', (event: any) => {
    //         console.log(`Banner render error: ${event.error}`);
    //     });
    // }

    public adStarted = () => {
        CrazyAd.isVideoPlaying = true;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    public adError = (e: any) => {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        while (this._waitingPromises.length > 0) {
            const promise = this._waitingPromises.shift()!;
            promise.resolve(e);
        }
        // this.countDownAd(this.lastAdType!);
    }

    public adFinished = () => {
        CrazyAd.isVideoPlaying = false;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        while (this._waitingPromises.length > 0) {
            const promise = this._waitingPromises.shift()!;
            promise.resolve();
        }
        this.countDownAd(this.lastAdType!);
    }

    public async requestAd(adType: 'midgame' | 'rewarded' = 'rewarded') {
        return new Promise((resolve, reject) => {
            if (Global.adsdk !== 'crazyads') {
                resolve('This is not crazygames');
            }
            this._waitingPromises.push({ resolve, reject });
            this.lastAdId = random.firestore();
            this.lastAdType = adType;
            this.crazysdk.ad.requestAd(adType, {
                adFinished: this.adFinished,
                adError: this.adError,
                adStarted: this.adStarted,
            });
            this.timeout = setTimeout(() => {
                this.timeout = null;
                const i = this._waitingPromises.findIndex((p) => p.reject === reject);
                if (i !== -1) {
                    this._waitingPromises.splice(i, 1);
                    this.countDownAd(adType);
                    resolve(new Error('requestAd Timeout'));
                }
            }, 45 * 1000);
        });
    }

}
