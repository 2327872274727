



















import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CrazyAd } from '@/client/ads/crazyads';
import { AipAds } from '@/client/ads/aipads';
import gamex from '@/store/modules/gamex';
import { GameCommandType, IGameCommand } from '@/game/multithread/command';
import ItemButton from '../../mainMenu/tools/inventory/ItemButton.vue';
import StateButton from '@/components/inputs/StateButton.vue';
import userx from '@/store/modules/userx';
import { ItemIntelData } from '../../mainMenu/bag/ItemIntelTooltip.vue';
import { ItemCode } from '@/game/infos/itemInfos';
import { EditState } from '@/store/models.def';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';

@Component({
  components: {
    ItemButton,
    StateButton,
  },
})
export default class ClaimGladiatorModal extends Vue {

  public get rewards(): ItemCodeAmountPair[] {
    if (!userx.inventory.accuRewards || !userx.inventory.accuRewards.gladiator) {
      return [{ itemCode: ItemCode.Money, amount: 0 }];
    }
    const rewards: ItemCodeAmountPair[] = [];
    for (const code in userx.inventory.accuRewards.gladiator) {
      if (Object.prototype.hasOwnProperty.call(userx.inventory.accuRewards.gladiator, code)) {
        const amount = userx.inventory.accuRewards.gladiator[code];
        rewards.push({ itemCode: Number(code), amount });
      }
    }
    return rewards;
  }
  public get claimable() {
    return this.rewards[0];
  }
  public get submitLabel() {
    const labels = {
      active: 'Claim',
      sending: '',
      done: 'Claimed',
    };
    return labels[this.submitState];
  }
  public submitState: EditState = 'active';

  public onShow() {
    gamex.updateIvModalShowing(true);
  }
  public onHide() {
    gamex.updateIvModalShowing(false);
    Global.stageControl.uiControl.closeClaimGladiator();
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }

  public showItemIntel(i: number) {
    const id = 'gladiator-reward--' + i;
    const data: ItemIntelData = {
      targetId: id,
      placement: 'top',
      container: 'quest-component',
      itemCode: i,
    };
    this.$root.$emit('showItemIntel', data);
  }
  public hideItemIntel() {
    this.$root.$emit('hideItemIntel');
  }


  public async onClaim() {
    this.submitState = 'sending';
    try {
      await userx.claimAccuRewards('gladiator');
      this.submitState = 'done';
      new SoundEfx('buyChaching').play();
      Global.$root.$bvModal.hide('claimGladiator-modal');
    } catch (error) {
      this.$root.$emit('error', (error as any).message);
      this.submitState = 'active';
    }
  }
}
