import { RoomControl } from '@/client/controls/roomControl';
import { MiscData, MiscDataBiPenguinCrew } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../../..';
import { MiscSymbol } from '../../skills/miscSymbol';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';
import { MechguinPenguinState } from '@/game/infos/boss/xmasPenguinInfos';
import { HpLabelSymbol } from '../../playerLabelMixin';
import { BlindedEffect, BurningEffect, FrozenEffect, ParalyzedEffect, PuffEffect, WrappedEffect } from '../../hazardMixin';
import { StunAnimated } from '../../playerModel';
import { SoundEfx } from '@/client/sound/SoundEfx';

export class PenguinCrewSymbol extends MovieClip {

}
export class MechguinCrewWhiteFlag extends MovieClip {

}
export class BiPenguinCrewController implements MiscSymbol {

	public static get() {
		return BiPenguinCrewController._pool.get();
	}

	private static _pool: Pool<BiPenguinCrewController> = new Pool(BiPenguinCrewController);


	public data: MiscDataBiPenguinCrew | null = null;
	public pt = 0;

	// public sound = new LongSoundEfx('sunPunch');
	public symbol = new Container();
	public penguin = Factory.get(PenguinCrewSymbol);
	public hpLabel = new HpLabelSymbol();
	public puffEffect: PuffEffect | null = null;
	public wrappedEffect: WrappedEffect | null = null;
	public paralyzedEffect: ParalyzedEffect | null = null;
	public burningEffect: BurningEffect | null = null;
	public frozenEffect: FrozenEffect | null = null;
	public blindEffect: BlindedEffect | null = null;
	public stunEffect: StunAnimated | null = null;
	public whiteFlag: MechguinCrewWhiteFlag | null = null;


	public constructor() {
		this.symbol.addChild(this.penguin);

		this.hpLabel.maxHp = 250;
		this.hpLabel.barWidth = 250 / 6;
	}
	public dispose(room: RoomControl) {
		this.data = null;
		Tween.get(this.symbol).to({ alpha: 0 }, 300).call(() => {
			if (this.symbol.parent) {
				this.symbol.parent.removeChild(this.symbol);
			}
			if (this.hpLabel.parent) {
				this.hpLabel.parent.removeChild(this.hpLabel);
			}
			BiPenguinCrewController._pool.pool(this);
		});
		// Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
		// 	this.sound.stop();
		// });

		this.removePuffEffect();
		this.removeFrozenEffect();
		this.removeBurningEffect();
		this.removeParalizedEffect();
		this.removeStunEffect();
		this.removeBlindEffect();
		this.removeWrappedEffect();
		this.removeWhiteFlag();
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataBiPenguinCrew;

		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.symbol.angle = data.d;
		const pointRight = ((this.symbol.angle + 90) % 360 + 360) % 360 < 180;
		this.penguin.scale.y = pointRight ? 1 : -1;

		this.hpLabel.x = data.x;
		this.hpLabel.y = data.y - 70;
		this.hpLabel.hp = data.hp;

		if (!this.data) {
			// this.scale.set(0.01);
			this.penguin.gotoAndStop('idle');
			this.symbol.alpha = 1;
		} else {
			if (data.stt !== this.data.stt) {
				if (data.stt === MechguinPenguinState.Stabab) {
					this.hide(room);
				} else if (this.data.stt === MechguinPenguinState.Stabab) {
					this.show(room);
				}
				if (data.stt === MechguinPenguinState.Active) {
					this.penguin.gotoAndPlay('swim');
				} else if (data.stt === MechguinPenguinState.Fleeing) {
					this.penguin.gotoAndPlay('swim');
					if (!this.whiteFlag) {
						this.whiteFlag = Factory.get(MechguinCrewWhiteFlag);
						this.symbol.addChildAt(this.whiteFlag, 0);
						this.whiteFlag.x = -12;
						this.whiteFlag.y = -16;
						Tween.get(this.whiteFlag.scale).to({ x: 0.1, y: 0.1 }).to({ x: 1, y: 1 }, 500, Ease.elasticOut);
					}
				}
			}
		}
		if (data.bl) {
			if (!this.blindEffect) {
				this.blindEffect = Factory.get(BlindedEffect);
				this.penguin.addChild(this.blindEffect);
				this.blindEffect.scale.set(0.3);
				this.blindEffect.x = 30;
			}
		} else {
			this.removeBlindEffect();
		}
		if (data.stn) {
			if (!this.stunEffect) {
				this.stunEffect = Factory.get(StunAnimated);
				this.penguin.addChild(this.stunEffect);
				this.stunEffect.scale.set(0.5);
				this.stunEffect.x = 40;
			}
		} else {
			this.removeStunEffect();
		}
		if (data.pr) {
			if (!this.paralyzedEffect) {
				this.paralyzedEffect = Factory.get(ParalyzedEffect);
				this.symbol.addChild(this.paralyzedEffect);
				this.paralyzedEffect.scale.set(0.8, 0.6);
			}
		} else {
			this.removeParalizedEffect();
		}
		if (data.w) {
			if (!this.wrappedEffect) {
				this.wrappedEffect = Factory.get(WrappedEffect);
				this.symbol.addChild(this.wrappedEffect);
				this.wrappedEffect.scale.set(0.8, 0.6);
			}
		} else {
			this.removeWrappedEffect();
		}
		if (data.ff > 0) {
			if (!this.burningEffect) {
				this.burningEffect = Factory.get(BurningEffect);
				this.symbol.addChild(this.burningEffect);
				this.burningEffect.scale.set(0.8);
				this.burningEffect.angle = -90;
				this.burningEffect.x = -8;
				const sfx = new SoundEfx('burn');
				sfx.play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 });
			}
		} else {
			this.removeBurningEffect();
		}
		if (data.ff < 0) {
			if (!this.frozenEffect) {
				this.frozenEffect = Factory.get(FrozenEffect);
				this.symbol.addChild(this.frozenEffect);
				this.frozenEffect.scale.set(0.8, 0.6);
				const sfx = new SoundEfx('freeze');
				sfx.play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 });
			}
		} else {
			this.removeFrozenEffect();
		}
		this.data = data;

	}
	public show(room: RoomControl) {
		room.playersContainer.addChild(this.symbol);
		room.playerLabelsContainer.addChild(this.hpLabel);
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		if (this.hpLabel.parent) {
			this.hpLabel.parent.removeChild(this.hpLabel);
		}
	}

	public puff() {
		if (!this.puffEffect) {
			this.puffEffect = Factory.get(PuffEffect);
			this.symbol.addChild(this.puffEffect);
		}
		this.puffEffect.gotoAndPlay(0);

	}
	protected removeBlindEffect() {
		if (this.blindEffect) {
			this.blindEffect.x = 0;
			this.blindEffect.y = 0;
			this.blindEffect.scale.set(1);
			this.penguin.removeChild(this.blindEffect);
			Factory.pool(BlindedEffect, this.blindEffect);
			this.blindEffect = null;
		}
	}
	protected removeWhiteFlag() {
		if (this.whiteFlag) {
			Tween.removeTweens(this.whiteFlag);
			this.whiteFlag.x = 0;
			this.whiteFlag.y = 0;
			this.whiteFlag.scale.set(1);
			this.penguin.removeChild(this.whiteFlag);
			Factory.pool(MechguinCrewWhiteFlag, this.whiteFlag);
			this.whiteFlag = null;
		}
	}
	protected removeStunEffect() {
		if (this.stunEffect) {
			this.stunEffect.x = 0;
			this.stunEffect.y = 0;
			this.stunEffect.scale.set(1);
			this.penguin.removeChild(this.stunEffect);
			Factory.pool(StunAnimated, this.stunEffect);
			this.stunEffect = null;
		}
	}
	protected removePuffEffect() {
		if (this.puffEffect) {
			this.puffEffect.x = 0;
			this.puffEffect.y = 0;
			this.puffEffect.scale.set(1);
			this.symbol.removeChild(this.puffEffect);
			Factory.pool(PuffEffect, this.puffEffect);
			this.puffEffect = null;
		}
	}
	protected removeParalizedEffect() {
		if (this.paralyzedEffect) {
			this.paralyzedEffect.x = 0;
			this.paralyzedEffect.y = 0;
			this.paralyzedEffect.scale.set(1);
			this.symbol.removeChild(this.paralyzedEffect);
			Factory.pool(ParalyzedEffect, this.paralyzedEffect);
			this.paralyzedEffect = null;
		}
	}
	protected removeWrappedEffect() {
		if (this.wrappedEffect) {
			this.wrappedEffect.x = 0;
			this.wrappedEffect.y = 0;
			this.wrappedEffect.scale.set(1);
			this.symbol.removeChild(this.wrappedEffect);
			Factory.pool(WrappedEffect, this.wrappedEffect);
			this.wrappedEffect = null;
		}
	}
	protected removeBurningEffect() {
		if (this.burningEffect) {
			this.burningEffect.x = 0;
			this.burningEffect.y = 0;
			this.burningEffect.angle = 0;
			this.burningEffect.scale.set(1);
			this.symbol.removeChild(this.burningEffect);
			Factory.pool(BurningEffect, this.burningEffect);
			this.burningEffect = null;
			this.puff();
		}
	}
	protected removeFrozenEffect() {
		if (this.frozenEffect) {
			this.frozenEffect.x = 0;
			this.frozenEffect.y = 0;
			this.frozenEffect.scale.set(1);
			this.symbol.removeChild(this.frozenEffect);
			Factory.pool(FrozenEffect, this.frozenEffect);
			this.frozenEffect = null;
			this.puff();
		}
	}
}
