import { Container, Graphics } from 'pixi.js';

const OFFSET = 2.5;
const SKEW = 3;
const HEIGHT = 25;

function drawSkewedRectangle(graphics: Graphics, x: number, y: number, w: number, h: number, skew: number = 3) {
	graphics.drawPolygon([
		x + skew, y,
		x + w + skew, y,
		x + w - skew, y + h,
		x - skew, y + h,
	]);
}

export class ShadowedProgressBar extends Container {

	public bg = new Graphics();
	public barColor = 0xffffff;
	public bar = new Graphics();

	protected _progress = 1;
	protected _barWidth = 300;

	public constructor() {
		super();
		this.setBarWidth(this._barWidth);
		this.addChild(this.bg, this.bar);
	}

	public setBarWidth(w: number) {
		const h = HEIGHT;
		this._barWidth = w;
		this.bg.clear();
		this.bg.beginFill(0x001B4F);
		drawSkewedRectangle(this.bg, -w / 2 + OFFSET, -h / 2 + OFFSET, w, h, SKEW);
		this.setProgress(this._progress);
	}

	public setProgress(p: number) {
		const h = HEIGHT;
		this.bar.clear();
		this.bar.beginFill(this.barColor);
		const w = this._barWidth;
		drawSkewedRectangle(this.bar, -w / 2 - OFFSET, -h / 2 - OFFSET, w * p, h, SKEW);

	}
}
