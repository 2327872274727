import { PlayerType, StababType } from '@/game/infos/enums';
import { FishType } from '@/game/infos/fishInfos';
import { StababAction, StababData } from '@/game/multithread/viewState';
import settingx from '@/store/modules/settingx';
import { Pool } from '@/util/pool';
import { Container, Sprite } from 'pixi.js';
import Factory from '../factory';
import { ParalyzedEffect, BurningEffect, FrozenEffect, PuffEffect, ZompkinEffect } from '../factory/assets/hazardMixin';
import { FishAction, PlayerSymbol } from '../factory/assets/playerMixin';
import { TintContainer, rgbToInteger } from '../factory/helper';
import { disposeParticle, getParticle, Particle } from '../factory/particles';
import { PlayerControl } from './playerControl';
import { skinGroups } from '../factory/assets/skinAssets';
import { MovieClip } from 'pixi-animate';
import { PenguinCrewSymbol } from '../factory/assets/boss/xmasPenguin/biPenguinCrewSymbol';
import { Ease, Tween } from '@/util/tweents';

const SCALEX = 0.6;
const SCALEY = 0.6;

export class Pumpkinbab extends MovieClip {

	// public setZompkin(value: boolean) {
	// 	this.children[0].visible = value;
	// 	this.children[1].visible = !value;
	// }
}

export class StababControl {

	public static get(stababData: StababData, player?: PlayerControl | null) {
		const stababControl = StababControl._pool.get();
		stababControl.create(stababData, player);
		return stababControl;
	}

	private static _pool: Pool<StababControl> = new Pool(StababControl);

	public container = new Container();
	public tintContainer = new TintContainer();
	public fishSymbol: PlayerSymbol = PlayerSymbol.get();
	public symbol: MovieClip | null = null;
	public data: StababData | null = null;
	public playerId = NaN;
	public stababId = NaN;

	public player: PlayerControl | null = null;

	public paralyzedEffect: ParalyzedEffect | null = null;
	public burningEffect: BurningEffect | null = null;
	public frozenEffect: FrozenEffect | null = null;
	public zompkinEffect: ZompkinEffect | null = null;
	public puffEffect: PuffEffect | null = null;

	constructor() {
		this.container.scale.x = SCALEX;
		this.container.scale.y = SCALEY;
		this.container.angle = 90;
		this.container.addChild(this.tintContainer);
	}

	public create(data: StababData, player?: PlayerControl | null) {
		this.stababId = data.id;
		this.playerId = data.pid;
		this.container.angle = data.d + 90;
		if (data.t === StababType.Tail) {
			const skinGroup = data.pid;
			const tail = new skinGroups['s' + skinGroup].Tail_Axolotl();
			this.tintContainer.addChild(tail);
			tail.scale.set(2);
		} else if (data.t === StababType.Pumpkin) {
			const pumpkin = this.symbol = Factory.get(Pumpkinbab);
			this.tintContainer.addChild(this.symbol);
			this.symbol.angle = -90;
			this.symbol.scale.set(1);
			// pumpkin.setZompkin(Boolean(data.zompkin));
			if (data.ft === 0) {
				this.container.scale.set(0.01);
				Tween.get(this.container.scale).to({ x: SCALEX, y: SCALEY }, 500, Ease.circOut);
			}
		} else if (data.t === StababType.Penguin) {
			this.symbol = Factory.get(PenguinCrewSymbol);
			this.tintContainer.addChild(this.symbol);
			this.symbol.angle = 90;
			this.symbol.scale.set(1.5);
		} else {
			const skinGroup = player && player.toShowSkin && player.data && player.data.skinGroup || 0;
			this.fishSymbol.setFishType(data.ft, skinGroup);
			if (data.ft === FishType.Zompkin) {
				this.fishSymbol.miniSpike.visible = false;
				this.container.angle = 0;
				this.fishSymbol.scale.set(1.3);
			} else if (data.ft === FishType.Penguin) {
				this.fishSymbol.miniSpike.visible = false;
			} else {
				this.fishSymbol.scale.set(1);
				this.fishSymbol.miniSpike.visible = true;
			}
			// this.fishSymbol.miniSpike.visible = data.ft <= FishType.Dragon;
			this.tintContainer.addChild(this.fishSymbol);
			// get clone sprite
			if (player) {
				player.stababControl = this;
				this.player = player;
				this.fishSymbol.brightness = player.data?.playerType === PlayerType.Nemesis ? 0.5 : 1;

				// player.cloneDecoToStababSymbol(this.symbol);
				this.fishSymbol.topContainer.visible = this.fishSymbol.bottomContainer.visible = player.toShowDecos;
				if (player.symbol.topContainer.children[0]) {
					this.fishSymbol.topContainer.addChild(player.symbol.topContainer.children[0]);
				}
				if (player.symbol.bottomContainer.children[0]) {
					this.fishSymbol.bottomContainer.addChild(player.symbol.bottomContainer.children[0]);
				}
			}
			if (data.zompkin) {
				this.zompkinEffect = Factory.get(ZompkinEffect);
				this.container.addChild(this.zompkinEffect);
				this.zompkinEffect.gotoAndStop('idle');
			}
		}

	}

	public puff(scale = 1) {
		if (!this.puffEffect) {
			this.puffEffect = Factory.get(PuffEffect);
			this.puffEffect.playerControl = this;
			this.container.addChild(this.puffEffect);
		}
		this.puffEffect.scale.set(scale);
		this.puffEffect.gotoAndPlay(0);
	}

	public update(data: StababData, spearTemparature: number, spearElectrified: boolean) {
		if (data.t === StababType.Fish || data.t === StababType.Penguin) {

			if (!this.data || this.data.state !== data.state) {
				if (this.symbol) {
					const action = data.state === StababAction.Dead ? 'dead' :
						data.state === StababAction.Normal ? 'move' :
							data.state === StababAction.Experdite ? 'skill1' : 'idle';
					this.symbol.gotoAndPlay(action);
				} else {
					let action: FishAction;
					if (data.ft === FishType.AlligatorSnappingTurtle) {
						action = data.state === StababAction.Dead ? 'dead' :
							data.state === StababAction.Normal ? 'idle' :
								data.state === StababAction.Experdite ? 'move' : 'skill1';
					} else {
						action = data.state === StababAction.Dead ? 'dead' :
							data.state === StababAction.Normal ? 'move' :
								data.state === StababAction.Experdite ? 'skill1' : 'idle';
					}
					this.fishSymbol.setAction(action);


					if (this.player?.data?.playerType === PlayerType.Nemesis) {
						if (action === 'dead') {
							this.fishSymbol.disposeEvilEyes();
						} else {
							if (!this.fishSymbol.evilEyes) {
								this.fishSymbol.showEvilEyes();
							}
						}
					}
				}
				this.container.scale.y = (data.state === StababAction.Delay ? 0.9 : 1) * SCALEY;
			}
		}
		this.container.scale.x = data.scale * SCALEX;

		this.container.y = -data.dist;
		if (spearTemparature > 0) {
			const p = 255 - 255 * Math.min(spearTemparature, 100) / 100;
			this.tintContainer.setTint(
				rgbToInteger(
					255,
					191 + p / 4,
					125 + p / 2,
				));
		} else if (spearTemparature < 0) {
			const p = 255 - 255 * Math.max(spearTemparature, -100) / -100;
			this.tintContainer.setTint(
				rgbToInteger(
					p,
					255,
					255,
				));
		} else {
			this.tintContainer.setTint(0xffffff);
		}
		if (spearTemparature > 100) {
			if (!this.burningEffect) {
				this.burningEffect = Factory.get(BurningEffect);
				this.container.addChild(this.burningEffect);
			}
			if (spearTemparature < 500) {
				const p = (spearTemparature - 100) / 400;
				this.burningEffect.alpha = 0.4 + 0.6 * p;
				this.burningEffect.scale.set(0.75 + 0.25 * p);
			} else {
				this.burningEffect.alpha = 1;
				this.burningEffect.scale.set(1);
			}
		} else {
			if (this.burningEffect) {
				this.container.removeChild(this.burningEffect);
				Factory.pool(BurningEffect, this.burningEffect);
				this.burningEffect.scale.set(1);
				this.burningEffect.alpha = 1;
				this.burningEffect = null;
				this.puff();
			}
		}
		if (spearTemparature < -100) {
			if (!this.frozenEffect) {
				this.frozenEffect = Factory.get(FrozenEffect);
				this.container.addChild(this.frozenEffect);
			}
			if (spearTemparature > -500) {
				const p = (-spearTemparature - 100) / 400;
				this.frozenEffect.alpha = 0.4 + 0.6 * p;
				this.frozenEffect.scale.set(0.75 + 0.25 * p);
			} else {
				this.frozenEffect.alpha = 1;
				this.frozenEffect.scale.set(1);
			}
		} else {
			if (this.frozenEffect) {
				this.container.removeChild(this.frozenEffect);
				Factory.pool(FrozenEffect, this.frozenEffect);
				this.frozenEffect.scale.set(1);
				this.frozenEffect.alpha = 1;
				this.frozenEffect = null;
				this.puff();
			}
		}
		if (spearElectrified) {
			if (!this.paralyzedEffect) {
				this.paralyzedEffect = Factory.get(ParalyzedEffect);
				this.container.addChild(this.paralyzedEffect);
			}
		} else {
			if (this.paralyzedEffect) {
				this.container.removeChild(this.paralyzedEffect);
				Factory.pool(ParalyzedEffect, this.paralyzedEffect);
				this.paralyzedEffect = null;
			}
		}
		this.data = data;


		// if (tid === data.tid) {
		// 	const teamIndex = teamMembers.indexOf(data.pid);
		// 	if (!this.teamCircle) {
		// 		this.teamCircle = getParticle(Particle.TeamCircle);
		// 		this.teamCircle.alpha = 0.65;
		// 	}
		// 	this.teamCircle.tint = settingx.now[`color${teamIndex + 1}`];
		// 	this.symbol.addChildAt(this.teamCircle, 0);
		// }
	}

	public dispose() {

		// if (this.teamCircle) {
		// 	this.symbol.removeChild(this.teamCircle);
		// 	disposeParticle(Particle.TeamCircle, this.teamCircle);
		// 	this.teamCircle = null;
		// }
		this.tintContainer.setTint(0xffffff);

		if (this.zompkinEffect) {
			this.container.removeChild(this.zompkinEffect);
			Factory.pool(ZompkinEffect, this.zompkinEffect);
			this.zompkinEffect = null;
		}
		if (this.paralyzedEffect) {
			this.container.removeChild(this.paralyzedEffect);
			Factory.pool(ParalyzedEffect, this.paralyzedEffect);
			this.paralyzedEffect = null;
		}
		if (this.frozenEffect) {
			this.container.removeChild(this.frozenEffect);
			Factory.pool(FrozenEffect, this.frozenEffect);
			this.frozenEffect.scale.set(1);
			this.frozenEffect.alpha = 1;
			this.frozenEffect = null;
		}
		if (this.burningEffect) {
			this.container.removeChild(this.burningEffect);
			Factory.pool(BurningEffect, this.burningEffect);
			this.burningEffect.angle = 0;
			this.burningEffect.scale.set(1);
			this.burningEffect.alpha = 1;
			this.burningEffect = null;
		}
		if (this.puffEffect) {
			this.puffEffect.dispose();
		}

		if (this.player) {
			if (this.player.stababControl === this) {
				if (this.fishSymbol.topContainer.children[0]) {
					this.player.symbol.topContainer.addChild(this.fishSymbol.topContainer.children[0]);
				}
				if (this.fishSymbol.bottomContainer.children[0]) {
					this.player.symbol.bottomContainer.addChild(this.fishSymbol.bottomContainer.children[0]);
				}
				this.player.stababControl = null;
			}
			this.player = null;
		}

		this.fishSymbol.disposeDecos();
		this.fishSymbol.reset();
		if (this.fishSymbol.parent) {
			this.fishSymbol.parent.removeChild(this.fishSymbol);
		} else {
			if (this.data?.t === StababType.Pumpkin) {
				Factory.pool(Pumpkinbab, this.symbol);
				this.symbol!.scale.set(1);
				this.symbol!.angle = 0;
			} else if (this.data?.t === StababType.Penguin) {
				Factory.pool(PenguinCrewSymbol, this.symbol);
				this.symbol!.scale.set(1);
				this.symbol!.angle = 0;
			}
			this.symbol = null;
			this.tintContainer.removeChildren();
		}
		StababControl._pool.pool(this);
		this.data = null;
	}
}
