















































































































































































import { Analytics } from '@/client/analytics';
import { fishInfos, fishTiersAndBelow, FishType } from '@/game/infos/fishInfos';
import { TipId } from '@/game/infos/tipInfos';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import globalx from '@/store/modules/globalx';
import settingx from '@/store/modules/settingx';
import tipx from '@/store/modules/tipx';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { wait } from '@/util/wait';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishButton from '../mainMenu/tools/inventory/FishButton.vue';
import FishGeneComponent from '../mainMenu/evolution/FishGeneComponent.vue';
import FishAllSkillsComponent from '../mainMenu/evolution/FishAllSkillsComponent.vue';
import { FishIntelData } from '../mainMenu/tools/fishSelector/FishIntelTooltip.vue';
export interface FishGeneSkillInfo {
  order: number;
  type: number;
  lvl: number;
  status: '' | 'Stack!' | 'Inherit!';
}

@Component({
  components: {
    FishButton,
    FishGeneComponent,
    FishAllSkillsComponent,
  },
})
export default class TnyEvolutionModal extends Vue {
  public page: 'skill' | 'gene' = 'skill';

  @Prop({ default: () => [1, 2, 3] }) public heroes!: FishType[];

  public get geneses() {
    const geneses = this.heroes.map((h) => fishInfos[h].genes);
    const dic: Dictionary<FishGeneSkillInfo> = {};
    let order = 0;
    const result: FishGeneSkillInfo[][] = [];
    for (let i = 0; i < geneses.length; i++) {
      const genes = geneses[i];
      result[i] = [];

      for (const gene of genes) {
        const key = gene.type;
        if (dic[key]) {
          dic[key].lvl += gene.lvl;
          dic[key].status = 'Stack!';
        } else {
          dic[key] = {
            type: gene.type,
            order,
            lvl: gene.lvl,
            status: '',
          };
          order++;
        }
      }

      for (const gene of Object.values(dic)) {
        result[i].push({ ...gene });
        gene.status = 'Inherit!';
      }
      result[i].sort((a, b) => b.order - a.order);
    }
    return result;
  }

  public onToFishSelector(life: number) {
    this.$emit('select', life);
  }
  public showFishIntel(life: number) {
    const data: FishIntelData = {
      targetId: 'evomod-fish-' + life,
      placement: 'right',
      container: 'cps-evolution-modal',
      fishType: this.heroes[life],
    };
    this.$root.$emit('showFishIntel', data);
  }
  public hideFishIntel() {
    this.$root.$emit('hideFishIntel');
  }

}
