

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

declare const gapi: any;
@Component({
  components: {},
})
export default class YoutubeSubscibeContainer extends Vue {
  public mounted() {
    gapi.ytsubscribe.go();
  }
  public deactivated() {
    // document
    //   .getElementById('hiddenContainer')!
    //   .appendChild(document.getElementById('ytSubscribe')!);
  }
}
