import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsWhammyWave } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';

const info = specialSkillInfos[SpecialSkill.WhammyWave];
const castTime0 = info.castTime;
const duration = info.duration;

export class SsWhammyWaveSymbol extends Container implements MiscSymbol {

	public area!: Graphics;
	public wave1!: Container;
	public wave2!: Container;

	public data: MiscData | null = null;
	public pt = 0;

	public sound = new LongSoundEfx('whammyWave');

	public tween = new ManualTween(this.scale)
		.to({ x: 0.01, y: 0.01 }, 0)
		.wait(castTime0)
		.call(() => {
			this.visible = true;
			this.sound.play({ loop: false, start: 0 });
			if (this.area.parent) {
				this.area.parent.removeChild(this.area);
			}
		})
		.to({ x: 1, y: 1 }, duration, Ease.elasticOut);

	public init() {
		this.area = this.children[0] as Graphics;

	}
	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		Tween.get(this).to({ alpha: 0 }, 300).call(() => {
			if (this.parent) {
				this.parent.removeChild(this);
			}
			Factory.pool(SsWhammyWaveSymbol, this);
		});
		Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
			this.sound.stop();
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsWhammyWave;
		this.area.x = data.x;
		this.area.y = data.y;

		this.x = data.x;
		this.y = data.y;


		if (!this.data) {
			// this.scale.set(0.01);
			this.alpha = 1;
			this.area.visible = true;
			this.visible = false;
			this.tween.setPosition(0, true);
		} else {
			const delta = rt - this.pt;
			this.wave1.angle += delta * 0.05;
			this.wave2.angle -= delta * 0.05;
		}
		this.pt = rt;
		const castTime = data.ct;
		const startTime = data.st;
		const position = rt <= startTime + castTime ? (rt - startTime) / castTime * castTime0 : rt - startTime - castTime + castTime0;

		this.tween.setPosition(position);
		this.data = data;

		const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		this.sound.volume = volume;
	}
	public show(room: RoomControl) {
		if (this.tween.position < castTime0) {
			room.wallBottomContainer.addChild(this.area);
		}
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
		this.sound.volume = 0;
	}
}
