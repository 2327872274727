







import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { addComma } from '@/util/number';
import { ItemCode } from '@/game/infos/itemInfos';
import QuartersField from './poq/QuartersField.vue';
import { Global } from '@/store/globalz';
import BlackPearlField from './poq/BlackPearlField.vue';
@Component({
  components: {
    QuartersField,
    BlackPearlField,
  },
})
export default class CurrencyBar extends Vue {
  @Prop({ default: false }) public hideBlackPearl!: boolean;
  public baseUrl = process.env.BASE_URL;
}
