





























































































import { getQuestProgress } from '@/game/infos/firestoreFiles';
import { MainQuestCode, mainQuests } from '@/game/infos/questMain';
import { timeLimitedQuests } from '@/game/infos/questTimeLimited';
import { WeeklyQuestCode, weeklyQuests } from '@/game/infos/questWeekly';
import { QuestType } from '@/game/infos/unlockModels';
import { ServerTime } from '@/store/api/serverTime';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import QuestComponent from './quests/QuestComponent.vue';
import globalx from '@/store/modules/globalx';

interface QuestStatus {
  code: number;
  type: QuestType;
  completed: boolean;
  claimable: boolean;
}

@Component({
  components: {
    QuestComponent,
  },
})
export default class QuestTab extends Vue {
  public allMainQuestCodes = [
    MainQuestCode.LevelCrystal,
    MainQuestCode.Diy,
    MainQuestCode.Lvl5,
    MainQuestCode.UnlockSf2,
    MainQuestCode.NewCraft5,
    MainQuestCode.Deco5,
    MainQuestCode.UnlockSkin10,
    MainQuestCode.Rank,
    MainQuestCode.EmailVerify,
    MainQuestCode.LinkPoq,
  ];
  public allWeeklyQuestCodes = [
    WeeklyQuestCode.Login,
    WeeklyQuestCode.StabHappy,
    WeeklyQuestCode.Score,
    WeeklyQuestCode.Highscore,
    WeeklyQuestCode.BuyWeapon,
    WeeklyQuestCode.Mine,
    WeeklyQuestCode.Treasure,
    WeeklyQuestCode.Craft,
    WeeklyQuestCode.Armageddon,
    WeeklyQuestCode.Gacha,
    WeeklyQuestCode.Purchase,
  ];

  public get hasMainClaimables() {
    return this.mainQuestStatus.find((s) => s.claimable);
  }
  public get hasWeeklyClaimables() {
    return this.weeklyQuestStatus.find((s) => s.claimable);
  }

  public get hasMainUnread() {
    return userx.unreadCompletedQuestChallenges.find(
      (id) => id.indexOf(`QM-`) === 0,
    );
  }
  public get hasWeeklyUnread() {
    return userx.unreadCompletedQuestChallenges.find(
      (id) => id.indexOf(`QW-`) === 0,
    );
  }
  public get autoTimeLimitedEvent() {
    return globalx.autoTimeLimitedEvent;
  }

  public totalMainQuests = 0;
  public completedMainQuests = 0;
  public totalWeeklyQuests = 0;
  public completedWeeklyQuests = 0;

  public get mainQuestStatus(): QuestStatus[] {
    const claimableQuests = userx.claimableQuests;
    const progresses = userx.userDoc.mainQuestProgress;
    const quests = mainQuests;
    const type = QuestType.Main;
    let total = 0;
    let numCompleted = 0;
    const list: QuestStatus[] = this.allMainQuestCodes.map((code) => {
      const claimable = claimableQuests.find(
        (q) => q.type === type && q.code === code,
      )
        ? true
        : false;

      const progressA = getQuestProgress(progresses, code);
      const completed = progressA.currentStep >= quests[code].steps.length;
      if (completed) {
        numCompleted++;
      }
      total++;
      return {
        code,
        claimable,
        type,
        completed,
      };
    });
    this.totalMainQuests = total;
    this.completedMainQuests = numCompleted;
    list.sort((a, b) => {
      if (a.claimable !== b.claimable) {
        if (a.claimable) {
          return -1;
        }
        return 1;
      }
      if (a.completed !== b.completed) {
        if (a.completed) {
          return 1;
        }
        return -1;
      }
      return 0;
    });
    return list;
  }
  public get weeklyQuestStatus() {
    const claimableQuests = userx.claimableQuests;
    const progresses = userx.userDoc.weeklyQuestProgress;
    const quests = weeklyQuests;
    const type = QuestType.Weekly;
    let total = 0;
    let numCompleted = 0;
    const list: QuestStatus[] = this.allWeeklyQuestCodes.map((code) => {
      const claimable = claimableQuests.find(
        (q) => q.type === type && q.code === code,
      )
        ? true
        : false;
      const progressA = getQuestProgress(progresses, code);
      const completed = progressA.currentStep >= quests[code].steps.length;
      if (completed) {
        numCompleted++;
      }
      total++;
      return {
        code,
        claimable,
        type,
        completed,
      };
    });
    this.totalWeeklyQuests = total;
    this.completedWeeklyQuests = numCompleted;
    list.sort((a, b) => {
      if (a.claimable !== b.claimable) {
        if (a.claimable) {
          return -1;
        }
        return 1;
      }
      if (a.completed !== b.completed) {
        if (a.completed) {
          return 1;
        }
        return -1;
      }
      return 0;
    });
    return list;
  }
  public get timeLeft() {
    const startDate = 1638028800000;
    const week = 604800000;
    let nextWeek = startDate;
    while (nextWeek < this.time) {
      nextWeek += week;
    }

    const closeTime = nextWeek;
    let tl = closeTime - this.time;
    if (tl < 0) {
      tl = 0;
    }
    const day = Math.floor(tl / (24 * 60 * 60 * 1000));
    tl -= day * 24 * 60 * 60000;
    const hour = Math.floor(tl / (60 * 60 * 1000));
    tl -= hour * 60 * 60000;
    const min = Math.round(tl / 60000);
    // tl -= min * 60000
    return day > 0 ? `${day}d ${hour}h` : `${hour}h ${min}m`;
  }
  @Prop({ default: false }) public hasCloseBtn!: boolean;

  public tab: 'main' | 'weekly' = 'main';
  public mainQuests = mainQuests;
  public weeklyQuests = weeklyQuests;
  public timeLimitedQuests = timeLimitedQuests;

  public get time() {
    return globalx.time;
  }

  public selectTab(tab: 'main' | 'weekly') {
    this.tab = tab;
  }
  //   public get hasMissionCompleted() {
  // 	  return globalx.latestNews !== settingx.now.news;
  //   }
}
