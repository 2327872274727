


















import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseInput from '@/components/inputs/BaseInput.vue';

@Component
export default class Radix36Input extends BaseInput {
  @Prop({ default: true }) public readonly showInvalid!: boolean;
  @Prop({ default: '' }) public readonly placeholder!: string;

  public input: string = '';

  get invalidError() {
    return `Invalid Team ID.`;
  }

  public validate(value: string) {
    if (value === null) { return false; }

    if (value.length !== 6) { return false; }
    if (!/^[a-zA-Z0-9]*$/.test(value)) { return false; }
    return true;
  }
}
