











import SkinButton from '@/components/gacha/SkinButton.vue';
import { SkinIntelData } from '@/components/gacha/SkinIntelTooltip.vue';
import { decodeSkinId, SkinInfo, skinInfos } from '@/game/infos/skinInfos';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
@Component({
  components: {
    SkinButton,
  },
})
export default class NewSkinComponent extends Vue {
  @Prop({ default: 1001 }) public skinId!: number;

  public get skin() {
    const { skinGroup, fishType } = decodeSkinId(this.skinId);
    return skinInfos[skinGroup][fishType];
  }
  public get id() {
    return 'new-skin' + random.v4();
  }

  public showSkinIntel(skin: SkinInfo) {
    const data: SkinIntelData = {
      targetId: this.id,
      placement: 'top',
      container: 'gacha-result',
      ...skin,
    };
    this.$root.$emit('showSkinIntel', data);
  }
  public hideSkinIntel() {
    this.$root.$emit('hideSkinIntel');
  }
}
