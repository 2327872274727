import { SpecialSkill, NormalSkill, PassiveSkill, passiveSkillInfos, SkillInfo } from '@/game/infos/skills';
import { Pool } from '@/util/pool';
import { Container } from 'pixi.js';
import { SpecialSkillAsset, specialSkillAssets, disposeSkillBg, disposeSkillSprite, getSpecialSprite, getNormalSkillSprite, getPassiveSprite, getSkillBg, getSkillLvl, NormalSkillAsset, normalSkillAssets, SkillAssetInfo, SkillBgAsset } from '../skillAssets';

export interface SkillIconData {
	type: 'normal' | 'special' | 'passive' | 'gene' | 'buff' | 'recess',
	index: NormalSkillAsset | SpecialSkillAsset | PassiveSkill,
	lvl: number,
}
export function generateNormalSkillIconData(index: NormalSkill): SkillIconData {
	const assetData = normalSkillAssets[index] || normalSkillAssets[NormalSkill.None];
	return {
		type: 'normal',
		index: assetData.asset,
		lvl: assetData.lvl,
	};
}
export function generateSpecialSkillIconData(index: SpecialSkill): SkillIconData {
	const assetData = specialSkillAssets[index] || specialSkillAssets[SpecialSkill.None];
	return {
		type: 'special',
		index: assetData.asset,
		lvl: assetData.lvl,
	};
}
export function generatePassiveSkillIconData(index: PassiveSkill, lvl: number): SkillIconData {
	return passiveSkillInfos[index] && passiveSkillInfos[index].lvls[lvl ? lvl - 1 : lvl] ? {
		type: 'passive',
		index,
		lvl,
	} : {
		type: 'passive',
		index: PassiveSkill.None,
		lvl: 0,
	};
}
export function generateGeneSkillIconData(index: PassiveSkill, lvl: number): SkillIconData {
	return passiveSkillInfos[index] && passiveSkillInfos[index].lvls[lvl ? lvl - 1 : lvl] ? {
		type: 'gene',
		index,
		lvl,
	} : {
		type: 'gene',
		index: PassiveSkill.None,
		lvl: 0,
	};
}
export function generateRGeneSkillIconData(index: PassiveSkill, lvl: number): SkillIconData {
	return passiveSkillInfos[index] && passiveSkillInfos[index].lvls[lvl ? lvl - 1 : lvl] ? {
		type: 'recess',
		index,
		lvl,
	} : {
		type: 'recess',
		index: PassiveSkill.None,
		lvl: 0,
	};
}
export function generateBuffSkillIconData(index: PassiveSkill, lvl: number): SkillIconData {
	return passiveSkillInfos[index] && passiveSkillInfos[index].lvls[lvl ? lvl - 1 : lvl] ? {
		type: 'buff',
		index,
		lvl,
	} : {
		type: 'buff',
		index: PassiveSkill.None,
		lvl: 0,
	};
}

export class SkillIcon extends Container {
	public static get(data: SkillIconData | null) {
		const symbol = SkillIcon._pool.get();
		symbol.update(data);
		return symbol;

	}
	public static pool(symbol: SkillIcon) {
		symbol.reset();
		SkillIcon._pool.pool(symbol);
	}

	protected static _pool: Pool<SkillIcon> = new Pool(SkillIcon);

	public data: SkillIconData | null = null;

	public lvlContainer = new Container();
	public iconContainer = new Container();
	public bgContainer = new Container();

	constructor() {
		super();
		this.lvlContainer.x = 10;
		this.lvlContainer.y = -13;
		this.addChild(
			this.bgContainer,
			this.iconContainer,
			this.lvlContainer,
		);
	}

	public update(data: SkillIconData | null) {
		if (!data) {
			this.reset();
			return;
		}
		let bgDirty = false;
		let lvlDirty = false;
		let iconDirty = false;
		if (this.data) {
			if (data.type !== this.data.type) {
				this.disposeBg();
				iconDirty = true;
				bgDirty = true;
			} else if (data.index !== this.data.index) {
				iconDirty = true;
			}
			if (iconDirty) {
				this.disposeIcon();
			}
			if (data.lvl !== this.data.lvl) {
				this.disposeLvl();
				lvlDirty = true;
			}
		} else {
			bgDirty = lvlDirty = iconDirty = true;
		}
		if (data.type === 'normal') {
			if (iconDirty) {
				this.iconContainer.addChild(getNormalSkillSprite(data.index as NormalSkillAsset));
			}
		} else if (data.type === 'special') {
			if (iconDirty) {
				this.iconContainer.addChild(getSpecialSprite(data.index as SpecialSkillAsset));
			}
			if (bgDirty) {
				this.bgContainer.addChild(getSkillBg(SkillBgAsset.Special));
			}
		} else if (data.type === 'buff') {
			if (iconDirty) {
				this.iconContainer.addChild(getPassiveSprite(data.index as PassiveSkill));
			}
			if (bgDirty) {
				this.bgContainer.addChild(getSkillBg(SkillBgAsset.Buff));
			}
		} else if (data.type === 'gene') {
			if (iconDirty) {
				this.iconContainer.addChild(getPassiveSprite(data.index as PassiveSkill));
			}
			if (bgDirty) {
				this.bgContainer.addChild(getSkillBg(SkillBgAsset.Gene));
			}
		} else if (data.type === 'recess') {
			if (iconDirty) {
				const s = getPassiveSprite(data.index as PassiveSkill);
				s.tint = 0x666666;
				this.iconContainer.addChild(s);
			}
			if (bgDirty) {
				this.bgContainer.addChild(getSkillBg(SkillBgAsset.RGene));
			}
		} else if (data.type === 'passive') {
			if (iconDirty) {
				this.iconContainer.addChild(getPassiveSprite(data.index as PassiveSkill));
			}
			if (bgDirty) {
				this.bgContainer.addChild(getSkillBg(SkillBgAsset.Passive));
			}
		}
		if (lvlDirty && data.lvl !== 0) {
			this.lvlContainer.addChild(getSkillLvl(data.lvl));
		}
		this.data = data;

	}

	public reset() {
		this.disposeBg();
		this.disposeIcon();
		this.disposeLvl();
		this.data = null;
	}

	protected disposeBg() {
		if (this.bgContainer.children[0]) {
			disposeSkillBg(this.bgContainer.children[0]);
			this.bgContainer.removeChildren();
		}
	}
	protected disposeIcon() {
		if (this.iconContainer.children[0]) {
			disposeSkillSprite(this.iconContainer.children[0]);
			this.iconContainer.removeChildren();
		}
	}
	protected disposeLvl() {
		if (this.lvlContainer.children[0]) {
			disposeSkillSprite(this.lvlContainer.children[0]);
			this.lvlContainer.removeChildren();
		}
	}
}
