




import PixiCanvasComponent from '@/components/canvas/PixiCanvasComponent.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { allTextureLoaded, onClick } from '@/client/factory/helper';
import { DecoName } from '@/game/infos/decorativeInfos';
import {
  Decoratives,
  DecoSymbol,
} from '../ui/mainMenu/tools/decoEditor/decorative';

declare const sub: any;
@Component({
  components: {},
})
export default class DecoItemCanvasComponent extends PixiCanvasComponent {
  @Prop({ default: '' }) public decoName!: DecoName;
  //   public decoName: DecoName = 'Katana1';
  public symbol: DecoSymbol | null = null;

  @Watch('decoName', { immediate: true })
  public decoNameChanged(newVal: DecoName | '', oldVal: DecoName | '') {
    if (oldVal && this.symbol) {
      this.stage.removeChildren();
      Decoratives.pool(oldVal, this.symbol);
      this.symbol = null;
    }
    if (newVal) {
      this.symbol = Decoratives.getDeco(this.decoName, false);
      this.stage.addChild(this.symbol);

      allTextureLoaded(this.symbol).then(() => {
        this.reposition();
        this.rerenderAndStop();
      });
    }
  }

  public reposition() {
    if (this.symbol) {
      this.symbol.angle = 0;
      this.symbol.scale.set(1);
      this.symbol.x = this.symbol.y = 0;
      const bound = this.symbol.getLocalBounds();
      const length = Math.max(bound.width, bound.height);
      const scale = Math.min(100 / length, 1);
      this.symbol.scale.set(scale);
      const w = this.width - bound.width * scale;
      const h = this.height - bound.height * scale;
      this.symbol.x = -bound.x * scale + w / 2;
      this.symbol.y = -bound.y * scale + h / 2;
    }
  }

  public mounted() {
    this.width = this.height = 110;
    this.resize();
  }

  public _beforeDestroy() {
    if (this.symbol) {
      Decoratives.pool(this.decoName, this.symbol);
    }
  }
}
