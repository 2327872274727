import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsBatDart } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { BurningEffect, FrozenEffect, ParalyzedEffect } from '../hazardMixin';
import { MiscSymbol } from './miscSymbol';
import { PlayerMode } from '@/game/infos/enums';

// const info = specialSkillInfos[SpecialSkill.SwimmingFang];
export class SsBatDartSymbol extends Container {
}
export class SsBatDartSymbolController implements MiscSymbol {

	public static get() {
		return SsBatDartSymbolController._pool.get();
	}

	private static _pool: Pool<SsBatDartSymbolController> = new Pool(SsBatDartSymbolController);


	public area: Graphics = new Graphics();
	public data: MiscDataSsBatDart | null = null;
	public isSameTeam = false;

	public bounceX = 0;
	public bounceY = 0;

	public symbol = Factory.get(SsBatDartSymbol);
	public sprite = this.symbol.children[0] as Sprite;
	public paralyzedEffect: ParalyzedEffect | null = null;
	public burningEffect: BurningEffect | null = null;
	public frozenEffect: FrozenEffect | null = null;

	public dispose(room: RoomControl) {
		this.data = null;
		this.area.clear();
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}

		this.removeParalizedEffect();

		SsBatDartSymbolController._pool.pool(this);


	}

	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataSsBatDart;
		if (!this.data || data.ac !== this.data.ac) {
			this.isSameTeam = data.tid === state.tid || state.playerMode === PlayerMode.PvE && data.tid === '_pve';
			this.sprite.tint = this.isSameTeam ?  0x55ff99 : 0xffffff;
		}
		if (data.ac) {
			if (data.pr) {
				if (!this.paralyzedEffect) {
					this.paralyzedEffect = Factory.get(ParalyzedEffect);
					this.symbol.addChild(this.paralyzedEffect);
					this.paralyzedEffect.scale.set(0.5);
					this.paralyzedEffect.x = -8;
				}
			} else {
				this.removeParalizedEffect();
			}
			if (data.ff > 0) {
				if (!this.burningEffect) {
					this.burningEffect = Factory.get(BurningEffect);
					this.symbol.addChild(this.burningEffect);
					this.burningEffect.scale.set(0.4);
					this.burningEffect.angle = -90;
					this.burningEffect.x = -8;
				}
			} else {
				this.removeBurningEffect();
			}
			if (data.ff < 0) {
				if (!this.frozenEffect) {
					this.frozenEffect = Factory.get(FrozenEffect);
					this.symbol.addChild(this.frozenEffect);
					this.frozenEffect.scale.set(0.2);
					this.frozenEffect.x = -8;
				}
			} else {
				this.removeFrozenEffect();
			}

			if (!this.data || !this.data.ac) {
				room.playerBottomContainer.addChild(this.symbol);
				if (this.area.parent) {
					this.area.parent.removeChild(this.area);
				}
				new SoundEfx('dartMulti').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
			}
			if (!data.dd) {
				this.symbol.x = data.x;
				this.symbol.y = data.y;
				this.symbol.angle = data.d;
			} else {

				if (!this.data || !this.data.dd) {
					this.bounceX = Math.random() * 2 - 1;
					this.bounceY = 5;
					room.playerTopContainer.addChild(this.symbol);
				}
				this.symbol.x += this.bounceX;
				this.symbol.y -= this.bounceY;
				this.bounceY -= 0.5;
				this.symbol.angle += this.bounceX > 0 ? 20 : -20;
			}
		} else {
			const lineColor = this.isSameTeam ? 0xFFFF00 : 0xFF3399;
			this.area.clear();
			this.area.lineStyle(2, lineColor, 0.5);
			this.area.moveTo(data.x, data.y);
			const end = Rotate.move(Rotate.d2r(data.d), 750);
			this.area.lineTo(data.x + end.x, data.y + end.y);
		}


		this.data = data;
	}
	public show(room: RoomControl) {
		if (this.data) {
			if (this.data.ac) {
				room.playerBottomContainer.addChild(this.symbol);
			} else {
				room.playerBottomContainer.addChild(this.area);
			}
		}
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
	}

	protected removeParalizedEffect() {
		if (this.paralyzedEffect) {
			this.paralyzedEffect.x = 0;
			this.paralyzedEffect.y = 0;
			this.paralyzedEffect.scale.set(1);
			this.symbol.removeChild(this.paralyzedEffect);
			Factory.pool(ParalyzedEffect, this.paralyzedEffect);
			this.paralyzedEffect = null;
		}
	}
	protected removeBurningEffect() {
		if (this.burningEffect) {
			this.burningEffect.x = 0;
			this.burningEffect.y = 0;
			this.burningEffect.angle = 0;
			this.burningEffect.scale.set(1);
			this.symbol.removeChild(this.burningEffect);
			Factory.pool(BurningEffect, this.burningEffect);
			this.burningEffect = null;
		}
	}
	protected removeFrozenEffect() {
		if (this.frozenEffect) {
			this.frozenEffect.x = 0;
			this.frozenEffect.y = 0;
			this.frozenEffect.scale.set(1);
			this.symbol.removeChild(this.frozenEffect);
			Factory.pool(FrozenEffect, this.frozenEffect);
			this.frozenEffect = null;
		}
	}

}
