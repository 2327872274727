
























import FishButtonCanvasComponent from '@/components/canvas/FishButtonCanvasComponent.vue';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import settingx from '@/store/modules/settingx';
import userx from '@/store/modules/userx';
import { floorToFixed, roundToFixed } from '@/util/number';
import { Queuer } from '@/util/queuer';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { generateLargeFishImage } from '../mainMenu/tools/largeFishImageGenerator';
import { decodeDecoSet } from '@/game/infos/decorativeInfos';
import { wait } from '@/util/wait';

@Component({
	components: {
		FishButtonCanvasComponent,
	},
})
export default class FishButtonTransparent extends Vue {
	public get mouseMode() {
		return settingx.now.control === 'mouse';
	}
	public get name() {
		return fishInfos[this.fishType].name;
	}
	public get sumcheck() {
		return (
			this.fishType.toString() +
			this.skinGroup.toString() +
			this.decoSet
		);
	}
	public get imgStyle() {
		return {
			transformOrigin: 'center',
			transform: `scale(${fishInfos[this.fishType].stat.size * 1.25})`,
		};
	}

	@Prop({ default: FishType.Megalodon }) public fishType!: FishType;
	@Prop({ default: '' }) public decoSet!: string;
	@Prop({ default: 0 }) public skinGroup!: number;
	@Prop({ default: false }) public disabled!: boolean;
	@Prop({ default: false }) public active!: boolean;
	@Prop({ default: true }) public showDeco!: boolean;

	public imgSrc = '';

	public touchingStart = 0;
	public touchX = 0;
	public touchY = 0;

	@Watch('sumcheck', { immediate: true })
	public async sumcheckChange(newVal: string) {
		let id = (this.fishType).toString();
		while (id.length < 4) {
			id = '0' + id;
		}
		this.imgSrc = `/images/skins/skin0/skinFull${id}.png`;
		await Queuer.queue('fishImage', async () => {
			await wait(500);
			this.imgSrc = await generateLargeFishImage(
				this.fishType,
				this.skinGroup,
				decodeDecoSet(this.decoSet),
			);
		});
	}
	public onClick(e) {
		if (settingx.now.control === 'touch') {
			e.preventDefault();
			return;
		}
		if (!this.disabled) {
			this.$emit('click', e);
		}
	}

	public onMouseOver(e: any) {
		if (settingx.now.control === 'touch') {
			e.preventDefault();
			return;
		}
		this.$emit('mouseover', e);
	}
	public async onTouchStart(e: any) {
		this.$emit('mouseover', e);
		this.touchX = e.changedTouches[0]?.screenX;
		this.touchY = e.changedTouches[0]?.screenY;
		this.touchingStart = Date.now();

	}
	public async onTouchEnd(e: any) {
		this.$emit('mouseleave', e);
		if (
			Date.now() - this.touchingStart < 500 &&
			this.touchX === e.changedTouches[0]?.screenX &&
			this.touchY === e.changedTouches[0]?.screenY
		) {
			this.$emit('click', e);
		}

	}
}
