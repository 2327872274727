



































import {
  generateBuffSkillIconData,
  generateNormalSkillIconData,
  generateSpecialSkillIconData,
  SkillIconData,
} from '@/client/factory/assets/skillIcon';
import { fishInfos } from '@/game/infos/fishInfos';
import { PassiveSkill } from '@/game/infos/skills';
import gamex, { BuffData } from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishSkillComponent from '../../mainMenu/tools/fishSelector/FishSkillComponent.vue';
import ScrollContainer from '../../ScrollContainer.vue';

@Component({
  components: {
    FishSkillComponent,
    ScrollContainer,
  },
})
export default class UtilityInfoComponent extends Vue {
  public get fishInfo() {
    const myData = gamex.currentGameState;
    return fishInfos[myData['life' + myData.life]] || fishInfos[0];
  }
  public get hasActiveSkill() {
    return this.fishInfo.skill1 || this.fishInfo.skill2;
  }

  public get normalSkillIconData() {
    return generateNormalSkillIconData(this.fishInfo.skill1);
  }
  public get specialSkillIconData() {
    return generateSpecialSkillIconData(this.fishInfo.skill2);
  }
  public hasBuffs = false;
  public buffSkillIconData: SkillIconData[] = [];
  public get buffs() {
    return gamex.buffs;
  }
  @Watch('buffs', { immediate: true })
  public buffsChanged(newValue: BuffData[], oldValue: BuffData[]) {
    if (newValue !== oldValue) {
      this.hasBuffs = newValue.length > 0;
      this.buffSkillIconData = newValue.map((s) =>
        generateBuffSkillIconData(s.type, s.lvl),
      );
    }
  }

  public mounted() {
    this.$el.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
  public beforeDestroy() {
    this.$el.removeEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
}
