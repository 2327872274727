























































































































































































































































































import SkillIconCanvasComponent from '@/components/canvas/SkillIconCanvasComponent.vue';
import ScrollContainer from '@/components/ui/ScrollContainer.vue';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { fishUnlockRequirements } from '@/game/infos/fishUnlockRequirements';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishInfoTab from './FishInfoTab.vue';
import FishList from './FishList.vue';
import FishUnlockStepsComponent from './FishUnlockStepsComponent.vue';
import StateButton from '@/components/inputs/StateButton.vue';
import { EditState } from '@/store/models.def';
import { Global } from '@/store/globalz';
@Component({
	components: {
		FishUnlockStepsComponent,
		FishList,
		ScrollContainer,
		SkillIconCanvasComponent,
		FishInfoTab,
		StateButton,
	},
})
export default class FishSelector extends Vue {
	public unlockState: EditState = 'active';
	public get unlockLabel() {
		const labels = {
			active: 'Unlock Now',
			sending: 'Unlocking',
			done: 'Unlocked',
		};
		return labels[this.unlockState];
	}
	public get toRead() {
		return this.fishTab === 'unlock' && this.selectedHasCompleted.length > 0;
	}
	public get fishInfo() {
		const value = fishInfos[this.selected];
		Global.fishSelectorControl.size = value.stat.size;
		return value;
	}

	public get selectedPendingUnlock() {
		return userx.pendingFishUnlocks[this.selected];
	}
	public get selectedHasCompleted() {
		return userx.userSettings.completedChallenges.filter(
			(s) => s.indexOf(`F-${this.selected}-`) === 0,
		);
	}
	public get hasUnlockIndicator() {
		return this.selectedHasCompleted.length > 0 || this.selectedPendingUnlock;
	}

	public get selectedUnlocked() {
		const value = userx.fishUnlocked[this.selected];
		Global.fishSelectorControl.locked = !value;
		return value;
	}
	public get selectedMastered() {
		return userx.masterUnlocked[this.selected];
	}

	public get selectedRequirement() {
		return fishUnlockRequirements[this.selected] || null;
	}

	@Prop({ default: 0 }) public defaultFish!: FishType;
	public fishTab: 'info' | 'stat' | 'unlock' = 'stat';
	public showInfo = true;
	public selected = this.defaultFish || 1;

	@Watch('toRead', { immediate: true })
	public toReadChanged(newVal: string) {
		setTimeout(() => {
			userx.readUnlock(this.selectedHasCompleted);
		}, 500);
	}

	public deactivated() {
		setTimeout(() => {
			this.fishTab = 'info';
		}, 500);
	}

	public onChangeMode() {
		this.showInfo = !this.showInfo;
	}

	public onConfirm() {
		// if (this.selectedUnlocked) {
		//   userx.updateHeroes({ life: this.life, fishType: this.selected });
		// }
	}

	public async onUnlock() {
		this.unlockState = 'sending';
		try {
			await userx.unlockFish(this.selected);
			this.unlockState = 'done';
		} catch (error) {
			this.unlockState = 'active';
			this.$root.$emit('error', (error as any).message);
		}
	}

	public onSelect(type: FishType) {
		if (this.selected === type) {
			this.onConfirm();
		} else {
			this.selected = type;
			Global.fishSelectorControl.fishType = this.selected;
			this.unlockState = 'active';
		}
	}

	public selectFishTab(tab: 'info' | 'stat' | 'unlock') {
		this.fishTab = tab;
	}

	public activated() {
		this.selected = this.defaultFish;
		Global.fishSelectorControl.fishType = this.selected;
		Global.fishSelectorControl.show();
	}
}
