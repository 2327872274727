import { AbstractTween } from './AbstractTween';
import { Ease } from './Ease';

export interface TweenOptions {
	useTicks?: boolean,
	ignoreGlobalPause?: boolean,
	loop?: number,
	reversed?: boolean,
	bounce?: boolean,
	timeScale?: number,
	pluginData?: any,
	paused?: boolean,
	position?: number,
	override?: boolean,
	autoStart?: boolean, // personal added
}

export class Tween extends AbstractTween {
	// tiny api (primarily for tool output):
	// p.w = p.wait;
	// p.t = p.to;
	// p.c = p.call;
	// p.s = p.set;

	public set paused(value: boolean) {
		if (this._autoStart) {
			Tween._register(this, value);
		}
		super.paused = value;
	}

	/**
	 * Tweens properties for a single target. Methods can be chained to create complex animation sequences:
	 *
	 * <h4>Example</h4>
	 *
	 * createjs.Tween.get(target)
	 * 	.wait(500)
	 * 	.to({alpha:0, visible:false}, 1000)
	 * 	.call(handleComplete);
	 *
	 * Multiple tweens can share a target, however if they affect the same properties there could be unexpected
	 * behaviour. To stop all tweens on an object, use {{#crossLink "Tween/removeTweens"}}{{/crossLink}} or pass `override:true`
	 * in the props argument.
	 *
	 * 	createjs.Tween.get(target, {override:true}).to({x:100});
	 *
	 * Subscribe to the {{#crossLink "Tween/change:event"}}{{/crossLink}} event to be notified when the tween position changes.
	 *
	 * 	createjs.Tween.get(target, {override:true}).to({x:100}).addEventListener("change", handleChange);
	 * 	function handleChange(event) {
	 * 		// The tween changed.
	 * 	}
	 *
	 * See the {{#crossLink "Tween/get"}}{{/crossLink}} method also.
	 * @class Tween
	 * @param {Object} target The target object that will have its properties tweened.
	 * @param {Object} [props] The configuration properties to apply to this instance (ex. `{loop:-1, paused:true}`).
	 * Supported props are listed below. These props are set on the corresponding instance properties except where
	 * specified.
	 * @param {boolean} [props.useTicks=false]  See the {{#crossLink "AbstractTween/useTicks:property"}}{{/crossLink}} property for more information.
	 * @param {boolean} [props.ignoreGlobalPause=false] See the {{#crossLink "AbstractTween/ignoreGlobalPause:property"}}{{/crossLink}} for more information.
	 * @param {number|boolean} [props.loop=0] See the {{#crossLink "AbstractTween/loop:property"}}{{/crossLink}} for more information.
	 * @param {boolean} [props.reversed=false] See the {{#crossLink "AbstractTween/reversed:property"}}{{/crossLink}} for more information.
	 * @param {boolean} [props.bounce=false] See the {{#crossLink "AbstractTween/bounce:property"}}{{/crossLink}} for more information.
	 * @param {number} [props.timeScale=1] See the {{#crossLink "AbstractTween/timeScale:property"}}{{/crossLink}} for more information.
	 * @param {object} [props.pluginData] See the {{#crossLink "Tween/pluginData:property"}}{{/crossLink}} for more information.
	 * @param {boolean} [props.paused=false] See the {{#crossLink "AbstractTween/paused:property"}}{{/crossLink}} for more information.
	 * @param {number} [props.position=0] The initial position for this tween. See {{#crossLink "AbstractTween/position:property"}}{{/crossLink}}
	 * @param {Function} [props.onChange] Adds the specified function as a listener to the {{#crossLink "AbstractTween/change:event"}}{{/crossLink}} event
	 * @param {Function} [props.onComplete] Adds the specified function as a listener to the {{#crossLink "AbstractTween/complete:event"}}{{/crossLink}} event
	 * @param {boolean} [props.override=false] Removes all existing tweens for the target when set to `true`.
	 * </UL>
	 * @extends AbstractTween
	 * @constructor
	 */

	// static properties

	/**
	 * Constant returned by plugins to tell the tween not to use default assignment.
	 * @property IGNORE
	 * @type Object
	 * @static
	 */
	public static IGNORE = {};


	// static methods
	/**
	 * Returns a new tween instance. This is functionally identical to using `new Tween(...)`, but may look cleaner
	 * with the chained syntax of TweenJS.
	 * <h4>Example</h4>
	 *
	 * var tween = createjs.Tween.get(target).to({x:100}, 500);
	 * // equivalent to:
	 * var tween = new createjs.Tween(target).to({x:100}, 500);
	 *
	 * @method get
	 * @param {Object} target The target object that will have its properties tweened.
	 * @param {Object} [props] The configuration properties to apply to this instance (ex. `{loop:-1, paused:true}`).
	 * Supported props are listed below. These props are set on the corresponding instance properties except where
	 * specified.
	 * @param {boolean} [props.useTicks=false]  See the {{#crossLink "AbstractTween/useTicks:property"}}{{/crossLink}} property for more information.
	 * @param {boolean} [props.ignoreGlobalPause=false] See the {{#crossLink "AbstractTween/ignoreGlobalPause:property"}}{{/crossLink}} for more information.
	 * @param {number|boolean} [props.loop=0] See the {{#crossLink "AbstractTween/loop:property"}}{{/crossLink}} for more information.
	 * @param {boolean} [props.reversed=false] See the {{#crossLink "AbstractTween/reversed:property"}}{{/crossLink}} for more information.
	 * @param {boolean} [props.bounce=false] See the {{#crossLink "AbstractTween/bounce:property"}}{{/crossLink}} for more information.
	 * @param {number} [props.timeScale=1] See the {{#crossLink "AbstractTween/timeScale:property"}}{{/crossLink}} for more information.
	 * @param {object} [props.pluginData] See the {{#crossLink "Tween/pluginData:property"}}{{/crossLink}} for more information.
	 * @param {boolean} [props.paused=false] See the {{#crossLink "AbstractTween/paused:property"}}{{/crossLink}} for more information.
	 * @param {number} [props.position=0] The initial position for this tween. See {{#crossLink "AbstractTween/position:property"}}{{/crossLink}}
	 * @param {Function} [props.onChange] Adds the specified function as a listener to the {{#crossLink "AbstractTween/change:event"}}{{/crossLink}} event
	 * @param {Function} [props.onComplete] Adds the specified function as a listener to the {{#crossLink "AbstractTween/complete:event"}}{{/crossLink}} event
	 * @param {boolean} [props.override=false] Removes all existing tweens for the target when set to `true`.
	 * @return {Tween} A reference to the created tween.
	 * @static
	 */

	public static get(target: any, props?: TweenOptions) {
		return new Tween(target, props);
	}
	/**
	 * Advances all tweens. This typically uses the {{#crossLink "Ticker"}}{{/crossLink}} class, but you can call it
	 * manually if you prefer to use your own "heartbeat" implementation.
	 * @method tick
	 * @param {Number} delta The change in time in milliseconds since the last tick. Required unless all tweens have
	 * `useTicks` set to true.
	 * @param {Boolean} paused Indicates whether a global pause is in effect. Tweens with {{#crossLink "Tween/ignoreGlobalPause:property"}}{{/crossLink}}
	 * will ignore this, but all others will pause if this is `true`.
	 * @static
	 */
	public static tick(delta: number, paused: boolean) {
		let tween = Tween._tweenHead;
		const t = Tween._inTick = Date.now();
		while (tween) {
			const next = tween._next;
			const status = tween._status;
			tween._lastTick = t;
			if (status === 1) { tween._status = 0; } else if (status === -1) { Tween._delist(tween); } else if ((paused && !tween.ignoreGlobalPause) || tween._paused) { /* paused */ } else { tween.advance(tween.useTicks ? 1 : delta); }
			tween = next;
		}
		Tween._inTick = 0;
	}
	/**
	 * Handle events that result from Tween being used as an event handler. This is included to allow Tween to handle
	 * {{#crossLink "Ticker/tick:event"}}{{/crossLink}} events from the createjs {{#crossLink "Ticker"}}{{/crossLink}}.
	 * No other events are handled in Tween.
	 * @method handleEvent
	 * @param {Object} event An event object passed in by the {{#crossLink "EventDispatcher"}}{{/crossLink}}. Will
	 * usually be of type "tick".
	 * @private
	 * @static
	 * @since 0.4.2
	 */
	public static handleEvent(event: any) {
		if (event.type === 'tick') {
			this.tick(event.delta, event.paused);
		}
	}
	/**
	 * Removes all existing tweens for a target. This is called automatically by new tweens if the `override`
	 * property is `true`.
	 * @method removeTweens
	 * @param {Object} target The target object to remove existing tweens from.
	 * @static
	 */
	public static removeTweens(target: any) {
		if (!target.tweenjs_count) { return; }
		let tween = Tween._tweenHead;
		while (tween) {
			const next = tween._next;
			if (tween.target === target) { Tween._register(tween, true); }
			tween = next;
		}
		target.tweenjs_count = 0;
	}
	/**
	 * Stop and remove all existing tweens.
	 * @method removeAllTweens
	 * @static
	 * @since 0.4.1
	 */
	public static removeAllTweens() {
		let tween = Tween._tweenHead;
		while (tween) {
			const next = tween._next;
			tween._paused = true;
			if (tween.target) { tween.target.tweenjs_count = 0; }
			tween._next = tween._prev = null;
			tween = next;
		}
		Tween._tweenHead = Tween._tweenTail = null;
	}
	/**
	 * Indicates whether there are any active tweens on the target object (if specified) or in general.
	 * @method hasActiveTweens
	 * @param {Object} [target] The target to check for active tweens. If not specified, the return value will indicate
	 * if there are any active tweens on any target.
	 * @return {Boolean} Indicates if there are active tweens.
	 * @static
	 */
	public static hasActiveTweens(target: any) {
		if (target) { return !!target.tweenjs_count; }
		return !!Tween._tweenHead;
	}
	/**
	 * @property _tweens
	 * @type Array[Tween]
	 * @static
	 * @protected
	 */
	protected static _tweens: Tween[] = [];

	/**
	 * @property _plugins
	 * @type Object
	 * @static
	 * @protected
	 */
	protected static _plugins: any[] | null = null;

	/**
	 * @property _tweenHead
	 * @type Tween
	 * @static
	 * @protected
	 */
	protected static _tweenHead: Tween | null = null;

	/**
	 * @property _tweenTail
	 * @type Tween
	 * @static
	 * @protected
	 */
	protected static _tweenTail: Tween | null = null;

	/**
	 * 0 if not in tick, otherwise a tick ID (currently just a timestamp).
	 * @property _inTick
	 * @type Number
	 * @static
	 * @protected
	 */
	protected static _inTick = 0;
	/**
	 * Registers or unregisters a tween with the ticking system.
	 * @method _register
	 * @param {Tween} tween The tween instance to register or unregister.
	 * @param {Boolean} paused If `false`, the tween is registered. If `true` the tween is unregistered.
	 * @static
	 * @protected
	 */
	protected static _register(tween: Tween, paused: boolean) {
		const target = tween.target;
		if (!paused && tween._paused) {
			// TODO: this approach might fail if a dev is using sealed objects
			if (target) { target.tweenjs_count = target.tweenjs_count ? target.tweenjs_count + 1 : 1; }
			const tail = Tween._tweenTail;
			if (!tail) { Tween._tweenHead = Tween._tweenTail = tween; } else {
				Tween._tweenTail = tail._next = tween;
				tween._prev = tail;
			}
			tween._status = Tween._inTick ? 1 : 0;
			// if (!Tween._inited && createjs.Ticker) { createjs.Ticker.addEventListener('tick', Tween); Tween._inited = true; }
		} else if (paused && !tween._paused) {
			if (target) { target.tweenjs_count--; }
			// tick handles delist if we're in a tick stack and the tween hasn't advanced yet:
			if (!Tween._inTick || tween._lastTick === Tween._inTick) { Tween._delist(tween); }
			tween._status = -1;
		}
		tween._paused = paused;
	}

	// private static _inited = false;

	/**
	 * Installs a plugin, which can modify how certain properties are handled when tweened. See the {{#crossLink "SamplePlugin"}}{{/crossLink}}
	 * for an example of how to write TweenJS plugins. Plugins should generally be installed via their own `install` method, in order to provide
	 * the plugin with an opportunity to configure itself.
	 * @method _installPlugin
	 * @param {Object} plugin The plugin to install
	 * @static
	 * @protected
	 */
	private static _installPlugin(plugin: any) {
		const priority = (plugin.priority = plugin.priority || 0);
		const arr = (Tween._plugins = Tween._plugins || []);
		for (let i = 0, l = arr.length; i < l; i++) {
			if (priority < arr[i].priority) {
				arr.splice(i, 0, plugin);
				break;
			}
		}
	}
	private static _delist(tween: Tween) {
		const next = tween._next;
		const prev = tween._prev;
		if (next) { next._prev = prev; } else { Tween._tweenTail = prev; } // was tail
		if (prev) { prev._next = next; } else { Tween._tweenHead = next; } // was head.
		tween._next = tween._prev = null;
	}


	// public properties:

	/**
	 * Allows you to specify data that will be used by installed plugins. Each plugin uses this differently, but in general
	 * you specify data by assigning it to a property of `pluginData` with the same name as the plugin.
	 * Note that in many cases, this data is used as soon as the plugin initializes itself for the tween.
	 * As such, this data should be set before the first `to` call in most cases.
	 * @example
	 * myTween.pluginData.SmartRotation = data;
	 *
	 * Most plugins also support a property to disable them for a specific tween. This is typically the plugin name followed by "_disabled".
	 * @example
	 * myTween.pluginData.SmartRotation_disabled = true;
	 *
	 * Some plugins also store working data in this object, usually in a property named `_PluginClassName`.
	 * See the documentation for individual plugins for more details.
	 * @property pluginData
	 * @type {Object}
	 */
	public pluginData: any = null;

	/**
	 * The target of this tween. This is the object on which the tweened properties will be changed.
	 * @property target
	 * @type {Object}
	 * @readonly
	 */
	public target: any;

	/**
	 * Indicates the tween's current position is within a passive wait.
	 * @property passive
	 * @type {Boolean}
	 * @default false
	 * @readonly
	 */
	public passive = false;

	public step: TweenStep | null = null;


	// private properties:

	/**
	 * @property _stepHead
	 * @type {TweenStep}
	 * @protected
	 */
	protected _stepHead = new TweenStep(null, 0, 0, {}, null, true);

	/**
	 * @property _stepTail
	 * @type {TweenStep}
	 * @protected
	 */
	protected _stepTail = this._stepHead;

	/**
	 * The position within the current step. Used by MovieClip.
	 * @property _stepPosition
	 * @type {Number}
	 * @default 0
	 * @protected
	 */
	protected _stepPosition = 0;

	/**
	 * @property _actionHead
	 * @type {TweenAction}
	 * @protected
	 */
	protected _actionHead: TweenAction | null = null;

	/**
	 * @property _actionTail
	 * @type {TweenAction}
	 * @protected
	 */
	protected _actionTail: TweenAction | null = null;

	/**
	 * Plugins added to this tween instance.
	 * @property _plugins
	 * @type Array[Object]
	 * @default null
	 * @protected
	 */
	protected _plugins: any[] | null = null;

	/**
	 * Hash for quickly looking up added plugins. Null until a plugin is added.
	 * @property _plugins
	 * @type Object
	 * @default null
	 * @protected
	 */
	protected _pluginIds: any = null;

	/**
	 * Used by plugins to inject new properties.
	 * @property _injected
	 * @type {Object}
	 * @default null
	 * @protected
	 */
	protected _injected: any = null;

	protected _autoStart = true;

	constructor(target: any, props: any) {
		super(props);
		this.target = target;
		if (props) {
			this.pluginData = props.pluginData;
			if (props.override) { Tween.removeTweens(target); }
			this._autoStart = props.autoStart === undefined ? true : props.autoStart || false;
		}
		if (!this.pluginData) { this.pluginData = {}; }

		this._init(props);
	}

	// events:

	// public methods:
	/**
	 * Adds a wait (essentially an empty tween).
	 * <h4>Example</h4>
	 *
	 * //This tween will wait 1s before alpha is faded to 0.
	 * createjs.Tween.get(target).wait(1000).to({alpha:0}, 1000);
	 *
	 * @method wait
	 * @param {Number} duration The duration of the wait in milliseconds (or in ticks if `useTicks` is true).
	 * @param {Boolean} [passive=false] Tween properties will not be updated during a passive wait. This
	 * is mostly useful for use with {{#crossLink "Timeline"}}{{/crossLink}} instances that contain multiple tweens
	 * affecting the same target at different times.
	 * @return {Tween} This tween instance (for chaining calls).
	 * @chainable
	 */
	public wait(duration: number, passive = false) {
		if (duration > 0) { this._addStep(+duration, this._stepTail.props, null, passive); }
		return this;
	}
	/**
	 * Adds a tween from the current values to the specified properties. Set duration to 0 to jump to these value.
	 * Numeric properties will be tweened from their current value in the tween to the target value. Non-numeric
	 * properties will be set at the end of the specified duration.
	 * <h4>Example</h4>
	 *
	 * createjs.Tween.get(target).to({alpha:0, visible:false}, 1000);
	 *
	 * @method to
	 * @param {Object} props An object specifying property target values for this tween (Ex. `{x:300}` would tween the x
	 * property of the target to 300).
	 * @param {Number} [duration=0] The duration of the tween in milliseconds (or in ticks if `useTicks` is true).
	 * @param {Function} [ease="linear"] The easing function to use for this tween. See the {{#crossLink "Ease"}}{{/crossLink}}
	 * class for a list of built-in ease functions.
	 * @return {Tween} This tween instance (for chaining calls).
	 * @chainable
	 */
	public to(props: any, duration = 0, ease = Ease.linear) {
		if (!duration) { duration = 0; }
		const step = this._addStep(+duration, null, ease);
		this._appendProps(props, step);
		return this;
	}
	/**
	 * Adds a label that can be used with {{#crossLink "Tween/gotoAndPlay"}}{{/crossLink}}/{{#crossLink "Tween/gotoAndStop"}}{{/crossLink}}
	 * at the current point in the tween. For example:
	 *
	 * 	var tween = createjs.Tween.get(foo)
	 * 					.to({x:100}, 1000)
	 * 					.label("myLabel")
	 * 					.to({x:200}, 1000);
	 * // ...
	 * tween.gotoAndPlay("myLabel"); // would play from 1000ms in.
	 *
	 * @method label
	 * @param {String} label The label name.
	 * @return {Tween} This tween instance (for chaining calls).
	 * @chainable
	 */
	public label(name: string) {
		this.addLabel(name, this.duration);
		return this;
	}
	/**
	 * Adds an action to call the specified function.
	 * <h4>Example</h4>
	 *
	 * 	//would call myFunction() after 1 second.
	 * 	createjs.Tween.get().wait(1000).call(myFunction);
	 *
	 * @method call
	 * @param {Function} callback The function to call.
	 * @param {Array} [params]. The parameters to call the function with. If this is omitted, then the function
	 * will be called with a single param pointing to this tween.
	 * @param {Object} [scope]. The scope to call the function in. If omitted, it will be called in the target's scope.
	 * @return {Tween} This tween instance (for chaining calls).
	 * @chainable
	 */
	public call(callback: () => void, params?: any[], scope?: any) {
		return this._addAction(scope || this.target, callback, params || [this]);
	}
	/**
	 * Adds an action to set the specified props on the specified target. If `target` is null, it will use this tween's
	 * target. Note that for properties on the target object, you should consider using a zero duration {{#crossLink "Tween/to"}}{{/crossLink}}
	 * operation instead so the values are registered as tweened props.
	 * <h4>Example</h4>
	 *
	 * myTween.wait(1000).set({visible:false}, foo);
	 *
	 * @method set
	 * @param {Object} props The properties to set (ex. `{visible:false}`).
	 * @param {Object} [target] The target to set the properties on. If omitted, they will be set on the tween's target.
	 * @return {Tween} This tween instance (for chaining calls).
	 * @chainable
	 */
	public set(props: any, target: any) {
		return this._addAction(target || this.target, this._set, [props]);
	}
	/**
	 * Adds an action to play (unpause) the specified tween. This enables you to sequence multiple tweens.
	 * <h4>Example</h4>
	 *
	 * myTween.to({x:100}, 500).play(otherTween);
	 *
	 * @method play
	 * @param {Tween} [tween] The tween to play. Defaults to this tween.
	 * @return {Tween} This tween instance (for chaining calls).
	 * @chainable
	 */
	public play(tween: Tween) {
		return this._addAction(tween || this, this._set, [{ paused: false }]);
	}
	/**
	 * Adds an action to pause the specified tween.
	 *
	 * 	myTween.pause(otherTween).to({alpha:1}, 1000).play(otherTween);
	 *
	 * Note that this executes at the end of a tween update, so the tween may advance beyond the time the pause
	 * action was inserted at. For example:
	 *
	 * myTween.to({foo:0}, 1000).pause().to({foo:1}, 1000);
	 *
	 * At 60fps the tween will advance by ~16ms per tick, if the tween above was at 999ms prior to the current tick, it
	 * will advance to 1015ms (15ms into the second "step") and then pause.
	 *
	 * @method pause
	 * @param {Tween} [tween] The tween to pause. Defaults to this tween.
	 * @return {Tween} This tween instance (for chaining calls)
	 * @chainable
	 */
	public pause(tween: Tween) {
		return this._addAction(tween || this, this._set, [{ paused: true }]);
	}

	/**
	 * Returns a string representation of this object.
	 * @method toString
	 * @return {String} a string representation of the instance.
	 */
	public toString() {
		return '[Tween]';
	}
	/**
	 * @method clone
	 * @protected
	 */
	public clone() {
		throw new Error(('Tween can not be cloned.'));
	}

	// private methods:
	/**
	 * Adds a plugin to this tween.
	 * @method _addPlugin
	 * @param {Object} plugin
	 * @protected
	 */
	protected _addPlugin(plugin: any) {
		const ids = this._pluginIds || (this._pluginIds = {});
		const id = plugin.ID;
		if (!id || ids[id]) { return; } // already added

		ids[id] = true;
		const plugins = this._plugins || (this._plugins = []);
		const priority = plugin.priority || 0;
		for (let i = 0, l = plugins.length; i < l; i++) {
			if (priority < plugins[i].priority) {
				plugins.splice(i, 0, plugin);
				return;
			}
		}
		plugins.push(plugin);
	}
	// Docced in AbstractTween
	protected _updatePosition(jump: boolean, end: boolean) {
		let step = this._stepHead.next;
		const t = this.position;
		const d = this.duration;
		if (this.target && step) {
			// find our new step index:
			let stepNext = step.next;
			while (stepNext && stepNext.t <= t) { step = stepNext; stepNext = step.next; }
			const ratio = end ? d === 0 ? 1 : t / d : (t - step.t) / step.d; // TODO: revisit this.
			this._updateTargetProps(step, ratio, end);
		}
		this._stepPosition = step ? t - step.t : 0;
	}
	/**
	 * @method _updateTargetProps
	 * @param {Object} step
	 * @param {Number} ratio
	 * @param {Boolean} end Indicates to plugins that the full tween has ended.
	 * @protected
	 */
	protected _updateTargetProps(step: TweenStep, ratio: number, end: boolean) {
		this.passive = !!step.passive;
		if (this.passive) { return; } // don't update props.

		let v, v0, v1, ease;
		const p0 = step.prev!.props;
		const p1 = step.props;
		ease = step.ease;
		if (ease) { ratio = ease(ratio, 0, 1, 1); }

		const plugins = this._plugins;
		proploop:
		for (const n in p0) {
			if (Object.prototype.hasOwnProperty.call(p0, n)) {
				v0 = p0[n];
				v1 = p1[n];

				// values are different & it is numeric then interpolate:
				if (v0 !== v1 && (typeof (v0) === 'number')) {
					v = v0 + (v1 - v0) * ratio;
				} else {
					v = ratio >= 1 ? v1 : v0;
				}

				if (plugins) {
					for (let i = 0, l = plugins.length; i < l; i++) {
						const value: any = plugins[i].change(this, step, n, v, ratio, end);
						if (value === Tween.IGNORE) { continue proploop; }
						if (value !== undefined) { v = value; }
					}
				}
				this.target[n] = v;
			}
		}

	}
	/**
	 * @method _runActionsRange
	 * @param {Number} startPos
	 * @param {Number} endPos
	 * @param {Boolean} includeStart
	 * @protected
	 */
	protected _runActionsRange(startPos: number, endPos: number, jump: boolean, includeStart: boolean): boolean {
		const rev = startPos > endPos;
		let action = rev ? this._actionTail : this._actionHead;
		let ePos = endPos, sPos = startPos;
		if (rev) { ePos = startPos; sPos = endPos; }
		const t = this.position;
		while (action) {
			const pos = action.t;
			if (pos === endPos || (pos > sPos && pos < ePos) || (includeStart && pos === startPos)) {
				action.funct.apply(action.scope, action.params);
				if (t !== this.position) { return true; }
			}
			action = rev ? action.prev : action.next;
		}
		return false;
	}
	/**
	 * @method _appendProps
	 * @param {Object} props
	 * @protected
	 */
	protected _appendProps(props: any, step: TweenStep, stepPlugins = false) {
		const initProps = this._stepHead.props;
		let plugins = Tween._plugins;
		const target = this.target;
		let i, value, initValue, inject;
		const oldStep = step.prev!;
		const oldProps = oldStep.props;
		const stepProps = step.props || (step.props = this._cloneProps(oldProps));
		const cleanProps: any = {}; // TODO: is there some way to avoid this additional object?

		for (const n in props) {
			if (!props.hasOwnProperty(n)) { continue; }
			cleanProps[n] = stepProps[n] = props[n];

			if (initProps[n] !== undefined) { continue; }

			initValue = undefined; // accessing missing properties on DOMElements when using CSSPlugin is INSANELY expensive, so we let the plugin take a first swing at it.
			if (plugins) {
				for (i = plugins.length - 1; i >= 0; i--) {
					value = plugins[i].init(this, n, initValue);
					if (value !== undefined) { initValue = value; }
					if (initValue === Tween.IGNORE) {
						delete (stepProps[n]);
						delete (cleanProps[n]);
						break;
					}
				}
			}

			if (initValue !== Tween.IGNORE) {
				if (initValue === undefined) { initValue = target[n]; }
				oldProps[n] = (initValue === undefined) ? null : initValue;
			}
		}

		for (const n in cleanProps) {
			if (Object.prototype.hasOwnProperty.call(cleanProps, n)) {
				value = props[n];

				// propagate old value to previous steps:
				let o = oldStep;
				let prev = o.prev;

				while (o && prev) {
					if (prev.props !== o.props) {
						if (prev.props[n] !== undefined) { break; } // already has a value, we're done.
						prev.props[n] = oldProps[n];
					}
					o = prev;
					prev = o.prev;
				}
			}
		}
		plugins = this._plugins;
		if (stepPlugins !== false && plugins) {
			for (i = plugins.length - 1; i >= 0; i--) {
				plugins[i].step(this, step, cleanProps);
			}
		}
		inject = this._injected;
		if (inject) {
			this._injected = null;
			this._appendProps(inject, step, false);
		}

		// added by Dan Zen 03/27/21 to provide for dynamically adjusted tweens
		this.step = step;
	}
	/**
	 * Used by plugins to inject properties onto the current step. Called from within `Plugin.step` calls.
	 * For example, a plugin dealing with color, could read a hex color, and inject red, green, and blue props into the tween.
	 * See the SamplePlugin for more info.
	 * @method _injectProp
	 * @param {String} name
	 * @param {Object} value
	 * @protected
	 */
	protected _injectProp(name: string, value: any) {
		const o = this._injected || (this._injected = {});
		o[name] = value;
	}
	/**
	 * @method _addStep
	 * @param {Number} duration
	 * @param {Object} props
	 * @param {Function} ease
	 * @param {Boolean} passive
	 * @protected
	 */
	protected _addStep(duration: number, props: any, ease: ((...n: number[]) => number) | null, passive = false) {
		const step = new TweenStep(this._stepTail, this.duration, duration, props, ease, passive);
		this.duration += duration;
		return this._stepTail = (this._stepTail.next = step);
	}
	/**
	 * @method _addAction
	 * @param {Object} scope
	 * @param {Function} funct
	 * @param {Array} params
	 * @protected
	 */
	protected _addAction(scope: any, funct: (arg: any) => void, params: any) {
		const action = new TweenAction(this._actionTail, this.duration, scope, funct, params);
		if (this._actionTail) { this._actionTail.next = action; } else { this._actionHead = action; }
		this._actionTail = action;
		return this;
	}
	/**
	 * @method _set
	 * @param {Object} props
	 * @protected
	 */
	protected _set(props: any) {
		for (const n in props) {
			if (Object.prototype.hasOwnProperty.call(props, n)) {
				(this as any)[n] = props[n];
			}
		}
	}
	/**
	 * @method _cloneProps
	 * @param {Object} props
	 * @protected
	 */
	protected _cloneProps(props: any) {
		const o: any = {};
		for (const n in props) {
			if (Object.prototype.hasOwnProperty.call(props, n)) {
				o[n] = props[n];
			}
		}
		return o;
	}
}

export class TweenStep {
	public next: TweenStep | null;
	public prev: TweenStep | null;
	public t: number;
	public d: number;
	public props: any;
	public ease: ((...n: number[]) => number) | null;
	public passive: boolean;
	public index: number;

	constructor(prev: TweenStep | null, t: number, d: number, props: any, ease: ((...n: number[]) => number) | null, passive: boolean) {

		this.next = null;
		this.prev = prev;
		this.t = t;
		this.d = d;
		this.props = props;
		this.ease = ease;
		this.passive = passive;
		this.index = prev ? prev.index + 1 : 0;
	}
}
export class TweenAction {
	public next: TweenAction | null;
	public prev: TweenAction | null;
	public t: number;
	public d: number;
	public scope: any;
	public funct: (arg: any) => void;
	public params: any;

	constructor(prev: TweenAction | null, t: number, scope: any, funct: (arg: any) => void, params: any) {

		this.next = null;
		this.prev = prev;
		this.t = t;
		this.d = 0;
		this.scope = scope;
		this.funct = funct;
		this.params = params;
	}
}
