import { allTextureLoaded } from '@/client/factory/helper';
import { MarchingSquares } from '@/util/marchingSquares';
import { Point } from '@/util/collide';
import { Decoratives } from './decorative';
import { DecoName } from '@/game/infos/decorativeInfos';
import { Scene } from 'pixi-animate';
import { hull } from '@/util/hull/hull';
import { Container } from 'pixi.js';
import { Queuer } from '@/util/queuer';

const app = new Scene(
	220,
	220,
	{
		antialias: false,
		transparent: true,
		forceCanvas: true,
	},
	true,
);

const stage = app.stage;

async function _generateOutlineFromContainer(deco: Container, id?: any): Promise<Point[]> {

	deco.x = 110;
	deco.y = 110;
	deco.angle = 0;
	deco.scale.set(0.5);
	const canvas = app.view;
	await allTextureLoaded(deco);
	stage.removeChildren();
	stage.addChild(deco);
	app.start();
	app.render();
	app.stop();
	const width = canvas.width,
		height = canvas.height,
		data4 = canvas.getContext('2d')!.getImageData(0, 0, width, height).data,  // Uint8ClampedArray
		len = width * height,
		data = new Uint8Array(len),
		points: Array<[number, number]> = [];
	for (let i = 1; i < len; ++i) {
		data[i] = data4[(i << 2) - 1];
		if (data[i]) {
			const x = i % width;
			const y = Math.floor(i / width);
			points.push([x, y]);
		}
	}
	const hulled = hull(points);
	// const outlinePoints = MarchingSquares.getBlobOutlinePoints(data, width, height);  // returns [x1,y1,x2,y2,x3,y3... etc.]
	// for (let i = 0; i < outlinePoints.length; i++) {
	// 	outlinePoints[i] -= 110;
	// 	outlinePoints[i] *= 2;
	// }
	// const result: Point[] = [];
	// for (let i = 0; i < outlinePoints.length; i += 2) {
	// 	result.push({ x: outlinePoints[i] + 3, y: outlinePoints[i + 1] });
	// }
	for (let i = 0; i < hulled.length - 1; i++) {
		hulled[i][0] -= 110;
		hulled[i][0] *= 2;
		hulled[i][1] -= 110;
		hulled[i][1] *= 2;
	}
	return hulled.map((p) => ({ x: p[0] + 2, y: p[1] }));
}

export async function generateOutlineFromContainer(deco: Container, id?: any) {
	// canvas.style.position = 'absolute';
	// canvas.style.top = '0';
	// document.body.appendChild(canvas);

	return new Promise(async (resolve: (outline: Point[]) => void, reject: (reason?: any) => void) => {
		await Queuer.queue('decoOutline', async () => {
			try {
				const points = await _generateOutlineFromContainer(deco, id);
				resolve(points);
			} catch (error) {
				reject(error);
			}
		});

	});

}

export async function generateOutline(decoName: DecoName) {
	const deco = Decoratives.getDeco(decoName, false);
	const result = await generateOutlineFromContainer(deco);
	Decoratives.pool(decoName, deco);
	return result;
}
