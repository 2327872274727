export interface Point {
    x: number,
    y: number,
}
export interface Rect {
    x: number,
    y: number,
    w: number,
    h: number,
}

export function pointify(p: Point) {
    return { x: p.x, y: p.y };
}

export class Rotate {
    public static diff(fromAngle: number, toAngle: number) {
        return (toAngle % 360 - fromAngle % 360 + 540) % 360 - 180;
    }

    public static r2d(radian: number) {
        return radian / Math.PI * 180;
    }
    public static d2r(degree: number) {
        return degree / 180 * Math.PI;
    }

    public static dist(x: number, y: number, x2: number, y2: number) {
        const a = x2 - x;
        const b = y2 - y;
        return Math.sqrt(a * a + b * b);
    }
    public static radian(x: number, y: number, tx: number, ty: number) {
        const dx = tx - x;
        const dy = ty - y;
        return Math.atan2(dy, dx);
    }
    public static degree(x: number, y: number, tx: number, ty: number) {
        const dx = tx - x;
        const dy = ty - y;
        return Math.atan2(dy, dx) / Math.PI * 180;
    }

    public static move(radian: number, distance: number): Point {
        const x = distance * Math.cos(radian);
        const y = distance * Math.sin(radian);
        return { x, y };
    }
    public static normal(radian: number): Point {
        const x = Math.cos(radian);
        const y = Math.sin(radian);
        return { x, y };
    }

    public static n2r(dx: number, dy: number) {
        return Math.atan2(dy, dx);
    }
    public static n2d(dx: number, dy: number) {
        return Math.atan2(dy, dx) / Math.PI * 180;
    }
    public static rotate(cx: number, cy: number, x: number, y: number, radian: number) {
        const cos = Math.cos(radian),
            sin = Math.sin(radian),
            nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
            ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
        return { x: nx, y: ny };
    }
}
