export interface Point {
    x: number,
    y: number,
}

export default class Collision {
    public static rectRect(x: number, y: number, w: number, h: number, x2: number, y2: number, w2: number, h2: number) {
        // 2d
        // add in a thing to detect rectMode CENTER
        if (x + w >= x2 &&    // r1 right edge past r2 left
            x <= x2 + w2 &&    // r1 left edge past r2 right
            y + h >= y2 &&    // r1 top edge past r2 bottom
            y <= y2 + h2) {    // r1 bottom edge past r2 top
            return true;
        }
        return false;
    }

    public static rectCircle(rx: number, ry: number, rw: number, rh: number, cx: number, cy: number, diameter: number) {
        // 2d
        // temporary variables to set edges for testing
        let testX = cx;
        let testY = cy;

        // which edge is closest?
        if (cx < rx) {
            testX = rx;       // left edge
        } else if (cx > rx + rw) { testX = rx + rw; }   // right edge

        if (cy < ry) {
            testY = ry;       // top edge
        } else if (cy > ry + rh) { testY = ry + rh; }   // bottom edge

        // // get distance from closest edges
        const distance = Collision.dist(cx, cy, testX, testY);

        // if the distance is less than the radius, collision!
        if (distance <= diameter / 2) {
            return true;
        }
        return false;
    }

    public static circleCircle(x: number, y: number, d: number, x2: number, y2: number, d2: number) {
        // 2d
        if (Collision.dist(x, y, x2, y2) <= (d / 2) + (d2 / 2)) {
            return true;
        }
        return false;
    }

    public static pointCircle(x: number, y: number, cx: number, cy: number, d: number) {
        // 2d
        if (Collision.dist(x, y, cx, cy) <= d / 2) {
            return true;
        }
        return false;
    }

    public static pointEllipse(x: number, y: number, cx: number, cy: number, dx: number, dy: number) {
        // 2d
        const rx = dx / 2, ry = dy / 2;
        // Discarding the points outside the bounding box
        if (x > cx + rx || x < cx - rx || y > cy + ry || y < cy - ry) {
            return false;
        }
        // Compare the point to its equivalent on the ellipse
        const xx = x - cx, yy = y - cy;
        const eyy = ry * Math.sqrt(Math.abs(rx * rx - xx * xx)) / rx;
        return yy <= eyy && yy >= -eyy;
    }

    public static pointRect(pointX: number, pointY: number, x: number, y: number, xW: number, yW: number) {
        // 2d
        if (pointX >= x &&         // right of the left edge AND
            pointX <= x + xW &&    // left of the right edge AND
            pointY >= y &&         // below the top AND
            pointY <= y + yW) {    // above the bottom
            return true;
        }
        return false;
    }

    public static pointLine(px: number, py: number, x1: number, y1: number, x2: number, y2: number, buffer: number = 0.1) {
        // get distance from the point to the two ends of the line
        const d1 = Collision.dist(px, py, x1, y1);
        const d2 = Collision.dist(px, py, x2, y2);

        // get the length of the line
        const lineLen = Collision.dist(x1, y1, x2, y2);

        // since floats are so minutely accurate, add a little buffer zone that will give collision
        // if (buffer === undefined) { buffer = 0.1; }   // higher # = less accurate

        // if the two distances are equal to the line's length, the point is on the line!
        // note we use the buffer here to give a range, rather than one #
        if (d1 + d2 >= lineLen - buffer && d1 + d2 <= lineLen + buffer) {
            return true;
        }
        return false;
    }

    public static lineCircleDepth(x1: number, y1: number, x2: number, y2: number, cx: number, cy: number, r: number) {
        const dcx = cx - x1;
        const dcy = cy - y1;
        if (dcx * dcx + dcy * dcy <= r * r) {
            return 0;
        }
        const dx = x2 - x1;
        const dy = y2 - y1;
        if (dx * (cx - x1) < 0 && dy * (cy - y1) < 0) {
            // opposite side
            return NaN;
        }
        const m = dy / dx;
        const n = y1 - m * x1;

        // r: circle radius
        // h: x value of circle centre
        // k: y value of circle centre
        // m: slope
        // n: y-intercept

        // get a, b, c values
        const a = 1 + m * m;
        const b = -cx * 2 + (m * (n - cy)) * 2;
        const c = cx * cx + (n - cy) * (n - cy) - r * r;

        // get discriminant
        const d = b * b - 4 * a * c;
        if (d >= 0) {
            const x = (-b + (dx <= 0 ? 1 : -1) * Math.sqrt(b * b - 4 * a * c)) / (2 * a);
            const y = m * x + n;

            const ndx = x - x1;
            const ndy = y - y1;
            const len = ndx * ndx + ndy * ndy;
            if (len > dx * dx + dy * dy) {
                return NaN;
            }
            return Math.sqrt(len);
        }
        // no intersection
        return NaN;
    }
    public static lineCircle(x1: number, y1: number, x2: number, y2: number, cx: number, cy: number, diameter: number) {
        // is either end INSIDE the circle?
        // if so, return true immediately
        const inside1 = Collision.pointCircle(x1, y1, cx, cy, diameter);
        if (inside1) { return ({ x: x1, y: y1 }); }
        const inside2 = Collision.pointCircle(x2, y2, cx, cy, diameter);
        if (inside2) { return ({ x: x2, y: y2 }); }

        // get length of the line
        let distX = x1 - x2;
        let distY = y1 - y2;
        const len = Math.sqrt((distX * distX) + (distY * distY));

        // get dot product of the line and circle
        const dot = (((cx - x1) * (x2 - x1)) + ((cy - y1) * (y2 - y1))) / Math.pow(len, 2);

        // find the closest point on the line
        const closestX = x1 + (dot * (x2 - x1));
        const closestY = y1 + (dot * (y2 - y1));

        // is this point actually on the line segment?
        // if so keep going, but if not, return false
        const onSegment = Collision.pointLine(closestX, closestY, x1, y1, x2, y2);
        if (!onSegment) { return null; }

        // draw a debug circle at the closest point on the line
        // if (Collision._debug) {
        //     Collision.ellipse(closestX, closestY, 10, 10);
        // }

        // get distance to closest point
        distX = closestX - cx;
        distY = closestY - cy;
        const distance = Math.sqrt((distX * distX) + (distY * distY));

        if (distance <= diameter / 2) {
            return { x: closestX, y: closestY };
        }
        return null;
    }
    public static lineCircle2(x1: number, y1: number, x2: number, y2: number, cx: number, cy: number, r: number) {
        const dcx = cx - x1;
        const dcy = cy - y1;
        if (dcx * dcx + dcy * dcy <= r * r) {
            return { x: x1, y: y1 };
        }
        const dx = x2 - x1;
        const dy = y2 - y1;
        if (dx * (cx - x1) < 0 && dy * (cy - y1) < 0) {
            // opposite side
            return null;
        }
        const m = dy / dx;
        const n = y1 - m * x1;

        // r: circle radius
        // h: x value of circle centre
        // k: y value of circle centre
        // m: slope
        // n: y-intercept

        // get a, b, c values
        const a = 1 + m * m;
        const b = -cx * 2 + (m * (n - cy)) * 2;
        const c = cx * cx + (n - cy) * (n - cy) - r * r;

        // get discriminant
        const d = b * b - 4 * a * c;
        if (d >= 0) {
            const x = (-b + (dx <= 0 ? 1 : -1) * Math.sqrt(b * b - 4 * a * c)) / (2 * a);
            const y = m * x + n;

            const ndx = x - x1;
            const ndy = y - y1;
            const len = ndx * ndx + ndy * ndy;
            if (len > dx * dx + dy * dy) {
                return null;
            }
            return { x, y };
        }
        // no intersection
        return null;
    }

    public static lineLine(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number, calcIntersection: boolean = false) {

        let intersection: Point;
        let intersectionX = 0;
        let intersectionY = 0;

        // calculate the distance to intersection point
        const uA = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));
        const uB = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));

        // if uA and uB are between 0-1, lines are colliding
        if (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1) {

            // if (Collision._debug || calcIntersection) {
            //     // calc the point where the lines meet
            //     intersectionX = x1 + (uA * (x2 - x1));
            //     intersectionY = y1 + (uA * (y2 - y1));
            // }

            // if (Collision._debug) {
            //     Collision.ellipse(intersectionX, intersectionY, 10, 10);
            // }

            if (calcIntersection) {
                // specially added
                intersectionX = x1 + (uA * (x2 - x1));
                intersectionY = y1 + (uA * (y2 - y1));

                intersection = {
                    x: intersectionX,
                    y: intersectionY,
                };
                return intersection;
            } else {
                return true;
            }
        }
        if (calcIntersection) {
            return null;
        }
        return false;
    }

    public static lineRect(x1: number, y1: number, x2: number, y2: number, rx: number, ry: number, rw: number, rh: number, calcIntersection = false) {

        // check if the line has hit any of the rectangle's sides. uses the lineLine function above
        let left, right, top, bottom, intersection;

        if (calcIntersection) {
            left = Collision.lineLine(x1, y1, x2, y2, rx, ry, rx, ry + rh, true);
            right = Collision.lineLine(x1, y1, x2, y2, rx + rw, ry, rx + rw, ry + rh, true);
            top = Collision.lineLine(x1, y1, x2, y2, rx, ry, rx + rw, ry, true);
            bottom = Collision.lineLine(x1, y1, x2, y2, rx, ry + rh, rx + rw, ry + rh, true);
            intersection = {
                left,
                right,
                top,
                bottom,
            };
        } else {
            // return booleans
            left = Collision.lineLine(x1, y1, x2, y2, rx, ry, rx, ry + rh);
            right = Collision.lineLine(x1, y1, x2, y2, rx + rw, ry, rx + rw, ry + rh);
            top = Collision.lineLine(x1, y1, x2, y2, rx, ry, rx + rw, ry);
            bottom = Collision.lineLine(x1, y1, x2, y2, rx, ry + rh, rx + rw, ry + rh);
        }

        // if ANY of the above are true, the line has hit the rectangle
        if (left || right || top || bottom) {
            if (calcIntersection) {
                return intersection;
            }
            return true;
        }
        return false;
    }


    public static pointPoly(px: number, py: number, vertices: Point[]) {
        let collision = false;

        // go through each of the vertices, plus the next vertex in the list
        let next = 0;
        for (let current = 0; current < vertices.length; current++) {

            // get next vertex in list if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === vertices.length) { next = 0; }

            // get the PVectors at our current position this makes our if statement a little cleaner
            const vc = vertices[current];    // c for "current"
            const vn = vertices[next];       // n for "next"

            // compare position, flip 'collision' variable back and forth
            if (((vc.y > py && vn.y < py) || (vc.y < py && vn.y > py)) &&
                (px < (vn.x - vc.x) * (py - vc.y) / (vn.y - vc.y) + vc.x)) {
                collision = !collision;
            }
        }
        return collision;
    }

    // POLYGON/CIRCLE
    public static circlePoly(cx: number, cy: number, diameter: number, vertices: Point[], interior = false) {

        // if (interior === undefined) {
        //     interior = false;
        // }

        // go through each of the vertices, plus the next vertex in the list
        let next = 0;
        for (let current = 0; current < vertices.length; current++) {

            // get next vertex in list if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === vertices.length) { next = 0; }

            // get the PVectors at our current position this makes our if statement a little cleaner
            const vc = vertices[current];    // c for "current"
            const vn = vertices[next];       // n for "next"

            // check for collision between the circle and a line formed between the two vertices
            const collision = Collision.lineCircle(vc.x, vc.y, vn.x, vn.y, cx, cy, diameter);
            if (collision) { return collision; }
        }

        // test if the center of the circle is inside the polygon
        if (interior === true) {
            const centerInside = Collision.pointPoly(cx, cy, vertices);
            if (centerInside) { return { x: cy, y: cy }; }
        }

        // otherwise, after all that, return false
        return null;
    }


    public static rectPoly(rx: number, ry: number, rw: number, rh: number, vertices: Point[], interior = false) {
        // if (interior === undefined) {
        //     interior = false;
        // }

        // go through each of the vertices, plus the next vertex in the list
        let next = 0;
        for (let current = 0; current < vertices.length; current++) {

            // get next vertex in list if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === vertices.length) { next = 0; }

            // get the PVectors at our current position this makes our if statement a little cleaner
            const vc = vertices[current];    // c for "current"
            const vn = vertices[next];       // n for "next"

            // check against all four sides of the rectangle
            const collision = Collision.lineRect(vc.x, vc.y, vn.x, vn.y, rx, ry, rw, rh);
            if (collision) { return true; }

            // optional: test if the rectangle is INSIDE the polygon note that this iterates all sides of the polygon again, so only use this if you need to
            if (interior === true) {
                const inside = Collision.pointPoly(rx, ry, vertices);
                if (inside) { return true; }
            }
        }

        return false;
    }

    public static linePoly(x1: number, y1: number, x2: number, y2: number, vertices: Point[], calcIntersection = false) {

        // go through each of the vertices, plus the next vertex in the list
        let next = 0;
        for (let current = 0; current < vertices.length; current++) {

            // get next vertex in list if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === vertices.length) { next = 0; }

            // get the PVectors at our current position extract X/Y coordinates from each
            const x3 = vertices[current].x;
            const y3 = vertices[current].y;
            const x4 = vertices[next].x;
            const y4 = vertices[next].y;

            // do a Line/Line comparison if true, return 'true' immediately and stop testing (faster)
            const hit = Collision.lineLine(x1, y1, x2, y2, x3, y3, x4, y4, calcIntersection);
            if (hit) {
                return hit;
            }
        }
        // never got a hit
        return false;
    }

    public static polyPoly(p1: Point[], p2: Point[], interior: boolean = false, calcIntersection = false) {
        // if (interior === undefined) {
        //     interior = false;
        // }
        if (!p2[0]) {
            return false;
        }

        // go through each of the vertices, plus the next vertex in the list
        let next = 0;
        for (let current = 0; current < p1.length; current++) {

            // get next vertex in list, if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === p1.length) { next = 0; }

            // get the PVectors at our current position this makes our if statement a little cleaner
            const vc = p1[current];    // c for "current"
            const vn = p1[next];       // n for "next"

            // use these two points (a line) to compare to the other polygon's vertices using polyLine()
            let collision = Collision.linePoly(vc.x, vc.y, vn.x, vn.y, p2, calcIntersection);
            if (collision) { return collision; }

            // check if the 2nd polygon is INSIDE the first
            if (interior === true) {
                collision = Collision.pointPoly(p2[0].x, p2[0].y, p1);
                if (collision) { return p2[0]; }
            }
        }

        return false;
    }

    public static pointTriangle(px: number, py: number, x1: number, y1: number, x2: number, y2: number, x3: number, y3: number) {

        // get the area of the triangle
        const areaOrig = Math.abs((x2 - x1) * (y3 - y1) - (x3 - x1) * (y2 - y1));

        // get the area of 3 triangles made between the point and the corners of the triangle
        const area1 = Math.abs((x1 - px) * (y2 - py) - (x2 - px) * (y1 - py));
        const area2 = Math.abs((x2 - px) * (y3 - py) - (x3 - px) * (y2 - py));
        const area3 = Math.abs((x3 - px) * (y1 - py) - (x1 - px) * (y3 - py));

        // if the sum of the three areas equals the original, we're inside the triangle!
        if (area1 + area2 + area3 === areaOrig) {
            return true;
        }
        return false;
    }

    public static pointPoint(x: number, y: number, x2: number, y2: number, buffer: number = 0) {
        // if (buffer === undefined) {
        //     buffer = 0;
        // }

        if (Collision.dist(x, y, x2, y2) <= buffer) {
            return true;
        }

        return false;
    }

    // public static pointArc = function (px: number, py: number, ax: number, ay: number, arcRadius: number, arcHeading: number, arcAngle: number, buffer: number) {

    //     if (buffer === undefined) {
    //         buffer = 0;
    //     }
    //     // point
    //     const point = Collision.createVector(px, py);
    //     // arc center point
    //     const arcPos = Collision.createVector(ax, ay);
    //     // arc radius vector
    //     const radius = Collision.createVector(arcRadius, 0).rotate(arcHeading);

    //     const pointToArc = point.copy().sub(arcPos);

    //     if (point.dist(arcPos) <= (arcRadius + buffer)) {
    //         const dot = radius.dot(pointToArc);
    //         const angle = radius.angleBetween(pointToArc);
    //         if (dot > 0 && angle <= arcAngle / 2 && angle >= -arcAngle / 2) {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    public static dist(x: number, y: number, x2: number, y2: number) {
        const a = x2 - x;
        const b = y2 - y;
        return Math.sqrt(a * a + b * b);
    }
    public static radian(x: number, y: number, x2: number, y2: number) {
        const dx = x2 - x;
        const dy = y2 - y;
        return Math.atan2(dy, dx);
    }
    public static degree(x: number, y: number, x2: number, y2: number) {
        const dx = x2 - x;
        const dy = y2 - y;
        return Math.atan2(dy, dx) / Math.PI * 180;
    }

    public static move(radian: number, distance: number): Point {
        const x = distance * Math.cos(radian);
        const y = distance * Math.sin(radian);
        return { x, y };
    }


    public static intersectLinePoly(x1: number, y1: number, x2: number, y2: number, vertices: Point[], calcIntersection = true) {

        // go through each of the vertices, plus the next vertex in the list
        let next = 0;
        for (let current = 0; current < vertices.length; current++) {

            // get next vertex in list if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === vertices.length) { next = 0; }

            // get the PVectors at our current position extract X/Y coordinates from each
            const x3 = vertices[current].x;
            const y3 = vertices[current].y;
            const x4 = vertices[next].x;
            const y4 = vertices[next].y;

            // do a Line/Line comparison if true, return 'true' immediately and stop testing (faster)
            const hit = Collision.lineLine(x1, y1, x2, y2, x3, y3, x4, y4, calcIntersection);
            if (hit) {
                return hit;
            }
        }
        // never got a hit
        return null;
    }


    public static lineLineDepth(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) {

        // calculate the distance to intersection point
        const d = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));
        const uA = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / d;
        const uB = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / d;

        // if uA and uB are between 0-1, lines are colliding
        if (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1) {

            const a = (uA * (x2 - x1));
            const b = (uA * (y2 - y1));
            return Math.sqrt(a * a + b * b);
        }
        return NaN;
    }


    public static intersectLinePolyDepth(x1: number, y1: number, x2: number, y2: number, vertices: Point[]) {

        // go through each of the vertices, plus the next vertex in the list
        let minDepth = Number.MAX_VALUE;
        let hit = false;
        let next = 0;
        for (let current = 0; current < vertices.length; current++) {

            // get next vertex in list if we've hit the end, wrap around to 0
            next = current + 1;
            if (next === vertices.length) { next = 0; }

            // get the PVectors at our current position extract X/Y coordinates from each
            const x3 = vertices[current].x;
            const y3 = vertices[current].y;
            const x4 = vertices[next].x;
            const y4 = vertices[next].y;

            // do a Line/Line comparison if true, return 'true' immediately and stop testing (faster)
            const depth = Collision.lineLineDepth(x1, y1, x2, y2, x3, y3, x4, y4);
            if (depth === depth) {
                hit = true;
                if (depth < minDepth) {
                    minDepth = depth;
                }
            }
        }
        // never got a hit
        return hit ? minDepth : NaN;
    }
}

// const a = [
//     // [1969.2999999999997, 858.7999999999997, 1969.3000000000002, 693.2, 1438, 833, 82],
//     // [1970.2999999999997, 858.7999999999997, 1970.3000000000002, 693.2, 1438, 833, 82],
//     // [1971.2999999999997, 858.7999999999997, 1971.3000000000002, 693.2, 1438, 833, 82],
//     // [1885.25, 887.9, 1869.85, 760.2, 1816.5999109847269, 971.1773549210677, 82.8]
// ];
// for (const list of a) {
//     console.log(Collision.lineCircle(...list as [number, number, number, number, number, number, number]));
// }
