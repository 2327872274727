















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';

@Component({
  components: {},
})
export default class LinkAccountWarningModal extends Vue {
  @Prop({ default: false }) public value!: boolean;

  public get titleHtml() {
    return `<i class="fa fa-exclamation-triangle"></i> Data at Risk`;
  }
  public onHide() {
    const days = 3 * 24 * 60 * 60000;
    userx.changeUserSettings({
      nextLinkWarning: globalx.time + days,
      nextVerificationWarning: globalx.time + days,
    });
  }

}
