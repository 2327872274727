






























































import StateButton from '@/components/inputs/StateButton.vue';
import { TeamMemberData } from '@/game/infos/firestoreFiles';
import { EditState } from '@/store/models.def';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: { StateButton },
})
export default class TeamMateComponent extends Vue {
  public get leaderable() {
    return this.imLeader && this.data.isOnline;
  }
  public get myServer() {
    return globalx.selectedServer || globalx.recommendedServer;
  }
  public get sameServer() {
    return this.currentServer && this.currentServer === this.myServer;
  }

  public get servers() {
    return globalx.fastestServers;
  }

  public get currentServer() {
    return this.servers.find((s) => s.url === this.data.server);
  }
  public get status() {
    return !this.data.isOnline
      ? 'Offline'
      : this.data.server
      ? 'Playing'
      : 'Online';
  }
  public get kickMemberLabel() {
    const labels = this.isMe
      ? {
          active: 'Leave',
          waiting: 'Leave',
          sending: '',
          done: 'Leaved',
        }
      : {
          active: 'Kick',
          waiting: 'Kick',
          sending: '',
          done: 'Kicked',
        };
    return labels[this.kickMemberState];
  }
  public get changeLeaderLabel() {
    const labels = {
      active: 'Leader',
      waiting: 'Leader',
      sending: '',
      done: 'Leader',
    };
    return labels[this.changeLeaderState];
  }

  public get serverName() {
    if (!this.currentServer) {
      return '';
    }
    const split = this.currentServer.name.split('|');
    return split[1] + '-' + split[2] + '-' + split[3];
  }
  @Prop() public data!: TeamMemberData;
  @Prop({ default: false }) public imLeader!: boolean;
  @Prop({ default: false }) public isLeader!: boolean;
  @Prop({ default: false }) public isMe!: boolean;

  public kickMemberState: EditState = 'active';

  public changeLeaderState: EditState = 'active';

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
  public async onKickMember() {
    if (this.data.server) {
      this.showError('Can not kick member who are playing.');
      return;
    }
    this.kickMemberState = 'waiting';
    const value = await this.$bvModal.msgBoxConfirm('Are you sure?', {
      title: this.isMe ? `Leave Team` : 'Kick Member',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      cancelVariant: 'link btn-link-warning',
      modalClass: 'funny-modal',
      okTitle: 'Confirm',
      cancelTitle: 'Cancel',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: false,
    });
    if (!value) {
      this.kickMemberState = 'active';
      return;
    }
    try {
      this.kickMemberState = 'sending';
      if (this.isMe) {
        await userx.leaveTeam();
      } else {
        await userx.kickMember(this.data.uid);
      }
      this.kickMemberState = 'active';
    } catch (error) {
      this.showError(error);
    }
  }
  public async onChangeLeader() {
    this.changeLeaderState = 'waiting';
    const value = await this.$bvModal.msgBoxConfirm('Are you sure?', {
      title: `Appoint Leader`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      cancelVariant: 'link btn-link-warning',
      modalClass: 'funny-modal',
      okTitle: 'Confirm',
      cancelTitle: 'Cancel',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: false,
    });
    if (!value) {
      this.changeLeaderState = 'active';
      return;
    }
    try {
      this.changeLeaderState = 'sending';
      await userx.changeTeamLeader({
        uid: this.data.uid,
      });

      this.changeLeaderState = 'active';
    } catch (error) {
      this.showError(error);
    }
  }

  public async onFollowServer() {
    if (this.currentServer && this.myServer !== this.currentServer) {
      globalx.updateSelectedServer(this.currentServer);
      this.$bvModal.msgBoxOk(`Changed sever to ${this.serverName}`, {
        title: `Changed Server`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        modalClass: 'funny-modal',
        okTitle: 'OK',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false,
      });
    }
  }
}
