




















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  UnlockChallenge,
  UnlockChallengeProgress,
  UnlockChallengeType,
} from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { getChallengeProgress } from '@/game/infos/firestoreFiles';
import StateButton from '@/components/inputs/StateButton.vue';
import { EditState } from '@/store/models.def';
import { floorToFixed, roundToFixed } from '@/util/number';
import { ItemCode } from '@/game/infos/itemInfos';
@Component({
  components: {
    StateButton,
  },
})
export default class QuestChallengeComponent extends Vue {
  @Prop() public challenge!: UnlockChallenge;
  @Prop({ default: false }) public reading!: boolean;
  @Prop({ default: false }) public fullGreen!: boolean;
  @Prop({ default: 'QM' }) public initials!: string;
  @Prop({ default: 0 }) public progress!: number;
  @Prop({ default: false }) public forceCompleted!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  public get completed() {
    if (this.forceCompleted) {
      return true;
    }
    const challenge = this.challenge;
    const current =
      challenge.type === UnlockChallengeType.Level
        ? userx.userDoc.lvl
        : this.progress;
    return current >= challenge.goal;
  }

  public submitState: EditState = 'active';
  public get submitLabel() {
    const labels = {
      active: 'Pay',
      sending: '',
      done: 'Paid',
    };
    return labels[this.submitState];
  }
  public hasIndicator = false;

  @Watch('challengeId', { immediate: true })
  public challengeIdChanged(newVal: string) {
    this.hasIndicator = false;
    this.submitState = 'active';
  }
  @Watch('newlyCompleted', { immediate: true })
  public newlyCompletedChanged(newVal: boolean) {
    if (newVal) {
      this.hasIndicator = true;
    }
  }

  public get challengeId() {
    return `${this.initials}-${this.challenge.unlockId}-${this.challenge.step}-${this.challenge.index}`;
  }

  public get newlyCompleted() {
    return !this.reading
      ? false
      : userx.unreadCompletedQuestChallenges.find((s) => s === this.challengeId)
      ? true
      : false;
  }

  public get isContribute() {
    return this.challenge.type === UnlockChallengeType.Contribute;
  }

  public get statusIcon() {
    const completed = this.completed;
    return `<i class="fas fa-${completed ? 'check-circle' : 'clock'} text-${
      completed ? 'green' : 'white'
    }"/>`;
  }

  public get hasEnoughMaterial() {
    return this.myNumMaterial >= this.challenge.goal;
  }

  public get myNumMaterial() {
    const challenge = this.challenge;
    if (!challenge.material) {
      return 0;
    }
    return challenge.material === ItemCode.Money
      ? userx.inventory.money
      : challenge.material === ItemCode.Gem
      ? userx.inventory.gem
      : userx.inventory.availables[challenge.material] || 0;
  }

  public get description() {
    const challenge = this.challenge;
    const des = challenge.description;
    const type = challenge.type;
    const suffix =
      this.completed || type === UnlockChallengeType.UnlockFish
        ? ''
        : type === UnlockChallengeType.Level
        ? `[${userx.userDoc.lvl}/${challenge.goal}]`
        : type === UnlockChallengeType.Contribute
        ? `[${this.myNumMaterial}/${challenge.goal}]`
        : `[${floorToFixed(this.progress, challenge.goal > 10 ? 0 : 1)}/${
            challenge.goal
          }]`;

    return des + ' ' + suffix;
  }

  public async onContribute() {
    this.submitState = 'sending';
    try {
      await userx.questPay(this.challengeId);
      this.submitState = 'done';
    } catch (error) {
      this.$root.$emit('error', (error as any).message);
      this.submitState = 'active';
    }
  }
}
