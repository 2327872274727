

















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gamex from '@/store/modules/gamex';
import userx from '@/store/modules/userx';
import { ordinalSuffix } from '@/util/number';
import ScrollContainer from '../ScrollContainer.vue';
import BossChatWindow from './BossChatWindow.vue';
import { Global } from '@/store/globalz';
import { GameType } from '@/game/infos/roomInfos';
import { BossLobbyState, ClientBossPlayerData } from '@/game/infos/roomInfosBoss';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import GameBagComponent from '../game/utilityBar/GameBagComponent.vue';
import globalx from '@/store/modules/globalx';
import { Dictionary } from '@/util/dictionary';
import { FishInfo, FishType, fishInfos } from '@/game/infos/fishInfos';

function frameToTime(tl: number) {
	const frame = tl % 5;
	tl -= frame;
	tl *= 200;

	const min = Math.floor(tl / 60000);
	tl -= min * 60000;
	const sec = Math.floor(tl / 1000);
	return `${min}'${double(sec)}"${double(frame * 12)}`;
}
function double(n: number) {
	return n < 10 ? '0' + n : n;
}


@Component({
	components: {
		BossChatWindow,
		ScrollContainer,
		GameBagComponent,
	},
})
export default class AfterBossPage extends Vue {
	public get myUid() {
		return userx.uid;
	}
	public boss = 1;
	public difficulty = 1;
	public get bossLobbyData() {
		return gamex.bossLobbyData!;
	}
	public get bossRankings() {
		return globalx.bossRankings &&
			globalx.bossRankings[this.boss] &&
			globalx.bossRankings[this.boss][this.difficulty] || { players: [], records: {} };
	}
	public get recordTime() {
		return this.champions.length === 0 ? 'Waiting for you...' : frameToTime(this.champions[0].frameUsed);
	}
	public get champions() {
		if (this.bossRankings.players.length === 0) {
			return [];
		}
		const champion = this.bossRankings.players[0];

		return this.bossRankings.players.filter((p) => p.frameUsed === champion.frameUsed);
	}
	public get championDesc() {
		let other = this.champions.length;
		const len = Math.min(4, other);
		const names: string[] = [];
		for (let i = 0; i < len; i++) {
			other--;
			const champion = this.champions[i];
			if (champion.uid === userx.userDoc.uid) {
				names.unshift('You');
			} else {
				names.push(champion.name);
			}
		}
		const str = names.join(', ');
		return other === 0 ? str : str + ` and ${other} others`;
	}
	public get bestFishes() {
		const dic: Dictionary<number> = {};
		for (const champion of this.champions) {
			if (!dic[champion.fishType]) {
				dic[champion.fishType] = 1;
			} else {
				dic[champion.fishType]++;
			}
		}
		const list: Array<{ fishType: string, amount: number, info: FishInfo; }> = [];
		for (const fishType in dic) {
			if (Object.prototype.hasOwnProperty.call(dic, fishType)) {
				const amount = dic[fishType];
				list.push({ fishType, amount, info: fishInfos[fishType] });
			}
		}
		list.sort((a, b) => (b.amount - a.amount));
		if (list.length > 4) {
			list.length = 4;
		}
		return list.map((f) => f.info.name);
	}
	public get myRank() {
		let beforeMe = 0;
		this.myEquals = 0;
		for (const frameUsed in this.bossRankings.records) {
			if (Object.prototype.hasOwnProperty.call(this.bossRankings.records, frameUsed)) {
				const amount = this.bossRankings.records[frameUsed];
				const frame = Number(frameUsed);
				if (frame < this.myBestFrame) {
					beforeMe += amount;
				} else if (frame === this.myBestFrame) {
					this.myEquals = amount;
				}
			}
		}
		return beforeMe + 1;
	}
	public myEquals = 0;
	public get lobbyClosed() {
		return gamex.bossLobbyData!.state >= BossLobbyState.Closing;
	}
	public get rewards() {
		if (!this.bossLobbyData || !this.bossLobbyData.prevResult || !this.bossLobbyData.prevResult.rewards) {
			return [];
		}
		const list: ItemCodeAmountPair[] = [];
		for (const itemCode in this.bossLobbyData.prevResult.rewards) {
			if (
				Object.prototype.hasOwnProperty.call(
					this.bossLobbyData.prevResult.rewards,
					itemCode,
				)
			) {
				const amount = this.bossLobbyData.prevResult.rewards[itemCode];
				list.push({ itemCode: Number(itemCode), amount });
			}
		}
		return list;
	}
	public get myNewFrame() {
		if (!this.bossLobbyData || !this.bossLobbyData.prevResult || this.bossLobbyData.prevResult.timeUsed < 0) {
			return -1;
		}
		return Math.round(this.bossLobbyData.prevResult.timeUsed * 5 / 1000);
	}
	public get myOldFrameRecord() {
		const { boss, difficulty } = this;
		return userx.oldBossRecord[boss] && userx.oldBossRecord[boss][difficulty] && userx.oldBossRecord[boss][difficulty].frameUsed || -1;
	}
	public get myBestFrame() {
		if (this.myNewFrame < 0 && this.myOldFrameRecord < 0) {
			return -1;
		}
		if (this.myNewFrame < 0) {
			return this.myOldFrameRecord;
		} else if (this.myOldFrameRecord < 0) {
			return this.myNewFrame;
		}
		return this.myNewFrame < this.myOldFrameRecord ? this.myNewFrame : this.myOldFrameRecord;
	}
	public get myNewTime() {
		return this.myNewFrame < 0 ? `--'--"--` : frameToTime(this.myNewFrame);
	}
	public get myOldTime() {
		return this.myOldFrameRecord < 0 ? `--'--"--` : frameToTime(this.myOldFrameRecord);
	}
	public get myBestTime() {
		return this.myBestFrame < 0 ? `--'--"--` : frameToTime(this.myBestFrame);
	}
	public width = 640;
	public height = 460;
	public mounted() {
		this.boss = this.bossLobbyData.roomOptions.boss;
		this.difficulty = this.bossLobbyData.roomOptions.difficulty;
		globalx.loadBossRankings({boss: this.boss, difficulty: this.difficulty});
		this.onResize();
		window.addEventListener('resize', this.onResize);
	}
	public beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	}
	public onResize() {
		this.width = this.$el.clientWidth;
		this.height = (this.width / 640) * 460;
	}

	public onContext(event: any, uid: string) {
		(this.$refs.chatbox as BossChatWindow).onContext(event, uid);
	}

	public onPlayAgain() {
		gamex.setGameStage('BossLobby');
	}
	public onQuit() {
		Global.stageControl.uiControl.onLeaveBoss();
	}
	public get socketErrorMessage() {
		return gamex.socketErrorMessage;
	}
	@Watch('socketErrorMessage')
	public async socketErrorMessageChanged(value: string) {
		await this.$bvModal.msgBoxOk(value, {
			titleHtml: `<i class="fa fa-exclamation-triangle"></i> Connection Lost`,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			modalClass: 'funny-modal',
		});
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('BossList');
	}
	public get rejectReason() {
		return gamex.rejectReason;
	}
	@Watch('rejectReason')
	public async rejectReasonChanged(value: string) {
		await this.$bvModal.msgBoxOk(value, {
			titleHtml: `<i class="fa fa-exclamation-triangle"></i> Join Room Failed`,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			modalClass: 'funny-modal',
		});
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('BossList');
	}
}
