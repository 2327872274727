import settingx from '@/store/modules/settingx';
import Collision from '@/util/collide';
import { Rotate } from '@/util/rotate';
import { Container, DisplayObject, Graphics, InteractionEvent, Point, Text } from 'pixi.js';
export interface PinchDelta {
	scale: number, deltaX: number, deltaY: number, deltaAngle: number;
}
export class DecoPinchArea extends Container {
	public square = new Graphics();
	public sample = new Graphics();
	public text = new Text('');
	public lastLen = 0;
	public initPoint = new Point(0, 0);
	public initDistance = 0;
	public initAngle = 0;

	constructor() {
		super();

		this.square.lineStyle(1, 0xffffff, 0.8);
		this.square.beginFill(0xff0000, 0.001);
		this.square.drawRect(-95, -90, 190, 160);
		this.addChild(this.square);
		// this.sample.beginFill(0xff0000, 0.5);
		// this.sample.drawRect(-75, -80, 150, 150);
		// this.addChild(this.sample);

		this.addChild(this.text);
		this.text.x = -75;
		this.text.y = -80;
		this.text.scale.set(0.5);
		this.interactive = true;
		this.cursor = 'move';

		this
			.on('touchstart', this.touchStart)
			.on('touchend', this.touchEnd)
			.on('touchendoutside', this.touchEnd)

			.on('mousedown', this.mouseDown)
			.on('mouseup', this.mouseUp)
			.on('mouseupoutside', this.mouseUp);

	}

	public mouseDown(e: InteractionEvent) {
		this.on('mousemove', this.mouseMove);
		const og = (e.data.originalEvent as PointerEvent);
		this.pinchUpdate(1, og.clientX, og.clientY);
	}
	public mouseMove(e: InteractionEvent) {
		const og = (e.data.originalEvent as PointerEvent);
		this.pinchUpdate(1, og.clientX, og.clientY);
	}
	public mouseUp(e: InteractionEvent) {
		this.removeListener('mousemove', this.mouseMove);
		this.pinchUpdate(0);
	}


	public touchStart(e: InteractionEvent) {
		this.on('touchmove', this.touchMove);
		// this.text.text = (e.data.originalEvent as TouchEvent).targetTouches.length.toFixed();
		this.processTouchEvent((e.data.originalEvent as TouchEvent).targetTouches);
	}

	public touchEnd(e: InteractionEvent) {
		this.removeListener('touchmove', this.touchMove);
		// this.text.text = (e.data.originalEvent as TouchEvent).targetTouches.length.toFixed();
		this.processTouchEvent((e.data.originalEvent as TouchEvent).targetTouches);
	}
	public touchMove(e: InteractionEvent) {
		// this.text.text = (e.data.originalEvent as TouchEvent).targetTouches.length.toFixed()+'\ntouchmove';
		this.processTouchEvent((e.data.originalEvent as TouchEvent).targetTouches);
	}

	public pinchUpdate(length: number, x0: number = 0, y0: number = 0, x1: number = 0, y1: number = 0) {
		const dpi = settingx.now.dpi;
		x0 *= dpi;
		x1 *= dpi;
		y0 *= dpi;
		y1 *= dpi;
		if (length === 0) {
			this.lastLen = 0;
			this.emit('pinchend');
			return;
		}
		if (length !== this.lastLen) {
			this.lastLen = length;
			if (length === 1) {
				const local = this.toLocal(new Point(x0, y0));
				this.initPoint.x = local.x;
				this.initPoint.y = local.y;
				this.initAngle = 0;
				this.initDistance = 0;
			} else {
				const dx = x0 - x1;
				const dy = y0 - y1;
				this.initDistance = Math.sqrt(dx * dx + dy * dy);
				const local = this.toLocal(new Point((x0 + x1) / 2, (y0 + y1) / 2));
				this.initPoint.x = local.x;
				this.initPoint.y = local.y;
				this.initAngle = Collision.degree(x0, y0, x1, y1);
			}
			this.emit('pinchset');
			// this.text.text = `${this.initPoint.x}\n${this.initPoint.y}\n${this.x}\n${this.y}`;
		} else {
			let distance = 0;
			let x = 0;
			let y = 0;
			let angle = 0;
			if (length === 1) {
				const local = this.toLocal(new Point(x0, y0));
				x = local.x;
				y = local.y;
			} else {
				const dx = x0 - x1;
				const dy = y0 - y1;
				distance = Math.sqrt(dx * dx + dy * dy);
				const local = this.toLocal(new Point((x0 + x1) / 2, (y0 + y1) / 2));
				x = local.x;
				y = local.y;
				angle = Collision.degree(x0, y0, x1, y1);
			}
			const scale = this.initDistance === 0 ? 1 : distance / this.initDistance;
			const deltaX = x - this.initPoint.x;
			const deltaY = y - this.initPoint.y;
			const deltaAngle = angle - this.initAngle;
			const myScale = collectScale(this);

			function collectScale(symbol: DisplayObject, currentScale: number = 1) {
				currentScale *= symbol.scale.x;
				if (symbol.parent) {
					return collectScale(symbol.parent, currentScale);
				} else {
					return currentScale;
				}
			}
			this.emit('pinchmove', { scale, deltaX, deltaY, deltaAngle });
			// this.text.text = `d\n${deltaX}\n${deltaY}\n${deltaAngle}\n${scale}`;

			// this.sample.scale.set(scale);
			// this.sample.x = deltaX;
			// this.sample.y = deltaY;
			// this.sample.angle = deltaAngle;
		}
	}
	private processTouchEvent(t: TouchList) {
		if (t[1]) {
			this.pinchUpdate(2, t[0].clientX, t[0].clientY, t[1].clientX, t[1].clientY);
		} else if (t[0]) {
			this.pinchUpdate(1, t[0].clientX, t[0].clientY);
		} else {
			this.pinchUpdate(0);
		}

	}
}
