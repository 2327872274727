





























import { Component, Vue, Watch } from 'vue-property-decorator';
import GameStageComponent from '@/components/canvas/GameStageComponent.vue';
import SettingsComponent from '@/components/ui/SettingsComponent.vue';
import globalx from '@/store/modules/globalx';
import InputControlToast from '@/components/ui/InputControlToast.vue';
import MainMenu from '@/components/ui/mainMenu/MainMenu.vue';
import gamex from '@/store/modules/gamex';
import userx from '@/store/modules/userx';
import assetx from '@/store/modules/assetx';
import LobbyComponent from '@/components/ui/mainMenu/LobbyComponent.vue';
import AfterGameReport from '@/components/ui/afterGameReport/AfterGameReport.vue';
import FishUnlockModal from '@/components/ui/afterGameReport/FishUnlockModal.vue';
import { Global } from '@/store/globalz';
import CraftModal from '@/components/ui/mainMenu/bag/CraftModal.vue';
import { BgmManager } from '@/client/sound/BgmManager';
import { Tween } from '@/util/tweents';
import SkinPreviewer from '@/components/gacha/SkinPreviewer.vue';
import TourneyLobby from '@/components/ui/tourney/TourneyLobby.vue';
import TourneyList from '@/components/ui/tourney/TourneyList.vue';
import AfterTourneyPage from '@/components/ui/tourney/AfterTourneyPage.vue';
import { GameType } from '@/game/infos/roomInfos';
import BossList from '@/components/ui/boss/BossList.vue';
import BossLobby from '@/components/ui/boss/BossLobby.vue';
import AfterBossPage from '@/components/ui/boss/AfterBossPage.vue';

@Component({
  components: {
    GameStageComponent,
    SettingsComponent,
    InputControlToast,
    MainMenu,
    LobbyComponent,
    TourneyLobby,
    TourneyList,
    AfterTourneyPage,
    AfterGameReport,
    FishUnlockModal,
    CraftModal,
    SkinPreviewer,
    BossList,
    BossLobby,
    AfterBossPage,
  },
})
export default class Home extends Vue {
  public get show() {
    return globalx.switch;
  }
  public get gameType() {
    return gamex.gameType;
  }
  public mounted() {
    window.addEventListener('beforeunload', this.preventNav);
    window.addEventListener('unload', () => {
      Global.clientCommunicator.quit();
    });
  }
  public beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNav);
    Global.clientCommunicator.quit();
  }

  public get currentPage() {
    return gamex.gameStage;
  }

  public get bgmCheckSum() {
    return this.pageReady + this.currentPage;
  }
  @Watch('bgmCheckSum')
  public bgmCheckSumChange() {
    const result = this.currentPage;
    if (this.pageReady === 'done') {
      if (result === 'MainMenu') {
        BgmManager.instance.play('bgmMainMenu');
        Tween.get(BgmManager.currentBgm, { override: true }).to(
          { volume: 1 },
          1000,
        );
      } else if (result === 'Start') {
        BgmManager.instance.play(
          this.gameType === GameType.Armageddon ? 'bgmGame' : 'arena',
        );
        Tween.get(BgmManager.currentBgm, { override: true }).to(
          { volume: 1 },
          0,
        );
      } else if (result === 'End') {
        // BgmManager.instance.play('bgmGame');
      } else {
        if (this.showLobby) {
          BgmManager.instance.play('bgmMainMenu');
          Tween.get(BgmManager.currentBgm, { override: true }).to(
            { volume: 0.1 },
            1000,
          );
        } else {
          BgmManager.instance.play('');
        }
      }
    }
  }
  public get showLobby() {
    const cp = gamex.gameStage;
    return (
      cp === 'Connecting' ||
      cp === 'Joining' ||
      cp === 'Queue' ||
      cp === 'Rejected'
    );
  }
  public get showTourneyList() {
    const cp = gamex.gameStage;
    return (
      cp === 'TourneyList' ||
      cp === 'TourneyCreate' ||
      (gamex.gameType === GameType.Tourney &&
        (cp === 'Connecting' || cp === 'Joining'))
    );
  }
  public get showBossList() {
    const cp = gamex.gameStage;
    return (
      cp === 'BossList' ||
      cp === 'BossCreate' ||
      (gamex.gameType === GameType.Boss &&
        (cp === 'Connecting' || cp === 'Joining'))
    );
  }

  public async beforeRouteUpdate(to, from, next) {
    if (
      (this.currentPage === 'Start' || this.currentPage === 'End') &&
      (await this.stopPageLeave())
    ) {
      next(false);
    } else {
      next();
    }
  }
  public async beforeRouteLeave(to, from, next) {
    if (
      (this.currentPage === 'Start' || this.currentPage === 'End') &&
      (await this.stopPageLeave())
    ) {
      next(false);
    } else {
      next();
    }
  }

  public async preventNav(event) {
    if (this.currentPage === 'Start' || this.currentPage === 'End') {
      event.preventDefault();
      event.returnValue = '';
    }
  }
  public async stopPageLeave() {
    const value = await this.$bvModal.msgBoxConfirm(
      'Are you sure you quit to leave the game?',
      {
        size: 'sm',
        cancelVariant: 'link',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        modalClass: 'funny-modal',
        titleHtml: `Quit Game`,
      },
    );
    if (value) {
      Global.clientCommunicator.quit();
      return false;
    } else {
      return true;
    }
  }
  public get pageReady() {
    if (!globalx.maintenanceInfo) {
      return 'maintenanceCheck';
    }
    if (userx.loginStatus === 'loading') {
      return 'auth';
    }
    if (userx.loginStatus === 'logged in') {
      if (!globalx.userDocsReady) {
        return 'loadingUserDocs';
      }
    }
    if (!globalx.serverListReady) {
      return 'loadingServers';
    } else if (
      Object.values(globalx.serverList).length > 1 &&
      !globalx.firstPingTestReceived
    ) {
      return 'pingingServers';
    }
    if (assetx.assetLoadState === 'completed') {
      return 'done';
    }
    return Math.round(assetx.assetLoadProgress);
  }
  @Watch('pageReady', { immediate: true })
  public pageReadyChanged(newVal: string) {
    switch (newVal) {
      case 'auth':
        this.$root.$emit('loading', 'Authenticating...');
        break;
      case 'loadingUserDocs':
        this.$root.$emit('loading', 'Loading  User  Data...');
        break;
      case 'maintenanceCheck':
        this.$root.$emit('loading', 'Checking  For  Update...');
        break;
      case 'loadingServers':
        this.$root.$emit('loading', 'Loading  Server  Lists...');
        break;
      case 'pingingServers':
        this.$root.$emit('loading', 'Connecting  To  Servers...');
        break;
      case 'done':
        this.$root.$emit('loading', '');
        break;
      default:
        this.$root.$emit('loading', `Loading Assets...${newVal}%`);
        break;
    }
  }
}
