






















































import {
	generateSpecialSkillIconData,
	generateGeneSkillIconData,
	generateNormalSkillIconData,
	generatePassiveSkillIconData,
	generateRGeneSkillIconData,
} from '@/client/factory/assets/skillIcon';
import SkillIconCanvasComponent from '@/components/canvas/SkillIconCanvasComponent.vue';
import { FishInfo } from '@/game/infos/fishInfos';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishSkillComponent from './FishSkillComponent.vue';
@Component({
	components: {
		SkillIconCanvasComponent,
		FishSkillComponent,
	},
})
export default class FishInfoTab extends Vue {
	@Prop({ required: true }) public fishInfo!: FishInfo;

	public get hasActiveSkill() {
		return this.fishInfo.skill1 || this.fishInfo.skill2;
	}
	public get hasPassiveSkill() {
		return this.fishInfo.passives.length > 0;
	}
	public get hasGeneSkill() {
		return this.fishInfo.genes.length > 0;
	}
	public get hasRGeneSkill() {
		return this.fishInfo.recessives.length > 0;
	}

	public get normalSkillIconData() {
		return generateNormalSkillIconData(this.fishInfo.skill1);
	}
	public get specialSkillIconData() {
		return generateSpecialSkillIconData(this.fishInfo.skill2);
	}
	public get passiveSkillIconData() {
		return this.fishInfo.passives.map((s) =>
			generatePassiveSkillIconData(s.type, s.lvl),
		);
	}
	public get geneSkillIconData() {
		return this.fishInfo.genes.map((s) =>
			generateGeneSkillIconData(s.type, s.lvl),
		);
	}
	public get recessSkillIconData() {
		return this.fishInfo.recessives.map((s) =>
			generateRGeneSkillIconData(s.type, s.lvl),
		);
	}
}
