











import PixiCanvasComponent from '@/components/canvas/PixiCanvasComponent.vue';
import { Container, Graphics } from 'pixi.js';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { FishType } from '@/game/infos/fishInfos';
import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import Factory from '@/client/factory';
import userx from '@/store/modules/userx';
import { Decoratives } from '../ui/mainMenu/tools/decoEditor/decorative';
import { DecoSet } from '@/game/infos/decorativeInfos';
import settingx from '@/store/modules/settingx';
import { SkinGroup } from '@/game/infos/skinInfos';

declare const sub: any;
@Component({
  components: {},
})
export default class FishCanvasComponent extends PixiCanvasComponent {
  public get sumcheck() {
    return (
      this.fishType.toString() +
      this.skinGroup.toString() +
      JSON.stringify(this.decoSet) +
      this.width.toString() +
      this.height.toString()
    );
  }
  @Prop({ default: null }) public decoSet!: DecoSet | null;
  @Prop({ default: 0 }) public fishType!: FishType;
  @Prop({ default: 0 }) public skinGroup!: SkinGroup;
  @Prop({ default: false }) public small!: boolean;

  public builtSumCheck = '';
  public symbol: PlayerSymbol | null = null;

  public touchingStart = 0;
  public touchX = 0;
  public touchY = 0;

  @Watch('sumcheck', { immediate: true })
  public sumcheckChanged(newVal: FishType | '', oldVal: FishType | '') {
    if (this.assetLoadState !== 'completed') {
      return;
    }
    if (newVal) {
      this.build();
    }
  }

  public async build() {
    if (this.builtSumCheck === this.sumcheck || this.width === 0) {
      return;
    }
    this.builtSumCheck = this.sumcheck;
    if (!this.symbol) {
      this.symbol = PlayerSymbol.get();
    }
    const symbol = this.symbol;
    const decoSet = this.decoSet;
    this.app.start();
    this.stage.addChild(symbol);
    symbol.setFishType(this.fishType, this.skinGroup);
    symbol.setAction('idle');
    // symbol.angle = -90;
    const smallest = Math.min(this.width, this.height);
    symbol.scale.set((smallest / 114) * 0.7);
    symbol.x = this.width / 2;
    symbol.y = this.height / 2;
    // symbol.shadow.visible = false;
    symbol.miniSpike.visible = true;
    Decoratives.generateDeco(symbol, decoSet);
    this.rerenderAndStop();
  }
  public mounted() {
    this.width = this.height = 114;
    this.resize();
    if (this.assetLoadState === 'completed') {
      this.build();
    }
  }
  public _beforeDestroy() {
    this.stage.removeChildren();
    if (this.symbol) {
      this.symbol.disposeDecos();
      PlayerSymbol.pool(this.symbol);
      this.symbol = null;
    }
    // super.beforeDestroy();
  }
  public rerenderAndStop2() {
    this.app.start();
    this.isStopped = true;
    this.app.render();
    this.app.stop();
  }
  public onClick(e) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    this.$emit('click', event);
  }

  public onMouseOver(e: any) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    this.$emit('mouseover', e);
  }
  public async onTouchStart(e: any) {
    this.$emit('mouseover', e);
    this.touchX = e.changedTouches[0]?.screenX;
    this.touchY = e.changedTouches[0]?.screenY;
    this.touchingStart = Date.now();

  }
  public async onTouchEnd(e: any) {
    this.$emit('mouseleave', e);
    if (
      Date.now() - this.touchingStart < 500 &&
      this.touchX === e.changedTouches[0]?.screenX &&
      this.touchY === e.changedTouches[0]?.screenY
    ) {
      this.$emit('click', e);
    }

  }
}
