import { Rotate } from '@/util/rotate';
import { BroadcastArmageddonExposeEvent, BroadcastBuffEvent, BroadcastChatEvent, BroadcastDojoDisbandEvent, BroadcastDojoEvent, BroadcastDojoHostChangeEvent, BroadcastDojoInvitesEvent, BroadcastMuteEvent, BroadcastOuroborosOpenEvent, BroadcastRecessGeneEvent, BroadcastSecretRoomEvent, BroadcastSecretRoomFoundEvent, BroadcastState, BroadcastWhirlpoolEvent, PlayerGameState } from '@/game/multithread/state';
import { PlayerType } from '@/game/infos/enums';
import { BroadcastEvent, ChatType, EventType, MuteType } from '@/game/infos/eventType';
import { Interpolatable, MiscData } from '@/game/multithread/skills/miscContainer';
import { FoodData, getDefaultFoodData, getDefaultPlayerData, getDefaultStababData, getDefaultViewState, PlayerData, StababData, TrackerData, UpdateState, UserData, ViewState } from '@/game/multithread/viewState';
import { Dictionary } from 'vue-router/types/router';
import gamex from '@/store/modules/gamex';
import userx from '@/store/modules/userx';
import { Global } from '@/store/globalz';
import { SoundEfx } from '../sound/SoundEfx';
import { arrayRemoveOne } from '@/util/array';
import { passiveSkillInfos } from '@/game/infos/skills';


let isServerBoosting = false;
let isClientBoosting = false;
let clientStartBoostTime = 0;

export function clientBoost(value: boolean) {
	if (value && !isClientBoosting) {
		clientStartBoostTime = Date.now();
	}
	isClientBoosting = value;
}

function serverBoost(value: boolean) {
	if (value && !isServerBoosting) {
		console.log('boost', Date.now() - clientStartBoostTime);
	}
	isServerBoosting = value;
}

// let lastState: UpdateState;
// let lastStateTime = 0;
let initialGameStart = 0;
export class ViewStateManager {
	public renderDelay = 20;
	public maxRenderDelay = 100;

	public gameUpdates: ViewState[] = [];
	// events: BroadcastEvent[] = [];
	public gameStart = 0;
	public firstServerTimestamp = 0;
	public noNext = 0;
	public events: BroadcastEvent[] = [];

	public lastState: ViewState = getDefaultViewState();

	public initState(maxRenderDelay = 100) {
		this.maxRenderDelay = maxRenderDelay;
		this.renderDelay = Math.min(maxRenderDelay, 20);
		this.gameStart = 0;
		this.firstServerTimestamp = 0;
		this.clear();
	}
	public clear() {
		this.gameUpdates.length = 0;
		this.lastState = getDefaultViewState();
	}
	public addState(update: UpdateState) {
		if (!this.firstServerTimestamp) {
			this.firstServerTimestamp = update.t;
			this.gameStart = Date.now();
			initialGameStart = this.gameStart;
		}
		// else if (this.renderDelay < this.maxRenderDelay) {
		// 	const len = this.gameUpdates.length;
		// 	let addDelay = false;

		// 	if (len === 1) {
		// 		addDelay = true;
		// 	} else if (len > 1) {
		// 		const gap = state.t - this.gameUpdates[len - 1].t;
		// 		console.log(gap);
		// 		if (gap > this.renderDelay) {
		// 			if (len === 0) { addDelay = true; }
		// 		}
		// 	}
		// 	if (addDelay) {
		// 		this.renderDelay++;
		// 	}

		// const ct = Date.now();
		// if (lastState) {

		// 	console.log(update.t - ct, update.t - lastState.t, ct - lastStateTime);
		// }
		// lastState = update;
		// lastStateTime = ct;
		const newJoin = !gamex.users[userx.uid!];
		let usersDirty = false;
		if (update.my.playerRemove) {
			for (const id of update.my.playerRemove) {
				delete this.lastState.players[id];
			}
			delete update.my.playerRemove;
		}
		if (update.my.foodRemove) {
			for (const id of update.my.foodRemove) {
				delete this.lastState.foods[id];
			}
			delete update.my.foodRemove;
		}
		if (update.my.miscRemove) {
			for (const id of update.my.miscRemove) {
				delete this.lastState.miscs[id];
			}
			delete update.my.miscRemove;
		}
		if (update.my.userRemove) {
			usersDirty = true;
			for (const id of update.my.userRemove) {
				// if (!newJoin && gamex.users[id]) {
				// 	gamex.addSystemMessage({
				// 		chatType: ChatType.ChatSystem,
				// 		t: update.t,
				// 		msg: `${gamex.users[id].name} left the server.`,
				// 	});
				// }
				delete gamex.users[id];
			}
			delete update.my.userRemove;
		}
		if (update.my.userAdd) {
			usersDirty = true;
			let numMutedYou = 0;
			for (const userData of update.my.userAdd) {
				gamex.users[userData.uid] = userData;
				if (userData.uid === userx.uid) {
					if (userData.mutedAll) {

						gamex.addSystemMessage({
							chatType: ChatType.ChatSystem,
							t: update.t,
							msg: 'You muted everone.',
						});
					}
				} else {
					if (!newJoin) {
						// gamex.addSystemMessage({
						// 	chatType: ChatType.ChatSystem,
						// 	t: update.t,
						// 	msg: `${userData.name} joined the server.`,
						// });
					} else {
						if (userData.mutedYou) {
							numMutedYou++;
						}
					}
				}
			}
			if (numMutedYou > 0) {
				gamex.addSystemMessage({
					chatType: ChatType.ChatSystem,
					t: update.t,
					msg: `${numMutedYou} player${numMutedYou > 1 ? 's' : ''} muted you.`,
				});
			}
			delete update.my.userAdd;
		}
		const state = { ...this.lastState, ...update.my } as ViewState;

		state.t = update.t;
		state.rt = update.rt;

		if (update.state) {
			state.state = update.state;
		}
		if (update.ranks) {
			state.ranks = update.ranks;
		}
		if (update.evts) {
			this.events.push(...update.evts);
			for (const evt of update.evts) {
				if (evt.type === EventType.Chat) {
					gamex.addChatMessage(evt as BroadcastChatEvent);
				} else if (evt.type === EventType.Mute) {
					const event = evt as BroadcastMuteEvent;
					const target = gamex.users[event.uid];
					if (target) {
						usersDirty = true;
						if (event.mt === MuteType.All) {
							target.mutedAll = event.v;
							if (event.uid === userx.uid) {
								gamex.addSystemMessage({
									chatType: ChatType.ChatSystem,
									t: update.t,
									msg: event.v ? 'You muted everone.' : 'Mute All canceled.',
								});
							}
						} else if (event.mt === MuteType.You) {
							target.mutedYou = event.v;
							const eventer = gamex.users[event.uid];
							if (eventer) {
								gamex.purgeChatSystemMessage(`${eventer.name} ${!event.v ? 'muted' : 'unmuted'} you`);
								gamex.addSystemMessage({
									chatType: ChatType.ChatSystem,
									t: update.t,
									msg: `${eventer.name} ${event.v ? 'muted' : 'unmuted'} you`,
								});
							}
						} else {
							target.muted = event.v;

							const eventer = gamex.users[event.uid];
							if (eventer) {
								gamex.addSystemMessage({
									chatType: ChatType.ChatSystem,
									t: update.t,
									msg: `You ${event.v ? 'muted' : 'unmuted'} ${eventer.name}`,
								});
							}
						}
					}
				} else if (evt.type === EventType.OuroborosOpen) {
					const e = evt as BroadcastOuroborosOpenEvent;
					state.ouroboros1X = e.x1;
					state.ouroboros1Y = e.y1;
					state.ouroboros2X = e.x2;
					state.ouroboros2Y = e.y2;
					state.animateOuroborus = Boolean(e.n);

				} else if (evt.type === EventType.OuroborosClose) {
					const e = evt as BroadcastOuroborosOpenEvent;
					state.ouroboros1X = -1;
					state.ouroboros1Y = -1;
					state.ouroboros2X = -1;
					state.ouroboros2Y = -1;

				} else if (evt.type === EventType.Whirlpool) {
					const e = evt as BroadcastWhirlpoolEvent;
					state.whirlpoolX = e.x;
					state.whirlpoolY = e.y;
					state.whirlpoolSpawnTime = e.t;
					state.whirlpoolEndTime = e.et;

				} else if (evt.type === EventType.Siren) {
					const e = evt as BroadcastWhirlpoolEvent;
					state.sirenX = e.x;
					state.sirenY = e.y;
					state.sirenSpawnTime = e.t;
					state.sirenEndTime = e.et;

				} else if (evt.type === EventType.SummonNemesis) {
					const event = evt as BroadcastEvent;
					const h = Global.$root.$createElement;
					Global.$root.$bvToast.toast('abc', {
						variant: 'dark',
						title: [
							h('span', { class: ['text-center', 'text-pink', 'w-100'] }, 'You have summoned the Nemeses.'),
						],
						toaster: 'b-toaster-bottom-center b-toaster-alert b-toaster-event',
						solid: false,
						autoHideDelay: 1500,
						noCloseButton: true,
						appendToast: true,
					});
					new SoundEfx('announcement').play();
					gamex.addSystemMessage({
						chatType: ChatType.Warning,
						t: update.t,
						senderName: 'Game Event',
						msg: 'You have summoned the Nemeses.',
					});
				} else if (evt.type === EventType.RecessGene) {
					const event = evt as BroadcastRecessGeneEvent;
					const gene = passiveSkillInfos[event.g].name;
					const h = Global.$root.$createElement;
					Global.$root.$bvToast.toast('abc', {
						variant: 'dark',
						title: [
							h('div', { class: ['text-center', 'text-light', 'w-100'] }, [
								'Recessive Gene ',
								h('span', { class: ['text-primary', 'fwb'] }, `'${gene}'`),
								' activated!',
							]),
						],
						toaster: 'b-toaster-bottom-center b-toaster-alert b-toaster-event',
						solid: false,
						autoHideDelay: 1500,
						noCloseButton: true,
						appendToast: true,
					});
					new SoundEfx('evolveEffect').play({ volume: .25 });
					gamex.addSystemMessage({
						chatType: ChatType.Warning,
						t: update.t,
						senderName: 'Game Event',
						msg: `Recessive Gene '${gene}' activated!`,
					});
				} else if (evt.type === EventType.SecretRoomFound) {
					const e = evt as BroadcastSecretRoomFoundEvent;
					state.explorerUid = e.uid;
					state.explorerName = e.n;
					state.idolDirection = e.d;

					if (e.pid) {
						state.explorerPid = e.pid;
						const h = Global.$root.$createElement;
						Global.$root.$bvToast.toast('abc', {
							variant: 'dark',
							title: [
								h('div', { class: ['text-center', 'text-light', 'w-100'] }, [
									h('span', { class: ['text-yellow', 'fwb'] }, e.n),
									' just discovered the Golden Idol!',
								]),
							],
							toaster: 'b-toaster-bottom-center b-toaster-alert b-toaster-event',
							solid: false,
							autoHideDelay: 1500,
							noCloseButton: true,
							appendToast: true,
						});
						new SoundEfx('announcement').play();
						gamex.addSystemMessage({
							chatType: ChatType.Warning,
							t: update.t,
							senderName: 'Game Event',
							msg: e.n + ' just discovered the Golden Idol!',
						});

					}
				} else if (evt.type === EventType.SecretRoomData) {
					const e = evt as BroadcastSecretRoomEvent;
					state.scrX = e.x;
					state.scrY = e.y;
					state.scrD = e.d;
				} else if (evt.type === EventType.ArmageddonExpose) {
					const e = evt as BroadcastArmageddonExposeEvent;

					const msg = `Exposing all ${e.l === 1 ? '1st' : '2nd'} life players.`;
					Global.$root.$bvToast.toast('abc', {
						variant: 'dark',
						title: msg,
						toaster: 'b-toaster-bottom-center b-toaster-alert b-toaster-event',
						solid: false,
						autoHideDelay: 1500,
						noCloseButton: true,
						appendToast: true,
					});
					new SoundEfx('announcement').play();
					gamex.addSystemMessage({
						chatType: ChatType.Warning,
						t: update.t,
						senderName: 'Game Event',
						msg,
					});
				} else if (evt.type === EventType.DojoCreated) {
					const event = evt as BroadcastDojoEvent;
					const target = gamex.users[event.uid];
					if (target) {
						usersDirty = true;
						target.dojoId = event.did;
					}
				} else if (evt.type === EventType.DojoInvited) {
					const event = evt as BroadcastDojoEvent;
					const target = gamex.users[event.uid];
					if (target) {
						usersDirty = true;
						target.dojoId = event.did;
						const msg = `${target.name} invited you to their dojo. Join them via the safe zone.`;
						gamex.purgeChatSystemMessage(msg);
						gamex.addSystemMessage({
							chatType: ChatType.ChatSystem,
							t: update.t,
							msg,
						});

					}
				} else if (evt.type === EventType.DojoInvites) {
					const event = evt as BroadcastDojoInvitesEvent;
					if (event.set) {
						gamex.updateDojoInvites(event.set);
					} else if (event.add) {
						gamex.updateDojoInvites([...gamex.dojoInvites, event.add]);
					} else if (event.remove) {
						arrayRemoveOne(gamex.dojoInvites, event.remove);
						gamex.updateDojoInvites([...gamex.dojoInvites]);
					}
					if (event.setM) {
						gamex.updateDojoMembers(event.setM);
					} else if (event.addM) {
						gamex.updateDojoMembers([...gamex.dojoMembers, event.addM]);
					} else if (event.removeM) {
						arrayRemoveOne(gamex.dojoMembers, event.removeM);
						gamex.updateDojoMembers([...gamex.dojoMembers]);
					}
					if (event.setS) {
						gamex.updateDojoSpectators(event.setS);
					} else if (event.addS) {
						gamex.updateDojoSpectators([...gamex.dojoSpectators, event.addS]);
					} else if (event.removeS) {
						arrayRemoveOne(gamex.dojoSpectators, event.removeS);
						gamex.updateDojoSpectators([...gamex.dojoSpectators]);
					}
				} else if (evt.type === EventType.DojoDisband) {
					const event = evt as BroadcastDojoDisbandEvent;
					const target = gamex.users[event.uid];
					if (target) {
						usersDirty = true;
						target.dojoId = 0;

						const msg = `${target.name} has disbanded their dojo.`;
						gamex.purgeChatSystemMessage(msg);
						gamex.addSystemMessage({
							chatType: ChatType.ChatSystem,
							t: update.t,
							msg,
						});
					}
				} else if (evt.type === EventType.DojoKicked) {
					const event = evt as BroadcastDojoEvent;
					const target = gamex.users[event.uid];
					if (target) {
						usersDirty = true;
						target.dojoId = 0;

						const msg = `${target.name} removed you from their dojo.`;
						gamex.purgeChatSystemMessage(msg);
						gamex.addSystemMessage({
							chatType: ChatType.ChatSystem,
							t: update.t,
							msg,
						});

					}
				} else if (evt.type === EventType.DojoHostChange) {
					const event = evt as BroadcastDojoHostChangeEvent;
					const target1 = gamex.users[event.uid1];
					let name1 = target1.name || gamex.userNameCaches[event.uid1] || `Stabfish_${event.uid1.slice(0, 3)}`;
					name1.charAt(name1.length - 1) === 's' ? name1 += '\'' : name1 += '\'s';
					if (target1) {
						usersDirty = true;
						target1.dojoId = 0;
					}
					const target2 = gamex.users[event.uid2];
					const name2 = target2.name || gamex.userNameCaches[event.uid2] || `Stabfish_${event.uid1.slice(0, 3)}`;

					if (target2) {
						usersDirty = true;
						target2.dojoId = event.did;
					}
					const msg = `${name2} has taken over ${name1} dojo.`;
					gamex.purgeChatSystemMessage(msg);
					gamex.addSystemMessage({
						chatType: ChatType.ChatSystem,
						t: update.t,
						msg,
					});
				}
			}
		}
		if (usersDirty) {
			gamex.updateUsers({ ...gamex.users });
		}
		if (update.ts) {
			state.ts = update.ts;
		}
		if (update.ors) {
			state.ors = update.ors;
		}
		if (update.bottles) {
			state.bottles = update.bottles;
		}
		if (update.hzds) {
			state.hzds = update.hzds;
			for (const data of update.hzds) {
				if (!state.hazards[data.id]) {
					state.hazards[data.id] = data;
				} else {
					state.hazards[data.id].active = data.active;
				}
			}
		}
		if (update.ps) {
			state.ps = update.ps;
			for (const data of update.ps) {
				if (!state.players[data.id]) {
					state.players[data.id] = getDefaultPlayerData();
				}
				const pd = state.players[data.id];
				if (data.sbs) {
					const stababs = {};
					for (const stabab of data.sbs) {
						if (!pd.stababs[stabab.id]) {
							stababs[stabab.id] = { ...getDefaultStababData(), ...stabab };
						} else {
							stababs[stabab.id] = { ...pd.stababs[stabab.id], ...stabab };
						}
					}
					pd.stababs = stababs;
				}
				delete data.sbs;
				state.players[data.id] = { ...pd, ...data };
			}
		}
		if (update.fds) {
			state.fds = update.fds;
			for (const data of update.fds) {
				if (!state.foods[data.id]) {
					state.foods[data.id] = { ...getDefaultFoodData(), ...data };
				} else {
					state.foods[data.id] = { ...state.foods[data.id], ...data };
				}
			}
		}
		if (update.mis) {
			state.mis = update.mis;
			for (const data of update.mis) {
				if (!state.miscs[data.id]) {
					state.miscs[data.id] = data as MiscData;
				} else {
					if (data.cs) {
						for (let i = 0; i < data.cs.length; i++) {
							const oldData = state.miscs[data.id].cs![i];
							data.cs[i] = { ...oldData, ...data.cs[i] };
						}
					}
					state.miscs[data.id] = { ...state.miscs[data.id], ...data };
				}
			}
		}
		this.lastState = JSON.parse(JSON.stringify(state));

		// const pid = state.pid;
		// const me = state.players[pid];
		// serverBoost(me && me.skill1);

		this.gameUpdates.push(state);

		const tDiff = Date.now() - state.t;
		if (tDiff > this.gameStart - this.firstServerTimestamp + 10) {
			this.gameStart += 2;
			// console.log('gs', this.gameStart - initialGameStart);
		} else if (tDiff < this.gameStart - this.firstServerTimestamp - 10) {
			this.gameStart -= 2;
			// console.log('gs', this.gameStart - initialGameStart);
		}

		// Keep only one game update before the current server time
		const index = this.getUpdateIndex();
		if (index > 0) {
			// this.renderDelay--;
			this.gameUpdates.splice(0, index);
		}
	}

	// Returns { me, others, bullets }
	public getCurrentState(): ViewState {
		if (!this.firstServerTimestamp || this.gameUpdates.length === 0) {
			return getDefaultViewState();
		}

		const index = this.getUpdateIndex();
		const serverTime = this.currentServerTime();

		// If base is the most recent update we have, use its state.
		// Otherwise, interpolate between its state and the state of (base + 1).
		if (index < 0 || index === this.gameUpdates.length - 1) {
			if (this.noNext > 0) {
				// console.log('no next', noNext);
				if (this.renderDelay < this.maxRenderDelay) {
					this.renderDelay++;
					// console.log('renderDelay', this.renderDelay);
				}
			}
			this.noNext++;
			const baseUpdate = this.gameUpdates[this.gameUpdates.length - 1];
			const result = { ...baseUpdate };
			result.evts = this.events.splice(0);
			return result;
		} else {
			this.noNext = 0;
			// if (this.renderDelay > 100 && base < this.gameUpdates.length - 2) this.renderDelay--;
			const baseUpdate = this.gameUpdates[index];
			const next = this.gameUpdates[index + 1];
			const ratio = (serverTime - baseUpdate.t) / (next.t - baseUpdate.t);
			const rt = this.interpolate(baseUpdate.rt, next.rt, ratio);

			const result: ViewState = {
				...baseUpdate,
			};
			result.t = serverTime;
			result.rt = rt;
			result.evts = this.interpolateEvents(this.events, rt);
			if (baseUpdate.state !== PlayerGameState.Waiting) {
				result.x = this.interpolatePosition(baseUpdate.x, next.x, ratio);
				result.y = this.interpolatePosition(baseUpdate.y, next.y, ratio);
				result.hp = this.interpolate(baseUpdate.hp, next.hp, ratio);
				result.stamina = this.interpolate(baseUpdate.stamina, next.stamina, ratio);
				result.oxy = this.interpolate(baseUpdate.oxy, next.oxy, ratio);
			}
			result.blood = this.interpolate(baseUpdate.blood, next.blood, ratio);
			result.skillCooldown2 = this.interpolate(baseUpdate.skillCooldown2, next.skillCooldown2, ratio);
			result.scoreL = this.interpolate(baseUpdate.scoreL, next.scoreL, ratio);
			result.money = this.interpolate(baseUpdate.money, next.money, ratio);

			result.players = {};
			for (const id in baseUpdate.players) {
				if (Object.prototype.hasOwnProperty.call(baseUpdate.players, id)) {
					const obj1 = baseUpdate.players[id];
					const obj2 = next.players[id];
					if (obj2) {
						result.players[id] = this.interpolatePlayer(obj1, obj2, ratio);
					} else {
						result.players[id] = { ...obj1 };
					}
				}
			}
			result.foods = {};
			for (const id in baseUpdate.foods) {
				if (Object.prototype.hasOwnProperty.call(baseUpdate.foods, id)) {
					const obj1 = baseUpdate.foods[id];
					const obj2 = next.foods[id];
					if (obj2) {
						result.foods[id] = this.interpolateFood(obj1, obj2, ratio);
					} else {
						result.foods[id] = { ...obj1 };
					}
				}
			}
			result.miscs = {};
			for (const id in baseUpdate.miscs) {
				if (Object.prototype.hasOwnProperty.call(baseUpdate.miscs, id)) {
					const obj1 = baseUpdate.miscs[id];
					const obj2 = next.miscs[id];
					if (obj2) {
						result.miscs[id] = this.interpolateMisc(obj1, obj2, ratio) as MiscData;
					} else {
						result.miscs[id] = { ...obj1 };
					}
				}
			}
			result.ts = [];
			for (const tracker1 of baseUpdate.ts) {
				const tracker2 = next.ts.find((t) => t.id === tracker1.id && t.type === tracker1.type);
				if (tracker2) {
					result.ts.push(this.interpolateTracker(tracker1, tracker2, ratio));
				} else {
					result.ts.push(tracker1);
				}
			}
			// const player = Object.values(result.players)[0];
			// if (player)
			// 	console.log(player.x, result.x, player.y, result.y);
			return result;
		}
	}

	protected interpolateEvents(evts: BroadcastEvent[], t: number) {
		let index = -1;
		for (let i = 0; i < evts.length; i++) {
			const evt = evts[i];
			if (evt.t <= t) {
				index = i;
			} else { break; }
		}
		if (index !== -1) {
			return evts.splice(0, index + 1);
		} else {
			return [];
		}
	}

	protected currentServerTime() {
		return this.firstServerTimestamp + (Date.now() - this.gameStart) - this.renderDelay;
	}

	protected serverTimeToLocalTime(t: number) {
		return t - this.firstServerTimestamp + this.gameStart + this.renderDelay;
	}

	// Returns the index of the base update, the first game update before
	// current server time, or -1 if N/A.
	protected getUpdateIndex() {
		const serverTime = this.currentServerTime();
		for (let i = this.gameUpdates.length - 1; i >= 0; i--) {
			if (this.gameUpdates[i].t <= serverTime) {
				return i;
			}
		}
		return -1;
	}


	protected interpolatePlayer(object1: PlayerData, object2: PlayerData, ratio: number, tell = false) {
		const interpolated = { ...object1 };
		interpolated.x = this.interpolate(object1.x, object2.x, ratio);
		interpolated.y = this.interpolate(object1.y, object2.y, ratio);
		interpolated.hp = this.interpolate(object1.hp, object2.hp, ratio);
		interpolated.bodyRadius = this.interpolate(object1.bodyRadius, object2.bodyRadius, ratio);
		interpolated.stun = this.interpolate(object1.stun, object2.stun, ratio);

		interpolated.deg = this.interpolateDirection(object1.deg!, object2.deg!, ratio);

		for (const stababId in object1.stababs) {
			if (Object.prototype.hasOwnProperty.call(object1.stababs, stababId)) {
				const stabab1 = object1.stababs[stababId];
				const stabab2 = object2.stababs[stababId];
				if (stabab2) {
					interpolated.stababs[stababId] = this.interpolateStabab(stabab1, stabab2, ratio);
				} else {
					interpolated.stababs[stababId] = { ...stabab1 };
				}

			}
		}
		return interpolated;
	}
	protected interpolateStabab(object1: StababData, object2: StababData, ratio: number, tell = false) {
		const interpolated = { ...object1 };
		interpolated.dist = this.interpolate(object1.dist, object2.dist, ratio);
		interpolated.scale = this.interpolate(object1.scale, object2.scale, ratio);

		return interpolated;
	}
	protected interpolateFood(object1: FoodData, object2: FoodData, ratio: number, tell = false) {
		const interpolated = { ...object1 };
		interpolated.x = this.interpolate(object1.x, object2.x, ratio);
		interpolated.y = this.interpolate(object1.y, object2.y, ratio);
		interpolated.tx = this.interpolate(object1.tx, object2.tx, ratio);
		interpolated.ty = this.interpolate(object1.ty, object2.ty, ratio);

		return interpolated;
	}
	protected interpolateMisc(object1: Interpolatable, object2: Interpolatable, ratio: number, tell = false) {
		const interpolated = { ...object1 };
		interpolated.x = this.interpolate(object1.x, object2.x, ratio);
		interpolated.y = this.interpolate(object1.y, object2.y, ratio);
		if (interpolated.sc !== undefined) {
			interpolated.sc = this.interpolate(object1.sc!, object2.sc!, ratio);
		}
		if (interpolated.d !== undefined) {
			interpolated.d = this.interpolateDirection(object1.d!, object2.d!, ratio);
		}
		if (interpolated.cs) {
			interpolated.cs = interpolated.cs.map((c, i) => {
				return this.interpolateMisc(object1.cs![i], object2.cs![i], ratio);
			});
		}
		return interpolated;
	}
	protected interpolateTracker(object1: TrackerData, object2: TrackerData, ratio: number, tell = false) {
		if (!object2) {
			return object1;
		}
		const interpolated = { ...object1 };
		interpolated.x = this.interpolate(object1.x, object2.x, ratio);
		interpolated.y = this.interpolate(object1.y, object2.y, ratio);

		return interpolated;
	}

	protected interpolate(n1: number, n2: number, ratio: number) {
		if (n2 !== n2) { return n1; }
		return n1 + (n2 - n1) * ratio;
	}
	protected interpolatePosition(n1: number, n2: number, ratio: number) {
		if (n2 !== n2) { return n1; }
		const d = n2 - n1;
		if (d > 100 || d < -100) {
			return n2;
		}
		return n1 + (d) * ratio;
	}

	protected interpolateDirection(d1: number, d2: number, ratio: number) {
		const diff = Rotate.diff(d1, d2) * ratio;
		return d1 + diff;
	}

}
