import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsSnowball } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';

export class SsSnowballSymbol extends MovieClip {

}
export class SsSnowBallController implements MiscSymbol {

	public static get() {
		return SsSnowBallController._pool.get();
	}

	private static _pool: Pool<SsSnowBallController> = new Pool(SsSnowBallController);


	public data: MiscData | null = null;
	public pt = 0;

	public symbol = Factory.get(SsSnowballSymbol);

	public dispose(room: RoomControl) {
		this.data = null;
		Tween.get(this.symbol).to({ alpha: 0 }, 300).call(() => {
			if (this.symbol.parent) {
				this.symbol.parent.removeChild(this.symbol);
			}
			SsSnowBallController._pool.pool(this);
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsSnowball;

		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.symbol.scale.set(data.sc);


		if (!this.data) {
			// this.scale.set(0.01);
			this.symbol.alpha = 1;
			this.symbol.angle = 10;
		} else {
			this.symbol.angle -= 0.05;
		}
		this.data = data;

	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this.symbol);
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
	}
}
