export class Grid {
	public trunc = Math.trunc || function(val: number) { // (number) -> number
		return val - val % 1;
	};
	protected _cells: Array<Array<Array<[number, number]>>> = [];
	protected _cellSize: number;
	protected _reverseCellSize: number;

	constructor(points: Array<[number, number]>, cellSize: number) {
		this._cellSize = cellSize;
		this._reverseCellSize = 1 / cellSize;

		for (const point of points) {
			const x = this.coordToCellNum(point[0]);
			const y = this.coordToCellNum(point[1]);
			if (!this._cells[x]) {
				const array: Array<Array<[number, number]>> = [];
				array[y] = [point];
				this._cells[x] = array;
			} else if (!this._cells[x][y]) {
				this._cells[x][y] = [point];
			} else {
				this._cells[x][y].push(point);
			}
		}
	}
	public coordToCellNum(x: number) { // (number) -> number
		return this.trunc(x * this._reverseCellSize);
	}
	public extendBbox(bbox: [number, number, number, number], scaleFactor: number): [number, number, number, number] { // (Array, Number) -> Array
		return [
			bbox[0] - (scaleFactor * this._cellSize),
			bbox[1] - (scaleFactor * this._cellSize),
			bbox[2] + (scaleFactor * this._cellSize),
			bbox[3] + (scaleFactor * this._cellSize),
		];
	}

	public removePoint(point: [number, number]) { // (Array) -> Array
		const cellX = this.coordToCellNum(point[0]);
		const cellY = this.coordToCellNum(point[1]);
		const cell = this._cells[cellX][cellY];
		let pointIdxInCell: number = 0;

		for (let i = 0; i < cell.length; i++) {
			if (cell[i][0] === point[0] && cell[i][1] === point[1]) {
				pointIdxInCell = i;
				break;
			}
		}

		cell.splice(pointIdxInCell, 1);

		return cell;
	}
	public cellPoints(x: number, y: number) { // (Number, Number) -> Array
		return (this._cells[x] !== undefined && this._cells[x][y] !== undefined) ? this._cells[x][y] : [];
	}

	public rangePoints(bbox: [number, number, number, number]) { // (Array) -> Array
		const tlCellX = this.coordToCellNum(bbox[0]);
		const tlCellY = this.coordToCellNum(bbox[1]);
		const brCellX = this.coordToCellNum(bbox[2]);
		const brCellY = this.coordToCellNum(bbox[3]);
		const points: Array<[number, number]> = [];

		for (let x = tlCellX; x <= brCellX; x++) {
			for (let y = tlCellY; y <= brCellY; y++) {
				// replaced Array.prototype.push.apply to avoid hitting stack size limit on larger arrays.
				const list = this.cellPoints(x, y);
				for (const point of list) {
					points.push(point);
				}
			}
		}

		return points;
	}
}
