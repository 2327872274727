






import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ItemInventory from './ItemInventory.vue';

@Component({
  components: {
    ItemInventory,
  },
})
export default class DecosTab extends Vue {
  public get items(): ItemCodeAmountPair[] {
    return userx.decos;
  }
}
