










































import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class HowToPlay extends Vue {
	public playTutorial() {
		Global.$root.$emit('playTutorial');
	}
}
