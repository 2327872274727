import { PlayerControl } from '@/client/controls/playerControl';
import Factory from '@/client/factory';
import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import { decodeDecoSet, DecoSet } from '@/game/infos/decorativeInfos';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { Scene } from 'pixi-animate';
import { HighscoreData, ArmageddonHighscoreData, CraftingHighscoreData } from '@/game/infos/highscoreInfos';
import { spearLvlRequirements, SpearType } from '@/game/infos/spearInfos';
import { Preset } from '@/game/infos/preset';
import { Decoratives } from '../decoEditor/decorative';
import { allTextureLoaded } from '@/client/factory/helper';
import { Global } from '@/store/globalz';
import { Container } from 'pixi.js';
import { SkinGroup } from '@/game/infos/skinInfos';

const scale = 0.75;
const app = Global.fishApp;
const w = app.view.width;
const h = app.view.height;

const stage = app.stage;
const container = new Container();
stage.addChild(container);
container.angle = 0;
container.y = h / 2;
container.x = w / 2;
container.scale.set(scale);
let symbol: PlayerSymbol;

export async function generateFishImage(fishType: FishType, skinGroup: SkinGroup, decoSet: DecoSet | null) {
	if (!symbol) {
		symbol = PlayerSymbol.get();
		symbol.setAction('idle');
		container.addChild(symbol);
		symbol.miniSpike.visible = true;
		symbol.spear.visible = false;
	}
	if (!fishType) {
		fishType = 3;
	}
	// const bodyScale = fishInfos[fishType].stat.size;
	await symbol.setFishType(fishType, skinGroup);
	// symbol.setBodyScale(bodyScale);
	Decoratives.generateDeco(symbol, decoSet);
	await allTextureLoaded(symbol);
	app.start();
	app.render();
	app.stop();
	const result = app.view.toDataURL();
	return result;
}
