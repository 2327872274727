import { disposeItemSprite, getItemSprite, ItemSprite } from '@/client/factory/itemParticles';
import { ItemCode, itemInfos, Rarity } from '@/game/infos/itemInfos';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import { Pool } from '@/util/pool';
import { BitmapText, Container, ParticleContainer, Sprite, Ticker } from 'pixi.js';
import { LegendaryItemBlingEffectControl } from '../effects/legendaryItemBlingEfxControl';

export class GameBag extends Container {

	public items: GameBagItem[] = [];
	public itemContainer = new Container();
	public effectsContainer = new ParticleContainer(100, { vertices: true, position: true, rotation: true, tint: false });

	public loop = this._loop.bind(this);
	protected lastBlingTime = 0;
	protected size = 80;

	public constructor() {
		super();
		this.addChild(this.itemContainer, this.effectsContainer);
		Ticker.shared.add(this.loop);
	}

	public update(items: ItemCodeAmountPair[], numColumns: number, numRows: number, size: number) {
		const len = numColumns * numRows;
		if (this.items.length < len) {
			for (let i = this.items.length; i < len; i++) {
				this.items.push(GameBagItem.get());
			}
		} else if (this.items.length > len) {

			for (let i = len; i < this.items.length; i++) {
				this.items[i].dispose();
			}
			this.items.length = len;
		}

		for (let i = 0; i < len; i++) {
			const item = this.items[i];
			this.itemContainer.addChild(item);
			item.update(items[i]);
			item.x = i % numColumns * size;
			item.y = Math.floor(i / numColumns) * size;
			item.scale.set(size / 80);
		}
		this.size = size;
	}
	public destroy() {
		super.destroy();
		Ticker.shared.remove(this.loop);
		for (const item of this.items) {
			item.dispose();
		}
	}
	protected _loop() {
		const blingTime = Math.floor(Date.now() / 500);
		const canBling = blingTime !== this.lastBlingTime;
		if (canBling) {
			this.lastBlingTime = blingTime;

			for (const item of this.items) {
				if (item.isLegendary) {

					const rx = item.x + 10 + Math.random() * (this.size - 20);
					const ry = item.y + 10 + Math.random() * (this.size - 20);
					const bling = LegendaryItemBlingEffectControl.get(rx, ry);
					this.effectsContainer.addChild(bling.symbol);
				}
			}
		}
	}
}

export class GameBagItem extends Container {
	public static get() {
		const control = GameBagItem._pool.get();
		return control;
	}
	private static _pool: Pool<GameBagItem> = new Pool(GameBagItem);

	public isLegendary = false;
	public symbol: Sprite | null = null;
	public text = new BitmapText('x1', { fontName: 'TitanOneShaded', fontSize: 25, align: 'right' });
	public itemCode = 0;

	constructor() {
		super();
		this.addChild(this.text);
		this.text.x = 78;
		this.text.y = 78;
		this.text.anchor.set(1, 1);
	}

	public update(pair: ItemCodeAmountPair | undefined) {
		if (pair) {
			this.text.text = `x${pair.amount}`;
			this.text.visible = true;
			if (this.itemCode !== pair.itemCode) {
				this.itemCode = pair.itemCode;

				if (this.symbol) {
					disposeItemSprite(this.symbol);
					this.removeChild(this.symbol);
				}
				this.symbol = getItemSprite(pair.itemCode, 40, 40);
				this.addChildAt(this.symbol, 0);
				const info = itemInfos[pair.itemCode];
				this.isLegendary = (info?.rarity || 1) === Rarity.Legendary;

			}

		} else {
			this.clear();
		}

	}

	public clear() {

		this.isLegendary = false;
		this.text.visible = false;
		this.itemCode = 0;
		if (this.symbol) {
			disposeItemSprite(this.symbol);
		}
	}

	public dispose() {
		if (this.parent) {
			this.parent.removeChild(this);
		}
		this.clear();
		GameBagItem._pool.pool(this);
	}
}
