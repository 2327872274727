import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { Rarity } from '@/game/infos/itemInfos';
import { epicSkins, legendarySkins, SkinGroup } from '@/game/infos/skinInfos';
import { Global } from '@/store/globalz';
import assetx from '@/store/modules/assetx';
import { Dictionary } from '@/util/dictionary';
import { nonReact } from '@/util/nonReact';
import { Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { BLEND_MODES, Container, Graphics, Loader, Sprite, Text } from 'pixi.js';
import { SkinGachaControl } from '../controls/skinGachaControl';
import { blend, rgbToInteger, tint } from './helper';

declare const gac: any;
let loading = false;

const assetLoader = nonReact(new Loader());
for (const id in gac.gachaAssets.assets) {
	if (Object.prototype.hasOwnProperty.call(gac.gachaAssets.assets, id)) {
		const asset = gac.gachaAssets.assets[id];
		assetLoader.add(id, asset);
	}
}
export function loadGachaAssets() {
	if (loading) {
		return;
	}
	assetx.updateGachaLoadState('loading');
	loading = true;
	assetLoader.onComplete.once(onLoadComplete);
	assetLoader.onError.add(onError);
	assetLoader.onLoad.add(onLoad);
	assetLoader.load();
}

function onError(error: Error, loader: Loader, resources: any) {
	assetx.addLoadError(error);
}
function onLoad(loader: Loader, resource: any) {
	assetx.updateGachaLoadProgress(loader.progress);
	assetx.updateGachaLoadState('loading');
}
function onLoadComplete(loader: Loader, resources: Dictionary<any>) {
	assetx.updateGachaLoadState('completed');
	Global.skinGachaControl = nonReact(new SkinGachaControl());
	Global.skinGachaControl.resize(Global.width, Global.height);
}
export class GachaAnimation extends MovieClip {
	public portal!: PortalScene;
	public side!: SideScene;
	public init() {
		this.removeMovieClips();
		// cuztom event in fla 'completed';
		this.on('completed', this.completed);
	}

	public start(isMultiple = false, isSsr = false) {
		this.portal.symbol10.visible = this.side.symbol10.visible = this.side.symbol3.visible = isMultiple;
		this.side.ssr.visible = isSsr;
		this.gotoAndPlay(0);
	}

	public removeMovieClips() {
		this.removeChild(this.side);
		this.removeChild(this.portal);
	}

	public completed() {
		// removing movieclips to purge them from Ticker;
		this.stop();
		this.removeMovieClips();
		this.portal.removeMovieClips();
		this.side.removeMovieClips();
	}
}
export class PortalScene extends MovieClip {
	public symbol10!: MovieClip;
	public init() {
		this.removeMovieClips();
	}
	public removeMovieClips() {
		this.removeChild(this.symbol10);
	}
}
export class SideScene extends MovieClip {
	public ssr!: MovieClip;
	public symbol3!: MovieClip;
	public symbol10!: MovieClip;
	public init() {
		this.removeMovieClips();
	}
	public removeMovieClips() {
		this.removeChild(this.ssr);
		this.removeChild(this.symbol3);
		this.removeChild(this.symbol10);
	}
}


export class GachaReveal extends MovieClip {
	public skin!: GachaRevealSkin;
	public bgSkin!: GachaRevealSkin;
	public label!: GachaSkinLabel;
	public bg!: MovieClip;
	public container!: Container;
	public init() {
		blend(this.bg, BLEND_MODES.ADD);
		this.removeMovieClips();
		// cuztom event in fla 'completed';
		this.on('completed', this.completed);
		this.on('sound2', this.sound2);
	}

	public start(fishType: FishType, skinGroup: SkinGroup) {
		this.gotoAndPlay(0);
		this.label.gotoAndPlay(0);
		this.bg.gotoAndPlay(0);
		this.skin.brightness = 0;
		this.skin.container.removeChildren();
		this.bgSkin.container.removeChildren();

		const url = getSkinImgUrl(skinGroup, fishType);
		this.skin.container.addChild(Sprite.from(url));
		this.bgSkin.container.addChild(Sprite.from(url));

		let rarity = Rarity.Rare;
		if (epicSkins[skinGroup]?.includes(fishType)) {
			rarity = Rarity.Epic;
		} else if (legendarySkins[skinGroup]?.includes(fishType)) {
			rarity = Rarity.Legendary;
		}
		this.setRarity(rarity);

		const fishInfo = fishInfos[fishType];
		const name = fishInfo.name.replaceAll(' ', '\n');
		this.label.title.text = name;
	}

	public removeMovieClips() {
		this.removeChild(this.skin);
		this.removeChild(this.label);
		this.removeChild(this.bg);
		this.removeChild(this.bgSkin);
	}

	public completed() {
		// removing movieclips to purge them from Ticker;
		this.stop();
	}

	public sound2() {
		Tween.get(this.skin, { override: true }).to({ brightness: 1 }, 500);
	}

	public setRarity(rarity: Rarity) {
		if (rarity === Rarity.Rare) {
			tint(this.bg, 0x7F5B00);
		} else if (rarity === Rarity.Epic) {
			tint(this.bg, 0x337F19);
		} else if (rarity === Rarity.Legendary) {
			tint(this.bg, 0x7F4C7F);
		}
		this.label.setRarity(rarity);
	}
}
export class GachaRevealSkin extends Container {
	public container!: Container;
	protected _brightness: number = 1;
	public get brightness() {
		return this._brightness;
	}
	public set brightness(value: number) {
		if (this._brightness === value) { return; }
		this._brightness = value;
		const v = value = 255;
		const color = rgbToInteger(v, v, v);
		tint(this, color);
	}
}
export class GachaSkinLabel extends MovieClip {
	public rare!: Container;
	public epic!: Container;
	public legendary!: Container;
	public logo!: Container;

	public title = new Text('', {
		fontFamily: 'Titan One',
		fontSize: 40,
		fill: 0xffffff,
		align: 'left',
	});

	public init() {
		this.addChild(this.title);
		(this.logo.children[0] as Sprite).blendMode = BLEND_MODES.ADD;
	}

	public setRarity(rarity: Rarity) {
		this.rare.visible = this.epic.visible = this.legendary.visible = false;
		if (rarity === Rarity.Rare) {
			this.rare.visible = true;
			tint(this.logo, 0xffcc00);
		} else if (rarity === Rarity.Epic) {
			this.epic.visible = true;
			tint(this.logo, 0x66ff33);
		} else if (rarity === Rarity.Legendary) {
			this.legendary.visible = true;
			tint(this.logo, 0xff66ff);
		}
	}
}

export function getSkinImgUrl(skinGroup: SkinGroup, fishType: FishType) {
	let fish = fishType.toString();
	while (fish.length < 4) {
		fish = '0' + fish;
	}
	return `images/skins/skin${skinGroup}/skinFull${fish}.png?v=2a`;
}

export function getSkinMiniImgUrl(skinGroup: SkinGroup, fishType: FishType) {
	let fish = fishType.toString();
	while (fish.length < 4) {
		fish = '0' + fish;
	}
	return `images/skinsMini/skin${skinGroup}/skinMini${fish}.png?v=2a`;
}
