









import assetx from '@/store/modules/assetx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import throttle from 'lodash/throttle';
import { Container, Graphics } from 'pixi.js';
import UiControlComponent from '../ui/game/UiControlComponent.vue';
import OverlayGameRoomDebug from '../ui/game/OverlayGameRoomDebug.vue';
import GameOverOverlay from '../ui/game/GameOverOverlay.vue';
import gamex from '@/store/modules/gamex';
import { GameType } from '@/game/infos/roomInfos';

@Component({
  components: {
    UiControlComponent,
    OverlayGameRoomDebug,
    GameOverOverlay,
  },
})
export default class GameStageComponent extends Vue {
  public get socketErrorMessage() {
    return gamex.socketErrorMessage;
  }

  public get app() {
    return assetx.mainApp;
  }

  @Watch('socketErrorMessage')
  public async socketErrorMessageChanged(value: string) {
    await this.$bvModal.msgBoxOk(value, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Connection Lost`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
    if (gamex.gameType === GameType.Tourney) {
      gamex.setGameStage('TourneyList');
    } else {
      gamex.setGameStage('Reward');
    }
  }
  public mounted() {
    (this.$refs.stage as HTMLDivElement).appendChild(this.app.view);
    this.app.page = 'game';
  }
  public beforeDestroy() {
    (this.$refs.stage as HTMLDivElement).removeChild(this.app.view);
    if (this.app.page === 'game') {
      this.app.page = 'none';
    }
  }
}
