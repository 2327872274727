import { getParticle, Particle } from '@/client/factory/particles';
import { BroadcastDamageEvent } from '@/game/multithread/state';
import { Pool } from '@/util/pool';
import { Tween } from '@/util/tweents';

export class CutEffectControl {

	public static get(event: BroadcastDamageEvent) {
		const control = CutEffectControl._pool.get();
		control.set(event);
		return control;
	}
	private static _pool: Pool<CutEffectControl> = new Pool(CutEffectControl);

	public symbol = getParticle(Particle.Cut);

	public set(event: BroadcastDamageEvent) {
		this.symbol.x = event.pos.x;
		this.symbol.y = event.pos.y;
		this.symbol.scale.set(0.5);
		this.symbol.angle = Math.random() * 360; // Rotate.degree(0, 0, event.norm.x, event.norm.y);
		Tween.get(this.symbol.scale).to({ x: 1, y: 2 }, 100).call(this.dispose.bind(this));
	}

	public dispose() {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		CutEffectControl._pool.pool(this);
	}
}
