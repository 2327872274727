import { EmojiType } from '@/game/infos/emojiInfos';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Container } from 'pixi.js';
import { getAllTimedChildren } from '../helper';


export class AllEmojis extends MovieClip {
}

export interface EmojiSymbol extends Container {
	emojiType: EmojiType;
}

const emojis: Array<Pool<EmojiSymbol>> = [];

export function registerEmojis(symbol: AllEmojis) {

	const list = emojis;
	// const timeline = (symbol as any)._timelines;
	const children = getAllTimedChildren(symbol);
	// console.log(symbol);
	for (let i = 0; i < children.length; i++) {
		const child = children[i];
		const symbolClass = child.constructor;
		list[i] = new Pool(symbolClass);
	}
	// console.log(symbol);
	symbol.destroy();
}

export function getEmojiSymbol(emojiType: EmojiType) {
	const symbol = emojis[emojiType].get();
	symbol.emojiType = emojiType;
	return symbol;
}
export function disposeEmojiSymbol(emojiSymbol: Container) {
	const symbol = emojiSymbol as EmojiSymbol;
	emojis[symbol.emojiType].pool(symbol);
	return symbol;
}
