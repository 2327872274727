import { StageControl } from '@/client/controls/stageControl';
import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import { IClientCommunicator } from '@/client/io/iclientCommunicator';
import { Vue } from 'vue-property-decorator';
import DecoItemInventoryModal from '@/components/ui/mainMenu/tools/inventory/DecoItemInventoryModal.vue';
import DecoSkinInventoryModal from '@/components/ui/mainMenu/tools/inventory/DecoSkinInventoryModal.vue';
import FishInventoryModal from '@/components/ui/mainMenu/tools/inventory/FishInventoryModal.vue';
import FishUnlockModal from '@/components/ui/afterGameReport/FishUnlockModal.vue';
import ContextMenu from '@/components//ui/ContextMenu.vue';
import CraftModal from '@/components/ui/mainMenu/bag/CraftModal.vue';
import ConvertDuplicatesModal from '@/components/gacha/ConvertDuplicatesModal.vue';
import ConvertAllDuplicatesModal from '@/components/gacha/ConvertAllDuplicatesModal.vue';
import PoqLinkModal from '@/components/gacha/poq/PoqLinkModal.vue';
import ShopModal from '@/components/shop/ShopModal.vue';
import CnyShopModal from '@/components/shop/CnyShopModal.vue';
import { StabfishFirebaseSocket } from './sf-firestore';
import { BvModal } from 'bootstrap-vue';
import { nonReact } from '@/util/nonReact';
import { EventEmitter } from '@pixi/utils';
import { ClientCommunicator } from '@/client/io/clientCommunicator';
import { OfflineCommunicator } from '@/io/offlineCommunicator';
import { Container } from 'pixi.js';
import { Scene } from 'pixi-animate';
import { SoundGroup } from '@/client/sound/SoundGroup';
import settingx from './modules/settingx';
import { SkinGachaControl } from '@/client/controls/skinGachaControl';
import globalx from './modules/globalx';
import gamex from './modules/gamex';
import { MainMenuControl } from '@/client/controls/mainMenuControl';
import { DecoEditorControl } from '@/client/controls/decoEditorControl';
import { FishSelectorControl } from '@/client/controls/fishSelectorControl';

declare const sub: any;
declare const adsdk: 'crazyads' | 'aipads';

/** avatar for deco custamization, cannot create right away because need to wait the assets loaded */
let modelAvatar: PlayerSymbol;

/** avatars for main menu */

let avatars: PlayerSymbol[];

/** avatar for fish selection */
let selectedAvatar: PlayerSymbol;

/** stageControl */
let stageControl: StageControl;
let mainMenuControl: MainMenuControl;
let decoEditorControl: DecoEditorControl;
let fishSelectorControl: FishSelectorControl;
let blindScreen: Container;

export let alertAdmin = '';

const Communicator =
	// process.env.NODE_ENV !== 'production' ? OfflineCommunicator : ClientCommunicator; alertAdmin = process.env.NODE_ENV === 'production' ? 'Communicator' : '';
	ClientCommunicator;

const clientCommunicator: IClientCommunicator = nonReact(new Communicator());

export class Global {

	public static mainMenuIndex = 0;

	public static adsdk = adsdk;

	public static width = document.documentElement.clientWidth;
	public static height = document.documentElement.clientHeight;

	public static $root: Vue;
	public static $bvModal: BvModal;
	public static hasMaintenance = StabfishFirebaseSocket.hasMaintenance;
	public static overlayGameIndicators = nonReact(new Container());
	public static overlayCanvasStage: Container;
	public static event = nonReact(new EventEmitter());
	public static get modelAvatar() {
		modelAvatar = modelAvatar || nonReact(PlayerSymbol.get());

		return modelAvatar;
	}
	public static get selectedAvatar() {
		selectedAvatar = selectedAvatar || nonReact(PlayerSymbol.get());
		return selectedAvatar;
	}
	public static get avatars() {
		avatars = avatars || [nonReact(PlayerSymbol.get()), nonReact(PlayerSymbol.get()), nonReact(PlayerSymbol.get())];
		// avatar.spear.visible = false;
		return avatars;
	}
	public static get stageControl() {
		if (!stageControl) {
			stageControl = nonReact(new StageControl());
			clientCommunicator.stageControl = stageControl;
		}
		return stageControl;
	}
	public static get mainMenuControl() {
		if (!mainMenuControl) {
			mainMenuControl = nonReact(new MainMenuControl());
		}
		return mainMenuControl;
	}
	public static get decoEditorControl() {
		if (!decoEditorControl) {
			decoEditorControl = nonReact(new DecoEditorControl());
		}
		return decoEditorControl;
	}
	public static get fishSelectorControl() {
		if (!fishSelectorControl) {
			fishSelectorControl = nonReact(new FishSelectorControl());
		}
		return fishSelectorControl;
	}
	public static get blindScreen() {
		if (!blindScreen) {
			blindScreen = nonReact(new sub.BlindScreen());
		}
		return blindScreen;
	}

	public static get clientCommunicator(): IClientCommunicator {
		return clientCommunicator;
	}

	public static rarityLabels = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];
	public static rarityColors = ['#CCCCCC', '#F3C900', '#33CC99', '#3399FF', '#a863ff'];
	public static rarityRgbs = Global.rarityColors.map((c) => hexToRgb(c));
	public static decoInvetoryModal: DecoItemInventoryModal;
	public static decoSkinModal: DecoSkinInventoryModal;
	public static fishInvetoryModal: FishInventoryModal;
	public static fishUnlockModal: FishUnlockModal;
	public static convertDuplicatesModal: ConvertDuplicatesModal;
	public static convertAllDuplicatesModal: ConvertAllDuplicatesModal;
	public static poqLinkModal: PoqLinkModal;
	public static shopModal: ShopModal;
	public static cnyShopModal: CnyShopModal;
	public static contextMenu: ContextMenu;
	public static craftModal: CraftModal;
	public static skinGachaControl: SkinGachaControl | null = null;

	public static partner = '';

	public static highscoreFishApp = new Scene(
		375,
		100,
		{
			antialias: true,
			transparent: true,
			forceCanvas: false,
		},
		true,
	);
	public static fishApp = new Scene(
		114,
		114,
		{
			antialias: true,
			transparent: true,
			forceCanvas: false,
		},
		true,
	);

	public static bgmSoundGroup = new SoundGroup();
	public static sfxSoundGroup = new SoundGroup();

	public static bgmControlSoundGroup = new SoundGroup();
	public static masterSoundGroup = new SoundGroup();
	public static isVideoPlaying = false;

	public static get appScale() {
		const width = document.documentElement.clientWidth;
		const height = document.documentElement.clientHeight;
		let appScale = 1;
		const min = 550;
		const wh = Math.min(width, height);
		// const wh = width;
		if (wh < min) {
			appScale = wh / min;
		}
		return appScale;
	}
}
function hexToRgb(hex: string) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => {
		return r + r + g + g + b + b;
	});

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ?
		parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16)
		: '255,255,255';
}

Global.bgmSoundGroup.volume = settingx.now.bgm;
Global.sfxSoundGroup.volume = settingx.now.sfx;
Global.bgmSoundGroup.muted = settingx.now.bgmMuted;
Global.sfxSoundGroup.muted = settingx.now.sfxMuted;
// window.onblur = () => {
// 	Tween.get(Global.masterSoundGroup, { override: true }).to(
// 		{ volume: 0 },
// 		1000,
// 	);
// };
// window.onfocus = () => {
// 	Tween.get(Global.masterSoundGroup, { override: true }).to(
// 		{ volume: 1 },
// 		1000,
// 	);
// };
setInterval(() => {
	if (document.visibilityState === 'visible' && !Global.isVideoPlaying) {
		if (Global.masterSoundGroup.volume < 1) {
			Global.masterSoundGroup.volume += 0.05;
			if (Global.masterSoundGroup.volume > 1) {
				Global.masterSoundGroup.volume = 1;
			}
		}
	} else {
		if (Global.masterSoundGroup.volume > 0) {
			Global.masterSoundGroup.volume -= 0.05;
			if (Global.masterSoundGroup.volume < 0) {
				Global.masterSoundGroup.volume = 0;
			}
		}
	}
}, 30);

(window as any).globalx = globalx;
(window as any).gamex = gamex;
