






















import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class LoadingPage extends Vue {
  public text: string = '';

  public get letters() {
    return this.text.split('');
  }
  public get clientVersion() {
    return globalx.clientVersionString;
  }

  public get uid() {
    return userx.uid;
  }

  public created() {
    this.$root.$on('loading', (text) => {
      this.text = text;
    });
  }
}
