


































import StateButton from '@/components/inputs/StateButton.vue';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BlueprintsTab from './BlueprintsTab.vue';
import DecosTab from './DecosTab.vue';
import MaterialsTab from './MaterialsTab.vue';
import SkinsTab from './SkinsTab.vue';
@Component({
	components: {
		StateButton,
		MaterialsTab,
		BlueprintsTab,
		DecosTab,
		SkinsTab,
	},
})
export default class BagModal extends Vue {
	public sorting = false;
	public tab: 'materials' | 'blueprints' | 'decos' | 'skins' = 'materials';
	public selectTab(tab: 'materials' | 'blueprints' | 'decos' | 'skins') {
		this.tab = tab;
	}
	public get titleHtml() {
		return `Bag`;
	}
	public showError(error: any) {
		this.$root.$emit('error', error);
	}
	public async onSort() {
		if (this.sorting) {
			return;
		}
		this.sorting = true;
		try {
			await userx.sortBag();
		} catch (error) { }
		this.sorting = false;
	}
}
