




















































import GameBagCanvasComponent from '@/components/canvas/GameBagCanvasComponent.vue';
import { ItemCode, itemInfos } from '@/game/infos/itemInfos';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import { Global } from '@/store/globalz';

@Component({
  components: {
    GameBagCanvasComponent,
  },
})
export default class GameBagComponent extends Vue {
  public get infos() {
    return this.items.map((i) => itemInfos[i.itemCode]);
  }
  public get ids() {
    return this.items.map((i) => {
      let id = i.itemCode.toString();
      while (id.length < 4) {
        id = '0' + id;
      }
      return id;
    });
  }
  public get amounts() {
    return this.items.map((i) => i.amount);
  }

  public get size() {
    let size = Math.floor(this.width / 6);
    if (size < 60) {
      size = Math.floor(this.width / 5);
    }
    return size;
  }
  public get numColumns() {
    return Math.floor(this.width / this.size);
  }

  public get numRows() {
    return Math.max(Math.ceil(this.items.length / this.numColumns), 1);
  }

  public get height() {
    return Math.ceil((this.size * this.numRows) / 5) * 5;
  }
  public get boxStyle() {
    return {
      width: `${this.size}px`,
      height: `${this.size}px`,
    };
  }
  public get borderStyles() {
    return this.infos.map((i) => {
      const color = Global.rarityRgbs[i.rarity];
      return {
        border: `1px solid rgba(${color}, ${i.rarity * 0.15 + 0.2})`,
        background: `rgba(${color}, ${i.rarity * 0.05})`,
      };
    });
  }

  public get numBoxes() {
    return this.numColumns * this.numRows;
  }
  @Prop({ default: () => [] }) public items!: ItemCodeAmountPair[];

  public width = 300;
  public getRarity(index: number) {
    const info = this.infos[index];
    const rarity = Global.rarityLabels[info.rarity];
    const color = Global.rarityColors[info.rarity];
    return `<div class="text-sm font-italic" style="color:${color}">${rarity}</div>`;
  }
  public mounted() {
    if (!(this.$el as any).resizeSensor) {
      const res = new ResizeSensor(this.$el, this.onResize);
    }

    this.onResize();
  }
  public beforeDestroy() {
    if ((this.$el as any).resizeSensor) {
      ResizeSensor.detach(this.$el);
    }
  }
  public onResize() {
    this.width = this.$el.clientWidth - 1;
  }
}
