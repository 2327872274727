import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataBiAtomBomb, MiscDataBiMechguinBullet, MiscDataSsSunPunch } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../../..';
import { MiscSymbol } from '../../skills/miscSymbol';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';
import { SsSunPunchSymbol } from '../../skills/ssSunPunchSymbol';
import { wait } from '@/util/wait';

export class MechguinTurretBulletSymbol extends MovieClip {
	public init() {
		this.gotoAndStop(0);
	}
}
export class BiAMechguinBulletController implements MiscSymbol {

	public static get() {
		return BiAMechguinBulletController._pool.get();
	}

	private static _pool: Pool<BiAMechguinBulletController> = new Pool(BiAMechguinBulletController);


	public data: MiscData | null = null;
	public pt = 0;

	// public sound = new LongSoundEfx('sunPunch');
	public symbol = Factory.get(MechguinTurretBulletSymbol);


	public constructor() {
	}
	public async dispose(room: RoomControl) {
		this.data = null;
		this.symbol.gotoAndPlay('dead');
		await wait(170);
		if (this.symbol.parent) {
			this.symbol.gotoAndStop(0);
			this.symbol.parent.removeChild(this.symbol);
			BiAMechguinBulletController._pool.pool(this);
		}
		// Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
		// 	this.sound.stop();
		// });
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataBiMechguinBullet;

		this.symbol.x = data.x;
		this.symbol.y = data.y;


		if (!this.data) {
			this.symbol.gotoAndStop(0);
		}
		this.data = data;

		const volume = (2000 - Rotate.dist(data.x, data.y, state.x, state.y)) / 2000;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this.symbol);
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
	}
}
