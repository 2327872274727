






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { SpearType, spearSkills, weaponDecs, weaponLongNames, weaponNames } from '@/game/infos/spearInfos';
import { specialSkillInfos } from '@/game/infos/skills';
import settingx from '@/store/modules/settingx';
@Component({
	components: {},
})
export default class TnyWeaponModal extends Vue {
	@Prop({ default: () => [] }) public weapons!: SpearType[];
	@Prop({ default: 0 }) public spearType!: SpearType;

	public get weaponData() {
		return this.weapons.map((spearType) => {
			let desc = (spearSkills[this.spearType]) ? specialSkillInfos[spearSkills[this.spearType]].desc : weaponDecs[this.spearType];
			if (settingx.now.control === 'mouse' && spearSkills[this.spearType]) {
				desc += ` [Press ${settingx.now.skill3}]`;
			}
			return {
				spearType,
				name: weaponLongNames[spearType],
				desc,
				img: `/images/weapons/${weaponNames[spearType]}1.png`,
			};
		});
	}
	public get titleHtml() {
		return `Choose Weapon`;
	}
	public onChoose(spearType: SpearType) {
		this.$emit('select', spearType);
	}
}
