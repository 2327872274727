

























































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import TnyEvolutionModal from './TnyEvolutionModal.vue';
import TnyFishSelectModal from './TnyFishSelectModal.vue';
import {
	TourneyLobbyData,
	MatchData,
	ClientTourneyPlayerData,
	tourneyMapInfos,
	TourneyMapType,
} from '@/game/infos/roomInfosTny';
import { ServerTime } from '@/store/api/serverTime';
import TnyEvolutionFooter from './TnyLobbyEvolutionFooter.vue';
import userx from '@/store/modules/userx';
import TnyChatWindow from './TnyChatWindow.vue';
import TnyWeaponSelector from './TnyWeaponSelector.vue';
import { SpearType } from '@/game/infos/spearInfos';
import { GameType } from '@/game/infos/roomInfos';

@Component({
	components: {
		TnyEvolutionModal,
		TnyFishSelectModal,
		TnyEvolutionFooter,
		TnyChatWindow,
		TnyWeaponSelector,
	},
})
export default class TourneyLobby extends Vue {
	public get myUid() {
		return userx.uid;
	}
	public get tnyLobbyData() {
		return gamex.tnyLobbyData;
	}
	public get mapInfo() {
		return tourneyMapInfos[
			this.tnyLobbyData?.roomOptions.mapType || TourneyMapType.SmallArena
		];
	}
	public get totalRound() {
		return this.tnyLobbyData?.roomOptions.totalRound || 2;
	}
	public get maxPlayers() {
		return Math.pow(2, this.totalRound);
	}
	public get participants() {
		if (!this.tnyLobbyData) {
			return [];
		}
		return Object.values(this.tnyLobbyData?.participants).sort((a, b) => {
			if (a.rank === b.rank) {
				if (a.isComputer) {
					return 1;
				}
				if (b.isComputer) {
					return -1;
				}
				return 0;
			}
			return a.rank - b.rank;
		}) as ClientTourneyPlayerData[];
	}
	public get joinRoomOptions() {
		return gamex.roomOptions;
	}

	public get me() {
		const me = this.tnyLobbyData?.participants[userx.uid || ''] || null;
		return me as ClientTourneyPlayerData | null;
	}
	public get winRank() {
		return this.me && this.tnyLobbyData
			? this.me.rank -
			Math.pow(2, this.totalRound - this.tnyLobbyData.currentRound)
			: 0;
	}
	public get myMatch(): MatchData {
		const matches = this.tnyLobbyData?.matches;
		if (matches) {
			for (const mid in matches) {
				if (Object.prototype.hasOwnProperty.call(matches, mid)) {
					const mm = matches[mid];
					if (mm.p1 === userx.uid || mm.p2 === userx.uid) {
						return mm as MatchData;
					}
				}
			}
		}
		return {
			mid: '1',
			p1: '',
			p2: '',
			closed: false,
			result: '',
			difficulty: 0,
		};
	}
	public get myOpponent() {
		const participants = this.tnyLobbyData?.participants;
		if (participants) {
			for (const uid in participants) {
				if (Object.prototype.hasOwnProperty.call(participants, uid)) {
					const player = participants[uid];
					if (player.uid !== userx.uid) {
						if (
							player.uid === this.myMatch.p1 ||
							player.uid === this.myMatch.p2
						) {
							return player as ClientTourneyPlayerData;
						}
					}
				}
			}
		}
		return null;
	}

	public get lockTimeLeft() {
		if (!this.tnyLobbyData) {
			return '?m ?s';
		}
		const availableTime = this.tnyLobbyData.lockTime;

		let tl = availableTime - this.time;
		if (tl <= 0) {
			tl = 0;
			return '0m 0s';
		} else {
			//   const day = Math.floor(tl / (24 * 60 * 60 * 1000));
			//   tl -= day * 24 * 60 * 60000;
			tl = Math.ceil(tl / 1000);
			const min = Math.floor(tl / 60);
			tl -= min * 60;
			const sec = tl;
			// tl -= min * 60000
			return `${min}m ${sec}s`;
		}
	}
	public get startTimeLeft() {
		if (!this.tnyLobbyData) {
			return '?m ?s';
		}
		const availableTime = this.tnyLobbyData.startTime;

		let tl = availableTime - this.time;
		if (tl <= 0) {
			tl = 0;
			return '0s';
		} else {
			//   const day = Math.floor(tl / (24 * 60 * 60 * 1000));
			//   tl -= day * 24 * 60 * 60000;
			tl = Math.ceil(tl / 1000);
			const sec = tl;
			// tl -= min * 60000
			return `${sec}s`;
		}
	}
	public options: any = { lifes: [1, 2, 3], weapon: 0 };

	public time = Date.now();
	public interval: any;
	@Watch('tnyLobbyData', { immediate: true })
	public onTnyLobbyDataChange(options: TourneyLobbyData | null) {
		if (options) {
			if (options.lifes) {

				this.options.lifes[0] =
					options.lifes[0];
				this.options.lifes[1] =
					options.lifes[1];
				this.options.lifes[2] =
					options.lifes[2];
			}
			if (options.weapon) {
				this.options.weapon = options.weapon;
			}
		}
	}

	public onCheckEvolution() {
		this.$bvModal.show('tny-evolution-modal');
	}
	public async onChooseFish(life: number) {
		const modal = this.$refs.selectModal as TnyFishSelectModal;
		const fishType = await modal.show(
			this.options.lifes[life],
			this.joinRoomOptions!.fishes![life],
		);
		if (fishType && this.options.lifes[life] !== fishType) {
			this.options.lifes[life] = fishType;
			this.options.lifes = [...this.options.lifes];
			Global.clientCommunicator.changePlayerOptions({
				lifes: this.options.lifes,
			});
		}
	}
	public async onChooseWeapon(spearType: SpearType) {
		this.options.weapon = spearType;
		Global.clientCommunicator.changePlayerOptions({
			weapon: this.options.weapon,
		});

	}
	public onLock() {
		Global.clientCommunicator.lockTourneyLobby();
	}
	public onReady() {
		if (this.me?.isReady) {
			Global.clientCommunicator.unreadyTourneyLobby();
		} else {
			Global.clientCommunicator.readyTourneyLobby();
		}
	}
	public onQuit() {
		Global.stageControl.uiControl.onLeaveTourney();
	}
	public mounted() {
		this.interval = setInterval(() => {
			this.time = Date.now();
		}, 1000);
	}
	public beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}
	public onContext(event: any, uid: string) {
		(this.$refs.chatbox as TnyChatWindow).onContext(event, uid);
	}
	public get socketErrorMessage() {
		return gamex.socketErrorMessage;
	}
	@Watch('socketErrorMessage')
	public async socketErrorMessageChanged(value: string) {
		await this.$bvModal.msgBoxOk(value, {
			titleHtml: `<i class="fa fa-exclamation-triangle"></i> Connection Lost`,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			modalClass: 'funny-modal',
		});
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('TourneyList');
	}
	public get rejectReason() {
		return gamex.rejectReason;
	}
	@Watch('rejectReason')
	public async rejectReasonChanged(value: string) {
		await this.$bvModal.msgBoxOk(value, {
			titleHtml: `<i class="fa fa-exclamation-triangle"></i> Join Room Failed`,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			modalClass: 'funny-modal',
		});
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('TourneyList');
	}
}
