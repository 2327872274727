import {
	getModule,
	Module,
	VuexModule,
	Mutation,
	Action,
} from 'vuex-module-decorators';
import store from '@/store';
import { Dictionary } from 'vue-router/types/router';
import { nonReact } from '@/util/nonReact';
import { Scene } from 'pixi-animate';
import { MainScene } from '@/client/controls/mainScene';

@Module({
	namespaced: true,
	name: 'assetx',
	store,
	dynamic: true,
})
class AssetModule extends VuexModule {
	public dpi = Math.min(screen.width, screen.height) >= 768 ? 1 : 1 + (window.devicePixelRatio - 1) * 0.5;
	public app = nonReact(new Scene(document.documentElement.clientWidth * this.dpi, document.documentElement.clientHeight * this.dpi, {
		// backgroundColor: 0x07171e,
		transparent: true,
		antialias: false,
	}));
	public mainApp = nonReact(new MainScene(document.documentElement.clientWidth * this.dpi, document.documentElement.clientHeight * this.dpi));
	public assetLoadState: 'completed' | 'loading' | 'none' = 'none';
	public assetLoadProgress = 0; // 0-100
	public assetLoadErrors: Error[] = [];


	public gachaAssetLoadState: 'completed' | 'loading' | 'none' = 'none';
	public gachaAssetLoadProgress = 0; // 0-100
	public gachaAssetLoadErrors: Error[] = [];
	public mechguinAssetLoadState: 'completed' | 'loading' | 'none' = 'none';
	public mechguinAssetLoadProgress = 0; // 0-100
	public mechguinAssetLoadErrors: Error[] = [];

	@Mutation
	public m_updateLoadProgress(progress: number) {
		this.assetLoadProgress = progress;
	}
	@Action
	public async updateLoadProgress(progress: number) {
		this.m_updateLoadProgress(progress);
	}
	@Mutation
	public m_updateLoadState(state: 'completed' | 'loading') {
		this.assetLoadState = state;
		this.mainApp.updateAssetLoadState(state);
	}
	@Action
	public async updateLoadState(state: 'completed' | 'loading') {
		this.m_updateLoadState(state);
	}
	@Mutation
	public m_addLoadError(error: Error) {
		this.assetLoadErrors = [...this.assetLoadErrors, error];
	}
	@Action
	public async addLoadError(error: Error) {
		this.m_addLoadError(error);
	}


	@Mutation
	public m_updateGachaLoadProgress(progress: number) {
		this.gachaAssetLoadProgress = progress;
	}
	@Action
	public async updateGachaLoadProgress(progress: number) {
		this.m_updateGachaLoadProgress(progress);
	}
	@Mutation
	public m_updateGachaLoadState(state: 'completed' | 'loading') {
		this.gachaAssetLoadState = state;
	}
	@Action
	public async updateGachaLoadState(state: 'completed' | 'loading') {
		this.m_updateGachaLoadState(state);
	}
	@Mutation
	public m_addGachaLoadError(error: Error) {
		this.gachaAssetLoadErrors = [...this.gachaAssetLoadErrors, error];
	}
	@Action
	public async addGachaLoadError(error: Error) {
		this.m_addGachaLoadError(error);
	}



	@Mutation
	public m_updateMechguinLoadProgress(progress: number) {
		this.mechguinAssetLoadProgress = progress;
	}
	@Action
	public async updateMechguinLoadProgress(progress: number) {
		this.m_updateMechguinLoadProgress(progress);
	}
	@Mutation
	public m_updateMechguinLoadState(state: 'completed' | 'loading') {
		this.mechguinAssetLoadState = state;
	}
	@Action
	public async updateMechguinLoadState(state: 'completed' | 'loading') {
		this.m_updateMechguinLoadState(state);
	}
	@Mutation
	public m_addMechguinLoadError(error: Error) {
		this.mechguinAssetLoadErrors = [...this.mechguinAssetLoadErrors, error];
	}
	@Action
	public async addMechguinLoadError(error: Error) {
		this.m_addMechguinLoadError(error);
	}
}


export default getModule(AssetModule);
