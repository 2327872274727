import { Rotate } from '@/util/rotate';
import { Tween } from '@/util/tweents';
import { BLEND_MODES, Container, Sprite } from 'pixi.js';
import Factory from '..';

export class ShootingStarSymbol extends Container {
	public img!: Sprite;

	public init() {
		this.img = this.children[0] as Sprite;
		this.img.blendMode = BLEND_MODES.ADD;
		this.img.tint = 0x4DFFFF;
		this.scale.set(0.75);
	}
}

export class ShootingStarsContainer extends Container {
	public screenWidth = 1000;
	public screenHeight = 1000;

	public shootingAngle = 135;
	public shootingRadian = this.shootingAngle / 180 * Math.PI;

	public isShooting = false;


	public setSize(w: number, h: number) {
		this.screenWidth = w;
		this.screenHeight = h;
	}

	public start() {
		this.isShooting = true;
		this.shootStar();
		this.shootStar();
	}

	public shootStar() {

		if (!this.isShooting) {
			return;
		}
		const star = Factory.get(ShootingStarSymbol);
		star.angle = this.shootingAngle;
		const w = this.screenWidth;
		star.x = Math.random() * (w + 200) + 200;
		star.y = -10;
		star.alpha = Math.random() * 0.5 + 0.5;
		// star.y = - Math.random() * 200 - 10;
		const distance = (Math.random() * 500 + this.screenHeight) * 1.5;
		const vector = Rotate.move(this.shootingRadian, distance);
		// console.log(star.x, star.x + vector.x);

		this.addChild(star);
		Tween.get(star).to({ x: star.x + vector.x, y: star.y + vector.y, alpha: 0 }, distance * 0.5).call(() => {
			this.removeChild(star);
			Factory.pool(ShootingStarSymbol, star);
			setTimeout(this.shootStar.bind(this), Math.random() * 300);
		});
	}

	public stop() {
		this.isShooting = false;
	}
}
