import random from '@/util/random';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { BLEND_MODES, Container, Ticker } from 'pixi.js';
import { getParticle, Particle } from '../../particles';

export class UnlockAnimationBubble {
	public static get() {
		const bubble = UnlockAnimationBubble.pool.shift() || new UnlockAnimationBubble();
		return bubble;
	}
	protected static pool: UnlockAnimationBubble[] = [];
	public symbol = new Container();
	public tickAdded = false;
	public constructor() {
		this.symbol.addChild(getParticle(Particle.MiniBubble));
	}
	public tick = () => {
		const scale = this.symbol.scale.x * 5;
		const bubble = this.symbol.children[0];
		bubble.x += Math.random() * scale - scale / 2;
		bubble.y += Math.random() * scale - scale / 2 * 2.5;
	}

	public dispose() {
		UnlockAnimationBubble.pool.push(this);
		if (this.tickAdded) {
			Ticker.shared.remove(this.tick);
			this.tickAdded = false;
		}
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
	}

	public init(x: number, y: number, speed: number, angle: number, scale: number) {
		const symbol = this.symbol;
		const bubble = this.symbol.children[0];
		bubble.x = 0;
		bubble.y = 0;

		const posRad = Math.random() * Math.PI * 2;
		const posDist = Math.random() * 45 * scale;
		const pos = Rotate.move(posRad, posDist);

		symbol.scale.set(scale * 0.4);
		symbol.x = pos.x + x;
		symbol.y = pos.y + y;
		symbol.alpha = 1;

		const tRad = scale * (Math.random() * 0.2 - 0.1) + angle;
		const tDist = (speed * scale * Math.random() * 2 + 1.5) * scale;
		const tPos = Rotate.move(tRad, tDist);

		if (!this.tickAdded) {
			Ticker.shared.add(this.tick);
			this.tickAdded = true;
		}

		Tween.get(symbol).to({ alpha: 0, x: tPos.x + symbol.x, y: tPos.y + symbol.y }, 1000, Ease.circOut).call(() => {
			this.dispose();
		});
	}

}

export class UnlockAnimationStar {
	public static get() {
		const bubble = UnlockAnimationStar.pool.shift() || new UnlockAnimationStar();
		return bubble;
	}

	protected static pool: UnlockAnimationStar[] = [];

	public symbol = getParticle(Particle.Star);

	constructor() {
		this.symbol.blendMode = BLEND_MODES.ADD;
	}

	public dispose() {
		UnlockAnimationStar.pool.push(this);
		if (this.symbol.parent) {
			this.symbol.removeChild(this.symbol);
		}
	}

	public init(x: number = 0, y: number = 0) {

		const tRad = Math.random() * Math.PI * 2;
		const tDist = Math.random() * 600 + 400;
		const tPos = Rotate.move(tRad, tDist);
		this.symbol.x = x;
		this.symbol.y = y;
		this.symbol.angle = 0;
		this.symbol.alpha = Math.random() * 0.3 + 0.2;
		this.symbol.scale.set(0.2 + Math.random() * 0.8);

		this.symbol.tint = random.choose([0xffffff, 0xffff00, 0x00ff00, 0x00ffff, 0xff00ff]);

		Tween.get(this.symbol).to({ angle: 360, alpha: 0, x: x + tPos.x, y: y + tPos.y }, 1000, Ease.quadOut).call(() => {
			this.dispose();
		});
	}
}
