import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsSpikeArmor, MiscDataSsWhammyWave } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';

const fishInfo = fishInfos[FishType.Onifugu];

export class SsSpikeArmorSymbol implements MiscSymbol {

	public static get() {
		return SsSpikeArmorSymbol._pool.get();
	}

	private static _pool: Pool<SsSpikeArmorSymbol> = new Pool(SsSpikeArmorSymbol);
	public area: Graphics = new Graphics();

	public data: MiscData | null = null;
	public pt = 0;
	public casting = true;


	constructor() {
		this.area.lineStyle(1, 0xFFFF00, 0.75);
		this.area.beginFill(0xffff99, 0.15);
		this.area.drawCircle(0, 0, 85 * fishInfo.stat.size * 2);
	}
	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		SsSpikeArmorSymbol._pool.pool(this);

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsSpikeArmor;
		this.area.x = data.x;
		this.area.y = data.y;

		if (!this.data) {
			this.area.visible = true;
			// this.visible = false;
		}
		this.pt = rt;
		const castTime = data.ct;
		const startTime = data.st;
		this.casting = rt <= startTime + castTime;
		if (!this.casting && this.area.parent) {
			const dist = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
			new SoundEfx('spikeGrow').play({ volume: dist * .4 });
			this.area.parent.removeChild(this.area);
			if (dist > .5) {
				room.cameraControl.swing(4 * (dist - .4), 500);
			}
		}
		this.data = data;
	}
	public show(room: RoomControl) {
		if (this.casting) {
			room.wallBottomContainer.addChild(this.area);
		}
		// room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		// if (this.parent) {
		// 	this.parent.removeChild(this);
		// }
	}
}
