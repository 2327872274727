import { disposeItemSprite, getItemSprite } from '@/client/factory/itemParticles';
import { getParticle, Particle } from '@/client/factory/particles';
import { ItemCode, itemInfos, Rarity } from '@/game/infos/itemInfos';
import { BroadcastDamageEvent, BroadcastLootEvent } from '@/game/multithread/state';
import settingx from '@/store/modules/settingx';
import { Pool } from '@/util/pool';
import { Point, Rotate } from '@/util/rotate';
import { Tween } from '@/util/tweents';
import { BLEND_MODES, DisplayObject, Sprite, Ticker } from 'pixi.js';
import { RoomControl } from '../roomControl';
import { LegendaryItemBlingEffectControl } from './legendaryItemBlingEfxControl';

function quintIn(x: number) {
	return x * x * x * x * x;
}
const duration = 1000;

export class LootParticleEffectControl {

	public static get() {
		const control = LootParticleEffectControl._pool.get();
		return control;
	}
	private static _pool: Pool<LootParticleEffectControl> = new Pool(LootParticleEffectControl);
	public symbol!: DisplayObject;

	public startPosition: Point = { x: 0, y: 0 };
	public target: Point = { x: 0, y: 0 };
	public flyPosition: Point = { x: 0, y: 0 };
	public startTime = 0;

	public scale = 1;
	public symbolScale = 1;
	public isLegendary = false;
	public offsetX = 0;
	public offsetY = 0;


	protected roomControl!: RoomControl;
	protected lastBlingTime = 0;

	protected crossGlow1 = getParticle(Particle.CrossGlow);
	protected crossGlow2 = getParticle(Particle.CrossGlow);


	constructor() {
		this.crossGlow1.blendMode = BLEND_MODES.ADD;
		this.crossGlow2.blendMode = BLEND_MODES.ADD;
		this.crossGlow1.alpha = 0.5;
		this.crossGlow2.alpha = 0.25;
	}

	public ticker = () => {

		const ct = Date.now();


		const timePassed = (ct - this.startTime);
		const timePos = timePassed / duration;
		if (timePassed > duration || !this.symbol) {
			this.dispose();
			return;
		}


		this.symbol.scale.set((timePos < 0.5 ? this.scale : this.scale * (1 - quintIn((timePos - 0.5) * 2))) * this.symbolScale);

		const target = this.target;
		if (!target || target.x === 0 && target.y === 0) {
			return;
		}
		const neg = 1 - timePos;
		const tx = this.target.x + this.offsetX;
		const ty = this.target.y + this.offsetY;
		const progressPos = {
			x: tx - (tx - this.startPosition.x) * neg,
			y: ty - (ty - this.startPosition.y) * neg,
		};
		this.symbol.x = progressPos.x + Math.sin(timePos * Math.PI) * this.flyPosition.x;
		this.symbol.y = progressPos.y + Math.sin(timePos * Math.PI) * this.flyPosition.y;

		if (this.isLegendary && this.roomControl) {
			if (!settingx.now.hideParticles) {
				const blingTime = Math.floor(ct / 100);
				const canBling = blingTime !== this.lastBlingTime;
				if (canBling) {
					this.lastBlingTime = blingTime;
					const rx = this.symbol.x + Math.random() * 60 - 30;
					const ry = this.symbol.y + Math.random() * 60 - 30;
					const bling = LegendaryItemBlingEffectControl.get(rx, ry);
					this.roomControl.effectsContainer.addChild(bling.symbol);
				}
			}
			this.crossGlow1.x = this.symbol.x;
			this.crossGlow1.y = this.symbol.y;
			this.crossGlow2.x = this.symbol.x;
			this.crossGlow2.y = this.symbol.y;
			this.crossGlow1.angle += 2;
			this.crossGlow2.angle -= 1;
			this.crossGlow1.scale.set(this.symbol.scale.x);
			this.crossGlow2.scale.set(this.symbol.scale.x);
		}

	}
	public set(event: BroadcastLootEvent) {
	}
	public init(start: Point, target: Point, symbol: DisplayObject, roomControl: RoomControl, symbolScale = 1, isLegendary = false, offsetX = 0, offsetY = 0) {
		this.roomControl = roomControl;
		this.symbol = symbol;

		this.target = target;
		this.symbol.x = start.x;
		this.symbol.y = start.y;
		this.startPosition.x = start.x;
		this.startPosition.y = start.y;
		this.symbolScale = symbolScale;
		this.isLegendary = isLegendary;
		this.offsetX = offsetX;
		this.offsetY = offsetY;

		const angle = Math.random() * Math.PI * 2;
		const distance = Math.random() * 100 + 100;


		this.flyPosition = Rotate.move(angle, distance);


		this.symbol.scale.set(this.scale);
		this.startTime = Date.now();
		Ticker.shared.add(this.ticker);

		if (this.isLegendary) {
			roomControl.playerTopContainer.addChild(this.crossGlow1);
			roomControl.playerTopContainer.addChild(this.crossGlow2);
			this.crossGlow1.angle = 0;
			this.crossGlow1.angle = 45;
		}

		roomControl.playerTopContainer.addChild(this.symbol);
	}


	public dispose() {
		this.target = { x: 0, y: 0 };
		Ticker.shared.remove(this.ticker);
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		if (this.crossGlow1.parent) {
			this.crossGlow1.parent.removeChild(this.crossGlow1);
		}
		if (this.crossGlow2.parent) {
			this.crossGlow2.parent.removeChild(this.crossGlow2);
		}
		LootParticleEffectControl._pool.pool(this);
	}
}
