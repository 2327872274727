












































import { AipAds } from '@/client/ads/aipads';
import { CrazyAd } from '@/client/ads/crazyads';
import { Analytics } from '@/client/analytics';
import {
  CommandReincarnate,
  GameCommandType,
} from '@/game/multithread/command';
import { PlayerGameState } from '@/game/multithread/state';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import globalx from '@/store/modules/globalx';
import { wait } from '@/util/wait';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import OverlayCanvasComponent from '../canvas/OverlayCanvasComponent.vue';
import OverlayGameAds from './OverlayGameAds.vue';
import { GameType } from '@/game/infos/roomInfos';

@Component({
  components: {
    OverlayCanvasComponent,
    OverlayGameAds,
  },
})
export default class OverlayComponent extends Vue {
  public bool: boolean = false;
  public showAdBlocker: boolean = false;
  public width = document.documentElement.clientWidth;
  public height = document.documentElement.clientHeight;
  public interval: any;
  public secLeft = 45;
  public reincarnateCommand: CommandReincarnate = {
    type: GameCommandType.Reincarnate,
    sessionId: '',
    value: 1,
  };

  public get toShowNextRevealButton() {
    return globalx.toShowNextRevealButton;
  }
  public get showUnlock() {
    return globalx.showingUnlock;
  }
  public get showingGachaAnimation() {
    return globalx.showingGachaAnimation;
  }
  public get toShowChampionshipWinBadges() {
    return (
      gamex.gameStage === 'End' && gamex.gameType === GameType.Tourney
    );
  }
  // public showUnlock = 0;
  // ### Reincarnation
  public get showReincarnation() {
    // return this.bool;
    return (
      gamex.gameType === GameType.Armageddon && gamex.gameStage === 'Start' && gamex.playerState === PlayerGameState.Dead
    );
  }
  public reincarnationOpacity = 0;

  public videoAdReady = false;
  public videoShowing = false;

  public get gamexWatchingAds() {
    return gamex.watchingAds;
  }

  @Watch('showReincarnation', { immediate: true })
  public async showReincarnationChanged(newVal: boolean) {
    if (newVal) {
      const adReady =
        Global.adsdk === 'crazyads'
          ? CrazyAd.instance.adReady.rewarded
          : AipAds.instance.adReady.midgame;
      this.videoAdReady = gamex.currentGameState.life === 2 && adReady;
      await wait(1300);
      if (this.showReincarnation) {
        this.showAdBlocker = false;
        this.reincarnationOpacity = 1;
      }
    } else {
      this.reincarnationOpacity = 0;
      this.videoShowing = false;
    }
  }
  public async onReincarnate() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    this.reincarnateCommand.sessionId = gamex.currentGameState.sid;
    if (gamex.playerState !== PlayerGameState.Dead) {
      return;
    }
    if (this.videoAdReady) {
      this.videoShowing = true;
      this.reincarnateCommand.value = 2;
      Global.clientCommunicator.sendCommand(this.reincarnateCommand);
      this.reincarnationOpacity = 0;
      this.secLeft = 45;
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        this.secLeft--;
        if (this.secLeft < 40 && !this.showAdBlocker) {
          this.showAdBlocker = true;
        }
        if (this.secLeft <= 0) {
          this.secLeft = 0;
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 1000);
      Global.isVideoPlaying = true;
      if (Global.adsdk === 'crazyads') {
        await CrazyAd.instance.requestAd('rewarded');
      } else {
        await AipAds.instance.requestVideoAd('midgame');
      }
      Global.isVideoPlaying = false;
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.showAdBlocker = false;
    this.secLeft = 45;
    this.reincarnateCommand.value = 1;
    Global.clientCommunicator.sendCommand(this.reincarnateCommand);
    if (gamex.currentGameState.life === 2) {
      Analytics.life3();
    } else {
      Analytics.life2();
    }
  }
  public async onQuitReincarnate() {
    const value = await Global.$bvModal.msgBoxConfirm(
      'Are you sure you want to quit the game?',
      {
        size: 'sm',
        cancelVariant: 'link',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        modalClass: 'funny-modal',
        titleHtml: `Quit Game`,
        centered: true,
      },
    );
    if (value) {
      this.reincarnateCommand.sessionId = gamex.currentGameState.sid;
      this.reincarnateCommand.value = 0;
      Global.clientCommunicator.sendCommand(this.reincarnateCommand);
    }
  }
  public onRevealNext() {
    if (this.toShowNextRevealButton && Global.skinGachaControl) {
      Global.skinGachaControl.revealNext();
    }
  }
  public onSkipGacha() {
    if (Global.skinGachaControl) {
      Global.skinGachaControl.skipGacha();
    }
  }

  public mounted() {
    this.resize();
    window.addEventListener('resize', this.resize);
    document.body.appendChild(this.$el);
  }
  public beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    document.body.removeChild(this.$el);
  }
  public resize() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
  }
  // ### End Reincarnation
}
