





























import { SoundEfx } from '@/client/sound/SoundEfx';
import { TipData, TipId, tipInfos } from '@/game/infos/tipInfos';
import settingx from '@/store/modules/settingx';
import tipx from '@/store/modules/tipx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class TipsToast extends Vue {
  public visible = false;
  public tipId = 0;
  public animationId = 0;
  public isHidingId = TipId.None;

  public get currentShowingTipId() {
    return tipx.currentShowingTipId;
  }
  public get tipInfo(): TipData | null {
    return tipInfos[this.tipId] || null;
  }
  public get imgSrc() {
    return `/images/articles/tips/${this.tipInfo?.img}.jpg`;
  }
  public get tipContent() {
    let desc = this.tipInfo?.desc || '';
    desc = desc.replace(
      '$tap',
      settingx.now.control === 'touch' ? 'Tap' : 'Click',
    );

    let skill1: string = settingx.now.skill1;
    let skill2: string = settingx.now.skill2;
    let wpnL: string = settingx.now.wpnL;

    if (skill1 !== 'Left-Click' && skill1 !== 'Right-Click') {
      skill1 = 'Press ' + skill1.toUpperCase();
    }
    if (skill2 !== 'Left-Click' && skill2 !== 'Right-Click') {
      skill2 = 'Press ' + skill2.toUpperCase();
    }
    if (wpnL !== 'Left-Click' && wpnL !== 'Right-Click') {
      wpnL = 'Press ' + wpnL.toUpperCase();
    }

    desc = desc.replace(
      '$leftClick',
      settingx.now.control === 'touch' ? 'Tap the orange button' : skill1,
    );
    desc = desc.replace(
      '$rightClick',
      settingx.now.control === 'touch' ? 'Tap the blue button' : skill2,
    );
    desc = desc.replace('$wpn', wpnL);
    return desc;
  }

  public get nudgeClass() {
    return this.animationId ? 'animated--nudge' : 'animated--nudge2';
  }

  @Watch('currentShowingTipId')
  public onShowingTipIdChanged() {
    if (this.isHidingId) {
      return;
    }
    if (this.currentShowingTipId !== 0) {
      this.tipId = this.currentShowingTipId;
      this.animationId = this.animationId ? 0 : 1;
    }
    this.visible = this.currentShowingTipId !== 0;
  }
  @Watch('tipId')
  public onTipIdChanged() {
    if (this.tipId !== 0) {
      new SoundEfx('tips').play();
    }
  }

  public onShow() {}

  public onHidden() {
    tipx.closeTip(this.isHidingId);
    this.isHidingId = 0;
    this.tipId = tipx.currentShowingTipId;
    if (tipx.currentShowingTipId) {
      this.visible = true;
    }
  }
  public onHide() {
    this.isHidingId = this.tipId;
    tipx.readTip(this.tipId);
  }
}

(window as any).tipx = tipx;
