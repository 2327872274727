
































































































































import { BModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FishType } from '@/game/infos/fishInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import WeeklyHighscoreComponent from './WeeklyHighscoreComponent.vue';

export type ContestType = 'killers' | 'survivors' | 'explorers';

export interface WeeklyRankings {
  week: number;
  curr: WeeklyHighscoreData[];
  prev: WeeklyHighscoreData[];
  currMe?: WeeklyHighscoreDataWithRank;
  prevMe?: WeeklyHighscoreDataWithRank;
}
export interface WeeklyHighscoreData {
  uid: string;
  name: string;
  fishType: FishType;
  decoSet: string | null;
  skin: SkinGroup;
  spearType: number;
  scoreLeft: number;
  serverName: string;
  count: number;
  subCount: number;
}

export interface WeeklyHighscoreDataWithRank extends WeeklyHighscoreData {
  rank: number;
}

@Component({
  components: {
    WeeklyHighscoreComponent,
  },
})
export default class WeeklyContestComponent extends Vue {
  @Prop({ required: true }) public contestType!: ContestType;
  @Prop({ default: false }) public unread!: boolean;

  public tab: 'curr' | 'prev' = 'curr';
  public leaderboard: WeeklyRankings = {
    week: 1,
    curr: [],
    prev: [],
  };
  public get title() {
    return this.contestType === 'killers'
      ? 'Slayers'
      : this.contestType === 'survivors'
      ? 'Survivors'
      : 'Explorers';
  }
  public get countUnit() {
    return this.contestType === 'killers'
      ? 'kills'
      : this.contestType === 'survivors'
      ? 'wins'
      : 'idols';
  }
  public async showLeaderboard() {
    const modal = this.$refs.lbModal as BModal;
    if (modal) {
      modal.show();
    }
    const lobbyServerAddress = globalx.lobbyServerAddress;
    try {
      const response = await fetch(
        `${lobbyServerAddress}/weekly/${this.contestType}/${userx.uid}`,
        {
          method: 'GET',
        },
      );
      if (!response.ok) {
        throw await response.json();
      }
      const json = await response.json();
      this.leaderboard = json;
    } catch (error) {
      throw error;
    }
  }
}
