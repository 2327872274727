






































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import userx from '@/store/modules/userx';
import BossChatWindow from './BossChatWindow.vue';
import { GameType } from '@/game/infos/roomInfos';
import { Dictionary } from '@/util/dictionary';
import { BossLobbyData, BossLobbyState, ClientBossPlayerData, LobbyPrivacy } from '@/game/infos/roomInfosBoss';
import BossFishSelectModal from './BossFishSelectModal.vue';
import FishButtonTransparent from './FishButtonTransparent.vue';
import { FishType } from '@/game/infos/fishInfos';
import { ChatType } from '@/game/infos/eventType';

@Component({
	components: {
		BossChatWindow,
		BossFishSelectModal,
		FishButtonTransparent,
	},
})
export default class BossLobby extends Vue {
	public get myUid() {
		return userx.uid;
	}
	public get lobbyData() {
		return gamex.bossLobbyData;
	}
	public get lobbyId() {
		return this.lobbyData?.id || '-';
	}
	public get privacy() {
		return this.lobbyData?.roomOptions.privacy || 0;
	}
	public get numPlayers() {
		let n = 0;
		for (const p of this.participants) {
			if (p) {
				n++;
			}
		}
		return n;
	}
	public get hostChallenged() {
		return this.lobbyData && this.lobbyData.hostClaimTime ? true : false;
	}
	public set hostChallenged(value: boolean) {
	}
	public get hostChallengeCountDown() {
		if (!this.lobbyData || !this.lobbyData.hostClaimTime) {
			return 0;
		}
		return Math.floor((this.lobbyData.hostClaimTime - this.time) / 1000);
	}
	public get isAllReady() {
		for (const p of this.participants) {
			if (p && p.uid !== this.lobbyData!.host && !p.readyTime) {
				return false;
			}
		}
		return true;
	}
	public get toFade() {
		return this.lobbyData?.state === BossLobbyState.Transfering;
	}
	public get participants(): Array<ClientBossPlayerData | null> {
		if (!this.lobbyData) {
			return [];
		}
		const list: Array<ClientBossPlayerData | null> = [];
		for (const id in this.lobbyData.participants) {
			if (Object.prototype.hasOwnProperty.call(this.lobbyData.participants, id)) {
				const player = this.lobbyData.participants[id];
				list.unshift(player);
			}
		}
		while (list.length < 4) {
			list.unshift(null);
		}
		// list.unshift(
		// 	null,
		// 	// {
		// 	// 	uid: 'c',
		// 	// 	deco: '',
		// 	// 	fishType: FishType.BlueShark,
		// 	// 	isOffline: false,
		// 	// 	isReady: false,
		// 	// 	name: 'ccc',
		// 	// 	skin: 1,
		// 	// 	muted: false,
		// 	// 	mutedYou: false,
		// 	// },
		// 	// {
		// 	// 	uid: 'b',
		// 	// 	deco: '',
		// 	// 	fishType: FishType.BlueRay,
		// 	// 	isOffline: false,
		// 	// 	isReady: false,
		// 	// 	name: 'bbb',
		// 	// 	skin: 1,
		// 	// 	muted: false,
		// 	// 	mutedYou: false,
		// 	// },
		// 	{
		// 		uid: 'a',
		// 		deco: '',
		// 		fishType: FishType.ArchBeluga,
		// 		readyTime: 1,
		// 		name: 'aaa',
		// 		skin: 1,
		// 		muted: false,
		// 		mutedYou: false,
		// 	});
		return list;
	}

	public get me() {
		const me = this.lobbyData?.participants[userx.uid || ''] || null;
		return me as ClientBossPlayerData | null;
	}
	public get canClaimHost() {
		if (!this.me || !this.me.readyTime) {
			return false;
		}
		return this.me.readyTime + 30000 < this.time;
	}
	public get socketErrorMessage() {
		return gamex.socketErrorMessage;
	}
	public get rejectReason() {
		return gamex.rejectReason;
	}
	public get canSos() {
		return this.lobbyData && this.lobbyData.roomOptions.privacy === LobbyPrivacy.Public && this.time >= this.nextSosTime;
	}
	public kickableTime: Dictionary<number> = {};

	public nextSosTime = 0;
	public nextReadyTime = 0;
	public time = Date.now();
	public interval: any;
	public difficulty = 1;
	@Watch('lobbyData', { immediate: true })
	public onLobbyDataChange(options: BossLobbyData) {
		if (options) {
			if (options.roomOptions && options.roomOptions.difficulty) {
				if (options.roomOptions.difficulty !== this.difficulty) {
					this.difficulty = options.roomOptions.difficulty;
				}
			}
			if (options.participants) {
				for (const uid in this.kickableTime) {
					if (Object.prototype.hasOwnProperty.call(this.kickableTime, uid)) {
						if (!options.participants[uid]) {
							delete this.kickableTime[uid];
						}
					}
				}
				for (const uid in options.participants) {
					if (Object.prototype.hasOwnProperty.call(options.participants, uid)) {
						if (!this.kickableTime[uid]) {
							this.kickableTime[uid] = this.time + 600;
						}
					}
				}
			}
		}

	}
	public onRejectHostChallenge() {
		Global.clientCommunicator.rejectClaimHostBossLobby();
	}
	public onDropDown(e) {
		if (this.myUid !== this.lobbyData?.host) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `Only the host can change the room privacy type.`,
			});
			e.preventDefault();
			return;
		}
	}
	public onPrivacy(n: number) {
		Global.clientCommunicator.changeBossRoomOptions({ privacy: n });
	}
	public async onChooseFish() {
		const modal = this.$refs.selectModal as BossFishSelectModal;
		const fishType = await modal.show(
			this.me!.fishType,
		);
		if (fishType && this.me!.fishType !== fishType) {
			this.me!.fishType = fishType;
			Global.clientCommunicator.changeBossPlayerOptions({
				fishType,
			});
		}
	}

	public onDifficulty(direction: number) {
		if (direction < 0 && this.difficulty > 1) {
			this.difficulty -= 1;
			Global.clientCommunicator.changeBossRoomOptions({ difficulty: this.difficulty });
		} else if (direction > 0) {
			if (this.difficulty < 3) {
				this.difficulty += 1;
				Global.clientCommunicator.changeBossRoomOptions({ difficulty: this.difficulty });
			}
		}
	}
	public onStartNow() {
		if (this.numPlayers < 2) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `Need at least 2 players to start the game.`,
			});
		} else if (!this.isAllReady) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `Please wait for all players to be ready.`,
			});
		} else {
			const ct = Date.now();
			if (ct > this.nextReadyTime) {
				this.nextReadyTime = ct + 1000;
				this.saveFish(this.me!.fishType);
				Global.clientCommunicator.readyBossLobby();
			}
		}
	}
	public onReady() {
		if (this.me?.readyTime) {
			Global.clientCommunicator.unreadyBossLobby();
		} else {
			const ct = Date.now();
			if (ct > this.nextReadyTime) {
				this.nextReadyTime = ct + 1000;
				this.saveFish(this.me!.fishType);
				Global.clientCommunicator.readyBossLobby();
			}
		}
	}
	public async onClaimHost() {
		const value = await Global.$bvModal.msgBoxConfirm(
			'If the host does not react in 10 seconds, you will be given the host role.',
			{
				size: 'sm',
				cancelVariant: 'link',
				okVariant: 'primary',
				okTitle: 'Continue',
				cancelTitle: 'Cancel',
				modalClass: 'funny-modal',
				centered: true,
				titleHtml: `Claim to become the host?`,
			},
		);

		if (value) {
			Global.clientCommunicator.claimHostBossLobby();
		}
	}
	public saveFish(fishType: FishType) {
		if (this.me) {
			if (!userx.userSettings.lastBossHeroes || userx.userSettings.lastBossHeroes[0] !== fishType) {
				userx.updateBossHeroes({ life: 0, fishType: this.me.fishType });
			}
		}
	}
	public onKick(uid: string) {
		Global.clientCommunicator.kickBoss(uid);
	}
	public async onSos() {
		if (!this.lobbyData) {
			return;
		}
		if (this.myUid !== this.lobbyData.host) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `Only the host can change the room privacy type.`,
			});
			return;
		}
		if (this.lobbyData.roomOptions.privacy !== LobbyPrivacy.Public) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `SOS can only be sent for a public room.`,
			});
			return;
		}
		const ct = this.time;
		if (ct < this.nextSosTime) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `An SOS was just sent. Please wait for a few seconds.`,
			});
			return;
		}
		const value = await Global.$bvModal.msgBoxConfirm(
			'Send an SOS to nearby servers to ask for help?',
			{
				size: 'sm',
				cancelVariant: 'link',
				okVariant: 'secondary',
				okTitle: 'Yes',
				cancelTitle: 'Cancel',
				modalClass: 'funny-modal',
				centered: true,
				titleHtml: `Send SOS`,
			},
		);
		if (value) {
			this.nextSosTime = ct + 30000;
			Global.clientCommunicator.sosBoss();
		}
	}
	public onView(fishType: FishType) {
		Global.fishUnlockModal.show(fishType);
	}
	public onQuit() {
		Global.stageControl.uiControl.onLeaveBoss();
	}
	public mounted() {
		Global.clientCommunicator.stateManager.initState(53);
		this.interval = setInterval(() => {
			this.time = Date.now();
		}, 1000);
	}
	public beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}
	public onContext(event: any, uid: string) {
		(this.$refs.chatbox as BossChatWindow).onContext(event, uid);
	}
	@Watch('socketErrorMessage')
	public async socketErrorMessageChanged(value: string) {
		await this.$bvModal.msgBoxOk(value, {
			titleHtml: `<i class="fa fa-exclamation-triangle"></i> Connection Lost`,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			modalClass: 'funny-modal',
		});
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('BossList');
	}
	@Watch('rejectReason')
	public async rejectReasonChanged(value: string) {
		await this.$bvModal.msgBoxOk(value, {
			titleHtml: `<i class="fa fa-exclamation-triangle"></i> Join Room Failed`,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'danger',
			modalClass: 'funny-modal',
		});
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('BossList');
	}
}
