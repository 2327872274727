






















import { Global } from '@/store/globalz';
import { Dictionary } from '@/util/dictionary';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DecoArranger from './DecoArranger.vue';
import DecoProperties from './DecoProperties.vue';
import {
  DecoItem,
  Decoratives,
  DecoSplitType,
  EYE_W,
  EYE_Y,
  MOUTH_H,
  MOUTH_Y,
} from './decorative';
import userx from '@/store/modules/userx';
import { use } from 'vue/types/umd';
import { PinchDelta } from './decoPinchArea';
import { generateOutline } from './generateOutline';
import { Graphics, Point, Rectangle } from 'pixi.js';
import { SimplePolygon } from '@/util/simplePolygon';
import { DecoData, decoDic, DecoSet } from '@/game/infos/decorativeInfos';
import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import { FishType } from '@/game/infos/fishInfos';
import { arrayRemoveOne } from '@/util/array';
import {
  eyeGuideDatas,
  mouthGuideDatas,
} from '@/client/factory/assets/playerModel';
import { wait } from '@/util/wait';
import { ItemCode, itemInfos } from '@/game/infos/itemInfos';

const emptyDecoSet: DecoSet = { decos: {}, body: null, top: [], bottom: [] };

@Component({
  components: {
    DecoArranger,
    DecoProperties,
  },
})
export default class DecoEditor extends Vue {
  public get skinGroup() {
    return userx.equippedSkins[this.fishType] || 0;
  }
  public get numDecos() {
    return Object.keys(this.decoSet.decos).length;
  }

  public get newDecoSet(): DecoSet {
    const top: string[] = [];
    const bottom: string[] = [];
    let isBottom = false;
    for (const item of this.list) {
      if (item.decoId === 'body') {
        isBottom = true;
        continue;
      }
      const array = isBottom ? bottom : top;
      array.push(item.decoId);
    }
    const decos = JSON.parse(JSON.stringify(this.decoSet.decos));
    for (const decoId in decos) {
      if (Object.prototype.hasOwnProperty.call(decos, decoId)) {
        const deco = decos[decoId];
        delete deco.preferedScale;
        // if (deco.type === 'eyewear') {
        //   const eyeGuide = Global.modelAvatar.eyeGuide;
        //   const esx = eyeGuide.scale.x;
        //   const ey = eyeGuide.y;
        //   const nx = deco.x;
        //   deco.y -= ey;
        //   deco.x /= esx;
        //   const d = Math.abs(deco.x) / (EYE_W / 2);
        //   if (d < 1) {
        //     const scale = (1 - d) * (esx - 1) + 1;
        //     deco.scale /= scale;
        //   }
        // } else if (deco.type === 'mouthpiece') {
        //   const mouthGuide = Global.modelAvatar.mouthGuide;
        //   const msx = mouthGuide.scale.x;
        //   const msy = mouthGuide.scale.y;
        //   const mh = msy * MOUTH_H;
        //   const my = mouthGuide.y;
        //   deco.y -= my;
        //   if (deco.y >= mh) {
        //     deco.y = deco.y - mh + MOUTH_H;
        //   } else if (deco.y > 0) {
        //     deco.y /= msy;
        //     deco.x /= msx;
        //   }
        // }
      }
    }
    return { version: 1, decos, body: null, top, bottom };
  }
  public get selectedDeco() {
    if (this.selectedId === 'body') {
      return null;
    }
    return this.decoSet.decos[this.selectedId];
  }
  public get forceOpen() {
    return this.width > 640;
  }
  // public decoShape = new Graphics();
  // public rect = new Graphics();

  @Prop({ default: 1 }) public fishType!: FishType;
  public saving = false;
  public width: number = document.documentElement.clientHeight;
  public height: number = document.documentElement.clientWidth;
  public list: DecoItem[] = [];
  public originalItemCodes: Dictionary<number> = {};
  public selectedId = 'body';
  public selectedItemIndex = 0;
  public body: DecoItem = {
    id: 0,
    decoId: 'body',
    decoName: 'Body',
    name: 'Body',
    splitType: DecoSplitType.NONE,
  };
  public decoSet: DecoSet = { decos: {}, body: null, top: [], bottom: [] };
  public decoIdCount = 1;

  public decoInitProp = { scale: 1, x: 0, y: 0, angle: 0 };

  public selectedPolygon = new SimplePolygon();

  @Watch('skinGroup')
  public skinGroupChanged() {
    Global.modelAvatar.setFishType(this.fishType, this.skinGroup);
    Global.modelAvatar.setAction('idle');
  }

  public onKeyDown(e: KeyboardEvent) {
    const deco = this.selectedDeco;
    if (!deco) {
      return;
    }
    const delta = 1 / Global.modelAvatar.parent.scale.x;
    switch (e.code) {
      case 'KeyW':
        deco.y -= delta;
        break;
      case 'KeyA':
        deco.x -= delta;
        break;
      case 'KeyS':
        deco.y += delta;
        break;
      case 'KeyD':
        deco.x += delta;
        break;
    }
    this.examineShapeLimit();

    this.decoSet = JSON.parse(JSON.stringify(this.decoSet));
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }

  public onPinchMove(delta: PinchDelta) {
    const prop = this.decoInitProp;
    const deco = this.selectedDeco;
    if (!deco) {
      return;
    }
    deco.x = prop.x + delta.deltaX;
    deco.y = prop.y + delta.deltaY;
    deco.angle = prop.angle + delta.deltaAngle;
    while (deco.angle < -180) {
      deco.angle += 360;
    }
    while (deco.angle > 180) {
      deco.angle -= 360;
    }
    deco.preferedScale = prop.scale * delta.scale;
    if (deco.preferedScale > 0.5) {
      deco.preferedScale = 0.5;
    }
    if (deco.preferedScale < 0.05) {
      deco.preferedScale = 0.05;
    }
    // this.decoSet[deco.decoId] = { ...deco };
    // this.decoSet.decos = { ...this.decoSet.decos };
    this.examineShapeLimit();

    this.decoSet = JSON.parse(JSON.stringify(this.decoSet));
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }
  public onPinchSet() {
    const prop = this.decoInitProp;
    const deco = this.selectedDeco;
    if (!deco) {
      return;
    }
    prop.x = deco.x;
    prop.y = deco.y;
    prop.angle = deco.angle;
    prop.scale = deco.preferedScale!;
  }

  public setupDeco() {
    const decoSet = this.decoSet;
    const toSplit: Dictionary<DecoItem> = {};
    const done: string[] = [];
    const top: DecoItem[] = [];
    const bottom: DecoItem[] = [];
    // for (const decoId in decoSet.decos) {
    //   if (Object.prototype.hasOwnProperty.call(decoSet.decos, decoId)) {
    //     const deco = decoSet.decos[decoId];
    //     this._normalizeNonGear(deco);
    //   }
    // }
    const foo = (decoId: string) => {
      if (done.includes(decoId)) {
        return null;
      }
      const decoName = decoSet.decos[decoId].decoName;
      const decoInfo = Decoratives.getDecoInfo(decoName);
      const item: DecoItem = {
        id: this.decoIdCount++,
        decoId,
        decoName,
        name: decoInfo.name,
        splitType: DecoSplitType.NONE,
      };
      if (toSplit[decoId]) {
        item.splitType = DecoSplitType.PART_B;
        item.entangle = toSplit[decoId];
        toSplit[decoId].entangle = item;
        delete toSplit[decoId];
        done.push(decoId);
        item.name = `${decoInfo.name} B`;
      } else if (decoInfo.splitted) {
        item.splitType = DecoSplitType.PART_A;
        toSplit[decoId] = item;
        item.name = `${decoInfo.name} A`;
      } else {
        done.push(decoId);
      }
      return item;
    };
    for (const decoId of decoSet.top) {
      const item = foo(decoId);
      if (item) {
        top.push(item);
      }
    }
    for (const decoId of decoSet.bottom) {
      const item = foo(decoId);
      if (item) {
        bottom.push(item);
      }
    }
    this.list = [...top, this.body, ...bottom];
    this.originalItemCodes = {};
    for (const deco of this.list) {
      if (deco.decoName === 'Body' || deco.splitType === DecoSplitType.PART_B) {
        continue;
      }

      const itemCode = decoDic.indexOf(deco.decoName);
      if (itemCode > 0) {
        this.originalItemCodes[itemCode] =
          (this.originalItemCodes[itemCode] || 0) + 1;
      }
    }
  }
  public async onSkin() {
    Global.decoSkinModal.show(this.fishType);
  }

  public async onSelect(itemIndex: number, decoId: string) {
    this.selectedItemIndex = itemIndex;
    this.selectedId = decoId;
    await this._generatePolygon(decoId);
  }
  // @Watch('selectedDeco', { immediate: true })
  // public async selectedDecoChanged() {
  //   this.examineShapeLimit();
  // }

  public async examineShapeLimit() {
    if (!this.selectedDeco) {
      return;
    }
    const polygon = this.selectedPolygon;
    polygon.x = this.selectedDeco.x - 1;
    polygon.y = this.selectedDeco.y;
    polygon.angle = this.selectedDeco.angle;
    polygon.scaleX =
      this.selectedDeco.preferedScale! * (this.selectedDeco.flipX ? -1 : 1);
    polygon.scaleY =
      this.selectedDeco.preferedScale! * (this.selectedDeco.flipY ? -1 : 1);

    // this.decoShape.clear();
    // this.decoShape.lineStyle(1, 0xff0000, 0.5);
    // this.decoShape.drawPolygon(
    //   polygon.transformedVertices.map(p => new Point(p.x, p.y)),
    // );
    // Global.modelAvatar.addChild(this.decoShape);
    let bound = polygon.getBounds();
    if (bound.width > 190 || bound.height > 160) {
      // const ogBound = polygon.getLocalBounds();
      let scale;
      if (bound.width > bound.height) {
        scale = (this.selectedDeco.preferedScale! * 190) / bound.width;
      } else {
        scale = (this.selectedDeco.preferedScale! * 160) / bound.height;
      }
      polygon.scaleX = scale * (this.selectedDeco.flipX ? -1 : 1);
      polygon.scaleY = scale * (this.selectedDeco.flipY ? -1 : 1);
      this.selectedDeco.scale = scale;
      bound = polygon.getBounds();
    } else {
      this.selectedDeco.scale = this.selectedDeco.preferedScale!;
    }
    // console.log(bound.x, bound.y, bound.width, bound.height);
    // this.rect.clear();
    // this.rect.lineStyle(1, 0x00ff00);
    // Global.avatar.addChild(this.rect);
    // this.rect.drawRect(bound.x, bound.y, bound.width, bound.height);
    if (bound.y < -90) {
      this.selectedDeco.y -= bound.y + 90;
    }
    if (bound.x < -95) {
      this.selectedDeco.x -= bound.x + 95;
    }

    if (bound.y + bound.height > 70) {
      this.selectedDeco.y -= bound.y + bound.height - 70;
    }
    if (bound.x + bound.width > 95) {
      this.selectedDeco.x -= bound.x + bound.width - 95;
    }
  }
  public async onRemove(decoId: string) {
    delete this.decoSet.decos[decoId];
    // this.decoSet.top = this.decoSet.top.filter(id => id !== decoId);
    // this.decoSet.bottom = this.decoSet.bottom.filter(id => id !== decoId);
    this.decoSet = { ...this.decoSet };
    let currentIndex = this.selectedItemIndex;
    let selectedItem = this.list[currentIndex];
    while (selectedItem && selectedItem.decoId === decoId) {
      selectedItem = this.list[++currentIndex];
    }
    selectedItem = selectedItem || this.body;
    this.list = this.list.filter((item) => item.decoId !== decoId);
    this.selectedItemIndex = this.list.indexOf(selectedItem);
    this.selectedId = selectedItem.decoId;
    await this._generatePolygon(this.selectedId);
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }
  public async onChange(index: number) {
    const element = this.list[index];
    const decoId = element.decoId;
    const deco = this.decoSet.decos[decoId];
    const result = await Global.decoInvetoryModal.show(
      'changeDeco',
      this.originalItemCodes,
      this.list,
      deco.decoName,
    );
    if (!result) {
      return;
    }
    this.selectedItemIndex = index;
    const selectedItem = this.list[this.selectedItemIndex];
    this.selectedId = selectedItem.decoId;
    const info = Decoratives.getDecoInfo(result);
    deco.decoName = result;
    element.decoName = result;
    if (element.splitType === DecoSplitType.NONE) {
      element.name = info.name;
      if (!info.splitted) {
      } else {
        const b: DecoItem = {
          name: `${info.name} B`,
          id: this.decoIdCount++,
          splitType: DecoSplitType.PART_B,
          decoId,
          decoName: result,
          entangle: element,
        };
        this.list.splice(index + 1, 0, b);
        element.entangle = b;
        element.splitType = DecoSplitType.PART_A;
        element.name = `${info.name} A`;
      }
    } else {
      if (!info.splitted) {
        arrayRemoveOne(this.list, element.entangle);
        element.entangle = undefined;
        element.splitType = DecoSplitType.NONE;
        element.name = info.name;
      } else if (element.splitType === DecoSplitType.PART_A) {
        element.name = `${info.name} A`;
        element.entangle!.name = `${info.name} B`;
        element.entangle!.decoName = result;
      } else {
        element.name = `${info.name} B`;
        element.entangle!.name = `${info.name} A`;
        element.entangle!.decoName = result;
      }
    }
    this.selectedItemIndex = this.list.indexOf(selectedItem);
    await this._generatePolygon(decoId);
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }

  public async onAdd() {
    const result = await Global.decoInvetoryModal.show(
      'addDeco',
      this.originalItemCodes,
      this.list,
    );
    if (!result) {
      return;
    }
    const info = Decoratives.getDecoInfo(result);
    const decoId = random.firestore();
    const splitted = info.splitted;
    const a: DecoItem = {
      name: `${info.name}${splitted ? ' A' : ''}`,
      id: this.decoIdCount++,
      splitType: splitted ? DecoSplitType.PART_A : DecoSplitType.NONE,
      decoId,
      decoName: result,
    };
    const toAdd: DecoItem[] = [a];
    if (splitted) {
      const b: DecoItem = {
        name: `${info.name} B`,
        id: this.decoIdCount++,
        splitType: DecoSplitType.PART_B,
        decoId,
        decoName: result,
        entangle: a,
      };
      a.entangle = b;
      toAdd.push(b);
    }
    const deco = {
      decoName: result,
      decoId,
      x: info.defaultX,
      y: info.defaultY,
      scale: info.defaultScale,
      angle: info.defaultAngle,
      type: info.defaultType,
      flipX: false,
      flipY: false,
      preferedScale: info.defaultScale,
    };
    this._normalizeNonGear(deco);

    this.decoSet.decos[decoId] = deco;
    const index = this.selectedItemIndex;
    this.list.splice(index, 0, ...toAdd);
    // this.selectedItemIndex += toAdd.length;
    await this.onSelect(index, toAdd[0].decoId);

    this.decoSet = JSON.parse(JSON.stringify(this.decoSet));
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }

  public onMove(list: DecoItem[]) {
    this.list = list;
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }
  public onMoveUp() {
    const oldIndex = this.selectedItemIndex;
    if (oldIndex === 0) {
      return;
    }
    const target = this.list[oldIndex];
    let newIndex = oldIndex - 1;
    if (target.entangle === this.list[newIndex]) {
      newIndex--;
    }
    this.list.splice(oldIndex, 1);
    this.list.splice(newIndex, 0, target);
    this.checkPartOnMove(newIndex);
  }
  public onMoveDown() {
    const oldIndex = this.selectedItemIndex;
    if (oldIndex === this.list.length - 1) {
      return;
    }
    const target = this.list[oldIndex];
    let newIndex = oldIndex + 1;
    if (target.entangle === this.list[newIndex]) {
      newIndex++;
    }
    this.list.splice(oldIndex, 1);
    this.list.splice(newIndex, 0, target);
    this.checkPartOnMove(newIndex);
  }
  public checkPartOnMove(newIndex: number) {
    const target = this.list[newIndex];

    if (target.entangle) {
      if (target.splitType === DecoSplitType.PART_A) {
        const aIndex = newIndex;
        const bIndex = this.list.indexOf(target.entangle);
        if (bIndex < aIndex) {
          this.list.splice(aIndex, 0, this.list.splice(bIndex, 1)[0]);
        }
      } else if (target.splitType === DecoSplitType.PART_B) {
        const bIndex = newIndex;
        const aIndex = this.list.indexOf(target.entangle);
        if (bIndex < aIndex) {
          this.list.splice(bIndex, 0, this.list.splice(aIndex, 1)[0]);
        }
      }
    }
    this.selectedItemIndex = this.list.indexOf(target);
    this.list = [...this.list];
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }
  public onPropUpdate(deco: DecoData) {
    const { decoId } = deco;
    this.decoSet.decos[decoId] = deco;

    this.examineShapeLimit();

    this.decoSet = { ...this.decoSet };
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
  }

  public activated() {
    this.selectedItemIndex = 0;
    this.selectedId = 'body';
    this.decoSet = JSON.parse(
      JSON.stringify(userx.decoSets[this.fishType] || emptyDecoSet),
    );
    const decoSet = this.decoSet;
    const decoIds = Object.keys(decoSet.decos);
    const topBottomIds = new Set([...decoSet.top, ...decoSet.bottom]);
    if (topBottomIds.size < decoIds.length) {
      for (const id of topBottomIds) {
        arrayRemoveOne(decoIds, id);
      }
      for (const id of decoIds) {
        delete this.decoSet.decos[id];
      }
    }

    for (const decoId in this.decoSet.decos) {
      if (Object.prototype.hasOwnProperty.call(this.decoSet.decos, decoId)) {
        const deco = this.decoSet.decos[decoId];
        deco.preferedScale = deco.scale;
      }
    }
    Global.modelAvatar.setFishType(this.fishType, this.skinGroup);
    Global.modelAvatar.setAction('idle');
    this.setupDeco();
    Decoratives.setAvatarDeco(this.decoSet.decos, this.list, true);
    this.resize();
    window.addEventListener('resize', this.resize);
    window.addEventListener('keydown', this.onKeyDown);
    Global.decoEditorControl.show();
    Global.decoEditorControl.on('pinchset', this.onPinchSet.bind(this));
    Global.decoEditorControl.on('pinchend', this.onPinchSet.bind(this));
    Global.decoEditorControl.on('pinchmove', this.onPinchMove.bind(this));
  }
  public deactivated() {
    this.decoSet = emptyDecoSet;
    this.setupDeco();
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('keydown', this.onKeyDown);
    Global.decoEditorControl.off('pinchset');
    Global.decoEditorControl.off('pinchend');
    Global.decoEditorControl.off('pinchend');
  }
  public resize() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
  }

  public async onBack() {
    const newDecoSet = this.newDecoSet;
    // console.log(JSON.stringify(newDecoSet));
    // console.log(JSON.stringify(userx.decoSet));
    if (
      (!userx.decoSets[this.fishType] &&
        newDecoSet.top.length + newDecoSet.bottom.length === 0) ||
      JSON.stringify(newDecoSet) === JSON.stringify(userx.decoSets[this.fishType])
    ) {
      Decoratives.clearAvatarCache();
      this.$emit('back');
      return;
    }
    const toSave = await this.$bvModal.msgBoxConfirm('Save Changes?', {
      title: 'Save Changes?',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'primary',
      cancelVariant: 'secondary',
      okTitle: 'YES',
      cancelTitle: 'NO',
      hideHeaderClose: false,
      centered: true,
      bodyClass: 'd-none',
      headerTextVariant: 'gray-900',
    });
    // modal closed
    if (toSave === null) {
      return;
    }
    if (toSave) {
      this.saving = true;
      try {
        const isIllegal = await Decoratives.isDecoOutOfBound(newDecoSet, 0);
        if (isIllegal) {
          throw new Error('Out of bound!');
        }
        await userx.updateDecoSet({
          fishType: this.fishType,
          decoSet: newDecoSet,
        });
        wait(1000).then(() => {
          Decoratives.clearAvatarCache();
        });
        this.$emit('back');
      } catch (error) {
        this.$root.$emit('error', error);
      }
      this.saving = false;
    } else {
      this.$emit('back');
    }
  }

  protected _normalizeNonGear(deco: DecoData) {
    if (deco.type === 'eyewear') {
      const eyeGuide = eyeGuideDatas[this.fishType];
      const esx = eyeGuide.scaleX;
      deco.y += eyeGuide.y;
      const d = Math.abs(deco.x) / (EYE_W / 2);
      if (d < 1) {
        const scale = (1 - d) * (esx - 1) + 1;
        deco.scale *= scale;
        deco.preferedScale = Math.min(deco.scale, 0.5);
      }
    } else if (deco.type === 'mouthpiece') {
      const mouthGuide = mouthGuideDatas[this.fishType];
      const msx = mouthGuide.scaleX;
      const msy = mouthGuide.scaleY;
      const mh = msy * MOUTH_H;
      const my = mouthGuide.y;
      if (deco.y <= 0) {
        deco.y += my;
      } else if (deco.y >= MOUTH_H) {
        deco.y += my + mh - MOUTH_H;
      } else {
        deco.x *= msx;
        deco.y *= msy;
        deco.y += my;
      }
      deco.scale *= msx;
      deco.preferedScale = Math.min(deco.scale, 0.5);
    }
  }
  private async _generatePolygon(decoId: string) {
    if (decoId && decoId !== 'body') {
      const selectedDeco = this.decoSet.decos[decoId];
      const outline = await generateOutline(selectedDeco.decoName);
      this.selectedPolygon.vertices = outline;
      this.examineShapeLimit();
    }
  }
}
