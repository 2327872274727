import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsBandage } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import { disposeParticle, getParticle, Particle } from '../../particles';
import { getSpecialSprite, SpecialSkillAsset } from '../../skillAssets';
import { MiscSymbol } from './miscSymbol';

const info = specialSkillInfos[SpecialSkill.Bandage];
const duration = info.duration;

export class SsBandageSymbol extends Container implements MiscSymbol {
	public static get() {
		return SsBandageSymbol._pool.get();
	}

	private static _pool: Pool<SsBandageSymbol> = new Pool(SsBandageSymbol);

	public data: MiscData | null = null;

	public icon = getSpecialSprite(SpecialSkillAsset.Bandage);
	public circle = new Graphics();
	public bg = new Graphics();
	public duration = duration;

	public sound = new LongSoundEfx('bandage');

	constructor() {
		super();
		this.addChild(
			this.bg,
			this.icon,
			this.circle,
		);
		this.bg.beginFill(0x0050FA, 0.8);
		this.bg.drawCircle(0, 0, 20);
	}

	public dispose(room: RoomControl) {
		this.data = null;
		if (this.parent) {
			this.parent.removeChild(this);
		}
		this.sound.stop();
		SsBandageSymbol._pool.pool(this);
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsBandage;

		if (!this.data) {
			// this.particleFlag = 0;
			this.sound.play({ loop: true, start: 0 });
			this.duration = duration * data.bst;
		}
		const percent = (rt - data.st) / this.duration;
		this.circle.clear();
		this.circle.lineStyle(4, 0xffff00);
		this.circle.arc(0, 0, 20, -Math.PI / 2, Math.PI * 2 * percent - Math.PI / 2);

		this.data = data;
		this.x = dt.x + 45;
		this.y = dt.y + 45;

		// if (player && this.particleFlag % 15 === 0) {
		// }
		// this.particleFlag++;

		const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * 0.3;
		this.sound.volume = volume;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		room.playerTopContainer.removeChild(this);

	}
}
