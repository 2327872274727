













import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { BTooltip } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { wait } from '@/util/wait';
import { spearSkills, SpearType, weaponDecs, weaponLongNames, weaponNames } from '@/game/infos/spearInfos';
import { specialSkillInfos } from '@/game/infos/skills';

export interface WeaponIntelData {
	targetId: string;
	placement: 'top' | 'left' | 'right' | 'bottom';
	container: any;
	spearType: SpearType;
}
@Component({
	components: {
	},
})
export default class WeaponIntelToolTip extends Vue {

	public get shortName() {
		return weaponNames[this.spearType];
	}
	public get longName() {
		return weaponLongNames[this.spearType];
	}

	public get desc() {
		if (spearSkills[this.spearType]) {
			return specialSkillInfos[spearSkills[this.spearType]].desc;
		}
		return weaponDecs[this.spearType];
	}
	public targetId = '';
	public placement = 'top';
	public container: any = null;
	public spearType: SpearType = SpearType.Tusk;
	public show = false;
	protected localShow = false;

	@Watch('localShow', { immediate: true })
	public async showChanged() {
		if (this.localShow) {
			await wait(100);
			if (this.localShow) {
				this.show = true;
			}
		} else {
			this.show = false;
		}
	}

	public mounted() {
		this.$root.$on('showWeaponIntel', this.showIntel);
		this.$root.$on('hideWeaponIntel', this.hideIntel);
	}
	public beforeDestroy() {
		this.$root.$off('showWeaponIntel', this.showIntel);
		this.$root.$off('hideWeaponIntel', this.hideIntel);
	}

	public async showIntel(data: WeaponIntelData) {
		this.targetId = data.targetId;
		this.placement = data.placement;
		this.container = data.container;
		this.spearType = data.spearType;
		// this.$nextTick(() => {
		this.localShow = true;
		// });
	}
	public hideIntel() {
		this.localShow = false;
		this.targetId = '';
	}
}
