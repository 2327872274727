














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
// import { FoodWallDebug } from '@/game/debug/foodWallDebug';
import { Bounds, Events, Pairs, Render, Vector } from 'matter-js';
import { Point, Rotate } from '@/util/rotate';
import { Global } from '@/store/globalz';
import { OfflineCommunicator } from '@/io/offlineCommunicator';
import { nonReact } from '@/util/nonReact';

@Component({
  components: {},
})
export default class OverlayGameRoomDebug extends Vue {
  public communicator = Global.clientCommunicator as OfflineCommunicator;
  public room = nonReact(this.communicator.currentRoom);

  public dispose: null | (() => void) = null;
  public beforeDestroy() {
    if (this.dispose) {
      this.dispose();
      this.dispose = null;
    }
  }

  public mounted() {
    const container = this.$refs.debugContainer as HTMLDivElement;
    const engine = this.room.engine;

    const options: any = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight - 5,
      showAngleIndicator: true,
      hasBounds: true,
      showCollisions: true,
      // showBroadphase: true,
      // wireframes: false,
    };
    // without this line before create will run into call stack error
    Pairs.clear(engine.pairs);
    // Create a renderer
    const render = nonReact(
      Render.create({
        element: container,
        options,
        engine,
      }),
    );
    const viewportCentre = {
      x: render.options.width! * 0.5,
      y: render.options.height! * 0.5,
    };

    // Run the renderer
    Render.run(render);
    const resize = () => {
      render.canvas.width = document.documentElement.clientWidth;
      render.canvas.height = document.documentElement.clientHeight - 5;
      viewportCentre.x = render.canvas.width / 2;
      viewportCentre.y = render.canvas.height / 2;
      render.bounds.max.x = render.bounds.min.x + render.canvas.width;
      render.bounds.max.y = render.bounds.min.y + render.canvas.height;
      render.options.width = render.canvas.width;
      render.options.height = render.canvas.height;
    };
    const update = () => {
      const deltaCentre = Vector.sub(this.room.viewTarget, viewportCentre);
      Bounds.shift(render.bounds, deltaCentre);
    };

    window.addEventListener('resize', resize);

    Events.on(engine, 'afterUpdate', update);
    this.dispose = () => {
      window.removeEventListener('resize', resize);
      Events.off(engine, 'afterUpdate', update);
    };
  }
}
