import { Point } from './collide';
import { rotate, translate, compose, applyToPoint, scale } from 'transformation-matrix';
import { Rotate } from './rotate';

export class SimplePolygon {
	public vertices: Point[] = [];

	public scaleX = 1;
	public scaleY = 1;
	public x = 0;
	public y = 0;
	public angle = 0;

	public get transformedVertices() {
		const matrix = compose(translate(this.x, this.y), rotate(Rotate.d2r(this.angle)), scale(this.scaleX, this.scaleY));
		return this.vertices.map((point) => applyToPoint(matrix, point));
	}

	public getBounds() {
		let x = Number.POSITIVE_INFINITY;
		let y = Number.POSITIVE_INFINITY;
		let w = Number.NEGATIVE_INFINITY;
		let h = Number.NEGATIVE_INFINITY;
		for (const point of this.transformedVertices) {
			if (point.x < x) { x = point.x; }
			if (point.x > w) { w = point.x; }
			if (point.y < y) { y = point.y; }
			if (point.y > h) { h = point.y; }
		}
		return { x, y, width: w - x, height: h - y };
	}
	public getLocalBounds() {
		let x = Number.POSITIVE_INFINITY;
		let y = Number.POSITIVE_INFINITY;
		let w = Number.NEGATIVE_INFINITY;
		let h = Number.NEGATIVE_INFINITY;
		for (const point of this.vertices) {
			if (point.x < x) { x = point.x; }
			if (point.x > w) { w = point.x; }
			if (point.y < y) { y = point.y; }
			if (point.y > h) { h = point.y; }
		}
		return { x, y, width: w - x, height: h - y };
	}
}

const rect = [
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
    { x: 0, y: 0 },
];
export function helperRect(x1: number, y1: number, x2: number, y2: number) {
    rect[0].x = x1;
    rect[0].y = y1;
    rect[1].x = x2;
    rect[1].y = y1;
    rect[2].x = x2;
    rect[2].y = y2;
    rect[3].x = x1;
    rect[3].y = y2;
    return rect;
}
