
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Chrome } from 'vue-color';
import random from '@/util/random';

@Component({
  components: {
    Chrome,
  },
})
export default class ColorPickerButton extends Vue {
  @Prop({ default: '#ff0000' }) public value!: any;

  public get colorBg() {
    return { 'background-color': this.input.hex || this.input };
  }

  protected input = this.value;
  protected oldValue = this.input;
  @Watch('value', { immediate: true })
  public valueChanged() {
    this.input = this.value;
  }
  @Watch('input', { immediate: true })
  public inputChanged() {
    if (this.value !== this.input) {
      this.$emit('input', this.input.hex);
    }
  }
  public onShow() {
    this.oldValue = this.input;
  }
  public onHidden() {
    if (this.input !== this.oldValue) {
      this.$emit('change', this.input.hex);
    }
  }

  public get popoverId() {
    return random.v4();
  }
}
