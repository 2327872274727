
























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gamex from '@/store/modules/gamex';
import { TourneyLobbyState } from '@/game/infos/roomInfosTny';
import { ChatType } from '@/game/infos/eventType';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { ContextMenuItem } from '../ContextMenu.vue';
import userx from '@/store/modules/userx';
import { Global } from '@/store/globalz';
import { wait } from '@/util/wait';
import ScrollContainer from '../ScrollContainer.vue';
import ChatLogs from '../game/ChatLogs.vue';

@Component({
  components: {
    ScrollContainer,
    ChatLogs,
  },
})
export default class TnyChatWindow extends Vue {
  public get tnyLobbyData() {
    return gamex.tnyLobbyData;
  }
  public get participants() {
    return this.tnyLobbyData?.participants || {};
  }
  public get lobbyClosed() {
    return this.tnyLobbyData?.state === TourneyLobbyState.Closed;
  }
  public msgInput = '';
  public mutedAll = false;
  public get chatLogs() {
    return gamex.chatLogs;
  }
  public onSubmit(e) {
    if (this.msgInput.trim() === '') {
      return;
    }
    if (this.lobbyClosed) {
      return;
    }
    if (this.msgInput.length > 100) {
      gamex.addSystemMessage({
        chatType: ChatType.ChatSystem,
        t: gamex.currentGameState.t,
        msg: `Character limit 100.`,
      });
      new SoundEfx('chatFailed').play();
      return;
    }
    // const command: CommandChatAction = {
    //   type: GameCommandType.Chat,
    //   sessionId: gamex.currentGameState.sid,
    //   msg: this.msgInput,
    // };
    // Global.clientCommunicator.sendCommand(command);
    Global.clientCommunicator.sendChatTourney(this.msgInput);
    this.msgInput = '';
  }
  public onContext(event: any, uid: string) {
    const user = gamex.tnyLobbyData?.participants[uid];
    if (!user) {
      return;
    }

    const list: ContextMenuItem[] = [
      {
        icon: '',
        text: 'Mention',
        disabled: false,
        click: () => {
          this.mention(user.name!);
        },
      },
    ];
    if (userx.uid !== uid) {
      list.push({
        icon: '',
        text: user.muted ? 'Unmute' : 'Mute',
        disabled: false,
        click: () => {
          this.mute(uid, !user.muted);
        },
      });
    }
    Global.contextMenu.open(event, list);
  }
  public mention(name: string) {
    if (this.msgInput) {
      this.msgInput += ' ';
    }
    this.msgInput += '@' + name + ' ';
    wait(500).then(() => {
      (this.$refs.msgInput as HTMLElement).focus();
    });
  }
  public mute(uid: string, value: boolean) {
    Global.clientCommunicator.muteTourney(uid, value);
  }
}
