import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsDart, MiscDataSsStrongArms } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container, DisplayObject, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { BurningEffect, FrozenEffect, ParalyzedEffect } from '../hazardMixin';
import { MiscSymbol } from './miscSymbol';
import { Preset } from '@/game/infos/preset';
import { PlayerSymbol } from '../playerMixin';
import { FishBody } from '../playerModel';
import { PlayerControl } from '@/client/controls/playerControl';
import { SpecialSkillAsset, getSpecialSprite } from '../../skillAssets';

// const info = specialSkillInfos[SpecialSkill.SwimmingFang];
export class SsStrongArmsSymbol extends MovieClip {
}
export class SsStrongArmsController implements MiscSymbol {

	public static get() {
		return SsStrongArmsController._pool.get();
	}

	private static _pool: Pool<SsStrongArmsController> = new Pool(SsStrongArmsController);


	public area: Graphics = new Graphics();
	public data: MiscDataSsStrongArms | null = null;

	public player: PlayerControl | null = null;
	public legs: Container | null = null;
	public closedLegs: Container | null = null;
	public legs2: MovieClip | null = null;

	public symbol = new Container();
	public icon = getSpecialSprite(SpecialSkillAsset.StrongArms);
	public circle = new Graphics();
	public bg = new Graphics();
	protected state = -1;
	protected isMe: RoomControl | null = null;

	constructor() {
		this.area.lineStyle(1, 0xFFFF00, 0.75);
		this.area.beginFill(0xffff99, 0.15);
		this.area.drawCircle(0, 0, 200);
		this.symbol.addChild(
			this.bg,
			this.icon,
			this.circle,
		);
		this.bg.beginFill(0x993333, 0.8);
		this.bg.drawCircle(0, 0, 20);
	}
	public dispose(room: RoomControl) {

		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		this.symbol.parent?.removeChild(this.symbol);
		if (this.player) {
			this.player.label.isStrongArmer = false;
			this.player.label.alpha = 1;
			this.player.label.scale.set(1);
			this.legs!.addChild(this.closedLegs!);
			this.legs!.visible = true;
			this.closedLegs!.x = 0;
			this.closedLegs!.y = 0;
			this.closedLegs!.angle = 0;
			this.closedLegs!.scale.set(1);
			this.legs2!.visible = false;
			this.player.symbol.off('updateSkin');
			this.player.inDisguise = false;
			this.player.symbol.alpha = this.closedLegs!.alpha = 1;
			this.player = null;
			this.legs = null;
			this.legs2 = null;
		}
		this.data = null;
		this.state = -1;
		if (this.isMe) {
			this.isMe.strongArmingVid = 0;
			this.isMe = null;
		}

		SsStrongArmsController._pool.pool(this);


	}

	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataSsStrongArms;

		if (!this.data) {
			this.isMe = data.pid === state.pid ? room : null;
		}

		this.area.x = data.x;
		this.area.y = data.y;
		this.area.visible = data.p < 0;

		this.symbol.x = data.x;
		this.symbol.y = data.y - 200;
		this.symbol.visible = data.p > 0;

		if (!this.player) {
			const player = room.allPlayers[data.pid];
			if (player) {
				this.player = player;
				const processLegs = () => {
					this.state = -1;
					const skin = (player.symbol.body.children[0] as any);
					const legs = this.legs = skin.legs as Container;
					const legs2 = this.legs2 = skin.legs2 as MovieClip;
					const closedLegs = this.closedLegs = legs.children[0] as Container;
					legs2.alpha = 1;
					legs2.visible = false;
					skin.on('reset', () => {
						legs.addChild(closedLegs);
						legs.visible = true;
						closedLegs.scale.set(1);
						closedLegs.x = closedLegs.y = closedLegs.angle = 0;
						legs2.visible = false;
						skin.setTint(0xffffff);
					});
				};
				processLegs();
				player.symbol.on('updateSkin', processLegs);
			}
		}
		if (this.player) {

			if (data.p < 0) {
				if (this.state === -1) {
					this.legs!.visible = false;
					this.legs2!.visible = true;
					this.state = 0;
					this.legs2!.gotoAndPlay(0);
				}
			} else {

				if (this.isMe) {
					room.strongArmingVid = data.vid;
				}
				this.player.label.isStrongArmer = true;
				this.player.label.alpha = 0.5;
				this.player.label.scale.set(0.75);
				const playerSymbol = this.player.symbol;
				this.closedLegs!.x = playerSymbol.x;
				this.closedLegs!.y = playerSymbol.y - 5;
				this.closedLegs!.angle = playerSymbol.angle;
				this.closedLegs!.scale.set(playerSymbol.scale.x);
				if (this.state <= 0 && playerSymbol.parent) {
					this.legs!.visible = true;
					this.legs2!.visible = false;
					playerSymbol.setTint(0xffffff);
					playerSymbol.parent.addChild(this.closedLegs!);
					playerSymbol.parent.addChildAt(playerSymbol, 0);
					this.state = 1;
				}
				const target = room.allPlayers[data.vid];
				if (target) {
					this.player.inDisguise = target.inDisguise;
					this.closedLegs!.alpha = this.player.symbol.alpha = target.symbol.alpha;
				}
				this.circle.clear();
				this.circle.lineStyle(4, 0xff0033);
				this.circle.arc(0, 0, 20, -Math.PI / 2, Math.PI * 2 * data.p - Math.PI / 2);
				// const control = room.allPlayers[data.pid];
				// if (control) {
				// 	control.label.text1.text = control.label.text2.text = data.p;
				// }

			}
		}

		this.data = data;
	}
	public show(room: RoomControl) {
		if (this.data) {
			room.playerBottomContainer.addChild(this.area);
			room.playerTopContainer.addChild(this.symbol);
		}
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		this.symbol.parent?.removeChild(this.symbol);
		this.state = -1;
	}

}
