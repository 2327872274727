















































import userx from '@/store/modules/userx';
import { addComma, addMK } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { WealthHighscoreData } from '@/game/infos/highscoreInfos';
import random from '@/util/random';
import { ItemCode, Rarity } from '@/game/infos/itemInfos';
import ItemButton from '../tools/inventory/ItemButton.vue';

@Component({
  components: {},
})
export default class WealthHighscoreComponent extends Vue {
  @Prop({ default: 0 }) public index!: number;
  @Prop() public data!: WealthHighscoreData;

  public id = random.v4();
  public expand = this.index === 0;

  public get name() {
    return this.data.name;
  }
  public get money() {
    return addComma(this.data.money);
  }
  public get moneySpent() {
    return addMK(this.data.record.totalMoneyEarned - this.data.money, 2, 0);
  }
  public get isMe() {
    return this.data.uid === userx.uid;
  }
  public get diamond() {
    return this.data.inventory[ItemCode.DiamondOre];
  }
  public get ruby() {
    return this.data.inventory[ItemCode.RubyOre];
  }
  public get gold() {
    return this.data.inventory[ItemCode.GoldOre];
  }
  public get emerald() {
    return this.data.inventory[ItemCode.EmeraldOre];
  }
  public get sapphire() {
    return this.data.inventory[ItemCode.SapphireOre];
  }
  public get mythril() {
    return this.data.inventory[ItemCode.MythrilOre];
  }
}
