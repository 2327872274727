










































































import { AipAds } from '@/client/ads/aipads';
import { CrazyAd } from '@/client/ads/crazyads';
import { UnlockAnimationStar } from '@/client/factory/assets/unlock/unlockParticles';
import { BgmManager } from '@/client/sound/BgmManager';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { PlayerGameState } from '@/game/multithread/state';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import { Tween } from '@/util/tweents';
import { wait } from '@/util/wait';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { GameType } from '@/game/infos/roomInfos';

@Component({
	components: {},
})
export default class GameOverOverlay extends Vue {
	public width = this.$el ? this.$el.clientWidth : 0;
	public height = this.$el ? this.$el.clientHeight : 0;
	public spectate = false;

	public get adsdk() {
		return Global.adsdk;
	}

	public get isArmageddon() {
		return gamex.gameType === GameType.Armageddon;
	}
	public get isBoss() {
		return gamex.gameType === GameType.Boss;
	}
	public get gameStage() {
		return gamex.gameStage;
	}
	public get active() {
		return this.gameStage === 'End' && (this.isArmageddon || this.isBoss);
	}
	public get kills() {
		return gamex.kills;
	}
	public get stabs() {
		return gamex.stabs;
	}
	public get killStreakRecord() {
		return gamex.killStreakRecord;
	}
	public get stabStreakRecord() {
		return gamex.stabStreakRecord;
	}
	public get gold() {
		return gamex.money;
	}
	public get highscore() {
		return gamex.highscore;
	}
	public get place() {
		return gamex.currentGameState.armageddonPlace;
	}
	public get win() {
		return this.isArmageddon && this.place === 1 || this.isBoss && gamex.currentGameState.cpsPlace === 1;
	}
	public get myTime() {
		let tl = Math.round(gamex.currentGameState.timeResult * 5 / 1000);
		const frame = tl % 5;
		tl -= frame;
		tl *= 200;

		const min = Math.floor(tl / 60000);
		tl -= min * 60000;
		const sec = Math.floor(tl / 1000);
		function double(n: number) {
			return n < 10 ? '0' + n : n;
		}
		return `${min}'${double(sec)}"${double(frame * 12)}`;

	}
	@Watch('active', { immediate: true })
	public async activeChanged(newVal: boolean, oldVal: boolean) {
		if (newVal && newVal !== oldVal) {
			wait(500).then(() => {
				if (Global.adsdk === 'crazyads') {
					//   if (this.width < 330) {
					//     CrazyAd.instance.refreshBannerAd('gameOver-320x100');
					//   } else if (this.width < 730) {
					//     CrazyAd.instance.refreshBannerAd('gameOver-468x60');
					//   } else {
					//     CrazyAd.instance.refreshBannerAd('gameOver-728x90');
					//   }
				} else {
					if (this.width < 730) {
						AipAds.instance.refreshBannerAd('stabfish2-io_336x280');
					} else if (this.width < 980) {
						AipAds.instance.refreshBannerAd('stabfish2-io_728x90');
					} else {
						AipAds.instance.refreshBannerAd('stabfish2-io_970x250');
					}
				}
			});
			Tween.get(BgmManager.currentBgm, { override: true }).to(
				{ volume: 0 },
				1000,
			);
			if (this.win) {
				new SoundEfx('win').play();
			} else {
				new SoundEfx('lose').play();
			}
			this.spectate = false;
			if (this.isArmageddon) {
				if (gamex.currentGameState.armageddonStarted) {
					await wait(1500);
					if (this.place === 1) {
						const placeEl = this.$refs.place as HTMLDivElement;
						this.celebrate(placeEl);
					}
					await wait(5000 - 1500);
					if (gamex.currentGameState.state !== PlayerGameState.GameOver) {
						this.spectate = true;
						Tween.get(BgmManager.currentBgm, { override: true }).to(
							{ volume: 0.25 },
							1000,
						);
					}
				}
			} else if (this.isBoss) {
				if (this.win) {
					await wait(1500);
					if (gamex.currentGameState.cpsPlace === 1) {
						const placeEl = this.$refs.time as HTMLDivElement;
						this.celebrate(placeEl);
					}
				}
			}
		}
	}

	public celebrate(el: HTMLDivElement) {

		const bound = el.getBoundingClientRect();
		const centerX = bound.x + bound.width / 2;
		const centerY = bound.y + bound.height / 2;
		for (let i = 0; i < 20; i++) {
			const star = UnlockAnimationStar.get();
			star.init(centerX, centerY);
			Global.overlayGameIndicators.addChild(star.symbol);
		}
	}

	public exit() {
		Global.clientCommunicator.disconnect();
		gamex.setGameStage('Reward');
	}

	public mounted() {
		window.addEventListener('resize', this.onResize);
		this.onResize();
	}

	public beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	}

	public onResize() {
		this.width = this.$el.clientWidth;
		this.height = this.$el.clientHeight;
	}
	//   @Watch('gameStage', { immediate: true })
	//   public gameStageChange(newVal: GameStage) {
	//     if (newVal === 'End') {
	//     }
	//   }
}
