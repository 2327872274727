import assetx from '@/store/modules/assetx';
import { nonReact } from '@/util/nonReact';
import { Loader } from 'pixi.js';
import { Dictionary } from 'vue-router/types/router';

// declare const baXP: any;
let loading = false;

const assetLoader = nonReact(new Loader());
// for (const id in baXP.baXmasPenguin.assets) {
// 	if (Object.prototype.hasOwnProperty.call(baXP.baXmasPenguin.assets, id)) {
// 		const asset = baXP.baXmasPenguin.assets[id];
// 		assetLoader.add(id, asset);
// 	}
// }
export function loadMechguinAssets() {
	if (loading) {
		return;
	}
	assetx.updateMechguinLoadState('loading');
	loading = true;
	assetLoader.onComplete.once(onLoadComplete);
	assetLoader.onError.add(onError);
	assetLoader.onLoad.add(onLoad);
	assetLoader.load();
}

function onError(error: Error, loader: Loader, resources: any) {
	assetx.addLoadError(error);
}
function onLoad(loader: Loader, resource: any) {
	assetx.updateMechguinLoadProgress(loader.progress);
	assetx.updateMechguinLoadState('loading');
}
function onLoadComplete(loader: Loader, resources: Dictionary<any>) {
	assetx.updateMechguinLoadState('completed');
}
