import { MovieClip } from 'pixi-animate';
import { Container, Sprite } from 'pixi.js';

export class EmojiBubble extends Container {

	public container = new Container();
	public symbol: Container | null = null;
	public bubble!: Sprite;

	protected isShown = false;

	public init() {
		this.bubble = this.children[0] as Sprite;
		this.bubble.alpha = 0.9;
		this.container.x = 14;
		this.container.y = -33;
		this.addChild(this.container);
	}
}
