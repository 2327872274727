import { arrayRemoveOne } from '@/util/array';
import { IVolumeControl } from './IVolumeControl';
import { SoundGroup } from './SoundGroup';
import { Sound, PlayOptions } from '@pixi/sound';

export class SoundInstance implements IVolumeControl {

	public get volume() {
		return this._volume;
	}
	public set volume(value) {
		if (value < 0) { value = 0; }
		this._volume = value;
		this.updateVolume();
	}
	public get muted() {
		return this._muted;
	}
	public set muted(value) {
		this._muted = value;
		this.updateVolume();
	}

	public sound: Sound;

	public groups: SoundGroup[] = [];

	private _volume = 1;

	private _muted = false;

	constructor(sound: Sound) {
		this.sound = sound;
	}
	public play(options: PlayOptions) {
		if (this.sound.isPlayable) {
			this.sound.play(options);
		}
	}
	public stop() {
		this.sound.stop();
	}

	public updateVolume() {
		this.sound.volume = this.getVolume();
	}
	public addGroup(group: SoundGroup) {
		this.groups.push(group);
		group.members.push(this);
	}

	public removeGroup(group: SoundGroup) {
		arrayRemoveOne(this.groups, group);
		arrayRemoveOne(group.members, this);
	}

	public dispose() {
		for (const group of this.groups) {
			arrayRemoveOne(group.members, this);
		}
		this.groups.length = 0;
	}
	protected getVolume() {
		if (this.muted) {
			return 0;
		}
		let volume = this.volume;
		for (const group of this.groups) {
			if (group.muted || group.volume === 0) {
				return 0;
			}
			volume *= group.volume;
		}
		return volume;
	}
}
