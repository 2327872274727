import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsMegaBite } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';

const W = 220;
const info = specialSkillInfos[SpecialSkill.MegaBite];
const castTime0 = info.castTime;
const setupTime = 500;
const biteTime = 300;
const fullTime = castTime0 + biteTime + setupTime;

export class SsMegaBiteSymbol extends Container implements MiscSymbol {

	public area!: Graphics;
	public fangs1!: Container;
	public fangs2!: Container;
	public follower = new Container();
	public fang1Container = new Container();
	public fang2Container = new Container();

	public sound = new LongSoundEfx('megaBite');
	public shaked = false;

	public data: MiscData | null = null;
	// public fang1Container2 = new Container();
	// public fang2Container2 = new Container();
	public tween1 = new ManualTween(this.fang1Container).to({ angle: 75 }, 0)
		.wait(castTime0)
		.to({ angle: 98 }, setupTime)
		.to({ angle: 0 }, biteTime, Ease.sineIn);
	public tween2 = new ManualTween(this.fang2Container).to({ angle: -75 }, 0)
		.wait(castTime0)
		.to({ angle: -98 }, setupTime)
		.to({ angle: 0 }, biteTime, Ease.sineIn);
	public tween3 = new ManualTween(this.fang1Container.scale).to({ x: 0.01, y: 0.01 }, 0)
		.wait(castTime0)
		.call(() => {
			if (this.area.parent) {
				this.area.parent.removeChild(this.area);
			}
			this.follower.visible = true;
		})
		.to({ x: 1, y: 1 }, setupTime, Ease.sineOut);
	public tween4 = new ManualTween(this.fang2Container.scale).to({ x: 0.01, y: 0.01 }, 0)
		.wait(castTime0)
		.to({ x: 1, y: 1 }, setupTime, Ease.sineOut);


	protected ac = new AnimatingContainers();

	public init() {
		this.area = this.children[0] as Graphics;
		// this.fang1Container.addChild(this.fang1Container2);
		// this.fang2Container.addChild(this.fang2Container2);
		this.fang1Container.addChild(this.fangs1);
		this.fang2Container.addChild(this.fangs2);
		this.follower.addChild(this.fang1Container, this.fang2Container);
		this.fangs1.y = this.fangs2.y = -45;

	}
	public dispose(room: RoomControl) {
		this.data = null;
		// console.log('disposeee')
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		Tween.get(this.follower).to({ alpha: 0 }, 500).call(() => {
			if (this.follower.parent) {
				this.follower.parent.removeChild(this.follower);
			}
			Factory.pool(SsMegaBiteSymbol, this);
			this.sound.stop();
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsMegaBite;
		this.area.x = data.x;
		this.area.y = data.y;
		this.area.angle = data.d + 90;
		this.area.scale.set((data.l + 45) / W * data.sc);

		this.follower.x = data.x;
		// console.log(data.n.x, data.n.y);
		this.follower.y = data.y;
		this.follower.angle = data.d + 90;
		this.follower.scale.set(data.sc);

		if (!this.data) {
			const fangScale = data.l / W;
			this.fangs1.scale.set(fangScale);
			this.fangs2.scale.set(fangScale);
			this.follower.visible = false;
			this.tween1.setPosition(0, true);
			this.tween2.setPosition(0, true);
			this.tween3.setPosition(0, true);
			this.tween4.setPosition(0, true);

			this.sound.play({ loop: false, start: 0 });
			this.shaked = false;
		}

		this.follower.alpha = 1;
		const castTime = data.ct;
		const startTime = data.st;
		const position = rt <= startTime + castTime ? (rt - startTime) / castTime * castTime0 : rt - startTime - castTime + castTime0;


		this.tween1.setPosition(position);
		this.tween2.setPosition(position);
		this.tween3.setPosition(position);
		this.tween4.setPosition(position);
		const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		this.sound.volume = volume;

		if (!this.shaked && position >= fullTime) {
			this.shaked = true;
			if (volume > .5) {
				room.cameraControl.swing(8 * (data.l / W) * (volume - .4), 800);
			}
		}
		this.data = data;
	}
	public show(room: RoomControl) {
		// console.log('nani');
		if (this.tween1.position < castTime0) {
			room.wallBottomContainer.addChild(this.area);
		}
		room.playerTopContainer.addChild(this.follower);
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.follower.parent) {
			this.follower.parent.removeChild(this.follower);
		}
		this.sound.volume = 0;
	}

}
