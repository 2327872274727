
















import gamex from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import GameBagComponent from './GameBagComponent.vue';
@Component({
  components: {
    GameBagComponent,
  },
})
export default class UtilityGameBagComponent extends Vue {
  public get items() {
    return gamex.inventory;
  }
  public mounted() {
    this.$el.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
  public beforeDestroy() {
    this.$el.removeEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
}
