import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsMegaBite, MiscDataSsSwimmingFang } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { MiscSymbol } from './miscSymbol';
import { PlayerMode } from '@/game/infos/enums';

// const info = specialSkillInfos[SpecialSkill.SwimmingFang];

export class SsSwimmingFangSymbol extends MovieClip implements MiscSymbol {

	public missileIndicator = getParticle(Particle.MissileIndicator);

	public data: MiscDataSsSwimmingFang | null = null;
	public isSameTeam = false;

	public boom = getParticle(Particle.Boom);
	public sound = new LongSoundEfx('swimmingFang');


	public init() {
		this.gotoAndStop('idle');
		this.missileIndicator.scale.set(0.5);
		this.scale.set(0.5);
	}
	public dispose(room: RoomControl) {
		this.data = null;
		this.isSameTeam = false;
		if (this.missileIndicator.parent) {
			this.missileIndicator.parent.removeChild(this.missileIndicator);
		}
		this.sound.stop();
		this.stop();
		if (this.parent) {
			Tween.get(this).to({ alpha: 0 }, 500).call(() => {
				if (this.parent) {
					this.parent.removeChild(this);
				}
				this.removeChild(this.boom);
				this.gotoAndStop('move');
				Factory.pool(SsSwimmingFangSymbol, this);
			});

			for (const child of this.children) {
				const xx = child.x + Math.random() * 300 - 150;
				const yy = child.y + Math.random() * 300 - 150;
				const angle = child.angle + Math.random() * 90 - 45;
				Tween.get(child).to({ x: xx, y: yy, angle }, 500, Ease.cubicOut);
			}
			this.addChildAt(this.boom, 0);
			this.boom.scale.set(0.1);
			Tween.get(this.boom.scale).to({ x: 1, y: 1 }, 500, Ease.cubicOut);


			if (room.state) {
				const dist = (1300 - Rotate.dist(this.x, this.y, room.state.x, room.state.y)) / 1300;
				const volume = dist * 3;
				const sfx = new SoundEfx('swimmingFangBoom');
				sfx.play({ volume });
				if (dist > .5) {
					room.cameraControl.swing(4 * (dist - .4), 500);
				}
			}
		} else {
			this.gotoAndStop('move');
			Factory.pool(SsSwimmingFangSymbol, this);
		}

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		this.alpha = 1;

		const data = dt as MiscDataSsSwimmingFang;
		this.missileIndicator.x = data.x;
		this.missileIndicator.y = data.y;

		this.x = data.x;
		this.y = data.y;
		this.angle = data.d;

		if (!this.data || data.ac !== this.data.ac) {
			if (data.ac) {
				this.gotoAndPlay('move');
				this.sound.start();
			} else {
				this.gotoAndStop('idle');
			}
		}
		if (!this.data) {
			this.isSameTeam = data.tid === state.tid || state.playerMode === PlayerMode.PvE && data.tid === '_pve';
		} else if (data.tid !== this.data.tid) {
			const isSameTeam = data.tid === state.tid || state.playerMode === PlayerMode.PvE && data.tid === '_pve';
			if (this.isSameTeam !== isSameTeam) {
				if (isSameTeam) {
					this.missileIndicator.parent?.removeChild(this.missileIndicator);
				} else {
					room.playerTopContainer.addChild(this.missileIndicator);
				}
				this.isSameTeam = isSameTeam;
			}
		}
		this.data = data;

		const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		this.sound.volume = volume;
	}
	public show(room: RoomControl) {
		if (!this.isSameTeam) {
			room.playerTopContainer.addChild(this.missileIndicator);
		}
		room.playerBottomContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		this.sound.volume = 0;
		if (this.missileIndicator.parent) {
			this.missileIndicator.parent.removeChild(this.missileIndicator);
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}

}
