import { getParticle, Particle } from '@/client/factory/particles';
import { BroadcastDamageEvent } from '@/game/multithread/state';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Tween } from '@/util/tweents';
import { Vector } from 'matter-js';
import { Ticker } from 'pixi.js';

export class MiningSparkleEffectControl {

	public static get(x: number, y: number) {
		const control = MiningSparkleEffectControl._pool.get();
		control.init(x, y);
		return control;
	}
	private static _pool: Pool<MiningSparkleEffectControl> = new Pool(MiningSparkleEffectControl);

	public tickAdded = false;
	public symbol = getParticle(Particle.MiningSparkle);
	public vector = { x: 0, y: 0 };

	protected startTime = 0;

	public init(x: number, y: number) {
		this.symbol.x = x;
		this.symbol.y = y;

		this.startTime = Date.now();

		this.symbol.scale.x = 0.1;
		this.vector = Vector.rotate({ x: 5, y: 0 }, Math.random() * Math.PI * 2);
		this.symbol.angle = Rotate.degree(0, 0, this.vector.x, this.vector.y);

		if (!this.tickAdded) {
			Ticker.shared.add(this.tick);
			this.tickAdded = true;
		}
	}

	public tick = () => {
		// this.symbol.scale.x *= 5;
		if (Date.now() > this.startTime + 400) {
			this.dispose();
			return;
		}
		this.symbol.scale.x = Math.min(0.3, (Date.now() - this.startTime) / 300 + 0.1);
		this.vector.y += 0.5;
		this.symbol.x += this.vector.x;
		this.symbol.y += this.vector.y;
		// console.log(this.symbol.angle);
		this.symbol.angle = Rotate.degree(0, 0, this.vector.x, this.vector.y);
		// console.log(this.symbol.angle);
		this.symbol.angle++;
	}
	public dispose() {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		if (this.tickAdded) {
			Ticker.shared.remove(this.tick);
			this.tickAdded = false;
		}
		MiningSparkleEffectControl._pool.pool(this);
	}
}
