

























import { SoundEfx } from '@/client/sound/SoundEfx';
import { Global } from '@/store/globalz';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { LimitedShopItemClient } from '@/game/infos/limitedShop';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import { itemInfos } from '@/game/infos/itemInfos';
import userx from '@/store/modules/userx';
import ItemButton from '../mainMenu/tools/inventory/ItemButton.vue';
import { ItemIntelData } from '../mainMenu/bag/ItemIntelTooltip.vue';


@Component({
	components: {
		ItemButton,
	},
})
export default class ShopItemComponent extends Vue {
	@Prop() public itemId!: string;
	@Prop({ default: '' }) public error!: string;
	@Prop() public data!: LimitedShopItemClient;

	public get pair(): ItemCodeAmountPair {
		return { itemCode: this.data.itemCode, amount: this.data.unit };
	}
	public get info() {
		return itemInfos[this.data.itemCode];
	}

	public get rarity() {
		const info = this.info;
		const rarity = Global.rarityLabels[info.rarity];
		const color = Global.rarityColors[info.rarity];
		return `<div class="text-sm font-italic" style="color:${color}">${rarity}</div>`;
	}
	//   public bought = this.spearType === 0 ? true : false;
	//   public equipped = this.spearType === 0 ? true : false;
	public interval: any = null;
	public index = 1;

	public mounted() {
		this.interval = setInterval(() => {
			this.index++;
			if (this.index >= 5) {
				this.index = 1;
			}
		}, 1000);
	}
	public beforeDestroy() {
		clearTimeout(this.interval);
	}

	public get notAvailable() {
		if (this.purchased >= this.data.limit) {
			return true;
		}
		return false;
	}

	public get purchased() {
		return userx.inventory.shop && userx.inventory.shop[this.itemId] || 0;
	}
	public get sufficient() {
		return userx.inventory.availables[this.data.costItem] >= this.cost;
	}

	public onBuy() {
		this.$emit('buy', this.itemId, this.data);
	}

	public get cost() {
		return this.data.cost;
	}
	public showItemIntel() {
		const id = this.itemId;
		const data: ItemIntelData = {
			targetId: id,
			placement: 'top',
			container: 'item-inventory',
			itemCode: this.data.itemCode,
		};
		this.$root.$emit('showItemIntel', data);
	}
	public hideItemIntel() {
		this.$root.$emit('hideItemIntel');
	}
}
