














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import { wait } from '@/util/wait';

@Component({
  components: {},
})
export default class ScrollContainer extends Vue {
  @Prop({ default: false }) public behaveAsChatBox!: boolean;

  public width = this.$el ? this.$el.clientWidth : 0;
  public height = this.$el ? this.$el.clientHeight : 0;
  public contentHeight = this.$refs.container
    ? (this.$refs.container as HTMLElement).clientHeight
    : 0;

  public scrollTop = 0;
  public lockToBottom = true;

  public get hasTop() {
    return this.contentHeight > this.height && this.scrollTop > 0;
  }
  public get hasBottom() {
    return (
      this.contentHeight > this.height + 3 &&
      this.contentHeight - this.scrollTop > this.height + 3
    );
  }

  @Watch('hasBottom')
  public onHasBottomChange(newVal: boolean) {
    if (this.behaveAsChatBox) {
      if (newVal) {
        if (this.lockToBottom) {
          const scroll = (this.$refs.scroll as any) as HTMLElement;
          scroll.scrollTop = this.contentHeight;
        }
      } else {
        this.lockToBottom = true;
      }
    }
  }

  public onResize() {
    this.width = this.$el.clientWidth;
    this.height = this.$el.clientHeight;
  }

  public async onContentResize() {
    const container = this.$refs.container as HTMLElement;
    this.contentHeight = container.clientHeight;
  }
  public onScroll() {
    const scroll = (this.$refs.scroll as any) as HTMLElement;
    if (
      this.scrollTop > scroll.scrollTop &&
      this.contentHeight > this.height &&
      this.contentHeight - scroll.scrollTop > this.height
    ) {
      this.lockToBottom = false;
    }
    this.scrollTop = scroll.scrollTop;
  }
  public mounted() {
    this.$root.$on('scrollContainerCheck', this.addResizeSensor);
    const scroll = (this.$refs.scroll as any) as HTMLElement;
    scroll.addEventListener('scroll', this.onScroll);
    this.addResizeSensor();
  }

  public async addResizeSensor() {
    await wait(200);
    if (this.$el.clientWidth) {
      if (!(this.$el as any).resizeSensor) {
        const res = new ResizeSensor(this.$el, this.onResize);
      }
      const container = this.$refs.container as HTMLElement;
      if (!(container as any).resizeSensor) {
        const res = new ResizeSensor(container, this.onContentResize);
      }
    }
  }

  public beforeDestroy() {
    if ((this.$el as any).resizeSensor) {
      ResizeSensor.detach(this.$el);
      const container = this.$refs.container as HTMLElement;
      ResizeSensor.detach(container);
    }
    const scroll = (this.$refs.scroll as any) as HTMLElement;
    scroll.removeEventListener('scroll', this.onScroll);
    this.$root.$off('scrollContainerCheck', this.addResizeSensor);
  }
}
