
export enum ProcessState {
	Undefined,
	Command,
	Preprocess,
	Attempt,
	Await,
	Succeed,
	Failed,
}
export type LoginStatus = 'loading' | 'logged in' | 'logged out';
export type EditState = 'active' | 'waiting' | 'sending' | 'done';
export interface LoginSubmission {
	email: string,
	password: string,
}
export interface SignInAndResetPasswordSubmission {
	oldPassword: string,
	newPassword: string,
}
// export interface TypicalResponse {
// 	success: boolean,
// 	errorCode?: string,
// 	errorMessage?: string,
// 	error?: any,
// }
export interface Signature {
	signedAt: number,
	signedVersion: number,
}
export interface StateKeyPairs {
	key: string,
	state: ProcessState;
}
export interface ProfileSubmission {
	displayName?: string,
	photoURL?: string,
}
