import { nonReact } from '@/util/nonReact';
import { MovieClip } from 'pixi-animate';
import { BaseTexture, Container, DisplayObject, Graphics, Sprite, Text, Texture } from 'pixi.js';
import Factory from '.';

export function stopAtTheEnd(mc: MovieClip, callBack?: () => void) {
    mc.addAction(() => {
        mc.stop();
        if (callBack) {
            callBack();
        }
    }, mc.totalFrames - 1);
}

export function getAllTimedChildren(mc: MovieClip) {
    const obj = mc as any;
    return obj._timedChildTimelines.map((t: any) => t.target) as DisplayObject[];
}

export function setShrinkableText(text: Text, str: string, maxWidth: number) {
    text.text = str;
    if (maxWidth <= 0) {
        return;
    }
    text.width = maxWidth;
    const ow = text.texture.width;
    if (ow < maxWidth) { text.width = ow; }
}


export function setupButton(button: DisplayObject) {
    const btn = button as any;
    button.interactive = true;
    button.buttonMode = true;
    if (!(button instanceof MovieClip)) { return; }
    btn._mouseOver = btn._mouseDown = false;
    const update = () => {
        const index = btn._mouseDown ? 2 : btn._mouseOver ? 1 : 0;
        button.gotoAndStop(index);
    };
    const onHover = () => {
        btn._mouseOver = true;
        update();
    };
    const onDown = () => {
        btn._mouseDown = true;
        update();
    };
    const onOut = () => {
        btn._mouseOver = false;
        update();
    };
    const onUp = () => {
        btn._mouseDown = false;
        update();
    };
    btn.on('mousedown', onDown)
        .on('touchstart', onDown)

        .on('mouseup', onUp)
        .on('touchend', onUp)
        .on('mouseupoutside', onUp)
        .on('touchendoutside', onUp)

        .on('mouseover', onHover)

        .on('mouseout', onOut);
}

export function onClick(button: DisplayObject, callback: (e: any) => void) {

    button.interactive = true;
    button.buttonMode = true;
    const btn = button as any;
    if (btn._previousCallback) {
        button.removeListener('click', btn._previousCallback);
        button.removeListener('tap', btn._previousCallback);
    }
    btn._previousCallback = callback;

    button.on('click', callback);
    button.on('tap', callback);
}

export function allTextureLoaded(container: Container) {
    return new Promise((resolve: (v?: unknown) => void) => {

        const textures: BaseTexture[] = [];
        function collectTexture(con: Container) {
            for (const child of con.children) {
                if (child instanceof Sprite) {
                    if (!child.texture.baseTexture.valid) {
                        textures.push(child.texture.baseTexture);
                    }
                } else if (child instanceof Container) {
                    collectTexture(child);
                }
            }
        }
        collectTexture(container);
        let numTextures = textures.length;
        if (numTextures === 0) {
            resolve();
        } else {

            const loaded = () => {
                numTextures--;
                if (numTextures === 0) {
                    resolve();
                }
            };
            for (const texture of textures) {
                texture.on('loaded', loaded);
            }
        }
    });
}

export function preloadTextures(urls: Set<string>) {
    return new Promise((resolve: (v?: unknown) => void) => {

        const textures: BaseTexture[] = [];
        urls.forEach((url) => {
            const texture = Texture.from(url);
            if (!texture.baseTexture.valid) {
                textures.push(texture.baseTexture);
            }
        });
        let numTextures = textures.length;
        if (numTextures === 0) {
            resolve();
        } else {

            const loaded = () => {
                numTextures--;
                if (numTextures === 0) {
                    resolve();
                }
            };
            for (const texture of textures) {
                texture.on('loaded', loaded);
            }
        }
    });
}

export function tint(target: DisplayObject, color: number) {
    if (target instanceof Sprite || target instanceof Graphics) {
        target.tint = color;
    } else if (target instanceof MovieClip) {
        const children = target.totalFrames > 1 ? getAllTimedChildren(target) : target.children;
        for (const child of children) {
            tint(child, color);
        }
    } else if (target instanceof Container) {
        const children = target.children;
        for (const child of children) {
            tint(child, color);
        }
    }
}
export function blend(target: DisplayObject, blendMode: number) {
    if (target instanceof Sprite || target instanceof Graphics) {
        target.blendMode = blendMode;
    } else if (target instanceof MovieClip) {
        const children = target.totalFrames > 1 ? getAllTimedChildren(target) : target.children;
        for (const child of children) {
            blend(child, blendMode);
        }
    } else if (target instanceof Container) {
        const children = target.children;
        for (const child of children) {
            blend(child, blendMode);
        }
    }
}


export function rgbToInteger(red: number, green: number, blue: number) {
    return (red << 16) + (green << 8) + (blue);
}


export function cloneSprite(target: Sprite) {
    const texture = target.texture;
    const newSprite = Sprite.from(texture);
    const bounds = target.getLocalBounds();
    newSprite.setTransform(bounds.x, bounds.y);
    return newSprite;
}


export class TintContainer extends Container {

    public get brightness() {
        return this._brightness;
    }
    public set brightness(value: number) {
        if (this._brightness === value) { return; }
        this._brightness = value;
        this.tintAndBrightness();
    }
    private _tint = 0xffffff;
    private _brightness = 1;
    public setTint(value: number) {
        if (this._tint === value) { return; }
        this._tint = value;
        this.tintAndBrightness();
    }
    protected tintAndBrightness() {
        const red = this._tint >> 16;
        const green = this._tint - (red << 16) >> 8;
        const blue = this._tint - (red << 16) - (green << 8);
        const value = rgbToInteger(red * this.brightness, green * this.brightness, blue * this.brightness);
        for (const child of this.children) {
            tint(child, value);
        }
    }

}

export class FactoryPool<T> {
    private _pool: T[] = [];
    private _theClass!: new () => T;

    constructor(theClass: any) {
        this._theClass = theClass;
    }

    public get(): T {
        const result = this._pool.shift() || nonReact(Factory.get(this._theClass));
        return result;
    }

    public pool(obj: T) {
        if (this._pool.indexOf(obj) === -1) {
            this._pool.push(obj);
        }
    }
}

