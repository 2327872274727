












import { Vue, Component, Prop } from 'vue-property-decorator';
import { EditState } from '@/store/models.def';

@Component({})
export default class StateButton extends Vue {
	@Prop({ default: 'active' }) public state!: EditState;
	@Prop({ default: true }) public showSpinner!: boolean;
	@Prop({ default: false }) public disabled!: boolean;
	@Prop({ default: 'md' }) public readonly size!: string;
	@Prop({ }) public readonly variant!: string;
}
