import { RoomControl } from '@/client/controls/roomControl';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsBandage, MiscDataSsRation } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import { disposeParticle, getParticle, Particle } from '../../particles';
import { getSpecialSprite, SpecialSkillAsset } from '../../skillAssets';
import { MiscSymbol } from './miscSymbol';

export class SsRationSymbol extends Container implements MiscSymbol {
	public static get() {
		return SsRationSymbol._pool.get();
	}

	private static _pool: Pool<SsRationSymbol> = new Pool(SsRationSymbol);

	public data: MiscData | null = null;

	public icon = getSpecialSprite(SpecialSkillAsset.Ration);
	public circle = new Graphics();
	public bg = new Graphics();

	public pid = NaN;
	public lvl = 1;

	// public info = specialSkillInfos[SpecialSkill.Ration];

	constructor() {
		super();
		this.addChild(
			this.bg,
			this.icon,
			this.circle,
		);
		this.bg.beginFill(0x0050FA, 0.8);
		this.bg.drawCircle(0, 0, 20);
	}

	public dispose(room: RoomControl) {
		this.data = null;
		if (this.parent) {
			this.parent.removeChild(this);
		}
		SsRationSymbol._pool.pool(this);
		const player = room.allPlayers[this.pid];
		if (player) {
			for (let i = 0; i < 5 * this.lvl; i++) {
				player.healParticle(0xffff00);
			}
		}
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsRation;
		this.pid = data.pid;
		this.lvl = data.lvl;
		// if (!this.data || data.p.lvl !== this.data.p.lvl) {
		// 	this.info = data.p.lvl === 1 ? specialSkillInfos[SpecialSkill.Ration]
		// 		: specialSkillInfos[SpecialSkill.Ration2];
		// }
		const percent = (rt - data.st) / data.ct;
		this.circle.clear();
		this.circle.lineStyle(4, 0xffff00);
		this.circle.arc(0, 0, 20, -Math.PI / 2, Math.PI * 2 * percent - Math.PI / 2);

		this.data = data;
		this.x = dt.x + 45;
		this.y = dt.y + 45;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		room.playerTopContainer.removeChild(this);

	}
}
