export enum TipId {
	None,
	Hp,
	Crush,
	LeftClick,
	RightClick,
	Chest,
	Armory,
	ChangeWeaponOld,
	SafeZone,
	Hazard,
	ArrangeDeco,
	ChooseFish,
	ChangeWeapon,
	ChangeWeaponTouch,
}

export interface TipProgress {
	id: TipId,
	read: boolean,
	autoClose: number,
}

export interface TipData {
	id: TipId,
	img: string,
	desc: string,
	priority: number,
}

export const tipInfos: TipData[] = [];

let tipId: TipId = TipId.ArrangeDeco;
let priority = 0;
tipInfos[tipId] = {
	id: tipId,
	img: 'arrangeDeco',
	desc: `You can move the decoration to the <span class="text-yellow">back</span> of your fish by <span class="text-yellow">dragging</span> and rearrange the decoration bars.`,
	priority,
};
tipId = TipId.Chest;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'chest',
	desc: `You found a treasure chest! <span class="fwby">$tap</span> it to open the chest!`,
	priority,
};
tipId = TipId.SafeZone;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'safeZone',
	desc: `The green zone is the <span class="text-yellow">safe zone</span>. You can't hurt or be hurt in the area.`,
	priority,
};
tipId = TipId.Armory;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'theo',
	desc: `<span class="fwby">$tap</span> the <span class="text-yellow">polar bear</span> to buy new weapons.`,
	priority,
};
tipId = TipId.ChangeWeapon;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'changeWeapon2',
	desc: `<span class="fwby">$wpn</span> to switch weapons.`,
	priority,
};
tipId = TipId.ChangeWeaponTouch;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'changeWeapon2',
	desc: `<span class="fwby">Swipe Left or Right</span> to switch weapons.`,
	priority,
};

tipId = TipId.LeftClick;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'leftClick',
	desc: `<span class="fwby">$leftClick</span> and hold to boost, it consumes stamina (<span class="text-yellow">yellow bar</span>).`,
	priority,
};
tipId = TipId.RightClick;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'rightClick',
	desc: `<span class="fwby">$rightClick</span> to replenish stamina (<span class="text-yellow">yellow bar</span>). You can change the action keys in the settings.`,
	priority,
};
tipId = TipId.Hp;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'hp',
	desc: `The numbers above each fish are their <span class="fwby">HP</span>. Reduce them to 0 in order to impale the fish.`,
	priority,
};
tipId = TipId.Crush;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'crush',
	desc: `<span class="fwby">Crush</span> the impaled fish into the wall to end their life.`,
	priority,
};
tipId = TipId.Hazard;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'fire',
	desc: `By touching the <span class="text-yellow">fire</span> with your <span class="text-yellow">impaled victims</span>, you can set them on fire. It works on other hazards as well.`,
	priority,
};
tipId = TipId.ChooseFish;
priority++;
tipInfos[tipId] = {
	id: tipId,
	img: 'choose',
	desc: `Press the <span class="text-yellow">Fish Pictures</span> to switch to other fish. You can choose lower tier fish for 2nd or 3rd life.`,
	priority,
};

export function getTipProgress(id: TipId) {

}
