import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsDisguise } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import { disposeParticle, getParticle, Particle } from '../../particles';
import { getSpecialSprite, SpecialSkillAsset } from '../../skillAssets';
import { MiscSymbol } from './miscSymbol';

const info = specialSkillInfos[SpecialSkill.Disguise];
const duration = info.duration;

export class SsDisguiseSymbol extends Container implements MiscSymbol {
	public static get() {
		return SsDisguiseSymbol._pool.get();
	}

	private static _pool: Pool<SsDisguiseSymbol> = new Pool(SsDisguiseSymbol);

	public data: MiscData | null = null;

	public icon = getSpecialSprite(SpecialSkillAsset.Disguise);
	public circle = new Graphics();
	public bg = new Graphics();


	constructor() {
		super();
		this.addChild(
			this.bg,
			this.icon,
			this.circle,
		);
		this.bg.beginFill(0x0050FA, 0.8);
		this.bg.drawCircle(0, 0, 20);
	}

	public dispose(room: RoomControl) {
		this.data = null;
		if (this.parent) {
			this.parent.removeChild(this);
		}
		SsDisguiseSymbol._pool.pool(this);
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsDisguise;

		const percent = (rt - data.st) / (duration * data.bst);
		this.circle.clear();
		this.circle.lineStyle(4, 0xffff00);
		this.circle.arc(0, 0, 20, -Math.PI / 2, Math.PI * 2 * percent - Math.PI / 2);

		this.data = data;
		this.x = dt.x + 45;
		this.y = dt.y + 45;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		room.playerTopContainer.removeChild(this);

	}
}
