export class AbstractTween {

	public get paused() {
		return this._paused;
	}
	public set paused(value: boolean) {
		this._paused = value;
	}
	public get currentLabel() {
		const labels = this.getLabels();
		const pos = this.position;
		for (let i = 0, l = labels.length; i < l; i++) {
			if (pos < labels[i].position) {
				return (i === 0) ? null : labels[i - 1].label;
			}
		}
	}


	// public properties:
	/**
	 * Causes this tween to continue playing when a global pause is active. For example, if TweenJS is using {{#crossLink "Ticker"}}{{/crossLink}},
	 * then setting this to false (the default) will cause this tween to be paused when `Ticker.paused` is set to
	 * `true`. See the {{#crossLink "Tween/tick"}}{{/crossLink}} method for more info. Can be set via the `props`
	 * parameter.
	 * @property ignoreGlobalPause
	 * @type Boolean
	 * @default false
	 */
	public ignoreGlobalPause = false;

	/**
	 * Indicates the number of times to loop. If set to -1, the tween will loop continuously.
	 *
	 * Note that a tween must loop at _least_ once to see it play in both directions when `{{#crossLink "AbstractTween/bounce:property"}}{{/crossLink}}`
	 * is set to `true`.
	 * @property loop
	 * @type {Number}
	 * @default 0
	 */
	public loop = 0;

	/**
	 * Uses ticks for all durations instead of milliseconds. This also changes the behaviour of some actions (such as `call`).
	 * Changing this value on a running tween could have unexpected results.
	 * @property useTicks
	 * @type {Boolean}
	 * @default false
	 * @readonly
	 */
	public useTicks = false;

	/**
	 * Causes the tween to play in reverse.
	 * @property reversed
	 * @type {Boolean}
	 * @default false
	 */
	public reversed = false;

	/**
	 * Causes the tween to reverse direction at the end of each loop. Each single-direction play-through of the
	 * tween counts as a single bounce. For example, to play a tween once forward, and once back, set the
	 * `{{#crossLink "AbstractTween/loop:property"}}{{/crossLink}}` to `1`.
	 * @property bounce
	 * @type {Boolean}
	 * @default false
	 */
	public bounce = false;

	/**
	 * Changes the rate at which the tween advances. For example, a `timeScale` value of `2` will double the
	 * playback speed, a value of `0.5` would halve it.
	 * @property timeScale
	 * @type {Number}
	 * @default 1
	 */
	public timeScale = 1;

	/**
	 * Indicates the duration of this tween in milliseconds (or ticks if `useTicks` is true), irrespective of `loops`.
	 * This value is automatically updated as you modify the tween. Changing it directly could result in unexpected
	 * behaviour.
	 * @property duration
	 * @type {Number}
	 * @default 0
	 * @readonly
	 */
	public duration = 0;

	/**
	 * The current normalized position of the tween. This will always be a value between 0 and `duration`.
	 * Changing this property directly will have unexpected results, use {{#crossLink "Tween/setPosition"}}{{/crossLink}}.
	 * @property position
	 * @type {Object}
	 * @default 0
	 * @readonly
	 */
	public position = 0;

	/**
	 * The raw tween position. This value will be between `0` and `loops * duration` while the tween is active, or -1 before it activates.
	 * @property rawPosition
	 * @type {Number}
	 * @default -1
	 * @readonly
	 */
	public rawPosition = -1;


	// private properties:
	/**
	 * @property _paused
	 * @type {Boolean}
	 * @default false
	 * @protected
	 */
	protected _paused = true;

	/**
	 * @property _next
	 * @type {Tween}
	 * @default null
	 * @protected
	 */
	protected _next: any | null = null;

	/**
	 * @property _prev
	 * @type {Tween}
	 * @default null
	 * @protected
	 */
	protected _prev: any | null = null;

	/**
	 * @property _parent
	 * @type {Object}
	 * @default null
	 * @protected
	 */
	protected _parent: any = null;

	/**
	 * @property _labels
	 * @type Object
	 * @protected
	 */
	protected _labels: any = null;

	/**
	 * @property _labelList
	 * @type Array[Object]
	 * @protected
	 */
	protected _labelList: any[] | null = null;

	/**
	 * Status in tick list:
	 * 0 = in list
	 * 1 = added to list in the current tick stack
	 * -1 = remvoed from list (or to be removed in this tick stack)
	 * @property _status
	 * @type Number
	 * @default -1
	 * @protected
	 */
	protected _status = -1;

	/**
	 * Tick id compared to Tween._inTick when removing tweens from the tick list in a tick stack.
	 * @property _lastTick
	 * @type Number
	 * @default 0
	 * @protected
	 */
	protected _lastTick = 0;

	/**
	 * @property _actionHead
	 * @type {TweenAction}
	 * @protected
	 */
	protected _actionHead: any | null = null;

	protected tweens: any[] | null = null;

	constructor(props: any) {
		if (props) {
			this.useTicks = !!props.useTicks;
			this.ignoreGlobalPause = !!props.ignoreGlobalPause;
			this.loop = props.loop === true ? -1 : (props.loop || 0);
			this.reversed = !!props.reversed;
			this.bounce = !!props.bounce;
			this.timeScale = props.timeScale || 1;
		}
	}


	// public methods:
	/**
	 * Advances the tween by a specified amount.
	 * @method advance
	 * @param {Number} delta The amount to advance in milliseconds (or ticks if useTicks is true). Negative values are supported.
	 * @param {Number} [ignoreActions=false] If true, actions will not be executed due to this change in position.
	 */
	public advance(delta: number, ignoreActions = false) {
		this.setPosition(this.rawPosition + delta * this.timeScale, ignoreActions);
	}
	/**
	 * Advances the tween to a specified position.
	 * @method setPosition
	 * @param {Number} rawPosition The raw position to seek to in milliseconds (or ticks if useTicks is true).
	 * @param {Boolean} [ignoreActions=false] If true, do not run any actions that would be triggered by this operation.
	 * @param {Boolean} [jump=false] If true, only actions at the new position will be run. If false, actions between the old and new position are run.
	 * @param {Function} [callback] Primarily for use with MovieClip, this callback is called after properties are updated, but before actions are run.
	 */
	public setPosition(rawPosition: number, ignoreActions = false, jump = false, callback?: (thisArg: any) => void) {
		const d = this.duration, loopCount = this.loop;
		const prevRawPos = this.rawPosition;
		let loop = 0, t = 0, end = false;

		// normalize position:
		if (rawPosition < 0) { rawPosition = 0; }

		if (d === 0) {
			// deal with 0 length tweens.
			end = true;
			if (prevRawPos !== -1) { return end; } // we can avoid doing anything else if we're already at 0.
		} else {
			loop = rawPosition / d | 0;
			t = rawPosition - loop * d;

			end = (loopCount !== -1 && rawPosition >= loopCount * d + d);
			if (end) { rawPosition = (t = d) * (loop = loopCount) + d; }
			if (rawPosition === prevRawPos) { return end; } // no need to update

			const rev = !this.reversed !== !(this.bounce && loop % 2); // current loop is reversed
			if (rev) { t = d - t; }

		}

		// set this in advance in case an action modifies position:
		this.position = t;
		this.rawPosition = rawPosition;

		this._updatePosition(jump, end);
		if (end) { this.paused = true; }

		if (callback) { callback(this); }

		if (!ignoreActions) { this._runActions(prevRawPos, rawPosition, jump, !jump && prevRawPos === -1); }

		// this.dispatchEvent("change");
		// if (end) { this.dispatchEvent("complete"); }

		return false;
	}
	/**
	 * Calculates a normalized position based on a raw position. For example, given a tween with a duration of 3000ms set to loop:
	 * 	console.log(myTween.calculatePosition(3700); // 700
	 * @method calculatePosition
	 * @param {Number} rawPosition A raw position.
	 */
	public calculatePosition(rawPosition: number) {
		// largely duplicated from setPosition, but necessary to avoid having to instantiate generic objects to pass values (end, loop, position) back.
		const d = this.duration;
		const loopCount = this.loop;
		let loop = 0, t = 0;

		if (d === 0) { return 0; }
		if (loopCount !== -1 && rawPosition >= loopCount * d + d) { t = d; loop = loopCount; } else if (rawPosition < 0) { t = 0; } else { loop = rawPosition / d | 0; t = rawPosition - loop * d; }

		const rev = !this.reversed !== !(this.bounce && loop % 2); // current loop is reversed
		return rev ? d - t : t;
	}
	/**
	 * Returns a list of the labels defined on this tween sorted by position.
	 * @method getLabels
	 * @return {Array[Object]} A sorted array of objects with label and position properties.
	 */
	public getLabels() {
		let list = this._labelList;
		if (!list) {
			list = this._labelList = [];
			const labels = this._labels || [];
			for (const n in labels) {
				if (Object.prototype.hasOwnProperty.call(labels, n)) {
					list.push({ label: n, position: labels[n] });
				}
			}
			list.sort(function(a, b) { return a.position - b.position; });
		}
		return list;
	}

	/**
	 * Defines labels for use with gotoAndPlay/Stop. Overwrites any previously set labels.
	 * @method setLabels
	 * @param {Object} labels An object defining labels for using {{#crossLink "Timeline/gotoAndPlay"}}{{/crossLink}}/{{#crossLink "Timeline/gotoAndStop"}}{{/crossLink}}
	 * in the form `{myLabelName:time}` where time is in milliseconds (or ticks if `useTicks` is `true`).
	 */
	public setLabels(labels: any) {
		this._labels = labels;
		this._labelList = null;
	}
	/**
	 * Adds a label that can be used with {{#crossLink "Timeline/gotoAndPlay"}}{{/crossLink}}/{{#crossLink "Timeline/gotoAndStop"}}{{/crossLink}}.
	 * @method addLabel
	 * @param {String} label The label name.
	 * @param {Number} position The position this label represents.
	 */
	public addLabel(label: string, position: number) {
		if (!this._labels) { this._labels = {}; }
		this._labels[label] = position;
		const list = this._labelList;
		if (list) {
			for (let i = 0, l = list.length; i < l; i++) {
				if (position < list[i].position) {
					list.splice(i, 0, { label, position });
					break;
				}
			}
		}
	}
	/**
	 * Unpauses this timeline and jumps to the specified position or label.
	 * @method gotoAndPlay
	 * @param {String|Number} positionOrLabel The position in milliseconds (or ticks if `useTicks` is `true`)
	 * or label to jump to.
	 */
	public gotoAndPlay(positionOrLabel: string | number) {
		this.paused = false;
		this._goto(positionOrLabel);
	}
	/**
	 * Pauses this timeline and jumps to the specified position or label.
	 * @method gotoAndStop
	 * @param {String|Number} positionOrLabel The position in milliseconds (or ticks if `useTicks` is `true`) or label
	 * to jump to.
	 */
	public gotoAndStop(positionOrLabel: string | number) {
		this.paused = true;
		this._goto(positionOrLabel);
	}
	/**
	 * If a numeric position is passed, it is returned unchanged. If a string is passed, the position of the
	 * corresponding frame label will be returned, or `null` if a matching label is not defined.
	 * @method resolve
	 * @param {String|Number} positionOrLabel A numeric position value or label string.
	 */
	public resolve(positionOrLabel: string | number) {
		let pos = Number(positionOrLabel);
		if (isNaN(pos)) { pos = this._labels && this._labels[positionOrLabel]; }
		return pos;
	}

	/**
	 * Returns a string representation of this object.
	 * @method toString
	 * @return {String} a string representation of the instance.
	 */
	public toString() {
		return '[AbstractTween]';
	}
	/**
	 * @method clone
	 * @protected
	 */
	public clone() {
		throw new Error(('AbstractTween can not be cloned.'));
	}

	// private methods:
	/**
	 * Shared logic that executes at the end of the subclass constructor.
	 * @method _init
	 * @protected
	 */
	protected _init(props: any) {
		if (!props || !props.paused) { this.paused = false; }
		if (props && (props.position != null)) { this.setPosition(props.position); }
	}
	/**
	 * @method _updatePosition
	 * @protected
	 */
	protected _updatePosition(jump: boolean, end: boolean) {
		// abstract.
	}
	/**
	 * @method _goto
	 * @protected
	 */
	protected _goto(positionOrLabel: string | number) {
		const pos = this.resolve(positionOrLabel);
		if (pos != null) { this.setPosition(pos, false, true); }
	}
	/**
	 * @method _runActions
	 * @protected
	 */
	protected _runActions(startRawPos: number, endRawPos: number, jump: boolean, includeStart: boolean) {
		// runs actions between startPos & endPos. Separated to support action deferral.

		// console.log(this.passive === false ? " > Tween" : "Timeline", "run", startRawPos, endRawPos, jump, includeStart);

		// if we don't have any actions, and we're not a Timeline, then return:
		// TODO: a cleaner way to handle this would be to override this method in Tween, but I'm not sure it's worth the overhead.
		// if (!this._actionHead && !this.tweens) { return; }
		if (!this._actionHead && !this.tweens) { return false; }

		const d = this.duration;
		let reversed = this.reversed, bounce = this.bounce;
		const loopCount = this.loop;
		let loop0, loop1, t0, t1;

		if (d === 0) {
			// deal with 0 length tweens:
			loop0 = loop1 = t0 = t1 = 0;
			reversed = bounce = false;
		} else {
			loop0 = startRawPos / d | 0;
			loop1 = endRawPos / d | 0;
			t0 = startRawPos - loop0 * d;
			t1 = endRawPos - loop1 * d;
		}

		// catch positions that are past the end:
		if (loopCount !== -1) {
			if (loop1 > loopCount) { t1 = d; loop1 = loopCount; }
			if (loop0 > loopCount) { t0 = d; loop0 = loopCount; }
		}

		// special cases:
		if (jump) { return this._runActionsRange(t1, t1, jump, includeStart); } else if (loop0 === loop1 && t0 === t1 && !jump && !includeStart) { return false; } else if (loop0 === -1) { loop0 = t0 = 0; } // correct the -1 value for first advance, important with useTicks.

		const dir = (startRawPos <= endRawPos);
		let loop = loop0;
		do {
			const rev = !reversed !== !(bounce && loop % 2);

			let start = (loop === loop0) ? t0 : dir ? 0 : d;
			let end = (loop === loop1) ? t1 : dir ? d : 0;

			if (rev) {
				start = d - start;
				end = d - end;
			}

			if (bounce && loop !== loop0 && start === end) { /* bounced onto the same time/frame, don't re-execute end actions */ } else if (this._runActionsRange(start, end, jump, includeStart || (loop !== loop0 && !bounce))) { return true; }

			includeStart = false;
		} while ((dir && ++loop <= loop1) || (!dir && --loop >= loop1));
		return false;
	}
	protected _runActionsRange(startPos: number, endPos: number, jump: boolean, includeStart: boolean): boolean {
		// abstract
		return false;
	}
}
