





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { itemInfos, ItemCode } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { decoDic } from '@/game/infos/decorativeInfos';
import { blueprintInfos } from '@/game/infos/blueprintInfos';
import DecoItemCanvasComponent from '../canvas/DecoItemCanvasComponent.vue';
import userx from '@/store/modules/userx';

@Component({
  components: {
    DecoItemCanvasComponent,
  },
})
export default class ProductShowcase extends Vue {
  @Prop({ default: 0 }) public itemCode!: ItemCode;

  public get itemInfo() {
    return itemInfos[this.itemCode];
  }
  public get rarityColor() {
    return Global.rarityColors[this.itemInfo.rarity];
  }
  public get rarity() {
    return Global.rarityLabels[this.itemInfo.rarity];
  }
  public get decoName() {
    return this.itemInfo.type !== 'blueprint'
      ? decoDic[this.itemCode] || ''
      : decoDic[blueprintInfos[this.itemCode].result] || '';
  }
  public get boxStyle() {
    const color = Global.rarityRgbs[this.itemInfo.rarity];
    return {
      width: '110px',
      height: '110px',
      border: `1px solid rgba(${color}, ${this.itemInfo.rarity * 0.15 + 0.2})`,
      background: `rgba(${color}, ${this.itemInfo.rarity * 0.05})`,
    };
  }
  public get own() {
    return (
      (userx.inventory.availables[this.itemCode] || 0) +
      (userx.inventory.equippedDecos[this.itemCode] || 0)
    );
  }
}
