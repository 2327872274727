







































































import globalx from '@/store/modules/globalx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class MaintenancePage extends Vue {
  public baseUrl = process.env.BASE_URL;
  public get startTime() {
    return globalx.maintenanceInfo?.start || 0;
  }
  public get endTime() {
    return globalx.maintenanceInfo?.end || 0;
  }
  public get startDate() {
    return new Date(this.startTime);
  }
  public get endDate() {
    return new Date(this.endTime);
  }

  public get showMaintenance() {
    return globalx.maintenanceInfo?.ongoing || false;
  }

  public onRefresh() {
    window.location.reload();
  }
}
