import { OreColor } from '@/game/infos/oreInfo';
import { FoodData, OreData } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Sprite } from 'pixi.js';
import { getWallSprite, MapAsset } from '../factory/mapAssets';
import { getParticle, Particle } from '../factory/particles';


export class OreControl {
	public static get(data: OreData) {
		const control = OreControl._pools[data.color].get();
		control.setData(data);
		return control;
	}
	private static _pools: Array<Pool<OreControl>> = Array.from({ length: 6 }, (_) => new Pool(OreControl));
	public symbol!: Sprite;

	public oreId = NaN;
	public data!: OreData;
	protected _color: OreColor = 0;

	public setData(data: OreData) {

		this.oreId = data.id;
		if (!this.symbol) {
			this.symbol = getWallSprite(MapAsset.RedOre + data.color);
			this._color = data.color;
		}
		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.data = data;
	}

	public dispose() {
		OreControl._pools[this._color].pool(this);
	}

}
