import { EmojiType } from '@/game/infos/emojiInfos';
import { Pool } from '@/util/pool';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container } from 'pixi.js';
import Factory from '../factory';
import { EmojiBubble } from '../factory/assets/controls/emojiBubbleMixin';
import { disposeEmojiSymbol, getEmojiSymbol } from '../factory/assets/emojiAssets';
import { SkillIcon, SkillIconData } from '../factory/assets/skillIcon';
import { RoomControl } from './roomControl';

export class EmojiBubbleContainer extends Container {
	public static get() {
		return EmojiBubbleContainer._pool.get();
	}

	protected static _pool: Pool<EmojiBubbleContainer> = new Pool(EmojiBubbleContainer);

	public bubble = Factory.get(EmojiBubble);

	public hideTime = 0;

	public playerId = NaN;

	public roomControl: RoomControl | null = null;

	protected isShown = false;

	protected lastContentType: 'buff' | 'emoji' | '' = '';

	protected content: Container | null = null;

	constructor() {
		super();
		this.addChild(this.bubble);
		this.bubble.scale.set(0.05);
	}

	public showBuff(time: number, iconData: SkillIconData) {
		this.clear();
		this.show(time);
		this.content = SkillIcon.get(iconData);
		this.content.x = 0;
		this.content.y = 0;
		this.content.scale.set(0.8);
		this.bubble.container.addChild(this.content);
		this.bubble.bubble.tint = 0;
		this.lastContentType = 'buff';
	}

	public showEmoji(time: number, emojiType: EmojiType) {
		this.clear();
		this.show(time);
		this.content = getEmojiSymbol(emojiType);
		this.content.x = 0;
		this.content.y = 0;
		if (this.content instanceof MovieClip) {
			this.content.gotoAndPlay(0);
		}
		this.bubble.container.addChild(this.content);
		this.bubble.bubble.tint = 0xffffff;
		this.lastContentType = 'emoji';
	}

	public next(time: number) {
		if (time >= this.hideTime) {
			if (this.isShown) {
				Tween.get(this.bubble.scale).to({ x: 0.05, y: 0.05 }, 100, Ease.circIn);
				this.isShown = false;
			} else if (time >= this.hideTime + 100) {
				this.dispose();
			}
		}
	}


	public dispose() {
		if (this.parent) {
			this.parent.removeChild(this);
			if (this.roomControl) {
				delete this.roomControl.emojiBubbles[this.playerId];
			}
			this.roomControl = null;
		}
		this.clear();
		this.isShown = false;
		EmojiBubbleContainer._pool.pool(this);
	}

	protected clear() {
		if (this.content) {

			this.bubble.container.removeChildren();
			if (this.lastContentType === 'emoji') {
				disposeEmojiSymbol(this.content);
			} else if (this.lastContentType === 'buff') {
				SkillIcon.pool(this.content as SkillIcon);
			}
		}
		this.content = null;
		this.lastContentType = '';
	}

	protected show(time: number) {
		this.hideTime = time + 4000;
		if (!this.isShown) {
			Tween.get(this.bubble.scale).to({ x: 1, y: 1 }, 500, Ease.elasticOut);
			this.isShown = true;
		}
	}
}

