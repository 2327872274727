import { Global } from '@/store/globalz';

declare const gtag: any;

export class Analytics {
	public static playCount = 0;
	public static life2Count = 0;
	public static life3Count = 0;
	public static deathCount = 0;
	public static gameOverCount = 0;
	public static backToMainMenuCount = 0;
	public static restartCount = 0;
	public static loginTime = 0;


	public static visit() {
		if (Analytics.visited) { return; }
		Analytics.visited = true;
		Analytics.loginTime = Date.now();
		Analytics.sendStat('visit', { loginTime: Analytics.loginTime, partner: Global.partner || 'default' });
	}
	public static play() {
		const time = Date.now() - Analytics.loginTime;
		const playCount = ++Analytics.playCount;
		Analytics.sendStat('play', { time, playCount });
	}
	public static life2() {
		const time = Date.now() - Analytics.loginTime;
		const extraLifeCount = ++Analytics.life2Count;
		Analytics.sendStat('life2', { time, extraLifeCount });
	}
	public static life3() {
		const time = Date.now() - Analytics.loginTime;
		const extraLifeCount = ++Analytics.life3Count;
		Analytics.sendStat('life3', { time, extraLifeCount });
	}
	public static death() {
		const time = Date.now() - Analytics.loginTime;
		const deathCount = ++Analytics.deathCount;
		Analytics.sendStat('death', { time, deathCount });
	}
	public static gameOver() {
		const time = Date.now() - Analytics.loginTime;
		const gameOverCount = ++Analytics.gameOverCount;
		Analytics.sendStat('gameOver', { time, gameOverCount });
	}
	public static backToMainMenu() {
		const time = Date.now() - Analytics.loginTime;
		const backToMainMenuCount = ++Analytics.backToMainMenuCount;
		Analytics.sendStat('backToMainMenu', { time, backToMainMenuCount });
	}
	public static restart() {
		const time = Date.now() - Analytics.loginTime;
		const restartCount = ++Analytics.restartCount;
		Analytics.sendStat('restart', { time, restartCount });
	}

	public static async sendStat(type: string, payload?: any) {
		gtag('event', type, payload);
	}

	protected static visited = false;

}
