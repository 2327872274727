
import { Vue } from 'vue-property-decorator';
const Observer = (new Vue()).$data
	.__ob__
	.constructor;


export function nonReact<T>(value: T) {
	(value as any).__ob__ = new Observer({});
	return value;
}
