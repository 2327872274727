




































import { Mail, MailType } from '@/game/infos/firestoreFiles';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ordinalSuffix } from '@/util/number';
import ItemButton from '../../tools/inventory/ItemButton.vue';
import { itemInfos } from '@/game/infos/itemInfos';
import userx from '@/store/modules/userx';
import { EditState } from '@/store/models.def';
import { SoundEfx } from '@/client/sound/SoundEfx';
import StateButton from '@/components/inputs/StateButton.vue';
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

@Component({
  components: {
    ItemButton,
    StateButton,
  },
})
export default class MailComponent extends Vue {
  @Prop() public mail!: Mail;
  public get itemInfos() {
    return itemInfos;
  }
  public submitState: EditState = 'active';
  public get submitLabel() {
    const labels = {
      active: 'Claim',
      sending: '',
      done: 'Claimed',
    };
    return labels[this.submitState];
  }
  public expand = false;

  public get date() {
    const d = new Date(this.mail.sentTime);
    return `${ordinalSuffix(d.getDate())} ${
      months[d.getMonth()]
    } ${d.getFullYear()}`;
  }

  public get msg() {
    const mail = this.mail;
    if (mail.type === MailType.Custom) {
      return mail.msg;
    }
    const vars = mail.msg.split('|');

    if (mail.type === MailType.WeeklyContest) {
      return `Congratulations! You've won the <span class="fwb">${ordinalSuffix(
        Number(vars[1]),
      )} place of our Weekly ${vars[0]} Contest!</span> Claim your rewards!`;
    }
    return 'Something Wrong';
  }
  public get readTime() {
    return this.mail.readTime;
  }
  @Watch('readTime', { immediate: true })
  public onReadTimeChanged(val: number | undefined) {
    if (val) {
      this.submitState = 'done';
    } else {
      this.submitState = 'active';
    }
  }

  public onClick() {
    this.expand = !this.expand;
    if (this.expand && !this.mail.items) {
      userx.readMail(this.mail.id);
    }
  }
  public async onClaim() {
    this.submitState = 'sending';
    try {
      await userx.readMail(this.mail.id);
      this.submitState = 'done';
      new SoundEfx('buyChaching').play();
    } catch (error) {
      this.$root.$emit('error', (error as any).message);
      this.submitState = 'active';
    }
  }
}
