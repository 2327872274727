// Firebase App (the core Firebase SDK) is always required and must be listed first
import { getApp, initializeApp } from 'firebase/app';

import userx from '@/store/modules/userx';
import { LoginSubmission, ProcessState, ProfileSubmission } from './models.def';
import { LocalData } from '@/util/localData';
import { StabfishError } from '@/util/error';
import { ActionCodeSettings, Auth, AuthCredential, EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, TwitterAuthProvider, UserInfo, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, getAuth, getIdToken, getRedirectResult, linkWithCredential, linkWithPopup, linkWithRedirect, onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail, sendSignInLinkToEmail, signInAnonymously, signInWithCredential, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, unlink, updatePassword, updateProfile, verifyBeforeUpdateEmail } from 'firebase/auth';
import { User as FsUser } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { Functions, getFunctions } from 'firebase/functions';
import { FirebaseStorage, getStorage } from 'firebase/storage';

export type OAuthSigninMethod = 'google' | 'twitter' | 'facebook' | 'apple' | 'microsoft' | 'yahoo';

export interface User {
	token: string,
	uid: string,
	email: string,
	displayName: string,
	emailVerified: boolean,
	isAnonymous: boolean,
	providerData: UserInfo[],
	noPassword: boolean,
}
export interface SignLinkError {
	action: 'signin' | 'link',
	error: {
		code: string,
		message: string,
		email: string,
		credential?: AuthCredential,
	};
}

// Your web app's Firebase configuration
// const firebaseConfig = {
// 	apiKey: 'AIzaSyA5SConG_Z798Bkt53Ugq7_ZPGlqsOmZaM',
// 	authDomain: 'gobi-9081.firebaseapp.com',
// 	databaseURL: 'https://gobi-9081.firebaseio.com',
// 	projectId: 'gobi-9081',
// 	storageBucket: 'gs://gobi-9081.appspot.com',
// 	messagingSenderId: '252957131638',
// 	appId: '1:252957131638:web:e13c8a4c97b0bbf1243057',
// };
const firebaseConfig = {
	apiKey: 'AIzaSyAz47-PTxnDXGOaM6x9vlWYZdSq-WKh9YY',
	authDomain: 'stabfish2.firebaseapp.com',
	projectId: 'stabfish2',
	storageBucket: 'stabfish2.appspot.com',
	messagingSenderId: '485187692107',
	appId: '1:485187692107:web:cf50cdb4fce1b0cff3cfce',
	measurementId: 'G-6Q5EFD77QJ',
};

const actionCodeSettings: ActionCodeSettings = { url: 'https://stabfish2.io/', handleCodeInApp: true };

export let auth: Auth;
export let db: Firestore;
export let functions: Functions;
export let storage: FirebaseStorage;

let redirectState: ProcessState = ProcessState.Undefined;

export class FirebaseSocket {
	public auth: Auth;
	public db: Firestore;
	public functions: Functions;
	public storage: FirebaseStorage;

	public email: string = '';
	public displayName: string = '';
	public emailVerified: boolean = false;
	public isAnonymous: boolean = true;
	public uid: string = '';
	public providerData: UserInfo[] = [];


	protected _emulator: 'functions' | 'firestore' | 'none' = 'none';

	public constructor(emulator: 'functions' | 'firestore' | 'none' = 'none') {
		// Initialize Firebase
		initializeApp(firebaseConfig);
		// firebase.analytics();

		this.auth = auth = getAuth();
		this.db = db = getFirestore();
		this.functions = functions = getFunctions(getApp(), 'asia-east2');
		this.storage = storage = getStorage();

		// console.log('env:', process.env);
		// this._emulator = emulator;
		// if (process.env.NODE_ENV === 'development') {
		// 	if (emulator === 'firestore') {
		// 		db.settings({
		// 			host: 'localhost:8081',
		// 			ssl: false,
		// 		});
		// 		functions.useEmulator('localhost', 5001);
		// 	} else if (emulator === 'functions') {
		// 		functions.useEmulator('localhost', 5000);
		// 	}
		// }

		// for easy debug
		(window as any).functions = functions;
		(window as any).db = db;
		(window as any).auth = auth;
		(window as any).storage = storage;
		getRedirectResult(auth).then((result) => {
			if (result) {
				const credential = GoogleAuthProvider.credentialFromResult(result);
				if (credential) {
					const token = credential.accessToken;
					// Accounts successfully linked.
					const user = result.user!;
					// console.log('signin / link successful', credential, user?.uid);
					// ...
					if (user && redirectState === ProcessState.Await) {
						this.hasLogin(user);
					}
				}
			} else {
				if (auth.currentUser) {
					this.hasLogin(auth.currentUser);
				}
			}
			redirectState = ProcessState.Succeed;
			// this.checkFailedMergeUsers();
		}).catch((error) => {
			console.log(error);
			// if (error.code === 'auth/credential-already-in-use' && auth.currentUser && auth.currentUser.isAnonymous) {
			// 	const credential: firebase.auth.AuthCredential = error.credential;

			// 	redirectState = ProcessState.Failed;
			// 	this.mergeUsers(auth.currentUser, credential);
			// } else {

			userx.setSignLinkError({
				action: auth.currentUser ? 'link' : 'signin',
				error,
			});


			redirectState = ProcessState.Succeed;
			// this.checkFailedMergeUsers();

			// }
		});

		onAuthStateChanged(auth, (user) => {
			if (user) {
				// User is signed in.
				console.log('sign in');

				if (redirectState === ProcessState.Undefined) {
					redirectState = ProcessState.Await;
				} else if (redirectState === ProcessState.Succeed) {
					this.hasLogin(user);
				}


				// see if user have set their password
				// auth.fetchSignInMethodsForEmail(email).then((list) => {
				// 	// console.log(idTokenResult.claims)
				// 	if (list.includes('password')) {
				// 		userObject.noPassword = false;
				// 	} else {
				// 		userObject.noPassword = true;
				// 	}
				// 	// userx.updateUser(userObject);
				// });


			} else {
				// No user is signed in.
				console.log('sign out');
				userx.logoutLocal();
			}
		});
	}

	public async hasLogin(user: FsUser) {
		const email = this.email = user.email || '';
		const displayName = this.displayName = user.displayName || '';
		const emailVerified = this.emailVerified = user.emailVerified;
		const isAnonymous = this.isAnonymous = user.isAnonymous;
		const uid = this.uid = user.uid;
		const providerData = this.providerData = user.providerData.filter((f) => f) as any as UserInfo[];
		const noPassword = this.providerData.findIndex((u) => u && u.providerId === 'password') === -1;
		const token = await getIdToken(user, true);
		const userObject = { uid, token, email, displayName, emailVerified, isAnonymous, providerData, noPassword };
		// console.log('hasLogin', userObject);
		userx.loginLocal(userObject);
		this._readExtraDocs(userObject);
	}

	public async createUserWithEmailAndPassword(user: LoginSubmission) {
		return createUserWithEmailAndPassword(auth, user.email, user.password);
	}

	public async loginUserAnonymously() {
		return signInAnonymously(auth);
	}

	public async loginUserWithEmailAndPassword(user: LoginSubmission) {
		return signInWithEmailAndPassword(auth, user.email, user.password);
	}

	public async updateUserEmail(email: string) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await verifyBeforeUpdateEmail(auth.currentUser, email, actionCodeSettings);
		} catch (error) {
			// if (error.code === 'auth/email-already-in-use') {
			// 	return this.mergeEmailUser(auth.currentUser, user.email, user.password);
			// } else {
			console.error(error);
			throw error;
			// }
		}
	}
	public async linkUserWithEmailAndPassword(user: LoginSubmission) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		const credential = EmailAuthProvider.credential(user.email, user.password);
		try {
			await linkWithCredential(auth.currentUser, credential);
		} catch (error) {
			// if (error.code === 'auth/email-already-in-use') {
			// 	return this.mergeEmailUser(auth.currentUser, user.email, user.password);
			// } else {
			console.error(error);
			throw error;
			// }
		}
	}

	public async loginUserWithOAuth(method: OAuthSigninMethod, redirect = true) {

		const provider =
			method === 'facebook' ? new FacebookAuthProvider()
				: method === 'twitter' ? new TwitterAuthProvider()
					: method === 'apple' ? new OAuthProvider('apple.com')
						: method === 'yahoo' ? new OAuthProvider('yahoo.com')
							: method === 'microsoft' ? new OAuthProvider('microsoft.com')
								: new GoogleAuthProvider();

		return redirect ? signInWithRedirect(auth, provider) : signInWithPopup(auth, provider);
	}
	public async linkUserWithOAuth(method: OAuthSigninMethod, redirect = true) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		const provider =
			method === 'facebook' ? new FacebookAuthProvider()
				: method === 'twitter' ? new TwitterAuthProvider()
					: method === 'apple' ? new OAuthProvider('apple.com')
						: method === 'yahoo' ? new OAuthProvider('yahoo.com')
							: method === 'microsoft' ? new OAuthProvider('microsoft.com')
								: new GoogleAuthProvider();
		return redirect ? linkWithRedirect(auth.currentUser, provider) : linkWithPopup(auth.currentUser, provider);
	}
	public async unlinkUserWithOAuth(method: OAuthSigninMethod) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		return unlink(auth.currentUser, method + '.com');
	}

	public async logoutUser() {
		await auth.signOut();
	}

	public async updateDisplayName(displayName: string) {
		try {
			await this.updateProfile({ displayName });

		} catch (error) {
			console.error(error);
			throw error;
		}
	}
	public async updateProfile(profile: ProfileSubmission) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		return updateProfile(auth.currentUser, profile);
	}

	public async updatePassword(password: string) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		return updatePassword(auth.currentUser, password);
	}
	public async signInAndResetPassword(oldPassword: string, newPassword: string) {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		try {
			await signInWithEmailAndPassword(auth, auth.currentUser.email!, oldPassword);
			await updatePassword(auth.currentUser, newPassword);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	public async sendSignInEmail(email: string) {
		try {
			window.localStorage.setItem('emailForSignIn', email);
			await sendSignInLinkToEmail(auth, email, actionCodeSettings);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	public async sendEmailVerification() {
		if (!auth.currentUser) {
			throw new StabfishError('auth/requires-login');
		}
		return sendEmailVerification(auth.currentUser, actionCodeSettings);
	}

	public async sendPasswordResetEmail(email: string) {
		return sendPasswordResetEmail(auth, email, actionCodeSettings);
	}
	public async isUserExist(email: string) {
		const haveUser = await fetchSignInMethodsForEmail(auth, email);
		if (haveUser.length > 0) {
			return true;
		}
		return false;
	}
	public async fetchSignInMethodsForEmail(email) {
		return fetchSignInMethodsForEmail(auth, email);
	}

	protected async mergeUsers(user: FsUser, credential: AuthCredential) {
		const anonymousToken = await user.getIdToken(true);
		const newResult = await signInWithCredential(auth, credential);
		if (newResult.user) {
			const targetToken = await newResult.user.getIdToken(true);
			try {
				await this.serverMergeUsers(anonymousToken, targetToken);
				console.log('successfully merge user');
				this.hasLogin(newResult.user);
				redirectState = ProcessState.Succeed;

			} catch (error) {
				LocalData.save('stabfishTokenToMerge', { anonymousToken, targetToken });
				this.hasLogin(newResult.user);
				redirectState = ProcessState.Succeed;
				throw error;
			}
		}
	}
	protected async mergeEmailUser(user: FsUser, email: string, password: string) {
		const anonymousToken = await user.getIdToken(true);

		return signInWithEmailAndPassword(auth, email, password).then(async (newResult) => {
			if (newResult.user) {
				const targetToken = await newResult.user.getIdToken(true);
				try {
					await this.serverMergeUsers(anonymousToken, targetToken);
					console.log('successfully merge user');

				} catch (error) {
					LocalData.save('stabfishTokenToMerge', { anonymousToken, targetToken });
					throw error;
				}
			}
		}).catch((error) => {
			error.code = 'auth/email-exists-but-login-failed';
			error.message = 'Email exists but failed to login with password provided.';
			error.email = email;
			userx.setSignLinkError({
				action: auth.currentUser ? 'link' : 'signin',
				error,
			});
			throw error;
		});

	}
	protected async checkFailedMergeUsers() {
		const failedMerge = LocalData.load('stabfishTokenToMerge');
		if (failedMerge) {
			console.log('merge failed reattempt');
			const anonymousToken: string = failedMerge.anonymousToken;
			const targetToken: string = failedMerge.targetToken;
			try {
				await this.serverMergeUsers(anonymousToken, targetToken);
				LocalData.delete('stabfishTokenToMerge');
			} catch (error) {
				throw (error);

			}
		}

	}
	protected async serverMergeUsers(anonymousToken: string, targetToken: string) {
	}

	protected _readExtraDocs(userObject: any) {
	}
}

(window as any).signInWithCustomToken = (token: string) => signInWithCustomToken(auth, token);
