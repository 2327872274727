import { specialSkillInfos } from '@/game/infos/skills';
import { SpecialSkillData, SkillData } from '@/game/multithread/state';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, Sprite } from 'pixi.js';
import { normalSkillAssets, specialSkillAssets, disposeSkillBg, disposeSkillSprite, getSpecialSprite, getNormalSkillSprite, getSkillBg, SkillBgAsset, SkillAssetInfo, getSkillLvl } from '../../skillAssets';

export class SkillButtonSymbol extends Container {

	public pressed = false;

	public lvlContainer = new Container();
	public bgContainer = new Container();
	public buttonContainer = new Container();
	public disableCover = new Graphics();
	public coolDownCover = new Graphics();
	public activeSprite!: Sprite;
	public cancel!: Container;

	public skillIndex: number = 0;

	public cancelable = false;


	public init() {
		this.activeSprite = this.children[0] as Sprite;
		this.disableCover.beginFill(0, 0.4);
		this.disableCover.drawCircle(0, 0, 20);
		this.lvlContainer.x = 10;
		this.lvlContainer.y = -13;
		this.addChild(
			this.bgContainer,
			this.buttonContainer,
			this.disableCover,
			this.coolDownCover,
			this.lvlContainer,
			this.cancel,
		);
	}

	public update(sk: number, ac: boolean, db: boolean, cd: number = 0) {
		this.coolDownCover.clear();
		const isSpecialSkill = sk < 0;
		if (this.skillIndex !== sk) {
			this.skillIndex = sk;
			this.cancelable = false;
			const actualSkill = sk < 0 ? -sk : sk;
			if (this.bgContainer.children[0]) {
				disposeSkillBg(this.bgContainer.children[0]);
				this.bgContainer.removeChildren();
			}
			if (this.buttonContainer.children[0]) {
				disposeSkillSprite(this.buttonContainer.children[0]);
				this.buttonContainer.removeChildren();
			}
			if (this.lvlContainer.children[0]) {
				disposeSkillSprite(this.lvlContainer.children[0]);
				this.lvlContainer.removeChildren();
			}
			if (actualSkill !== 0) {
				let assetData: SkillAssetInfo;
				if (isSpecialSkill) {
					this.cancelable = specialSkillInfos[actualSkill].cancelable;
					assetData = specialSkillAssets[actualSkill];
					this.bgContainer.addChild(getSkillBg(SkillBgAsset.Special));
					this.buttonContainer.addChild(getSpecialSprite(assetData.asset));
				} else {
					assetData = normalSkillAssets[actualSkill];
					this.buttonContainer.addChild(getNormalSkillSprite(assetData.asset));
				}
				if (assetData.lvl) {
					this.lvlContainer.addChild(getSkillLvl(assetData.lvl));
				}
			}
		}
		if (this.skillIndex !== 0) {
			this.disableCover.visible = db;
			this.activeSprite.visible = ac || this.pressed;
			this.cancel.visible = ac && this.cancelable;
			if (cd) {
				this.coolDownCover.beginFill(0, 0.6);
				const p = 1 - cd;
				this.coolDownCover.arc(0, 0, 20, -Math.PI / 2 + (Math.PI * p), - Math.PI / 2 - (Math.PI * p));
			}
		} else {
			this.disableCover.visible = true;
			this.activeSprite.visible = false;
			this.cancel.visible = false;
		}
	}
}
