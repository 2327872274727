



















import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  HighscoreData,
  ArmageddonHighscoreData,
  CraftingHighscoreData,
} from '@/game/infos/highscoreInfos';
import random from '@/util/random';
import { Queuer } from '@/util/queuer';
import { generateHighscoreFishImage } from '../tools/inventory/highscoreFishImageGenerator';
import { fishInfos } from '@/game/infos/fishInfos';

@Component({
  components: {},
})
export default class HighscoreComponent extends Vue {
  @Prop({ default: 0 }) public index!: number;
  @Prop({ default: '' }) public accordion!: string;
  @Prop({ default: false }) public isFish!: boolean;
  @Prop() public data!: HighscoreData;

  public id = random.v4();
  public imgSrc = '';
  public lastCheckSum = '';

  public expand = this.index === 0;

  public get name() {
    return this.isFish ? fishInfos[this.data.fishType].name : this.data.name;
  }
  public get subName() {
    return this.isFish ? this.data.name : fishInfos[this.data.fishType].name;
  }
  public get label() {
    return `${this.name} (${this.subName})`;
  }
  public get score() {
    return addComma(this.data.score);
  }
  public get kills() {
    return addComma(this.data.kills);
  }
  public get isMe() {
    return this.data.uid === userx.uid;
  }

  public get checkSum() {
    return (
      this.data.fishType +
      '|' +
      this.data.spearType +
      '|' +
      this.data.scoreLeft +
      '|' +
      this.data.decoSet
    );
  }
  @Watch('checkSum', { immediate: true })
  public checkSumChanegd(newVal: string) {
    this.refreshImage();
  }
  @Watch('expand', { immediate: true })
  public expandChanegd(newVal: boolean) {
    this.refreshImage();
  }

  public async refreshImage() {
    if (!this.expand) {
      return;
    }
    if (this.lastCheckSum === this.checkSum) {
      return;
    }
    const checkSum = this.checkSum;
    this.lastCheckSum = checkSum;
    Queuer.queue('highscoreFish', async () => {
      if (checkSum !== this.checkSum) {
        return;
      }
      const { fishType, spearType, decoSet, scoreLeft, skin } = this.data;
      const imgSrc = await generateHighscoreFishImage(
        fishType,
        spearType,
        decoSet,
        scoreLeft,
        skin || 0,
      );
      if (checkSum !== this.checkSum) {
        return;
      }
      if (checkSum !== this.checkSum) {
        return;
      }
      this.imgSrc = imgSrc;
    });
  }
}
