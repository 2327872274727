


























































































import FishButtonCanvasComponent from '@/components/canvas/FishButtonCanvasComponent.vue';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import settingx from '@/store/modules/settingx';
import userx from '@/store/modules/userx';
import { floorToFixed, roundToFixed } from '@/util/number';
import { Queuer } from '@/util/queuer';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { generateFishImage } from './fishImageGenerator';

@Component({
  components: {
    FishButtonCanvasComponent,
  },
})
export default class FishButton extends Vue {
  public get mouseMode() {
    return settingx.now.control === 'mouse';
  }

  public get hasIndicator() {
    return this.pinkIndicator || this.yellowIndicator;
  }
  public get name() {
    return fishInfos[this.fishType].name;
  }
  public get tier() {
    return fishInfos[this.fishType].tier;
  }
  public get decoSet() {
    return this.showDeco ? userx.decoSets[this.fishType] : null;
  }
  public get skinGroup() {
    return this.showDeco ? userx.equippedSkins[this.fishType] || 0 : 0;
  }
  public get sumcheck() {
    return (
      this.fishType.toString() +
      this.skinGroup.toString() +
      JSON.stringify(this.decoSet)
    );
  }

  public get unlockProgressPercent() {
    return floorToFixed(this.unlockProgress * 100, 1);
  }
  @Prop({ default: FishType.Megalodon }) public fishType!: FishType;
  @Prop({ default: false }) public selectable!: boolean;
  @Prop({ default: false }) public confirmable!: boolean;
  @Prop({ default: false }) public locked!: boolean;
  @Prop({ default: false }) public selected!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: false }) public active!: boolean;
  @Prop({ default: false }) public small!: boolean;
  @Prop({ default: false }) public pinkIndicator!: boolean;
  @Prop({ default: false }) public yellowIndicator!: boolean;
  @Prop({ default: 0 }) public unlockProgress!: number;
  @Prop({ default: true }) public showDeco!: boolean;

  public imgSrc = '';

  public touchingStart = 0;
  public touchX = 0;
  public touchY = 0;

  @Watch('sumcheck', { immediate: true })
  public async sumcheckChange(newVal: string) {
    let id = (this.fishType + 1).toString();
    while (id.length < 4) {
      id = '0' + id;
    }
    this.imgSrc = `/images/fishThumbnails/fishThumbnails${id}.png`;
    if (this.skinGroup || this.decoSet) {
      await Queuer.queue('fishImage', async () => {
        this.imgSrc = await generateFishImage(
          this.fishType,
          this.skinGroup,
          this.decoSet,
        );
      });
    }
  }
  public onClick(e) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    if (!this.disabled) {
      this.$emit('click', event);
    }
  }

  public onMouseOver(e: any) {
    if (settingx.now.control === 'touch') {
      e.preventDefault();
      return;
    }
    this.$emit('mouseover', e);
  }
  public async onTouchStart(e: any) {
    this.$emit('mouseover', e);
    this.touchX = e.changedTouches[0]?.screenX;
    this.touchY = e.changedTouches[0]?.screenY;
    this.touchingStart = Date.now();

  }
  public async onTouchEnd(e: any) {
    this.$emit('mouseleave', e);
    if (
      Date.now() - this.touchingStart < 500 &&
      this.touchX === e.changedTouches[0]?.screenX &&
      this.touchY === e.changedTouches[0]?.screenY
    ) {
      this.$emit('click', e);
    }

  }
}
