import { Decoratives, DecoSymbol } from '@/components/ui/mainMenu/tools/decoEditor/decorative';
import { Preset } from '@/game/infos/preset';
import { SkinGroup } from '@/game/infos/skinInfos';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, Rectangle, Sprite } from 'pixi.js';
import * as PIXI from 'pixi.js';
import Factory from '..';
import { getAllTimedChildren, rgbToInteger, stopAtTheEnd, tint } from '../helper';
import { disposeFishBody, disposeSpear, eyeGuideDatas, FishBody, getFishBody, getSpear, MiniSpike, SpearSprite, StunAnimated } from './playerModel';
import { disposeFishSkin, getFishSkin } from './skinAssets';
import { FishType } from '@/game/infos/fishInfos';

export type FishAction = 'idle' | 'move' | 'dead' | 'skill1' | 'skill2' | 'skill3';

export class PlayerSymbol extends Container {

	public get decoShape() {
		if (!this._decoShape) {
			this._decoShape = new Graphics();
			this.addChild(this._decoShape);
		}
		return this._decoShape;
	}

	public get brightness() {
		return this._brightness;
	}
	public set brightness(value: number) {
		if (this._brightness === value) { return; }
		this._brightness = value;
		this.tintAndBrightness();
	}

	public static get() {
		const symbol = PlayerSymbol._pool.get();
		return symbol;
	}
	public static pool(symbol: PlayerSymbol) {
		PlayerSymbol._pool.pool(symbol);
		symbol.reset();
	}


	private static _pool: Pool<PlayerSymbol> = new Pool(PlayerSymbol);

	public miniSpike: MiniSpike;
	public body: Container = new Container();
	public spear: Container = new Container();
	public spearFront: Container = new Container();

	public spearContainer: Container = new Container();
	public spearFrontContainer: Container = new Container();

	public topContainer: Container = new Container();
	public bottomContainer: Container = new Container();
	// public shadow!: Container;
	public stababContainer = new Container();

	public evilEyes: Sprite | null = null;
	public stunSymbol: StunAnimated | null = null;
	private _decoShape: Graphics | null = null;

	private _fishType = -1;
	private _skinGroup = -1;
	private _spearType = -1;

	private _bodyScale = 0;

	private _spearLvl = -1;
	private _spearHeight = Preset.SPEAR_GRID_HEIGHT * this._spearLvl;
	private _spearScale = 1;
	private _isStunned = false;

	private _action: FishAction = 'move';
	private _actionDirty = true;

	private _tint = 0xffffff;
	private _brightness = 1;

	constructor() {
		super();
		this.miniSpike = Factory.get(MiniSpike);
		this.spearFront.y = this.spear.y = -45;

		this.addChild(
			this.bottomContainer,
			this.miniSpike,
			this.spearContainer,
			this.body,
			this.spearFrontContainer,
			this.topContainer,
		);
		this.spearContainer.addChild(this.spear);
		this.spearFrontContainer.addChild(this.spearFront);
		this.spearFront.addChild(this.stababContainer);
	}

	public reset() {
		this.x = this.y = 0;
		this.alpha = 1;
		this.miniSpike.visible = true;
		// this.shadow.visible = true;
		this._bodyScale = 0;
		this.setBodyScale(1);
		this.disposeDecos();
		this.setAction('idle');
		this.setFishType(-1, 0);
		this.setSpear(-1, Preset.SPEAR_MIN_LEVEL, Preset.SPEAR_MIN_LEVEL * Preset.SPEAR_GRID_HEIGHT);
		this.spearContainer.scale.set(1);
		this.spearFrontContainer.scale.set(1);
		this._brightness = 1;
		this._tint = 0xffffff;
		this.tintAndBrightness();
		this.disposeEvilEyes();
	}
	public setTint(value: number) {
		if (this._tint === value) { return; }
		this._tint = value;
		this.tintAndBrightness();
	}

	public setAngle(value: number) {
		this.angle = value;
		// this.shadow.angle = -value;
	}

	public setBodyScale(value: number) {
		if (this._bodyScale === value) { return; }
		this._bodyScale = value;
		this.scale.set(value);
		this.spear.scale.set(this._spearScale / this._bodyScale);
		this.spearFront.scale.set(this._spearScale / this._bodyScale);
	}

	public setSpearHeight(value: number) {
		const oh = Preset.SPEAR_GRID_HEIGHT * this._spearLvl;
	}

	public async setFishType(i: number, skinGroup: SkinGroup) {
		if (this._fishType === i && this._skinGroup === skinGroup) { return; }
		this._fishType = i;
		this._skinGroup = skinGroup;
		if (i === FishType.Penguin || i === FishType.Macguin) {
			this.spearFront.x = this.spear.x = -45;
			this.spear.angle = this.spearFront.angle = .2 / Math.PI * 180;
		} else {
			this.spearFront.x = this.spear.x = 0;
			this.spear.angle = this.spearFront.angle = 0;
		}
		const oldSymbol = this.body.children[0];
		if (oldSymbol) {
			tint(this.body, 0xffffff);
			this.body.removeChildren();
			oldSymbol.emit('reset');
			disposeFishBody(oldSymbol as FishBody);
		}
		if (i < 0) { return; }
		const symbol = getFishBody(i);
		this.body.addChild(symbol);
		this._actionDirty = true;
		this.setAction(this._action);
		this.emit('updateSkin');

		if (skinGroup === 0) { return; }
		const skin = await getFishSkin(i, skinGroup);
		if (skin) {
			if (this._fishType !== skin.fishType || this._skinGroup !== skin.skinGroup) {
				disposeFishSkin(skin);
			} else {
				const oldBody = this.body.children[0];
				if (oldBody) {
					this.body.removeChildren();
					oldBody.emit('reset');
					disposeFishBody(oldBody as FishBody);
				}
				this.body.addChild(skin);
				this._actionDirty = true;
				this.setAction(this._action);
				this.tintAndBrightness();
				this.emit('updateSkin');
			}
		}
	}
	public showEvilEyes() {
		this.disposeEvilEyes();
		const eyeGuide = eyeGuideDatas[this._fishType];
		const size = eyeGuide.scaleX >= 0.8 ? 'Big' : 'Small';
		this.evilEyes = Sprite.from('fishAssets_NemesisEyes' + size);
		this.addChild(this.evilEyes);
		this.evilEyes.scale.set(eyeGuide.scaleX / (eyeGuide.scaleX >= 0.8 ? 1 : 0.67));
		this.evilEyes.x = -this.evilEyes.width / 2 + eyeGuide.x;
		this.evilEyes.y = -this.evilEyes.height / 2 + eyeGuide.y;
	}
	public disposeEvilEyes() {
		if (this.evilEyes) {
			this.removeChild(this.evilEyes);
			this.evilEyes = null;
		}
	}
	public setSpear(i: number, lvl: number, height: number) {
		if (this._spearType !== i || this._spearLvl !== lvl) {
			this._spearType = i;
			this._spearLvl = lvl;
			const oldSymbol = this.spear.children[0];
			if (oldSymbol) {
				this.spear.removeChildren();
				disposeSpear(oldSymbol as SpearSprite);
			}
			if (i >= 0) {
				const symbol = getSpear(i, lvl);
				this.spear.addChild(symbol);
				this._spearHeight = 0;
			}
		}
		if (this._spearHeight !== height) {
			const oh = Preset.SPEAR_GRID_HEIGHT * (this._spearLvl + Preset.SPEAR_MIN_LEVEL);
			this._spearScale = height / oh;
			this.spear.scale.set(this._spearScale / this._bodyScale);
			this.spearFront.scale.set(this._spearScale / this._bodyScale);
		}
	}
	public setAction(action: FishAction) {
		if (!this._actionDirty && this._action === action) { return; }
		this._action = action;
		const mc = this.body.children[0] as any;
		if (!mc) { return; }
		if (mc._labelDict[action]) {
			mc.gotoAndPlay(action);
		} else {
			mc.gotoAndPlay('idle');
		}
	}
	public setIsStunned(value: boolean) {
		if (this._isStunned === value) { return; }
		this._isStunned = value;
		if (value) {
			this.stunSymbol = Factory.get(StunAnimated);
			this.addChild(this.stunSymbol);
		} else {
			this.removeChild(this.stunSymbol!);
			Factory.pool(StunAnimated, this.stunSymbol!);
			this.stunSymbol = null;
		}
	}

	public disposeDecos() {
		const top = this.topContainer;
		const bottom = this.bottomContainer;
		for (const child of top.children) {
			const symbol = child as DecoSymbol;
			if (symbol.decoName) {
				Decoratives.pool(symbol.decoName, symbol);
			} else if (symbol.cacheAsBitmap) {
				symbol.cacheAsBitmap = false;
			}
		}
		for (const child of bottom.children) {
			const symbol = child as DecoSymbol;
			if (symbol.decoName) {
				Decoratives.pool(symbol.decoName, symbol);
			} else if (symbol.cacheAsBitmap) {
				symbol.cacheAsBitmap = false;
			}
		}
		top.removeChildren();
		bottom.removeChildren();
	}
	protected tintAndBrightness() {
		const red = this._tint >> 16;
		const green = this._tint - (red << 16) >> 8;
		const blue = this._tint - (red << 16) - (green << 8);
		const value = rgbToInteger(red * this.brightness, green * this.brightness, blue * this.brightness);
		tint(this.body, value);
	}
}
