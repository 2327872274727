












































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import MustLoginModal from '../mainMenu/MustLoginModal.vue';
import { ServerInitInfo } from '@/game/infos/serverInfos';
import gamex from '@/store/modules/gamex';
import { GameType } from '@/game/infos/roomInfos';
import { FishType } from '@/game/infos/fishInfos';
import ServerDropDown from './ServerDropDown.vue';
import { loadMechguinAssets } from '@/client/factory/assets/boss/xmasPenguin/loadXmasPenguinAssets';
import globalx from '@/store/modules/globalx';
import assetx from '@/store/modules/assetx';
import { BossRoomType } from '@/game/infos/roomInfosBoss';
import Radix36Input from '@/components/inputs/Radix36Input.vue';
import LimitedShopModal from './LimitedShopModal.vue';
import { ItemCode } from '@/game/infos/itemInfos';
import { SoundEfx } from '@/client/sound/SoundEfx';
import settingx from '@/store/modules/settingx';
import { ServerTime } from '@/store/api/serverTime';
import { TimeLimitedQuestCode, isTimeQuestTime } from '@/game/infos/questTimeLimited';

@Component({
  components: {
    MustLoginModal,
    ServerDropDown,
    Radix36Input,
    LimitedShopModal,
  },
})
export default class BossList extends Vue {

  public get myServerName() {
    const myServer = globalx.selectedServer || globalx.recommendedServer;
    if (!myServer) {
      return 'Please Choose';
    }
    const split = myServer.name.split('|');
    return split[1];
  }
  public get myShop() {
    return userx.inventory.shop || {};
  }
  public get canUnlock2() {
    return !this.unlocking && (userx.inventory.availables[ItemCode.PirateToken] || 0) > 1000;
  }
  public get canUnlock3() {
    return !this.unlocking && (userx.inventory.availables[ItemCode.PirateToken] || 0) > 2000;
  }
  public get hasShopIndicator() {
    return !settingx.now.readBoss1Shop;
  }
  public get hasDiffIndicator() {
    return !settingx.now.readBoss1Dif2 || !settingx.now.readBoss1Dif3;
  }

  public get showMustLoginModal() {
    return userx.loginStatus !== 'logged in' ? true : false;
  }
  public get socketErrorMessage() {
    return gamex.socketErrorMessage;
  }
  public get rejectReason() {
    return gamex.rejectReason;
  }
  public get maintenanceStartTime() {
    return globalx.maintenanceInfo?.start || 0;
  }
  public get letters() {
    return `Loading Event Assets...${assetx.mechguinAssetLoadProgress}%`.split('');
  }
  public get assetLoadProgress() {
    return Math.round(assetx.mechguinAssetLoadProgress);
  }
  public get toFade() {
    return gamex.gameStage === 'BossCreate' || (gamex.gameType === GameType.Boss && (gamex.gameStage === 'Connecting' || gamex.gameStage === 'Joining'));
  }
  public get maintenanceSoon() {
    return this.maintenanceStartTime > 0 && globalx.time > this.maintenanceStartTime - 15 * 60000;
  }
  public get eventEnded() {
    return !isTimeQuestTime(globalx.time, TimeLimitedQuestCode.XmasPenguin);
  }


  public unlocking = false;
  public difficulty = this.myShop.boss1dif3 ? 3 : this.myShop.boss1dif2 ? 2 : 1;
  public lobbyIdInput = '';
  public async onUnlock(itemId: string) {

    try {
      this.unlocking = true;
      // await wait(1000);
      await userx.buyShopLimited({ itemId, count: 1 });
      new SoundEfx('buyChaching').play();
      this.unlocking = false;
    } catch (error) {
      this.showError(error);
    }

  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }


  public created() {
    loadMechguinAssets();
  }

  public onDifficulty(direction: number) {
    if (direction < 0 && this.difficulty > 1) {
      this.difficulty -= 1;
    } else if (direction > 0 && this.difficulty < 3) {
      this.difficulty += 1;
    }
    if (this.difficulty === 2 && !settingx.now.readBoss1Dif2) {
      settingx.read('readBoss1Dif2');
    } else if (this.difficulty === 3 && !settingx.now.readBoss1Dif3) {
      settingx.read('readBoss1Dif3');
    }
  }
  public onCreate() {
    const server = globalx.selectedServer || globalx.recommendedServer;
    if (server) {

      Global.stageControl.communicator?.createBossLobby(
        server.region,
        userx.userSettings.lastBossHeroes ? userx.userSettings.lastBossHeroes[0] : userx.userSettings.lastHeroes[2],
        {
          boss: 1,
          difficulty: this.difficulty,
        },
      );
    } else {
      this.$root.$emit('error', 'No server selected.');
    }
  }
  public onFind() {
    const server = globalx.selectedServer || globalx.recommendedServer;
    if (server) {
      this.$bvModal.show('lobby-id-input');
    } else {
      this.$root.$emit('error', 'No server selected.');
    }
  }

  public onJoinPublic(e) {
    const server = globalx.selectedServer || globalx.recommendedServer;
    if (server) {
      Global.stageControl.communicator?.findBossLobby(
        server.region,
        userx.userSettings.lastBossHeroes ? userx.userSettings.lastBossHeroes[0] : userx.userSettings.lastHeroes[2],
        '',
        {
          boss: 1,
          difficulty: this.difficulty,
        },
      );
    } else {
      this.$root.$emit('error', 'No server selected.');
    }

  }
  public onLobbyIdOk(e) {
    const server = globalx.selectedServer || globalx.recommendedServer;
    if (server) {
      Global.stageControl.communicator?.findBossLobby(
        server.region,
        userx.userSettings.lastBossHeroes ? userx.userSettings.lastBossHeroes[0] : userx.userSettings.lastHeroes[2],
        this.lobbyIdInput,
        {
          boss: 1,
          difficulty: this.difficulty,
        },
      );
    } else {
      this.$root.$emit('error', 'No server selected.');
    }

  }
  public onLobbyIdCancel() {
    // this.lobbyIdInput = '';
  }
  public onJoinTeam() {
    const server = globalx.selectedServer || globalx.recommendedServer;
    if (server) {
      Global.stageControl.communicator?.joinTeamBossLobby(
        server.region,
        userx.userSettings.lastBossHeroes ? userx.userSettings.lastBossHeroes[0] : userx.userSettings.lastHeroes[2],
        {
          boss: 1,
          difficulty: this.difficulty,
        },
      );
    } else {
      this.$root.$emit('error', 'No server selected.');
    }

  }
  public onExit() {
    gamex.setGameStage('MainMenu');
  }
  @Watch('socketErrorMessage')
  public async socketErrorMessageChanged(value: string) {
    if (!value) {
      return;
    }
    gamex.updateGameType(GameType.None);
    gamex.setGameStage('BossList');
    gamex.setSocketErrorMessage('');
    this.$bvModal.msgBoxOk(value, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Connection Lost`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
  }
  @Watch('rejectReason')
  public async rejectReasonChanged(value: string) {
    if (!value) {
      return;
    }
    gamex.updateGameType(GameType.None);
    gamex.setGameStage('BossList');
    gamex.setRejectReason('');
    this.$bvModal.msgBoxOk(value, {
      titleHtml: `<i class="fa fa-exclamation-triangle"></i> Join Room Failed`,
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'danger',
      modalClass: 'funny-modal',
    });
  }
}
