






















































































import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { floorToFixed, roundToFixed } from '@/util/number';
import { BModal } from 'bootstrap-vue';
import uuid from 'uuid';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StateButton from '@/components/inputs/StateButton.vue';

import { EditState } from '@/store/models.def';
import { ItemCode, Rarity } from '@/game/infos/itemInfos';
import SkinButton from './SkinButton.vue';
import { decodeSkinId, SkinGroup, skinInfos } from '@/game/infos/skinInfos';
import { wait } from '@/util/wait';
import { SoundEfx } from '@/client/sound/SoundEfx';

@Component({
  components: {
    StateButton,
    SkinButton,
  },
})
export default class ConvertAllDuplicatesModal extends Vue {
  public baseUrl = process.env.BASE_URL;
  public convertState: EditState = 'active';

  public skinGroup: SkinGroup = SkinGroup.First;
  public rarity: Rarity = Rarity.Rare;

  public get rarityLabel() {
    return this.rarity === Rarity.Rare
      ? 'Rare'
      : this.rarity === Rarity.Epic
      ? 'Epic'
      : 'Legendary';
  }

  public get blackPearlOwned() {
    return userx.inventory.availables[ItemCode.Gem] || 0;
  }

  public get conversionRate() {
    const rarity = this.rarity;

    return rarity === Rarity.Legendary
      ? 5 * 200
      : rarity === Rarity.Epic
      ? 200
      : rarity === Rarity.Rare
      ? 200 / 5
      : 0;
  }

  public get result() {
    return this.amount * this.conversionRate;
  }

  public get amount() {
    return userx.numDuplicates[this.skinGroup][this.rarity] || 0;
  }

  public get canConvert() {
    return this.amount > 0;
  }
  public get convertLabel() {
    const labels = {
      active: 'Convert',
      waiting: 'Convert',
      sending: 'Converting',
      done: 'Converted',
    };
    return labels[this.convertState];
  }

  public get rarityColor() {
    return {
      4: Global.rarityColors[Rarity.Legendary],
      3: Global.rarityColors[Rarity.Rare],
      2: Global.rarityColors[Rarity.Uncommon],
    };
  }

  public created() {
    Global.convertAllDuplicatesModal = this;
  }

  public show(skinGroup: SkinGroup, rarity: Rarity) {
    this.skinGroup = skinGroup;
    this.rarity = rarity;
    (this.$refs.myModal as BModal).show();
  }

  public async onConvert() {
    this.convertState = 'waiting';
    const value = await this.$bvModal.msgBoxConfirm(
      'This action cannot be undone.',
      {
        title: `Are you sure?`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'orange',
        cancelVariant: 'link btn-link',
        modalClass: 'funny-modal',
        okTitle: 'Confirm',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      },
    );
    if (value) {
      try {
        this.convertState = 'sending';
        // await wait(1000);
        const { skinGroup, rarity } = this;
        await userx.convertAllDuplicateSkins({ skinGroup, rarity });
        new SoundEfx('buyChaching').play();
        this.convertState = 'active';
      } catch (error) {
        this.showError(error);
      }
    } else {
      this.convertState = 'active';
    }
  }

  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
