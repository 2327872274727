



















































































































































































































































































































































































































































































































































import { AipAds } from '@/client/ads/aipads';
import { CrazyAd } from '@/client/ads/crazyads';
import { Analytics } from '@/client/analytics';
import { BgmManager } from '@/client/sound/BgmManager';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { FishType } from '@/game/infos/fishInfos';
import { levelRequirements } from '@/game/infos/levelInfos';
import {
  ItemCodeAmountPair,
  UnlockChallenge,
  UnlockChallengeType,
} from '@/game/infos/unlockModels';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import GameBagComponent from '../game/utilityBar/GameBagComponent.vue';
import QuestComponent from '../mainMenu/tabs/quests/QuestComponent.vue';
import ChallengeProgressComponent from './ChallengeProgressComponent.vue';
import FishUnlockModal from './FishUnlockModal.vue';
import { GameType } from '@/game/infos/roomInfos';
import SideBossButtons from '../boss/SideBossButtons.vue';

@Component({
  components: {
    ChallengeProgressComponent,
    FishUnlockModal,
    GameBagComponent,
    QuestComponent,
	SideBossButtons,
  },
})
export default class AfterGameReport extends Vue {
  public get hasMaintenance() {
     return globalx.hadMaintenance;
  }
  public get sessionId() {
    return this.lastSessionReport?.sessionId || '';
  }

  public get progresses() {
    if (!this.lastSessionReport || !this.lastSessionReport.progress) {
      return {};
    }
    const result: Dictionary<string[]> = {};
    for (const challengeId of this.lastSessionReport.progress) {
      const splits = challengeId.split('-');
      if (splits[0] !== 'F') {
        continue;
      }
      if (!result[splits[1]]) {
        result[splits[1]] = [];
      }
      result[splits[1]].push(challengeId);
    }
    return result;
  }
  public get rank() {
    const exp = this.highscore;
    return exp >= 500000
      ? 'sss'
      : exp >= 100000
      ? 'ss'
      : exp >= 10000
      ? 's'
      : exp >= 5000
      ? 'a'
      : exp >= 1000
      ? 'b'
      : exp >= 100
      ? 'c'
      : exp > 0
      ? 'd'
      : 'e';
  }

  public get rewards(): ItemCodeAmountPair[] {
    if (!this.lastSessionReport || !this.lastSessionReport.rewards) {
      return [];
    }
    const list: ItemCodeAmountPair[] = [];
    for (const itemCode in this.lastSessionReport.rewards) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.lastSessionReport.rewards,
          itemCode,
        )
      ) {
        const amount = this.lastSessionReport.rewards[itemCode];
        list.push({ itemCode: Number(itemCode), amount });
      }
    }
    return list;
  }
  public get loot(): ItemCodeAmountPair[] {
    if (!this.lastSessionReport || !this.lastSessionReport.loot) {
      return [];
    }
    const list: ItemCodeAmountPair[] = [];
    for (const itemCode in this.lastSessionReport.loot) {
      if (
        Object.prototype.hasOwnProperty.call(
          this.lastSessionReport.loot,
          itemCode,
        )
      ) {
        const amount = this.lastSessionReport.loot[itemCode];
        list.push({ itemCode: Number(itemCode), amount });
      }
    }
    return list;
  }
  public get highscore() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.score || 0;
  }
  public get newRecordScore() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      this.lastSessionReport.oldRecord.score < this.highscore
    );
  }
  public get moneyEarned() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.money || 0;
  }
  public get newRecordMoney() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      this.lastSessionReport.oldRecord.money < this.moneyEarned
    );
  }
  public get mined() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.mined || 0;
  }
  public get newRecordMined() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      this.lastSessionReport.oldRecord.mined < this.mined
    );
  }
  public get stabs() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.stabs || 0;
  }
  public get newRecordStabs() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      this.lastSessionReport.oldRecord.stabs < this.stabs
    );
  }
  public get stabstreaks() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.stabstreaks || 0;
  }
  public get newRecordStabStreaks() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      this.lastSessionReport.oldRecord.stabstreaks < this.stabstreaks
    );
  }
  public get kills() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.kills || 0;
  }
  public get newRecordKills() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      this.lastSessionReport.oldRecord.kills < this.kills
    );
  }
  public get killstreaks() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.killstreaks || 0;
  }
  public get newRecordKillStreaks() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      this.lastSessionReport.oldRecord.killstreaks < this.killstreaks
    );
  }
  public get nmsStage() {
    if (!this.lastSessionReport) {
      return 0;
    }
    return this.lastSessionReport.nmsStage || 0;
  }
  public get newRecordNmsStage() {
    if (!this.lastSessionReport) {
      return false;
    }
    return (
      !this.lastSessionReport.oldRecord ||
      (this.lastSessionReport.oldRecord.nmsStage || 0) < this.nmsStage
    );
  }
  public get lastSessionReport() {
    const report = userx.userDoc.lastSessionReport;
    const sessionId = Global.clientCommunicator.lastSessionId;
    return report; // && report.sessionId === sessionId ? report : null;
  }

  public get newLvl() {
    if (!this.lastSessionReport) {
      return 1;
    }
    const report = this.lastSessionReport;

    this.hitLvlCap = report.hitLvlCap;
    this.lvlUp = userx.oldLvl < report.newLvl;
    if (report.hitLvlCap) {
      this.newLvlPercentage = 1;
      this.oldLvlPercentage = 1;
      this.nextLvlExp = this.currentLvlExp =
        levelRequirements[levelRequirements.length - 1];
    } else {
      this.nextLvlExp = levelRequirements[report.newLvl - 1];

      let oldLvlExp = userx.oldExp;
      let currentLvlExp = report.newExp;
      for (let i = 0; i < report.newLvl - 1; i++) {
        currentLvlExp -= levelRequirements[i];
        oldLvlExp -= levelRequirements[i];
      }
      this.currentLvlExp = currentLvlExp;

      if (this.lvlUp) {
        this.oldLvlPercentage = 0;
      } else {
        this.oldLvlPercentage = oldLvlExp / this.nextLvlExp;
      }
      this.newLvlPercentage = currentLvlExp / this.nextLvlExp;
      if (this.oldLvlPercentage > 0 && this.oldLvlPercentage < 0.01) {
        this.oldLvlPercentage = 0;
      }
      if (this.newLvlPercentage > 0 && this.newLvlPercentage < 0.01) {
        this.newLvlPercentage = 0.01;
      }
    }

    return report.newLvl;
  }

  public get newExpBarStyle() {
    return {
      width: `${this.newLvlPercentage * 100}%`,
      borderRadius: this.newLvlPercentage < 0.96 ? '10px 0px 0px 10px' : '10px',
    };
  }
  public get oldExpBarStyle() {
    return {
      width: `${this.oldLvlPercentage * 100}%`,
      borderRadius:
        this.newLvlPercentage >= 0.96 && this.oldLvlPercentage > 0.94
          ? '10px'
          : '10px 0px 0px 10px',
    };
  }
  public pendingUnlocks: FishType[] = [];

  public get pendingClaims() {
    return userx.claimableQuests;
  }

  public randomChallenges: UnlockChallenge[] = [];

  public nextLvlExp = 0;
  public currentLvlExp = 0;
  public oldLvlPercentage = 0.01;
  public newLvlPercentage = 0.01;
  public hitLvlCap = false;
  public lvlUp = false;

  public doneClickable = false;

  public addComma = addComma;
  public mounted() {
    Analytics.gameOver();
    if (Global.adsdk === 'crazyads') {
      CrazyAd.instance.refreshMultipleBannerAd(
        'afterGame-300x250',
        'afterGame-468x60',
      );
    } else {
      AipAds.instance.refreshBannerAd('stabfish2-io_336x280');
      AipAds.instance.refreshBannerAd('stabfish2-io_468x60');
    }
    let count = 1;
    const playSound = () => {
      count--;
      if (count > 0) {
        new SoundEfx('scoreReveal').play({ volume: 0.8 });
        setTimeout(playSound, 500);
      } else {
        new SoundEfx('rankReveal').play();
      }
    };
    playSound();
    setTimeout(() => {
      BgmManager.instance.play('bgmMainMenu');
      BgmManager.currentBgm.volume = 1;
      this.doneClickable = true;
    }, 500);
  }

  @Watch('sessionId', { immediate: true })
  public sessionIdChanged() {
    this.setPendingUnlocks(this.progresses);
    this.setRandomChallenges(this.progresses);
  }
  public onContinue() {
    gamex.updateGameType(GameType.None);
    gamex.setGameStage('MainMenu');
    Analytics.backToMainMenu();
  }
  public async onRestart() {
    Global.isVideoPlaying = true;
    gamex.updateGameType(GameType.Armageddon);
    gamex.setGameStage('Connecting');
    if (Global.adsdk === 'crazyads') {
      await CrazyAd.instance.requestAd('midgame');
    } else {
      await AipAds.instance.requestVideoAd('midgame');
    }
    Global.isVideoPlaying = false;
    const server = globalx.selectedServer || globalx.recommendedServer;
    if (server) {
      Global.stageControl.communicator!.connect(server, userx.heroes, userx.needTutorial);
      Analytics.restart();
    } else {
      gamex.updateGameType(GameType.None);
      gamex.setGameStage('MainMenu');
      this.$root.$emit('error', 'No server selected.');
      Analytics.backToMainMenu();
    }
  }

  public onView(fishType: FishType) {
    Global.fishUnlockModal.show(fishType);
  }

  protected setPendingUnlocks(progresses: Dictionary<string[]>) {
    const results: FishType[] = [];
    for (let i = 0; i < userx.pendingFishUnlocks.length; i++) {
      const isPending = userx.pendingFishUnlocks[i];

      if (isPending && !progresses[i]) {
        results.push(i);
      }
    }
    this.pendingUnlocks = results;
  }
  protected setRandomChallenges(progresses: Dictionary<string[]>) {
    const plen = Object.values(progresses).length;
    if (plen >= 2) {
      this.randomChallenges = [];
    }
    const results = userx.fishCurrentUnlockChallenges.filter(
      (c) => c && !progresses[c.unlockId],
    ) as UnlockChallenge[];
    results.sort(() => Math.random() * 2 - 1);

    this.randomChallenges = results.splice(0, 2 - plen);
  }
}
