






















import { FishType } from '@/game/infos/fishInfos';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  UnlockChallenge,
  UnlockChallengeProgress,
  UnlockChallengeType,
} from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { getChallengeProgress } from '@/game/infos/firestoreFiles';
import StateButton from '@/components/inputs/StateButton.vue';
import { EditState } from '@/store/models.def';
import { floorToFixed, roundToFixed } from '@/util/number';
import { ItemCode } from '@/game/infos/itemInfos';
@Component({
  components: {
    StateButton,
  },
})
export default class FishChallengeComponent extends Vue {
  @Prop() public challenge!: UnlockChallenge;
  @Prop({ default: true }) public disabled!: boolean;
  @Prop({ default: false }) public reading!: boolean;
  @Prop({ default: false }) public fullGreen!: boolean;

  public submitState: EditState = 'active';
  public get submitLabel() {
    const labels = {
      active: 'Pay',
      sending: '',
      done: 'Paid',
    };
    return labels[this.submitState];
  }
  public hasIndicator = false;

  @Watch('challengeId', { immediate: true })
  public challengeIdChanged(newVal: string) {
    this.hasIndicator = false;
    this.submitState = 'active';
  }
  @Watch('newlyCompleted', { immediate: true })
  public newlyCompletedChanged(newVal: boolean) {
    if (newVal) {
      this.hasIndicator = true;
    }
  }

  public get challengeId() {
    return `F-${this.challenge.unlockId}-${this.challenge.step}-${this.challenge.index}`;
  }

  public get newlyCompleted() {
    return !this.reading
      ? false
      : userx.unreadCompletedFishChallenges.find((s) => s === this.challengeId)
      ? true
      : false;
  }

  public get isContribute() {
    return this.challenge.type === UnlockChallengeType.Contribute;
  }

  public get statusIcon() {
    const completed = this.progress.completed;
    return `<i class="fas fa-${completed ? 'check-circle' : 'clock'} text-${
      this.disabled ? 'gray-600' : completed ? 'green' : 'white'
    }"/>`;
  }

  public get progress(): UnlockChallengeProgress {
    //  this.challenge.
    const progresses = userx.userDoc.unlockProgress;
    const { unlockId, step, index } = this.challenge;
    return getChallengeProgress(progresses, unlockId, step, index);
  }

  public get hasEnoughMaterial() {
    return this.myNumMaterial >= this.challenge.goal;
  }

  public get myNumMaterial() {
    const challenge = this.challenge;
    if (!challenge.material) {
      return 0;
    }
    return challenge.material === ItemCode.Money
      ? userx.inventory.money
      : challenge.material === ItemCode.Gem
      ? userx.inventory.gem
      : userx.inventory.availables[challenge.material] || 0;
  }

  public get description() {
    const challenge = this.challenge;
    const des = challenge.description;
    const type = challenge.type;
    const suffix =
      this.progress.completed || type === UnlockChallengeType.UnlockFish
        ? ''
        : type === UnlockChallengeType.Level
        ? `[${userx.userDoc.lvl}/${challenge.goal}]`
        : type === UnlockChallengeType.Contribute
        ? `[${this.myNumMaterial}/${challenge.goal}]`
        : `[${floorToFixed(
            this.progress.current,
            challenge.goal > 10 ? 0 : 1,
          )}/${challenge.goal}]`;

    return des + ' ' + suffix;
  }

  public async onContribute() {
    this.submitState = 'sending';
    try {
      await userx.contribute(this.challengeId);
      this.submitState = 'done';
    } catch (error) {
      this.$root.$emit('error', (error as any).message);
      this.submitState = 'active';
    }
  }
}
