




















































import { availableFishes, FishType } from '@/game/infos/fishInfos';
import { passiveSkillInfos } from '@/game/infos/skills';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import FishButton from '../inventory/FishButton.vue';
import userx from '@/store/modules/userx';

@Component({
  components: {
    FishButton,
  },
})
export default class FishList extends Vue {
  @Prop({ default: 0 }) public confirmed!: FishType;
  @Prop({ default: 0 }) public selected!: FishType;
  @Prop({ default: -1 }) public life!: number;
  @Prop({ default: true }) public showInfo!: boolean;
  public width = this.$el ? this.$el.clientWidth : 0;
  public height = this.$el ? this.$el.clientHeight : 0;
  public filter: string = '';

  public get hasNewlyCompletes() {
    return this.inventory.map((f) =>
      userx.userSettings.completedChallenges.find(
        (s) => s.indexOf(`F-${f.type}-`) === 0,
      ),
    );
  }
  public get pendingFishUnlocks() {
    return userx.pendingFishUnlocks;
  }
  public get fishUnlockProgress() {
    return userx.fishUnlockProgress;
  }

  public get inventory() {
    return availableFishes;
  }

  public get datas() {

    return this.inventory.map((f) => {
      const genes = f.genes.map((g) => 'gene' + passiveSkillInfos[g.type].name);
      const recess = f.recessives.map((g) => 'gene' + passiveSkillInfos[g.type].name);
      return ['name ' + f.name, f.family + ' family', 'color ' + f.color + ' color', 'colour ' + f.color + ' colour', 'tier ' + f.tier, ...genes, ...recess]
        .join('  ')
        .toLocaleLowerCase();
    });
  }

  public get filteredInventory() {
    const keywords = this.filter
      .toLocaleLowerCase()
      .split(',')
      .map((k) => k.trim());
    let results = this.inventory;
    // results.unshift();

    results = this.filter.trim()
      ? results.filter((f, i) => {
        for (const keyword of keywords) {
          const match = keyword.charAt(0) !== '!';
          const key = keyword.replaceAll('!', '').trim();
          if (match) {
            if (this.datas[i].indexOf(key) === -1) {
              return false;
            }
          } else {
            if (this.datas[i].indexOf(key) !== -1) {
              return false;
            }
          }

        }
        return true;
      })
      : results;
    return results;
  }

  public get unlocked() {
    return userx.fishUnlocked;
  }
  public select(type: FishType) {
    this.$emit('select', type);
  }

  public onResize() {
    this.width = this.$el.clientWidth;
    this.height = this.$el.clientHeight;
  }

  public get singleLine() {
    return this.height < 250;
  }

  public scrollFishList(e: any) {
    const el = (this.$refs.scroll as any);
    if (e.deltaY > 0) {
      el.scrollLeft += 100;
    } else {
      el.scrollLeft -= 100;
    }
  }

  public mounted() {
    const el = (this.$refs.scroll as any);
    el.addEventListener('wheel', this.scrollFishList);
  }

  public activated() {
    if (!(this.$el as any).resizeSensor) {
      const res = new ResizeSensor(this.$el, this.onResize);
    }
    setTimeout(() => {
      const scroll = (this.$refs.scroll as any);
      const container = this.$refs.fishButtonContainer as HTMLElement;
      const el = container.children[
        this.filteredInventory.findIndex((f) => f.type === this.selected)
      ] as HTMLDivElement;
      if (this.singleLine) {
        const offset = el.offsetLeft;
        scroll.scrollLeft = offset - 100;
        // console.log('offsetLeft', offset, this.selected);
      } else {
        const offset = el.offsetTop;
        scroll.scrollTop = offset - 100;
        // console.log('offsetTop', offset, this.selected);
      }
    }, 500);
  }
  public deactivated() {
    if ((this.$el as any).resizeSensor) {
      ResizeSensor.detach(this.$el);
    }
  }

  public beforeDestroy() {
    const el = (this.$refs.scroll as any);
    el.removeEventListener('wheel', this.scrollFishList);
  }
}
