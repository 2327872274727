import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsBodySlam } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';
import { SoundEfx } from '@/client/sound/SoundEfx';


export class SsBodySlamSymbol extends Container implements MiscSymbol {

	public area!: Graphics;

	public data: MiscData | null = null;

	public sound = new LongSoundEfx('bodySlam');
	public nextShake = 0;

	public init() {
		this.area = this.children[0] as Graphics;

	}
	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.parent) {
			this.parent.removeChild(this);
		}
		Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
			this.sound.stop();
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsBodySlam;
		this.x = data.x;
		this.y = data.y;

		this.x = data.x;
		this.y = data.y;
		this.angle = data.d;
		this.scale.set(data.bs);

		const startTime = data.st;
		const castTime = data.ct;
		if (rt < startTime + castTime) {
			this.area.visible = true;
			this.nextShake = 0;
		} else {
			this.area.visible = false;
			const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
			this.sound.volume = volume;
			this.sound.start({ loop: false });

			if (rt > this.nextShake) {
				this.nextShake = rt + 196;
				if (volume > .5) {
					room.cameraControl.swing(10 * (volume - .4), 196);
				}
			}
		}

		this.data = data;

	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this);
		}
		this.sound.volume = 0;
	}
}

export class SsJomamaShieldSymbol extends Container implements MiscSymbol {


	public data: MiscData | null = null;


	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscData;
		this.x = data.x;
		this.y = data.y;

		this.x = data.x;
		this.y = data.y;

		if (!this.data) {
			new SoundEfx('diamondHarden').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
		}
		this.data = data;

	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}
}
