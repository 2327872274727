

























































































































































































































































import settingx from '@/store/modules/settingx';
import gamex from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store/globalz';
import { addComma } from '@/util/number';
import UtilityBarComponent from './utilityBar/UtilityBarComponent.vue';
import UtilityInfo from './utilityBar/UtilityInfo.vue';
import SkillButtonOverlay from './SkillButtonOverlay.vue';
import UtilityRankComponent from './utilityBar/UtilityRankComponent.vue';
import userx from '@/store/modules/userx';
import { levelRequirements, maxExp } from '@/game/infos/levelInfos';
import { Ticker, TimeLimiter } from 'pixi.js';
import { ViewState } from '@/game/multithread/viewState';
import UtilityGameBagComponent from './utilityBar/UtilityGameBag.vue';
import PolarModal from './modals/PolarModal.vue';
import { SoundEfx } from '@/client/sound/SoundEfx';
import PlayerListComponent from './PlayerListComponent.vue';
import MiniChatBox from './MiniChatBox.vue';
import ChatModal from './ChatModal.vue';
import IvHealModal from './modals/IvHealModal.vue';
import IdolNoteModal from './modals/IdolNoteModal.vue';
import { tourneyMapInfos } from '@/game/infos/roomInfosTny';
import { PlayerGameState } from '@/game/multithread/state';
import { TimeLimitedQuestCode, isQuestTime } from '@/game/infos/questTimeLimited';
import { GameType } from '@/game/infos/roomInfos';
import DojoLobbyModal from './modals/DojoLobbyModal.vue';
import DojoSettingsModal from './modals/DojoSettingsModal.vue';
import ClaimGladiatorModal from './modals/ClaimGladiatorModal.vue';

@Component({
	components: {
		UtilityBarComponent,
		SkillButtonOverlay,
		UtilityInfo,
		UtilityRankComponent,
		UtilityGameBagComponent,
		PolarModal,
		MiniChatBox,
		ChatModal,
		IvHealModal,
		IdolNoteModal,
		ClaimGladiatorModal,
		DojoLobbyModal,
		DojoSettingsModal,
	},
})
export default class UiControlComponent extends Vue {

	public get showDeadDuringBoss() {
		// return this.bool;
		return (
			gamex.gameType === GameType.Boss && gamex.gameStage === 'Start' && gamex.playerState === PlayerGameState.Dead
		);
	}
	public hideMini = false;

	public get tnyData() {
		return gamex.tnyLobbyData;
	}
	public get bossData() {
		return gamex.bossLobbyData;
	}
	public get mapData() {
		return tourneyMapInfos[this.tnyData?.roomOptions.mapType || 0];
	}
	public get isArmageddon() {
		return gamex.gameType === GameType.Armageddon;
	}
	public get isBoss() {
		return gamex.gameType === GameType.Boss;
	}
	public get isWaiting() {
		return gamex.currentGameState.state === PlayerGameState.Waiting;
	}

	public get symbol() {
		return Global.stageControl.uiControl;
	}
	public get hasChat() {
		return this.isArmageddon || gamex.gameType === GameType.Boss;
	}

	public get toShowChat() {
		return gamex.toShowChat;
	}

	public get gameState() {
		return gamex.currentGameState;
	}
	public get control() {
		return settingx.now.control;
	}
	public get gameStage() {
		return gamex.gameStage;
	}
	public get playerState() {
		return gamex.playerState;
	}
	public get scoreLeft() {
		return addComma(gamex.scoreLeft, 0);
	}
	public get money() {
		return addComma(gamex.money, 0);
	}
	public get numPlayers() {
		return gamex.numPlayers;
	}
	public get numZombies() {
		return gamex.currentGameState.numZombies;
	}

	public get currentUtility() {
		return gamex.currentUtility;
	}

	public get lvl() {
		return userx.userDoc?.lvl || 1;
	}
	public get currentExp() {
		return userx.oldExp + gamex.highscore * 2;
	}
	public get hitLvlCap() {
		return this.currentExp >= maxExp;
	}

	public get lvlPercentage() {
		if (this.hitLvlCap) {
			return 1;
		}
		let i = 0;
		let nextLvlExp = levelRequirements[i];

		let exp = this.currentExp;
		while (exp >= nextLvlExp) {
			exp -= nextLvlExp;
			i++;
			nextLvlExp = levelRequirements[i];
		}
		return exp / nextLvlExp;
	}

	public get expBarStyle() {
		return {
			width: `${this.lvlPercentage * 100}%`,
			borderRadius: '10px',
		};
	}
	public get match() {
		return gamex.cpsPlayers;
	}
	public get myHp() {
		const player = gamex.currentGameState.players[gamex.currentGameState.pid];
		return player ? (player.hp / player.maxHp) * 100 : 0;
	}
	public get opponentHp() {
		return (gamex.currentGameState.opponentHpPercent / 255) * 100;
	}
	public get myLife() {
		return 3 - gamex.currentGameState.life;
	}
	public get opponentLife() {
		return 3 - gamex.currentGameState.opponentCurrentLife;
	}
	public get myHpBar() {
		return { width: this.myHp + '%' };
	}
	public get opponentHpBar() {
		return { width: this.opponentHp + '%' };
	}
	public baseUrl = process.env.BASE_URL;
	public interval: any;

	public serverCloseLabel = 'Server Closing In:';
	public countDown = '0h 0m 0s';
	public showCountDown = false;
	public vultrMaintenance = false;
	public countDownMin = '3';
	public countDownSec = ':00';
	public timeLeft = '6:00';

	public mounted() {
		if (this.match) {
			this.countDownMin = '3';
			this.countDownSec = ':00';
		}
		this.interval = setInterval(() => {
			const time = gamex.currentGameState.t;

			this.serverCloseLabel = gamex.currentGameState.armageddonStartTime
				? 'Armageddon Starting In'
				: 'Server Closing In:';
			const closeTime = gamex.currentGameState.armageddonStartTime
				? gamex.currentGameState.armageddonStartTime
				: gamex.currentGameState.closeTime;
			let tl = closeTime - time;
			if (this.isArmageddon) {
				const showCountDown =
					(!gamex.currentGameState.armageddonStarted &&
						closeTime &&
						tl < 15 * 60000 &&
						tl > -1000) ||
					false;
				if (this.showCountDown !== showCountDown) {
					this.showCountDown = showCountDown;
				}
				if (showCountDown) {
					if (tl < 0) {
						tl = 0;
					}
					const hour = Math.floor(tl / (60 * 60 * 1000));
					tl -= hour * 60 * 60000;
					const min = Math.floor(tl / 60000);
					tl -= min * 60000;
					const sec = Math.round(tl / 1000);
					const countDown = `${hour}h ${min}m ${sec}s`;
					if (this.countDown !== countDown) {
						this.countDown = countDown;
					}
					if (hour === 0 && min === 0 && sec <= 10) {
						new SoundEfx('tick').play();
					}
				}
			} else if ((this.match || this.isBoss) && tl > -1000) {
				if (tl < 0) {
					tl = 0;
				}
				const min = Math.floor(tl / 60000);
				tl -= min * 60000;
				const sec = Math.floor(tl / 1000);

				const countDownMin = min.toString();
				const countDownSec = `:${sec < 10 ? '0' : ''}${sec}`;
				if (this.countDownMin !== countDownMin) {
					this.countDownMin = countDownMin;
				}
				if (this.countDownSec !== countDownSec) {
					this.countDownSec = countDownSec;
				}
				if (min === 0 && sec <= 10 && sec > 0) {
					new SoundEfx('tick').play();
				}
			}
		}, 1000);
	}

	@Watch('gameState', { immediate: true })
	public gameStateChanged(newVal: ViewState, oldVal: ViewState) {
		if (!oldVal || newVal.vultrMaintenance !== oldVal.vultrMaintenance) {
			this.vultrMaintenance = newVal.vultrMaintenance;
		}
	}

	public onHideMini(value: boolean) {
		this.hideMini = value;
	}

	public closeChat() {
		gamex.updateToShowChat(false);
	}
	protected beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	// @Watch('control', { immediate: true })
	// public controlChanged(newVal: 'mouse' | 'touch') {}
	// @Watch('gameStage', { immediate: true })
	// public gameStageChanged(newVal: GameStage) {}
	// @Watch('playerState', { immediate: true })
	// public playerStateChanged(newVal: PlayerGameState) {}
}
