import { HazardType } from '@/game/infos/hazardInfos';
import { OreColor } from '@/game/infos/oreInfo';
import { FoodData, HazardData, OreData } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Container, Sprite } from 'pixi.js';
import Factory from '../factory';
import { ElectricHazardSymbol, FireHazardSymbol, IceHazardSymbol } from '../factory/assets/hazardMixin';
import { getWallSprite, MapAsset } from '../factory/mapAssets';
import { getParticle, Particle } from '../factory/particles';
import { LongSoundEfx } from '../sound/LongSoundEfx';


export class HazardControl {
	public static get(data: HazardData) {
		const control = HazardControl._pools[data.type].get();
		control.setData(data);
		return control;
	}
	private static _pools: Array<Pool<HazardControl>> = Array.from({ length: 5 }, (_) => new Pool(HazardControl));
	public symbol!: Container;

	public hazardId = NaN;
	public data!: HazardData;
	protected _type: HazardType = 0;

	protected electricSound = new LongSoundEfx('electricHazard');

	public setData(data: HazardData) {

		this.hazardId = data.id;
		if (!this.symbol) {
			this.symbol = data.type === HazardType.Fire ? Factory.get(FireHazardSymbol) :
				data.type === HazardType.Ice ? Factory.get(IceHazardSymbol) :
					Factory.get(ElectricHazardSymbol);
			this._type = data.type;
		}
		this.symbol.angle = data.deg;
		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.data = data;

		this.electricSound.stop();
		if (data.type === HazardType.Electric) {
			if (data.active) {
				(this.symbol as ElectricHazardSymbol).activate();
			} else {
				(this.symbol as ElectricHazardSymbol).deactivate();
			}

		}
	}

	public seen(data: HazardData, distance: number) {
		if (data.type === HazardType.Electric) {
			if (data.active) {
				(this.symbol as ElectricHazardSymbol).activate();
				this.electricSound.volume = (1300 - distance) / 1300 * 0.2;
				this.electricSound.start();
			} else {
				(this.symbol as ElectricHazardSymbol).deactivate();
				this.electricSound.stop();
			}
		}
	}
	public unseen() {
		this.electricSound.stop();
	}

	public dispose() {
		HazardControl._pools[this._type].pool(this);
		this.electricSound.stop();
	}

}
