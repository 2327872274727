import { ItemCode } from '@/game/infos/itemInfos';
import { nonReact } from '@/util/nonReact';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Sprite } from 'pixi.js';
import { getAllTimedChildren } from './helper';
import { ParticleData } from './particles';

export interface ScaledParticleData extends ParticleData {
	scaleX: number,
	scaleY: number,
}

const particles: ScaledParticleData[] = nonReact([]);
const particlePools: Sprite[][] = nonReact([]);

export interface ItemSprite extends Sprite {
	spriteId: ItemCode,
}
export function registerItemSprites(symbol: MovieClip) {

	const list = particles;
	const pools = particlePools;
	const timeline = (symbol as any)._timelines;
	const children = getAllTimedChildren(symbol);
	for (let i = 0; i < children.length; i++) {
		const child = children[i];
		if (i === 0) { child.x = child.y = 0; child.scale.x = child.scale.y = 1; }
		const pos = timeline[i]?._currentProps || { x: 0, y: 0, sx: 1, sy: 1 };
		const rect = child.getLocalBounds();
		const scaleX = child.scale.x * (pos.sx || 1);
		const scaleY = child.scale.y * (pos.sy || 1);
		const u = -(child.x + pos.x || 0) / rect.width / scaleX;
		const v = -(child.y + pos.y || 0) / rect.height / scaleY;
		const texture = (child as Sprite).texture;
		list[i] = { u, v, texture, scaleX, scaleY };
		pools[i] = [];
	}
	// console.log(symbol);
	symbol.destroy();
}
export function getItemSprite(index: ItemCode, x = 0, y = 0) {
	index -= 1;
	const pool = particlePools[index];
	let symbol = pool.shift();

	const data = particles[index];
	if (!symbol) {
		symbol = Sprite.from(data.texture);
	}
	symbol.anchor.set(data.u, data.v);
	symbol.x = x;
	symbol.y = y;
	symbol.scale.set(data.scaleX, data.scaleY);
	(symbol as ItemSprite).spriteId = index;

	return symbol;
}
export function disposeItemSprite(sprite: Sprite) {
	particlePools[(sprite as ItemSprite).spriteId].push(sprite);
}
