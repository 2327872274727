import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsBoxPunch, MiscDataSsWhammyWave } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';
import { MovieClip } from 'pixi-animate';


export class SsBoxPunchSymbol extends MovieClip implements MiscSymbol {


	public data: MiscDataSsBoxPunch | null = null;
	public pt = 0;
	public symbol: any | null = null;


	public init() {
	}
	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.symbol) {
			this.symbol.handR.visible = true;
			this.symbol.handL.visible = true;
			this.symbol = null;
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
		Factory.pool(SsBoxPunchSymbol, this);
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsBoxPunch;

		this.x = data.x;
		this.y = data.y;

		this.angle = data.d;

		if (!this.data || this.data.s !== data.s) {
			this.scale.set(1, data.l ? 1 : -1);
			if (data.s) {
				this.gotoAndPlay('action');
			} else {
				this.gotoAndStop('idle');
			}
		}
		const player = room.allPlayers[data.pid];
		if (!this.symbol && player) {
			const symbol: any = this.symbol = player.symbol.body.children[0];

			const resetTail = () => {
				symbol.off('reset', resetTail);
				symbol.handR.visible = true;
				symbol.handL.visible = true;
			};
			symbol.on('reset', resetTail);
			symbol.handR.visible = false;
			symbol.handL.visible = false;
		}
		// if (data.ts) {
		// 	this.area.visible = true;
		// 	this.visible = false;
		// 	this.area.scale.set(data.ts);
		// } else {
		// 	this.area.visible = false;
		// 	this.visible = true;
		// 	this.scale.set(data.sc);
		// 	const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		// 	this.sound.volume = volume * .2;
		// 	this.sound.start();
		// }

		this.data = data;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}
}
