import { decodeDecoSet, DecoSet, encodeDecosSet, randomDecos } from '@/game/infos/decorativeInfos';
import { Decoratives, DecoSymbol } from '@/components/ui/mainMenu/tools/decoEditor/decorative';
import Factory from '../factory';
import { PlayerSymbol, FishAction } from '../factory/assets/playerMixin';
import { Queuer } from '@/util/queuer';
import { allTextureLoaded, cloneSprite, rgbToInteger, tint } from '../factory/helper';
import { Container, Filter, Graphics, ParticleContainer, Sprite } from 'pixi.js';
import { Preset } from '@/game/infos/preset';
import { Dictionary } from '@/util/dictionary';
import { StababControl } from './stababControl';
import { Pool } from '@/util/pool';
import { wait } from '@/util/wait';
import { PlayerLabelSymbol } from '../factory/assets/playerLabelMixin';
import { Ease, Tween } from '@/util/tweents';
import { disposeParticle, getParticle, Particle } from '../factory/particles';
import { PlayerData } from '@/game/multithread/viewState';
import random from '@/util/random';
import settingx from '@/store/modules/settingx';
import { ParalyzedEffect, BlindedEffect, BurningEffect, FrozenEffect, PuffEffect, ZompkinEffect, BrokenSword, WrappedEffect } from '../factory/assets/hazardMixin';
import { LongSoundEfx } from '../sound/LongSoundEfx';
import { Rotate } from '@/util/rotate';
import { SoundEfx } from '../sound/SoundEfx';
import { Common } from 'matter-js';
import { RoomControl } from './roomControl';
import { FishType } from '@/game/infos/fishInfos';
import { BodySlamEffect, DragonTridentSymbol, EvolveEffect, OnifuguSpikes } from '../factory/assets/playerModel';
import { isQuestTime, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';
import tipx from '@/store/modules/tipx';
import { TipId } from '@/game/infos/tipInfos';
import { PlayerType } from '@/game/infos/enums';
import { Global } from '@/store/globalz';

const santaHatDecoSet: DecoSet = { decos: { 0: { decoName: 'SantaHat', decoId: '0', x: -40.4, y: -45.25, scale: 0.371, angle: -37, flipX: false, flipY: false } }, body: null, top: ['0'], bottom: [] };
const encodedSantaHatDecoSet = encodeDecosSet(santaHatDecoSet);
const cnyDecoSets: DecoSet[] = [{
	decos: {
		0: { decoName: 'BunCover', decoId: '0', x: 34, y: -44, scale: 0.435, angle: 40, flipX: false, flipY: false },
		1: { decoName: 'BunCover', decoId: '1', x: -34, y: -44, scale: 0.435, angle: -40, flipX: true, flipY: false },
	}, body: null, top: ['0', '1'], bottom: [],
},
{ decos: { 0: { decoName: 'GuapiCap', decoId: '0', x: -35, y: -50, scale: 0.327, angle: -37, flipX: false, flipY: false } }, body: null, top: ['0'], bottom: [] },
];

const encodedCnysDecoSets = cnyDecoSets.map((d) => encodeDecosSet(d));
export class PlayerControl {

	public static get(data: PlayerData, decoSet: string, alliance: 'me' | 'team' | 'enemy') {
		const playerControl = PlayerControl._pool.get();
		playerControl.playerId = data.id;
		playerControl.alliance = alliance;
		// if (alliance === 'me') {
		// 	data.skinGroup = 1;
		// }
		playerControl.toShowSkin = !((settingx.now.hideEnemySkin && alliance === 'enemy') ||
			(settingx.now.hideTeamSkin && alliance === 'team') ||
			(settingx.now.hideMySkin && alliance === 'me'));

		playerControl.symbol.setFishType(data.fishType, playerControl.toShowSkin ? data.skinGroup : 0);
		playerControl.label.setData(data);
		playerControl.decoSet = decoSet;
		playerControl.renderedDecos = false;

		playerControl.symbol.miniSpike.visible = data.fishType <= FishType.Dragon;
		if (data.playerType === PlayerType.Mob) {
			if (isQuestTime(TimeLimitedQuestCode.Xmas2022)) {
				playerControl.decoSet = encodedSantaHatDecoSet;
			} else if (isQuestTime(TimeLimitedQuestCode.Cny2024)) {
				playerControl.decoSet = encodedCnysDecoSets[data.id % 2];
			}
		}

		// const decos = random.choose(randomDecos);
		// playerControl.requestRenderDecoSet(decos);
		if (
			!(Global.stageControl.symbol.parent?.parent) ||
			(settingx.now.hideEnemyDecos && alliance === 'enemy') ||
			(settingx.now.hideTeamDecos && alliance === 'team') ||
			(settingx.now.hideMyDecos && alliance === 'me')
		) {
			playerControl.hideDecos();
		} else {
			playerControl.showDecos();
		}
		return playerControl;
	}
	private static _pool: Pool<PlayerControl> = new Pool(PlayerControl);


	public playerId = NaN;
	public symbol = PlayerSymbol.get();
	public label = Factory.get(PlayerLabelSymbol);
	public shadow = getParticle(Particle.Shadow);
	public alliance: 'me' | 'team' | 'enemy' = 'enemy';

	public stababs: Dictionary<StababControl> = {};
	public data: PlayerData | null = null;
	public teamCircle: Sprite | null = null;

	public boostSound = new LongSoundEfx('boostBubbles');
	public stunSound = new LongSoundEfx('stun');

	public particleContainer = new ParticleContainer(30, { vertices: false, position: true, rotation: false, uvs: false, tint: true });

	public lastStabTime = 0;

	public stababControl: StababControl | null = null;

	public toShowDecos = false;
	public renderedDecos = false;
	public decoSet = '';
	public toShowSkin = true;

	public paralyzedEffect: ParalyzedEffect | null = null;
	public burningEffect: BurningEffect | null = null;
	public frozenEffect: FrozenEffect | null = null;
	public blindEffect: BlindedEffect | null = null;
	public zompkinEffect: ZompkinEffect | null = null;
	public wrappedEffect: WrappedEffect | null = null;
	public puffEffect: PuffEffect | null = null;
	public brokenSword: BrokenSword | null = null;
	public oniFuguSpikes: OnifuguSpikes | null = null;
	public dragonBlades: DragonTridentSymbol | null = null;
	public bodySlamEffect: BodySlamEffect | null = null;
	public daredevilEffect: ParalyzedEffect | null = null;

	public waterLevel = 1;
	public spearLvl = 0;
	public inDisguise = false;

	protected lastParticleTime = 0;
	protected bottomCover = new Graphics();

	constructor() {
		this.shadow.angle = 45;
		this.symbol.addChild(this.particleContainer);
		this.bottomCover.beginFill(0x3a74a3);
		this.bottomCover.drawCircle(0, 0, 45);
		// this.shadow.alpha = 0.5;
		// this.symbol.filters=[new GlowFilter() as any]
	}

	public showDecos() {
		this.toShowDecos = true;
		this.symbol.topContainer.visible = true;
		this.symbol.bottomContainer.visible = true;
		if (this.stababControl) {
			this.stababControl.fishSymbol.topContainer.visible = true;
			this.stababControl.fishSymbol.bottomContainer.visible = true;
		}
		if (!this.renderedDecos) {
			const decoSet = decodeDecoSet(this.decoSet);
			this.requestRenderDecoSet(decoSet);
		}
	}

	public hideDecos() {
		this.toShowDecos = false;
		this.symbol.topContainer.visible = false;
		this.symbol.bottomContainer.visible = false;
		if (this.stababControl) {
			this.stababControl.fishSymbol.topContainer.visible = false;
			this.stababControl.fishSymbol.bottomContainer.visible = false;
		}
	}

	public async requestRenderDecoSet(decoSet: DecoSet | null) {
		const pid = this.playerId;
		Queuer.queue('decoQueue', async () => {
			if (pid !== this.playerId || !this.toShowDecos || this.renderedDecos) { return; }
			let { top, bottom } = Decoratives.generateDecoContainers(decoSet);
			await Promise.all([allTextureLoaded(top), allTextureLoaded(bottom)]);
			if (pid !== this.playerId || !this.toShowDecos || this.renderedDecos) { return; }
			const topLen = top.children.length;
			const bottomLen = bottom.children.length;
			if (topLen > 1) {
				top.cacheAsBitmap = true;
				wait(100).then(() => {
					for (const child of top.children) {
						const symbol = child as DecoSymbol;
						Decoratives.pool(symbol.decoName, symbol);
					}
					top.removeChildren();
				});
			} else if (topLen === 1) {
				top = top.children[0] as Container;
			}
			if (bottomLen > 1) {
				bottom.addChild(this.bottomCover);
				bottom.cacheAsBitmap = true;
				wait(100).then(() => {
					bottom.removeChildAt(bottomLen);
					for (const child of bottom.children) {
						const symbol = child as DecoSymbol;
						Decoratives.pool(symbol.decoName, symbol);
					}
					bottom.removeChildren();
				});
			} else if (bottomLen === 1) {
				bottom = bottom.children[0] as Container;
			}
			// clear any current decos in symbol
			this.symbol.disposeDecos();
			if (topLen >= 1) {
				this.symbol.topContainer.addChild(top);
			}
			if (bottomLen >= 1) {
				this.symbol.bottomContainer.addChild(bottom);
			}
			this.renderedDecos = true;
		});

	}

	public cloneDecoToStababSymbol(stababSymbol: PlayerSymbol) {
		const top = this.symbol.topContainer.children[0];
		if (top) {
			const target = top as Container;
			const sprite = (target.cacheAsBitmap) ? (target as any)._cacheData.sprite : target.children[0];
			const clone = cloneSprite(sprite);
			const container = new Container();
			container.setTransform(target.x, target.y, target.scale.x, target.scale.y, target.angle, target.skew.x, target.skew.y, target.pivot.x, target.pivot.y);
			if (!target.cacheAsBitmap) {
				clone.setTransform(sprite.x, sprite.y, sprite.scale.x, sprite.scale.y, sprite.angle, sprite.skew.x, sprite.skew.y, sprite.pivot.x, sprite.pivot.y);
			}
			container.addChild(clone);
			stababSymbol.topContainer.removeChildren();
			stababSymbol.topContainer.addChild(container);
		}
		const bottom = this.symbol.bottomContainer.children[0];
		if (bottom) {
			const target = bottom as Container;
			const sprite = (target.cacheAsBitmap) ? (target as any)._cacheData.sprite : target.children[0];
			const clone = cloneSprite(sprite);
			const container = new Container();
			container.setTransform(target.x, target.y, target.scale.x, target.scale.y, target.angle, target.skew.x, target.skew.y, target.pivot.x, target.pivot.y);
			if (!target.cacheAsBitmap) {
				clone.setTransform(sprite.x, sprite.y, sprite.scale.x, sprite.scale.y, sprite.angle, sprite.skew.x, sprite.skew.y, sprite.pivot.x, sprite.pivot.y);
			}
			container.addChild(clone);
			stababSymbol.bottomContainer.removeChildren();
			stababSymbol.bottomContainer.addChild(container);
		}
	}

	public puff() {
		if (!this.puffEffect) {
			this.puffEffect = Factory.get(PuffEffect);
			this.puffEffect.playerControl = this;
			this.symbol.addChild(this.puffEffect);
		}
		this.puffEffect.gotoAndPlay(0);

	}
	public swordBreak(scale: number) {
		if (!this.brokenSword) {
			this.brokenSword = Factory.get(BrokenSword);
			this.brokenSword.playerControl = this;
			this.symbol.addChild(this.brokenSword);
			this.brokenSword.x = this.symbol.spear.x;
			this.brokenSword.angle = this.symbol.spear.angle;
			this.brokenSword.y = this.symbol.spear.y;
			this.brokenSword.scale.set(scale);
		}
		this.brokenSword.gotoAndPlay(0);

	}

	public update(data: PlayerData, players: Dictionary<PlayerControl>, ct: number, vx: number, vy: number, roomControl: RoomControl) {
		this.symbol.x = this.shadow.x = data.x;
		this.symbol.y = this.shadow.y = data.y;
		this.symbol.setAngle(data.deg + 90);
		this.symbol.setBodyScale(data.bodyRadius / Preset.PLAYER_RADIUS);
		this.shadow.scale.set(data.bodyRadius / Preset.PLAYER_RADIUS);

		const spearLvl = (Math.floor(data.spearLvl) - Preset.SPEAR_MIN_LEVEL) || 0;

		const stabD = ct - this.lastStabTime;
		if (this.bodySlamEffect) {
			this.symbol.setTint(0xffff66);
		} else if (stabD < 200) {
			const p = 0.4 + 0.6 * stabD / 200;
			const g = (data.burn ? 0.75 : 1) * p * 255;
			const b = (data.burn ? 0.5 : 1) * p * 255;
			this.symbol.setTint(
				rgbToInteger(
					255,
					g,
					b,
				));
			// if (this.diamondfishHarden) {
			// 	tint(this.diamondfishHarden,
			// 		rgbToInteger(
			// 			255,
			// 			g,
			// 			b,
			// 		));
			// }

		} else {
			this.symbol.setTint(data.burn ? 0xFFBF7D : 0xffffff);
			// if (this.diamondfishHarden) {
			// 	tint(this.diamondfishHarden, data.burn ? 0xFFBF7D : 0xffffff);
			// }

		}
		if (data.stun > 0) {
			this.stunSound.volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
			this.stunSound.start();
			if (this.alliance === 'me' && this.data && this.data.stun === 0) {
				roomControl.cameraControl.swing(30, 3000);
			}
		} else {
			this.stunSound.stop();
		}
		this.symbol.setIsStunned(data.stun > 0);
		this.symbol.setSpear(data.spearType, spearLvl, data.spearHeight);
		if (this.alliance === 'me') {

			if (this.data && this.data.spearType !== data.spearType) {
				roomControl.cameraControl.swing(1, 500);
			}
			if (data.hasSpear && spearLvl !== this.spearLvl) {
				this.playEvolveSpear();
			}
		}
		this.spearLvl = spearLvl;

		if (this.data) {

			if (this.data.jobuff > data.jobuff) {
				if (this.data.jobuff >= 7) {
					this.playEvolveSpear();
				}
				this.healParticle(0xffff00);
				this.healParticle(0xffff00);
				this.healParticle(0x00ff00);
			}
			if (this.data.hasSpear !== data.hasSpear) {
				this.symbol.miniSpike.visible = !data.hasSpear;
				this.symbol.spearContainer.visible = this.symbol.spearFrontContainer.visible = true;
				if (data.hasSpear) {
					Tween.get(this.symbol.spearContainer.scale, { override: true }).to({ x: 1, y: 1 }, 200);
					Tween.get(this.symbol.spearFrontContainer.scale, { override: true }).to({ x: 1, y: 1 }, 200);
					if (data.assassinLvl) {
						const bling = getParticle(Particle.BigBling);
						bling.scale.set(0.1);
						bling.alpha = 1;
						bling.angle = 0;
						bling.x = 0;
						bling.y = -45;
						bling.tint = 0;
						this.symbol.addChild(bling);
						Tween.get(bling.scale).to({ x: 1 + data.assassinLvl, y: 1 + data.assassinLvl }, 200)
							.to({ x: .01, y: .01 }, 300)
							.call(() => {
								this.symbol.removeChild(bling);
								disposeParticle(Particle.BigBling, bling);
							});

						Tween.get(bling).to({ angle: 200, y: -45 + -data.spearHeight / this.symbol.scale.x }, 200)
							.to({ angle: 300 }, 300, Ease.circOut);

					}
				} else {
					// using override will cause bug
					Tween.get(this.symbol.spearContainer.scale, { override: true }).to({ x: 0.01, y: 0.01 }, 200).call(this.hideSpear.bind(this));
					Tween.get(this.symbol.spearFrontContainer.scale, { override: true }).to({ x: 0.01, y: 0.01 }, 200);
				}
			}
		} else {
			this.symbol.miniSpike.visible = data.fishType <= FishType.Dragon && !data.hasSpear;
			this.symbol.spearContainer.visible = this.symbol.spearFrontContainer.visible = data.hasSpear;
			this.symbol.spearContainer.scale.set(data.hasSpear ? 1 : 0.01);
			this.symbol.spearFrontContainer.scale.set(data.hasSpear ? 1 : 0.01);
			if (data.playerType === PlayerType.Nemesis) {
				this.symbol.brightness = .5;
				this.symbol.showEvilEyes();
			} else {
				this.symbol.brightness = 1;
			}
		}

		if (data.burn) {
			if (!this.burningEffect) {
				this.burningEffect = Factory.get(BurningEffect);
				this.symbol.addChild(this.burningEffect);
				const volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300 * 0.3;
				const sfx = new SoundEfx('burn');
				sfx.play({ volume });
			}
		} else {
			if (this.burningEffect) {
				this.symbol.removeChild(this.burningEffect);
				this.burningEffect.angle = 0;
				Factory.pool(BurningEffect, this.burningEffect);
				this.burningEffect = null;
				this.puff();
			}
		}
		if (data.frozen) {
			if (!this.frozenEffect) {
				this.frozenEffect = Factory.get(FrozenEffect);
				this.symbol.addChild(this.frozenEffect);
				const volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
				const sfx = new SoundEfx('freeze');
				sfx.play({ volume });
			}
		} else {
			if (this.frozenEffect) {
				this.symbol.removeChild(this.frozenEffect);
				Factory.pool(FrozenEffect, this.frozenEffect);
				this.frozenEffect = null;
				this.puff();
			}
		}
		if (data.blind) {
			if (!this.blindEffect) {
				this.blindEffect = Factory.get(BlindedEffect);
				this.symbol.addChild(this.blindEffect);
			}
		} else {
			if (this.blindEffect) {
				this.symbol.removeChild(this.blindEffect);
				Factory.pool(BlindedEffect, this.blindEffect);
				this.blindEffect = null;
			}
		}
		if (data.zompkin) {
			if (!this.zompkinEffect) {
				this.zompkinEffect = Factory.get(ZompkinEffect);
				this.symbol.addChild(this.zompkinEffect);
				if (this.data && !this.data.zompkin) {
					this.zompkinEffect.gotoAndPlay(0);
					const volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
					const sfx = new SoundEfx('zombieCrunch');
					sfx.play({ volume });
				} else {
					this.zompkinEffect.gotoAndStop('idle');
				}
			}
		} else {
			if (this.zompkinEffect) {
				this.symbol.removeChild(this.zompkinEffect);
				Factory.pool(ZompkinEffect, this.zompkinEffect);
				this.zompkinEffect = null;
			}
		}
		if (data.paralyzed) {
			if (!this.paralyzedEffect) {
				this.paralyzedEffect = Factory.get(ParalyzedEffect);
				this.symbol.addChild(this.paralyzedEffect);
				const volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
				const sfx = new SoundEfx('electricShock');
				sfx.play({ volume });
			}
		} else {
			if (this.paralyzedEffect) {
				this.symbol.removeChild(this.paralyzedEffect);
				Factory.pool(ParalyzedEffect, this.paralyzedEffect);
				this.paralyzedEffect = null;
			}
		}
		if (data.isWrapped) {
			if (!this.wrappedEffect) {
				this.wrappedEffect = Factory.get(WrappedEffect);
				this.symbol.addChild(this.wrappedEffect);
				// const volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
				// const sfx = new SoundEfx('freeze');
				// sfx.play({ volume });
			}
		} else {
			if (this.wrappedEffect) {
				this.symbol.removeChild(this.wrappedEffect);
				Factory.pool(WrappedEffect, this.wrappedEffect);
				this.wrappedEffect = null;
				this.puff();
			}
		}
		if (data.daredevilLvl && data.hp < data.maxHp * .25) {
			if (!this.daredevilEffect) {
				this.daredevilEffect = Factory.get(ParalyzedEffect);
				tint(this.daredevilEffect, 0);
				this.daredevilEffect.scale.set(1.5);
				this.daredevilEffect.alpha = -0.1 + data.daredevilLvl * 0.3;
				this.symbol.addChild(this.daredevilEffect);
			}
		} else {
			if (this.daredevilEffect) {
				tint(this.daredevilEffect, 0xffffff);
				this.daredevilEffect.scale.set(1);
				this.daredevilEffect.alpha = 1;
				this.symbol.removeChild(this.daredevilEffect);
				Factory.pool(ParalyzedEffect, this.daredevilEffect);
				this.daredevilEffect = null;
			}
		}

		if (this.data && !this.data.spearElectrified && data.spearElectrified) {
			const volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
			const sfx = new SoundEfx('electricCharge');
			sfx.play({ volume });
		}

		// if (tid === data.tid) {
		// 	const teamIndex = teamMembers.indexOf(data.id);
		// 	if (!this.filter) {
		// 		// this.filter = new GlowFilter({
		// 		// 	outerStrength: 2,
		// 		// 	color: 0xff0000,
		// 		// 	knockout: false,
		// 		// 	quality: 10,
		// 		// });
		// 		this.filter = new OutlineFilter(4) as any;
		// 	}
		// 	this.filter!.color = settingx.now[`color${teamIndex + 1}`];

		// 	// if (!this.teamCircle) {
		// 	// 	this.teamCircle = getParticle(Particle.TeamCircle);
		// 	// 	this.teamCircle.alpha = 0.65;
		// 	// }
		// 	// this.teamCircle.angle = -data.d.deg;
		// 	// this.teamCircle.tint = settingx.now[`color${teamIndex + 1}`];
		// 	// this.symbol.addChildAt(this.teamCircle, 0);
		// 	if (!this.symbol.body.filters) { this.symbol.body.filters = []; }
		// 	this.symbol.body.filters[0] = this.filter! as any;
		// }

		if (!this.data ||
			Math.ceil(this.data.spd) !== Math.ceil(data.spd) ||
			this.data.skill1 !== data.skill1 ||
			this.data.skill2 !== data.skill2
		) {
			const action: FishAction = data.skill1 ? 'skill1'
				: data.spd !== 0 ? 'move' : 'idle';
			this.symbol.setAction(action);

			if (data.fishType === FishType.AlligatorSnappingTurtle ? data.skill2 : data.skill1) {
				this.boostSound.start();
			} else {
				this.boostSound.stop();
			}
			if (data.fishType === FishType.Onifugu) {

				if (data.skill2) {
					if (!this.oniFuguSpikes) {

						this.oniFuguSpikes = Factory.get(OnifuguSpikes);
						this.symbol.addChildAt(this.oniFuguSpikes, this.symbol.getChildIndex(this.symbol.topContainer));
						if (this.data?.playerType === PlayerType.Nemesis) {
							tint(this.oniFuguSpikes, 0x7F7F7F);
						}
					}
				} else {
					if (this.oniFuguSpikes) {
						tint(this.oniFuguSpikes, 0xffffff);
						this.symbol.removeChild(this.oniFuguSpikes);
						Factory.pool(OnifuguSpikes, this.oniFuguSpikes);
						this.oniFuguSpikes = null;
					}
				}
			} else if (data.fishType === FishType.Jomama) {

				if (data.skill2) {
					if (!this.bodySlamEffect) {

						this.bodySlamEffect = Factory.get(BodySlamEffect);
						this.symbol.addChild(this.bodySlamEffect);
					}
				} else {
					if (this.bodySlamEffect) {
						this.symbol.removeChild(this.bodySlamEffect);
						Factory.pool(BodySlamEffect, this.bodySlamEffect);
						this.bodySlamEffect = null;
					}
				}
			} else if (data.fishType === FishType.MantisShrimp) {

				if (this.data && data.skill2 && !this.data.skill2) {
					(this.symbol.body.children[0] as any)?.legs?.gotoAndPlay(1);
				}
			} else if (data.fishType === FishType.Cuttlefish) {

				if (data.skill2) {
					this.symbol.alpha = 0.3;
					this.inDisguise = true;
					if (this.data && !this.data.skill2) {
						this.puff();
					}
				} else {
					this.symbol.alpha = 1;
					this.inDisguise = false;
				}
			} else if (data.fishType === FishType.Dragon) {

				if (data.skill2) {
					if (!this.dragonBlades) {
						this.dragonBlades = Factory.get(DragonTridentSymbol);
						this.symbol.spearContainer.addChildAt(this.dragonBlades, 0);
						this.dragonBlades.angle = -90;
					}
				} else {
					if (this.dragonBlades) {
						this.symbol.spearContainer.removeChild(this.dragonBlades);
						Factory.pool(DragonTridentSymbol, this.dragonBlades);
						this.dragonBlades = null;
					}
				}
			}
		}
		if (data.skill1) {
			this.boostSound.volume = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300 * .2;

			if (this.alliance === 'me' && tipx.currentShowingTipId === TipId.LeftClick && !tipx.tipsRead[TipId.LeftClick]) {
				tipx.achieveTip(TipId.LeftClick);
			}
		}
		if (data.skill2) {
			if (this.alliance === 'me' && tipx.currentShowingTipId === TipId.RightClick && !tipx.tipsRead[TipId.RightClick]) {
				tipx.achieveTip(TipId.RightClick);
			}
			if (this.dragonBlades) {
				this.dragonBlades.y = -18 + -data.spearHeight / this.symbol.scale.x;
				this.dragonBlades.scale.set((1 / 1.3) * 1.2);
			}
		}

		this.particleContainer.angle = -data.deg - 90;
		if (this.burningEffect) {
			this.burningEffect.angle = -data.deg - 90;
		}
		// set stababs
		const stababs: Dictionary<StababControl> = {};
		const stababDatas = Object.values(data.stababs);
		if (this.alliance === 'me' && stababDatas.length > 0) {
			if (tipx.currentShowingTipId === TipId.Hp && !tipx.tipsRead[TipId.Hp]) {
				tipx.achieveTip(TipId.Hp);
			}
			if (!tipx.tipsRead[TipId.Crush] && !tipx.tipsToShow[TipId.Crush]) {
				tipx.showTip(TipId.Crush);
			}
		}
		for (const stababData of stababDatas) {
			const stabab = this.stababs[stababData.id] || StababControl.get(
				stababData,
				players[stababData.pid],
			);
			stabab.update(stababData, data.spearTemparature, data.spearElectrified);
			stababs[stababData.id] = stabab;
			delete this.stababs[stababData.id];
			this.symbol.stababContainer.addChild(stabab.container);
			if (stabab.burningEffect) {
				stabab.burningEffect.angle = -data.deg - 90 - stabab.container.angle;
				if (this.alliance === 'me' && tipx.currentShowingTipId === TipId.Hazard && !tipx.tipsRead[TipId.Hazard]) {
					tipx.achieveTip(TipId.Hazard);
				}
			}
		}

		for (const stabab of Object.values(this.stababs)) {
			this.symbol.stababContainer.removeChild(stabab.container);
			stabab.dispose();
			if (this.alliance === 'me' && tipx.currentShowingTipId === TipId.Crush && !tipx.tipsRead[TipId.Crush] && !players[stabab.playerId]) {
				tipx.achieveTip(TipId.Crush);
			}
		}
		this.stababs = stababs;
		if (!this.inDisguise) {

			const blink = data.blink - ct;
			if (blink > 0) {
				const factor = blink > 990 ? 30 : blink > 540 ? 60 : 120;
				const step = Math.ceil(blink / factor);
				const hide = step % 2 === 0;
				this.symbol.alpha = hide ? 0.5 : 1;
			} else {
				this.symbol.alpha = 1;
			}
		}

		const waterLvl = (data.y < -data.bodyRadius / 2 ? -1 : data.y > data.bodyRadius / 2 ? 1 : 0);

		if (this.waterLevel !== waterLvl) {
			if (this.waterLevel === 1) {
				const dist = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
				let speed = (this.data?.y || data.y) - data.y;
				roomControl.splashParticles(data.x, data.bodyRadius, speed);
				if (speed > 10) { speed = 10; }
				speed /= 5;
				new SoundEfx('splashOut').play({ volume: dist * speed });
			} else if (this.waterLevel === -1) {
				const dist = (1300 - Rotate.dist(data.x, data.y, vx, vy)) / 1300;
				let speed = data.y - (this.data?.y || data.y);
				roomControl.splashParticles(data.x, data.bodyRadius, speed);
				if (speed > 10) { speed = 10; }
				speed /= 5;
				new SoundEfx('splashIn').play({ volume: dist * speed });
			}
			this.waterLevel = waterLvl;
		}
		if (data.isHealing && ct > this.lastParticleTime + 120) {
			this.healParticle(0x00ff00);
			this.lastParticleTime = ct;
		}
		this.data = data;
	}

	public disposeDecos() {

	}

	public playEvolveSpear() {

		const evolveEffect = Factory.get(EvolveEffect);
		this.symbol.spearFront.addChild(evolveEffect);
		evolveEffect.gotoAndPlay(0);
		new SoundEfx('evolveEffect').play({ volume: .5 });
	}

	public hide() {
		this.stunSound.stop();
		this.boostSound.stop();
		this.waterLevel = 0;
	}

	public dispose() {
		this.lastStabTime = 0;
		this.data = null;
		if (this.stababControl && this.stababControl.player === this) {
			this.stababControl.player = null;
		}
		this.stababControl = null;
		// if (this.teamCircle) {
		// 	this.symbol.removeChild(this.teamCircle);
		// 	disposeParticle(Particle.TeamCircle, this.teamCircle);
		// 	this.teamCircle = null;
		// }
		// if (this.symbol.body.filters) {
		// 	this.symbol.body.filters = [];
		// }
		this.waterLevel = 1;
		this.spearLvl = 0;

		if (this.frozenEffect) {
			this.symbol.removeChild(this.frozenEffect);
			Factory.pool(FrozenEffect, this.frozenEffect);
			this.frozenEffect = null;
		}
		if (this.blindEffect) {
			this.symbol.removeChild(this.blindEffect);
			Factory.pool(BlindedEffect, this.blindEffect);
			this.blindEffect = null;
		}
		if (this.zompkinEffect) {
			this.symbol.removeChild(this.zompkinEffect);
			Factory.pool(ZompkinEffect, this.zompkinEffect);
			this.zompkinEffect = null;
		}
		if (this.burningEffect) {
			this.symbol.removeChild(this.burningEffect);
			this.burningEffect.angle = 0;
			Factory.pool(BurningEffect, this.burningEffect);
			this.burningEffect = null;
		}
		if (this.puffEffect) {
			this.puffEffect.dispose();
		}
		if (this.oniFuguSpikes) {
			this.symbol.removeChild(this.oniFuguSpikes);
			Factory.pool(OnifuguSpikes, this.oniFuguSpikes);
			tint(this.oniFuguSpikes, 0xffffff);
			this.oniFuguSpikes = null;
		}
		if (this.dragonBlades) {
			this.symbol.spearContainer.removeChild(this.dragonBlades);
			Factory.pool(DragonTridentSymbol, this.dragonBlades);
			this.dragonBlades = null;
		}
		if (this.bodySlamEffect) {
			this.symbol.removeChild(this.bodySlamEffect);
			Factory.pool(BodySlamEffect, this.bodySlamEffect);
			this.bodySlamEffect = null;
		}
		if (this.paralyzedEffect) {
			this.symbol.removeChild(this.paralyzedEffect);
			Factory.pool(ParalyzedEffect, this.paralyzedEffect);
			this.paralyzedEffect = null;
		}
		if (this.daredevilEffect) {
			tint(this.daredevilEffect, 0xffffff);
			this.daredevilEffect.scale.set(1);
			this.daredevilEffect.alpha = 1;
			this.symbol.removeChild(this.daredevilEffect);
			Factory.pool(ParalyzedEffect, this.daredevilEffect);
			this.daredevilEffect = null;
		}
		if (this.wrappedEffect) {
			this.symbol.removeChild(this.wrappedEffect);
			Factory.pool(WrappedEffect, this.wrappedEffect);
			this.wrappedEffect = null;
		}

		this.symbol.disposeDecos();
		this.symbol.reset();
		for (const key in this.stababs) {
			if (Object.prototype.hasOwnProperty.call(this.stababs, key)) {
				const stabab = this.stababs[key];
				stabab.dispose();
				delete (this.stababs[key]);
			}
		}
		this.symbol.stababContainer.removeChildren();
		this.boostSound.stop();
		this.stunSound.stop();

		PlayerControl._pool.pool(this);
	}

	public healParticle(color: number, particleType = Particle.Plus) {
		const particle = getParticle(particleType);
		particle.tint = color;
		const posRad = Math.random() * Math.PI * 2;
		const posDist = Math.random() * 50;
		const pos = Rotate.move(posRad, posDist);
		particle.x = pos.x;
		particle.y = pos.y;
		particle.alpha = 0.75;
		particle.scale.set(Math.random() * 0.2 + 0.1);
		const targetY = Math.random() * 50 + 30;
		Tween.get(particle).to({ x: pos.x, y: pos.y - targetY, alpha: 0 }, 800)
			.call(() => {
				if (particle.parent) {
					particle.parent.removeChild(particle);
				}
				particle.x = 0; particle.y = 0;
				particle.scale.set(1);
				particle.alpha = 1;
				particle.tint = 0xffffff;
				disposeParticle(particleType, particle);
			});
		this.particleContainer.addChild(particle);
	}

	protected hideSpear() {

		this.symbol.spearFrontContainer.visible = this.symbol.spearContainer.visible = false;
	}
}
