import { ordinalSuffix } from '@/util/number';
import { ItemCode } from './itemInfos';
import { Quest, QuestStep, QuestType, UnlockChallengeType, UnlockSteps } from './unlockModels';

export enum WeeklyQuestCode {
	StabHappy,
	Login,
	Score,
	BuyWeapon,
	Mine,
	Treasure,
	Craft,
	Armageddon,
	Highscore,
	Gacha,
	Purchase,
	PlaySf1,
	LoginSf1,
}

export const weeklyQuests: Quest[] = [];

function setQuest(questTitle: string, questSteps: QuestStep[], isSf1 = false) {
	const missionCode = questSteps[0].challenges[0].unlockId;
	for (let i = 0; i < questSteps.length; i++) {
		const req = questSteps[i].challenges;
		for (let k = 0; k < req.length; k++) {
			const cha = req[k];
			cha.isMaster = false;
			cha.step = i;
			cha.index = k;
			cha.unlockId = missionCode;
		}
	}
	weeklyQuests[missionCode] = {
		code: missionCode,
		title: questTitle,
		type: QuestType.Weekly,
		steps: questSteps,
		isSf1,
	};
}

let steps: QuestStep[];
const index = 0;
let unlockId;
let title;
// let rewards: ItemCodeAmountPair[];
// let numbers: number[];
let goals: number[];
let rewards: number[];
unlockId = WeeklyQuestCode.StabHappy;
title = 'Happiness is One Stab away!';
steps = [];
goals = [10, 100, 500, 1000, 2000];
for (let i = 0; i < goals.length; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Money, amount: goals[i] * 5 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Accumulate,
					goal: goals[i],
					description: `Stab ${goals[i]} enemies`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = WeeklyQuestCode.Score;
title = 'Black Pearls for your effort!';
steps = [];
goals = [1000, 2000, 5000, 10000, 20000, 50000, 75000, 100000];
rewards = [10, 10, 10, 10, 15, 15, 15, 15];
for (let i = 0; i < goals.length; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: rewards[i] }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Accumulate,
					goal: goals[i],
					description: `Accumulate ${goals[i]} points.`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = WeeklyQuestCode.Highscore;
title = 'Good Game Well Done!';
steps = [];
goals = [5000, 10000, 50000, 100000];
rewards = [10, 15, 15, 20];
for (let i = 0; i < goals.length; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: rewards[i] }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.OneGame,
					goal: goals[i],
					description: `Score ${goals[i]} points in ONE game.`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = WeeklyQuestCode.Armageddon;
title = 'May the best fish wins!';
steps = [];
goals = [8, 6, 3, 1];
rewards = [100, 100, 100, 100];
for (let i = 0; i < goals.length; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: rewards[i] }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.OneGame,
					goal: 1,
					description: `Get ${ordinalSuffix(goals[i])} place ${goals[i] !== 1 ? 'or above ' : ''}in Armageddon`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = WeeklyQuestCode.BuyWeapon;
title = 'Chance favors the prepared!';
steps = [];
goals = [5];
rewards = [5];
for (let i = 0; i < goals.length; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: rewards[i] }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Accumulate,
					goal: goals[i],
					description: `Buy weapon from Theo's weaponry ${goals[i]} times.`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = WeeklyQuestCode.Mine;
title = 'Heigh-Ho!';
steps = [];
for (let i = 0; i < 7; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 5 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Accumulate,
					goal: 7,
					description: `Mine ${7} ores.`,
				},
			],

		},
	);
}
setQuest(title, steps);

unlockId = WeeklyQuestCode.Treasure;
title = 'Sunken Booty Ahoy!';
steps = [];
for (let i = 0; i < 3; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 5 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Accumulate,
					goal: 1,
					description: `Open ${1} treasure chest.`,
				},
			],

		},
	);
}
setQuest(title, steps);


const current = Date.now();
let nextLogin = 1647273600000;
const day = 24 * 60 * 60000;
while (nextLogin < current) {
	nextLogin += day;
}
const refreshHour = new Date(nextLogin).getHours();

unlockId = WeeklyQuestCode.Login;
title = 'Welcome Back!';
steps = [];
goals = [];
for (let i = 0; i < 4; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 200 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Login,
					goal: 1,
					description: `Login Daily (${refreshHour}:00)`,
				},
			],

		},
	);
}
setQuest(title, steps);


unlockId = WeeklyQuestCode.Craft;
title = 'Blueprints of Life!';
steps = [];
goals = [];
for (let i = 0; i < 4; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 150 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Craft,
					goal: 3,
					description: `Craft 3 Decorations.`,
				},
			],

		},
	);
}
setQuest(title, steps);


unlockId = WeeklyQuestCode.Gacha;
title = 'Gacha!';
steps = [];
for (let i = 0; i < 1; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 50 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Gacha,
					goal: 1,
					description: `Summon(Gacha) ${1} time.`,
				},
			],

		},
	);
}
setQuest(title, steps);


unlockId = WeeklyQuestCode.Purchase;
title = 'Thanks for supporting the game!';
steps = [];
for (let i = 0; i < 1; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 50 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.Purchase,
					goal: 1,
					description: `Purchase ${1} time.`,
				},
			],

		},
	);
}
setQuest(title, steps);


unlockId = WeeklyQuestCode.LoginSf1;
title = 'Welcome!';
steps = [];
goals = [];
steps.push(
	{
		rewards: [{ itemCode: ItemCode.Gem, amount: 200 }],
		challenges: [
			{
				unlockId,
				index,
				step: 0,
				type: UnlockChallengeType.LoginSf1,
				goal: 1,
				description: `Weekly Login`,
			},
		],

	},
);
setQuest(title, steps, true);

unlockId = WeeklyQuestCode.PlaySf1;
title = 'Stab\'em Every Day!';
steps = [];
goals = [];
for (let i = 0; i < 7; i++) {
	steps.push(
		{
			rewards: [{ itemCode: ItemCode.Gem, amount: 150 }],
			challenges: [
				{
					unlockId,
					index,
					step: i,
					type: UnlockChallengeType.PlaySf1,
					goal: 1,
					description: `Daily Play (${refreshHour}:00)`,
				},
			],

		},
	);
}
setQuest(title, steps, true);
