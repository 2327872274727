



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import userx from '@/store/modules/userx';
import { SignLinkError } from '@/store/firestore';
import fb from '@/store/sf-firestore';
import { Auth, fetchSignInMethodsForEmail } from 'firebase/auth';
declare let auth: Auth;

@Component({
  components: {},
})
export default class LoginFailedModal extends Vue {
  public signLinkError: SignLinkError | null = null;
  public providers: string[] = [];
  public get titleHtml() {
    return `<i class="fas fa-times-circle pr-2"></i>${
      this.action === 'link' ? 'Link' : 'Login'
    } Failed`;
  }
  public get email() {
    return this.signLinkError?.error.email || 'The email address';
  }
  public get action() {
    return this.signLinkError?.action || 'signin';
  }
  public get errorCode() {
    // auth/account-exists-with-different-credential

    // auth/email-already-in-use
    return this.signLinkError?.error.code || '';
  }
  public get message() {
    if (this.errorCode === 'auth/email-exists-but-login-failed') {
      // link situation
      return 'The email address exists but the password is incorrect or was registered using other methods. Please link by typing the correct password or using the previous method.';
    }
    if (this.errorCode === 'auth/email-already-in-use') {
      // link situation
      return 'The email address is already in use by another account. Please link using previous method.';
    }
    if (this.errorCode === 'auth/account-exists-with-different-credential') {
      // sign in situation
      return 'An account already exists with the same email address, but different sign-in credentials. Please sign in using previous method.';
    }
    return this.signLinkError?.error.message || '';
  }

  public get previousMethods() {
    return this.providers.join(', ');
  }

  public async showModal(error: SignLinkError) {
    const modal = this.$refs.logoutModal as BModal;
    this.signLinkError = error;
    if (modal) {
      modal.show();
    }
    if (this.email) {
      this.providers = await fetchSignInMethodsForEmail(auth, this.email);
    }
  }

  public onOk() {}

  public created() {
    this.$root.$on('signLinkError', this.showModal);
  }
}
