import { FishType } from '@/game/infos/fishInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import { SpearType } from '@/game/infos/spearInfos';
import { Dictionary } from '@/util/dictionary';
import { nonReact } from '@/util/nonReact';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Container, Sprite } from 'pixi.js';
import Factory from '..';
import { getAllTimedChildren, tint } from '../helper';
import { ScaledParticleData } from '../itemParticles';
import { ParticleData } from '../particles';
import { FishAction } from './playerMixin';
import { disposeFishSkin } from './skinAssets';


export class AllBodies extends MovieClip {
	public body!: MovieClip;
	public mouthGuide!: Container;
	public eyeGuide!: Container;
}
export class EvolveEffect extends MovieClip {
	public init() {
		this.y = -45;
		this.gotoAndStop(this.totalFrames - 1);
		this.addAction(() => {
			if (this.parent) {
				this.parent.removeChild(this);
				Factory.pool(EvolveEffect, this);
			}
		}, this.totalFrames - 1);
	}
}
export class AllSpears extends MovieClip {
	public lvl!: MovieClip;
	public init() {
		this.y = -45;
		this.gotoAndStop(this.totalFrames - 1);
	}
}
export class MiniSpike extends Container {
	public init() {
		this.y = -42.9;
	}
}
export class StunAnimated extends MovieClip {
	public init() {
		this.x = 0;
		this.y = -50;
	}
}
export class OnifuguSpikes extends Container {
}
export class DragonTridentSymbol extends Container {
}
export class BodySlamEffect extends MovieClip {
}
export class KrakenRunSymbol extends Container {
}
const fishBodies: Array<Pool<MovieClip>> = nonReact([]);

interface Guide {
	x: number,
	y: number,
	scaleX: number,
	scaleY: number,
	angle: number,
}
export const eyeGuideDatas: Guide[] = nonReact([]);
export const mouthGuideDatas: Guide[] = nonReact([]);

export function registerBodies(symbol: AllBodies) {

	const timelines = (symbol as any)._timelines;
	for (const timeline of timelines) {
		const guides = timeline[0].target.name === 'eyeGuide' ? eyeGuideDatas
			: timeline[0].target.name === 'mouthGuide' ? mouthGuideDatas : null;
		if (guides) {
			for (const tween of timeline) {
				const { startProps, startFrame, endFrame } = tween;
				const prop = {
					x: startProps.x || 0,
					y: startProps.y || 0,
					scaleX: startProps.sx || 0,
					scaleY: startProps.sy || 0,
					angle: startProps.a || 0,
				};
				for (let i = startFrame; i <= endFrame; i++) {
					guides[i] = prop;
				}
			}
		}
	}
	const children = getAllTimedChildren(symbol);
	// console.log(symbol);
	for (let i = 0; i < children.length; i++) {
		const child = children[i] as MovieClip;
		if (child.name !== 'body') { continue; }
		const symbolClass = child.constructor;
		fishBodies[i] = new Pool(symbolClass);

	}
	// console.log(symbol);
	symbol.destroy();
}
export interface FishBody extends MovieClip {
	skinGroup: SkinGroup,
	fishType: FishType,
}
export function getFishBody(fishType: FishType) {
	const symbol = fishBodies[fishType].get() as FishBody;
	symbol.fishType = fishType;
	symbol.skinGroup = SkinGroup.None;
	return symbol;
}

export function disposeFishBody(fishBody: FishBody) {
	const { fishType, skinGroup } = fishBody;
	if (skinGroup !== SkinGroup.None) {
		return disposeFishSkin(fishBody);
	}
	fishBodies[fishType].pool(fishBody as MovieClip);
	tint(fishBody, 0xffffff);
	fishBody.x = 0;
	fishBody.y = 0;
	fishBody.scale.set(1);
	fishBody.angle = 0;
}

const spears: ScaledParticleData[][] = nonReact([]);
const spearsPools: Sprite[][][] = nonReact([]);

export function registerSpears(symbol: AllSpears) {

	const list = spears;
	const pools = spearsPools;
	const children = getAllTimedChildren(symbol);
	for (let k = 0; k < children.length; k++) {
		const spear = children[k] as MovieClip;
		list[k] = [];
		pools[k] = [];
		const lvls = getAllTimedChildren(spear);
		const timeline = (spear as any)._timelines;
		for (let i = 0; i < lvls.length; i++) {
			const child = lvls[i] as Sprite;
			const pos = timeline[i]?._currentProps || { x: 0, y: 0, sx: 1, sy: 1, ky: 0, kx: 0 };
			if (i === 0 && lvls.length !== 1) { child.x = child.y = 0; child.scale.x = child.scale.y = 1; }
			const rect = child.getLocalBounds();
			const scaleX = child.scale.x * (pos.sx || 1) * (pos.ky ? -1 : 1);
			const scaleY = child.scale.y * (pos.sy || 1) * (pos.kx ? -1 : 1);

			const u = -(child.x + pos.x || 0) / rect.width / scaleX;
			const v = -(child.y + pos.y || 0) / rect.height / scaleY;
			const texture = (child as Sprite).texture;
			list[k][i] = { u, v, texture, scaleX, scaleY };
			pools[k][i] = [];
		}
	}
	symbol.destroy();
	// list[SpearType.HolyTusk][1] = list[SpearType.HolyTusk][2] = list[SpearType.HolyTusk][3] = list[SpearType.HolyTusk][0];
	// pools[SpearType.HolyTusk][1] = spearsPools[SpearType.HolyTusk][2] = spearsPools[SpearType.HolyTusk][3] = spearsPools[SpearType.HolyTusk][0];
}

export interface SpearSprite extends Sprite {
	spearType: number,
	lvl: number,
}
export function getSpear(spearType: number, lvl: number) {

	const pool = spearsPools[spearType][lvl];
	const old = pool.shift();
	if (old) { return old; }

	const data = spears[spearType][lvl];
	const symbol = Sprite.from(data.texture) as SpearSprite;
	symbol.scale.set(data.scaleX, data.scaleY);
	symbol.spearType = spearType;
	symbol.lvl = lvl;
	symbol.anchor.set(data.u, data.v);

	return symbol;
}
export function disposeSpear(sprite: SpearSprite) {
	const spearType = sprite.spearType;
	const lvl = sprite.lvl;
	spearsPools[spearType][lvl].push(sprite);
}


