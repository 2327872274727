








import PixiCanvasComponent from '@/components/canvas/PixiCanvasComponent.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { SkillIconData, SkillIcon } from '@/client/factory/assets/skillIcon';

@Component({
  components: {},
})
export default class SkillIconCanvasComponent extends PixiCanvasComponent {
  @Prop({ default: null }) public data!: SkillIconData | null;

  public symbol: SkillIcon | null = null;
  public get sumcheck() {
    return (
      JSON.stringify(this.data) + this.width.toString() + this.height.toString()
    );
  }

  @Watch('sumcheck', { immediate: true })
  public sumcheckChanged(
    newVal: SkillIconData | null,
    oldVal: SkillIconData | null,
  ) {
    this.build();
  }

  public build() {
    if (this.assetLoadState !== 'completed') {
      return;
    }
    this.app.start();
    if (!this.symbol) {
      this.symbol = SkillIcon.get(this.data);
      this.stage.addChild(this.symbol);
    } else {
      this.symbol.update(this.data);
    }
    const smallest = Math.min(this.width, this.height);
    this.symbol.scale.set(smallest / 40);
    this.symbol.x = this.width / 2;
    this.symbol.y = this.height / 2;
    this.rerenderAndStop();
  }

  public mounted() {
    this.width = this.height = 40;
    this.resize();
    if (this.assetLoadState === 'completed') {
      this.build();
    }
  }

  protected _beforeDestroy() {
    this.stage.removeChildren();
    if (this.symbol) {
      SkillIcon.pool(this.symbol);
      this.symbol = null;
    }
  }
}
