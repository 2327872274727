
export type StabfishErrorCode =
	'invalid-parameters' |
	'auth/requires-login' |
	'auth/permission-denied' |

	'team/exceed-capacity' |
	'team/member-not-found' |
	'team/id-full' |
	'team/not-found' |
	'team/wrong-passcode' |
	'team/invalid-passcode' |
	'team/user-playing' |

	'unlock/incomplete' |

	'contribute/prev-step' |
	'contribute/insufficient-gold' |
	'contribute/insufficient-material' |
	'contribute/not-found' |

	'craft/insufficient-material' |

	'deco/insufficient-deco' |
	'deco/exceed-limit-per-fish' |
	'deco/exceed-limit' |

	'quest/claim-failed' |

	'poq/request-failed' |
	'poq/transaction-failed' |
	'poq/not_enough_quarters' |

	'gacha/insufficient-pearls' |
	'convert/insufficient-skins' |
	'equip-skin/insufficient-skins' |

	'username/ban' |

	'deco-limit/max' |
	'deco-limit/insufficient-fund' |

	'no-new-lobby/maintenance' |

	'none';
const messages: { [code in StabfishErrorCode]: string } = {
	'none': '',
	'auth/requires-login': 'Requires user login.',
	'invalid-parameters': 'Invalid Parameter',

	'auth/permission-denied': 'Permission Denied.',
	'team/exceed-capacity': 'Team Full.',
	'team/member-not-found': 'Member Not Found.',
	'team/id-full': 'Team ID is currently used up, please try again later or inform GM.',
	'team/not-found': 'Team Not Found.',
	'team/wrong-passcode': 'Wrong Passcode.',
	'team/invalid-passcode': 'Invalid Passcode.',
	'team/user-playing': `Do not kick members while they are playing.`,

	'unlock/incomplete': 'Unlock Failed.',

	'contribute/prev-step': 'Previous Step not completed',
	'contribute/insufficient-gold': 'Insufficient Gold.',
	'contribute/insufficient-material': 'Insufficient Material.',
	'contribute/not-found': 'Challenge Not Found.',

	'craft/insufficient-material': 'Insufficient Material.',

	'deco/insufficient-deco': 'Insufficient Decorations.',
	'deco/exceed-limit-per-fish': 'Exceed equip limit per fish of the decoration.',
	'deco/exceed-limit': 'Exceed decoration limit.',

	'quest/claim-failed': 'Failed to claim. Make sure you completed all challenges.',


	'poq/request-failed': 'POQ Request Failed: ',
	'poq/transaction-failed': 'Transaction Failed: ',
	'poq/not_enough_quarters': 'Transaction Failed: You do not have enough Quarters.',

	'gacha/insufficient-pearls': 'Insufficient Black Pearls.',
	'convert/insufficient-skins': 'Insufficient Skins.',
	'equip-skin/insufficient-skins': 'Insufficient Skins.',

	'username/ban': 'Your permission to change username is temporarily stripped.',

	'deco-limit/max': 'You have reached the max decoration limit.',
	'deco-limit/insufficient-fund': 'Insufficient fund to increase your decoration limit.',

	'no-new-lobby/maintenance': 'Fail to create new room currently due to Maintenance or Armageddon.',

};

export class StabfishError extends Error {

	public name = 'StabfishError';

	public stack?: string;

	constructor(readonly code: StabfishErrorCode, additionalMessage = '') {
		super(messages[code] + additionalMessage);
		// HACK: We write a toString property directly because Error is not a real
		// class and so inheritance does not work correctly. We could alternatively
		// do the same "back-door inheritance" trick that FirebaseError does.

		(this as any).__proto__ = StabfishError.prototype;

		this.toString = () => `${this.name}: [code=${this.code}]: ${this.message + additionalMessage}`;
	}

	public toJSON(): object {
		return {
			code: this.code,
			message: this.message,
		};
	}
}
