
























































import globalx from '@/store/modules/globalx';
import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ArmageddonTutorial from './articles/ArmageddonTutorial.vue';
import HowToPlay from './articles/HowToPlay.vue';
import Newspaper from './articles/Newspaper.vue';
import SummonTutorial from './articles/SummonTutorial.vue';
import TipsLibrary from './articles/TipsLibrary.vue';
import NemesisTutorial from './articles/NemesisTutorial.vue';
import throttle from 'lodash/throttle';

type Tab = 'news' | 'howToPlay' | 'armageddon' | 'summon' | 'tips' | 'nemesis';

@Component({
	components: {
		HowToPlay,
		ArmageddonTutorial,
		Newspaper,
		SummonTutorial,
		TipsLibrary,
		NemesisTutorial,
	},
})
export default class NewsTab extends Vue {
	@Prop({ default: false }) public hasCloseBtn!: boolean;
	public onScrollThrottled = throttle(this.onScroll, 500);
	public tab: Tab = 'news';
	public readRemains = 10;

	public selectTab(tab: Tab) {
		this.tab = tab;
		(this.$refs.scroll as HTMLDivElement).scrollTop = 0;
	}

	public get hasNews() {
		return globalx.latestNews !== settingx.now.news || settingx.now.newsTime < globalx.autoTimeLimitedEventTime || settingx.now.newsTime < globalx.autoDecoContestTime;
	}

	public mounted() {
		(this.$refs.scroll as HTMLDivElement).addEventListener(
			'scroll',
			this.onScrollThrottled,
		);
	}
	public beforeDestroy() {
		(this.$refs.scroll as HTMLDivElement).removeEventListener(
			'scroll',
			this.onScrollThrottled,
		);
	}

	public onScroll() {
		if (!this.$refs.newspaper) {
			return;
		}
		const scroll = this.$refs.scroll as HTMLDivElement;
		const newspaper = (this.$refs.newspaper as any).$el as HTMLDivElement;
		// console.log(
		//   scroll.clientHeight,
		//   newspaper.clientHeight,
		//   newspaper.clientHeight - scroll.scrollTop,
		// );
		this.readRemains =
			(newspaper.clientHeight - scroll.scrollTop) / scroll.clientHeight;
	}
}
