import globalx from '../modules/globalx';


let syncedTime = 0;
let serverTime = 0;

const timeframes: number[][] = [];

export enum ActivityEvents {
	Xmas2020,
}
timeframes[ActivityEvents.Xmas2020] = [1608566400000, 1609430399999];

export class ServerTime {
	public static async sync() {
		const lobbyServerAddress = globalx.lobbyServerAddress;
		const response = await fetch(`${lobbyServerAddress}/time?v=ae`);
		if (!response.ok) {
			return;
		}
		syncedTime = Date.now();
		const json = await response.json();
		serverTime = json.time;
	}

	public static get time() {
		return serverTime + Date.now() - syncedTime;
	}

	public static hasStarted(event: ActivityEvents) {
		const st = ServerTime.time;
		return st >= timeframes[event][0];
	}
	public static hasEnded(event: ActivityEvents) {
		const st = ServerTime.time;
		return st > timeframes[event][1];
	}
	public static is(event: ActivityEvents) {
		const st = ServerTime.time;
		return st >= timeframes[event][0] && st <= timeframes[event][1];
	}
}

