
export enum StababDeathReason {
	None,
	Wall,
	Squeezed,
	StabberDeath,
	Sacrifice,
}

export enum PlayerDeathReason {
	None,
	ServerClose,
	GaveUp,
	StababDeath,
	Bledout,
	Suffocated,
	Frozen,
	Burnt,
	Electrocuted,
	ArmageddonPoison,

	Skill,
	FishBolt,
	BossSkill,
}

export enum PlayerType {
	None,
	Admin,
	Spectator,
	Player,
	Mob,
	Bot,
	Nemesis,
}
export enum StababType {
	Fish,
	Tail,
	Penguin,
	Pumpkin,
}

export enum HealType {
	Food,
	Buff,
	Skill,
	Teammate,
	AdHeal,
}
export enum PlayerMode {
	Enemy,
	PvE,
	PvP,
}

export type StabType = 'Stab' | 'Revenge Stab' | 'Stab Streak' | 'Bounty Stab' | 'Spike Stab';

export function testTeamAndMode(teamA: string, teamB: string, modeA: PlayerMode, modeB: PlayerMode) {
	if (teamA === teamB) {
		return true;
	}
	if (modeA === PlayerMode.Enemy || modeB === PlayerMode.Enemy) {
		return false;
	}
	if (modeA === PlayerMode.PvE || modeB === PlayerMode.PvE) {
		return true;
	}
	return false;
}
