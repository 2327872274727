

































import { ItemCode } from '@/game/infos/itemInfos';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ItemIntelData } from '../ui/mainMenu/bag/ItemIntelTooltip.vue';
import ItemButton from '../ui/mainMenu/tools/inventory/ItemButton.vue';
@Component({
  components: {
    ItemButton,
  },
})
export default class ProductButtonComponent extends Vue {
  @Prop({ default: true }) public showTimeLimited!: boolean;
  @Prop({ default: 1 }) public itemCode!: ItemCode;
  @Prop({ default: 'blackpearl' }) public currency!:
    | 'blackpearl'
    | 'quarters'
    | 'gold';

  public get id() {
    return 'product-' + random.v4();
  }
  public get pair(): ItemCodeAmountPair {
    return { itemCode: this.itemCode, amount: 0 };
  }
  public showItemIntel() {
    const id = this.id;
    const data: ItemIntelData = {
      targetId: id,
      placement: 'top',
      container: 'item-inventory',
      itemCode: this.itemCode,
    };
    this.$root.$emit('showItemIntel', data);
  }
  public hideItemIntel() {
    this.$root.$emit('hideItemIntel');
  }
}
