import { Decoratives } from '@/components/ui/mainMenu/tools/decoEditor/decorative';
import { fishInfos } from '@/game/infos/fishInfos';
import { Global } from '@/store/globalz';
import settingx from '@/store/modules/settingx';
import userx from '@/store/modules/userx';
import { GlowFilter } from 'pixi-filters';
import { Container, Graphics, Rectangle, Sprite } from 'pixi.js';
import { ShootingStarsContainer } from '../factory/assets/shootingStarMixin';
import { onClick, rgbToInteger, tint } from '../factory/helper';
import { getWallSprite, MapAsset } from '../factory/mapAssets';
import { getParticle, Particle } from '../factory/particles';

export class MainMenuControl extends Container {

	public get heroes() {
		return userx.heroes;
	}

	public avatarsContainer = new Container();
	public decoEditButton = new Graphics();
	public avatarsTargetX = 0;
	public avatarsTargetY = 0;
	public avatarsTargetScale = 1;
	public shadows: Sprite[] = [];
	public shootingStarContainer = new ShootingStarsContainer();

	public foreground1 = new Container();
	public foreground2 = new Container();

	public onMouseMove = this._onMouseMove.bind(this);
	public constructor() {
		super();
		this.decoEditButton.beginFill(0xff0000, 0.001);
		this.decoEditButton.drawRect(-75, -75, 150, 150);
		this.decoEditButton.visible = true;
		this.addChild(this.shootingStarContainer);
		this.addChild(this.decoEditButton);
		this.addChild(this.foreground1);
		this.addChild(this.foreground2);
		this.addChild(this.avatarsContainer);


		let fg = getWallSprite(MapAsset.LeftNodeT);
		this.foreground2.addChild(fg);
		fg.x = -50;
		fg.y = -300;
		fg = getWallSprite(MapAsset.LeftNode);
		this.foreground2.addChild(fg);
		fg.x = -50;
		fg.y = 20;

		fg = getWallSprite(MapAsset.MainNodeT);
		this.foreground1.addChild(fg);
		fg.x = -20;
		fg.y = -320;
		fg = getWallSprite(MapAsset.MainNodeL);
		this.foreground1.addChild(fg);
		fg.x = 320;
		fg.y = 20;
		fg.scale.x = -1;
		fg = getWallSprite(MapAsset.MainNode);
		this.foreground1.addChild(fg);
		fg.x = -20;
		fg.y = 20;
		fg.scale.x = -1;
		this.foreground1.scale.set(0.8);
		tint(this.foreground1, 0x669fff);

		const len = this.heroes.length;

		const containerWidth = 110;
		const gap = containerWidth / len;
		const start = gap / 2 - containerWidth / 2;

		// const seaColor = [68, 255, 247];
		const seaColor = [200, 200, 200];
		const tintGap = seaColor.map((c) => (255 - c) / len);
		for (let i = 0; i < len; i++) {
			const fishType = this.heroes[i];
			const avatar = Global.avatars[i];
			avatar.filters = [new GlowFilter() as any];
			const shadow = getParticle(Particle.Shadow);
			this.shadows[i] = shadow;
			shadow.angle = 45;
			avatar.setFishType(fishType, userx.equippedSkins[fishType] || 0);
			avatar.setAction('move');
			Decoratives.generateDeco(avatar, userx.decoSets[fishType]);
			this.avatarsContainer.addChildAt(avatar, 0);
			this.avatarsContainer.addChildAt(shadow, 0);
			avatar.x = shadow.x = start + gap * i;
			const scale = fishInfos[fishType].stat.size * 0.8;
			avatar.setBodyScale(scale);
			shadow.scale.set(scale);
			// avatar.scale.set(0.8);
			tint(
				avatar,
				rgbToInteger(
					255 - i * tintGap[0],
					255 - i * tintGap[1],
					255 - i * tintGap[2],
				),
			);
		}
		this.decoEditButton.interactive = true;
		this.decoEditButton.cursor = 'pointer';
		onClick(this.decoEditButton, this.toDecoEditor);

		//   this.shootingStarContainer.start();
		this.on('added', () => {
			this.shootingStarContainer.start();
			document.addEventListener('mousemove', this.onMouseMove);
		});
		this.on('removed', () => {
			this.shootingStarContainer.stop();
			document.removeEventListener('mousemove', this.onMouseMove);
		});
	}
	public toDecoEditor() {
		Global.$root.$emit('selectFish');
	}

	public setSize(width: number, height: number) {
		this.avatarsContainer.x = width / 2;
		this.avatarsContainer.y = height / 2;
		this.avatarsContainer.scale.set(0.1);
		let maxHeight = height - 20;
		let maxWidth = width;
		if (width <= 640) {
			this.avatarsTargetX = width / 2;
			maxWidth = width - 20;
			maxHeight = 300;
			this.avatarsTargetY = 380;
		} else if (width < 1020) {
			this.avatarsTargetX = (width - 320) / 2;
			maxWidth = width - 320;
		} else if (width <= 1300) {
			this.avatarsTargetX = (width - 300) / 4;
			maxWidth = (width - 300) / 2;
		} else {
			maxWidth = 500;
			this.avatarsTargetX = (width - 1300) / 2 + 250;
		}
		this.avatarsTargetScale = Math.min(maxWidth / 150, maxHeight / 150, 2.5);
		if (width > 640) {
			this.avatarsTargetY = 40 + height / 2 - 10 * this.avatarsTargetScale;
		}

		this.decoEditButton.x = this.avatarsTargetX;
		this.decoEditButton.y = this.avatarsTargetY;
		this.decoEditButton.scale.set(this.avatarsTargetScale);
		this.avatarsContainer.x = this.avatarsTargetX;
		this.avatarsContainer.y = this.avatarsTargetY;
		this.avatarsContainer.scale.set(this.avatarsTargetScale);
		this.foreground1.y = height;
		this.foreground2.y = height;
		this.foreground2.x = width;
	}
	private _onMouseMove(e: MouseEvent) {
		if (settingx.now.control === 'mouse') {
			const width = document.documentElement.clientWidth;
			const height = document.documentElement.clientHeight;
			const px = -e.clientX / width + 0.5;
			const py = -e.clientY / height + 0.3;

			const len = this.heroes.length;
			const containerWidth = 110;
			const gap = containerWidth / len;
			const start = gap / 2 - containerWidth / 2;
			for (let i = 0; i < len; i++) {
				const avatar = Global.avatars[i];
				const shadow = this.shadows[i];
				avatar.x = shadow.x = start + gap * i + 10 * px * i;
				avatar.y = shadow.y = 10 * py * i;
			}
		}
	}

}
