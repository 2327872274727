















import { ItemCode } from '@/game/infos/itemInfos';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ItemIntelData } from '../../bag/ItemIntelTooltip.vue';
import ItemButton from '../../tools/inventory/ItemButton.vue';
@Component({
  components: {
    ItemButton,
  },
})
export default class NewDecoComponent extends Vue {
  @Prop({ default: 1 }) public itemCode!: ItemCode;

  public get id() {
    return 'new-item' + random.v4();
  }
  public get pair(): ItemCodeAmountPair {
    return { itemCode: this.itemCode, amount: 0 };
  }
  public showItemIntel() {
    const id = this.id;
    const data: ItemIntelData = {
      targetId: id,
      placement: 'top',
      container: 'item-inventory',
      itemCode: this.itemCode,
    };
    this.$root.$emit('showItemIntel', data);
  }
  public hideItemIntel() {
    this.$root.$emit('hideItemIntel');
  }
}
