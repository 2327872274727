
















import { TipId, tipInfos } from '@/game/infos/tipInfos';
import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class TipsLibrary extends Vue {
  public get tipIds() {
    const list = [
      TipId.Hp,
      TipId.Crush,
      TipId.LeftClick,
      TipId.RightClick,
      TipId.SafeZone,
      TipId.Armory,
      TipId.ChangeWeapon,
      TipId.ChangeWeaponTouch,
      TipId.ChooseFish,
      TipId.Hazard,
      TipId.Chest,
      TipId.ArrangeDeco,
    ];
    // console.log(tipInfos.length-1, list.length);
    return list;
  }
  public get tips() {
    let skill1: string = settingx.now.skill1;
    let skill2: string = settingx.now.skill2;
    let wpnL: string = settingx.now.wpnL;

    if (skill1 !== 'Left-Click' && skill1 !== 'Right-Click') {
      skill1 = 'Press ' + skill1.toUpperCase();
    }
    if (skill2 !== 'Left-Click' && skill2 !== 'Right-Click') {
      skill2 = 'Press ' + skill2.toUpperCase();
    }
    if (wpnL !== 'Left-Click' && wpnL !== 'Right-Click') {
      wpnL = 'Press ' + wpnL.toUpperCase();
    }

    return this.tipIds.map((id) => {
      const info = { ...tipInfos[id] };

      let desc = info?.desc || '';
      desc = desc.replace(
        '$tap',
        settingx.now.control === 'touch' ? 'Tap' : 'Click',
      );

      desc = desc.replace(
        '$leftClick',
        settingx.now.control === 'touch' ? 'Tap the orange button' : skill1,
      );
      desc = desc.replace(
        '$rightClick',
        settingx.now.control === 'touch' ? 'Tap the blue button' : skill2,
      );
      desc = desc.replace('$wpn', wpnL);

      if (id === TipId.ChangeWeaponTouch) {
        desc += ' (Touch Devices)';
      } else if (id === TipId.ChangeWeapon) {
        desc += ' (Mouse and Keyboard)';
      }
      info.desc = desc;
      return info;
    });
  }
}
