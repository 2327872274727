export enum EmojiType {
	Bye,
	Hi,
	Love,
	Help,
	FollowMe,
	ExclamationMark,
	QuestionMark,
	Haha,
	Angry,
	Sad,
	Pvp,
	Peace,
}
