














































import {
  generateGeneSkillIconData,
  generateNormalSkillIconData,
  generatePassiveSkillIconData,
  generateRGeneSkillIconData,
  generateSpecialSkillIconData,
} from '@/client/factory/assets/skillIcon';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import gamex from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishSkillComponent from '../../mainMenu/tools/fishSelector/FishSkillComponent.vue';
@Component({
  components: {
    FishSkillComponent,
  },
})
export default class FishAllSkillsComponent extends Vue {
  @Prop({ default: 1 }) public fishType!: FishType;
  public get fishInfo() {
    return fishInfos[this.fishType];
  }
  public get hasActiveSkill() {
    return this.fishInfo.skill1 || this.fishInfo.skill2;
  }
  public get hasPassiveSkill() {
    return this.fishInfo.passives.length > 0;
  }
  public get hasGeneSkill() {
    return this.fishInfo.genes.length > 0;
  }
  public get hasRGeneSkill() {
    return this.fishInfo.recessives.length > 0;
  }

  public get normalSkillIconData() {
    return generateNormalSkillIconData(this.fishInfo.skill1);
  }
  public get specialSkillIconData() {
    return generateSpecialSkillIconData(this.fishInfo.skill2);
  }
  public get passiveSkillIconData() {
    return this.fishInfo.passives.map((s) =>
      generatePassiveSkillIconData(s.type, s.lvl),
    );
  }
  public get geneSkillIconData() {
    return this.fishInfo.genes.map((s) =>
      generateGeneSkillIconData(s.type, s.lvl),
    );
  }
  public get recessSkillIconData() {
    return this.fishInfo.recessives.map((s) =>
      generateRGeneSkillIconData(s.type, s.lvl),
    );
  }
}
