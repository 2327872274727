



















import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class InputControlToast extends Vue {
  public get control() {
    return settingx.now.control;
  }
  public get mode() {
    return this.control === 'mouse' ? 'Mouse' : 'Touch';
  }
  public get icon() {
    return this.control === 'mouse'
      ? 'fa fa-mouse text-blue'
      : 'fa fa-hand-pointer text-yellow';
  }
  @Watch('control')
  public controlChanged(newVal: 'mouse' | 'touch') {
    this.$bvToast.show('inputControlToast');
  }
}
