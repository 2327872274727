



































































































import { GameCommandType, IGameCommand } from '@/game/multithread/command';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { wait } from '@/util/wait';
import DecoVoteComponent from '../decoContest/DecoVoteComponent.vue';
import settingx, { SettingsData } from '@/store/modules/settingx';
import { GameType } from '@/game/infos/roomInfos';
import userx from '@/store/modules/userx';
import { FishType } from '@/game/infos/fishInfos';
import { EditState } from '@/store/models.def';
import StateButton from '@/components/inputs/StateButton.vue';
import globalx from '@/store/modules/globalx';
import DecoContestPoster from '../decoContest/DecoContestPoster.vue';

@Component({
	components: {
		DecoContestPoster,
		DecoVoteComponent,
		StateButton,
	},
})
export default class LobbyComponent extends Vue {
	// public get eligible() {
	// 	return userx.fishUnlocked[FishType.HammeredHead] && Date.now() < 1715670000000;
	// }
	// public get submitLabel() {
	// 	const labels = {
	// 		active: 'Save',
	// 		sending: 'Saving',
	// 		done: 'Saved',
	// 	};
	// 	return labels[this.submitState];
	// }
	public get beginTutorial() {
		return gamex.beginTutorial;
	}

	public get decoContest() {
		return globalx.decoContest;
	}
	public get decoStage() {
		return globalx.autoDecoContestStage;
	}

	public get showBackButton() {
		return gamex.rejectReason || gamex.socketErrorMessage ? true : false;
	}
	public get showQuitButton() {
		return gamex.gameStage === 'Queue';
	}
	public get showStartButton() {
		return gamex.gameStage === 'Joining' && !this.showBackButton;
	}
	public get label() {
		const stage = gamex.gameStage;
		if (gamex.rejectReason) {
			return 'Failed to join: ' + gamex.rejectReason;
		}
		if (gamex.socketErrorMessage) {
			return gamex.socketErrorMessage;
		}
		if (stage === 'Joining') {
			if (!this.interval) {
				if (this.beginTutorial) {
					this.countDown = 5;
					this.interval = setInterval(() => {
						this.countDown--;
						if (this.countDown < 0) {
							this.onStart(false);

							this.countDown = 0;
						}
					}, 1000);
				} else {
					this.countDown = 30;
					this.interval = setInterval(() => {
						this.countDown--;
						if (this.countDown < 0) {

							this.onQuit();
							this.countDown = 0;
						}
					}, 1000);
				}

			}
			return `Connected. (${this.countDown}s)`;
		}
		if (stage === 'Queue') {
			return `Server Full... Queueing... ${gamex.queueNumber} left`;
		}
		return 'Connecting To Server...';
	}
	public interval: any;
	public countDown = 15;

	// public toKeep = userx.peaceModeVote.keep;
	// public middleGround = userx.peaceModeVote.middle;
	// public playerType = userx.peaceModeVote.player;

	// public submitState: EditState = 'active';

	public nemesisMode = !settingx.now.capStreak;
	// public onVote() {
	// 	this.submitState = 'sending';
	// 	try {
	// 		userx.votePeaceMode({
	// 			uid: '',
	// 			keep: this.toKeep,
	// 			middle: this.middleGround,
	// 			player: this.playerType,
	// 		});
	// 	} catch (error) {
	// 		//   this.showError((error as any).message);
	// 	}
	// 	this.submitState = 'done';

	// }
	public clearCountDown() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}
	public onBack() {
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('MainMenu');
		this.clearCountDown();
	}
	public onQuit() {
		gamex.updateGameType(GameType.None);
		gamex.setGameStage('MainMenu');
		Global.clientCommunicator.disconnect();
		this.clearCountDown();
	}
	public async goToContest() {
		this.$router.push('/decoContest');
		await wait(500);
		this.onQuit();
	}
	public async goToVote() {
		this.$router.push('/decoContest/vote');
		await wait(500);
		this.onQuit();
	}
	public onStart(joinRandomTeam = false) {
		Global.clientCommunicator.join(joinRandomTeam, !this.nemesisMode);
		this.clearCountDown();
	}

	public beforeDestroy() {
		this.clearCountDown();
	}
	public onNemesisChanged(value: boolean) {
		this.nemesisMode = value;
		const submission: Partial<SettingsData> = {
			capStreak: !this.nemesisMode,
		};
		settingx.updateSettings(submission);
	}
}
