import { IVolumeControl } from './IVolumeControl';
import { SoundInstance } from './SoundInstance';

export class SoundGroup implements IVolumeControl {
	public get volume() {
		return this._volume;
	}
	public set volume(value) {
		this._volume = value;
		this.updateVolume();
	}
	public get muted() {
		return this._muted;
	}
	public set muted(value) {
		this._muted = value;
		this.updateVolume();
	}

	public members: SoundInstance[] = [];
	private _volume = 1;

	private _muted = false;

	public updateVolume() {
		for (const member of this.members) {
			member.updateVolume();
		}
	}
}
