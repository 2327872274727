import { PlayerMode, PlayerType } from '@/game/infos/enums';
import { PassiveSkill } from '@/game/infos/skills';
import { PlayerData } from '@/game/multithread/viewState';
import { MovieClip } from 'pixi-animate';
import { BitmapText, Container, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '..';
import { disposeSkillSprite, getPassiveSprite, SkillSprite } from '../skillAssets';
import { Particle, disposeParticle, getParticle } from '../particles';
import { ScoreboardData } from '@/game/multithread/state';
import { Preset } from '@/game/infos/preset';

const mobIconGap = 33;

class NemesisStageSymbol extends Container {
	public text1!: Text;
	public text2!: Text;
}
class NemesisLvlSymbol extends Container {
	public text1!: Text;
	public text2!: Text;
}

export class HpLabelSymbol extends Container {

	public graphics = new Graphics();

	public hpText = new BitmapText('300', { fontName: 'TitanOneShaded', fontSize: 20, align: 'center' });

	private _hp = 100;
	public get hp() {
		return this._hp;
	}
	public set hp(value) {
		if (value === this._hp) {
			return;
		}
		this._hp = value;
		this.hpText.text = value.toFixed(0);
		this.redrawBar();
	}
	private _maxHp = 100;
	public get maxHp() {
		return this._maxHp;
	}
	public set maxHp(value) {
		this._maxHp = value;
		this.redrawBar();
	}
	private _barWidth = 100;
	public get barWidth() {
		return this._barWidth;
	}
	public set barWidth(value) {
		this._barWidth = value;
		this.redrawBar();
	}
	private _barHeight = 4;
	public get barHeight() {
		return this._barHeight;
	}
	public set barHeight(value) {
		this._barHeight = value;
		this.redrawBar();
	}
	constructor() {
		super();
		this.addChild(this.graphics);

		this.addChild(this.hpText);
		this.hpText.anchor.set(.5, .5);
		this.hpText.y = -8;
	}

	protected redrawBar() {
		this.graphics.clear();
		if (this.hp > 0) {
			this.graphics.beginFill(0x00A500, 0.9);
			this.graphics.drawRect(
				-this.barWidth / 2,
				0,
				(this.hp / this.maxHp) * this.barWidth,
				this.barHeight,
			);
		}
	}
}
export class BossLabelSymbol extends MovieClip {
	public get hp() {
		return this._hp;
	}
	public get stage() {
		return this._stage;
	}
	public get maxHps() {
		return this._maxHps;
	}
	public set maxHps(value) {
		this._maxHps = [...value].reverse();
		this.redrawBar();
	}
	public get barWidth() {
		return this._barWidth;
	}
	public set barWidth(value) {
		this._barWidth = value;
		this.redrawBar();
	}
	public get barGap() {
		return this._barGap;
	}
	public set barGap(value) {
		this._barGap = value;
		this.redrawBar();
	}
	public get barHeight() {
		return this._barHeight;
	}
	public set barHeight(value) {
		this._barHeight = value;
		this.redrawBar();
	}

	public text1!: Text;
	public text2!: Text;
	public graphics = new Graphics();

	public hpText = new BitmapText('300', { fontName: 'TitanOneShaded', fontSize: 20, align: 'center' });
	public colors = [0x00A500, 0xCC66CC, 0xFFFF66];

	private _hp = 100;
	private _stage = 100;
	private _maxHps = [100];
	private _barWidth = 300;
	private _barGap = 4;
	private _barHeight = 8;
	public setName(name: string) {
		this.text1.text = this.text2.text = name;
	}
	public setHp(value: number, stage: number) {
		if (value === this._hp && stage === this._stage) {
			return;
		}
		this._hp = value;
		this._stage = stage;
		this.hpText.text = value.toFixed(0);
		this.redrawBar();
	}

	public init() {

		this.addChild(this.graphics);

		this.addChild(this.hpText);
		this.hpText.anchor.set(.5, .5);
		this.hpText.y = -8;
	}
	protected redrawBar() {
		this.graphics.clear();
		let i = 0;
		const toDraw = this.maxHps.length - this.stage - 1;
		while (i < toDraw) {
			this.graphics.beginFill(this.colors[i % this.colors.length], 0.9);
			this.graphics.drawRect(
				-this.barWidth / 2,
				i * (this.barHeight + this.barGap),
				this.barWidth,
				this.barHeight,
			);
			i++;
		}
		if (this.hp > 0) {
			const maxHp = this.maxHps[i];
			this.graphics.beginFill(this.colors[i % this.colors.length], 0.9);

			this.graphics.drawRect(
				-this.barWidth / 2,
				i * (this.barHeight + this.barGap),
				(this.hp / maxHp) * this.barWidth,
				this.barHeight,
			);

		}
	}

}
interface JobuffParticle extends Sprite {
	pid: Particle,
}
export class PlayerLabelSymbol extends MovieClip {

	public isStrongArmer = false;
	public text1!: Text;
	public text2!: Text;
	public crown!: Container;
	public bossIcon!: Container;
	public nemesisStageLabel!: NemesisStageSymbol;
	public nemesisLabel!: NemesisLvlSymbol;
	public mobIconContainer = new Container();
	public jobuffContainer = new Container();
	public graphics = new Graphics();

	public hpText = new BitmapText('300', { fontName: 'TitanOneShaded', fontSize: 20, align: 'center' });


	public noobBadge: SkillSprite | null = null;
	public lastLvl = 0;
	public jobuff = 0;

	public init() {
		this.crown.visible = false;
		for (let i = 0; i < 5; i++) {
			const mobIcon = Factory.getByName('MobIcon');
			this.mobIconContainer.addChild(mobIcon);
			mobIcon.x = i * mobIconGap;
		}
		this.mobIconContainer.y = -102;
		this.addChild(this.graphics);

		this.addChild(this.hpText);
		this.hpText.anchor.set(.5, .5);
		this.hpText.y = -78;


		this.removeChild(this.nemesisStageLabel);
		this.nemesisLabel.text1.x = 43;
		this.nemesisLabel.text2.x = 44;
		this.nemesisStageLabel.text1.x = 16;
		this.nemesisStageLabel.text2.x = 16.75;
		this.nemesisStageLabel.y = -64;
		this.isStrongArmer = false;

		this.jobuffContainer.y = 110;
	}

	public setData(data: PlayerData) {
		this.lastLvl = -1;
		this.text1.tint = 0xffffff;
		if (data.playerType === PlayerType.Player) {
			this.text1.visible = this.text2.visible = true;
			this.text1.text = this.text2.text = data.name;
			if (this.text1.width > 320) {
				this.text1.text = this.text2.text = data.name.substring(0, 2);
			}
			this.addChild(this.text2);
			this.addChild(this.text1);
			this.addChild(this.nemesisStageLabel);
			this.removeChild(this.mobIconContainer);
			this.removeChild(this.nemesisLabel);
			this.removeChild(this.bossIcon);
		} else if (data.playerType === PlayerType.Nemesis) {
			this.nemesisLabel.text1.text = this.nemesisLabel.text2.text = data.mobLvl.toString();
			data.mobLvl.toString();
			this.removeChild(this.mobIconContainer);
			this.addChild(this.nemesisLabel);
			this.removeChild(this.nemesisStageLabel);
			this.removeChild(this.text2);
			this.removeChild(this.text1);
			this.removeChild(this.bossIcon);
		} else if (data.mobLvl === 7) {
			this.text1.visible = this.text2.visible = true;
			this.text1.text = this.text2.text = data.name;
			this.text1.tint = 0xff3560;
			this.addChild(this.text2);
			this.addChild(this.text1);
			this.addChild(this.bossIcon);
			this.addChild(this.nemesisStageLabel);
			this.removeChild(this.mobIconContainer);
			this.removeChild(this.nemesisLabel);
		} else {
			this.removeChild(this.text2);
			this.removeChild(this.text1);
			this.removeChild(this.nemesisLabel);
			this.removeChild(this.nemesisStageLabel);
			this.removeChild(this.bossIcon);
			if (data.playerType === PlayerType.Mob && data.mobLvl < 6) {
				this.addChild(this.mobIconContainer);
				this.mobIconContainer.x = -(data.mobLvl - 1) * mobIconGap / 2;
				for (let i = 0; i < 5; i++) {
					const mobIcon = this.mobIconContainer.children[i];
					mobIcon.visible = i < data.mobLvl;
				}
			} else {
				this.removeChild(this.mobIconContainer);
			}
		}
	}

	public update(data: PlayerData, ranks: ScoreboardData[], armageddonStarted: boolean) {

		if (this.jobuff !== data.jobuff) {
			if (data.jobuff > 0) {
				this.addChild(this.jobuffContainer);
				for (const child of (this.jobuffContainer.children as JobuffParticle[])) {
					disposeParticle(child.pid, child);
				}
				this.jobuffContainer.removeChildren();
				// const jobuffs = data.jobuff.toString();
				// console.log(jobuffs);
				const pid = data.jobuff >= 7 ? Particle.JobuffYellow : data.jobuff >= 5 ? Particle.JobuffSilver : Particle.JobuffRed;
				for (let i = 0; i < data.jobuff; i++) {
					const buffIcon = getParticle(pid);
					this.jobuffContainer.addChild(buffIcon);
					buffIcon.x = i * 27.5;
					buffIcon.scale.set(0.5);
					(buffIcon as JobuffParticle).pid = pid;
					this.jobuffContainer.x = -this.jobuffContainer.width / 2 + 22.5 / 2;
				}
			} else {
				this.removeChild(this.jobuffContainer);
			}
		}
		this.jobuff = data.jobuff;
		this.visible = data.bodyRadius > 10;
		if (data.noobLvl !== this.lastLvl) {
			if (this.noobBadge) {
				this.noobBadge.x = this.noobBadge.y = 0;
				disposeSkillSprite(this.noobBadge);
				this.removeChild(this.noobBadge);
			}
			if (data.noobLvl === 1) {
				this.noobBadge = getPassiveSprite(PassiveSkill.NewbieDefBonus);
			} else if (data.noobLvl === 2) {
				this.noobBadge = getPassiveSprite(PassiveSkill.RookieDefBonus);
			} else {
				this.noobBadge = null;
			}
			if (this.noobBadge) {
				this.noobBadge.x = - this.text1.width / 2 - 15;
				this.addChild(this.noobBadge);
				this.noobBadge.y = -105;
			}
			this.lastLvl = data.noobLvl;
		}

		// this.text1.tint = data.mobLvl === PlayerMode.PvP ? 0xff6363 : 0xffffff;
		if (data.id === (ranks[1]?.pid || 0)) {
			this.crown.visible = true;
			this.crown.scale.set(1);
			(this.crown.children[0] as Sprite).tint = 0xFFCC00;
		} else if (data.id === (ranks[0]?.pid || 0)) {
			this.crown.visible = true;
			this.crown.scale.set(0.7);
			(this.crown.children[0] as Sprite).tint = 0xFF6384;
		} else {
			this.crown.visible = false;
		}
		if (data.playerType === PlayerType.Player) {
			if (armageddonStarted || data.y > Preset.SECTOR_SIZE) {
				this.text1.tint = 0xffffff;
				this.text1.scale.set(1);
				this.text2.scale.set(1);
			} else {
				this.text1.tint = 0xb6ff00;
				this.text1.scale.set(1.2);
				this.text2.scale.set(1.2);
			}
		}
		if (data.nmsStage) {
			this.nemesisStageLabel.visible = true;
			this.nemesisStageLabel.text1.text = this.nemesisStageLabel.text2.text = data.nmsStage.toString();
		} else {
			this.nemesisStageLabel.visible = false;
		}

		this.x = data.x;
		this.y = data.y;
		if (this.isStrongArmer) {
			this.y -= 80;
		}

		this.hpText.text = data.hp.toFixed(0);

		this.graphics.clear();
		if (data.hp > 0) {
			const hp = Math.min(data.hp, 2000);
			const maxhp = Math.min(data.maxHp, 2000);
			this.graphics.beginFill(0x00A500, 0.9);
			this.graphics.drawRect(
				-maxhp / 6 / 2,
				-70,
				hp / 6,
				4,
			);
		}
		// if (data.stun > 0) {
		// 	this.graphics.beginFill(0x00A5A5, 0.9);
		// 	this.graphics.drawRect(
		// 		-25,
		// 		-78,
		// 		data.stun / 50,
		// 		4,
		// 	);
		// }
	}
}
