import { Dictionary } from '@/util/dictionary';
import { FishType, fishTiersAndBelow } from './fishInfos';
import { SpearType } from './spearInfos';

export interface JoinRoomOptions {
	fishes?: FishType[][],
	weapons?: SpearType[],
	// spears?: SpearOption[][],
}

export enum RoomType {
	Armageddon,
}

export enum GameType {
	None,
	Armageddon,
	Tourney,
	Boss,
	Championship,
}
export const fishRank: FishType[] = [
	FishType.Goldfish,
	FishType.BlueShark,
	FishType.KillerWhale,
	FishType.SakuraShrimp,
	FishType.MantaRay,
	FishType.Pufferfish,
	FishType.Stingray,
	FishType.DumboOctopus,
	FishType.MorayEel,
	FishType.HammerHead,
	FishType.FirebellyNewt,
	FishType.HumpbackWhale,
	FishType.Narwhal,
	FishType.BottlenoseDolphin,
	FishType.GreenSeaTurtle,

	FishType.Goldenfish,
	FishType.GulperEel,
	FishType.MantraRay,
	FishType.Tako,
	FishType.FireSalamander,
	FishType.Beluga,
	FishType.LeatherbackTurtle,
	FishType.CommonDolphin,
	FishType.SerialKillerWhale,
	FishType.BatRay,
	FishType.Porcupinefish,
	FishType.HammeredHead,
	FishType.PistolShrimp,
	FishType.GreatWhiteShark,
	FishType.BlueWhale,

	FishType.BlueRay,
	FishType.MantisShrimp,
	FishType.ElectricRay,
	FishType.ElectricEel,
	FishType.Jomama,
	FishType.PsychoKillerWhale,
	FishType.AlligatorSnappingTurtle,
	FishType.WhammyHead,
	FishType.Megalodon,
	FishType.Kraken,
	FishType.SpinnerDolphin,
	FishType.Diamondfish,
	FishType.Axolotl,
	FishType.Onifugu,
	FishType.ArchBeluga,
];
