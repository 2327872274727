import BootstrapVue from 'bootstrap-vue';
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/scss/global.scss';
import PortalVue from 'portal-vue';

import Factory from './client/factory';
import { Decoratives } from './components/ui/mainMenu/tools/decoEditor/decorative';
import { Global } from './store/globalz';

(window as any).Factory = Factory;
(window as any).Decoratives = Decoratives;
(window as any).Global = Global;

Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);
