export enum FoodType {
	None,
	Algae,
	Shrimp,
	Fillet,
	Salmon,
	Pumpkin,
	Coin,
}

export const numFoodType = 6;
