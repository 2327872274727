
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { DecoContestVotableEntry } from '@/game/infos/dataServerPackets';
import userx from '@/store/modules/userx';
import fb from '@/store/sf-firestore';
import DecoEntryForVote from './DecoEntryForVote.vue';
import globalx from '@/store/modules/globalx';
import { eventTimes, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';
import { ordinalSuffix } from '@/util/number';

@Component({
	components: {
		DecoEntryForVote,
	},
})
export default class DecoVoteComponent extends Vue {

	public get contestOrdinal() {
		return globalx.decoContestOrdinal;
	}
	public get votingPeriod() {
		const d1 = new Date(eventTimes[TimeLimitedQuestCode.DecoVote][0]);
		const d2 = new Date(eventTimes[TimeLimitedQuestCode.DecoVote][1]);
		const str = `${d1.getDate()} ~ ${new Intl.DateTimeFormat(
			'en-UK', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour12: true,
			hour: '2-digit',
			minute: '2-digit',
		}).format(d2)}`;

		return str;
	}
	public entries: DecoContestVotableEntry[] = Array.from({ length: 10 }).fill({
		uid: '',
		decoSet: '',
		skin: null,
		fishType: 0,
		numLikes: 0,
		numDislikes: 0,
		liked: false,
		disliked: false,
		reported: false,
	}) as DecoContestVotableEntry[];

	public width = 640;
	public height = 460;
	public myStyle = { height: '460px' };
	public contentStyle = { transform: 'scale(1)' };

	public mounted() {
		this.loadEntries();
		this.onResize();
		window.addEventListener('resize', this.onResize);
	}
	public beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	}
	public onResize() {
		this.width = this.$el.clientWidth;
		this.height = (this.width / 640) * 460;
		this.myStyle = { height: this.height + 'px' };
		this.contentStyle = { transform: 'scale(' + this.width / 640 + ')' };
	}
	public get numEntries() {
		return 2;
	}
	public get culledEntries() {
		return this.entries.slice(0, this.numEntries);
	}

	public async loadEntries() {
		this.entries = await fb.getDecoEntriesForVote(globalx.decoContest, this.numEntries);
	}
}
