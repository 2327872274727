import { getParticle, Particle } from '@/client/factory/particles';
import { BroadcastDamageEvent } from '@/game/multithread/state';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Tween } from '@/util/tweents';

export class LegendaryItemBlingEffectControl {

	public static get(x: number, y: number, vx: number = 0, vy: number = 0, scale: number = 1) {
		const control = LegendaryItemBlingEffectControl._pool.get();
		control.init(x, y, vx, vy, scale);
		return control;
	}
	private static _pool: Pool<LegendaryItemBlingEffectControl> = new Pool(LegendaryItemBlingEffectControl);

	public symbol = getParticle(Particle.LegendaryItemBling);

	public init(x: number, y: number, vx: number = 0, vy: number = 0, extraScale: number = 1) {
		this.symbol.x = x;
		this.symbol.y = y;
		this.symbol.angle = 0;
		this.symbol.scale.set(0.1 * extraScale);
		const scale = Math.random() * 1 + 2;
		Tween.get(this.symbol.scale).to({ x: scale * extraScale, y: scale * extraScale }, 300).to({ x: 0.1, y: 0.1 }, 300).call(this.dispose.bind(this));
		Tween.get(this.symbol).to({ angle: 90, x: x + vx, y: y + vy }, 600);
	}

	public dispose() {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		LegendaryItemBlingEffectControl._pool.pool(this);
	}
}
