


















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import userx from '@/store/modules/userx';
import globalx from '@/store/modules/globalx';

@Component({
  components: {},
})
export default class VerifyEmailWarningModal extends Vue {
  @Prop({ default: false }) public value!: boolean;
  public neverShowAgain: boolean = false;
  public get email() {
    return userx.user?.email || '';
  }
  public get titleHtml() {
    return `<i class="fa fa-exclamation-triangle"></i> Email not verified`;
  }
  public onHide() {
    if (this.neverShowAgain) {
      userx.changeUserSettings({
        nvrVerificationWarning: true,
      });
    } else {
      userx.changeUserSettings({
        nextVerificationWarning: globalx.time + 7 * 24 * 60 * 60000,
      });
    }
  }

  public async onClick() {
    this.$bvModal.hide('verify-email-warning-modal');
    await userx.sendEmailVerification();
    this.$bvModal.msgBoxOk(
      'Verification email sent, please check your inbox or junk mail.',
      {
        titleHtml: `<i class="fa fa-check-circle"></i> Success`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        modalClass: 'funny-modal',
        centered: true,
      },
    );
  }


}
