










































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gamex from '@/store/modules/gamex';
import { Global } from '@/store/globalz';
import { GameCommandType, IGameCommand, CommandDojoIdAction, CommandDojoHostAction } from '@/game/multithread/command';
import ScrollContainer from '../../ScrollContainer.vue';
import userx from '@/store/modules/userx';

@Component({
	components: {
		ScrollContainer,
	},
})
export default class DojoSettingsModal extends Vue {

	public get users() {
		return gamex.dojoInvites.map((uid) => ({
			name: gamex.users[uid]?.name || gamex.userNameCaches[uid] || `Stabfish_${uid.slice(0, 3)}`,
			uid,
			isHost: gamex.users[uid] && gamex.users[uid].dojoId === gamex.currentGameState.dojoId,
			joined: gamex.dojoMembers.includes(uid),
			spectate: gamex.dojoSpectators.includes(uid),
			isOffline: gamex.users[uid] ? false : true,
		}));
	}
	public get others() {
		return Object.values(gamex.users).filter((u) => !gamex.dojoInvites.includes(u.uid)).sort((a, b) => a.name.localeCompare(b.name));
	}

	public get numInvites() {
		return this.users.length;
	}

	public get me() {
		return Object.values(gamex.users).find((u) => u.uid === userx.uid);
	}
	public get isHost() {
		return this.me && gamex.currentGameState.dojoId === this.me.dojoId;
	}

	public async onLeaveDojo() {
		const command: IGameCommand = {
			type: GameCommandType.DojoLeave,
			sessionId: Global.clientCommunicator.sessionId,
		};
		Global.clientCommunicator.sendCommand(command);

		Global.$root.$bvModal.hide('dojoSettings-modal');
	}
	public async onDisbandDojo() {
		const command: IGameCommand = {
			type: GameCommandType.DojoDisband,
			sessionId: Global.clientCommunicator.sessionId,
		};
		Global.clientCommunicator.sendCommand(command);

		Global.$root.$bvModal.hide('dojoSettings-modal');
	}
	public async kick(uid: string) {
		const command: CommandDojoHostAction = {
			type: GameCommandType.DojoKick,
			sessionId: Global.clientCommunicator.sessionId,
			uid,
		};
		Global.clientCommunicator.sendCommand(command);

	}
	public async invite(uid: string) {
		const command: CommandDojoHostAction = {
			type: GameCommandType.DojoInvite,
			sessionId: Global.clientCommunicator.sessionId,
			uid,
		};
		Global.clientCommunicator.sendCommand(command);

	}
	public async makeHost(uid: string) {
		const command: CommandDojoHostAction = {
			type: GameCommandType.DojoChangeHost,
			sessionId: Global.clientCommunicator.sessionId,
			uid,
		};
		Global.clientCommunicator.sendCommand(command);

	}
	public async spectate(uid: string, value: boolean) {
		const command: CommandDojoHostAction = {
			type: value ? GameCommandType.DojoSpectate : GameCommandType.DojoFight,
			sessionId: Global.clientCommunicator.sessionId,
			uid,
		};
		Global.clientCommunicator.sendCommand(command);

	}
	public onShow() {
		gamex.updateIvModalShowing(true);
	}
	public onHide() {
		gamex.updateIvModalShowing(false);
		Global.stageControl.uiControl.closeDojoSettings();
	}

}
