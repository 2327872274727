import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsFakeSword, MiscDataSsSwordDance } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { BrokenSword, BurningEffect, FrozenEffect, ParalyzedEffect } from '../hazardMixin';
import { MiscSymbol } from './miscSymbol';
import { Preset } from '@/game/infos/preset';
import { SpearSprite, disposeSpear, getSpear } from '../playerModel';

export class SsSwordDanceController implements MiscSymbol {

	public static get() {
		return SsSwordDanceController._pool.get();
	}

	private static _pool: Pool<SsSwordDanceController> = new Pool(SsSwordDanceController);
	public area: Graphics = new Graphics();

	public data: MiscData | null = null;


	constructor() {
		this.area.lineStyle(1, 0xFFFF00, 0.75);
		this.area.beginFill(0xffff99, 0.15);
		this.area.drawCircle(0, 0, 300);
	}
	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		SsSwordDanceController._pool.pool(this);

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsSwordDance;
		this.area.x = data.x;
		this.area.y = data.y;

		// if (!this.data) {
		// 	this.area.scale.set(data.s);
		// }
		this.data = data;
	}
	public show(room: RoomControl) {
		room.wallBottomContainer.addChild(this.area);
		// room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		// if (this.parent) {
		// 	this.parent.removeChild(this);
		// }
	}
}

export class SsFakeSwordController implements MiscSymbol {
	public static get() {
		return SsFakeSwordController._pool.get();
	}

	private static _pool: Pool<SsFakeSwordController> = new Pool(SsFakeSwordController);


	public data: MiscDataSsFakeSword | null = null;

	public symbol = new Container();
	public sprite: Sprite | null = null;
	public brokenSword: BrokenSword | null = null;

	public dispose(room: RoomControl) {
		if (this.sprite) {
			this.symbol.removeChildren();
			disposeSpear(this.sprite as SpearSprite);
			this.symbol.scale.set(1);
			this.sprite.x = this.sprite.y = this.sprite.angle = 0;
			this.sprite = null;

			if (!this.brokenSword) {
				this.symbol.parent?.removeChild(this.symbol);
			}
		}
		this.data = null;

		SsFakeSwordController._pool.pool(this);
	}

	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataSsFakeSword;
		this.symbol.scale.set(data.sc);
		if (data.st === -1) {
			if (this.sprite) {
				this.symbol.removeChildren();
				disposeSpear(this.sprite as SpearSprite);
				this.sprite.x = this.sprite.y = this.sprite.angle = 0;
				this.symbol.scale.set(1);
				this.sprite = null;
			}
			if (!this.brokenSword) {
				const brokenSword = this.brokenSword = Factory.get(BrokenSword);
				this.symbol.addChild(brokenSword);
				const height = data.lvl * Preset.SPEAR_GRID_HEIGHT + 30;
				brokenSword.x = -height / 2;
				brokenSword.y = 0;
				brokenSword.angle = 90;
				brokenSword.gotoAndPlay(0);
				brokenSword.scale.set(height / 217);
				brokenSword.on('disposed', () => {
					this.symbol.parent?.removeChild(this.symbol);
				});
				const dist = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
				new SoundEfx('weaponBreak').play({ volume: dist * .4 });
			}
		} else {

			if (!this.sprite) {
				const spearLvl = (Math.floor(data.lvl) - Preset.SPEAR_MIN_LEVEL) || 0;
				this.sprite = getSpear(data.st, spearLvl);
				this.sprite.angle = 90;
				this.sprite.x = -data.lvl * Preset.SPEAR_GRID_HEIGHT * 0.5 + 12 + spearLvl * 3;
				this.symbol.addChild(this.sprite);
			}
		}


		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.symbol.angle = data.d;

		this.data = data;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this.symbol);
	}
	public hide(room: RoomControl) {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
	}
}
