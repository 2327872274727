import { Dictionary } from '@/util/dictionary';
import { ValueRarityPair } from '@/util/random';
import { fishInfos, FishType } from './fishInfos';
import { Rarity } from './itemInfos';

export enum SkinGroup {
	None,
	First,
}

export function encodeSkinId(skinGroup: SkinGroup, fishType: FishType) {
	return skinGroup * 1000 + fishType;
}

export function decodeSkinId(skinId: number) {
	const skinGroup = Math.floor(skinId / 1000);
	const fishType = skinId % 1000;
	return { skinGroup, fishType };
}

export const legendarySkins: Dictionary<number[] | undefined> = {
	1: [
		FishType.ArchBeluga,
		FishType.Onifugu,
		FishType.Kraken,
		FishType.AlligatorSnappingTurtle,
		FishType.WhammyHead,
		FishType.BlueRay,
		FishType.MantisShrimp,
		FishType.Axolotl,
		FishType.Sailfish,
		FishType.Dragon,
	],
};
export const epicSkins: Dictionary<number[] | undefined> = {
	1: [
		FishType.Megalodon,
		FishType.PsychoKillerWhale,
		FishType.Jomama,
		FishType.Diamondfish,
		FishType.ElectricEel,
		FishType.SpinnerDolphin,
		FishType.Beluga,
		FishType.GreatWhiteShark,
		FishType.HammeredHead,
		FishType.MantraRay,
		FishType.LeatherbackTurtle,
		FishType.GulperEel,
		FishType.BatRay,
		FishType.ElectricRay,
		FishType.PistolShrimp,
		FishType.Cuttlefish,
		FishType.ColossalSquid,
		FishType.Marlin,
		FishType.KingCrab,
		FishType.SpiderCrab,
		FishType.Mosasaurus,
		FishType.Oarfish,
	],
};

const raritiesForColor: Dictionary<Rarity> = {};
raritiesForColor[Rarity.Legendary] = Rarity.Legendary;
raritiesForColor[Rarity.Epic] = Rarity.Rare;
raritiesForColor[Rarity.Rare] = Rarity.Uncommon;
raritiesForColor[Rarity.Common] = Rarity.Common;

export interface SkinInfo {
	name: string,
	rarity: Rarity,
	rarityForColor: Rarity,
	skinGroup: SkinGroup,
	fishType: FishType,
	skinId: number,
}
export const skinInfos: Dictionary<SkinInfo[]> = {};

function setSkinInfos(skinGroup: SkinGroup) {
	skinInfos[skinGroup] = [];
	const numSkins = 60;
	for (let i = 0; i < numSkins; i++) {
		const fishType = i + 1;
		const name = fishInfos[fishType].name;
		let rarity = Rarity.Rare;
		if (epicSkins[skinGroup]?.includes(fishType)) {
			rarity = Rarity.Epic;
		} else if (legendarySkins[skinGroup]?.includes(fishType)) {
			rarity = Rarity.Legendary;
		}
		const rarityForColor = raritiesForColor[rarity];

		skinInfos[skinGroup][fishType] = {
			name,
			rarity,
			rarityForColor,
			skinGroup,
			fishType,
			skinId: encodeSkinId(skinGroup, fishType),
		};
	}
}

setSkinInfos(1);
