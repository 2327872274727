import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsDragonTrident } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { AnimatingContainers, getEmptyAnimatable, ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Graphics, Sprite, Text } from 'pixi.js';
import Factory from '../..';
import { getParticle, Particle } from '../../particles';
import { MiscSymbol } from './miscSymbol';
import { ElectricHazardSymbol } from '../hazardMixin';

// const info = specialSkillInfos[SpecialSkill.SwimmingFang];

export class SsDragonTridentSymbol implements MiscSymbol {
	public static get() {
		return SsDragonTridentSymbol._pool.get();
	}

	private static _pool: Pool<SsDragonTridentSymbol> = new Pool(SsDragonTridentSymbol);

	public data: MiscDataSsDragonTrident | null = null;
	public sound = new LongSoundEfx('dragonAxe');
	public electricSound = new LongSoundEfx('electricHazard');
	public electric = Factory.get(ElectricHazardSymbol);

	public constructor() {
		this.electric.scale.set(1 / 6);
		this.electric.activate();
	}

	public dispose(room: RoomControl) {
		this.data = null;

		this.sound.stop();
		this.electricSound.stop();
		this.electric.deactivate();
		if (this.electric.parent) {
			this.electric.parent.removeChild(this.electric);
		}

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsDragonTrident;

		const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		this.sound.volume = volume;
		if (!this.data) {
			this.sound.start();
			if (volume > .5) {
				room.cameraControl.swing(4 * (volume - .4), 500);
			}
		}
		if (data.pr) {
			this.electricSound.volume = volume * 0.1;
			this.electricSound.start();
			this.electric.visible = true;
		} else {
			this.electric.visible = false;
			this.electricSound.stop();
		}
		this.electric.x = data.x;
		this.electric.y = data.y;

		this.data = data;
	}

	public show(room: RoomControl) {
		room.playerBottomContainer.addChild(this.electric);
	}
	public hide(room: RoomControl) {
		if (this.electric.parent) {
			this.electric.parent.removeChild(this.electric);
		}

	}

}
