import { ItemCode } from '@/game/infos/itemInfos';
import { BLEND_MODES, Container, Sprite, Texture, TilingSprite } from 'pixi.js';
import Factory from '..';
import { getItemSprite } from '../itemParticles';
import { TempleChest } from './publiChestMixin';
import { ModeBanner } from './safeZoneMixin';

export class SecretTemple extends Container {

	public entrance = new Container();
	public idol = new Container();
	public ray = Sprite.from('mainAssets_goldenRays');
	public afterContainer = new Container();
	public templeChest = Factory.get(TempleChest);

	constructor() {
		super();

		const bg = Sprite.from('images/SecretTemple.jpg');
		this.addChild(bg);
		bg.y = bg.x = -512;

		const secretTempleEntranceBg = new TilingSprite(Texture.from('images/Tunnel_Wall.jpg'), 512, 980);
		secretTempleEntranceBg.alpha = .2;
		secretTempleEntranceBg.x = 512;
		secretTempleEntranceBg.y = -490;
		this.entrance.addChild(secretTempleEntranceBg);

		this.addChild(this.entrance);
		this.addChild(this.idol);
		const ray = this.ray;
		ray.blendMode = BLEND_MODES.ADD;
		ray.anchor.set(.5, .5);
		ray.scale.set(0.21);

		const idol = getItemSprite(ItemCode.GoldenIdol);
		// idol.anchor.set(.5, .5);
		this.idol.addChild(ray, idol);



		this.addChild(this.afterContainer);

		const note = Sprite.from('mainAssets_Mini_Note');
		note.anchor.set(.5, .5);
		note.y = 64;

		const armoryIv = Sprite.from('mainAssets_ArmoryIv');
		armoryIv.x = -486;
		armoryIv.y = 294;

		this.templeChest.y = 497;

		const modeBanner = Factory.get(ModeBanner);
		modeBanner.x = 400;
		modeBanner.y = 515;
		this.afterContainer.addChild(note, armoryIv, this.templeChest, modeBanner);
		this.afterContainer.visible = false;
	}
}
