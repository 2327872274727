

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import userx from '@/store/modules/userx';
@Component
export default class BaseInput extends Vue {
	@Prop({ default: false }) public readonly validateNow!: boolean;
	@Prop({ default: 'md' }) public readonly size!: string;
	@Prop({ default: false }) public readonly noNeedToShowIfValid!: boolean;
	@Prop() public value: any;

	public input: any;

	get state() {
		if (this.validateNow || (this._state !== undefined && this._state !== null)) {
			const result = this.validate(this.value);
			if (!result) {
				return false;
			}
			return this.noNeedToShowIfValid ? null : true;
		}
		this._state = null;
		return null;
	}

	protected _state: undefined | null | boolean = undefined;

	@Watch('value', { immediate: true })
	public onValueChanged(val: string, oldVal: string) {
		if (val !== null) {
			this.input = val;
			const result = this._validfy(this.input);
			if (result === null) {
				this.$emit('input', null);
			}
		}
	}

	public handleInput(e: any) {
		this.$emit('input', this._validfy(this.input));
	}
	public handleChange(e: any) {
		this.$emit('change', this._validfy(this.input));
	}
	public validate(value: any) {
		return true;
	}
	public mounted() {
		const result = this._validfy(this.input);
		if (result === null) {
			this.$emit('input', null);
		}
	}
	protected _validfy(value: any) {
		if (!this.validate(value)) {
			return null;
		}
		return value;
	}
}
