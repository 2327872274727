import { getEmojiSymbol } from '@/client/factory/assets/emojiAssets';
import { getParticle, Particle } from '@/client/factory/particles';
import { EmojiType } from '@/game/infos/emojiInfos';
import { Global } from '@/store/globalz';
import { MovieClip } from 'pixi-animate';
import { BitmapText, Container, Graphics } from 'pixi.js';

export class EmojiContainer extends Container {
	public bg = new Graphics();

	constructor() {
		super();
		this.addChild(this.bg);
		this.bg.beginFill(0x141617, 0.75);
		this.bg.drawRoundedRect(0, 0, 300, 100, 5);

		for (let i = 0; i < 10; i++) {
			let j = i + 1;
			if (j === 10) { j = 0; }
			const button = new EmojiButton(j, j);
			button.x = 60 * (i % 5) + 30;
			button.y = i >= 5 ? 75 : 25;
			this.addChild(button);
		}
	}
}

export class EmojiButton extends Container {
	public bg = getParticle(Particle.Circle);
	protected text;
	constructor(emojiType: EmojiType, key: number) {
		super();
		this.bg.scale.set(0.75);
		this.addChild(this.bg);
		const symbol = getEmojiSymbol(emojiType);
		this.addChild(symbol);
		if (symbol instanceof MovieClip) {
			symbol.gotoAndStop(symbol.totalFrames - 1);
		}
		if (symbol.width > 40 || symbol.height > 40) {
			symbol.scale.set(40 / Math.max(symbol.width, symbol.height));
		}
		this.text = new BitmapText(key.toString(), { fontName: 'TitanOneShaded', fontSize: 14, align: 'center' });

		this.addChild(this.text);
		this.text.anchor.set(.5, .5);
		this.text.x = -16;
		this.text.y = -16;

		this.bg.interactive = true;
		this.bg.buttonMode = true;
		const sendEmoji = () => {
			Global.stageControl.uiControl.sendEmoji(emojiType);
		};
		this.bg.on('click', sendEmoji);
		this.bg.on('touchstart', sendEmoji);
	}
}
