function ccw(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number) {
	const cw = ((y3 - y1) * (x2 - x1)) - ((y2 - y1) * (x3 - x1));
	return cw > 0 ? true : cw < 0 ? false : true; // colinear
}
export function intersect(seg1: [[number, number], [number, number]], seg2: [[number, number], [number, number]]) {
	const x1 = seg1[0][0], y1 = seg1[0][1],
		x2 = seg1[1][0], y2 = seg1[1][1],
		x3 = seg2[0][0], y3 = seg2[0][1],
		x4 = seg2[1][0], y4 = seg2[1][1];

	return ccw(x1, y1, x3, y3, x4, y4) !== ccw(x2, y2, x3, y3, x4, y4) && ccw(x1, y1, x2, y2, x3, y3) !== ccw(x1, y1, x2, y2, x4, y4);
}
