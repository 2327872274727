import { FishType } from '@/game/infos/fishInfos';
import { SkinGroup } from '@/game/infos/skinInfos';
import { Dictionary } from '@/util/dictionary';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { allTextureLoaded } from '../helper';
import { FishBody } from './playerModel';

declare const lib: any;
declare const s1: any;
export const skinGroups: Dictionary<any> = {
	s0: lib,
	s1,
};

const fishSkinNames: string[] = [];

fishSkinNames[FishType.BlueShark] = 'BlueShark';
fishSkinNames[FishType.GreatWhiteShark] = 'GreatWhiteShark';
fishSkinNames[FishType.Megalodon] = 'Megalodon';
fishSkinNames[FishType.KillerWhale] = 'KillerWhale';
fishSkinNames[FishType.SerialKillerWhale] = 'SerialKillerWhale';
fishSkinNames[FishType.PsychoKillerWhale] = 'PsychoKillerWhale';
fishSkinNames[FishType.HammerHead] = 'HammerHead';
fishSkinNames[FishType.HammeredHead] = 'HammeredHead';
fishSkinNames[FishType.WhammyHead] = 'WhammyHead';
fishSkinNames[FishType.HumpbackWhale] = 'HumpbackWhale';
fishSkinNames[FishType.BlueWhale] = 'BlueWhale';
fishSkinNames[FishType.Jomama] = 'Jomama';
fishSkinNames[FishType.MantaRay] = 'MantaRay';
fishSkinNames[FishType.MantraRay] = 'MantraRay';
fishSkinNames[FishType.BlueRay] = 'BlueRay';
fishSkinNames[FishType.Pufferfish] = 'Pufferfish';
fishSkinNames[FishType.Porcupinefish] = 'PorcupineFish';
fishSkinNames[FishType.Onifugu] = 'Onifugu';
fishSkinNames[FishType.BottlenoseDolphin] = 'BottlenoseDolphin';
fishSkinNames[FishType.CommonDolphin] = 'CommonDolphin';
fishSkinNames[FishType.SpinnerDolphin] = 'SpinnerDolphin';
fishSkinNames[FishType.Goldfish] = 'Goldfish';
fishSkinNames[FishType.Goldenfish] = 'Goldenfish';
fishSkinNames[FishType.Diamondfish] = 'Diamondfish';
fishSkinNames[FishType.Narwhal] = 'Narwhal';
fishSkinNames[FishType.Beluga] = 'Beluga';
fishSkinNames[FishType.ArchBeluga] = 'ArchBeluga';
fishSkinNames[FishType.DumboOctopus] = 'DumboOctopus';
fishSkinNames[FishType.Tako] = 'Tako';
fishSkinNames[FishType.Kraken] = 'Kraken';
fishSkinNames[FishType.MorayEel] = 'MorayEel';
fishSkinNames[FishType.GulperEel] = 'GulperEel';
fishSkinNames[FishType.ElectricEel] = 'ElectricEel';
fishSkinNames[FishType.GreenSeaTurtle] = 'GreenSeaTurtle';
fishSkinNames[FishType.LeatherbackTurtle] = 'LeatherbackTurtle';
fishSkinNames[FishType.AlligatorSnappingTurtle] = 'AlligatorSnappingTurtle';
fishSkinNames[FishType.Stingray] = 'StingRay';
fishSkinNames[FishType.BatRay] = 'BatRay';
fishSkinNames[FishType.ElectricRay] = 'ElectricRay';
fishSkinNames[FishType.SakuraShrimp] = 'SakuraShrimp';
fishSkinNames[FishType.PistolShrimp] = 'PistolShrimp';
fishSkinNames[FishType.MantisShrimp] = 'MantisShrimp';
fishSkinNames[FishType.FirebellyNewt] = 'FirebellyNewt';
fishSkinNames[FishType.FireSalamander] = 'FireSalamander';
fishSkinNames[FishType.Axolotl] = 'Axolotl';
fishSkinNames[FishType.VampireSquid] = 'VampireSquid';
fishSkinNames[FishType.Cuttlefish] = 'Cuttlefish';
fishSkinNames[FishType.ColossalSquid] = 'ColossalSquid';
fishSkinNames[FishType.Swordfish] = 'Swordfish';
fishSkinNames[FishType.Marlin] = 'Marlin';
fishSkinNames[FishType.Sailfish] = 'Sailfish';
fishSkinNames[FishType.BlueCrab] = 'BlueCrab';
fishSkinNames[FishType.KingCrab] = 'KingCrab';
fishSkinNames[FishType.SpiderCrab] = 'SpiderCrab';
fishSkinNames[FishType.Crocodile] = 'Crocodile';
fishSkinNames[FishType.Kronosaurus] = 'Kronosaurus';
fishSkinNames[FishType.Mosasaurus] = 'Mosasaurus';
fishSkinNames[FishType.Koi] = 'Koi';
fishSkinNames[FishType.Oarfish] = 'Oarfish';
fishSkinNames[FishType.Dragon] = 'Dragon';

const fishSkinPool: Dictionary<Pool<FishBody>> = {};

function getFishSkinPool(fishType: FishType, skinGroup: SkinGroup): Pool<FishBody> | null {
	const id = `${skinGroup}-${fishType}`;
	if (fishSkinPool[id]) {
		return fishSkinPool[id];
	}
	if (!skinGroup) { return null; }
	const library = skinGroups[`s${skinGroup}`];
	if (!library) { return null; }
	const assetClass = library[`Animated_${fishSkinNames[fishType] || 'XXX'}`];
	if (!assetClass) { return null; }
	fishSkinPool[id] = new Pool(assetClass);
	return fishSkinPool[id];
}

export async function getFishSkin(fishType: FishType, skinGroup: SkinGroup): Promise<FishBody | null> {

	const pool = getFishSkinPool(fishType, skinGroup);
	if (pool) {
		const body = pool.get();
		body.skinGroup = skinGroup;
		body.fishType = fishType;
		await allTextureLoaded(body);
		return body;
	}
	return null;
}

export function disposeFishSkin(fishBody: FishBody) {
	const pool = getFishSkinPool(fishBody.fishType, fishBody.skinGroup);
	if (pool) {
		pool.pool(fishBody);
	}
}

