import { PlayerControl } from '@/client/controls/playerControl';
import Factory from '@/client/factory';
import { PlayerSymbol } from '@/client/factory/assets/playerMixin';
import { decodeDecoSet, DecoSet } from '@/game/infos/decorativeInfos';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { Scene } from 'pixi-animate';
import { HighscoreData, ArmageddonHighscoreData, CraftingHighscoreData } from '@/game/infos/highscoreInfos';
import { spearLvlRequirements, SpearType } from '@/game/infos/spearInfos';
import { Preset } from '@/game/infos/preset';
import { Decoratives } from '../decoEditor/decorative';
import { allTextureLoaded } from '@/client/factory/helper';
import { Global } from '@/store/globalz';
import { Container } from 'pixi.js';
import { SkinGroup } from '@/game/infos/skinInfos';
import { DragonTridentSymbol } from '@/client/factory/assets/playerModel';

const scale = 0.6;
const app = Global.highscoreFishApp;
const w = app.view.width;
const h = app.view.height;

const stage = app.stage;
const container = new Container();
stage.addChild(container);
container.angle = -90;
container.y = h / 2;
container.x = w - 125;
container.scale.set(scale);
let symbol: PlayerSymbol;
let dragonBlades!: DragonTridentSymbol;

export async function generateHighscoreFishImage(fishType: FishType, spearType: SpearType, decoSet: string, scoreLeft: number, skinGroup: SkinGroup) {
	if (!symbol) {
		symbol = PlayerSymbol.get();
		symbol.setAction('idle');
		container.addChild(symbol);
		dragonBlades = Factory.get(DragonTridentSymbol);
		dragonBlades.angle = -90;
		dragonBlades.scale.set(1 / 1.3);
	}
	const spearLvl = calcSpearLvl(scoreLeft, spearType);
	const baseLevel = Math.floor(spearLvl);
	const fraction = spearLvl - baseLevel;
	const spearHeight = (baseLevel + fraction * Preset.SPEAR_MAX_SCALE_BEFORE_LVL_UP) * Preset.SPEAR_GRID_HEIGHT;
	// const sw = ((baseLevel - Preset.SPEAR_MIN_LEVEL) * Preset.SPEAR_WIDTH_INCREMENT_PER_LVL + Preset.SPEAR_WIDTH);
	if (!fishType) {
		fishType = 3;
	}
	const bodyScale = fishInfos[fishType].stat.size;

	symbol.setBodyScale(bodyScale);
	symbol.setSpear(spearType, baseLevel - Preset.SPEAR_MIN_LEVEL, spearHeight);
	if (spearType === SpearType.DragonAxe) {
		symbol.spearContainer.addChildAt(dragonBlades, 0);
		dragonBlades.y = (-18 - spearHeight) / 1.2;
	} else {
		symbol.spearContainer.removeChild(dragonBlades);
	}
	Decoratives.generateDeco(symbol, decodeDecoSet(decoSet));
	await Promise.all([symbol.setFishType(fishType, skinGroup), allTextureLoaded(symbol)]);
	app.start();
	app.render();
	app.stop();
	const result = app.view.toDataURL();
	return result;
}

function calcSpearLvl(score: number, spearType: SpearType) {
	const req = spearLvlRequirements[spearType];
	const len = req.length - 1;
	const maxScore = req[len];
	const spearMinLvl = Preset.SPEAR_MIN_LEVEL;
	if (score <= 0) { return spearMinLvl; }
	if (score >= maxScore) { return spearMinLvl + len; }
	let i = 0;
	while (score >= req[i]) {
		i++;
	}
	const curLvlReq = req[i - 1];
	const nextLvlReq = req[i];
	return (spearMinLvl + i - 1) + (score - curLvlReq) / nextLvlReq;
}
