export enum OreType {
	SpinelOre,
	GarnetOre,
	RubyOre,

	CopperOre,
	CitrineOre,
	GoldOre,

	PeridotOre,
	TurquoiseOre,
	EmeraldOre,

	LapisLazuliOre,
	AquamarineOre,
	SapphireOre,

	QuartzOre,
	AmethystOre,
	DiamondOre,

	IronOre,
	SilverOre,
	MythrilOre,
}
export enum OreColor {
	Red,
	Yellow,
	Green,
	Blue,
	Purple,
	White,
}
