import { fishInfos } from '@/game/infos/fishInfos';
import { Tween, Ease } from '@/util/tweents';
import { Container, Sprite, Text } from 'pixi.js';
import { rgbToInteger, tint } from '../helper';
import { PlayerSymbol } from './playerMixin';

export class ReincarnationContainer extends Container {

	public evolutionContainer!: Container;
	// public heartMask!: Container;
	public square!: Container;

	public life0: number = -1;
	public life1: number = -1;
	public life2: number = -1;

	public fish0 = new PlayerSymbol();
	public fish1 = new PlayerSymbol();
	public fish2 = new PlayerSymbol();

	private _fs0 = 1;
	private _fs1 = 1;
	private _fs2 = 1;
	public get fishShade0() {
		return this._fs0;
	}
	public set fishShade0(value: number) {
		this._fs0 = value;
		const v = 255 * value;
		tint(this.fish0, rgbToInteger(v, v, v));
	}
	public get fishShade1() {
		return this._fs1;
	}
	public set fishShade1(value: number) {
		this._fs1 = value;
		const v = 255 * value;
		tint(this.fish1, rgbToInteger(v, v, v));
	}
	public get fishShade2() {
		return this._fs2;
	}
	public set fishShade2(value: number) {
		this._fs2 = value;
		const v = 255 * value;
		tint(this.fish2, rgbToInteger(v, v, v));
	}


	public init() {
		this.evolutionContainer.addChild(this.fish0);
		this.evolutionContainer.addChild(this.fish1);
		this.evolutionContainer.addChild(this.fish2);
		this.fish1.x = 240;
		this.fish2.x = 480;
		this.fish0.setAction('idle');
		this.fish1.setAction('idle');
		this.fish2.setAction('idle');
		// this.square.mask = this.heartMask;
	}

	public start(life: number, life0: number, life1: number, life2: number) {
		this.square.y = -110;
		if (this.life0 !== life0) {
			this.life0 = life0;
			this.fish0.setFishType(life0, 0);
			this.fish0.setBodyScale(fishInfos[life0].stat.size);
		}
		if (this.life1 !== life1) {
			this.life1 = life1;
			this.fish1.setFishType(life1, 0);
			this.fish1.setBodyScale(fishInfos[life1].stat.size);
		}
		if (this.life2 !== life2) {
			this.life2 = life2;
			this.fish2.setFishType(life2, 0);
			this.fish2.setBodyScale(fishInfos[life2].stat.size);
		}
		this.evolutionContainer.x = -(life - 1) * 240;
		const black = 0;
		const white = 1;
		const gray = 0.25;
		this.fishShade0 = (life > 1 ? black : life === 1 ? white : gray);
		this.fishShade1 = (life === 2 ? white : gray);
		this.fishShade2 = (gray);

		const oldShade = 'fishShade' + (life - 1);
		const newShade = 'fishShade' + life;
		const to1: any = {};
		const to2: any = {};
		to1[oldShade] = 0;
		to2[newShade] = 1;
		Tween.get(this)
			.wait(300)
			.to(to1, 500)
			.wait(500)
			.to(to2, 500);

		Tween.get(this.evolutionContainer)
			.wait(300)
			.to({ x: -life * 240 }, 1000, Ease.cubicInOut);

	}
	public update(progress: number) {
		const offset = (1 - progress) * 24;
		const initY = -110;
		this.square.y = initY + offset;
	}
}
