



























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { Global } from '@/store/globalz';
import FishButton from './FishButton.vue';
import {
  FishInfo,
  fishInfos,
  fishTiers,
  FishType,
} from '@/game/infos/fishInfos';
import userx from '@/store/modules/userx';
import settingx from '@/store/modules/settingx';
import { FishIntelData } from '../fishSelector/FishIntelTooltip.vue';

@Component({
  components: {
    FishButton,
  },
})
export default class FishInventoryModal extends Vue {
  public get titleHtml() {
    return `<i class="fa fa-fish"></i> ${this.title}`;
  }

  public get unlocked() {
    return userx.fishUnlocked;
  }
  public get fishUnlockProgress() {
    return userx.fishUnlockProgress;
  }
  public resolve: null | ((value: FishType | null) => void) = null;
  public fishType: FishType = FishType.BlueShark;
  public okTitle = 'Choose';
  public title = 'Choose fish';

  public tiers1: FishInfo[][] = [fishTiers[0]];
  public tiers2: FishInfo[][] = [fishTiers[0], fishTiers[1]];
  public tiers3: FishInfo[][] = [fishTiers[0], fishTiers[1], fishTiers[2]];

  public inventory: FishInfo[][] = this.tiers1;
  public currentFishType: FishType = FishType.BlueShark;

  public show(currentFishType: FishType, tier: number) {
    if (this.resolve) {
      this.resolve(this.currentFishType);
    }
    // tier = 2;
    this.inventory = this['tiers' + (tier + 1)];
    this.currentFishType = currentFishType;
    this.fishType = currentFishType;

    const modal = this.$refs.modal as BModal;
    if (modal) {
      modal.show();
    }
    return new Promise((resolve: (value: FishType | null) => void) => {
      this.resolve = resolve;
    });
  }

  public select(fishType: FishType) {
    if (this.unlocked[fishType]) {
      this.fishType = fishType;
    } else {
      Global.fishUnlockModal.show(fishType);
    }
  }

  public onCustomize(hide: () => void) {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
    this.$root.$emit('customize', this.fishType);
    hide();
  }
  public onOk() {
    if (this.resolve) {
      this.resolve(this.fishType);
    }
    this.resolve = null;
  }

  public onCancel() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }
  public onClose() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }

  public showFishIntel(fishType: number) {
    const data: FishIntelData = {
      targetId: 'fish-inventory-' + fishType,
      placement: 'right',
      container: 'fish-inventory',
      fishType,
    };
    this.$root.$emit('showFishIntel', data);
  }
  public hideFishIntel() {
    this.$root.$emit('hideFishIntel');
  }
  public created() {
    Global.fishInvetoryModal = this;
  }
}
