


























import { SkillButtonSymbol } from '@/client/factory/assets/controls/SkillButtonMixin';
import {
  NormalSkillInfo,
  normalSkillInfos,
  SpecialSkillInfo,
  specialSkillInfos,
} from '@/game/infos/skills';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import settingx from '@/store/modules/settingx';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SkillButtonOverlay extends Vue {
  public get isMouseControl() {
    return settingx.now.control === 'mouse';
  }

  public get trigger() {
    if ((this.skill as SpecialSkillInfo).autoUse) {
      return 'Auto';
    }
    return this.index === 1
      ? settingx.now.skill1
      : this.index === 2
      ? settingx.now.skill2
      : settingx.now.skill3;
  }

  public get sk() {
    return (gamex.currentGameState['skill' + this.index] as number) || 0;
  }
  public skill: NormalSkillInfo | SpecialSkillInfo = normalSkillInfos[0];

  @Prop({ required: true }) public index!: number;

  public popoverId = random.v4();

  public skillButtonSymbol: SkillButtonSymbol | null = null;

  public updateBind = this.update.bind(this);

  public style: any = {};
  @Watch('sk', { immediate: true })
  public skChanged(sk: number) {
    this.skill = sk > 0 ? normalSkillInfos[sk] : specialSkillInfos[-sk];
  }

  public update() {
    const symbol = this.skillButtonSymbol;
    if (!symbol) {
      return;
    }

    const x = symbol.x;
    const y = symbol.y;
    const radius = 20 * symbol.scale.x;
    this.style = {
      left: `${x - radius}px`,
      top: `${y - radius}px`,
      width: `${radius * 2}px`,
      height: `${radius * 2}px`,
    };
  }

  public mounted() {
    const skillButtonSymbol = Global.stageControl.uiControl.symbol[
      `skill${this.index}`
    ] as SkillButtonSymbol;
    this.skillButtonSymbol = skillButtonSymbol;
    skillButtonSymbol.on('update', this.updateBind);
    this.update();
    this.$el.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }

  public beforeDestroy() {
    if (this.skillButtonSymbol) {
      this.skillButtonSymbol.off('update', this.updateBind);
    }
    this.$el.removeEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }
}
