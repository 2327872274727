


















import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  HighscoreData,
  ArmageddonHighscoreData,
  CraftingHighscoreData,
} from '@/game/infos/highscoreInfos';
import random from '@/util/random';
import { Queuer } from '@/util/queuer';
import { generateHighscoreFishImage } from '../tools/inventory/highscoreFishImageGenerator';

@Component({
  components: {},
})
export default class AmgdHighscoreComponent extends Vue {
  @Prop({ default: 0 }) public index!: number;
  @Prop() public data!: ArmageddonHighscoreData;

  public id = random.v4();
  public imgSrc = '';
  public lastCheckSum = '';
  public expand = false;

  public get name() {
    return this.data.name;
  }
  public get type() {
    return this.data.type;
  }
  public get badgeVariant() {
    return this.type === 'survivor' ? 'pink' : this.type === 'explorer' ? 'yellow' : 'danger';
  }
  public get serverName() {
    const split = this.data.serverName.split('|');
    return split[1] + '-' + split[2] + '-' + split[3];
  }
  public get timeUsed() {
    const time = this.data.timeUsed;
    if (this.type === 'gladiator') {
      return `${addComma(time)}`;
    }
    let secs = Math.floor((time % 60000) / 1000).toString();
    if (secs.length < 2) {
      secs = '0' + secs;
    }
    const mins = Math.floor(time / 60000);
    return `${mins}m ${secs}s`;
  }
  public get resultTitle() {
    return this.type === 'gladiator' ? this.timeUsed + ' PK Points' : 'Time Used';
  }
  public get isMe() {
    return this.data.uid === userx.uid;
  }

  public get checkSum() {
    return (
      this.data.fishType +
      '|' +
      this.data.spearType +
      '|' +
      this.data.scoreLeft +
      '|' +
      this.data.decoSet
    );
  }
  @Watch('checkSum', { immediate: true })
  public checkSumChanegd(newVal: string) {
    this.refreshImage();
  }
  @Watch('expand', { immediate: true })
  public expandChanegd(newVal: boolean) {
    this.refreshImage();
  }

  public async refreshImage() {
    if (!this.expand) {
      return;
    }
    if (this.lastCheckSum === this.checkSum) {
      return;
    }
    const checkSum = this.checkSum;
    this.lastCheckSum = checkSum;
    Queuer.queue('highscoreFish', async () => {
      if (checkSum !== this.checkSum) {
        return;
      }
      const { fishType, spearType, decoSet, scoreLeft, skin } = this.data;
      const imgSrc = await generateHighscoreFishImage(
        fishType,
        spearType,
        decoSet,
        scoreLeft,
        skin || 0,
      );
      if (checkSum !== this.checkSum) {
        return;
      }
      this.imgSrc = imgSrc;
    });
  }
}
