import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/p',
    component: () => import('../views/Portal.vue'),
    children: [
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../components/portal/ForgotPassword.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../components/portal/Register.vue'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../components/portal/Login.vue'),
      },
    ],
  },
  {
    path: '/b',
    component: () => import('../views/Black.vue'),
    children: [
      {
        path: '/terms',
        name: 'terms',
        component: () => import('../components/black/Terms.vue'),
      },
      {
        path: '/privacy',
        name: 'privacy',
        component: () => import('../components/black/Privacy.vue'),
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../components/black/UserPanel.vue'),
      },
      {
        path: '/partners',
        name: 'partners',
        component: () => import('../components/black/Partners.vue'),
      },
    ],
  }, {
    path: '/a',
    component: () => import('../views/AccountAssetLoaded.vue'),
    children: [
      {
        path: '/decoContest',
        name: 'DecoContest',
        component: () => import('../components/ui/decoContest/DecoContest.vue'),
      },
      {
        path: '/decoContest/vote',
        name: 'DecoContestVote',
        component: () => import('../components/ui/decoContest/DecoVote.vue'),
      },
      {
        path: '/decoContest/mod',
        name: 'DecoContestMod',
        component: () => import('../components/ui/decoContest/DecoContestMod.vue'),
      },
      {
        path: '/decoContest/result/',
        redirect: '../decoContest/result/1',
      },
      {
        path: '/decoContest/result/:contest',
        name: 'DecoContestResult',
        component: () => import('../components/ui/decoContest/DecoContestResultPage.vue'),
      },
    ],
  },
  {
    path: '/poq-connect',
    name: 'PoqConnect',
    component: () => import('../components/gacha/poq/PoqConnectPage.vue'),
  },
  // {
  //   path: '/gacha',
  //   name: 'Gacha',
  //   component: () => import('../components/black/GachaHome.vue'),
  // },
  {
    path: '/summon',
    redirect: 'summon/1',
  },
  {
    path: '/summon/:banner',
    name: 'Summon',
    component: () => import('../components/black/SummonHome.vue'),
  },
  {
    path: '/auth',
    component: () => import('../views/Auth.vue'),
    children: [
      {
        path: '/auth',
        name: 'auth',
        component: () => import('../components/auth/AuthAction.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/google_vignette',
    name: 'GoogleAd',
    component: Home,
  },
  {
    path: '*',
    component: () => import('../views/Black.vue'),
    children: [
      {
        path: '/',
        name: '404',
        component: () => import('../components/black/PageError.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'active',
});

export default router;

function parseQueryString(query: string) {
  const vars = query.split('&');
  const queryString: any = {};
  for (const val of vars) {
    const pair = val.split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof queryString[key] === 'undefined') {
      queryString[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof queryString[key] === 'string') {
      const arr = [queryString[key], decodeURIComponent(value)];
      queryString[key] = arr;
      // If third or later entry with this name
    } else {
      queryString[key].push(decodeURIComponent(value));
    }
  }
  return queryString;
}
router.onError((error) => {
  console.error(error);
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});
// beforeEnter navigation guard, afterEnter guard is dealed by App.vue and components
router.beforeEach((to, from, next) => {
  if (router.mode === 'hash') {
    const search = window.location.search.substring(1);
    if (search !== '') {
      const parsedParam = parseQueryString(window.location.search.substring(1));

      for (const key in parsedParam) {
        if (key === '') { continue; }
        to.query[key] = parsedParam[key];
      }
    }
    next();
  }
});

