import { OreColor } from '@/game/infos/oreInfo';
import { BottleData, FoodData, OreData } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Ease, Tween } from '@/util/tweents';
import { Sprite } from 'pixi.js';
import { getParticle, Particle } from '../factory/particles';


export class BottleControl {
	public static get(data: BottleData) {
		const control = BottleControl._pool.get();
		control.setData(data);
		return control;
	}
	private static _pool: Pool<BottleControl> = new Pool(BottleControl);
	public symbol = getParticle(Particle.BlueprintBottle);

	public id = NaN;
	public data!: BottleData;

	public tween = Tween.get(this.symbol, { loop: -1, autoStart: true })
		.to({ angle: -3 }, 0)
		.to({ angle: 3 }, 1000, Ease.sineInOut)
		.to({ angle: -3 }, 1000, Ease.sineInOut);


	public setData(data: BottleData) {

		this.id = data.id;
		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.data = data;
		this.tween.paused = false;

	}

	public dispose() {
		this.tween.paused = true;
		BottleControl._pool.pool(this);
	}

}
