import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsTentaBola } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';

export class SsTentaBolaSymbol extends Container implements MiscSymbol {

	public area!: Graphics;
	public ball1!: Container;
	public ball2!: Container;
	public line!: Container;

	public data: MiscDataSsTentaBola | null = null;

	// public sound = new LongSoundEfx('electricField');

	public init() {
		this.area = this.children[0] as Graphics;
	}
	public dispose(room: RoomControl) {
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
		Factory.pool(SsTentaBolaSymbol, this);
		// this.sound.stop();
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsTentaBola;

		this.x = data.x;
		this.y = data.y;
		this.angle = data.d;
		this.area.angle = data.d;

		if (!this.data) {
			this.area.visible = true;
			this.visible = false;
		}
		if (data.ac) {
			if (!this.visible) {
				// new SoundEfx('electricBolt').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
				this.visible = true;
			}
			if (this.area.parent) {
				this.area.parent.removeChild(this.area);
			}
			this.scale.set(data.sc);
			const iscale = 1 / data.sc;
			this.ball1.scale.set(iscale);
			this.ball2.scale.set(iscale);
			this.line.scale.x = iscale;

			const y = Math.cos(rt * 0.02);
			this.ball1.y = y * 250;
			this.ball2.y = -y * 250;
			this.line.scale.y = y;
		} else {
			this.area.x = data.x;
			this.area.y = data.y;
		}
		this.data = data;

		// const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		// this.sound.volume = volume;
	}
	public show(room: RoomControl) {
		if (!this.data || !this.data.ac) {
			room.wallBottomContainer.addChild(this.area);
		}
		room.playerTopContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}
}

// export class SsTentaWrappedSymbol extends Container implements MiscSymbol {

// 	public data: MiscData | null = null;
// 	public size = 1;
// 	// public sound = new LongSoundEfx('electricField');

// 	public dispose(room: RoomControl) {
// 		this.data = null;
// 		if (this.parent) {
// 			Tween.get(this.scale).to({ x: this.size * 1.2, y: this.size * 1.2 }, 200, Ease.circOut);
// 			Tween.get(this).to({ alpha: 0 }, 200, Ease.circOut).call(() => {
// 				this.parent.removeChild(this);
// 				Factory.pool(SsTentaWrappedSymbol, this);
// 			});
// 		}
// 		// this.sound.stop();
// 	}
// 	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
// 		const data = dt as MiscDataSsTentaBola;

// 		this.x = data.x;
// 		this.y = data.y;
// 		this.angle = data.d;

// 		this.size = data.sc;
// 		this.scale.set(this.size);
// 		if (!this.data) {
// 			this.alpha = 1;
// 		}
// 		this.data = data;

// 		// const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
// 		// this.sound.volume = volume;
// 	}
// 	public show(room: RoomControl) {
// 		room.playerTopContainer.addChild(this);
// 	}
// 	public hide(room: RoomControl) {
// 		if (this.parent) {
// 			this.parent.removeChild(this);
// 		}
// 	}
// }
