



























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SkillIconCanvasComponent from '@/components/canvas/SkillIconCanvasComponent.vue';
import { wait } from '@/util/wait';
import { ItemCode, itemInfos } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { blueprintInfos } from '@/game/infos/blueprintInfos';
import DecoItemCanvasComponent from '@/components/canvas/DecoItemCanvasComponent.vue';
import { decoDic } from '@/game/infos/decorativeInfos';
import { blueprintQuests } from '@/game/infos/blueprintQuestInfos';

export interface ItemIntelData {
  targetId: string;
  placement: 'top' | 'left' | 'right' | 'bottom';
  container: any;
  itemCode: ItemCode;
}

interface Material {
  name: string;
  possessed: number;
  required: number;
  sufficient: boolean;
}
@Component({
  components: {
    DecoItemCanvasComponent,
    SkillIconCanvasComponent,
  },
})
export default class ItemIntelToolTip extends Vue {
  public get itemInfo() {
    return itemInfos[this.itemCode];
  }
  public get rarityColor() {
    return Global.rarityColors[this.itemInfo.rarity];
  }
  public get rarity() {
    return Global.rarityLabels[this.itemInfo.rarity];
  }
  public get decoName() {
    return this.itemInfo.type !== 'blueprint'
      ? decoDic[this.itemCode] || ''
      : decoDic[blueprintInfos[this.itemCode].result] || '';
  }
  public get boxStyle() {
    const color = Global.rarityRgbs[this.itemInfo.rarity];
    return {
      width: '110px',
      height: '110px',
      border: `1px solid rgba(${color}, ${this.itemInfo.rarity * 0.15 + 0.2})`,
      background: `rgba(${color}, ${this.itemInfo.rarity * 0.05})`,
    };
  }
  public get blueprintQuest() {
    return (
      (blueprintQuests[this.itemCode] &&
        blueprintQuests[this.itemCode][0] &&
        blueprintQuests[this.itemCode][0][0]) ||
      null
    );
  }
  public get blueprintQuestProgress() {
    const progress = userx.userDoc.blueprintQuestProgress[this.itemCode] || {};
    return progress[0] || 0;
  }
  public get materialsNeeded(): Material[] {
    if (this.itemInfo.type !== 'blueprint') {
      return [];
    }
    const availables = userx.inventory.availables;
    const money = userx.inventory.money;
    const bpInfo = blueprintInfos[this.itemCode];
    return bpInfo.recipe.map((pair) => {
      const [itemCode, required] = pair;
      const name = itemInfos[itemCode].name;
      const possessed =
        itemCode === ItemCode.Money ? money : availables[itemCode];
      return { name, required, possessed, sufficient: possessed >= required };
    });
  }
  public get decoLimit() {
    if (this.itemInfo.type !== 'deco') {
      return 0;
    }
    return itemInfos[this.itemCode].decoLimit || 0;
  }

  public targetId = '';
  public placement = 'top';
  public container: any = null;
  public itemCode: ItemCode = ItemCode.None;
  public show = false;
  protected localShow = false;

  @Watch('localShow', { immediate: true })
  public async showChanged() {
    if (this.localShow) {
      await wait(100);
      if (this.localShow) {
        this.show = true;
      }
    } else {
      this.show = false;
    }
  }

  public mounted() {
    this.$root.$on('showItemIntel', this.showItemIntel);
    this.$root.$on('hideItemIntel', this.hideItemIntel);
  }

  public async showItemIntel(data: ItemIntelData) {
    this.targetId = data.targetId;
    this.placement = data.placement;
    this.container = data.container;
    this.itemCode = data.itemCode;
    // this.$nextTick(() => {
    this.localShow = true;
    // });
  }
  public hideItemIntel() {
    this.localShow = false;
    this.targetId = '';
  }
}
