
























































































import { SoundEfx } from '@/client/sound/SoundEfx';
import { ChatType } from '@/game/infos/eventType';
import {
	CommandChatAction,
	CommandMuteAction,
	CommandMuteAllAction,
	GameCommandType,
} from '@/game/multithread/command';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { wait } from '@/util/wait';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ContextMenuItem } from '../ContextMenu.vue';
import ScrollContainer from '../ScrollContainer.vue';
import ChatLogs from './ChatLogs.vue';
import UserComponent from './UserComponent.vue';
import { GameType } from '@/game/infos/roomInfos';
@Component({
	components: {
		ScrollContainer,
		ChatLogs,
		UserComponent,
	},
})
export default class ChatModal extends Vue {
	public tab: 'chats' | 'players' = 'chats';
	public msgInput = '';
	public mutedAll = false;
	public hideMini = false;
	public lastSent = 0;
	public get toShowChat() {
		return gamex.toShowChat;
	}
	@Watch('toShowChat')
	public onToShowChatChange(newVal: boolean) {
		if (newVal) {
			(this.$refs.msgInput as HTMLElement).focus();
			this.$root.$emit('scrollContainerCheck');
		}
	}
	public closeChat() {
		gamex.updateToShowChat(false);
	}
	public get chatLogs() {
		return gamex.chatLogs;
	}
	public get users() {
		const users = gamex.users;
		if (users[userx.uid!]) {
			this.mutedAll = users[userx.uid!].mutedAll;
		}
		return users;
	}
	public get numUsers() {
		return Object.values(this.users).length;
	}
	public get myScore() {
		return addComma(gamex.scoreLeft, 0);
	}
	public get outOfScore() {
		return this.isArmageddon && userx.uid !== 'fTaakPLzz0c8WTgQd8hkPZvaYu03' && gamex.scoreLeft < 20;
	}
	public get isArmageddon() {
		return gamex.gameType === GameType.Armageddon;
	}
	public onSubmit(e) {
		if (this.msgInput.trim() === '') {
			return;
		}
		if (this.outOfScore) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `You don't have enough score to chat.`,
			});
			new SoundEfx('chatFailed').play();
			return;
		}
		const ct = Date.now();
		if (ct < this.lastSent + 1500) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `Please do not spam.`,
			});
			new SoundEfx('chatFailed').play();
			return;
		}
		this.lastSent = ct;
		if (this.msgInput.length > 100) {
			gamex.addSystemMessage({
				chatType: ChatType.ChatSystem,
				t: gamex.currentGameState.t,
				msg: `Character limit 100.`,
			});
			new SoundEfx('chatFailed').play();
			return;
		}
		const command: CommandChatAction = {
			type: GameCommandType.Chat,
			sessionId: gamex.currentGameState.sid,
			msg: this.msgInput,
		};
		Global.clientCommunicator.sendCommand(command);
		this.msgInput = '';
	}

	public onContext(event: any, uid: string) {
		const me = gamex.users[userx.uid || ''];
		const user = gamex.users[uid];
		if (!user) {
			return;
		}

		const list: ContextMenuItem[] = [
			{
				icon: '',
				text: 'Mention',
				disabled: false,
				click: () => {
					this.mention(user.name);
				},
			},
		];
		if (userx.uid !== uid) {
			list.push({
				icon: '',
				text: user.muted ? 'Unmute' : 'Mute',
				disabled: false,
				click: () => {
					this.mute(uid, !user.muted);
				},
			});
		}
		if (me) {
			list.push({
				icon: '',
				text: me.mutedAll ? 'Unmute All' : 'Mute All',
				disabled: false,
				click: () => {
					this.muteAll(!me.mutedAll);
				},
			});
		}
		Global.contextMenu.open(event, list);
	}

	public mention(name: string) {
		if (this.msgInput) {
			this.msgInput += ' ';
		}
		this.msgInput += '@' + name + ' ';
		this.tab = 'chats';
		wait(500).then(() => {
			(this.$refs.msgInput as HTMLElement).focus();
		});
	}
	public mute(uid: string, value: boolean) {
		const command: CommandMuteAction = {
			type: GameCommandType.Mute,
			sessionId: gamex.currentGameState.sid,
			uid,
			value,
		};
		Global.clientCommunicator.sendCommand(command);
	}
	public muteAll(value: boolean) {
		const command: CommandMuteAllAction = {
			type: GameCommandType.MuteAll,
			sessionId: gamex.currentGameState.sid,
			value,
		};
		Global.clientCommunicator.sendCommand(command);
	}

	public onMutedAllChanged(checked: boolean) {
		this.muteAll(checked);
	}
	public onHideMiniChanged(checked: boolean) {
		this.$emit('hideMini', checked);
	}

	public get armageddonWinners() {
		if (!globalx.rankings || !gamex.currentGameServer) {
			return [];
		}
		const myServerName = gamex.currentGameServer.name.split('|');
		const region = myServerName.slice(0, -1).join('|');
		const sameServers = globalx.rankings.amgdHighscores.filter(
			(h) => h.serverName.indexOf(region) !== -1,
		);
		const lastHours = sameServers.filter((h) => h.time > Date.now() - 60 * 60000);
		return lastHours;
	}
}
