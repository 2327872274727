import { nonReact } from './nonReact';

export class Pool<T> {
    private _pool: T[] = [];
    private _theClass!: new () => T;

    constructor(theClass: any) {
        this._theClass = theClass;
    }

    public get(): T {
        const result = this._pool.shift() || nonReact(new this._theClass());
        return result;
    }

    public pool(obj: T) {
        if (this._pool.indexOf(obj) === -1) {
            this._pool.push(obj);
        }
    }
}
