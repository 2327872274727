
















import gamex from '@/store/modules/gamex';
import settingx from '@/store/modules/settingx';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ChatLogs from './ChatLogs.vue';

@Component({
  components: {
    ChatLogs,
  },
})
export default class MiniChatBox extends Vue {
  @Prop({ default: false }) public hideMini!: boolean;

  public get chatLogs() {
    return gamex.chatLogs.slice(-15);
  }
  public openChat() {
    gamex.updateToShowChat(true);
  }
  public get goLeft() {
    return settingx.now.leftHanded;
  }
  public get mutedAll() {
    return gamex.users[userx.uid!].mutedAll;
  }
  public get users() {
    return gamex.users;
  }
}
