import { Dictionary } from '@/util/dictionary';
import { FishType, fishTiersAndBelow } from './fishInfos';
import { SpearType } from './spearInfos';

export enum TouneyRoomType {
	Armageddon,
	TrainingGround,
	BasicArena,
	SmallArena,
}

export interface TourneyOptions {
	mapType: TourneyMapType,
	oneHpOnly: boolean,
	fishes: FishType[][],
	weapons: SpearType[],
	isPublic: boolean,
	totalRound: number,
}
export interface ClientTourneyPlayerData {
	uid: string,
	isOffline: boolean,
	isComputer: boolean,
	rank: number,
	name: string,
	isReady: boolean,
	isFighting: boolean,
	rankUp: boolean,
	muted: boolean,
	mutedYou: boolean,
}
export interface TourneyLobbyData {
	host: string,
	lifes: FishType[],
	weapon: SpearType,

	roomOptions: TourneyOptions,
	participants: Dictionary<ClientTourneyPlayerData>,
	quits?: string[],
	matches?: Dictionary<UpdateMatchData>,

	currentRound: number,
	startTime: number,
	lockTime: number,

	state: TourneyLobbyState,
}
export interface MatchData {
	mid: string,
	p1: string,
	p2: string,
	difficulty: number,
	result?: string,
	closed?: boolean,
}
export interface UpdateMatchData extends Partial<MatchData> {
	mid: string,
}

export enum TourneyLobbyState {
	Creating,
	Prelock, // able to change map and options, wll unready all players
	InterRound, // unable to change map and options, trigger when only 15 secs
	RoundStarted,
	AdBreak,
	Closing,
	Closed,
}

export interface TourneyEvent {
	type: TourneyEventType,
	data?: any,
}
export enum TourneyEventType {
	Join,
	Error,
	MatchResult,
	Last10Sec,
}
export interface TourneyMatchResult {
	result: 'win' | 'lose' | 'draw',
	myName: string,
	myRank1: number,
	myRank2?: number,
	oName: string,
	oRank1: number,
	oRank2?: number;
}
export interface TourneyMapInfo {
	mapType: TourneyMapType,
	name: string,
	img: string,
	oneHpOnly: number,
	difficulty: number,
	rarity: number,
	desc: string,
	fishes: FishType[][],
	weapons: SpearType[],
	defaultFishes: FishType[],
	defaultWeapon: SpearType,
	fishForComputer: FishType[],
}
// export interface SpearOption {
// 	spearType: SpearType,
// 	debuffs: BuffData,
// 	cost: number,
// }

export enum TourneyMapType {
	SmallArena,
	BigArena,
	Maelstrom,
	ZellyBloom,
	WhammyAscensionDen,
	BlueSharkFamilyNest,
}
export const tourneyMapInfos: TourneyMapInfo[] = [];

let mapType: TourneyMapType;

const fishByTier = [fishTiersAndBelow[0].map((i) => i.type), fishTiersAndBelow[1].map((i) => i.type), fishTiersAndBelow[2].map((i) => i.type)];
const tuskOnly = [SpearType.Tusk];
const defaultSharks = [1, 2, 3];
const defaultBestFishForComputer = [25, 26, 27];

mapType = TourneyMapType.SmallArena;
tourneyMapInfos[mapType] = {
	mapType,
	name: 'Small Arena',
	desc: `Step into the intense battleground of the <span class="text-orange">Small Arena</span>, a <span class="text-orange">compact</span> 1x1 map where players are <span class="text-orange">thrown into action</span> from the get-go. Here, your every move counts as you face off against your rival in close-quarters combat. But watch out for the <span class="text-orange">fiery and icy hazards</span> that can be used to your advantage or spell instant doom.`,
	rarity: 1,
	difficulty: 1,
	oneHpOnly: 0.5,
	img: 'small-arena',
	fishes: fishByTier,
	weapons: tuskOnly,
	defaultFishes: defaultSharks,
	defaultWeapon: SpearType.Tusk,
	fishForComputer: defaultBestFishForComputer,
};
mapType = TourneyMapType.BigArena;
tourneyMapInfos[mapType] = {
	mapType,
	name: 'Big Arena',
	desc: `Welcome to the vast expanse of the <span class="text-orange">Big Arena</span>, a 2x1 battlefield designed for strategic warfare. With <span class="text-orange">more room</span> to maneuver, players have space to <span class="text-orange">plan</span> their every move. <span class="text-orange">Fire and ice hazards</span> add an extra layer of excitement and danger, making positioning crucial in this larger-than-life showdown.`,
	rarity: 1,
	difficulty: 1,
	oneHpOnly: 0.5,
	img: 'big-arena',
	fishes: fishByTier,
	weapons: tuskOnly,
	defaultFishes: defaultSharks,
	defaultWeapon: SpearType.Tusk,
	fishForComputer: defaultBestFishForComputer,
};
mapType = TourneyMapType.Maelstrom;
tourneyMapInfos[mapType] = {
	mapType,
	name: `Maelstrom`,
	desc: `In the chaotic <span class="text-orange">Maelstrom</span>, a 1x1 map, a <span class="text-orange">massive whirlpool</span> churns at its center, creating an unpredictable and disorienting environment. As players fight for supremacy, they must <span class="text-orange">navigate the maelstrom's fury</span> while keeping an eye on the <span class="text-orange">fiery and icy hazards</span> that could lead to swift, brutal impalement.`,
	rarity: 1,
	difficulty: 2,
	oneHpOnly: 0,
	img: 'maelstrom',
	fishes: fishByTier,
	weapons: [SpearType.Chillblade, SpearType.Trident],
	defaultFishes: defaultSharks,
	defaultWeapon: SpearType.Chillblade,
	fishForComputer: defaultBestFishForComputer,
};
mapType = TourneyMapType.ZellyBloom;
tourneyMapInfos[mapType] = {
	mapType,
	name: 'Zelly Bloom',
	desc: `<span class="text-orange">Zelly Bloom</span>, a 2x1 map, offers a wide playing field <span class="text-orange">filled with elemental hazards</span> and <span class="text-orange">swarming Zellyfish</span>. These <span class="text-orange">explosive creatures</span> release elemental hazards when touched, adding an element of strategy and chaos to the gameplay. <span class="text-orange">Survive, outmaneuver, and exploit</span> your surroundings to eliminate your opponent.`,
	rarity: 1,
	difficulty: 2,
	oneHpOnly: 0.5,
	img: 'zelly-bloom',
	fishes: fishByTier,
	weapons: [SpearType.Trident],
	defaultFishes: defaultSharks,
	defaultWeapon: SpearType.Trident,
	fishForComputer: defaultBestFishForComputer,
};
mapType = TourneyMapType.WhammyAscensionDen;
tourneyMapInfos[mapType] = {
	mapType,
	name: `Whammy Ascension Den`,
	desc: `Dive into the heart of the <span class="text-orange">Whammy Ascension Den</span>, a 1x1 map featuring a <span class="text-orange">colossal whirlpool</span>. In this unique arena, <span class="text-orange">players must engage in mortal combat</span> while the master Whammyhead Shark undergoes an ascension trial within the vortex. Keep your wits about you as you fight your adversary, all while <span class="text-orange">avoiding the wrath of the furious Whammyhead</span>.`,
	rarity: 1,
	difficulty: 3,
	oneHpOnly: 0,
	img: 'whammy-ascension-den',
	fishes: fishByTier,
	weapons: [SpearType.Chillblade, SpearType.Trident],
	defaultFishes: defaultSharks,
	defaultWeapon: SpearType.Chillblade,
	fishForComputer: defaultBestFishForComputer,
};
mapType = TourneyMapType.BlueSharkFamilyNest;
tourneyMapInfos[mapType] = {
	mapType,
	name: 'Shark Cave',
	desc: `Prepare for the ultimate challenge in the treacherous <span class="text-orange">Shark Cave</span>, a 2x1 map teeming with ferocious marine predators. <span class="text-orange">Blue Sharks, Great Whites, and even the mighty Megalodon</span> lurk in the depths. It's a race against time as you strive to <span class="text-orange">eliminate your opponent</span> while <span class="text-orange">dodging the hungry jaws</span> of these formidable foes. Survival and victory are equally hard-fought in this perilous arena.`,
	rarity: 1,
	difficulty: 3,
	oneHpOnly: 0.5,
	img: 'nest-shark-fam',
	fishes: fishByTier,
	weapons: tuskOnly,
	defaultFishes: defaultSharks,
	defaultWeapon: SpearType.Tusk,
	fishForComputer: defaultBestFishForComputer,
};
