
































import { ServerData, ServerInfo } from '@/game/infos/serverInfos';
import globalx from '@/store/modules/globalx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ServerComponent extends Vue {
  @Prop() public data!: ServerData;
  @Prop() public time!: number;
  @Prop({ default: false }) public selected!: boolean;

  public get timeLeft() {
    const closeTime = this.data.armageddonStartTime
      ? this.data.armageddonStartTime
      : this.data.closeTime;
    let tl = closeTime - this.time;
    if (tl < 0) {
      tl = 0;
    }
    const hour = Math.floor(tl / (60 * 60 * 1000));
    tl -= hour * 60 * 60000;
    const min = Math.round(tl / 60000);
    // tl -= min * 60000
    return `${hour}h ${min}m`;
  }

  public get name() {
    const split = this.data.name.split('|');
    return split[1] + '-' + split[2] + '-' + split[3];
  }
  public get disabled() {
    return this.data.isOffline;
  }
  public onClick() {
    if (!this.disabled) {
      globalx.updateSelectedServer(this.data);
    }
  }
}
