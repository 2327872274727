import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsDiamondHarden } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';

// const vertices = [
// 	{ x: 90, y: -90 },
// 	{ x: 110, y: -40 },
// 	{ x: 110, y: 40 },
// 	{ x: 90, y: 90 },
// ];

export class DiamondShield extends Container {
}
export class SsDiamondHarden implements MiscSymbol {

	public static get() {
		return SsDiamondHarden._pool.get();
	}

	private static _pool: Pool<SsDiamondHarden> = new Pool(SsDiamondHarden);
	// public area: Graphics = new Graphics();
	public symbol = Factory.get(DiamondShield);

	public data: MiscData | null = null;
	public pt = 0;
	public casting = true;
	constructor() {
		// this.area.lineStyle(1, 0xFFFFFF, 0.75);
		// this.area.beginFill(0xffffff, 0.15);
		// this.area.moveTo(vertices[vertices.length - 1].x, vertices[vertices.length - 1].y);
		// for (const v of vertices) {
		// 	this.area.lineTo(v.x, v.y);
		// }
	}

	public dispose(room: RoomControl) {
		// console.log('disposeee')
		this.data = null;
		SsDiamondHarden._pool.pool(this);
		this.symbol.parent?.removeChild(this.symbol);
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsDiamondHarden;
		if (!this.data) {
			new SoundEfx('diamondHarden').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
		}
		this.symbol.angle = data.d;
		this.symbol.x = data.x;
		this.symbol.y = data.y;
		this.data = data;
	}
	public show(room: RoomControl) {
		room.playerTopContainer.addChild(this.symbol);

	}
	public hide(room: RoomControl) {
		this.symbol.parent?.removeChild(this.symbol);
	}

}
