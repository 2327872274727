import { RoomControl } from '@/client/controls/roomControl';
import { MiscData, MiscDataBiPenguinCrew, MiscDataBmSuperPearl, SuperPearlType } from '@/game/multithread/skills/miscContainer';
import { ViewState } from '@/game/multithread/viewState';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { BLEND_MODES, Container, Graphics, Sprite } from 'pixi.js';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';
import { MechguinPenguinState } from '@/game/infos/boss/xmasPenguinInfos';
import { MiscSymbol } from '../skills/miscSymbol';
import { HpLabelSymbol } from '../playerLabelMixin';
import { FactoryPool } from '../../helper';
import { Global } from '@/store/globalz';
import { SoundEfx } from '@/client/sound/SoundEfx';
import gamex from '@/store/modules/gamex';
import { ChatType } from '@/game/infos/eventType';

export class SuperPearl extends Container {
	public power = '';
	public magic!: Sprite;
	public pearlActive!: Sprite;
	public pearlDead!: Sprite;
	public init() {
		this.magic = this.children[0] as Sprite;
		this.pearlActive = this.children[1] as Sprite;
		this.pearlDead = this.children[2] as Sprite;
		this.magic.anchor.set(0.5);
		this.magic.x = this.magic.y = 0;
		this.magic.blendMode = BLEND_MODES.ADD;
	}
}
export class SuperPearlStamina extends SuperPearl {
	public init() {
		super.init();
		this.magic.tint = 0xffcc00;
		this.name = 'Stamina Pearl';
		this.power = 'Your stamina is no longer infinite!';
	}
}
export class SuperPearlRess extends SuperPearl {
	public init() {
		super.init();
		this.magic.tint = 0xE024E3;
		this.name = 'Ressurection Pearl';
		this.power = 'Your death is now permanent!';
	}
}
const pearlPools: Array<FactoryPool<SuperPearl>> = [new FactoryPool(SuperPearlRess), new FactoryPool(SuperPearlStamina)];
export class BmSuperPearlController implements MiscSymbol {

	public static get() {
		return BmSuperPearlController._pool.get();
	}

	private static _pool: Pool<BmSuperPearlController> = new Pool(BmSuperPearlController);


	public data: MiscDataBmSuperPearl | null = null;
	public pt = 0;

	// public sound = new LongSoundEfx('sunPunch');
	public symbol: SuperPearl | null = null;
	public hpLabel = new HpLabelSymbol();


	public constructor() {

		this.hpLabel.maxHp = 3000;
		this.hpLabel.barWidth = 300;
	}
	public dispose(room: RoomControl) {
		if (this.symbol) {
			if (this.data) {
				pearlPools[this.data.t].pool(this.symbol);
			}
			if (this.symbol.parent) {
				this.symbol.parent.removeChild(this.symbol);
			}
			this.symbol = null;
		}
		this.data = null;
		if (this.hpLabel.parent) {
			this.hpLabel.parent.removeChild(this.hpLabel);
		}
		// Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
		// 	this.sound.stop();
		// });
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataBmSuperPearl;



		if (!this.data) {
			this.symbol = pearlPools[data.t].get();
			this.symbol.x = data.x;
			this.symbol.y = data.y;
			this.hpLabel.x = data.x;
			this.hpLabel.y = data.y - 70;
			// this.scale.set(0.01);
			this.symbol.alpha = 1;
			// this.symbol.drawCircle(0, 0, 30);
		} else {
			if (data.hp === 0 && this.data.hp !== 0) {
				this.broken(rt);
			} else {
				const rate = data.hp / this.hpLabel.maxHp;
				const prevRate = this.data.hp / this.hpLabel.maxHp;
				if (prevRate > 0.5 && rate <= 0.5) {
					this.hpFallen('50', rt);
				}
				if (prevRate > 0.2 && rate <= 0.2) {
					this.hpFallen('20', rt);
				}
			}
			if (data.hp > 0) {

				this.symbol!.pearlActive.visible = this.symbol!.magic.visible = true;
				this.symbol!.pearlDead.visible = false;
				this.symbol!.magic.angle += 1;
			} else {

				this.symbol!.pearlActive.visible = this.symbol!.magic.visible = false;
				this.symbol!.pearlDead.visible = true;
			}
		}
		this.hpLabel.hp = data.hp;
		this.data = data;

	}
	public hpFallen(percentage: string, time: number) {
		const h = Global.$root.$createElement;
		Global.$root.$bvToast.toast('abc', {
			variant: 'dark',
			title: [
				h('div', { class: ['text-center', 'text-light', 'w-100'] }, [
					h('span', { class: ['text-pink', 'fwb'] }, this.symbol!.name),
					`'s durability falls below `,
					h('span', { class: ['text-yellow'] }, percentage),
					'%',
				]),
			],
			toaster: 'b-toaster-bottom-center b-toaster-alert b-toaster-event',
			solid: false,
			autoHideDelay: 3000,
			noCloseButton: true,
			appendToast: true,
		});
		new SoundEfx('announcement').play();
		gamex.addSystemMessage({
			chatType: ChatType.Warning,
			t: time,
			isTemp: true,
			senderName: 'Warning:',
			msg: `${this.symbol!.name}'s durability fall below ${percentage}%`,
		});
	}
	public broken(time: number) {
		const h = Global.$root.$createElement;
		Global.$root.$bvToast.toast('abc', {
			variant: 'dark',
			title: [
				h('div', { class: ['text-center', 'text-light', 'w-100'] }, [
					h('span', { class: ['text-pink', 'fwb'] }, this.symbol!.name),
					` has `,
					h('span', { class: ['text-yellow'] }, 'broken'),
					`! ${this.symbol!.power}`,
				]),
			],
			toaster: 'b-toaster-bottom-center b-toaster-alert b-toaster-event',
			solid: false,
			autoHideDelay: 5000,
			noCloseButton: true,
			appendToast: true,
		});
		new SoundEfx('announcement').play();
		gamex.addSystemMessage({
			chatType: ChatType.Warning,
			t: time,
			isTemp: true,
			senderName: 'Warning:',
			msg: `${this.symbol!.name} has broken! ${this.symbol!.power}%`,
		});
	}
	public show(room: RoomControl) {
		if (this.symbol) {
			room.playerBottomContainer.addChild(this.symbol);
		}
		room.playerLabelsContainer.addChild(this.hpLabel);
	}
	public hide(room: RoomControl) {
		if (this.symbol) {
			this.symbol.parent?.removeChild(this.symbol);
		}
		if (this.hpLabel.parent) {
			this.hpLabel.parent.removeChild(this.hpLabel);
		}
	}
}
