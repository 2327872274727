import { PlayerControl } from '@/client/controls/playerControl';
import { StababControl } from '@/client/controls/stababControl';
import { MovieClip } from 'pixi-animate';
import { Container } from 'pixi.js';
import Factory from '..';
import { SsFakeSwordController } from './skills/ssSwordDanceSymbol';

export class IceHazardSymbol extends Container {

}
export class FireHazardSymbol extends MovieClip {

}
export class ElectricHazardSymbol extends MovieClip {
	private currectAction = 'idle';
	public activate() {
		if (this.currectAction !== 'active') {
			this.currectAction = 'active';
			this.gotoAndPlay('active');
		}
	}
	public deactivate() {
		if (this.currectAction !== 'idle') {
			this.currectAction = 'idle';
			this.gotoAndStop('idle');
		}
	}
}

export class BurningEffect extends MovieClip {

}
export class FrozenEffect extends Container {

}
export class ParalyzedEffect extends MovieClip {

}
export class BlindedEffect extends Container {

}
export class ZompkinEffect extends MovieClip {

}
export class WrappedEffect extends Container {

}
export class PuffEffect extends MovieClip {
	public playerControl: PlayerControl | StababControl | null = null;
	public dispose() {
		if (this.playerControl) {
			this.playerControl.puffEffect = null;
		}
		this.gotoAndStop(0);
		if (this.parent) {
			this.parent.removeChild(this);
		}
		Factory.pool(PuffEffect, this);
	}
}

export class BrokenSword extends MovieClip {
	public playerControl: PlayerControl | SsFakeSwordController | null = null;
	public dispose() {
		if (this.playerControl) {
			this.playerControl.brokenSword = null;
		}
		this.gotoAndStop(0);
		if (this.parent) {
			this.parent.removeChild(this);
			this.emit('disposed');
		}
		Factory.pool(BrokenSword, this);
	}
}
