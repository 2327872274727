













import { FishType } from '@/game/infos/fishInfos';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FishIntelData } from '../../tools/fishSelector/FishIntelTooltip.vue';
import FishButton from '../../tools/inventory/FishButton.vue';

@Component({
  components: {
    FishButton,
  },
})
export default class NewFishComponent extends Vue {
  @Prop({ default: 1 }) public fishType!: FishType;

  public get id() {
    return 'new-fish' + random.v4();
  }
  public showFishIntel() {
    const data: FishIntelData = {
      targetId: this.id,
      placement: 'top',
      container: 'evolution-modal',
      fishType: this.fishType,
    };
    this.$root.$emit('showFishIntel', data);
  }
  public hideFishIntel() {
    this.$root.$emit('hideFishIntel');
  }
}
