










import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import { ordinalSuffix } from '@/util/number';
import globalx from '@/store/modules/globalx';
import { eventTimes, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';

@Component({
	components: {},
})
export default class CnyBanner extends Vue {
	public width = 800;
	public height = 150;
	public mounted() {
		if (!(this.$el as any).resizeSensor) {
			const res = new ResizeSensor(this.$el, this.onResize);
		}

		this.onResize();
	}
	public beforeDestroy() {
		if ((this.$el as any).resizeSensor) {
			ResizeSensor.detach(this.$el);
		}
	}
	public onResize() {
		this.width = this.$el.clientWidth;
		this.height = (this.width / 800) * 150;
	}
	public get myStyle() {
		return `height: ${this.height}px;`;
	}
	public get contentStyle() {
		return {
			transform: 'scale(' + this.width / 800 + ')',
		};
	}

	public get date() {
		const d1 = new Date(eventTimes[TimeLimitedQuestCode.Cny2024][0]);
		const d2 = new Date(eventTimes[TimeLimitedQuestCode.Cny2024][1]);
		const format = new Intl.DateTimeFormat(
			'en-UK', {
			day: 'numeric',
			month: 'short',
		});
		return `${format.format(d1)} ~ ${format.format(d2)}`;
	}
}
