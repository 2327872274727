import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsSunPunch } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { ManualTween } from '@/util/animatingVertices';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';
import { MovieClip } from 'pixi-animate';
import { Pool } from '@/util/pool';
import { PlayerMode } from '@/game/infos/enums';
import { tint } from '../../helper';

const info = specialSkillInfos[SpecialSkill.SunPunch];
const castTime0 = info.castTime;
const duration = info.duration;
export class SsSunPunchSymbol extends MovieClip {

}
export class SsSunPunchController implements MiscSymbol {

	public static get() {
		return SsSunPunchController._pool.get();
	}

	private static _pool: Pool<SsSunPunchController> = new Pool(SsSunPunchController);

	public area = new Graphics();

	public data: MiscData | null = null;
	public pt = 0;
	public isSameTeam = false;

	public sound = new LongSoundEfx('sunPunch');
	public symbol = Factory.get(SsSunPunchSymbol);

	public tween = new ManualTween(this.symbol.scale)
		.to({ x: 0.01, y: 0.01 }, 0)
		.wait(castTime0)
		.call(() => {
			this.symbol.visible = true;
			this.sound.play({ loop: false, start: 0 });
			if (this.area.parent) {
				this.area.parent.removeChild(this.area);
			}
		})
		.wait(100)
		.to({ x: 1.4, y: 1.4 }, 300, Ease.cubicOut);

	public constructor() {
		this.area.lineStyle(1, 0xFFFF00, 0.75);
		this.area.beginFill(0xffff99, 0.15);
		this.area.drawCircle(0, 0, 210);
	}
	public dispose(room: RoomControl) {
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		Tween.get(this.symbol).to({ alpha: 0 }, 300).call(() => {
			if (this.symbol.parent) {
				this.symbol.parent.removeChild(this.symbol);
			}
			SsSunPunchController._pool.pool(this);
		});
		Tween.get(this.sound).to({ volume: 0 }, 500).call(() => {
			this.sound.stop();
		});
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsSunPunch;
		this.area.x = data.x;
		this.area.y = data.y;

		this.symbol.x = data.x;
		this.symbol.y = data.y;


		if (!this.data) {
			// this.scale.set(0.01);
			this.symbol.alpha = 0.8;
			this.area.visible = true;
			this.symbol.visible = false;
			this.tween.setPosition(0, true);
			this.symbol.angle = 10;
			this.isSameTeam = data.tid === state.tid || state.playerMode === PlayerMode.PvE && data.tid === '_pve';
			tint(this.symbol, this.isSameTeam ? 0xff9900 : 0xffffff);
		} else {
			this.symbol.angle -= 0.05;
		}
		this.pt = rt;
		const castTime = data.ct;
		const startTime = data.st;
		const position = rt <= startTime + castTime ? (rt - startTime) / castTime * castTime0 : rt - startTime - castTime + castTime0;

		this.tween.setPosition(position);
		this.data = data;
		if (this.tween.position >= castTime0 && !this.symbol.parent) {
			room.playerTopContainer.addChild(this.symbol);
		}

		const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		this.sound.volume = volume;
	}
	public show(room: RoomControl) {
		if (this.tween.position < castTime0) {
			room.wallBottomContainer.addChild(this.area);
		} else {
			room.playerTopContainer.addChild(this.symbol);
		}
	}
	public hide(room: RoomControl) {
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		this.sound.volume = 0;
	}
}
