












































































































import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { BModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { authorizePoq, buyQuarters } from './poqFunctions';

@Component({
  components: {},
})
export default class PoqLinkModal extends Vue {
  public baseUrl = process.env.BASE_URL;
  public get poqLabel() {
    return userx.userDoc.poq ? 'Relink with poq.gg' : 'Link with poq.gg';
  }
  public get poqIndentity() {
    return userx.userDoc.poq;
  }

  public linkPoq() {
    authorizePoq();
  }
  public buyQuarters() {
    buyQuarters();
  }
  public created() {
    Global.poqLinkModal = this;
  }

  public show() {
    (this.$refs.myModal as BModal).show();
  }


  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
