import { TeamMemberInfo } from '@/client/controls/stageControl';
import { EmojiType } from '@/game/infos/emojiInfos';
import { Global } from '@/store/globalz';
import settingx from '@/store/modules/settingx';
import { Pool } from '@/util/pool';
import { Point } from '@/util/rotate';
import { MovieClip } from 'pixi-animate';
import { Container, Sprite, Text } from 'pixi.js';
import { getParticle, Particle } from '../particles';
import { disposeEmojiSymbol, EmojiSymbol, getEmojiSymbol } from './emojiAssets';

export class TeamMateIndicator extends Container {

	public name = '';
	public text2!: Text;
	public icon!: Container;
	public sprite!: Sprite;
	public container = new Container();

	public init() {
		this.sprite = this.icon.children[0] as Sprite;
		// this.text1.y += 6;
		this.text2.y += 4;
		// this.text1.visible = false;

		this.container.addChild(...this.children);
		this.addChild(this.container);
	}

	public update(info: TeamMemberInfo, inSight: boolean) {
		const { name, deg, isStabbed, teamIndex } = info;
		if (this.name !== name) {
			const n = name.substring(0, 1);
			this.name = name;
			// this.text1.text = n;
			this.text2.text = n;
		}
		this.text2.visible = !inSight;
		if (!inSight) {
			if (isStabbed) {
				const sx = Math.random() * 6 - 3;
				const sy = Math.random() * 6 - 3;
				this.container.x = sx;
				this.container.y = sy;
				this.icon.angle = -90;
			} else {
				this.container.x = this.container.y = 0;
				this.icon.angle = deg;
			}
		} else {
			this.container.x = this.container.y = 0;
			this.icon.angle = isStabbed ? -135 : 90;
		}
		this.sprite.tint = settingx.now['color' + (teamIndex + 1)];
	}
}

export class TeamMateEmoji extends Container {
	public static get() {
		return TeamMateEmoji._pool.get();
	}

	protected static _pool: Pool<TeamMateEmoji> = new Pool(TeamMateEmoji);

	public playerId = NaN;
	public showTime = 0;
	public emoji: EmojiSymbol | null = null;
	public showPosition: Point = { x: 0, y: 0 };
	constructor() {
		super();
		const emojiBg = getParticle(Particle.Circle);
		emojiBg.x = 0;
		emojiBg.y = 0;
		emojiBg.scale.set(1);
		this.addChild(emojiBg);
	}

	public next(time: number) {
		if (time > this.showTime + 4000) {
			this.dispose();
			return true;
		}
		return false;
	}
	public dispose() {
		this.clear();
		if (this.parent) {
			this.parent.removeChild(this);
		}
		delete Global.stageControl.uiControl.teamMateEmojis[this.playerId];
		TeamMateEmoji._pool.pool(this);
	}
	public clear() {
		if (this.emoji) {
			this.removeChild(this.emoji);
			disposeEmojiSymbol(this.emoji);
		}
	}
	public show(emojiType: EmojiType, showPosition: Point) {
		this.clear();
		this.visible = true;
		this.emoji = getEmojiSymbol(emojiType);
		this.addChild(this.emoji);
		if (this.emoji instanceof MovieClip) {
			this.emoji.gotoAndPlay(0);
		}
		this.showPosition = showPosition;
		this.showTime = Date.now();
	}
}
