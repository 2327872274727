import { RoomControl } from '@/client/controls/roomControl';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { SpecialSkill, specialSkillInfos } from '@/game/infos/skills';
import { MiscData, MiscDataSsElectricField, MiscDataSsWhammyWave } from '@/game/multithread/skills/miscContainer';
import { MyData } from '@/game/multithread/state';
import { ViewState } from '@/game/multithread/viewState';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { MovieClip } from 'pixi-animate';
import { Container, Graphics } from 'pixi.js';
import Factory from '../..';
import { MiscSymbol } from './miscSymbol';

const info = specialSkillInfos[SpecialSkill.ElectricField];
const castTime0 = info.castTime;
const duration = info.duration;

export class SsElectricFieldSymbol extends Container implements MiscSymbol {
	public area!: Graphics;
	public bolt1!: MovieClip;
	public bolt2!: MovieClip;
	public bolt3!: MovieClip;
	public bolt4!: MovieClip;
	public bolt5!: MovieClip;
	public field!: Container;

	public data: MiscData | null = null;
	public pt = 0;
	public casting = true;

	// public sound = new LongSoundEfx('electricField');

	public init() {
		this.area = this.children[0] as Graphics;
		this.bolt1.stop();
		this.bolt2.stop();
		this.bolt3.stop();
		this.bolt4.stop();
		this.bolt5.stop();
		this.alpha = 0.6;
	}
	public dispose(room: RoomControl) {
		this.data = null;
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
		Factory.pool(SsElectricFieldSymbol, this);
		// this.sound.stop();
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {
		const data = dt as MiscDataSsElectricField;
		this.area.x = data.x;
		this.area.y = data.y;

		this.x = data.x;
		this.y = data.y;
		this.scale.set(data.sc);
		this.area.scale.set(data.sc);

		if (!this.data) {
			this.area.visible = true;
			this.visible = false;
			// this.scale.set(0.01);
		}
		this.pt = rt;
		if (data.ac) {
			if (!this.visible) {
				new SoundEfx('electricBolt').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 });
				this.visible = true;
			}
			// this.scale.set(1);
			if (this.area.parent) {
				this.area.parent.removeChild(this.area);
			}
		}
		this.data = data;

		// const volume = (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300;
		// this.sound.volume = volume;
	}
	public show(room: RoomControl) {
		if (this.casting) {
			room.wallBottomContainer.addChild(this.area);
		}
		room.wallBottomContainer.addChild(this);
		this.bolt1.play();
		this.bolt2.play();
		this.bolt3.play();
		this.bolt4.play();
		this.bolt5.play();
	}
	public hide(room: RoomControl) {
		this.bolt1.stop();
		this.bolt2.stop();
		this.bolt3.stop();
		this.bolt4.stop();
		this.bolt5.stop();
		if (this.area.parent) {
			this.area.parent.removeChild(this.area);
		}
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}
}
