import { Dictionary } from '@/util/dictionary';
import { Pool } from '@/util/pool';
import { MovieClip } from 'pixi-animate';
import { Container, DisplayObject, Sprite } from 'pixi.js';
import assetx from '@/store/modules/assetx';
import WebFont from 'webfontloader';
import { googleFonts, mixinList } from './mixinList';
import { registerParticles } from './particles';
import { registerWallSprites } from './mapAssets';
import { nonReact } from '@/util/nonReact';
import { AllSpears, registerBodies, registerSpears, AllBodies } from './assets/playerModel';
import { Loader, LoaderResource } from 'pixi.js';
import { registerSpecialSprites, registerNormalSkillSprites, registerPassiveSprites, registerSkillLvlSprites } from './skillAssets';
import { AllEmojis, registerEmojis } from './assets/emojiAssets';
import { registerItemSprites } from './itemParticles';

declare const lib: any;
declare const sub: any;
declare const s1: any;
declare const ptc: any;
declare const map: any;
declare const gac: any;
// declare const baXP: any;


export default class Factory {
    public static dic: Dictionary<any> = {};
    public static pools: Dictionary<Pool<any>> = {};
    public static get<T>(theClass: new (...args: any[]) => T): T {
        return Factory.getByName((theClass as any).className) as any as T;
    }
    public static getByName(name: string): Container {
        const r = nonReact(Factory.pools[name].get());
        r.className = name;
        if (r.reset) { r.reset(); }
        return r;
    }
    public static pool<T>(theClass: new (...args: any[]) => T, symbol: T) {
        Factory.pools[(theClass as any).className].pool(symbol);
    }
}

function extendDisplayObject(isMovieClip: boolean, name: string, options: any, build: () => void) {
    const myClass = mixinList[name];
    const className = name;
    let symbol: any;
    if (isMovieClip) { options.framerate = 60; }
    if (myClass) {
        myClass.className = name;
        registerExtend(myClass);

        symbol = myClass.extend(function(this: any) {
            const t = new myClass(options);
            build.bind(t)();
            if (t.init) { t.init(); }
            return t;
        });
    } else {
        const DoClass: any = isMovieClip ? MovieClip : Container;
        registerExtend(DoClass);
        symbol = DoClass.extend(function(this: any) {
            DoClass.call(this, options);
            build.bind(this)();
        });
    }
    Factory.dic[className] = symbol;
    const pool = new Pool(symbol);
    Factory.pools[className] = pool;
    return symbol;
}

function registerExtend(myClass: any) {
    myClass.extend = myClass.e = function(child: any) {
        child.prototype = Object.create(myClass.prototype);
        child.prototype.__parent = myClass.prototype;
        child.prototype.constructor = child;
        return child;
    };
}

for (const key in lib) {
    if (Object.prototype.hasOwnProperty.call(lib, key)) {
        objToDisplayObject(lib, key);
    }
}
for (const key in sub) {
    if (Object.prototype.hasOwnProperty.call(sub, key)) {
        objToDisplayObject(sub, key);
    }
}
for (const key in s1) {
    if (Object.prototype.hasOwnProperty.call(s1, key)) {
        objToDisplayObject(s1, key);
    }
}
for (const key in ptc) {
    if (Object.prototype.hasOwnProperty.call(ptc, key)) {
        objToDisplayObject(ptc, key);
    }
}
for (const key in map) {
    if (Object.prototype.hasOwnProperty.call(map, key)) {
        objToDisplayObject(map, key);
    }
}
for (const key in gac) {
    if (Object.prototype.hasOwnProperty.call(gac, key)) {
        objToDisplayObject(gac, key);
    }
}
// for (const key in baXP) {
//     if (Object.prototype.hasOwnProperty.call(baXP, key)) {
//         objToDisplayObject(baXP, key);
//     }
// }

function objToDisplayObject(dic: any, key: string) {
    const obj: {
        name: string,
        type: 'Container' | 'MovieClip';
        build: () => void,
        options: any,
        assets?: any,
    } = dic[key];
    const resources = obj.assets;
    dic[key] = extendDisplayObject(obj.type === 'MovieClip', obj.name, obj.options, obj.build);
    if (resources) {
        dic[key].assets = resources;
    }
}
const subAssetShapes = {
    assets: {
        subAssets: sub.subAssets.assets.subAssets,
    },
};
const assetList: any[] = [
    lib.mainAssets,
    lib.fishAssets,
    map.mapAssets,
    ptc.particles,
    subAssetShapes,
];
lib.mainAssets.assets.TitanShaded = 'images/fonts/font-titan-one-export.xml?t=' + Date.now();
let currentLoadingAssetIndex = 0;

// lib.mainAssets.assets.TitanOneShaded = 'images/fonts/font-titan-one-export.fnt';

WebFont.load({
    google: {
        families: googleFonts,
    },
    active: () => {
        // add particles to loading list
        // lib.mainAssets.assets.particles_atlas_1 = process.env.BASE_URL + 'images/particles_atlas_1.json?t=' + Date.now();

        loadAsset();
    },
    inactive: () => {
        // add particles to loading list
        // lib.mainAssets.assets.particles_atlas_1 = process.env.BASE_URL + 'images/particles_atlas_1.json?t=' + Date.now();

        loadAsset();
    },
});

function loadAsset() {
    const assetLoader = assetx.app.load({ stage: assetList[currentLoadingAssetIndex], createInstance: false }) as Loader;
    assetLoader.onComplete.once(onLoadComplete);
    assetLoader.onError.add(onError);
    assetLoader.onLoad.add(onLoad);
}

function onError(error: Error, loader: Loader, resources: any) {
    assetx.addLoadError(error);
}
function onLoad(loader: Loader, resource: any) {
    const progress = (((loader.progress / 100) + currentLoadingAssetIndex) / assetList.length) * 100;
    assetx.updateLoadProgress(progress);
    assetx.updateLoadState('loading');
}
function onLoadComplete(loader: Loader, resources: Dictionary<any>) {

    const progress = ((1 + currentLoadingAssetIndex) / assetList.length) * 100;
    assetx.updateLoadProgress(progress);
    if (assetList.length <= currentLoadingAssetIndex + 1) {
        registerParticles(Factory.getByName('Particles1Symbol') as MovieClip);
        registerItemSprites(Factory.getByName('AllItems') as MovieClip);
        registerWallSprites(Factory.getByName('WallSprites') as MovieClip);
        registerNormalSkillSprites(Factory.getByName('AllNormalSkills') as MovieClip);
        registerSpecialSprites(Factory.getByName('AllSpecialSkillIcons') as MovieClip);
        registerPassiveSprites(Factory.getByName('AllPassiveIcons') as MovieClip);
        registerSkillLvlSprites(Factory.getByName('SkillLvl') as MovieClip);
        registerEmojis(Factory.get(AllEmojis));
        registerBodies(Factory.get(AllBodies));
        registerSpears(Factory.get(AllSpears));
        assetx.updateLoadState('completed');
    } else {
        currentLoadingAssetIndex++;
        loadAsset();
    }
}
