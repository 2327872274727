






























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ShopItemComponent from './ShopItemComponent.vue';
import { LimitedShopItemClient, limitedShopItemsInfos } from '@/game/infos/limitedShop';
import globalx from '@/store/modules/globalx';
import { Dictionary } from '@/util/dictionary';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { ItemCode, itemInfos } from '@/game/infos/itemInfos';
import userx from '@/store/modules/userx';
import { EditState } from '@/store/models.def';
import { BModal } from 'bootstrap-vue';
import ItemButton from '../mainMenu/tools/inventory/ItemButton.vue';
import StateButton from '@/components/inputs/StateButton.vue';
import settingx from '@/store/modules/settingx';
@Component({
	components: {
		StateButton,
		ItemButton,
		ShopItemComponent,
	},
})
export default class LimitedShopModal extends Vue {
	public get limitedShop() {
		return limitedShopItemsInfos;
	}
	public get titleHtml() {
		return `<i class="fa fas fa-store" /> <span class="ml-2 text-titan">Pirate Token Shop</span>`;
	}

	public get sufficient() {
		return this.toBuyData.cost * this.toBuyCount <= this.myToken;
	}
	public get canConvert() {
		return this.toBuyCount > 0 && this.sufficient;
	}
	public get convertLabel() {
		const labels = {
			active: 'Buy',
			waiting: 'Buy',
			sending: 'Buying',
			done: 'Bought',
		};
		return labels[this.convertState];
	}


	public get myShop() {
		return userx.inventory.shop || {};
	}
	public get myToken() {
		return userx.inventory.availables[ItemCode.PirateToken] || 0;
	}
	public get toBuyPurchased() {
		return this.myShop[this.toBuy] || 0;
	}
	public get toBuyOwned() {
		return userx.inventory.availables[this.toBuyData.itemCode] || 0;
	}
	public get toBuyInfo() {
		return itemInfos[this.toBuyData.itemCode];
	}
	public lastRefreshTime = 0;
	public dailyOffers: Dictionary<LimitedShopItemClient> = {};

	public convertState: EditState = 'active';
	public toBuyCount = 0;
	public toBuy = 'boss1PirateBandana';
	public toBuyData: LimitedShopItemClient = this.limitedShop[this.toBuy];

	public showError(error: any) {
		this.$root.$emit('error', error);
	}
	public mounted() {
		this.onRefresh();
	}
	public onShow() {
		this.onRefresh();
		if (!settingx.now.readBoss1Shop) {
			settingx.read('readBoss1Shop');
		}
	}

	public async onRefresh() {
		const ct = Date.now();
		if (ct < this.lastRefreshTime) {
			return;
		}
		this.lastRefreshTime = ct + 10000;
		try {
			const dataServerAddress = globalx.dataServerAddress;
			const response = await fetch(`${dataServerAddress}/shopLimited?v=ae`, {
				method: 'GET',
			});
			if (!response.ok) {
				throw await response.json();
			}
			const json = await response.json();
			this.dailyOffers = json;

		} catch (error) {
			this.showError(error);
		}

	}

	public async onConvert() {
		this.convertState = 'waiting';

		try {
			this.convertState = 'sending';
			// await wait(1000);
			await userx.buyShopLimited({ itemId: this.toBuy, count: this.toBuyCount });
			new SoundEfx('buyChaching').play();
			this.convertState = this.toBuyPurchased < this.toBuyData.limit ? 'active' : 'done';
		} catch (error) {
			this.showError(error);
		}

	}

	public onBuy(id: string, data: LimitedShopItemClient) {
		this.toBuy = id;
		this.toBuyData = data;
		this.toBuyCount = 1;
		this.convertState = this.toBuyPurchased < this.toBuyData.limit ? 'active' : 'done';
		(this.$refs.buyShopLimitedCountModal as BModal).show();
	}
}
