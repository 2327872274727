
































import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  HighscoreData,
  ArmageddonHighscoreData,
  CraftingHighscoreData,
} from '@/game/infos/highscoreInfos';
import random from '@/util/random';
import { Rarity } from '@/game/infos/itemInfos';

@Component({
  components: {},
})
export default class CraftingHighscoreComponent extends Vue {
  @Prop({ default: 0 }) public index!: number;
  @Prop() public data!: CraftingHighscoreData;

  public id = random.v4();
  public expand = this.index === 0;

  public get name() {
    return this.data.name;
  }
  public get newCrafted() {
    return addComma(this.data.newCrafted);
  }
  public get numCrafted() {
    return addComma(this.data.numCrafted);
  }
  public get isMe() {
    return this.data.uid === userx.uid;
  }
  public get legendary() {
    return this.data.rarityCrafted[Rarity.Legendary];
  }
  public get epic() {
    return this.data.rarityCrafted[Rarity.Epic];
  }
  public get rare() {
    return this.data.rarityCrafted[Rarity.Rare];
  }
  public get uncommon() {
    return this.data.rarityCrafted[Rarity.Uncommon];
  }
  public get common() {
    return this.data.rarityCrafted[Rarity.Common];
  }
}
