















































































































































































































































































































import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MainMenuCanvasComponent from '../../canvas/MainMenuCanvasComponent.vue';
import { AipAds } from '@/client/ads/aipads';
import settingx from '@/store/modules/settingx';
import { ordinalSuffix } from '@/util/number';
import { FishType } from '@/game/infos/fishInfos';
import DecoEditor from './tools/decoEditor/DecoEditor.vue';
import DecoItemInventoryModal from './tools/inventory/DecoItemInventoryModal.vue';
import FishButton from './tools/inventory/FishButton.vue';
import FishSelector from './tools/fishSelector/FishSelector.vue';
import UserBar from './UserBar.vue';
import IconButton from './IconButton.vue';
import TeamModal from './team/TeamModal.vue';
import globalx from '@/store/modules/globalx';
import MustLoginModal from './MustLoginModal.vue';
import ServerModal from './servers/ServerModal.vue';
import EvolutionModal from './evolution/EvolutionModal.vue';
import FishInventoryModal from './tools/inventory/FishInventoryModal.vue';
import NewsTab from './tabs/NewsTab.vue';
import HighscoreTab from './tabs/HighscoreTab.vue';
import YoutubeSubscibeContainer from './youtubeSubscribeContainer.vue';
import BagModal from './bag/BagModal.vue';
import PartnerBar from './PartnerBar.vue';
import { CrazyAd } from '@/client/ads/crazyads';
import QuestTab from './tabs/QuestTab.vue';
import DecoSkinInventoryModal from './tools/inventory/DecoSkinInventoryModal.vue';
import BpAlbumModal from './blueprints/BlueprintAlbumModal.vue';
import tipx from '@/store/modules/tipx';
import { TipId } from '@/game/infos/tipInfos';
import SideSaleButtons from '@/components/shop/SideSaleButtons.vue';
import ContestTab from './tabs/ContestTab.vue';
import gamex from '@/store/modules/gamex';
import SideBossButtons from '../boss/SideBossButtons.vue';
import { Analytics } from '@/client/analytics';
import LinkAccountWarningModal from './LinkAccountWarningModal.vue';
import VerifyEmailWarningModal from './VerifyEmailWarningModal.vue';
import { ServerTime } from '@/store/api/serverTime';

@Component({
	components: {
		MainMenuCanvasComponent,
		DecoEditor,
		FishInventoryModal,
		FishButton,
		FishSelector,
		UserBar,
		IconButton,
		TeamModal,
		ServerModal,
		BagModal,
		MustLoginModal,
		EvolutionModal,
		NewsTab,
		QuestTab,
		HighscoreTab,
		YoutubeSubscibeContainer,
		PartnerBar,
		DecoItemInventoryModal,
		DecoSkinInventoryModal,
		BpAlbumModal,
		SideSaleButtons,
		ContestTab,
		SideBossButtons,
		LinkAccountWarningModal,
		VerifyEmailWarningModal,
	},
})
export default class MainMenu extends Vue {
	public get screenSize() {
		return this.width < 641 ? 'sm' : this.width < 1020 ? 'md' : 'lg';
	}
	public get hasNews() {
		return globalx.latestNews !== settingx.now.news || settingx.now.newsTime < globalx.autoTimeLimitedEventTime || settingx.now.newsTime < globalx.autoDecoContestTime;
	}
	public get hasNewSkin() {
		return globalx.latestNewSkin !== settingx.now.newSkin;
	}
	public get heroes() {
		return userx.heroes;
	}
	public get lifesUnlocked() {
		return userx.lifesUnlocked;
	}
	public get clientVersion() {
		return globalx.clientVersionString;
	}

	public get showMustLoginModal() {
		return userx.loginStatus !== 'logged in' ? true : false;
	}
	public get showLinkEmail() {
		return ServerTime.time >= (userx.userSettings.nextLinkWarning || 0)
			&& userx.userDoc.lvl > 5 && userx.user && userx.user.isAnonymous;
	}
	public get showVerifyEmail() {
		return !userx.userSettings.nvrVerificationWarning && ServerTime.time >= (userx.userSettings.nextVerificationWarning || 0)
			&& userx.userDoc.lvl > 5 && userx.user && !userx.user.isAnonymous && !userx.emailVerificationSent && !userx.user.emailVerified;
	}

	public get hasCompletedFishChallenge() {
		return userx.unreadCompletedFishChallenges.length > 0;
	}
	public get hasPendingUnlocks() {
		return userx.pendingFishUnlocks.findIndex((b) => b) !== -1;
	}
	public get hasCompletedQuestChallenge() {
		return userx.unreadCompletedQuestChallenges.length > 0;
	}
	public get hasClaimableQuest() {
		return userx.hasClaimableQuest;
	}
	public get hasNewContest() {
		return (userx.userSettings.contestRead || 0) < globalx.latestContest;
	}
	public get hasUnreadMail() {
		const inbox = userx.inventory.inbox;
		for (const mailId in inbox) {
			if (Object.prototype.hasOwnProperty.call(inbox, mailId)) {
				const mail = inbox[mailId];
				if (!mail.readTime) {
					return true;
				}
			}
		}

		return false;
	}
	public get teamData() {
		return userx.teamData;
	}
	public get teamNumMembers() {
		return `${this.teamData ? Object.values(this.teamData.members).length : '-'
			}/${this.teamData ? '4' : '-'}`;
	}
	public displayNameInput = userx.userDoc.name;
	public baseUrl = process.env.BASE_URL;
	public showWelcome = true;

	public selectedFish: FishType = this.heroes[0];
	public selectedLife: number = 0;

	public userBarDestination = 'userBarTopRight';
	public tabDestination = 'rightTab';
	public partnerBarDestination = 'partnerBottomLeft';

	public width = 320;
	public height = 320;

	public get leftUserBarTopStyle() {
		if (this.height > 715) {
			return { top: '50px' };
		}
		let top = this.height - 715 + 50;
		if (top < 0) {
			top = 0;
		}
		return { top: top + 'px' };
	}

	public get adSizeStyle() {
		if (this.height < 530) {
			return {
				'margin-top': '-20px !important',
				'margin-bottom': '-10px !important',
			};
		}
		return {};
	}

	public currentPage: 'mainMenu' | 'decoEditor' | 'fishSelector' = 'mainMenu';
	public currentTab: 'highscore' | 'news' | 'quest' | 'contest' = 'news';
	public toShowTab = false;

	@Watch('screenSize', { immediate: true })
	public screenSizeChanged(size: 'sm' | 'md' | 'lg') {
		if (size === 'sm') {
			this.userBarDestination = 'userBarCenter';
			this.tabDestination = 'centerTab';
			this.partnerBarDestination = 'partnerCenter';
		} else if (size === 'md') {
			this.userBarDestination = 'userBarTopLeft';
			this.tabDestination = 'centerTab';
			this.partnerBarDestination = 'partnerBottomLeft';
		} else if (size === 'lg') {
			this.userBarDestination = 'userBarTopRight';
			this.tabDestination = 'rightTab';
			this.partnerBarDestination = 'partnerBottomLeft';
			this.toShowTab = false;
			if (this.currentTab === 'news') {
				settingx.readNews();
			} else if (this.currentTab === 'quest') {
				userx.readQuest();
			}
		}

		this.$root.$emit('scrollContainerCheck');
	}
	public get rightTab() {
		return this.screenSize === 'lg' ? 'tabDestination' : '';
	}
	public get centerTab() {
		return this.screenSize !== 'lg' ? 'tabDestination' : '';
	}

	public showTab(tab?: 'highscore' | 'news' | 'quest' | 'contest') {
		this.toShowTab = this.screenSize !== 'lg' && tab ? true : false;
		if (tab) {
			this.currentTab = tab;
			if (tab === 'news') {
				settingx.readNews();
			} else if (this.currentTab === 'quest') {
				userx.readQuest();
			} else if (this.currentTab === 'contest') {
				userx.readContest();
			}
		}
		this.$root.$emit('scrollContainerCheck');
	}

	public numberSuffix(n: number) {
		return ordinalSuffix(n);
	}

	public get maintenanceStartTime() {
		return globalx.maintenanceInfo?.start || 0;
	}
	@Watch('maintenanceStartTime', { immediate: true })
	public checkWarnMaintenance() {
		if (globalx.warnedMaintenance) {
			return;
		}
		const time = this.maintenanceStartTime;
		const ct = Date.now();
		if (time > ct && time - ct < 12 * 60 * 60000) {
			globalx.updateWarnedMaintenance(true);
			const h = this.$createElement;
			const messageVNode = h('div', {}, [
				h('p', {}, [' Maintenance starts at: ']),
				h('p', {}, [new Date(time).toString()]),
			]);
			this.$bvModal.msgBoxOk([messageVNode], {
				titleHtml: `<i class="fa fas fa-wrench pr-2"></i> Maintenance Notice`,
				size: 'md',
				// buttonSize: 'sm',
				okVariant: 'danger',
				modalClass: 'funny-modal',
				centered: true,
			});
		}
	}

	public async mounted() {
		const tabIndex = Global.mainMenuIndex % 3;
		if (tabIndex === 0) {
			this.currentTab = 'news';
		} else if (tabIndex === 1) {
			this.currentTab = 'quest';
		} else {
			this.currentTab = 'highscore';
		}
		this.$root.$emit('scrollContainerCheck');
		Global.mainMenuIndex++;
		window.addEventListener('resize', this.onResize);
		this.onResize();
		if (Global.adsdk === 'crazyads') {
			CrazyAd.instance.refreshBannerAd('300x250');
		} else {
			AipAds.instance.refreshBannerAd('stabfish2-io_300x250');
		}

		this.$root.$on('customize', this.onCustomize);
		this.$root.$on('selectFish', this.onSelectSkin);
		this.$root.$on('playTutorial', this.playTutorial);
		// alert('mounted');
		// this.moveCanvas('lobbyDestination');
		if (this.hasNews) {
			this.showTab('news');
		}

		if (
			userx.user &&
			userx.userDoc.crazyGames &&
			this.displayNameInput === `Stabfish_${userx.user.uid.slice(0, 3)}`
		) {
			const username = await CrazyAd.instance.getUserName();
			if (username) {
				this.displayNameInput = username;
			}
		}
	}

	public beforeDestroy() {
		this.$root.$off('selectFish', this.onSelectSkin);
		this.$root.$off('customize', this.onCustomize);
		this.$root.$off('playTutorial', this.playTutorial);
	}
	public onResize() {
		this.width = this.$el.clientWidth;
		this.height = this.$el.clientHeight;
	}
	public showTeamModal() {
		this.$bvModal.show('team-modal');
	}
	public showServerModal() {
		this.$bvModal.show('server-modal');
	}
	public showBagModal() {
		this.$bvModal.show('bag-modal');
	}
	public showBpAlbumModal() {
		this.$bvModal.show('bp-album-modal');
	}
	public onSelectSkin() {
		globalx.updateMainMenuName(this.displayNameInput);

		for (let i = 0; i < this.heroes.length; i++) {
			const hero = this.heroes[i];
			if (!userx.fishUnlocked[hero]) {
				userx.updateHeroes({
					life: i,
					fishType:
						i === 2
							? FishType.Megalodon
							: i === 1
								? FishType.GreatWhiteShark
								: FishType.BlueShark,
				});
			}
		}
		this.$bvModal.show('evolution-modal');
		if (
			userx.fishUnlocked[FishType.HammerHead] &&
			!tipx.tipsRead[TipId.ChooseFish] &&
			!tipx.tipsToShow[TipId.ChooseFish]
		) {
			setTimeout(() => {
				tipx.showTip(TipId.ChooseFish);
			}, 500);
		}
	}
	public onCustomize(type: FishType) {
		this.selectedFish = type;
		this.$bvModal.hide('evolution-modal');
		this.currentPage = 'decoEditor';
	}
	public onShowFishes() {
		this.selectedFish = this.heroes[0];
		this.currentPage = 'fishSelector';
	}
	public showFishes() {
		this.currentPage = 'fishSelector';
	}

	public onBackToLobby() {
		this.currentPage = 'mainMenu';
	}
	public onSave() { }

	public onPlay() {
		if (userx.needTutorial) {
			this.playTutorial();
			return;
		}
		this.onSelectSkin();
	}
	public async playTutorial() {
		globalx.updateMainMenuName(this.displayNameInput);
		const server = globalx.selectedServer || globalx.recommendedServer;
		if (server) {
			gamex.setGameStage('Connecting');
			if (globalx.mainMenuName !== userx.userDoc.name) {
				await userx.updateDisplayName(globalx.mainMenuName);
			}
			Global.stageControl.communicator!.connect(server, userx.heroes, true);
			Analytics.play();
		} else {
			this.$root.$emit('error', 'No server selected.');
		}
	}

	public beforeEnter(el: Element) {
		// console.log('beforeEnter', el);
		if (this.currentPage === 'mainMenu') {
			if (Global.adsdk === 'crazyads') {
				CrazyAd.instance.refreshBannerAd('300x250');
			} else {
				AipAds.instance.refreshBannerAd('stabfish2-io_300x250');
			}
		}
	}
	public beforeLeave(el: Element) {
		// if (this.currentPage !== 'mainMenu') {
		//   this.moveCanvas('otherDestination');
		// }
		(el as HTMLElement).style.height = el.clientHeight + 'px';
		settingx.hide();
	}
	public afterEnter(el: Element) {
		// if (this.currentPage === 'mainMenu') {
		// }
		(el as HTMLElement).style.height = '';
	}
	public afterLeave(el: Element) {
		// console.log('afterLeave', el);
	}
	public enterCancelled(el: Element) {
		// console.log('enterCancelled', this.currentPage);
	}
	public leave(el: Element) { }

	public activated() {
		console.log('main menu activated');
	}
	public deactivated() {
		console.log('main menu deactivated');
		settingx.hide();
	}
	public onSecret() {
		// gamex.setGameStage('TourneyList');
	}
}
