




import assetx from '@/store/modules/assetx';
import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import throttle from 'lodash/throttle';
import random from '@/util/random';
import * as PIXI from 'pixi.js';
import { Scene } from 'pixi-animate';
import { nonReact } from '@/util/nonReact';
import gamex from '@/store/modules/gamex';
import { PlayerGameState } from '@/game/multithread/state';
import { ReincarnationContainer } from '@/client/factory/assets/reincarnationContainerMixin';
import { Preset } from '@/game/infos/preset';
import Factory from '@/client/factory';
import { UnlockAnimation } from '@/client/factory/assets/unlock/unlockAnimationMixin';
import { FishType } from '@/game/infos/fishInfos';
import { Global } from '@/store/globalz';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { Tween } from '@/util/tweents';
import globalx from '@/store/modules/globalx';
// import { WsBadgeScene } from '@/client/factory/assets/cpsWsBadgeControl';
import userx from '@/store/modules/userx';
import { TourneyMatchResult } from '@/game/infos/roomInfosTny';

const fxaaFilter = new PIXI.filters.FXAAFilter();
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;

const scene = nonReact(
  new Scene(
    document.documentElement.clientWidth,
    document.documentElement.clientHeight,
    {
      antialias: false,
      transparent: true,
      forceCanvas: false,
    },
    true,
  ),
);
Global.overlayCanvasStage = scene.stage;

@Component({
  components: {},
})
export default class OverlayCanvasComponent extends Vue {
  public get dpi() {
    return settingx.now.dpi;
  }
  public get frameRate() {
    return settingx.now.frameRate;
  }
  public get antialias() {
    return settingx.now.antialias === 1;
  }
  public get assetLoadState() {
    return assetx.assetLoadState;
  }
  @Prop({ default: false }) public noWebGL!: boolean;

  public width = this.$el ? this.$el.clientWidth : 0;
  public height = this.$el ? this.$el.clientHeight : 0;
  public app = scene;
  public stage = this.app.stage;
  public id = random.firestore();

  public built = false;
  public isStopped = false;

  public updateAntialiasThrottled = throttle(this.updateAntialias, 500);

  public resizeThrottled = throttle(this.resize, 500);

  public reincarnationContainer!: ReincarnationContainer;
  public unlockAnimation!: UnlockAnimation;
  // public winBadgeScene!: WsBadgeScene;

  @Prop({ default: false }) public bool!: boolean;
  @Prop({ default: 0 }) public showUnlock!: FishType;
  @Prop({ default: false }) public showReincarnation!: boolean;
  @Prop({ default: false }) public showChampionshipWinBadges!: boolean;

  /** Main assets are loaded */
  public build() {
    if (!this.built) {
      this.built = true;
      this.reincarnationContainer = Factory.get(ReincarnationContainer);
      this.reincarnationContainer.x = this.width / 2;
      this.reincarnationContainer.y = this.height / 2;
      this.unlockAnimation = Factory.get(UnlockAnimation);
      this.unlockAnimation.resize(this.width, this.height);
      // this.winBadgeScene = Factory.get(WsBadgeScene);
      // this.winBadgeScene.resize(this.width, this.height);
      if (Global.skinGachaControl) {
        Global.skinGachaControl.resize(this.width, this.height);
      }
    }
  }
  // ### Indicators
  public get showGameIndicators() {
    // return this.bool;
    return gamex.gameStage === 'Start' || gamex.gameStage === 'End';
  }
  @Watch('showGameIndicators', { immediate: true })
  public showGameIndicatorsChanged(newVal: boolean) {
    if (this.built) {
      if (newVal) {
        this.stage.addChild(Global.overlayGameIndicators);
      } else {
        this.stage.removeChild(Global.overlayGameIndicators);
      }
    }
  }
  // ### Reincarnation
  public get progress() {
    const timeLeft = gamex.currentGameState.reinc - gamex.currentGameState.rt;
    const progress = timeLeft / Preset.STABAB_REINCARNATE_TIMEOUT;
    return progress;
  }

  @Watch('showReincarnation', { immediate: true })
  public showReincarnationChanged(newVal: boolean) {
    if (this.built) {
      if (newVal) {
        this.stage.addChild(this.reincarnationContainer);
        this.reincarnationContainer.start(
          gamex.currentGameState.life,
          gamex.currentGameState.life0,
          gamex.currentGameState.life1,
          gamex.currentGameState.life2,
        );
      } else {
        this.stage.removeChild(this.reincarnationContainer);
      }
    }
  }
  @Watch('progress', { immediate: true })
  public progressChanged(newVal: number) {
    if (newVal) {
      this.reincarnationContainer.update(newVal);
    }
  }
  // ### End Reincarnation

  // ### Unlock Animation

  @Watch('showUnlock', { immediate: true })
  public showUnlockChanged(newVal: FishType) {
    if (this.built) {
      if (newVal) {
        globalx.updatePreventChallengeToast(true);
        new SoundEfx('unlock').play();
        Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
          { volume: 0 },
          500,
        );
        this.stage.addChild(this.unlockAnimation);
        this.unlockAnimation.show(newVal);
      } else {
        globalx.updatePreventChallengeToast(false);
        Tween.get(Global.bgmControlSoundGroup, { override: true }).to(
          { volume: 1 },
          500,
        );
        this.unlockAnimation.hide();
        this.stage.removeChild(this.unlockAnimation);
      }
    }
  }

  // ### End Unlock Animation

  // ### Win Badges Animation

  // @Watch('showChampionshipWinBadges', { immediate: true })
  // public showChampionshipWinBadgesChanged(newVal: boolean) {
  //   if (this.built) {
  //     if (newVal) {
  //       globalx.updatePreventChallengeToast(true);
  //       this.stage.addChild(this.winBadgeScene);
  //     } else {
  //       globalx.updatePreventChallengeToast(false);
  //       this.stage.removeChild(this.winBadgeScene);
  //       this.winBadgeScene.gotoAndStop(0);
  //     }
  //   }
  // }
  // public cpsWinBadge() {
  //   if (gamex.currentGameState.cpsPlace > 0) {
  //     if (gamex.currentGameState.cpsPlace > 0) {
  //       const cpsPlayers = gamex.cpsPlayers!;
  //       this.winBadgeScene.win(
  //         cpsPlayers.me.name,
  //         cpsPlayers.me.rank,
  //         cpsPlayers.me.streak,
  //         cpsPlayers.me.streak + 1,
  //         cpsPlayers.opponent.name,
  //         cpsPlayers.opponent.rank,
  //         cpsPlayers.opponent.streak,
  //       );
  //     } else if (gamex.currentGameState.cpsPlace === 0) {
  //       const cpsPlayers = gamex.cpsPlayers!;
  //       this.winBadgeScene.draw(
  //         cpsPlayers.me.name,
  //         cpsPlayers.me.rank,
  //         cpsPlayers.me.streak,
  //         cpsPlayers.me.streak,
  //         cpsPlayers.opponent.name,
  //         cpsPlayers.opponent.rank,
  //         cpsPlayers.opponent.streak,
  //         cpsPlayers.opponent.streak,
  //       );
  //     } else {
  //       const cpsPlayers = gamex.cpsPlayers!;
  //       this.winBadgeScene.lose(
  //         cpsPlayers.me.name,
  //         cpsPlayers.me.rank,
  //         cpsPlayers.me.streak,
  //         cpsPlayers.opponent.name,
  //         cpsPlayers.opponent.rank,
  //         cpsPlayers.opponent.streak,
  //         cpsPlayers.opponent.streak + 1,
  //       );
  //     }
  //   }
  // }
  // public tnyWinBadge(data: TourneyMatchResult) {
  //   if (data.result === 'win') {
  //     this.winBadgeScene.win(
  //       data.myName,
  //       0,
  //       data.myRank1,
  //       data.myRank2!,
  //       data.oName,
  //       0,
  //       data.oRank1,
  //     );
  //   } else if (data.result === 'lose') {
  //     this.winBadgeScene.lose(
  //       data.myName,
  //       0,
  //       data.myRank1,
  //       data.oName,
  //       0,
  //       data.oRank1,
  //       data.oRank2!,
  //     );
  //   } else {
  //     this.winBadgeScene.draw(
  //       data.myName,
  //       0,
  //       data.myRank1,
  //       data.myRank2!,
  //       data.oName,
  //       0,
  //       data.oRank1,
  //       data.oRank2!,
  //     );
  //   }
  // }

  // ### End Win Badges

  public renderAndStop() {
    this.isStopped = true;
    this.app.render();
    this.app.stop();
  }
  public rerenderAndStop() {
    this.app.start();
    this.renderAndStop();
  }

  public onResize() {
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;
    const appScale = Global.appScale;
    this.width = Global.width = width / appScale;
    this.height = Global.height = height / appScale;
    this.resize();
    if (this.built) {
      this.reincarnationContainer.x = this.width / 2;
      this.reincarnationContainer.y = this.height / 2;
      this.unlockAnimation.resize(this.width, this.height);
      // this.winBadgeScene.resize(this.width, this.height);
      if (Global.skinGachaControl) {
        Global.skinGachaControl.resize(this.width, this.height);
      }
    }
  }

  public mounted() {
    // if (!(this.$el as any).resizeSensor) {
    //   const res = new ResizeSensor(this.$el, this.onResize);
    // }
    window.addEventListener('resize', this.onResize);
    this.$el.appendChild(this.app.view);
    this.app.view.style.width = '100%';
    this.app.view.style.height = '100%';
    this.app.stage.filterArea = this.app.renderer.screen;
    this.onResize();
    // this.$root.$on('cpsWinBadge', this.cpsWinBadge);
    // this.$root.$on('tnyWinBadge', this.tnyWinBadge);
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    // if ((this.$el as any).resizeSensor) {
    //   ResizeSensor.detach(this.$el);
    // }
    this.stage.removeChildren();
    // this.$root.$off('cpsWinBadge', this.cpsWinBadge);
    // this.$root.$off('tnyWinBadge', this.tnyWinBadge);
  }
  @Watch('assetLoadState', { immediate: true })
  public assetLoadStateChanged(newVal: 'completed' | 'loading' | 'none') {
    if (this.assetLoadState === 'completed') {
      this.build();
    }
  }
  @Watch('dpi', { immediate: true })
  public dpiChanged(newVal: number) {
    this.resizeThrottled();
  }
  @Watch('antialias', { immediate: true })
  public antialiasChanged(newVal: number) {
    this.updateAntialiasThrottled();
  }
  @Watch('frameRate', { immediate: true })
  public frameRateChanged(newVal: number) {
    this.app.ticker.maxFPS = newVal;
  }
  protected resize() {
    this._resize();
  }

  protected updateAntialias() {
    this.app.stage.filters = this.antialias ? [fxaaFilter] : [];
  }

  protected _resize() {
    const { dpi } = this;
    this.app.renderer.resize(this.width * dpi, this.height * dpi);
    this.app.stage.scale.set(dpi);
    if (assetx.assetLoadState === 'completed' && this.isStopped) {
      this.rerenderAndStop();
    }
  }
}
