import { MovieClip } from 'pixi-animate';
import { Container, Sprite } from 'pixi.js';
import { Particle, getParticle } from '../particles';
import { Rotate } from '@/util/rotate';
import { Ease, Tween } from '@/util/tweents';
import { Pool } from '@/util/pool';

export class WhirlpoolSymbol extends Container {

}
export class OuroborosSymbol extends Container {

}

const sirenPool: Sprite[] = [];
function getSirenNote() {
	let symbol = sirenPool.pop();
	if (!symbol) {
		symbol = Sprite.from('mainAssets_SirenNote');
		symbol.anchor.set(.5, .5);
	}
	return symbol;
}

export class SirenSymbol extends MovieClip {
	protected nextSing = 0;

	public next(time: number) {
		if (time > this.nextSing) {
			const note = getSirenNote();
			const radian = Math.random() * Math.PI - Math.PI;
			const start = Rotate.move(radian, 50);
			const end = Rotate.move(radian, 160 + Math.random() * 40);
			const startAngle = Math.random() * 20 - 10;
			const endAngle = startAngle + Math.random() * 10 + 10 * (Math.random() < .5 ? 1 : -1);
			note.scale.set(.5);
			note.x = start.x;
			note.y = start.y;
			note.alpha = 0;
			note.angle = startAngle;
			Tween.get(note).to({ x: end.x, y: end.y, angle: endAngle }, 1000, Ease.circOut).call(() => {
				note.parent.removeChild(note);
				sirenPool.push(note);
			});
			Tween.get(note).to({ alpha: 1 }, 250).wait(500).to({ alpha: 250 });
			Tween.get(note.scale).to({ x: 1, y: 1 }, 250);
			this.nextSing = time + 500;
			this.addChild(note);

		}
	}
}

