




import assetx from '@/store/modules/assetx';
import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import throttle from 'lodash/throttle';
import random from '@/util/random';
import * as PIXI from 'pixi.js';
import { Scene } from 'pixi-animate';
import { nonReact } from '@/util/nonReact';
import gamex from '@/store/modules/gamex';
import { PlayerGameState } from '@/game/multithread/state';
import { ReincarnationContainer } from '@/client/factory/assets/reincarnationContainerMixin';
import { Preset } from '@/game/infos/preset';
import { GameBag } from '@/client/controls/utility/gameBag';
import { UnlockAnimation } from '@/client/factory/assets/unlock/unlockAnimationMixin';
import { FishType } from '@/game/infos/fishInfos';
import { Global } from '@/store/globalz';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';

const fxaaFilter = new PIXI.filters.FXAAFilter();
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;

const scenes: Scene[] = [];
function getScene(width: number, height: number) {
  const scene = scenes.shift();
  if (!scene) {
    return nonReact(
      new Scene(
        width,
        height,
        {
          antialias: false,
          transparent: true,
          forceCanvas: false,
        },
        true,
      ),
    );
  } else {
    return scene;
  }
}

@Component({
  components: {},
})
export default class GameBagCanvasComponent extends Vue {
  public get dpi() {
    return settingx.now.dpi;
  }
  public get frameRate() {
    return settingx.now.frameRate;
  }
  public get antialias() {
    return settingx.now.antialias === 1;
  }
  public get assetLoadState() {
    return assetx.assetLoadState;
  }

  public get width() {
    return this.numColumns * this.size;
  }
  public get height() {
    return this.numRows * this.size;
  }
  public get sumCheck() {
    return (
      this.size +
      ' ' +
      this.numRows +
      ' ' +
      this.numColumns +
      JSON.stringify(this.items)
    );
  }
  @Prop({ default: false }) public noWebGL!: boolean;

  @Prop({ default: 60 }) public size!: number;
  @Prop({ default: 5 }) public numColumns!: number;
  @Prop({ default: 1 }) public numRows!: number;
  @Prop({ default: () => [] }) public items!: ItemCodeAmountPair[];
  public app = getScene(this.width, this.height);
  public stage = this.app.stage;
  public id = random.firestore();

  public built = false;
  public isStopped = false;

  public get canvasStyle() {
    return {
      width: `${this.width}px`,
      height: `${this.height}px`,
    };
  }

  public updateAntialiasThrottled = throttle(this.updateAntialias, 500);

  public resizeThrottled = throttle(this.resize, 500);

  public gameBag = new GameBag();
  @Watch('sumCheck', { immediate: true })
  public sumCheckChanged() {
    this.onResize();
  }

  /** Main assets are loaded */
  public build() {
    if (!this.built) {
      this.built = true;
      this.stage.addChild(this.gameBag);
    }
    this.gameBag.update(this.items, this.numColumns, this.numRows, this.size);
  }
  public renderAndStop() {
    this.isStopped = true;
    this.app.render();
    this.app.stop();
  }
  public rerenderAndStop() {
    this.app.start();
    this.renderAndStop();
  }

  public onResize() {
    this.resize();
    if (this.built) {
      this.build();
    }
  }

  public mounted() {
    // if (!(this.$el as any).resizeSensor) {
    //   const res = new ResizeSensor(this.$el, this.onResize);
    // }
    this.$el.appendChild(this.app.view);
    this.app.view.style.width = '100%';
    this.app.view.style.height = '100%';
    this.app.stage.filterArea = this.app.renderer.screen;
    this.onResize();
  }

  public beforeDestroy() {
    // if ((this.$el as any).resizeSensor) {
    //   ResizeSensor.detach(this.$el);
    // }
    scenes.push(this.app);
    this.stage.removeChildren();
  }
  @Watch('assetLoadState', { immediate: true })
  public assetLoadStateChanged(newVal: 'completed' | 'loading' | 'none') {
    if (this.assetLoadState === 'completed') {
      this.build();
    }
  }
  @Watch('dpi', { immediate: true })
  public dpiChanged(newVal: number) {
    this.resizeThrottled();
  }
  @Watch('antialias', { immediate: true })
  public antialiasChanged(newVal: number) {
    this.updateAntialiasThrottled();
  }
  @Watch('frameRate', { immediate: true })
  public frameRateChanged(newVal: number) {
    this.app.ticker.maxFPS = newVal;
  }
  protected resize() {
    this._resize();
  }

  protected updateAntialias() {
    this.app.stage.filters = this.antialias ? [fxaaFilter] : [];
  }

  protected _resize() {
    const { dpi } = this;
    this.app.renderer.resize(this.width * dpi, this.height * dpi);
    this.app.stage.scale.set(dpi);
    if (assetx.assetLoadState === 'completed' && this.isStopped) {
      this.rerenderAndStop();
    }
  }
}
