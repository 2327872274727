import { Container, Sprite } from 'pixi.js';
import { MiscSymbol } from './miscSymbol';
import { MiscData, MiscDataMcIronWave, MiscDataMcIronmonite } from '@/game/multithread/skills/miscContainer';
import { RoomControl } from '@/client/controls/roomControl';
import { Tween } from '@/util/tweents';
import Factory from '../..';
import { ViewState } from '@/game/multithread/viewState';
import { SpearType } from '@/game/infos/spearInfos';
import { Rotate } from '@/util/rotate';
import { SoundEfx } from '@/client/sound/SoundEfx';
import { LongSoundEfx } from '@/client/sound/LongSoundEfx';
import { WrappedEffect } from '../hazardMixin';

export class McIronmoniteSymbol extends Container implements MiscSymbol {
	public data: MiscDataMcIronmonite | null = null;
	public wrappedEffect: WrappedEffect | null = null;

	public init() {
	}
	public dispose(room: RoomControl) {
		this.data = null;
		if (this.parent) {
			Tween.get(this).to({ alpha: 0 }, 500).call(() => {
				if (this.parent) {
					this.parent.removeChild(this);
				}

				Factory.pool(McIronmoniteSymbol, this);
			});
		} else {
			Factory.pool(McIronmoniteSymbol, this);
		}

		this.removeWrappedEffect();
	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataMcIronmonite;
		if (!this.data) {
			this.alpha = 0;
			Tween.get(this).to({ alpha: 1 }, 200);
		}
		this.x = data.x;
		this.y = data.y;
		this.angle = data.d;
		this.data = data;
		if (data.w) {
			if (!this.wrappedEffect) {
				this.wrappedEffect = Factory.get(WrappedEffect);
				this.addChild(this.wrappedEffect);
				this.wrappedEffect.scale.set(2);
				this.wrappedEffect.angle = Math.random() * 360;
			}
		} else {
			this.removeWrappedEffect();
		}
	}
	public show(room: RoomControl) {
		room.playerBottomContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}


	protected removeWrappedEffect() {
		if (this.wrappedEffect) {
			this.wrappedEffect.x = 0;
			this.wrappedEffect.y = 0;
			this.wrappedEffect.angle = 0;
			this.wrappedEffect.scale.set(1);
			this.removeChild(this.wrappedEffect);
			Factory.pool(WrappedEffect, this.wrappedEffect);
			this.wrappedEffect = null;
		}
	}
}

const waveColors: number[] = [];
waveColors[SpearType.Chainsaw] = 0xffa27c;
waveColors[SpearType.Chillblade] = 0x0fffff;
waveColors[SpearType.Crossbow] = 0xb48d00;
waveColors[SpearType.MercyStaff] = 0xffff00;
waveColors[SpearType.Firesaber] = 0xff0000;
waveColors[SpearType.HammerToy] = 0x8C56D6;
waveColors[SpearType.LightningRod] = 0xffff99;

export class McIronWaveSymbol extends Container implements MiscSymbol {
	public data: MiscDataMcIronWave | null = null;
	public waveSound = new LongSoundEfx('wave');

	public init() {
	}
	public dispose(room: RoomControl) {
		this.data = null;
		if (this.parent) {
			this.parent.removeChild(this);
		}
		Factory.pool(McIronWaveSymbol, this);
		this.waveSound.stop();

	}
	public update(room: RoomControl, dt: MiscData, rt: number, state: ViewState) {

		const data = dt as MiscDataMcIronWave;
		if (!this.data) {
			(this.children[0] as Sprite).tint = waveColors[data.t] || 0xffffff;
			new SoundEfx('bell').play({ volume: (1300 - Rotate.dist(data.x, data.y, state.x, state.y)) / 1300 * .4 / data.l });
			if (data.t === SpearType.Trident) {
				this.waveSound.play({ speed: data.l });
			}
		}
		this.x = data.x;
		this.y = data.y;
		this.data = data;
		this.scale.set(data.r / 250);
		this.alpha = data.a;
		if (data.t === SpearType.Trident) {
			const d = Math.abs(Rotate.dist(data.x, data.y, state.x, state.y) - data.r);
			this.waveSound.volume = (1300 - d) / 1300 * .4 / data.l;
		}
	}
	public show(room: RoomControl) {
		room.playerBottomContainer.addChild(this);
	}
	public hide(room: RoomControl) {
		if (this.parent) {
			this.parent.removeChild(this);
		}
	}
}
