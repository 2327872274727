import userx from '@/store/modules/userx';

let quartersFieldCount = 0;

export function addQuarterField() {
	quartersFieldCount++;
}
export function subQuarterField() {
	quartersFieldCount--;
}

setInterval(() => {
	if (quartersFieldCount > 0 && userx.userDoc.poq) {
		userx.updatePoqWallet();
	}
}, 5000);

export function authorizePoq() {
	window.open(
		'https://www.poq.gg/oauth2/authorize?response_type=code&client_id=hYixrkiRnCmeTLAX8fVu&redirect_uri=https%3A%2F%2Fstabfish2.io%2Fpoq-connect&scope=identity%20email%20wallet%20transactions%20events',
		'sf-poq',
		'width=500,height=700',
	);
}
export function buyQuarters() {
	window.open(
		'https://www.poq.gg/buy',
		'sf-poq',
		'width=1175,height=720',
	);
}
