























































































































































import { SoundEfx } from '@/client/sound/SoundEfx';
import StateButton from '@/components/inputs/StateButton.vue';
import { decoLimitCost } from '@/game/infos/decorativeInfos';
import { ItemCode } from '@/game/infos/itemInfos';
import { TipId } from '@/game/infos/tipInfos';
import { Global } from '@/store/globalz';
import { EditState } from '@/store/models.def';
import tipx from '@/store/modules/tipx';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { addComma } from '@/util/number';
import { BModal, BvModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import DecoItemButton from '../inventory/DecoItemButton.vue';
import DecoArrangerItem from './DecoArrangerItem.vue';
import { DecoItem, Decoratives, DecoSplitType } from './decorative';
@Component({
  components: {
    Draggable,
    DecoArrangerItem,
    StateButton,
  },
})
export default class DecoArranger extends Vue {
  public get scrollbarOptions() {
    return { suppressScrollX: true };
  }

  public get opened() {
    return this.forceOpen || this.visible;
  }

  public get dragOptions() {
    return {
      //   animation: 200,
      group: 'description',
      disabled: this.removing,
      ghostClass: 'ghost',
      dragClass: 'dragged',
      delayOnTouchOnly: true,
      delay: 100,
    };
  }

  public get decoLimit() {
    return 5 + (userx.inventory.decoLimit || 0);
  }
  public get cost() {
    return decoLimitCost[this.decoLimit - 5] || Number.MAX_SAFE_INTEGER;
  }
  public get type() {
    return this.decoLimit < 9 ? 'gold' : 'blackpearl';
  }
  public get available() {
    return this.decoLimit < 9
      ? userx.inventory.money
      : userx.inventory.availables[ItemCode.Gem];
  }
  public get costString() {
    return addComma(this.cost, 0);
  }
  public get availableString() {
    return addComma(this.available, 0);
  }

  public get canIncrease() {
    return this.available > this.cost;
  }
  public get increaseLabel() {
    const labels = {
      active: 'Increase',
      waiting: 'Increase',
      sending: 'Increasing',
      done: 'Increased',
    };
    return labels[this.increaseState];
  }
  @Prop({ required: true }) public numDecos!: number;
  @Prop({ default: false }) public forceOpen!: boolean;
  @Prop({ default: '' }) public selectedId!: string;
  @Prop({ required: true }) public orderList!: DecoItem[];

  public list: DecoItem[] = [];

  public drag = false;
  public visible = false;
  public fixScrollTop = 0;
  public removing;
  public increaseState: EditState = 'active';
  @Watch('orderList', { immediate: true })
  public orderListChanged(newVal: DecoItem[]) {
    if (newVal.length > 1) {
      if (
        !tipx.tipsRead[TipId.ArrangeDeco] &&
        userx.userDoc.lvl >= 10 &&
        !tipx.tipsToShow[TipId.ArrangeDeco]
      ) {
        setTimeout(() => {
          tipx.showTip(TipId.ArrangeDeco);
        }, 1000);
      }
    }
    this.list = newVal;
  }
  public onScroll(e) {
    if (this.drag) {
      (this.$refs.scrollbar as HTMLElement).scrollTop = this.fixScrollTop;
    }
  }
  public onTransitionEnd() {
    // console.log('transition end');
  }
  public dragStart(e) {
    this.drag = true;
    this.fixScrollTop = (this.$refs.scrollbar as HTMLElement).scrollTop;
  }

  public dragEnd(evt) {
    this.drag = false;
    const target = this.list[evt.newIndex];

    if (target.entangle) {
      if (target.splitType === DecoSplitType.PART_A) {
        const aIndex = evt.newIndex;
        const bIndex = this.list.indexOf(target.entangle);
        if (bIndex < aIndex) {
          this.list.splice(aIndex, 0, this.list.splice(bIndex, 1)[0]);
        }
      } else if (target.splitType === DecoSplitType.PART_B) {
        const bIndex = evt.newIndex;
        const aIndex = this.list.indexOf(target.entangle);
        if (bIndex < aIndex) {
          this.list.splice(bIndex, 0, this.list.splice(aIndex, 1)[0]);
        }
      }
    }
    this.list = [...this.list];
    this.$emit('move', this.list);
  }
  public onChange(e) {
    if (e.moved) {
      if (e.moved.oldIndex < e.moved.newIndex) {
        if (tipx.currentShowingTipId === TipId.ArrangeDeco) {
          console.log('achieve drag to back');
          tipx.achieveTip(TipId.ArrangeDeco);
        }
      }
    }
    // const context = evt.draggedContext;
    // const element = context.element;
    // if (context.index === 3 && context.futureIndex < 2) {
    //   return false;
    // }
  }

  public removeAt(decoId: string) {
    // this.list = this.list.filter()
    //   this.list = [...this.list];
  }

  public increaseDecoLimit() {
    if (this.decoLimit < 10) {
      (this.$refs.limitModal as BModal).show();
    }
  }
  public async onIncrease() {
    this.increaseState = 'waiting';
    const value = await this.$bvModal.msgBoxConfirm(
      'This action cannot be undone.',
      {
        title: `Are you sure?`,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'orange',
        cancelVariant: 'link btn-link',
        modalClass: 'funny-modal',
        okTitle: 'Confirm',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      },
    );
    if (value) {
      try {
        this.increaseState = 'sending';
        // await wait(1000);
        const toLimit = this.decoLimit + 1;
        await userx.increaseDecoLimit(toLimit - 5);
        new SoundEfx('buyChaching').play();
        if (toLimit < 10) {
          this.increaseState = 'active';
        } else {
          (this.$refs.limitModal as BModal).hide();
        }
      } catch (error) {
        this.showError(error);
      }
    } else {
      this.increaseState = 'active';
    }
  }
  public showError(error: any) {
    this.$root.$emit('error', error);
  }
}
