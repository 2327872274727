



























import { generateGeneSkillIconData, generateRGeneSkillIconData } from '@/client/factory/assets/skillIcon';
import SkillIconCanvasComponent from '@/components/canvas/SkillIconCanvasComponent.vue';
import { passiveSkillInfos } from '@/game/infos/skills';
import random from '@/util/random';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FishGeneSkillInfo } from './EvolutionModal.vue';

const romans = [
  '0',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI',
];
@Component({
  components: {
    SkillIconCanvasComponent,
  },
})
export default class FishGeneComponent extends Vue {
  @Prop({ required: true }) public data!: FishGeneSkillInfo;
  @Prop({ default: 'fish-skill' }) public container!: string;
  @Prop({ default: false }) public hideDesc!: boolean;
  @Prop({ default: false }) public small!: boolean;

  public onMouseOver() {
    this.$root.$emit('bv::show::tooltip', this.popoverId);
  }
  public onMouseOut() {
    this.$root.$emit('bv::hide::tooltip', this.popoverId);
  }
  public get popoverId() {
    return random.v4();
  }
  public get lvl() {
    if (this.data.lvl > this.info.lvls.length) {
      this.data.lvl = this.info.lvls.length;
    }
    return this.data.lvl;
  }

  public get info() {
    return passiveSkillInfos[this.data.type];
  }

  public get iconData() {
    return this.data.recess ? generateRGeneSkillIconData(this.data.type, this.lvl) : generateGeneSkillIconData(this.data.type, this.lvl);
  }

  public get name() {
    const name = this.info.name;
    const roman = this.lvl === 0 ? '' : ' ' + romans[this.lvl];

    return name + roman;
    // let suffix = '';
    // if (settingx.now.control === 'mouse') {
    //   if (this.type === 'normal') {
    //     suffix = ' (Left Click)';
    //   } else if (this.type === 'special') {
    //     suffix = ' (Right Click)';
    //   }
    // }
    // return name + roman + suffix;
  }

  public get desc() {
    return this.info.lvls[this.lvl ? this.lvl - 1 : 0].desc;
  }
}
