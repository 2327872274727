




import assetx from '@/store/modules/assetx';
import settingx from '@/store/modules/settingx';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ResizeSensor from 'resize-sensor';
import throttle from 'lodash/throttle';
import * as PIXI from 'pixi.js';
import { Scene } from 'pixi-animate';

const fxaaFilter = new PIXI.filters.FXAAFilter();
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;

// const sprites:Sprite[] = [];

@Component({
  components: {},
})
export default class PixiCanvasComponent extends Vue {
  public get app() {
    this._app =
      this._app ||
      new Scene(
        this.width,
        this.height,
        {
          antialias: false,
          transparent: true,
          forceCanvas: this.noWebGL,
        },
        true,
      );
    return this._app;
  }
  public get stage() {
    return this.app.stage;
  }

  public get dpi() {
    return settingx.now.dpi;
  }
  public get frameRate() {
    return settingx.now.frameRate;
  }
  public get antialias() {
    return settingx.now.antialias === 1;
  }
  public get assetLoadState() {
    return assetx.assetLoadState;
  }

  public get updateAntialiasThrottled() {
    this._updateAntialiasThrottled =
      this._updateAntialiasThrottled || throttle(this.updateAntialias, 500);
    return this._updateAntialiasThrottled;
  }
  @Prop({ default: true }) public noWebGL!: boolean;

  public width = this.$el ? this.$el.clientWidth : 0;
  public height = this.$el ? this.$el.clientHeight : 0;

  public isStopped = false;
  private _app!: Scene;
  private _updateAntialiasThrottled: any;
  private _resizeThrottled: any;

  public renderAndStop() {
    this.isStopped = true;
    this.app.render();
    this.app.stop();
  }
  public rerenderAndStop() {
    this.app.start();
    this.renderAndStop();
  }

  public onResize() {
    this.width = this.$el.clientWidth;
    this.height = this.$el.clientHeight;
    this.resize();
  }
  public mounted() {
    if (!(this.$el as any).resizeSensor) {
      const res = new ResizeSensor(this.$el, this.onResize);
    }
    this.$el.appendChild(this.app.view);
    this.app.view.style.width = '100%';
    this.app.view.style.height = '100%';
    this.app.stage.filterArea = this.app.renderer.screen;
    this.onResize();
  }

  /** Main assets are loaded */
  public build() {
    // const sprite = new Sprite(
    //   Texture.from('./images/individuals/Bitmap138.png', {
    //     scaleMode: PIXI.SCALE_MODES.NEAREST,
    //   }),
    // );
    // sprites.push(sprite);
    // this.stage.addChild(sprite);
    // sprite.scale.set(.5);
    // sprite.texture.baseTexture.on('loaded', () => {
    //   this.renderAndStop();
    // });
  }
  public beforeDestroy() {
    this._beforeDestroy();
    if ((this.$el as any).resizeSensor) {
      ResizeSensor.detach(this.$el);
    }
    this.app.destroy(true);
  }
  @Watch('assetLoadState', { immediate: true })
  public assetLoadStateChanged(newVal: 'completed' | 'loading' | 'none') {
    if (this.assetLoadState === 'completed') {
      this.build();
    }
  }
  @Watch('dpi', { immediate: true })
  public dpiChanged(newVal: number) {
    this.resizeThrottled();
  }
  @Watch('antialias', { immediate: true })
  public antialiasChanged(newVal: number) {
    this.updateAntialiasThrottled();
  }
  @Watch('frameRate', { immediate: true })
  public frameRateChanged(newVal: number) {
    this.app.ticker.maxFPS = newVal;
  }

  public resizeThrottled() {
    this._resizeThrottled = this._resizeThrottled || throttle(this.resize, 500);
    return this._resizeThrottled;
  }
  protected _beforeDestroy() {

  }
  protected updateAntialias() {
    this.app.stage.filters = this.antialias ? [fxaaFilter] : [];
  }

  protected _resize() {
    const { dpi } = this;
    this.app.renderer.resize(this.width * dpi, this.height * dpi);
    this.app.stage.scale.set(dpi);
    if (assetx.assetLoadState === 'completed' && this.isStopped) {
      this.rerenderAndStop();
    }
  }

  protected resize() {
    this._resize();
  }
}
