























































import { SoundEfx } from '@/client/sound/SoundEfx';
import {
  getChallengeProgress,
  getQuestProgress,
} from '@/game/infos/firestoreFiles';
import { fishInfos, FishType } from '@/game/infos/fishInfos';
import { fishUnlockRequirements } from '@/game/infos/fishUnlockRequirements';
import { mainQuests } from '@/game/infos/questMain';
import { weeklyQuests } from '@/game/infos/questWeekly';
import { timeLimitedQuests } from '@/game/infos/questTimeLimited';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import { floorToFixed, roundToFixed } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishCanvasComponent from '../canvas/FishCanvasComponent.vue';
import {
	ItemCodeAmountPair,
  Quest,
  QuestType,
  UnlockChallengeType,
} from '@/game/infos/unlockModels';
import { ItemCode } from '@/game/infos/itemInfos';
import ItemButton from './mainMenu/tools/inventory/ItemButton.vue';
@Component({
  components: {
    FishCanvasComponent,
    ItemButton,
  },
})
export default class ChallengeCompletedToast extends Vue {
  public shownChallengeIds: string[] = [];
  public toShows: string[] = [];
  public visible = false;
  public get user() {
    return userx.user;
  }
  @Watch('user')
  public userChanged(newValue) {
    this.toShows.length = 0;
    this.shownChallengeIds.length = 0;
    this.visible = false;
  }

  public get preventChallengeToast() {
    return globalx.preventChallengeToast;
  }
  public get completedChallenges() {
    return userx.toShowCompletedChallenges;
  }
  @Watch('completedChallenges')
  public completedChallengesChanged(newVal: string[]) {
    for (const id of newVal) {
      if (!this.shownChallengeIds.includes(id) && !this.toShows.includes(id)) {
        this.toShows.push(id);
      }
    }
    if (!this.preventChallengeToast && this.toShows.length > 0) {
      this.visible = true;
    }
  }
  @Watch('preventChallengeToast')
  public preventChallengeToastChanged(newVal: boolean) {
    if (!newVal && this.toShows.length > 0) {
      this.visible = true;
    }
  }

  public get challengeId() {
    return this.toShows[0] || '';
  }
  public get splits() {
    return this.challengeId.split('-');
  }

  ///// Fish Challenge
  public get fishType(): FishType {
    return this.splits[0] === 'F' ? Number(this.splits[1]) : 0;
  }

  /// Quest Challenge
  public get questCode(): number {
    return this.splits[0] !== 'F' ? Number(this.splits[1]) : 0;
  }
  public get quest(): Quest | null {
    if (this.fishType) {
      return null;
    }
    const quests =
      this.splits[0] === 'QM'
        ? mainQuests
        : this.splits[0] === 'QW'
        ? weeklyQuests
        : timeLimitedQuests;
    return quests[this.questCode] || null;
  }

  public get currentChallenge() {
    const a = this.currentStep;
    const b = this.currentIndex;
    if (this.splits[0] === 'F') {
      if (!fishUnlockRequirements[this.fishType]) {
        return null;
      }
      if (!fishUnlockRequirements[this.fishType][a]) {
        return null;
      }
      if (!fishUnlockRequirements[this.fishType][a][b]) {
        return null;
      }
      return fishUnlockRequirements[this.fishType][a][b];
    } else {
      if (!this.questStep) {
        return null;
      }
      if (!this.questStep.challenges[b]) {
        return null;
      }
      return this.questStep.challenges[b];
    }
  }

  public get questStep() {
    if (!this.quest) {
      return null;
    }
    const a = this.currentStep;
    if (!this.quest.steps[a]) {
      return null;
    }
    return this.quest.steps[a];
  }
  public get questProgress() {
    if (!this.quest) {
      return null;
    }
    const progresses =
      this.quest.type === QuestType.Main
        ? userx.userDoc.mainQuestProgress
        : this.quest.type === QuestType.Weekly
        ? userx.userDoc.weeklyQuestProgress
        : userx.userDoc.timeLimitedQuestProgress;
    const progress = getQuestProgress(progresses, this.quest.code);
    return progress;
  }
  public get pair(): ItemCodeAmountPair {
    return this.questStep
      ? this.questStep.rewards[0]
      : { itemCode: ItemCode.Money, amount: 0 };
  }
  public get challengeTitle() {
    return !this.quest ? fishInfos[this.fishType].name : this.quest.title;
  }

  public get currentStep() {
    return Number(this.splits[2]);
  }
  public get currentIndex() {
    return Number(this.splits[3]);
  }

  public get challengeDescription() {
    return this.currentChallenge ? this.currentChallenge.description : null;
  }

  public get readyToUnlock() {
    if (!this.questStep) {
      return userx.pendingFishUnlocks[this.fishType];
    }
    return this.nextChallenge ? false : true;
  }
  public get unlockProgress() {
    if (!this.quest) {
      return floorToFixed(userx.fishUnlockProgress[this.fishType] * 100, 1);
    }
    if (!this.nextChallenge) {
      return 100;
    }
    const numSteps = this.quest.steps.length;
    const progress = this.questProgress!;
    const currentStep = progress.currentStep;
    const displayStep = currentStep >= numSteps ? numSteps - 1 : currentStep;

    const step = this.quest.steps[displayStep];
    const numChallenges = step.challenges.length;
    let percent = 0;
    for (let i = 0; i < numChallenges; i++) {
      const challenge = step.challenges[i];
      if (challenge.type === UnlockChallengeType.UnlockFish) {
        percent += userx.fishUnlocked[challenge.goal] ? 1 / numChallenges : 0;
      } else {
        const current =
          challenge.type === UnlockChallengeType.Level
            ? userx.userDoc.lvl
            : progress.progresses[i] || 0;

        percent += Math.min(current / challenge.goal, 1) / numChallenges;
      }
    }
    return floorToFixed(percent * 100);
  }

  public get nextChallenge() {
    if (!this.questStep) {
      return userx.fishCurrentUnlockChallenges[this.fishType] || null;
    }
    const progress = this.questProgress!;
    if (progress.currentStep !== this.currentStep) {
      return null;
    }
    for (let i = 0; i < this.questStep.challenges.length; i++) {
      const challenge = this.questStep.challenges[i];
      if (progress.progresses[i] < challenge.goal) {
        return challenge;
      }
    }
    return null;
  }

  public onShow() {
    new SoundEfx('achievement').play();
  }

  public onHidden() {
    const pop = this.toShows.shift();
    if (pop) {
      this.shownChallengeIds.push(pop);
    }
    if (this.toShows.length > 0) {
      this.visible = true;
    }
  }
}
