





























import {
  decoDic,
  DecoName,
  decoNames,
  DecoSet,
} from '@/game/infos/decorativeInfos';
import { ItemCode, ItemInfo, itemInfos } from '@/game/infos/itemInfos';
import { Global } from '@/store/globalz';
import { ItemCodeAmountPair } from '@/game/infos/unlockModels';
import userx from '@/store/modules/userx';
import { BModal } from 'bootstrap-vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router';
import ItemInventory from '../../bag/ItemInventory.vue';
import { DecoItem, DecoSplitType } from '../decoEditor/decorative';
import { blueprintInfos } from '@/game/infos/blueprintInfos';

@Component({
  components: {
    ItemInventory,
  },
})
export default class DecoItemInventoryModal extends Vue {
  public resolve: null | ((value: DecoName | null) => void) = null;
  public okTitle = 'OK';
  public title = 'Decoration';

  public items: ItemCodeAmountPair[] = [];

  public currentDecoName = '';
  public currentItemCode = -1;
  public type: string = '';

  public itemCode: ItemCode | -1 = -1;

  public usedItems: number[] = [];
  public limitedItems: boolean[] = [];

  public get disabledItems() {
    return this.items.map(
      (item, i) =>
        item.amount === 0 ||
        item.itemCode === this.currentItemCode ||
        this.limitedItems[i],
    );
  }

  public get decoName(): DecoName | null {
    return decoDic[this.itemCode] || null;
  }

  public get titleHtml() {
    return `<i class="fa fa-tshirt"></i> ${this.title}`;
  }

  public show(
    type?: 'changeDeco' | 'addDeco',
    originalItemCodes: Dictionary<number> = {},
    decos: DecoItem[] = [],
    currentDecoName?: DecoName,
  ) {
    this.usedItems.length = 0;
    this.limitedItems.length = 0;
    this.currentItemCode = -1;
    this.items = JSON.parse(JSON.stringify(userx.decos));

    for (const code in originalItemCodes) {
      if (Object.prototype.hasOwnProperty.call(originalItemCodes, code)) {
        const amount = originalItemCodes[code];
        const itemCode = Number(code);
        const index = this.items.findIndex((i) => i.itemCode === itemCode);
        if (index === -1) {
          this.items.push({ itemCode, amount });
        } else {
          this.items[index].amount += amount;
        }
      }
    }

    // this.items.length = 0;
    // for (const itemCode in decoDic) {
    //   if (Object.prototype.hasOwnProperty.call(decoDic, itemCode)) {
    //     this.items.push({ itemCode: Number(itemCode), amount: 5 });
    //   }
    // }

    for (const deco of decos) {
      if (deco.decoName === 'Body' || deco.splitType === DecoSplitType.PART_B) {
        continue;
      }
      const itemCode = decoDic.indexOf(deco.decoName);
      if (deco.decoName === currentDecoName) {
        this.currentItemCode = itemCode;
      }
      const index = this.items.findIndex((i) => i.itemCode === itemCode);
      if (index !== -1) {
        if (this.items[index].amount > 0) {
          this.items[index].amount--;
        }
        const limit = itemInfos[itemCode].decoLimit || 0;
        if (limit) {
          this.usedItems[index] = (this.usedItems[index] || 0) + 1;
          if (this.usedItems[index] >= limit) {
            this.limitedItems[index] = true;
          }
        }
      }
    }
    this.currentDecoName = currentDecoName || '';
    this.itemCode = this.currentItemCode;
    this.type = type || '';

    if (type === 'changeDeco') {
      this.title = 'Change Decoration';
      this.okTitle = 'Change';
    } else if (type === 'addDeco') {
      this.title = 'Add Decoration';
      this.okTitle = 'Add';
    } else {
      this.title = 'Decoration';
    }

    const modal = this.$refs.modal as BModal;
    if (modal) {
      modal.show();
    }
    if (this.resolve) {
      this.resolve(null);
    }
    return new Promise((resolve: (value: DecoName | null) => void) => {
      this.resolve = resolve;
    });
  }

  public select(itemInfo?: ItemInfo) {
    if (this.type) {
      this.itemCode = itemInfo?.code || -1;
    }
  }

  public onOk() {
    if (this.resolve) {
      this.resolve(this.decoName);
    }
    this.resolve = null;
  }

  public onCancel() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }
  public onClose() {
    if (this.resolve) {
      this.resolve(null);
    }
    this.resolve = null;
  }

  public created() {
    Global.decoInvetoryModal = this;
  }
}
