import { FoodType, numFoodType } from '@/game/infos/foodInfos';
import { FoodData } from '@/game/multithread/viewState';
import { Pool } from '@/util/pool';
import { Sprite } from 'pixi.js';
import { getParticle, Particle } from '../factory/particles';

const foodParticles: Particle[] = [
	0,
	Particle.FoodAlgae,
	Particle.FoodPrawn,
	Particle.FoodFillet,
	Particle.FoodSalmon,
	Particle.FoodPumpkin,
	Particle.Coin,
];

export class FoodControl {
	public static get(foodId: number, type: number) {
		const control = FoodControl._pools[type - 1].get();
		control.foodId = foodId;
		control.setType(type);
		return control;
	}
	private static _pools: Array<Pool<FoodControl>> = Array.from({ length: numFoodType }, (_) => new Pool(FoodControl));
	public symbol!: Sprite;

	public foodId = NaN;
	protected _foodType: FoodType = 0;

	public setType(foodType: FoodType) {
		if (this._foodType !== foodType) {
			this.symbol = getParticle(foodParticles[foodType] || 0);
			this._foodType = foodType;
		}
		this.symbol.visible = true;
	}

	public update(data: FoodData, ct: number) {
		if (data.eatenTime && ct > data.eatenTime && ct <= data.deathTime) {
			if (data.tx === data.tx) {
				const progress = (ct - data.eatenTime) / (data.deathTime - data.eatenTime);
				const eased = progress * progress;
				this.symbol.x = data.x + (data.tx - data.x) * eased;
				this.symbol.y = data.y + (data.ty - data.y) * eased;
			} else {
				this.symbol.visible = false;
			}
		} else if (data.deathTime > 0 && ct > data.deathTime) {
			this.symbol.visible = false;
		} else {
			this.symbol.x = data.x;
			this.symbol.y = data.y;
		}
	}

	public dispose() {
		if (this.symbol) {
			this.symbol.visible = true;
		}
		FoodControl._pools[this._foodType - 1].pool(this);
	}

}
