
































import { FishType } from '@/game/infos/fishInfos';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { wait } from '@/util/wait';
import { SkinGroup, SkinInfo, skinInfos } from '@/game/infos/skinInfos';
import { floorToFixed } from '@/util/number';
import { Global } from '@/store/globalz';

export interface SkinIntelData extends SkinInfo {
  targetId: string;
  placement: 'top' | 'left' | 'right' | 'bottom';
  container: any;
  rate?: number;
  index?: number;
}
@Component({
  components: {},
})
export default class SkinIntelToolTip extends Vue {
  public get skinInfo() {
    const { skinGroup, fishType } = this;
    return skinInfos[skinGroup]
      ? skinInfos[skinGroup][fishType]
      : {
          name: '',
          fishType,
          skinGroup,
          rarity: 0,
          rarityForColor: 0,
          skinId: 0,
        };
  }
  public get rateToFixed() {
    return floorToFixed(this.rate * 100, 2);
  }
  public get rarityName() {
    return Global.rarityLabels[this.skinInfo.rarity];
  }
  public get rarityColor() {
    return { color: Global.rarityColors[this.skinInfo.rarityForColor] };
  }

  public targetId = '';
  public placement = 'top';
  public container: any = null;
  public fishType: FishType = FishType.Random;
  public skinGroup: SkinGroup = SkinGroup.None;
  public rate: number = 0;
  public index: number | null | undefined = null;
  public show = false;
  protected localShow = false;

  @Watch('localShow', { immediate: true })
  public async showChanged() {
    if (this.localShow) {
      await wait(100);
      if (this.localShow) {
        this.show = true;
      }
    } else {
      this.show = false;
    }
  }

  public mounted() {
    this.$root.$on('showSkinIntel', this.showIntel);
    this.$root.$on('hideSkinIntel', this.hideIntel);
  }

  public async showIntel(data: SkinIntelData) {
    this.targetId = data.targetId;
    this.placement = data.placement;
    this.container = data.container;
    this.fishType = data.fishType;
    this.skinGroup = data.skinGroup;
    this.rate = data.rate || 0;
    this.index = data.index;
    // this.$nextTick(() => {
    this.localShow = true;
    // });
  }
  public hideIntel() {
    this.localShow = false;
    this.targetId = '';
  }
}
