export enum BlackPearlPurchaseType {
	Buy200,
	Buy400,
	Buy2000,
	Buy4000,
	Buy8000,
	Buy20000,
	Buy40000,
}

/*
usd
0.5 handful
1 string
5 shellful
10 pile
20 box
50 barrel
100 chest
*/

export interface BlackPearlPurchaseInfo {
	cost: number,
	amount: number,
}

export const blackPearlPurchaseInfos: BlackPearlPurchaseInfo[] = [];


function setInfo(type: BlackPearlPurchaseType, cost: number, amount: number) {
	blackPearlPurchaseInfos[type] = {
		cost,
		amount,
	};
}

setInfo(BlackPearlPurchaseType.Buy200, 200, 200);
setInfo(BlackPearlPurchaseType.Buy400, 400, 400);
setInfo(BlackPearlPurchaseType.Buy2000, 2000, 2000);
setInfo(BlackPearlPurchaseType.Buy4000, 4000, 4000);
setInfo(BlackPearlPurchaseType.Buy8000, 8000, 8000);
setInfo(BlackPearlPurchaseType.Buy20000, 20000, 20000);
setInfo(BlackPearlPurchaseType.Buy40000, 40000, 40000);
