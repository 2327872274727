var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay-canvas w-100 h-100 position-fixed",class:{
    active:
      _vm.showReincarnation ||
      _vm.showUnlock ||
      _vm.videoShowing ||
      _vm.showingGachaAnimation ||
      _vm.toShowChampionshipWinBadges ||
      _vm.gamexWatchingAds,

    unlock: _vm.showUnlock,
  }},[_c('div',{staticClass:"w-100 h-100 position-absolute reincarnation-bg",class:{
    active: _vm.showReincarnation || _vm.toShowChampionshipWinBadges,
    dark: _vm.toShowChampionshipWinBadges,
  }}),_c('OverlayCanvasComponent',{attrs:{"bool":_vm.bool,"showUnlock":_vm.showUnlock,"showReincarnation":_vm.showReincarnation && !_vm.videoShowing,"showChampionshipWinBadges":_vm.toShowChampionshipWinBadges}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showReincarnation),expression:"showReincarnation"}],staticClass:"reincarnation-container d-flex flex-column",style:(("opacity: " + _vm.reincarnationOpacity))},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onReincarnate}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoAdReady),expression:"videoAdReady"}],staticClass:"fa fas fa-film pr-2"}),_vm._v("Extra Life")]),_c('b-button',{attrs:{"variant":"link mt-3"},on:{"click":_vm.onQuitReincarnate}},[_vm._v("Quit")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showingGachaAnimation),expression:"showingGachaAnimation"}],staticClass:"reveal-container container",class:{ active: _vm.toShowNextRevealButton }},[_c('b-button',{attrs:{"variant":"link mr-3 reveal-next-btn"},on:{"click":_vm.onRevealNext}},[_vm._v("Next >")]),_c('b-button',{attrs:{"variant":"link mr-3 skip-btn"},on:{"click":_vm.onSkipGacha}},[_vm._v("Skip")])],1),(_vm.showAdBlocker)?_c('div',{staticClass:"\n\t\t\t\tad-blocker-container\n\t\t\t\td-flex\n\t\t\t\tjustify-content-center\n\t\t\t\talign-items-center\n\t\t\t\ttext-center text-titan\n\t\t\t"},[_vm._v(" If you are seeing this, it's likely you are using an ad-blocker."),_c('br'),_vm._v(" Please wait for 45 seconds."),_c('br'),_vm._v(" ("+_vm._s(_vm.secLeft)+"s) ")]):_vm._e(),_c('OverlayGameAds',{attrs:{"height":_vm.height,"width":_vm.width,"showReincarnation":_vm.showReincarnation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }