import { getParticle, Particle } from '@/client/factory/particles';
import { BroadcastDamageEvent } from '@/game/multithread/state';
import { Pool } from '@/util/pool';
import { Rotate } from '@/util/rotate';
import { Tween } from '@/util/tweents';
import { Vector } from 'matter-js';
import { Ticker } from 'pixi.js';

export class SplashEffectControl {

	public static get(x: number, px: number, speed: number) {
		const control = SplashEffectControl._pool.get();
		control.init(x, px, speed);
		return control;
	}
	private static _pool: Pool<SplashEffectControl> = new Pool(SplashEffectControl);

	public tickAdded = false;
	public symbol = getParticle(Particle.Circle);
	public vector = { x: 0, y: 0 };

	protected startTime = 0;

	constructor() {
		this.symbol.scale.set(0.15);
		this.symbol.alpha = 0.5;
		this.symbol.tint = 0xffffff;
	}

	public init(x: number, px: number, speed: number) {
		this.symbol.x = x;
		this.symbol.y = 0;

		this.startTime = Date.now();

		const speedScale = Math.random() * .5 + .5;
		const radian = Rotate.radian(px, 75, x, 0);
		this.vector = Vector.rotate({ x: speed * speedScale, y: 0 }, radian);

		if (!this.tickAdded) {
			Ticker.shared.add(this.tick);
			this.tickAdded = true;
		}
	}

	public tick = () => {
		this.vector.y += 0.5;
		this.symbol.x += this.vector.x;
		this.symbol.y += this.vector.y;
		if (this.symbol.y >= 0) {
			this.dispose();
		}
	}
	public dispose() {
		if (this.symbol.parent) {
			this.symbol.parent.removeChild(this.symbol);
		}
		if (this.tickAdded) {
			Ticker.shared.remove(this.tick);
			this.tickAdded = false;
		}
		SplashEffectControl._pool.pool(this);
	}
}
