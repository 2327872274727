import Factory from '@/client/factory';
import { Dictionary } from './dictionary';

const queues: Dictionary<Array<() => Promise<any>>> = {};


export class Queuer {
	public static async queue(queueId: string, promiseFunction: () => Promise<any>) {
		if (!queues[queueId]) { queues[queueId] = []; }
		const q = queues[queueId];
		const hasQueue = q.length > 0;
		q.push(promiseFunction);
		if (!hasQueue) {
			Queuer.runQueue(queueId);
		}
	}

	protected static async runQueue(queueId: string) {
		const q = queues[queueId];
		const f = q[0];
		if (f) {
			try {
				await f();
			} catch (error) {

			}
			q.shift();
			Queuer.runQueue(queueId);
		}
	}

}
