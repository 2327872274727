


































import { Analytics } from '@/client/analytics';
import { fishInfos, fishTiersAndBelow, FishType } from '@/game/infos/fishInfos';
import { TipId } from '@/game/infos/tipInfos';
import { Global } from '@/store/globalz';
import gamex from '@/store/modules/gamex';
import globalx from '@/store/modules/globalx';
import settingx from '@/store/modules/settingx';
import tipx from '@/store/modules/tipx';
import userx from '@/store/modules/userx';
import { Dictionary } from '@/util/dictionary';
import { wait } from '@/util/wait';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FishButton from '../mainMenu/tools/inventory/FishButton.vue';
import FishGeneComponent from '../mainMenu/evolution/FishGeneComponent.vue';
import FishAllSkillsComponent from '../mainMenu/evolution/FishAllSkillsComponent.vue';
import { FishIntelData } from '../mainMenu/tools/fishSelector/FishIntelTooltip.vue';
import { FishGeneSkillInfo } from '../mainMenu/evolution/EvolutionModal.vue';

@Component({
  components: {
    FishButton,
    FishGeneComponent,
    FishAllSkillsComponent,
  },
})
export default class TnyEvolutionFooter extends Vue {
  public page: 'skill' | 'gene' = 'skill';
  public withRecessive = true;

  @Prop({ default: () => [1, 2, 3] }) public heroes!: FishType[];

  public get geneses() {
    const geneses = this.heroes.map((h) => fishInfos[h].genes);
    const recessives = this.heroes.map((h) => fishInfos[h].recessives);
    const dic: Dictionary<FishGeneSkillInfo> = {};
    let order = 0;
    const result: FishGeneSkillInfo[][] = [];
    for (let i = 0; i < geneses.length; i++) {
      const genes = geneses[i];
      const recesses = recessives[i];
      result[i] = [];

      for (const gene of genes) {
        const key = gene.type;
        if (dic[key]) {
          if (dic[key].lvl < 2) {
            dic[key].lvl += gene.lvl;
            dic[key].status = 'Stack!';
          } else {
            dic[key].status = 'Too Much!';
          }
          dic[key].life = i;
          dic[key].order = order;
        } else {
          dic[key] = {
            type: gene.type,
            order,
            lvl: gene.lvl,
            status: '',
            recess: false,
            life: i,
          };
          order++;
        }
      }

      if (this.withRecessive) {
        for (const gene of recesses) {
          const key = gene.type;
          if (dic[key]) {
            if (dic[key].lvl < 2) {
              dic[key].lvl += gene.lvl;
              dic[key].status = 'Stack!';
              dic[key].recess = true;
            } else {
              dic[key].status = 'Too Much!';
            }
            dic[key].life = i;
            dic[key].order = order;
          } else {
            dic[key] = {
              type: gene.type,
              order,
              lvl: gene.lvl,
              status: '',
              recess: true,
              life: i,
            };
            order++;
          }
        }
      }

      for (const gene of Object.values(dic)) {
        result[i].push({ ...gene });
        gene.status = 'Inherit!';
      }
      result[i].sort((a, b) => a.life === b.life ? a.order - b.order
        : a.life === i ? -1
          : b.life === i ? 1
            : a.life - b.life);
    }
    return result;
  }

  public onToFishSelector(life: number) {
    this.$emit('select', life);
  }
  public showFishIntel(life: number) {
    const data: FishIntelData = {
      targetId: 'evomod-fish-' + life,
      placement: 'top',
      container: 'cps-evolution-modal',
      fishType: this.heroes[life],
    };
    this.$root.$emit('showFishIntel', data);
  }
  public hideFishIntel() {
    this.$root.$emit('hideFishIntel');
  }

  public async onOk() {
    // const server = globalx.selectedServer || globalx.recommendedServer;
    // if (server) {
    //   gamex.setGameStage('Connecting');
    //   if (globalx.mainMenuName !== userx.userDoc.name) {
    //     await userx.updateDisplayName(globalx.mainMenuName);
    //   }
    // //   Global.stageControl.communicator!.connect(server, userx.heroes);
    //   Global.stageControl.communicator!.connectChampionship(server);
    //   Analytics.play();
    // } else {
    //   this.$root.$emit('error', 'No server selected.');
    // }
  }
}
