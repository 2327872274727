function is_touch_device4() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  const mq = (queryString: string) => window.matchMedia(queryString).matches;
  const { DocumentTouch } = window as any;
  if (('ontouchstart' in window) || DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export const isTouchDevice = is_touch_device4();
// export const isMobile = isTouchDevice;
