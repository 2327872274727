













import { Global } from '@/store/globalz';
import userx from '@/store/modules/userx';
import { addComma } from '@/util/number';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { addQuarterField, subQuarterField } from './poqFunctions';

@Component({
  components: {},
})
export default class QuartersField extends Vue {
  public baseUrl = process.env.BASE_URL;
  public get quartersString() {
    return userx.userDoc.poq ? addComma(userx.poqWallet.balance) : '?';
  }

  public mounted() {
    addQuarterField();
  }
  public beforeDestroy() {
    subQuarterField();
  }

  public onClick() {
    Global.poqLinkModal.show();
  }
}
