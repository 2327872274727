import { DecoSet } from '../infos/decorativeInfos';
import { EmojiType } from '../infos/emojiInfos';
import { PlayerMode } from '../infos/enums';
import { FishType } from '../infos/fishInfos';

export interface IGameCommand {
	sessionId: string,
	type: number,
}

export enum GameCommandType {
	Spectate,
	JoinGame,
	QuitGame,

	Action,
	Bleedout,
	Reincarnate,
	Stabab,

	Emoji,

	OpenPublicChest,

	BuySpear,
	EquipSpear,
	Chat,
	Mute,
	MuteAll,

	AdHeal,
	OpenTempleChest,

	DojoCreate,
	DojoGoto,
	DojoLeave,
	DojoDisband,
	DojoInvite,
	DojoKick,
	DojoChangeHost,
	DojoHeal,
	DojoSpectate,
	DojoFight,
}
export interface CommandSpectate extends IGameCommand {
	nothing?: any,
}
export interface CommandJoinGame extends IGameCommand {
	join?: boolean,
	capStreak?: boolean,
}
export interface CommandQuitGame extends IGameCommand {
	nothing?: any,
}
export interface CommandAction extends IGameCommand {
	direction: number,
	speedScale: number,
	skill1: boolean,
	skill2: boolean,
	skill3: boolean,
	payload?: any,
}
export interface CommandReincarnate extends IGameCommand {
	value: number,
}
export interface CommandBleedoutAction extends IGameCommand {
	experdite: boolean,
	delay: boolean,
}
export interface CommandStababAction extends IGameCommand {
	index: number,
	payload: any,
}
export interface CommandEmojiAction extends IGameCommand {
	eid: EmojiType,
}
export interface CommandBuyEquipSpearAction extends IGameCommand {
	st: number,
}
export interface CommandMuteAction extends IGameCommand {
	uid: string,
	value: boolean,
}
export interface CommandMuteAllAction extends IGameCommand {
	value: boolean,
}
export interface CommandChatAction extends IGameCommand {
	msg: string,
}
export interface CommandPlayeModeAction extends IGameCommand {
	mode: PlayerMode,
}
export interface CommandDojoIdAction extends IGameCommand {
	id: number,
}
export interface CommandDojoHostAction extends IGameCommand {
	uid: string,
}

